import Page from "#/components/Page";
import React from "react";
import { Route, Switch } from "react-router";
import Project from "./components/Project";
import ProjectList from "./components/ProjectList";
import QueryList from "./components/QueryList";
import Top50ServiceWrapper from "./Top50ServiceWrapper";
import QueryPositions from "./components/QueryPositions";

const TopfiftyRoutes = ({ match }: any) => (
  <Top50ServiceWrapper>
    <Switch>
      <Route
        path={match.url}
        exact={true}
        render={(props: any) => (
          <Page title="TOP 50">
            <ProjectList {...props} />
          </Page>
        )}
      />
      <Route
        path={`${match.url}/queries/:alias?`}
        exact={true}
        render={(props: any) => (
          <Page title="Группы запросов | TOP 50">
            <QueryList {...props} />
          </Page>
        )}
      />
      <Route
        path={`${match.url}/projects/:projectId`}
        exact={false}
        render={(props: any) => <Project {...props} />}
      />
      <Route
        path={`${match.url}/query_positions/:id`}
        exact={true}
        render={(props: any) => (
          <Page title="Позиции | TOP 50">
            <QueryPositions {...props} />
          </Page>
        )}
      />
    </Switch>
  </Top50ServiceWrapper>
);

export default TopfiftyRoutes;
