import urljoin from "url-join";
import { Top50Project, PrimaryKey } from "#/store/types";

export const constructChangelogPath = () => `/changelog/`;

export const constructMainSiteURL = () => window.location.origin;

export const constructRegisterViaInvitePath = (code: string) =>
  urljoin(constructMainSiteURL(), "auth/register-via-invite", code);

export const constructTop50ProjectsListPath = () => `/services/top50/`;

export const constructTop50ProjectStatsPagePath = (project: { pk: PrimaryKey } | null) =>
  project ? `/services/top50/projects/${project.pk}/stats` : "";

export const constructtop50ProjectInfoPagePath = (project: Top50Project | null) =>
  project ? `/services/top50/projects/${project.pk}` : "";
