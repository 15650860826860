import { apiGet } from "#/api";
import Loader from "#/components/Loader";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Top50QueryPage } from "#/store/types";
import { useDocumentTitle } from "#/util/hooks";
import { useSelectionRegistry } from "#/util/selectionRegistry";
import React, { useEffect, useState } from "react";
import { Top50ProjectGraph } from "../../../../types";
import Graph from "../../../Graph";
import ProjectContext, { IProjectContext } from "../../ProjectContext";
import EventTable from "./components/EventTable";
import PageHeader from "./components/PageHeader";
import QueryTable from "./components/QueryTable";
import ProjectInfoContext from "./ProjectInfoContext";
import _ from "lodash";
import ControllableConfigurationSelect from "../../../ConfigurationsSelect/ControllableConfigurationsSelect";
import style from "./index.module.scss";

export interface ProjectInfoOwnProps {}

interface ProjectInfoConnectedProps {}

type ProjectInfoProps = ProjectInfoConnectedProps & ProjectInfoOwnProps;

const ProjectInfo = (props: ProjectInfoProps) => {
  const projectContext = React.useContext<IProjectContext>(ProjectContext);
  const {
    projectId,
    graph,
    project,
    projectLoading,
    projectQueries,
    fetchProject,
    supportedConfigs,
  } = projectContext;
  useDocumentTitle(() => (project ? `${project.title} | TOP50` : "Загрузка..."), [project]);

  // Flags
  const [loadingEventGraph, setLoadingEventGraph] = useState<boolean>(false);
  const loadingGraph = projectContext.graphLoading || loadingEventGraph;

  // Event graph
  const [eventGraph, setEventGraph] = useState<Top50ProjectGraph | null>(null);
  const [eventQueries, setEventQueries] = useState<Top50QueryPage[]>([]);
  const [showingEventGraph, setShowingEventGraph] = useState<boolean>(false);
  const [selectedEventID, setSelectedEventID] = useState<PrimaryKey | null>(null);

  const querySelection = useSelectionRegistry(
    {},
    projectQueries.map(a => a.pk),
    [projectQueries],
  );

  const showProjectGraph = React.useCallback(() => {
    setShowingEventGraph(false);
  }, []);

  const fetchEventGraph = React.useCallback(
    async (eventID: PrimaryKey) => {
      setSelectedEventID(eventID);
      setShowingEventGraph(true);
      setLoadingEventGraph(true);
      try {
        const response = await apiGet(API_ROUTES.TOP50.PROJECT_GRAPH(projectId), {
          event_id: eventID,
          days_count: 90,
        });
        setEventGraph(response);
        return true;
      } finally {
        setLoadingEventGraph(false);
      }
    },
    [projectId],
  );

  // Initial mount
  useEffect(() => {
    projectContext.setProjectFetchConfig({ daysCount: 30, config: null, fetchPositions: false });
  }, []);

  const configurationFilter = React.useCallback(c => _.includes(supportedConfigs, c.pk), [
    supportedConfigs,
  ]);

  const renderedGraph = (() => {
    if (!eventGraph) {
      return (
        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
          Необходимо выбрать событие, чтобы посмотреть график
        </div>
      );
    } else if (eventGraph && !eventGraph.graph.length) {
      return (
        <div className="d-flex w-100 h-100 justify-content-center align-items-center">
          График пуст
        </div>
      );
    }

    return (
      <div style={{ height: 400 }}>
        <Graph graph={eventGraph} height={400} />
      </div>
    );
  })();

  return (
    <ProjectInfoContext.Provider
      value={{
        querySelection,
        fetchEventGraph,
      }}
    >
      <div className="box h-100">
        <PageHeader />
        <div className="box-body">
          {projectLoading && <Loader />}
          {!projectLoading && (
            <>
              <EventTable />

              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-header with-border flex-vertically-centered-x">
                      <h5 className="box-subtitle mr-4">
                        <i className="fas fa-search" />
                        <span className="ml-2">Запросы</span>
                      </h5>

                      <ControllableConfigurationSelect
                        filter={configurationFilter}
                        value={
                          projectContext.projectFetchConfig &&
                          projectContext.projectFetchConfig.config
                            ? projectContext.projectFetchConfig.config
                            : supportedConfigs.length > 0
                            ? supportedConfigs[0]
                            : null
                        }
                        onChange={c =>
                          projectContext.setProjectFetchConfig({
                            ...projectContext.projectFetchConfig!,
                            config: c,
                          })
                        }
                        className={style["configuration-select"]}
                      />

                      {showingEventGraph && (
                        <div>
                          <button className="btn btn-list row-action" onClick={showProjectGraph}>
                            Показать исходный график
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="box-body">
                      {/* Graph */}
                      <div className="mb-5 py-2">{loadingGraph ? <Loader /> : renderedGraph}</div>
                      <QueryTable selectedEventID={selectedEventID} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </ProjectInfoContext.Provider>
  );
};

export default ProjectInfo;
