import { CODE_NON_REACHABLE } from "#/util";

export enum TaskTableSelectedTab {
  TO_USER = 0,
  FINISHED = 1,
  DELAYED = 2,
  FROM_USER = 3,
  WITHOUT_EXECUTOR = 4,
}

export const backgroundTints = {
  [TaskTableSelectedTab.TO_USER]: "bg-info",
  [TaskTableSelectedTab.FINISHED]: "bg-warning",
  [TaskTableSelectedTab.DELAYED]: "bg-danger",
  [TaskTableSelectedTab.FROM_USER]: "bg-info",
  [TaskTableSelectedTab.WITHOUT_EXECUTOR]: "bg-warning",
};

export const tabTitles = {
  [TaskTableSelectedTab.TO_USER]: "Текущие",
  [TaskTableSelectedTab.FINISHED]: "Завершенные",
  [TaskTableSelectedTab.DELAYED]: "Отложенные",
  [TaskTableSelectedTab.FROM_USER]: "Назначенные",
  [TaskTableSelectedTab.WITHOUT_EXECUTOR]: "Без исполнителя",
};

export const tabIcons: Record<TaskTableSelectedTab, string> = {
  [TaskTableSelectedTab.TO_USER]: "fas fa-list",
  [TaskTableSelectedTab.FINISHED]: "fas fa-check",
  [TaskTableSelectedTab.DELAYED]: "fas fa-history",
  [TaskTableSelectedTab.FROM_USER]: "fas fa-thumbtack",
  [TaskTableSelectedTab.WITHOUT_EXECUTOR]: "fa fa-user-alt-slash",
};

export const getTabTitle = (type: number) =>
  type in tabTitles ? (tabTitles as any)[type] : CODE_NON_REACHABLE();

export const getTabBackgroundTint = (type: number) =>
  type in backgroundTints ? (backgroundTints as any)[type] : CODE_NON_REACHABLE();

export const getIconClass = (type: number) =>
  type in tabIcons ? (tabIcons as any)[type] : CODE_NON_REACHABLE();

export const getTasksTypeBySelectedOption = (option: TaskTableSelectedTab) => {
  switch (option) {
    case TaskTableSelectedTab.TO_USER:
      return "Задачи, которые назначили Вам";
    case TaskTableSelectedTab.FINISHED:
      return "Завершенные задачи";
    case TaskTableSelectedTab.DELAYED:
      return "Приостановленные задачи, назначенные Вам";
    case TaskTableSelectedTab.FROM_USER:
      return "Назначенные Вами задачи";
    case TaskTableSelectedTab.WITHOUT_EXECUTOR:
      return "Задачи без назначеного исполнителя";
    default:
      CODE_NON_REACHABLE();
      return null;
  }
};
