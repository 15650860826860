import { InvitationSystemState, StoreRootState } from "#/store/types";
import { createSelector } from "reselect";

const getInvitationSystem = (store: StoreRootState) => store.invitationSystem;

const getInvitationLink = createSelector(
  getInvitationSystem,
  (invitationSystem: InvitationSystemState) => invitationSystem.invitationLink,
);

const didInvitationLinkGenerationFail = createSelector(
  getInvitationSystem,
  (invitationSystem: InvitationSystemState) => invitationSystem.generationFailed,
);

const isGeneratingInvitationLink = createSelector(
  getInvitationSystem,
  (invitationSystem: InvitationSystemState) => invitationSystem.generating,
);

const isRegistrationInProcess = createSelector(
  getInvitationSystem,
  (invitationSystem: InvitationSystemState) => invitationSystem.registering,
);

const didRegistrationFail = createSelector(
  getInvitationSystem,
  (invitationSystem: InvitationSystemState) => invitationSystem.registrationFailed,
);

export default {
  getInvitationLink,
  didRegistrationFail,
  isRegistrationInProcess,
  isGeneratingInvitationLink,
  didInvitationLinkGenerationFail,
};
