import React, { useState, ChangeEvent, useRef, useEffect } from "react";
import { fetchCounters } from "#/store/modules/metrika/goals";
import { StoreRootState } from "#/store/types";
import { connect } from "react-redux";

interface InputOwnProps {}

interface InputConnectedProps {
  fetchCounters: any;
}
type InputProps = InputOwnProps & InputConnectedProps;

const TokenInput = (props: InputProps) => {
  const [token, setToken] = useState("");
  const handleInput = (e: ChangeEvent<HTMLInputElement>) => setToken(e.target.value);
  const onClick = () => props.fetchCounters(token);
  return (
    <div className="input-group mb-3">
      <input
        type="text"
        className="form-control"
        placeholder="Token"
        aria-label="Recipient's username"
        aria-describedby="basic-addon2"
        onChange={handleInput}
      />
      <div className="input-group-append">
        <button className="btn btn-outline-secondary" type="button" onClick={onClick}>
          Get counters
        </button>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreRootState) => ({});

const mapDispatchToProps = {
  fetchCounters,
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenInput);
