import React from "react";
import _ from "lodash";
import { CODE_NON_REACHABLE } from "./util";

export enum TaskPriority {
  HIGH = 10,
  MEDIUM = 20,
  LOW = 30,
}

export interface TaskPriorityInfo {
  level: number;
  name: string;
}

export const taskPriorityToString = (tp: TaskPriority) =>
  tp === TaskPriority.HIGH ? "Высокий" : tp === TaskPriority.MEDIUM ? "Средний" : "Низкий";

export const taskPrioritiesById: Record<TaskPriority, TaskPriorityInfo> = _.keys(TaskPriority)
  .filter(key => !isNaN(Number(TaskPriority[key])))
  .reduce(
    (acc, tp) => ({
      ...acc,
      [TaskPriority[tp]]: { level: TaskPriority[tp], name: taskPriorityToString(TaskPriority[tp]) },
    }),
    {},
  ) as any;

export const formatPriority = (priorityIndex: TaskPriority) => {
  switch (_.toNumber(priorityIndex)) {
    case TaskPriority.HIGH:
      return <i className="fas fa-angle-double-up high-priority" />;
    case TaskPriority.MEDIUM:
      return <i className="fas fa-angle-double-up med-priority" />;
    case TaskPriority.LOW:
      return <i className="fas fa-angle-up low-priority" />;
    default:
      CODE_NON_REACHABLE();
      return "N/A";
  }
};
