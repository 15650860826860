import { apiGet } from "#/api";
import { API_ROUTES } from "#/conf/api";
import {
  ActionDescriptionTuple,
  canUser,
  canUserPerformAll,
  canUserPerformAnyOf,
} from "#/permissions";
import { PrimaryKey, Proxy, User } from "#/store/types";
import _ from "lodash";
import React, { RefObject, useCallback, useEffect, useMemo, useState } from "react";

export function useDebounced(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export function useIntersect(
  ref: React.RefObject<Element>,
  options: { threshold?: number; root?: Element | null; rootMargin?: string },
) {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin: "0px",
        root: null,
        threshold: 1.0,
        ...options,
      },
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current!);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting;
}

export const useDebouncedInputChangeValueUpdater = (
  updateFunction: (value: string) => void,
  inputRef: RefObject<HTMLInputElement>,
  debounceTimeMs = 1000,
) => {
  return _.debounce((ev: any) => {
    if (!inputRef.current) {
      return;
    }
    const value = inputRef.current.value;
    updateFunction(value);
  }, debounceTimeMs);
};

export const useDocumentTitle = (updater: () => string, dependencies?: any[]) => {
  useEffect(() => {
    document.title = updater();
  }, dependencies);
};

export const usePermissions = (
  user: User | null,
  options: Array<[ActionDescriptionTuple[], "or" | "and" | "none"]>,
  dependencies?: any[],
): boolean[] => {
  const perms = useMemo(() => {
    return _.flatten(
      options.map(o => {
        const [al, op] = o;
        if (op === "or") {
          return canUserPerformAnyOf(user, al);
        } else if (op === "and") {
          return canUserPerformAll(user, al);
        } else {
          return al.map(a => canUser(user, a[0], a[1], a[2]));
        }
      }),
    );
  }, dependencies);
  return perms;
};

export interface ModalState {
  shown: boolean;
  show: () => void;
  hide: () => void;
  toggle: () => void;
}

export const useModal = (initialStatus: boolean = false): ModalState => {
  const [shown, setShown] = useState<boolean>(initialStatus);
  const toggle = useCallback(() => setShown(!shown), [shown, setShown]);
  const hide = useCallback(() => setShown(false), [setShown]);
  const show = useCallback(() => setShown(true), [setShown]);
  return { shown, hide, show, toggle };
};

// TODO: Implement some kind of caching for proxy list
export const useProxies = (options: { includeById: boolean; minimal: boolean }, deps: any[]) => {
  const [proxies, setProxies] = React.useState([]);
  const includeById = options.includeById ? options.includeById : false;
  const minimal = options.minimal ? options.minimal : false;
  useEffect(() => {
    (async () => {
      const resp = await apiGet(API_ROUTES.PROXY.PROXIES, { minimal });
      setProxies(resp.items);
    })();
  }, [...deps]);
  const byId = React.useMemo<Record<PrimaryKey, Proxy>>(() => {
    if (!includeById) {
      return {};
    }
    return _.keyBy(proxies, "pk");
  }, [proxies, ...deps]);
  return { proxies, byId };
};
