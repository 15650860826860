import _ from "lodash";

export default {
  TASK_OPEN_NOT_PERMITTED: "Нет доступа к задаче",

  TASK_REMOVE_SUCCESS: "Задача была успешно удалена.",
  TASK_EDIT_SUCCESS: "Редактирование задачи успешно выполнено.",

  PROJECT_DELETE_FAIL: "Удаление проекта не было успешным. Повторите попытку.",
  PROJECT_DELETE_SUCCESS: (title: string) => "Проект " + title + " был успешно удален.",
  PROJECT_EDIT_SUCCESS: (title: string) =>
    "Изменение данных проекта " + title + " было успешно выполнено.",

  USER_EDIT_SUCCESS: (username: string) =>
    "Изменение данных пользователя " + username + " выполнено успешно.",
  USER_REMOVE_SUCCESS: "Удаление пользователя выполнено успешно.",
  USER_REMOVE_FAIL: "Произошла ошибка при удалении пользователя. Повторите попытку позже.",
};

export enum TaskType {
  SINGLE = "SINGLE",
  PERIODIC = "PERIODIC",
  SCHEDULED = "SCHEDULED",
}

export const taskTypeName = (tt: TaskType) => 
  tt === "SINGLE" ? "Разовая" : tt === "PERIODIC" ? "Периодичная" : tt === "SCHEDULED" ? "По расписанию" : null

export interface TaskTypeInfo {
  tag: TaskType;
  name: string;
}

export const taskTypesById: Record<TaskType, TaskTypeInfo> = _.keys(TaskType as any).reduce(
  (acc, ttag: any) => ({ ...acc, [ttag]: { tag: ttag, name: taskTypeName(ttag) } }),
  {},
) as any;

export const TaskPeriods = {
  DAILY: { tag: "DAILY", value: "Раз в несколько дней" },
  WEEKLY: { tag: "WEEKLY", value: "Раз в неделю" },
  MONTHLY: { tag: "MONTHLY", value: "Раз в месяц" },
};

export const WeekDays = {
  MONDAY: {'value': 0, 'name': 'Понедельник'},
  TUESDAY: {'value': 1, 'name': 'Вторник'},
  WEDNESDAY: {'value': 2, 'name': 'Среда'},
  THURSDAY: {'value': 3, 'name': 'Четверг'},
  FRIDAY: {'value': 4, 'name': 'Пятница'},
};