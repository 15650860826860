import { falseIfUndefinedElseBoolean } from "#/util";
import cn from "classnames";
import ru from "date-fns/locale/ru";
import React from "react";
import DatePicker, { ReactDatePickerProps, registerLocale } from "react-datepicker";
import style from "./index.module.scss";

registerLocale("ru-RU", ru);

interface Props extends ReactDatePickerProps {
  invalid?: boolean;
}

const DatePickerCustom = (props: Props) => {
  const invalid = falseIfUndefinedElseBoolean(props.invalid);
  return (
    <DatePicker {...props} className={cn([props.className, cn({ [style.invalid]: invalid })])} />
  );
};

export default DatePickerCustom;
