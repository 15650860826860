import { UserSettings } from "#/store/types";

export enum SettingsPageTab {
  GENERAL = 0,
  SECURITY = 1,
  NOTIFICATIONS = 2,
}

export interface TabProps {
  userSettings: UserSettings;
  setUserSettings: (nus: UserSettings) => void;
  loadingUserSettings: boolean;
}
