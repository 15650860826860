import { canUser, canUserPerformAll } from "#/permissions";
import { User } from "#/store/types";
import { userFullName } from "#/util";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ListItemConnectedProps {}

interface ListItemOwnProps {
  item: User;
  onSave: any;
  setActive: any;
  currentUser: User;
}

type ListItemProps = ListItemConnectedProps & ListItemOwnProps;

const ListItem = (props: ListItemProps) => {
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [item, setItem] = useState(props.item);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, first_name: e.target.value });
  };

  const onSurnameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, last_name: e.target.value });
  };

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, email: e.target.value });
  };

  const onPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({ ...item, phone: e.target.value });
  };

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const saveChanges = () => {
    if (_.isEqual(item, props.item)) {
      toggleEditMode();
      return;
    }
    setFetching(true);
    props.onSave(item).then(() => {
      setFetching(false);
      setEditMode(false);
    });
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      saveChanges();
    }
  };

  return (
    <tr>
      <td>{item.pk}</td>
      <td>
        {isEditMode ? (
          <>
            <input
              type="text"
              className="form-control"
              value={item.first_name}
              onChange={e => onNameChange(e)}
              onKeyDown={e => onEnterPress(e)}
            />
            <input
              type="text"
              className="form-control"
              value={item.last_name}
              onChange={e => onSurnameChange(e)}
              onKeyDown={e => onEnterPress(e)}
            />
          </>
        ) : (
          <Link to={"/profile/" + item.pk}>
            <img
              src={
                item.avatar
                  ? (item.avatar as string)
                  : "https://avatars.servers.getgo.com/2205256774854474505_medium.jpg"
              }
              className="proj-img"
              alt="project card"
            />
            <span className="ml-2">{userFullName(item)}</span>
          </Link>
        )}
      </td>
      <td>
        {isEditMode ? (
          <input
            type="text"
            className="form-control"
            value={item.email}
            onChange={e => onEmailChange(e)}
            onKeyDown={e => onEnterPress(e)}
          />
        ) : item.email ? (
          item.email
        ) : (
          "Не указана"
        )}
      </td>
      <td>
        {isEditMode ? (
          <input
            type="text"
            className="form-control"
            value={item.phone}
            onChange={e => onPhoneChange(e)}
            onKeyDown={e => onEnterPress(e)}
          />
        ) : item.phone ? (
          item.phone
        ) : (
          "Не указан"
        )}
      </td>
      <td>
        {item.telegram ? (
          <a href={item.telegram} target="_blank" rel="noopener noreferrer">
            Есть
          </a>
        ) : (
          "Не указан"
        )}
      </td>
      <td>19</td>
      <td>12-12-2018</td>
      {canUserPerformAll(props.currentUser, [
        ["EDIT", "USER", { obj: item }],
        ["DELETE", "USER", { obj: item }],
      ]) && (
        <td>
          {!isEditMode ? (
            <>
              {canUser(props.currentUser, "EDIT", "USER", { obj: item }) && (
                <button className="btn btn-list  row-action" onClick={() => toggleEditMode()}>
                  <i className="fas fa-edit" />
                </button>
              )}
              {canUser(props.currentUser, "DELETE", "USER", { obj: item }) && (
                <button
                  className="btn btn-list row-action rm"
                  onClick={() => props.setActive(item.pk)}
                  disabled={fetching}
                >
                  <i className="fas fa-trash" />
                </button>
              )}
            </>
          ) : (
            <>
              <button
                className="btn btn-list  row-action"
                onClick={() => saveChanges()}
                disabled={fetching}
              >
                <i className="fas fa-check" />
              </button>
              <button className="btn btn-list  row-action rm" onClick={() => toggleEditMode()}>
                <i className="fas fa-times" />
              </button>
            </>
          )}
        </td>
      )}
    </tr>
  );
};

export default ListItem;
