import { createReducer } from "#/store/helpers";
import { PrimaryKey, User, UserData, UserRole, UsersState } from "#/store/types";
import _ from "lodash";
import { Action } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const initialState: UsersState = {
  // Users data
  usersById: {},
  users: [],

  // Users flags
  loadingUsers: false,
  loadingUsersFailed: false,
  removingUser: false,
  removingUserFailed: false,
  creatingUser: false,
  creatingUserFailed: false,
  editingUser: false,
  editingUserFailed: false,

  // Roles
  rolesById: {},
  roles: [],
};

const userInitialFields: UserData = {
  data: null,
  loading: false,
  loadingFailed: false,
};

const roleInitialFields = {};

export const reducer = createReducer<UsersState, Action>(
  {
    ////////////////////////////////////////////////////
    // Create user
    ////////////////////////////////////////////////////

    [MutationType.CREATE_USER_START]: (state: UsersState, action: ActionTypes.CreateUserStart) => ({
      ...state,
      creatingUser: true,
      creatingUserFailed: false,
    }),

    [MutationType.CREATE_USER_FAIL]: (state: UsersState, action: ActionTypes.CreateUserFail) => ({
      ...state,
      creatingUser: false,
      creatingUserFailed: true,
    }),

    [MutationType.CREATE_USER_SUCCEED]: (
      state: UsersState,
      { payload }: ActionTypes.CreateUserSucceed,
    ) => ({
      ...state,
      creatingUser: false,
      creatingUserFailed: false,
      usersById: {
        ...state.usersById,
        [payload.id]: {
          ...userInitialFields,
          ..._.get(state.usersById, payload.id, {} as any),
          ...payload.user,
        },
      },
    }),

    ////////////////////////////////////////////////////
    // Fetch users
    ////////////////////////////////////////////////////

    [MutationType.FETCH_USERS_FAIL]: (
      state: UsersState,
      action: ActionTypes.FetchProfilesListSucceedAction,
    ) => ({
      ...state,
      loadingUsers: true,
      loadingUsersFailed: false,
    }),

    [MutationType.FETCH_USERS_FAIL]: (
      state: UsersState,
      action: ActionTypes.FetchProfilesListSucceedAction,
    ) => ({
      ...state,
      loadingUsers: false,
      loadingUsersFailed: true,
    }),

    [MutationType.FETCH_USER_ROLES_SUCCEED]: (state: UsersState, { payload: { roles } }) => ({
      ...state,
      rolesById: {
        ...state.rolesById,
        ..._.chain(
          roles.map((role: UserRole) => ({
            ...roleInitialFields,
            ...role,
          })),
        )
          .keyBy("pk")
          .value(),
      },
      roles: roles.map((role: UserRole) => role.pk),
    }),

    [MutationType.FETCH_USERS_SUCCEED]: (
      state: UsersState,
      { payload: { users } }: ActionTypes.FetchProfilesListSucceedAction,
    ) => {
      return {
        ...state,
        loadingUsers: false,
        loadingUsersFailed: false,
        usersById: {
          ...state.usersById,
          ..._.chain(
            users.map((user: User) => {
              const prevUserData = _.get(state.usersById, user.pk, userInitialFields);
              return {
                ...prevUserData,
                data: { ...prevUserData.data, ...user },
              };
            }),
          )
            .keyBy("data.pk")
            .value(),
        },
        users: users.map((user: User) => user.pk),
      };
    },

    // Fetch public user info
    [MutationType.FETCH_USER_INFO_START]: (
      state: UsersState,
      action: ActionTypes.FetchUserInfoSucceed,
    ) => {
      return {
        ...state,
        loadingUsers: true,
        loadingUsersFailed: false,
      };
    },

    [MutationType.FETCH_USER_INFO_SUCCEED]: (
      state: UsersState,
      action: ActionTypes.FetchUserInfoSucceed,
    ) => {
      return {
        ...state,
        loadingUsers: false,
        loadingUsersFailed: false,
        usersById: {
          ...state.usersById,
          [action.payload.id]: {
            ...userInitialFields,
            data: action.payload.user,
            tasksFrom: action.payload.user.tasks_from,
            tasksTo: action.payload.user.tasks_to,
          },
        },
      };
    },

    // Remove user
    [MutationType.REMOVE_USER_FAIL]: (
      state: UsersState,
      { payload: { id } }: ActionTypes.RemoveUserFail,
    ) => ({
      ...state,
      removingUser: false,
      removingUserFailed: true,
    }),
    [MutationType.REMOVE_USER_SUCCEED]: (
      state: UsersState,
      { payload: { id } }: ActionTypes.RemoveUserSucceed,
    ) => ({
      ...state,
      usersById: Object.keys(state.usersById)
        .filter((id_: string) => id_ !== id.toString())
        .reduce(
          (acc: any, currVal: any) => ({
            ...acc,
            [currVal]: state.usersById[currVal],
          }),
          {},
        ),
      users: state.users.filter((a: number) => a !== id),
      removingUser: false,
      removingUserFailed: false,
    }),

    // Edit user
    [MutationType.EDIT_USER_START]: (state: UsersState, action: ActionTypes.EditUserStart) => ({
      ...state,
      usersById: {
        ...state.usersById,
        [action.payload.id]: {
          ..._.get(state.usersById, action.payload.id, userInitialFields),
          editing: true,
          editingFailed: false,
        },
      },
    }),

    [MutationType.EDIT_USER_FAIL]: (state: UsersState, action: ActionTypes.EditUserFail) => ({
      ...state,
      usersById: {
        ...state.usersById,
        [action.payload.id]: {
          ..._.get(state.usersById, action.payload.id, userInitialFields),
          editing: false,
          editingFailed: true,
        },
      },
    }),

    [MutationType.EDIT_USER_SUCCEED]: (state: UsersState, action: ActionTypes.EditUserSucceed) => {
      const prevUserState = _.get(state.usersById, action.payload.id, userInitialFields);
      const newUserDataField = ({
        ..._.get(prevUserState, "data", null),
        ...action.payload.user,
      } as any) as User;
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.payload.id]: {
            ...prevUserState,
            data: newUserDataField,
            editing: false,
            editingFailed: false,
          },
        },
      };
    },

    // Fetch full user info
    [MutationType.FETCH_FULL_USER_INFO_SUCCEED]: (
      state: UsersState,
      action: ActionTypes.FetchFullUserInfoSucceedAction,
    ) => ({
      ...state,
      usersById: {
        [action.payload.id]: {
          ...userInitialFields,
          data: { ...action.payload.user },
        },
      },
    }),

    // Change user status to admin
    [MutationType.CHANGE_USER_STATUS_TO_ADMIN]: (
      state: UsersState,
      action: { payload: { userId: PrimaryKey } },
    ) => {
      const oldUserData = _.get(state.usersById, action.payload.userId, userInitialFields);
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.payload.userId]: {
            ...oldUserData,
            data: {
              ...(oldUserData.data as any),
              admin: true,
            },
          },
        },
      };
    },

    // Change user status to user
    [MutationType.CHANGE_USER_STATUS_TO_USER]: (
      state: UsersState,
      action: { payload: { userId: PrimaryKey } },
    ) => {
      const oldUserData = _.get(state.usersById, action.payload.userId, userInitialFields);
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.payload.userId]: {
            ...oldUserData,
            data: {
              ...(oldUserData.data as any),
              admin: false,
            },
          },
        },
      };
    },

    [MutationType.EDIT_USER_PROJECTS]: (
      state: UsersState,
      action: { payload: { userId: PrimaryKey; projects: PrimaryKey[] } },
    ) => {
      const oldUserData = _.get(state.usersById, action.payload.userId, userInitialFields);
      return {
        ...state,
        usersById: {
          ...state.usersById,
          [action.payload.userId]: {
            ...oldUserData,
            data: {
              ...(oldUserData.data as any),
              projects: action.payload.projects,
            },
          },
        },
      };
    },
  },
  initialState,
);

export default reducer;
