import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import taskChatModule from "#/store/modules/taskChat";
import tasksModule from "#/store/modules/tasks";
import taskTasksModule from "#/store/modules/taskTasks";
import usersModule from "#/store/modules/users";
import {
  ChatMessage,
  CloseCurrentTaskChatWebSocket,
  CRMTask,
  FetchCurrentTaskChatMessages,
  SendTaskChatMessage,
  SetupCurrentTaskChatWebSocket,
  StoreRootState,
  User,
  UsersById,
} from "#/store/types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import AddChatMessage from "./components/AddChatMessage";
import ChatHeader from "./components/ChatHeader";
import ChatMessagesContainer from "./components/ChatMessagesContainer";

export interface ChatProps {
  task: CRMTask;
  chatMessages: ChatMessage[];
  currentUser: User | null;
  usersById: UsersById;
  fetchTaskChatMessages: FetchCurrentTaskChatMessages;
  setupTaskChatWebSocket: SetupCurrentTaskChatWebSocket;
  closeTaskChatWebSocket: CloseCurrentTaskChatWebSocket;
  sendTaskMessage: SendTaskChatMessage;
  allTaskMessagesAlreadyLoaded: boolean;
}

const Chat = (props: ChatProps) => {
  // Initial render actions
  useEffect(() => {
    props.fetchTaskChatMessages().then(() => {
      props.setupTaskChatWebSocket();
    });
    return () => {
      props.closeTaskChatWebSocket();
    };
  }, []);

  return (
    <div className="col-12 col-lg-4" style={{ height: "0%" }}>
      <div className="box box-portlet">
        <ChatHeader />
        <div className="box-body">
          <div className="chat">
            <ChatMessagesContainer
              currentUser={props.currentUser!}
              chatMessages={props.chatMessages}
              usersById={props.usersById}
              fetchTaskChatMessages={props.fetchTaskChatMessages}
              allTaskMessagesAlreadyLoaded={props.allTaskMessagesAlreadyLoaded}
            />
            <div className="bottom">
              <AddChatMessage
                currentUser={props.currentUser}
                sendTaskMessage={props.sendTaskMessage}
                task={props.task}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (store: StoreRootState) => ({
    usersById: usersModule.selectors.getUsersById(store, null),
    chatMessages: taskTasksModule.selectors.getCurrentTaskChatSortedByDate(store),
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
    allTaskMessagesAlreadyLoaded: taskTasksModule.selectors.areThereNoMoreCurrentTaskMessagesToLoad(
      store,
    ),
  }),
  {
    sendTaskMessage: tasksModule.actions.sendTaskMessage,
    ...getActionsFromModule(taskChatModule, [
      "fetchTaskChatMessages",
      "setupTaskChatWebSocket",
      "closeTaskChatWebSocket",
    ]),
  },
)(Chat);
