import { apiPost } from "#/api";
import { showSuccessToast } from "#/components/toasts";
import YandexFavicon from "#/components/YandexFavicon";
import { API_ROUTES } from "#/conf/api";
import { top50BaseProjectName, top50BaseProjectPath, top50ProjectName } from "#/util";
import { useModal } from "#/util/hooks";
import { constructTop50ProjectStatsPagePath } from "#/util/paths";
import _ from "lodash";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { useConfigurations } from "../../../../ConfigurationsSelect/useConfigurations";
import ProjectConfigurationModificationForm, {
  Top50ConfigurationCreate,
} from "../../../../ProjectConfigurationModificationForm";
import ProjectContext, { IProjectContext } from "../../../ProjectContext";

const PageHeader = () => {
  const projectContext = useContext(ProjectContext) as IProjectContext;
  const project = projectContext.project;
  const projectId = projectContext.projectId;
  const alias = top50ProjectName(project);

  const addConfigurationModal = useModal(false);

  const [addingConfiguration, setAddingConfiguration] = React.useState<boolean>(false);
  const onAddConfigurationSubmit = React.useCallback(
    async (c: Top50ConfigurationCreate) => {
      if (!project) {
        return;
      }
      setAddingConfiguration(true);
      try {
        const resp = await apiPost(API_ROUTES.TOP50.PROJECT_ADD_CONFIG(project!.pk), {
          region: c.region,
          search_engine: c.search_engine,
        });
        projectContext.fetchProject();
        addConfigurationModal.hide();
        showSuccessToast("Конфигурация была успешно добавлена");
      } finally {
        setAddingConfiguration(false);
      }
    },
    [project, projectContext.setProject],
  );

  const configsFilter = React.useCallback(c => _.includes(projectContext.supportedConfigs, c.pk), [
    projectContext.supportedConfigs,
  ]);
  const configs = useConfigurations({ includeById: true, filter: configsFilter }, [configsFilter]);

  return (
    <>
      <div className="box-header with-border flex-vertically-centered-x justify-content-between">
        <h4 className="box-title">
          <YandexFavicon name={alias} alt="project fav" />
          <span className="ml-2">
            <>
              {alias}
              {top50BaseProjectName(project) ? (
                <span className="ml-1">
                  (<Link to={top50BaseProjectPath(project)}>{top50BaseProjectName(project)}</Link>)
                </span>
              ) : (
                ""
              )}
            </>
          </span>
        </h4>
        <div>
          <Button
            className="btn btn-list"
            color="transparent"
            outline={true}
            onClick={addConfigurationModal.toggle}
          >
            <i className="fas fa-sliders-h" />
            <span className="ml-2">Конфигурации</span>
          </Button>
          <Link to={constructTop50ProjectStatsPagePath({ pk: projectId })} className="btn btn-list">
            <i className="fas fa-chart-line" />
            <span className="ml-2">Динамика запросов</span>
          </Link>
        </div>
      </div>

      <Modal
        isOpen={addConfigurationModal.shown}
        toggle={addConfigurationModal.toggle}
        className={`modal-dialog-centered`}
        contentClassName="alert alert-primary"
        size="lg"
      >
        <ModalHeader toggle={addConfigurationModal.toggle}>Добавить конфигурацию</ModalHeader>
        <ModalBody>
          <ProjectConfigurationModificationForm
            configs={configs.data}
            onAdd={onAddConfigurationSubmit}
            addingConfiguration={addingConfiguration}
          />
        </ModalBody>
      </Modal>
    </>
  );
};

export default PageHeader;
