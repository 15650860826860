enum MutationType {
  CREATE_USER_START = "users/CREATE_USER_START",
  CREATE_USER_FAIL = "users/CREATE_USER_FAIL",
  CREATE_USER_SUCCEED = "users/CREATE_USER_SUCCEED",

  REMOVE_USER_START = "users/REMOVE_USER_START",
  REMOVE_USER_SUCCEED = "users/REMOVE_USER_SUCCEED",
  REMOVE_USER_FAIL = "users/REMOVE_USER_FAIL",

  EDIT_USER_START = "users/EDIT_USER_START",
  EDIT_USER_SUCCEED = "users/EDIT_USER_SUCCEED",
  EDIT_USER_FAIL = "users/EDIT_USER_FAIL",

  FETCH_USERS_SUCCEED = "users/FETCH_USERS_SUCCEED",
  FETCH_USERS_FAIL = "users/FETCH_USERS_FAIL",
  FETCH_USERS_START = "users/FETCH_USERS_START",

  FETCH_USER_ROLES_SUCCEED = "users/FETCH_USER_ROLES_SUCCEED",

  FETCH_FULL_USER_INFO_SUCCEED = "users/FETCH_FULL_USER_INFO_SUCCEED",

  FETCH_USER_INFO_SUCCEED = "users/FETCH_USER_INFO_SUCCEED",
  FETCH_USER_INFO_FAIL = "users/FETCH_USER_INFO_FAIL",
  FETCH_USER_INFO_START = "users/FETCH_USER_INFO_START",

  CHANGE_USER_STATUS_TO_USER = "users/CHANGE_USER_STATUS_TO_USER",
  CHANGE_USER_STATUS_TO_ADMIN = "users/CHANGE_USER_STATUS_TO_ADMIN",

  EDIT_USER_PROJECTS = "users/EDIT_USER_PROJECTS",
}

export default MutationType;
