import DebouncedInput from "#/components/DebouncedInput";
import AuthContext, { IAuthContext } from "#/scenes/Main/AuthContext";
import { usePermissions } from "#/util/hooks";
import React from "react";
import Top50GlobalContext, { ITop50GlobalContext } from "../../../Top50GlobalContext";
import ProjectListContext, { IProjectListContext } from "../ProjectListContext";
import ProgressButton from "#/components/ProgressButton";

const HeaderActions = () => {
  const {
    startQueryDataCollection,
    toggleCreateProjectModal,
    toggleSettingsModal,
    filters,
    setFilters,
    queryDataCollectionStatus,
  } = React.useContext<IProjectListContext>(ProjectListContext);
  const { currentUser, isLoggedIn } = React.useContext<IAuthContext>(AuthContext);
  const onFiltersChange = React.useCallback((v: string) => {
    setFilters({ ...filters, name: v });
  }, []);
  const [canCreateProjects] = usePermissions(
    currentUser,
    [[[["CREATE", "PROJECTM", {}]], "none"]],
    [currentUser],
  );
  const { queryDataColProgress } = React.useContext<ITop50GlobalContext>(Top50GlobalContext);
  const progress = Number((queryDataColProgress * 100).toFixed(2));
  return (
    <div className="d-flex align-items-center">
      <DebouncedInput
        placeholder="Фильтр"
        className="form-control"
        onChange={onFiltersChange}
        ms={1000}
      />
      <ProgressButton
        progress={progress}
        progressMax={100}
        running={queryDataCollectionStatus}
        onClick={startQueryDataCollection}
        textIfNotRunning={"Запустить сбор данных"}
        textIfRunning={"Сбор данных в процессе..."}
      />
      {/* Add project */}
      {canCreateProjects && (
        <button type="button" className="btn btn-list ml-2" onClick={toggleCreateProjectModal}>
          <i className="fas fa-plus" />
          <span className="ml-2">Добавить проект</span>
        </button>
      )}
      {/* Settings */}
      <button type="button" className="btn btn-list ml-2" onClick={toggleSettingsModal}>
        <i className="fas fa-cog" />
        <span className="ml-2">Настройки</span>
      </button>
    </div>
  );
};

export default HeaderActions;
