import React, { useEffect, useState } from "react";
import ErrorMsg from "./components/ErrorMessage";
import Table from "./components/Table";
import {
  getSites,
  fetchSite,
  getSelection,
  removeAccounts,
  changeCurrentSite,
  getCurrentSite,
} from "#/store/modules/accounts";
import { AccountsSite } from "#/store/modules/accounts/types";
import { connect } from "react-redux";
import { DispatchProp, PrimaryKey, StoreRootState } from "#/store/types";
import YandexFavicon from "#/components/YandexFavicon";
import PaginationSlider from "./components/PaginationSlider";
import Menu from "./components/Menu";
import { extractHostname } from "#/util";

interface AccountsPageOwnProps {
  match: any;
}

interface AccountsPageConnectedProps {
  sites: Record<number, AccountsSite>;
  fetchSite: DispatchProp<typeof fetchSite>;
  selection: PrimaryKey[];
  removeAccounts: DispatchProp<typeof removeAccounts>;
  changeCurrentSite: DispatchProp<typeof changeCurrentSite>;
  site: AccountsSite | undefined;
}

type AccountsPageProps = AccountsPageConnectedProps & AccountsPageOwnProps;

const AccountsPage = (props: AccountsPageProps) => {
  useEffect(() => {
    props.changeCurrentSite(parseInt(props.match.params.pk));
  }, []);

  useEffect(() => {
    if (!props.site) {
      fetchSite(props.match);
    }
  }, [props.site]);

  return (
    <>
      {!props.site && <ErrorMsg>Нету сайта</ErrorMsg>}
      {props.site && !props.site.number_of_accounts && (
        <ErrorMsg>Отсутствуют аккаунты для данного сайта.</ErrorMsg>
      )}
      {props.site && props.site.number_of_accounts && (
        <>
          <div className="box-header with-border flex-vertically-centered-x justify-content-between px-4">
            <h4 className="box-title">
              <YandexFavicon name={extractHostname(props.site.url)} alt="" />
              <span className="ml-2">{props.site.name}</span>
            </h4>
            <Menu />
          </div>
          <Table />
          <PaginationSlider />
        </>
      )}
    </>
  );
};

export default connect(
  (state: StoreRootState) => ({
    sites: getSites(state, null),
    selection: getSelection(state, null),
    site: getCurrentSite(state, null),
  }),
  {
    fetchSite,
    removeAccounts,
    changeCurrentSite,
  },
)(AccountsPage);
