import { StoreRootState, Top50State } from "#/store/types";
import { createSelector } from "reselect";

type Props = {} | null;

const getTop50State = (store: StoreRootState, props: Props) => store.top50;
const getProps = (state: StoreRootState, props: Props) => props;

export const isQueryDataCollectionRunning = createSelector(
  [getTop50State],
  (ts: Top50State) => ts.queryDataCollectionRunning,
);

export const didQueryDataCollectionFail = createSelector(
  [getTop50State],
  (ts: Top50State) => ts.queryDataCollectionFailed,
);
