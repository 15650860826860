import store from "#/store";
import { getMetrikaToken } from "#/store/modules/metrika/top50";
import { StoreRootState } from "#/store/types";

const genericMetrikaReqHeaders = {
  "Content-Type": "application/json",
};

const constructMetrikaGetReqHeaders = (token: string) => ({
  ...genericMetrikaReqHeaders,
  ...(token ? { Authorization: `OAuth ${token}` } : {}),
});

const METRIKA_PRIMARY_ROUTE = (id: number) =>
  `https://api-metrika.yandex.net/stat/v1/data?id=${id}&`;

export const METRIKA_ROUTES = {
  YANDEX_QUERIES_BY_VISITS: (id: number) =>
    `${METRIKA_PRIMARY_ROUTE(
      id,
    )}dimensions=ym:s:lastSearchPhrase&metrics=ym:s:visits&filter=ym:s:lastSearchEngineRoot=='Yandex'&limit=5000`,
};

export const metrikaGet = (url: string) => {
  const authToken = getMetrikaToken(store.getState() as StoreRootState);
  return fetch(url, {
    method: "GET",
    headers: constructMetrikaGetReqHeaders(authToken),
  }).then(res => {
    if (!res.ok) {
      throw new Error(res.statusText);
    }
    return res.json();
  });
};
