import ProjectSelect from "#/components/ProjectSelect";
import projectsModule from "#/store/modules/projects";
import {
  CRMProject,
  MonitoringProject,
  MonitoringProjectData,
  OptionalPrimaryKey,
  PrimaryKey,
  StoreRootState,
  SubmitEvent,
} from "#/store/types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface TopvisorProjectModalConnectedProps {
  projects: CRMProject[];
}

interface TopvisorProjectModalOwnProps {
  onCreate: (payload: Partial<MonitoringProjectData>) => Promise<unknown>;
  isModalOpen: boolean;
  toggleModal: () => void;
  isCurrentlyCreatingProject: boolean;
}

type TopvisorProjectModalProps = TopvisorProjectModalConnectedProps & TopvisorProjectModalOwnProps;

const TopvisorProjectModal = (props: TopvisorProjectModalProps) => {
  const [name, setName] = useState("");
  const [linkedProjectId, setLinkedProjectId] = useState<PrimaryKey | null>(null);
  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    if (name.length <= 0) { return null; }
    if (linkedProjectId === null) { return null; }
    props.onCreate({ name, base_project: linkedProjectId }).then(() => {
      setName("");
      setLinkedProjectId(null);
    });
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={props.isModalOpen}
        toggle={props.toggleModal}
        className="modal-dialog-centered"
        contentClassName="alert alert-primary"
      >
        <ModalHeader toggle={props.toggleModal}>Добавить проект для мониторинга</ModalHeader>
        <form onSubmit={handleSubmit}>
          <ModalBody>
            <div className="form-group">
              <FormGroup>
                <label>Название проекта</label>
                <input
                  type="text"
                  value={name}
                  onChange={e => setName(e.target.value)}
                  className="form-control"
                  required={true}
                />
              </FormGroup>
              <FormGroup>
                <label>Связанный проект</label>
                <ProjectSelect
                  value={linkedProjectId}
                  projects={props.projects}
                  onChange={(id: OptionalPrimaryKey) => setLinkedProjectId(id)}
                  required={true}
                />
              </FormGroup>
            </div>
          </ModalBody>
          <ModalFooter>
            <button
              type="submit"
              className="btn btn-success"
              disabled={props.isCurrentlyCreatingProject}
            >
              {props.isCurrentlyCreatingProject ? "Подождите..." : "Создать"}
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </React.Fragment>
  );
};

export default connect((store: StoreRootState) => ({
  projects: projectsModule.selectors.getAllProjectsBare(store, null),
}))(TopvisorProjectModal);
