import {
  constructEmptyFailFetchActionReducer,
  constructEmptyStartFetchActionReducer,
  createReducer,
} from "#/store/helpers";
import {
  Action,
  EmptyAction,
  PaginationCursor,
  PrimaryKey,
  SessionState,
  UserNotificationDerived,
} from "#/store/types";
import { returnStorageItemOrTrue, setLocalStorageValues } from "#/util";
import _ from "lodash";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const loadStateFromLocalStorage = () => ({
  token: (() => {
    const l = localStorage.getItem("token");
    return l ? JSON.parse(l) : null;
  })(),
});

const initialState: SessionState = {
  currentUserId: null,
  authenticating: false,
  loginErrMsg: null,
  isTextNotificationActive: returnStorageItemOrTrue("textNotification"),
  isAudioNotificationActive: returnStorageItemOrTrue("audioNotification"),
  isActionRunning: false,
  fetching: false,
  fetchingCurrentUserInfo: true,
  fetchingCurrentUserInfoFailed: false,
  loadingUserNotifications: false,
  loadingUserNotificationsFailed: false,
  notifications: [],
  notificationsNext: null,
  notificationsPrev: null,
  notificationsUnread: 0,
  ...loadStateFromLocalStorage(),
};

// TODO: Improve typing
export const reducer = createReducer<SessionState, Action>(
  {
    // Logout
    [MutationType.USER_LOGOUT]: (state: SessionState, action: any) => ({
      ...initialState,
      token: null,
      fetchingCurrentUserInfo: false,
    }),

    // Authenticate
    [MutationType.AUTHENTICATE_START]: (state: SessionState, action: any) => ({
      ...state,
      authenticating: true,
    }),
    [MutationType.AUTHENTICATE_SUCCEED]: (state: SessionState, action: any) => ({
      ...state,
      authenticating: false,
      token: action.payload,
    }),
    [MutationType.AUTHENTICATE_FAIL]: (state: SessionState, action: any) => ({
      ...state,
      authenticating: false,
      loginErrMsg: action.payload.errMsg,
    }),

    [MutationType.LOGIN_CLEAR_ERROR]: (state: SessionState, action: any) => ({
      ...state,
      loginErrMsg: null,
    }),

    // Fetch current user info
    [MutationType.FETCH_CURRENT_USER_INFO_START]: (
      state: SessionState,
      action: ActionTypes.FetchCurrentUserInfoStartAction,
    ) => ({
      ...state,
      fetchingCurrentUserInfo: true,
      fetchingCurrentUserInfoFailed: false,
    }),
    [MutationType.FETCH_CURRENT_USER_INFO_FAIL]: (
      state: SessionState,
      action: ActionTypes.FetchCurrentUserInfoFailAction,
    ) => ({
      ...state,
      fetchingCurrentUserInfo: false,
      fetchingCurrentUserInfoFailed: true,
    }),

    [MutationType.FETCH_CURRENT_USER_INFO_SUCCEED]: (
      state: SessionState,
      action: ActionTypes.FetchCurrentUserInfoSucceedAction,
    ) => ({
      ...state,
      fetchingCurrentUserInfo: false,
      fetchingCurrentUserInfoFailed: false,
      currentUserId: action.payload.id,
    }),

    // Fetch current user notifications
    [MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_START]: constructEmptyStartFetchActionReducer<
      SessionState,
      EmptyAction
    >("loadingUserNotifications", "loadingUserNotificationsFailed"),
    [MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_FAIL]: constructEmptyFailFetchActionReducer<
      SessionState,
      EmptyAction
    >("loadingUserNotifications", "loadingUserNotificationsFailed"),
    [MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_SUCCEED]: (
      state: SessionState,
      action: any,
    ) => ({
      ...state,
      loadingUserNotifications: false,
      loadingUserNotificationsFailed: false,
    }),

    // Add notifications
    [MutationType.SET_UNREAD_NOTIFS_AMOUNT]: (
      state: SessionState,
      action: {
        payload: {
          unread: number;
        };
      },
    ) => ({
      ...state,
      notificationsUnread: action.payload.unread,
    }),

    // Add notifications
    [MutationType.ADD_NOTIFICATIONS]: (
      state: SessionState,
      action: {
        payload: {
          notifications: UserNotificationDerived[];
          next: PaginationCursor;
          prev: PaginationCursor;
        };
      },
    ) => ({
      ...state,
      notifications: [
        ...state.notifications,
        ...action.payload.notifications.filter(
          nn => !_.some(state.notifications, n => n.pk === nn.pk),
        ),
      ],
      notificationsNext: action.payload.next,
      notificationsPrev: action.payload.prev,
    }),

    [MutationType.MARK_NOTIFICATIONS_AS_READ]: (
      state: SessionState,
      action: { payload: { notifications: PrimaryKey[] } },
    ) => {
      // Notifications that aren't affected by this action
      const nonModifiedNotifications = state.notifications.filter(
        n => !_.includes(action.payload.notifications, n.pk),
      );
      // Notifications that are affected
      const modifiedNotifications = _.compact(
        action.payload.notifications.map(id => {
          const prev = _.find(state.notifications, n => n.pk === id);
          if (!prev) {
            return null;
          }
          return {
            ...prev,
            notification_model: { ...prev.notification_model, read: true },
          };
        }),
      );
      return {
        ...state,
        notifications: [...nonModifiedNotifications, ...modifiedNotifications],
      };
    },
  },
  initialState,
);

export const localStorageUpdate = (newState: SessionState) => {
  setLocalStorageValues({
    token: newState.token,
  });
};

export default reducer;
