import React, { useMemo, useEffect, useCallback } from "react";
import {
  getGoalsAsNestedStructure,
  TableGoal,
  selectGoals,
  getSelectedGoals,
} from "#/store/modules/metrika/goals";
import { connect } from "react-redux";
import { useTable, useExpanded, useRowSelect, useGlobalFilter, useFilters } from "react-table";
import _ from "lodash";
import DefaultFilter from "./components/DefaultFilter";
import GlobalFilter from "./components/GlobalFilter";
import { PrimaryKey, StoreRootState } from "#/store/types";

interface TableOwnProps {}

interface TableConnectedProps {
  goals: TableGoal[];
  selectGoals: any;
  selectedGoals: PrimaryKey[];
}

type TableProps = TableOwnProps & TableConnectedProps;
// @ts-ignore
const Table = (props: TableProps) => {
  const nestedFilterFunction = useCallback((_rows, id, filterValue) => {
    console.log(_rows, id, filterValue);
    return rows.filter(row => {
      return (
        // subRow not always has all fields
        (row.values[id[0]] && row.values[id[0]].includes(filterValue)) ||
        row.subRows.some(
          subRow => subRow.values[id[0]] && subRow.values[id[0]].includes(filterValue),
        )
      );
    });
  }, []);

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultFilter,
      filter: nestedFilterFunction,
    }),
    [],
  );

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        disableFilters: true,
        Cell: ({ row }) =>
          row.canExpand ? (
            <span {...row.getExpandedToggleProps()}>{row.isExpanded ? "👇" : "👉"}</span>
          ) : null,
      },
      {
        Header: () => null,
        id: "selection",
        disableFilters: true,
        Cell: _state => {
          const row = _state.row;
          console.log(state, row);
          return (
            <div>
              <input
                type="checkbox"
                onChange={e => {
                  if (!e.target.checked) {
                    props.selectGoals(props.selectedGoals.filter(x => x !== row.original.id));
                  } else {
                    props.selectGoals([...props.selectedGoals, row.original.id]);
                  }
                }}
                checked={props.selectedGoals.includes(row.original.id)}
              />
            </div>
          );
        },
      },
      {
        Header: "Сайт",
        accessor: "counterName",
        canFilter: true,
        id: "counterName",
      },

      { Header: "Name", accessor: "name" },
      {
        Header: "Conditions",
        columns: [
          {
            Header: "Тип",
            accessor: (x: TableGoal) => _.get(x, "conditions", []).map(cond => cond.type),
            id: "conditionType",
            Cell: ({ cell: { value } }) => {
              return (
                <table className="table table-bordered" style={{ margin: 0 }}>
                  {value.map(type => (
                    <tr>
                      <td>{type}</td>
                    </tr>
                  ))}
                </table>
              );
            },
          },
          {
            Header: "URL",
            accessor: (x: TableGoal) => _.get(x, "conditions", []).map(cond => cond.url),
            id: "conditionUrl",
            Cell: ({ cell: { value } }) => {
              return (
                <table className="table table-bordered" style={{ margin: 0, padding: "-0.55rem" }}>
                  {value.map(url => (
                    <tr>
                      <td>{url}</td>
                    </tr>
                  ))}
                </table>
              );
            },
          },
        ],
      },
      {
        Header: "Комментарий",
        accessor: x => x.comment || "",
        id: "comment",
      },
    ],
    [props.selectedGoals],
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // @ts-ignore
    selectedFlatRows,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    flatColumns,
    filteredFlatRows,
  } = useTable(
    {
      columns,
      data: props.goals,
      // @ts-ignore
      defaultColumn,
      getSubRows: row => {
        // console.log(row, foo);
        return _.get(row, "steps", []);
      },
      // @ts-ignore
    },
    useFilters,
    useRowSelect,
    useGlobalFilter,
    useExpanded,
  );

  return (
    <table className="table" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render("Header")}
                <div>{column.canFilter ? column.render("Filter") : null}</div>
              </th>
            ))}
          </tr>
        ))}
        <tr>
          <th colSpan={flatColumns.length}>
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
          </th>
        </tr>
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default connect(
  (state: StoreRootState) => ({
    goals: getGoalsAsNestedStructure(state),
    selectedGoals: getSelectedGoals(state),
  }),
  {
    selectGoals,
  },
)(Table);
