import { PrimaryKey, Top50Event, Top50Project, Top50QueryPage } from "#/store/types";
import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import { EMPTY_SELECTION_REGISTRY, SelectionRegistry } from "#/util/selectionRegistry";
import React from "react";
import { ProjectInfo, Top50ProjectGraph } from "../../types";

export interface ProjectFetchConfig {
  daysCount: number;
  fetchPositions: boolean;
  config: PrimaryKey | null;
}

const DEFAULT_DAYS_COUNT = 7;

export const DEFAULT_PFETCH_CONFIG: ProjectFetchConfig = {
  daysCount: DEFAULT_DAYS_COUNT,
  fetchPositions: false,
  config: null,
};

export interface IProjectContext {
  fetching: boolean;
  project: ProjectInfo | null;
  projectGroups: Top50Event[];
  projectQueries: Top50QueryPage[];
  graph: Top50ProjectGraph | null;
  graphLoading: boolean;
  groupSelection: SelectionRegistry;
  addGroupsToProject: (pk: PrimaryKey[], configPk: PrimaryKey) => Promise<boolean>;
  removeGroups: (eventIds: PrimaryKey[]) => Promise<boolean>;
  fetchGraph: (daysCount: number, config: PrimaryKey) => Promise<boolean>;
  projectQueriesById: Record<PrimaryKey, Top50QueryPage>;
  isGraphAvailable: boolean;
  projectLoading: boolean;
  graphDates: string[];
  projectId: PrimaryKey;
  fetchProject: (
    daysCount?: number,
    fetchPositions?: boolean,
    config?: PrimaryKey,
  ) => Promise<void>;
  editQuery: (pk: PrimaryKey, payload: Partial<Top50QueryPage>) => Promise<boolean>;
  massQueryPageUrlChange: (pks: PrimaryKey[], url: string) => Promise<boolean>;
  setProject: (p: Top50Project) => void;
  getQueryPage: (pk: PrimaryKey) => Top50QueryPage | null;
  supportedConfigs: PrimaryKey[];
  setProjectFetchConfig: (c: ProjectFetchConfig) => void;
  projectFetchConfig: ProjectFetchConfig | null;
}

const initialProjectContext: IProjectContext = {
  fetching: false,
  projectGroups: [],
  projectQueries: [],
  graph: { graph: [] },
  graphLoading: false,
  project: null,
  removeGroups: NON_REACHABLE_EMPTY_FUNCTION,
  groupSelection: EMPTY_SELECTION_REGISTRY,
  addGroupsToProject: NON_REACHABLE_EMPTY_FUNCTION,
  fetchGraph: NON_REACHABLE_EMPTY_FUNCTION,
  projectQueriesById: {},
  isGraphAvailable: false,
  projectLoading: false,
  graphDates: [],
  projectId: -1,
  fetchProject: NON_REACHABLE_EMPTY_FUNCTION,
  editQuery: NON_REACHABLE_EMPTY_FUNCTION,
  setProject: NON_REACHABLE_EMPTY_FUNCTION,
  getQueryPage: NON_REACHABLE_EMPTY_FUNCTION,
  massQueryPageUrlChange: NON_REACHABLE_EMPTY_FUNCTION,
  supportedConfigs: [],
  setProjectFetchConfig: NON_REACHABLE_EMPTY_FUNCTION,
  projectFetchConfig: DEFAULT_PFETCH_CONFIG,
};

const ProjectContext = React.createContext<IProjectContext>(initialProjectContext);

export default ProjectContext;
