import history from "#/history";
import accountsModule from "#/store/modules/accounts";
import eventSystemModule from "#/store/modules/eventSystem";
import invitationSystemModule from "#/store/modules/invitationSystem";
import keybindingSystemModule from "#/store/modules/keybindingSystem";
import mwsModule from "#/store/modules/mainWS";
import metrikaModule from "#/store/modules/metrika";
import projectModule from "#/store/modules/project";
import projectsModule from "#/store/modules/projects";
import projectTasksModule from "#/store/modules/projectTasks";
import searchModule from "#/store/modules/search";
import sessionModuleReducer, {
  localStorageUpdate as sessionModuleLSUpdateFunction,
} from "#/store/modules/session/reducer";
import SessionMutationType from "#/store/modules/session/mutationType";
import sessionTasksModule from "#/store/modules/sessionTasks";
import monitoringModule from "#/store/modules/sitemonitoring";
import taskModule from "#/store/modules/task";
import tasksModule from "#/store/modules/tasks";
import top50Module from "#/store/modules/top50";
import userModule from "#/store/modules/user";
import usersModule from "#/store/modules/users";
import { Action, StoreRootState } from "#/store/types";
import { connectRouter } from "connected-react-router";
import _ from "lodash";
import { combineReducers } from "redux";
import uiModule from "./ui";

export const rootLevelReducer = (state: StoreRootState, action: Action) => {
  // TODO: Separate this logout-cleanup into separate modules (do separate cleanup for each)
  // TODO: Probably implement onLogout handler for each of the modules.
  if (action.type === SessionMutationType.USER_LOGOUT) {
    localStorage.clear();
    state = ({} as any) as StoreRootState;
  }

  const topLevelReducer = combineReducers({
    router: connectRouter(history),
    qwerty: sessionModuleReducer,
    eventSystem: eventSystemModule.reducer,
    invitationSystem: invitationSystemModule.reducer,
    keybindingSystem: keybindingSystemModule.reducer,
    project: projectModule.reducer,
    projects: projectsModule.reducer,
    monitoring: monitoringModule.reducer,
    task: taskModule.reducer,
    tasks: tasksModule.reducer,
    ui: uiModule.reducer,
    users: usersModule.reducer,
    top50: top50Module.reducer,
    accounts: accountsModule.reducer,
    mainWS: mwsModule.reducer,
    sessionTasks: sessionTasksModule.reducer,
    projectTasks: projectTasksModule.reducer,
    user: userModule.reducer,
    metrika: metrikaModule.reducer,
    search: searchModule.reducer,
  });

  return topLevelReducer(state as any, action);
};

const localStorageUpdateFunctions: Record<string, (newState: any) => void> = {
  ui: uiModule.localStorageUpdate,
  qwerty: sessionModuleLSUpdateFunction,
};

export const updateLocalStorage = (newState: StoreRootState) => {
  _.keys(localStorageUpdateFunctions).forEach((key: any) => {
    const fn = _.get(localStorageUpdateFunctions, key, null);
    if (fn !== null) {
      const reducerState = _.get(newState, key, null);
      if (reducerState === null) {
        console.error(
          `Can't determine local storage updater function storage state view for ${key}, your updater function key probably does not match the name of the store part.`,
        );
      } else {
        fn(reducerState);
      }
    } else {
      console.error(`Your local storage updater function is null: ${key}`);
    }
  });
};
