import { SubmitEvent } from "#/store/types";
import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { ProxiesPayload } from "../../types";

interface CreateProxyConnectedProps {}

interface CreateProxyOwnProps {
  onCreate: (payload: ProxiesPayload) => Promise<unknown>;
  isOpen: boolean;
  toggleModal: () => void;
}

type CreateProxyProps = CreateProxyConnectedProps & CreateProxyOwnProps;

const CreateProxyModal = (props: CreateProxyProps) => {
  const [label, setlabel] = useState("");
  const [proxyString, setProxyString] = useState("");
  const [proxyType, setproxyType] = useState("1");
  const [finishDate, setFinishDate] = useState(new Date());
  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    const proxies = proxyString.trim().split("\n");
    props.onCreate({ label, proxies, type: proxyType, expiration_date: finishDate });
  };
  const { isOpen, toggleModal } = props;

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader toggle={toggleModal}>Добавить прокси</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group">
            <label>Название лэйбла</label>
            <input
              type="text"
              className="form-control"
              onChange={e => setlabel(e.target.value)}
              value={label}
              placeholder={"Лэйбл"}
              required={true}
            />
          </div>
          <div className="form-group">
            <label>Содержимое прокси</label>
            <textarea
              className="form-control"
              value={proxyString}
              onChange={e => setProxyString(e.target.value)}
              rows={4}
              required={true}
            />
          </div>
          <div className="form-group">
            <label>Тип прокси</label>
            <select
              value={proxyType}
              className="form-control"
              onChange={e => setproxyType(e.target.value)}
            >
              <option value="1">http</option>
              <option value="2">socks4</option>
              <option value="3">socks5</option>
            </select>
          </div>
          <div className="form-group">
            <label>Срок активности</label>
            <DatePicker
              className="form-control d-block"
              selected={finishDate}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect={true}
              timeFormat="HH:mm"
              onChange={d => setFinishDate(d as any)}
              timeCaption="Время"
              timeIntervals={30}
              locale="ru-RU"
              shouldCloseOnSelect={true}
              placeholderText="Выберите дату окончания"
              required={true}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success">
            Создать прокси
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateProxyModal;
