import React, { useCallback, useState } from "react";
import {
  Counter,
  selectToken,
  makeGetTokensForCounter,
  Token,
  getSelection,
} from "#/store/modules/metrika/goals";
import { connect } from "react-redux";
import Block from "./components/Block";
import { Collapse } from "reactstrap";
import { StoreRootState } from "#/store/types";

interface CounterButtonsConnectedProps {
  tokens: Token[];
  selection: Record<number, number>;
}

interface CounterButtonsOwnProps {
  counter: Counter;
}

type CounterButtonsProps = CounterButtonsConnectedProps & CounterButtonsOwnProps;

const CounterButtons = ({ tokens, selection, counter }: CounterButtonsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = useCallback(() => {
    setIsOpen(x => !x);
  }, []);
  return (
    <>
      <Block
        counterId={counter.id}
        tokenId={tokens[0].id}
        isSite={true}
        text={counter.name}
        isChecked={counter.id in selection}
        onChevronClick={toggle}
      />
      <Collapse isOpen={isOpen}>
        {tokens.map(token => (
          <Block
            counterId={counter.id}
            tokenId={token.id}
            isSite={false}
            text={token.value}
            isChecked={selection[counter.id] === token.id}
          />
        ))}
      </Collapse>
    </>
  );
};

export default connect(
  (state: StoreRootState, props) => ({
    tokens: makeGetTokensForCounter()(state, props),
    selection: getSelection(state),
  }),
  {
    selectToken,
  },
)(CounterButtons);
