import { APIError, apiGet, apiGetPaginated, apiPost, extractCursorFromURL } from "#/api";
import { API_ROUTES } from "#/conf/api";
import {
  AuthToken,
  GetState,
  PaginationCursor,
  PrimaryKey,
  StoreRootState,
  ThunkResult,
  UserNotification,
} from "#/store/types";
import _ from "lodash";
import { Dispatch } from "redux";
import { action } from "typesafe-actions";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";
import selectors from "./selectors";
import { PAG_CURSOR_END } from "#/util/constants";

// Logout
export const logout = (): ThunkResult<Promise<void>> => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    dispatch({ type: MutationType.USER_LOGOUT });
    localStorage.clear();
    resolve();
  });
};

// Sign in
export const loginFail = (errMsg: string) => ({
  type: MutationType.AUTHENTICATE_FAIL,
  payload: { errMsg },
});

export const login = (username: string, password: string): ThunkResult<Promise<AuthToken>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.AUTHENTICATE_START });
  return apiPost(API_ROUTES.MAIN.AUTHORIZE, { username, password })
    .then((json: { token: AuthToken }) => {
      // Successfull login
      dispatch({
        type: MutationType.AUTHENTICATE_SUCCEED,
        payload: json.token,
      });
      return json.token;
    })
    .catch((err: APIError) => {
      dispatch(loginFail(err.message));
      throw err;
    });
};

export const clearLoginErrorMessage = () => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.LOGIN_CLEAR_ERROR });
};

/////////////////////////////////////////////////
// Fetch current user info
/////////////////////////////////////////////////

export const fetchCurrentUserInfoSucceed = (id: PrimaryKey) =>
  action(MutationType.FETCH_CURRENT_USER_INFO_SUCCEED, {
    id,
  });

export const fetchCurrentUserInfoFail = (
  error: Error,
): ActionTypes.FetchCurrentUserInfoFailAction => ({
  type: MutationType.FETCH_CURRENT_USER_INFO_FAIL,
  payload: {
    error,
  },
});

export const fetchCurrentUserInfoStart = (): ActionTypes.FetchCurrentUserInfoStartAction => ({
  type: MutationType.FETCH_CURRENT_USER_INFO_START,
});

const addNotifications = (notifications: UserNotification[]) => ({
  type: MutationType.ADD_NOTIFICATIONS,
  payload: { notifications },
});

export const addNotification = (notification: UserNotification) => addNotifications([notification]);

export const incrementUnreadNotifsAmount = () => (dispatch, getState) => {
  const unread = selectors.getUnreadNotificationsAmount(getState(), null);
  dispatch({ type: MutationType.SET_UNREAD_NOTIFS_AMOUNT, payload: { unread: unread + 1 } });
};

export const fetchCurrentUserNotifications = (
  options: {
    onlyUnreadAmount: boolean;
  } = { onlyUnreadAmount: false },
): ThunkResult<Promise<void>> => async (dispatch: Dispatch, getState: GetState) => {
  const onlyUnreadAmount = _.get(options, "onlyUnreadAmount", false);
  const next = selectors.getNotificationsNextCursor(getState(), null);
  const prev = null;
  if (next === PAG_CURSOR_END) {
    return;
  }
  dispatch({ type: MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_START });
  try {
    const json: {
      notifications?: UserNotification[];
      next?: PaginationCursor;
      prev?: PaginationCursor;
      unread: number;
    } = await apiGetPaginated(API_ROUTES.CRMPLAN.USER_NOTIFICATIONS, prev, next, {
      only_unread_amount: onlyUnreadAmount,
    });
    dispatch({
      type: MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_SUCCEED,
    });
    dispatch({
      type: MutationType.SET_UNREAD_NOTIFS_AMOUNT,
      payload: {
        unread: json.unread,
      },
    });
    if (!onlyUnreadAmount) {
      const nextCursor = extractCursorFromURL(json.next!);
      const prevNextCursor = next;
      dispatch({
        type: MutationType.ADD_NOTIFICATIONS,
        payload: {
          notifications: json.notifications!,
          next: nextCursor
            ? nextCursor === prevNextCursor
              ? PAG_CURSOR_END
              : nextCursor
            : PAG_CURSOR_END,
          prev: extractCursorFromURL(json.prev!),
        },
      });
    }
  } catch (error) {
    dispatch({
      type: MutationType.FETCH_CURRENT_USER_NOTIFICATIONS_FAIL,
      error: error.message,
    });
    throw error;
  }
};

// Mark current user notifications as read (given the notification ids).
export const markNotificationsAsRead = (
  notificationIds: PrimaryKey[],
): ThunkResult<Promise<void>> => (dispatch: Dispatch, getState: GetState) => {
  return apiPost(API_ROUTES.CRMPLAN.MARK_USER_NOTIFICATIONS_AS_READ, {
    notifications: notificationIds,
  }).then((resp: { unread: number }) => {
    dispatch({
      type: MutationType.MARK_NOTIFICATIONS_AS_READ,
      payload: { notifications: notificationIds },
    });
    dispatch({
      type: MutationType.SET_UNREAD_NOTIFS_AMOUNT,
      payload: { unread: resp.unread },
    });
  });
};

const fetchCurrentUserFullSettings = (): ThunkResult<Promise<any>> => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  return apiGet(API_ROUTES.CRMPLAN.CURRENT_USER_SETTINGS);
};

export default {
  clearLoginErrorMessage,
  login,
  loginFail,
  logout,
  fetchCurrentUserNotifications,
  addNotification,
  markNotificationsAsRead,
  fetchCurrentUserFullSettings,
  incrementUnreadNotifsAmount,
};
