import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import React from "react";
import { initialFieldChangesRecord, IProjectContext, TabName } from "./util";

const initialProjectContext: IProjectContext = {
  lastCheckInfo: {
    fieldChanges: { ...initialFieldChangesRecord },
  },
  activeTab: TabName.CONTENT,
  toggleTab: NON_REACHABLE_EMPTY_FUNCTION,
  unsubscribe: NON_REACHABLE_EMPTY_FUNCTION,
  subscribe: NON_REACHABLE_EMPTY_FUNCTION,
  project: null,
  isCheckActive: false,
  checkProgress: -1,
  loading: false,
  runCheckProcess: NON_REACHABLE_EMPTY_FUNCTION,
  dates: [],
  fetchCheckedUrls: NON_REACHABLE_EMPTY_FUNCTION,
  checkedUrls: [],
  urls: [],
  addUrls: NON_REACHABLE_EMPTY_FUNCTION,
  toggleUrls: NON_REACHABLE_EMPTY_FUNCTION,
  addingUrls: false,
  removeUrls: NON_REACHABLE_EMPTY_FUNCTION,
  filters: "",
  setFilters: NON_REACHABLE_EMPTY_FUNCTION,
  importFromSitemap: NON_REACHABLE_EMPTY_FUNCTION,
};

export default React.createContext<IProjectContext>(initialProjectContext);
