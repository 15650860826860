import { createReducer } from "#/store/helpers";
import { EmptyAction, InvitationSystemState } from "#/store/types";
import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";

export const initialState: InvitationSystemState = {
  invitationLink: null,
  generating: false,
  generationFailed: false,
  registering: false,
  registrationFailed: false,
};

export const reducer = createReducer<InvitationSystemState, any>(
  {
    // Generation
    [MutationTypes.GENERATE_INVITATION_LINK_START]: (
      state: InvitationSystemState,
      { payload }: ActionTypes.GenerateInvitationLinkStartAction,
    ) => ({
      ...state,
      invitationLink: null,
      generating: true,
      generationFailed: false,
    }),

    [MutationTypes.GENERATE_INVITATION_LINK_FAIL]: (
      state: InvitationSystemState,
      { payload }: ActionTypes.GenerateInvitationLinkFailAction,
    ) => ({
      ...state,
      invitationLink: null,
      generating: false,
      generationFailed: true,
    }),

    [MutationTypes.GENERATE_INVITATION_LINK_SUCCEED]: (
      state: InvitationSystemState,
      { payload }: ActionTypes.GenerateInvitationLinkSucceedAction,
    ) => ({
      ...state,
      invitationLink: payload.invitationLink,
      generating: false,
      generationFailed: false,
    }),

    // Reset
    [MutationTypes.RESET_INVITATION_LINK]: (state: InvitationSystemState, action: EmptyAction) => ({
      ...state,
      invitationLink: "",
    }),

    // Registration
    [MutationTypes.REGISTER_VIA_INVITATION_START]: (
      state: InvitationSystemState,
      action: ActionTypes.RegisterViaInvitationStartAction,
    ) => ({
      ...state,
      registering: true,
      registrationFailed: false,
    }),

    [MutationTypes.REGISTER_VIA_INVITATION_SUCCEED]: (
      state: InvitationSystemState,
      action: ActionTypes.RegisterViaInvitationSucceedAction,
    ) => ({
      ...state,
      registering: false,
      registrationFailed: false,
    }),

    [MutationTypes.REGISTER_VIA_INVITATION_FAIL]: (
      state: InvitationSystemState,
      action: ActionTypes.RegisterViaInvitationFailAction,
    ) => ({
      ...state,
      registering: false,
      registrationFailed: true,
    }),
  },
  initialState,
);

export default reducer;
