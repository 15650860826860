import { canUser } from "#/permissions";
import AuthContext, { IAuthContext } from "#/scenes/Main/AuthContext";
import Graph from "#/scenes/Main/components/Services/components/TOP50/components/Graph";
import React from "react";
import { Link } from "react-router-dom";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import ProjectListContext, { IProjectListContext } from "../ProjectListContext";
import { ListProjectItemData } from "../types";
import _ from "lodash";
import {
  constructTop50ProjectStatsPagePath,
  constructtop50ProjectInfoPagePath,
} from "#/util/paths";

const ProjectItem = (props: { p: ListProjectItemData; rightBorder: boolean }) => {
  const [popoverOpen, setPopoverOpen] = React.useState<boolean>(false);
  const togglePopover = React.useCallback(() => {
    setPopoverOpen(!popoverOpen);
  }, [popoverOpen]);
  const { currentUser } = React.useContext<IAuthContext>(AuthContext);
  const { removeProject, loading } = React.useContext<IProjectListContext>(ProjectListContext);
  const { p, rightBorder } = props;
  const graphData = p.graph;
  const style = rightBorder ? { borderRight: "1px solid #e0e9ff" } : {};
  return (
    <div className="col-xl-6 col-12" style={style}>
      <div className="box topf-project-list-item">
        <div className="box-header flex-vertically-centered-x justify-content-between">
          <h6 className="box-title">
            <img src={"//favicon.yandex.net/favicon/" + p.title} alt="project fav" />
            <Link className="ml-2" to={"/services/top50/projects/" + p.pk}>
              {p.title}
            </Link>
          </h6>

          <div>
            <Link className="btn btn-list row-action" to={constructtop50ProjectInfoPagePath(p)}>
              Статистика
            </Link>
            <Link
              className="btn btn-list row-action ml-2"
              to={constructTop50ProjectStatsPagePath(p)}
            >
              Динамика запросов
            </Link>
            {canUser(currentUser, "DELETE", "PROJECTTOP50", { obj: p }) && (
              <button
                type="button"
                className="btn btn-list row-action ml-2"
                id={"popover-topf-project-remove-" + p.pk}
                onClick={togglePopover}
              >
                <i className="fas fa-times" />
              </button>
            )}
          </div>

          {canUser(currentUser, "DELETE", "PROJECTTOP50", { obj: p }) && (
            <UncontrolledPopover
              placement="top"
              isOpen={popoverOpen}
              target={"popover-topf-project-remove-" + p.pk}
              trigger="click"
            >
              <PopoverHeader>Подтверждение</PopoverHeader>
              <PopoverBody>
                <div>Вы действительно хотите безвозвратно удалить этот проект?</div>
              </PopoverBody>
              <PopoverBody className="popover-border-top">
                <div className="text-right">
                  <button type="button" className="btn btn-list row-action" onClick={togglePopover}>
                    Отмена
                  </button>
                  <button
                    type="button"
                    className="btn btn-list row-action"
                    onClick={() => {
                      setPopoverOpen(false);
                      removeProject(p.pk);
                    }}
                    disabled={loading}
                  >
                    Удалить
                  </button>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          )}
        </div>
        <div className="box-body">
          {graphData && graphData.graph && graphData.graph.length ? (
            <Graph graph={graphData} height={200} />
          ) : (
            <small>Предпросмотр недоступен</small>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectItem;
