import { apiDelete, apiGet, apiPost, apiPut } from "#/api";
import InputFilter from "#/components/DebouncedInput";
import { API_ROUTES } from "#/conf/api";
import { canUserCreateContentPlanProjects } from "#/permissions";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import sessionUsers from "#/store/modules/sessionUsers";
import { ContentPlanProject, StoreRootState, User } from "#/store/types";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CreateProjectModal from "../CreateProjectModal";
import Header from "../Header";
import ProjectItem from "../ProjectItem";
import "./index.scss";
import DebouncedInput from "#/components/DebouncedInput";

// TODO: Make this component to use the Redux store
// TODO: Use react hooks in this component

interface ProjectListState {
  projects: ContentPlanProject[];
  projectsCopy: ContentPlanProject[];
  loading: boolean;
  isCurrentlyCreatingProject: boolean;
  isCreateProjectModalOpen: boolean;
}

export interface ProjectListOwnProps {}

interface ProjectListConnectedProps {
  currentUser: User | null;
}

type ProjectListProps = ProjectListConnectedProps & ProjectListOwnProps;

class ProjectList extends React.Component<ProjectListProps, ProjectListState> {
  public state: ProjectListState = {
    projects: [],
    projectsCopy: [],
    loading: false,
    isCurrentlyCreatingProject: false,
    isCreateProjectModalOpen: false,
  };

  public componentDidMount() {
    this.setState({ loading: true }, () => {
      apiGet(API_ROUTES.CONTENTPLAN.PROJECTS)
        .then(projects => {
          this.setState({ projects, projectsCopy: projects, loading: false });
        })
        .catch((err: Error) => {
          console.error(err);
          this.setState({ loading: false });
        });
    });
  }

  public toggleCreateProjectModal = (e: any) => {
    this.setState(prevState => ({
      isCreateProjectModalOpen: !prevState.isCreateProjectModalOpen,
    }));
  };

  public onCreateProject = (payload: Partial<ContentPlanProject>): Promise<ContentPlanProject> =>
    new Promise((resolve, reject) =>
      this.setState({ isCurrentlyCreatingProject: true }, () => {
        apiPost(API_ROUTES.CONTENTPLAN.PROJECTS, payload)
          .then((project: ContentPlanProject) => {
            const nextProjects = [...this.state.projects, project];
            this.setState(
              {
                projects: nextProjects,
                projectsCopy: nextProjects,
                isCurrentlyCreatingProject: false,
              },
              () => resolve(project),
            );
          })
          .catch((err: Error) => {
            console.error(err);
            this.setState({ isCurrentlyCreatingProject: false });
          });
      }),
    );

  public onEditProject = (pk: number, payload: Partial<ContentPlanProject>, callback: any) => {
    apiPut(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(pk), payload)
      .then(response => {
        callback();
      })
      .catch((err: Error) => {
        console.error(err);
      });
  };

  public removeProject = (projectKey: number) => {
    apiDelete(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(projectKey)).then(response => {
      const nextProjects = this.state.projects.filter(
        (p: ContentPlanProject) => p.pk !== projectKey,
      );
      this.setState({
        ...this.state,
        projects: nextProjects,
        projectsCopy: nextProjects,
      });
    });
  };

  public onFilterChange = (value: string) => {
    const { projectsCopy } = this.state;

    const filtered = projectsCopy.filter((str: ContentPlanProject) => {
      return str.name.toLowerCase().includes(value.toLowerCase());
    });

    this.setState({ ...this.state, projects: filtered });
  };

  public render() {
    const { loading, isCreateProjectModalOpen } = this.state;

    const projectsToRender = this.state.projects.map((p, i) => (
      <ProjectItem
        p={p}
        key={i}
        removeProject={this.removeProject}
        editProject={this.onEditProject}
      />
    ));

    return (
      <div>
        <Header />

        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                  <h4 className="box-title">Проекты</h4>
                  <div>
                    <Link to="/services/contentplan/tech" className="btn btn-list">
                      <i className="fas fa-wrench" />
                      <span className="ml-2">Техническая страница</span>
                    </Link>
                    {canUserCreateContentPlanProjects(this.props.currentUser) && (
                      <button
                        type="button"
                        className="btn btn-list"
                        onClick={this.toggleCreateProjectModal}
                      >
                        <i className="fas fa-plus" />
                        <span className="ml-2">Добавить проект</span>
                      </button>
                    )}
                  </div>
                </div>
                <div className="box-body">
                  {loading && (
                    <div className="flex-vertically-centered py-4 text-center">
                      <img src={loader} className="m-auto" alt="Loading Spinner" />
                    </div>
                  )}
                  {!loading && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="minimum-column" />
                          <th>Название проекта</th>
                          <th>Описание проекта</th>
                          <th className="text-right">Активен</th>
                          <th className="float-right">
                            <DebouncedInput
                              className="fz-13 form-control-small form-control"
                              placeholder="Фильтр"
                              onChange={this.onFilterChange}
                              ms={1000}
                            />
                          </th>
                        </tr>
                      </thead>
                      <tbody>{projectsToRender}</tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        {canUserCreateContentPlanProjects(this.props.currentUser) && (
          <CreateProjectModal
            isOpen={isCreateProjectModalOpen}
            toggle={this.toggleCreateProjectModal}
            onCreateProject={this.onCreateProject}
            isCurrentlyCreatingProject={this.state.isCurrentlyCreatingProject}
          />
        )}
      </div>
    );
  }
}

export default connect((store: StoreRootState) => ({
  currentUser: sessionUsers.selectors.getCurrentUser(store, null),
}))(ProjectList);
