import React from "react";
import "./index.scss";

const TableCheckbox = (props: {
  checked: boolean;
  value?: string;
  disabled?: boolean;
  onChange: () => void;
}) => {
  const checkboxClassName = [
    "custom-checkbox",
    props.disabled ? "custom-checkbox__disabled" : "",
  ].join(" ");

  const iconClassName = ["fas", "fa-check", props.checked ? "icon-checked" : "icon-unchecked"].join(
    " ",
  );

  return (
    <span onClick={props.onChange} className={checkboxClassName}>
      <input
        type="checkbox"
        checked={props.checked}
        value={props.value}
        disabled={props.disabled}
        readOnly={true}
      />
      <span>
        <i className={iconClassName} />
      </span>
    </span>
  );
};

export default TableCheckbox;
