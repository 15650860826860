import { canUser } from "#/permissions";
import { CRMProject, User } from "#/store/types";
import { formatDateTime, getShortStringText, userFullName } from "#/util";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface ListItemConnectedProps {}

interface ListItemOwnProps {
  item: CRMProject;
  setActive: any;
  onSave: any;
  editing: boolean;
  creator: User | null;
  currentUser: User | null;
}

type ListItemProps = ListItemConnectedProps & ListItemOwnProps;

const ListItem = (props: ListItemProps) => {
  const [isEditMode, setEditMode] = useState(false);
  const [item, setItem] = useState({ ...props.item });

  useEffect(() => {
    setItem({ ...props.item });
  }, [props.item]);

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItem({
      ...item,
      name: e.target.value,
    });
  };

  const toggleEditMode = () => {
    setEditMode(!isEditMode);
  };

  const handleSubmit = () => {
    if (_.isEqual(item, props.item)) {
      toggleEditMode();
      return;
    }

    props.onSave(item, () => {
      toggleEditMode();
    });
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  const canUserEdit = canUser(props.currentUser, "EDIT", "PROJECTCRM", { obj: props.item });
  const canUserDelete = canUser(props.currentUser, "DELETE", "PROJECTCRM", { obj: props.item });
  const canUserPerformAnyAction = canUserEdit || canUserDelete;

  return (
    <tr>
      <td>{item.pk}</td>
      <td>
        {isEditMode ? (
          <>
            <input
              type="text"
              className="form-control"
              value={item.name}
              onKeyDown={onEnterPress}
              onChange={onTitleChange}
            />
          </>
        ) : (
          <Link to={"/project/" + item.pk}>{item.name}</Link>
        )}
      </td>
      <td>{getShortStringText(item.description)}</td>
      <td>
        <Link to={"/profile/" + item.creator}>{userFullName(props.creator)}</Link>
      </td>
      <td>Активен</td>
      <td>{formatDateTime(item.create_date)}</td>

      {/* Actions */}
      {canUserPerformAnyAction && (
        <td>
          {!isEditMode ? (
            <>
              {canUserEdit && (
                <button className="btn row-action btn-list" onClick={toggleEditMode}>
                  <i className="fas fa-edit" />
                </button>
              )}
              {canUserDelete && (
                <button
                  className="btn row-action rm btn-list"
                  onClick={() => props.setActive(item)}
                  disabled={props.editing}
                >
                  <i className="fas fa-trash" />
                </button>
              )}
            </>
          ) : (
            <>
              <button
                type="submit"
                className="btn btn-info row-action"
                onClick={handleSubmit}
                disabled={props.editing}
              >
                <i className="fas fa-check" />
              </button>
              <button className="btn btn-danger row-action rm" onClick={toggleEditMode}>
                <i className="fas fa-times" />
              </button>
            </>
          )}
        </td>
      )}
    </tr>
  );
};

export default ListItem;
