import { canUser, canUserPerformAnyOf, mdAny } from "#/permissions";
import { MonitoringProject, PrimaryKey, User } from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import _ from "lodash";
import "moment-timezone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { Popover, PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";

// CLEANUP: Use react hooks
// CLEANUP: Translate into typescript

const ListItem = (props: {
  item: MonitoringProject;
  onRemove: (pk: PrimaryKey) => Promise<unknown>;
  currentUser: User | null;
}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const itemData = props.item.data!;
  const itemName = _.get(itemData, "name", "");
  const domain = itemName.split("//")[1];
  const itemID = _.get(itemData, "pk", 0);
  const lastCheckedDate = _.get(itemData, "last_checked", null);
  const lastCheckedAmountOfUrls = _.get(itemData, "last_amount_of_urls_checked", null);
  const lastAmountOfTitleChanged = _.get(itemData, "last_amount_of_title_changed", null);
  const lastAmountOfH1Changed = _.get(itemData, "last_amount_of_h1_changed", null);
  const lastAmountOfDescriptionChanged = _.get(
    itemData,
    "last_amount_of_description_changed",
    null,
  );
  const lastAmountOfCodeChanged = _.get(itemData, "last_amount_of_code_changed", null);
  const lastAmountOfContentChanged = _.get(itemData, "last_amount_of_content_changed", null);

  const onRemoveButtonPress = () =>
    props
      .onRemove(props.item.data!.pk)
      .then(() => setPopoverOpen(false))
      .catch(handleDispatchErrorAndDisplayToast);

  const popoverRemoveButtonID = `popover-remove-${itemID}`;
  const popoverRemoveContainerID = `popover-row-${itemID}`;

  return (
    <tr>
      <td>
        <Link to={"/services/topvisor/" + itemID + "/"}>
          <img src={"https://favicon.yandex.net/favicon/" + domain} alt="fav" />
          <span className="ml-2">{itemName}</span>
        </Link>
      </td>
      <td>
        {lastCheckedDate === null ? (
          "N/A"
        ) : (
          <Moment format="yyyy/MM/DD h:mm:ss a">{lastCheckedDate}</Moment>
        )}
      </td>
      <td>{lastCheckedAmountOfUrls === null ? "N/A" : lastCheckedAmountOfUrls}</td>
      <td>{lastAmountOfTitleChanged === null ? "N/A" : lastAmountOfTitleChanged}</td>
      <td>{lastAmountOfH1Changed === null ? "N/A" : lastAmountOfH1Changed}</td>
      <td>{lastAmountOfDescriptionChanged === null ? "N/A" : lastAmountOfDescriptionChanged}</td>
      <td>{lastAmountOfContentChanged === null ? "N/A" : lastAmountOfContentChanged}</td>
      <td>{lastAmountOfCodeChanged === null ? "N/A" : lastAmountOfCodeChanged}</td>
      {canUserPerformAnyOf(props.currentUser, [
        [
          "DELETE",
          "PROJECTM",
          {
            obj: props.item,
          },
        ],
      ]) && (
        <td className="text-right" id={popoverRemoveContainerID}>
          {canUser(props.currentUser, "DELETE", "PROJECTM", { obj: props.item }) && (
            <>
              <button
                className="btn btn-list row-action rm"
                disabled={props.item.loading}
                id={popoverRemoveButtonID}
                onClick={togglePopover}
              >
                Удалить
              </button>

              <Popover
                placement="top"
                isOpen={popoverOpen}
                target={popoverRemoveButtonID}
                trigger="focus"
                container={popoverRemoveContainerID}
              >
                <PopoverHeader>Подтверждение</PopoverHeader>
                <PopoverBody>
                  <div>Вы действительно хотите безвозвратно удалить этот элемент?</div>
                </PopoverBody>
                <PopoverBody className="popover-border-top">
                  <div className="text-right">
                    <button
                      type="button"
                      className="btn btn-list row-action"
                      onClick={togglePopover}
                    >
                      Отмена
                    </button>
                    <button
                      type="button"
                      className="btn btn-list row-action"
                      onClick={onRemoveButtonPress}
                      disabled={props.item.loading}
                    >
                      Удалить
                    </button>
                  </div>
                </PopoverBody>
              </Popover>
            </>
          )}
        </td>
      )}
    </tr>
  );
};

export default ListItem;
