import React from "react";
import { User } from "#/store/types";

export interface IAuthContext {
  currentUser: User | null;
  isLoggedIn: boolean;
}

const AuthContext = React.createContext<IAuthContext>({
  currentUser: null,
  isLoggedIn: false,
});

export default AuthContext;
