import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import projectsModule from "#/store/modules/projects";
import sessionUsersModule from "#/store/modules/sessionUsers";
import tasksModule from "#/store/modules/tasks";
import { becomeTaskExecutor, editTask, removeTask } from "#/store/modules/tasks/actions";
import usersModule from "#/store/modules/users";
import {
  CompleteTaskData,
  CRMTask,
  DispatchProp,
  PrimaryKey,
  ProjectsById,
  StoreRootState,
  TasksById,
  User,
  UsersById,
} from "#/store/types";
import {
  CODE_NON_REACHABLE,
  compareStringsAlphabetically,
  compareTwoAPIFormatDates,
  compareTwoNumbers,
  falseIfUndefinedElseBoolean,
  getChecklistProgress,
  getListStyle,
  getTaskExecutor,
  getTaskManager,
  getTaskProject,
  projectName,
  reorder,
  trueIfUndefinedElseBoolean,
  userFullName,
} from "#/util";
import _ from "lodash";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ExpandRow from "./components/ExpandRow";
import TableHeadRow from "./components/TableHeadRow";
import TableRow from "./components/TableRow";
import "./index.scss";

export enum SortTasksBy {
  NAME = 0,
  STATUS = 1,
  PROJECT_NAME = 2,
  MANAGER_NAME = 3,
  EXECUTOR_NAME = 4,
  PROGRESS = 5,
  CREATION_DATE = 6,
  COMPLETION_DATE = 7,
  PRIORITY = 8,
  MANAGER_MINUTES_TO_COMPLETE = 9
}

// Get corresponding sorting function from sorting a given sorting method
const constructCorrespondingSortingFn = (
  sortBy: SortTasksBy,
  usersById: UsersById,
  projectsById: ProjectsById,
  tasksById: TasksById,
): ((a: CRMTask, b: CRMTask) => number) => {
  switch (sortBy) {
    case SortTasksBy.NAME:
      return (a, b) => compareStringsAlphabetically(a.name, b.name);
    case SortTasksBy.COMPLETION_DATE:
      return (a, b) => compareTwoAPIFormatDates(a.completed_date, b.completed_date);
    case SortTasksBy.CREATION_DATE:
      return (a, b) => compareTwoAPIFormatDates(a.create_date, b.create_date);
    case SortTasksBy.EXECUTOR_NAME:
      return (a, b) =>
        compareStringsAlphabetically(
          userFullName(getTaskExecutor(a, usersById)),
          userFullName(getTaskExecutor(b, usersById)),
        );
    case SortTasksBy.MANAGER_NAME:
      return (a, b) =>
        compareStringsAlphabetically(
          userFullName(getTaskManager(a, usersById)),
          userFullName(getTaskManager(b, usersById)),
        );
    case SortTasksBy.STATUS:
      return (a, b) => compareTwoNumbers(a.status, b.status);
    case SortTasksBy.PROJECT_NAME:
      return (a, b) =>
        compareStringsAlphabetically(
          projectName(getTaskProject(a, projectsById)),
          projectName(getTaskProject(b, projectsById)),
        );
    case SortTasksBy.PRIORITY:
      return (a, b) => compareTwoNumbers(_.toNumber(a.priority), _.toNumber(b.priority));
    case SortTasksBy.PROGRESS:
      return (a, b) => {
        const ap = getChecklistProgress(_.get(_.get(tasksById, a.pk, {}), "checklists", []));
        const bp = getChecklistProgress(_.get(_.get(tasksById, b.pk, {}), "checklists", []));
        return compareTwoNumbers(ap, bp);
      };
    default:
      CODE_NON_REACHABLE();
      return (a, b) => 0;
  }
};

interface TaskTableConnectedStateProps {
  users: UsersById;
  projects: ProjectsById;
  currentUser: User | null;
  tasksById: TasksById;
}

type TaskTableConnectedProps = TaskTableConnectedStateProps;

interface TaskTableOwnProps {
  // Data
  tasks: CompleteTaskData[];
  sortByPreselected?: SortTasksBy;
  sortingDirectionPreselected?: boolean;

  // Flags
  direction: boolean;
  isCurrentlyDeletingTask: boolean;
  showProjectColumn?: boolean;
  showManagerColumn?: boolean;
  showExecutorColumn?: boolean;
  showBecomeExecutorColumn?: boolean;

  // Functions
  changeItem: DispatchProp<typeof editTask>;
  removeItem: DispatchProp<typeof removeTask>;
  becomeTaskExecutor?: DispatchProp<typeof becomeTaskExecutor>;
}

type TaskTableProps = TaskTableConnectedProps & TaskTableOwnProps;

const TaskTable = (props: TaskTableProps) => {
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  // Param options
  const showProjectColumn = trueIfUndefinedElseBoolean(props.showProjectColumn);
  const showManagerColumn = trueIfUndefinedElseBoolean(props.showManagerColumn);
  const showExecutorColumn = trueIfUndefinedElseBoolean(props.showExecutorColumn);
  const showBecomeExecutorColumn = falseIfUndefinedElseBoolean(props.showBecomeExecutorColumn);

  // Editable items container
  const [items, setItems] = useState<CompleteTaskData[]>(props.tasks);

  // Current sorting state
  const [sortBy, setSortBy] = useState(
    props.sortByPreselected !== undefined ? props.sortByPreselected : SortTasksBy.NAME,
  );
  useEffect(() => {
    setSortBy(props.sortByPreselected !== undefined ? props.sortByPreselected : SortTasksBy.NAME);
  }, [props.sortByPreselected]);
  const [sortingDirection, setSortingDirection] = useState<boolean>(
    falseIfUndefinedElseBoolean(props.sortingDirectionPreselected),
  );
  useEffect(() => {
    setSortingDirection(falseIfUndefinedElseBoolean(props.sortingDirectionPreselected));
  }, [props.sortingDirectionPreselected]);

  // Expanded rows storage
  const [expandedRows, setExpandedRows] = useState<Record<PrimaryKey, boolean>>({});
  const toggleExpandedStatusOfTask = useCallback(
    (pk: PrimaryKey) => setExpandedRows({ ...expandedRows, [pk]: !_.get(expandedRows, pk, false) }),
    [expandedRows, setExpandedRows],
  );

  // On external prop props.tasks change
  useEffect(() => {
    const sm = constructCorrespondingSortingFn(
      sortBy,
      props.users,
      props.projects,
      props.tasksById,
    );
    setItems(
      props.tasks.sort((a, b) => {
        const sd = sortingDirection ? 1 : -1;
        return sd * sm(a.data, b.data);
      }),
    );
  }, [props.tasks, sortBy, sortingDirection, props.users, props.projects, props.tasksById]);

  // Drag implementation
  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) { return; }
    setItems(reorder(items, source.index, destination.index));
  };

  // On column sort toggle
  const onSortToggle = (s: SortTasksBy) => {
    // Close all expanded rows
    setExpandedRows({});

    // If already sorting this way
    if (sortBy === s) {
      // Only change the sorting direction
      setSortingDirection(!sortingDirection);
    } else {
      // Update sorting method
      setSortBy(s);
      setSortingDirection(true);
    }
  };

  // Bug fix
  useLayoutEffect(() => {
    forceUpdate();
  }, [sortBy, sortingDirection]);

  const itemRows = items.map((task_: CompleteTaskData, idx) => {
    // Task data
    const task = task_.data;
    const taskProject = _.get(props.projects, task.project, null);
    const taskFromUser = _.get(props.users, task.task_from, null);
    const taskToUser = getTaskExecutor(task, props.users);
    const isCurrentUserManager = props.currentUser!.pk === task.task_from;
    const isCurrentUserExecutor = props.currentUser!.pk === task.task_to;

    // Permissions
    const permissionToExpandRow = true;

    const row = (
      <Draggable key={`dg-${task.pk}`} draggableId={task.pk.toString()} index={idx}>
        {(provided, snapshot) => {
          return (
            <TableRow
              /* Data */
              key={task.pk}
              item={task}
              changeTask={props.changeItem}
              removeTask={props.removeItem}
              project={taskProject}
              taskFromUser={taskFromUser ? taskFromUser.data : null}
              taskToUser={taskToUser}
              itemChecklists={task_.checklists}
              onClick={() => toggleExpandedStatusOfTask(task.pk)}
              becomeTaskExecutor={props.becomeTaskExecutor}
              /* Flags */
              isCurrentlyDeletingTask={props.isCurrentlyDeletingTask}
              isCurrentlyEditingTask={task_.editing}
              selected={false}
              /* Options */
              showSelect={permissionToExpandRow}
              showExecutorColumn={showExecutorColumn}
              showManagerColumn={showManagerColumn}
              showProjectColumn={showProjectColumn}
              showBecomeExecutorColumn={showBecomeExecutorColumn}
              /* Draggable */
              provided={provided}
              snapshot={snapshot}
            />
          );
        }}
      </Draggable>
    );

    const expandedRow = (() => {
      if (permissionToExpandRow) {
        return (
          <ExpandRow
            item={task_}
            key={"row-expanded-" + task.pk}
            changeTask={props.changeItem}
            isManager={isCurrentUserManager}
            isPerformer={isCurrentUserExecutor}
            taskFromUser={taskFromUser ? taskFromUser.data : null}
            isOpen={_.get(expandedRows, task.pk, false)}
          />
        );
      } else {
        return null;
      }
    })();

    return [row, expandedRow];
  });

  return (
    <TransitionGroup>
      <CSSTransition
        classNames={props.direction ? "mask" : "mask1"}
        appear={true}
        timeout={{
          enter: 0,
          exit: 300,
        }}
      >
        {itemRows.length > 0 ? (
          <table className="table">
            <thead>
              <TableHeadRow
                showProjectColumn={showProjectColumn}
                showExecutorColumn={showExecutorColumn}
                showManagerColumn={showManagerColumn}
                onSortToggle={onSortToggle}
                sortingBy={sortBy}
                sortingDirection={sortingDirection}
              />
            </thead>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided: any, snapshot: any) => (
                  <tbody
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    className="accordion"
                    id="accordion-table"
                  >
                    {itemRows}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </table>
        ) : (
          <div className="text-center">Не найдено ни одной задачи.</div>
        )}
      </CSSTransition>
    </TransitionGroup>
  );
};

const mapStateToProps = (store: StoreRootState): TaskTableConnectedStateProps => ({
  users: usersModule.selectors.getUsersById(store, null),
  projects: projectsModule.selectors.getProjectsById(store, null),
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  tasksById: tasksModule.selectors.getTasksById(store),
});

export default connect(
  mapStateToProps,
  {},
)(withAuthentication<TaskTableProps>(UserAuthenticationStatus.AUTHENTICATED)(TaskTable));
