import Page from "#/components/Page";
import React from "react";
import { Route, Switch } from "react-router";
import Changelog from "./components/Changelog";
import Index from "./components/Index";
import ListProfiles from "./components/ListProfiles";
import ListProjects from "./components/ListProjects";
import NotFoundPage from "./components/NotFoundPage";
import Profile from "./components/Profile";
import Project from "./components/Project";
import SearchPage from "./components/SearchPage";
import SettingsPage from "./components/SettingsPage";
import TaskPage from "./components/TaskPage";

const CRM = () => (
  <>
    <Switch>
      <Route
        path="/"
        exact={true}
        render={(props: any) => (
          <Page title="CRMPlan">
            <Index {...props} />
          </Page>
        )}
      />

      <Route
        path="/profile/:id"
        render={(props: any) => (
          <Page key={props.match.params.id} title="Профиль">
            <Profile {...props} />
          </Page>
        )}
      />

      <Route
        path="/project/:id"
        render={(props: any) => (
          <Page key={props.match.params.id} title="Проект">
            <Project {...props} />
          </Page>
        )}
      />

      <Route
        path="/profiles/"
        render={(props: any) => (
          <Page title="Список профилей">
            <ListProfiles {...props} />
          </Page>
        )}
      />

      <Route
        path="/projects/"
        render={(props: any) => (
          <Page title="Список проектов">
            <ListProjects {...props} />
          </Page>
        )}
      />

      <Route
        path="/settings/"
        render={(props: any) => (
          <Page title="Мои настройки">
            <SettingsPage {...props} />
          </Page>
        )}
      />

      <Route
        path="/changelog/"
        render={(props: any) => (
          <Page title="Список изменений">
            <Changelog {...props} />
          </Page>
        )}
      />

      <Route
        path="/task/:id"
        render={(props: any) => (
          <Page title="Задача">
            <TaskPage {...props} />
          </Page>
        )}
      />

      <Route
        path="/search/"
        render={(props: any) => (
          <Page title="Поиск">
            <SearchPage {...props} />
          </Page>
        )}
      />

      <Route
        path="*"
        render={(props: any) => (
          <Page title="Не найдено">
            <NotFoundPage {...props} />
          </Page>
        )}
      />
    </Switch>
  </>
);

export default CRM;
