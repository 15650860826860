import * as sessionModule from "#/store/modules/session";
import tasksModule from "#/store/modules/tasks";
import { PrimaryKey } from "#/store/types";
import { Dispatch } from "redux";

export const fetchTasksToCurrentUser = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    const currentUserId = sessionModule.selectors.getCurrentUserID(getState(), null);
    if (currentUserId === null) { reject(new Error("Not authenticated")); }
    dispatch(tasksModule.actions.fetchUserTaskList((currentUserId as any) as PrimaryKey) as any)
      .then(resolve)
      .catch(reject);
  });
};

export const fetchTasksFromCurrentUser = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    const currentUserId = sessionModule.selectors.getCurrentUserID(getState(), null);
    if (currentUserId === null) { reject(new Error("Not authenticated")); }
    dispatch(tasksModule.actions.fetchUserTaskFromList((currentUserId as any) as PrimaryKey) as any)
      .then(resolve)
      .catch(reject);
  });
};

export default {
  fetchTasksToCurrentUser,
  fetchTasksFromCurrentUser,
};
