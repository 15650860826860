import { Dispatch } from "redux";
import MutationType from "./mutationType";

// Notifications status
export const toggleTextNotification = () => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.TOGGLE_TEXT_NOTIFICATION });
};
export const toggleAudioNotification = () => (dispatch: Dispatch, getState: any) => {
  dispatch({
    type: MutationType.TOGGLE_AUDIO_NOTIFICATION,
  });
};

// Actions list shown
export const toggleActionsListShown = () => (dispatch: Dispatch, getState: any) => {
  dispatch({
    type: MutationType.TOGGLE_ACTIONS_LIST_SHOWN,
  });
};

// Directions list shown
export const toggleDirectionsListShown = () => (dispatch: Dispatch, getState: any) => {
  dispatch({
    type: MutationType.TOGGLE_DIR_LIST_SHOWN,
  });
};
