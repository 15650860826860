import * as actions from "./actions";
import reducer from "./reducer";
import * as selectors from "./selectors";
import * as actionTypes from "./actionTypes";
export * from "./actions";
export * from "./reducer";
export * from "./selectors";

export { reducer, selectors, actions };

export default {
  actions,
  reducer,
  selectors,
  actionTypes,
};
