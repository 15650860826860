import { createReducer } from "#/store/helpers";
import {
  MonitoringProject,
  MonitoringProjectData,
  MonitoringState,
  PrimaryKey,
} from "#/store/types";
import _ from "lodash";
import { Action } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const defaultMonitoringProjectFields: MonitoringProject = {
  data: undefined,
  loading: false,
  loadingFailed: false,
  checkedurls_dates: [],
  urlsById: {},
  removingUrls: false,
  removingUrlsFailed: false,
  urls: [],
  checkProgress: -1,
  addingUrls: false,
  addingUrlsFailed: false,
  scriptRunning: false,
  checkProgressFailed: false,
  changingFilters: false,
  changingFiltersFailed: false,
};

const initialState: MonitoringState = {
  projectsById: {},
  projects: [],
  loading: false,
  loadingFailed: false,
  creatingProject: false,
  projectCreationFailed: false,
  ws: null,
  deletingProject: false,
  deletingProjectFailed: false,
};

const getMonitoringProject = (
  state: MonitoringState,
  id: PrimaryKey,
  or_: MonitoringProject = defaultMonitoringProjectFields,
) => _.get(state.projectsById, id, or_);

const getMonitoringProjectData = (state: MonitoringState, id: PrimaryKey, or_: any = null) =>
  _.get(getMonitoringProject(state, id), "data", or_);

const setMonitoringProject = (
  prevState: MonitoringState,
  id: PrimaryKey,
  newData: Partial<MonitoringProject>,
) => {
  const prevProjState = _.get(prevState.projectsById, id, defaultMonitoringProjectFields);
  return {
    ...prevState,
    projectsById: {
      ...prevState.projectsById,
      [id]: {
        ...prevProjState,
        ...newData,
      },
    },
  };
};

const setMonitoringProjectData = (
  prevState: MonitoringState,
  id: PrimaryKey,
  newProjData: Partial<MonitoringProjectData>,
) =>
  setMonitoringProject(prevState, id, {
    ...getMonitoringProject(prevState, id),
    data: { ...getMonitoringProjectData(prevState, id, {}), ...newProjData },
  });

export const reducer = createReducer<MonitoringState, Action>(
  {
    /////////////////////////////////
    // Fetch monitoring projects
    /////////////////////////////////

    [MutationType.FETCH_PROJECTS_START]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectsStartAction,
    ) => ({
      ...state,
      loading: true,
      loadingFailed: false,
    }),

    [MutationType.FETCH_PROJECTS_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectFailAction,
    ) => ({
      ...state,
      loading: false,
      loadingFailed: true,
    }),

    [MutationType.FETCH_PROJECTS_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectsSucceedAction,
    ) => {
      const { payload } = action;
      const { projects } = payload;
      return {
        ...state,
        loading: false,
        projectsById: {
          ...state.projectsById,
          ..._.chain(
            projects.map((proj: MonitoringProjectData) => ({
              ...defaultMonitoringProjectFields,
              data: { ...proj },
            })),
          )
            .keyBy("data.pk")
            .value(),
        },
        projects: projects.map((a: MonitoringProjectData) => a.pk),
        loadingFailed: false,
      };
    },

    /////////////////////////////////
    // Fetch monitoring project
    /////////////////////////////////

    [MutationType.FETCH_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectStartAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            loading: true,
            loadingFailed: false,
          },
        },
      };
    },

    [MutationType.FETCH_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectSucceedAction,
    ) => {
      const { payload } = action;
      const { projID, project, urls, checkedurls_dates } = payload;
      const newUrls = urls;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            data: {
              ..._.get(_.get(state.projectsById, projID, {}), "data", {}),
              ...project,
            },
            checkedurls_dates,
            urlsById: _.chain(newUrls)
              .keyBy("pk")
              .value(),
            urls: newUrls.map((u: any) => u.pk),
            loading: false,
            loadingFailed: false,
            checkProgress: -1,
            scriptRunning: project.script_running,
          },
        },
        projects: Array.from(new Set([...state.projects, projID])),
      };
    },

    [MutationType.FETCH_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.FetchMonitoringProjectFailAction,
    ) => {
      const {
        payload: { projID, error },
      } = action;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            data: undefined,
            loading: false,
            loadingFailed: true,
            loadingFailedMessage: error,
          },
        },
        projects: state.projects.filter(a => a !== projID),
      };
    },

    /////////////////////////////////
    // Fetch monitoring project checked urls
    /////////////////////////////////

    [MutationType.FETCH_CHECKEDURLS_FOR_PROJ_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.FetchCheckedUrlsForProjectSucceedAction,
    ) => {
      const {
        payload: { projID, checkedUrls },
      } = action;
      const oldProjData = _.get(state.projectsById, projID, {}) as any;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...oldProjData,
            checkedUrls: {
              ...oldProjData.checkedUrls,
              loading: false,
              loadingFailed: false,
              data: checkedUrls,
            },
          },
        },
      };
    },

    [MutationType.FETCH_CHECKEDURLS_FOR_PROJ_START]: (
      state: MonitoringState,
      action: ActionTypes.FetchCheckedUrlsForProjectStartAction,
    ) => {
      const {
        payload: { projID },
      } = action;
      const oldProjData = _.get(state.projectsById, projID, {}) as any;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...oldProjData,
            checkedUrls: {
              ...oldProjData.checkedUrls,
              loading: true,
              loadingFailed: false,
              data: [],
            },
          },
        },
      };
    },

    [MutationType.FETCH_CHECKEDURLS_FOR_PROJ_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.FetchCheckedUrlsForProjectFailAction,
    ) => {
      const {
        payload: { projID, error },
      } = action;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            checkedUrls: {
              ...state.projectsById[projID].checkedUrls,
              data: [],
              loading: false,
              loadingFailed: true,
            },
          },
        },
      };
    },

    /////////////////////////////////
    // Create monitoring project
    /////////////////////////////////

    [MutationType.CREATE_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.CreateMonitoringProjectStartAction,
    ) => {
      return {
        ...state,
        creatingProject: true,
        projectCreationFailed: false,
      };
    },

    [MutationType.CREATE_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.CreateMonitoringProjectFailAction,
    ) => {
      return {
        ...state,
        creatingProject: false,
        projectCreationFailed: true,
      };
    },

    [MutationType.CREATE_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.CreateMonitoringProjectSucceedAction,
    ) => {
      const { newProject } = action.payload;
      const projID = _.get(newProject, "pk", null);

      if (projID === null) {
        console.error("New project does not have a primary key value");
        return state;
      }

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...defaultMonitoringProjectFields,
            data: { ...newProject },
          },
        },
        projects: _.concat(state.projects, projID),
        creatingProject: false,
        projectCreationFailed: false,
      };
    },

    /////////////////////////////////
    // Remove monitoring project
    /////////////////////////////////

    [MutationType.REMOVE_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.RemoveMonitoringProjectStartAction,
    ) => {
      const { projID } = action.payload;
      return {
        ...state,
        removingProject: true,
        removingProjectFailed: false,
      };
    },

    [MutationType.REMOVE_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.RemoveMonitoringProjectSucceedAction,
    ) => {
      const { projID } = action.payload;
      return {
        ...state,
        projectsById: Object.keys(state.projectsById)
          .filter((a: string) => a !== projID.toString())
          .reduce(
            (acc: any, currVal: any) => ({
              ...acc,
              [currVal]: state.projectsById[currVal],
            }),
            {},
          ),
        projects: state.projects.filter((a: number) => a !== projID),
        removingProject: true,
        removingProjectFailed: false,
      };
    },

    [MutationType.REMOVE_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.RemoveMonitoringProjectFailAction,
    ) => {
      const { projID } = action.payload;
      return {
        ...state,
        removingProject: false,
        removingProjectFailed: true,
      };
    },

    [MutationType.REMOVE_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.RemoveMonitoringProjectFailAction,
    ) => {
      const { projID } = action.payload;
      return {
        ...state,
        removingProject: false,
        removingProjectFailed: true,
      };
    },

    /////////////////////////////////
    // Remove monitoring urls
    /////////////////////////////////

    [MutationType.REMOVE_URLS_FROM_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.RemoveUrlsFromProjectStartAction,
    ) => {
      const { projID } = action.payload;
      return {
        ...state,
        projectsById: {
          [projID]: {
            ...state.projectsById[projID],
            removingUrls: true,
            removingUrlsFailed: false,
          },
        },
      };
    },

    [MutationType.REMOVE_URLS_FROM_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.RemoveUrlsFromProjectFailAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          [projID]: {
            ...state.projectsById[projID],
            removingUrls: false,
            removingUrlsFailed: true,
          },
        },
      };
    },

    [MutationType.REMOVE_URLS_FROM_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.RemoveUrlsFromProjectSucceedAction,
    ) => {
      const { payload } = action;
      const { projID, urls } = payload;
      const newUrlsById = Object.keys(state.projectsById[projID].urlsById).reduce(
        (acc: Record<string, any>, urlID: string) =>
          urls.includes(_.toNumber(urlID))
            ? { ...acc }
            : {
                ...acc,
                [urlID]: {
                  ...state.projectsById[projID].urlsById[_.toNumber(urlID)],
                },
              },
        {},
      );
      return {
        ...state,
        projectsById: {
          [projID]: {
            ...state.projectsById[projID],
            urlsById: newUrlsById,
            urls: state.projectsById[projID].urls.filter((a: number) => !urls.includes(a)),
            removingUrls: false,
            removingUrlsFailed: true,
          },
        },
      };
    },

    /////////////////////////////////
    // Add monitoring urls
    /////////////////////////////////

    [MutationType.ADD_URLS_TO_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.AddUrlsToProjectStartAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            addingUrls: true,
            addingUrlsFailed: false,
          },
        },
      };
    },

    [MutationType.ADD_URLS_TO_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.AddUrlsToProjectFailAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            addingUrls: false,
            addingUrlsFailed: true,
          },
        },
      };
    },

    [MutationType.ADD_URLS_TO_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.AddUrlsToProjectSucceedAction,
    ) => {
      const { payload } = action;
      const { projID, urls } = payload;

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            urlsById: _.chain(urls)
              .keyBy("pk")
              .value(),
            urls: urls.map((url: any) => url.pk),
            addingUrls: false,
            addingUrlsFailed: false,
          },
        },
      };
    },

    /////////////////////////////////
    // Toggle monitoring urls
    /////////////////////////////////

    [MutationType.TOGGLE_URLS_FROM_PROJECT_START]: (
      state: MonitoringState,
      action: ActionTypes.ToggleUrlsFromProjectStartAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            togglingUrls: true,
            togglingUrlsFailed: false,
          },
        },
      };
    },

    [MutationType.TOGGLE_URLS_FROM_PROJECT_FAIL]: (
      state: MonitoringState,
      action: ActionTypes.ToggleUrlsFromProjectFailAction,
    ) => {
      const { payload } = action;
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            togglingUrls: false,
            togglingUrlsFailed: true,
          },
        },
      };
    },

    [MutationType.TOGGLE_URLS_FROM_PROJECT_SUCCEED]: (
      state: MonitoringState,
      action: ActionTypes.ToggleUrlsFromProjectSucceedAction,
    ) => {
      const { payload } = action;
      const { projID, urls, newToggledState } = payload;
      const toggledUrls = urls.reduce(
        (acc: Record<number, any>, currentVal: number) => ({
          ...acc,
          [currentVal]: {
            ...state.projectsById[projID].urlsById[currentVal],
            check: newToggledState === "1",
          },
        }),
        {},
      );

      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            togglingUrls: false,
            togglingUrlsFailed: false,
            urlsById: {
              ...state.projectsById[projID].urlsById,
              ...toggledUrls,
            },
          },
        },
      };
    },

    /////////////////////////////////
    // Sitemonitoring WS
    /////////////////////////////////

    [MutationType.SETUP_WS]: (
      state: MonitoringState,
      action: ActionTypes.SetupSitemonitoringWebSocketSucceedAction,
    ) => {
      const { payload } = action;
      const { ws } = payload;
      return {
        ...state,
        ws,
      };
    },

    [MutationType.CLOSE_WS]: (state: MonitoringState, action: Action) => ({
      ...state,
      ws: null,
    }),

    /////////////////////////////////
    // Project progress
    /////////////////////////////////

    [MutationType.UPDATE_PROJECT_CHECK_PROGRESS]: (
      state: MonitoringState,
      action: ActionTypes.UpdateMonitoringProjectCheckProgressAction,
    ) => {
      const { payload } = action;
      const { projID, progress } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            checkProgress: progress,
            scriptRunning: progress !== -1,
          },
        },
      };
    },

    [MutationType.RUN_CHECK_PROCESS_START]: (
      state: MonitoringState,
      { payload }: ActionTypes.RunCheckProcessStartAction,
    ) => {
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            scriptRunning: true,
            checkProgressFailed: false,
            checkProgress: 0,
          },
        },
      };
    },

    [MutationType.RUN_CHECK_PROCESS_FAIL]: (
      state: MonitoringState,
      { payload }: ActionTypes.RunCheckProcessFailAction,
    ) => {
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            scriptRunning: false,
            checkProgressFailed: true,
          },
        },
      };
    },

    [MutationType.RUN_CHECK_PROCESS_SUCCEED]: (
      state: MonitoringState,
      { payload }: ActionTypes.RunCheckProcessSucceedAction,
    ) => {
      const { projID } = payload;
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [projID]: {
            ...state.projectsById[projID],
            checkProgressFailed: false,
          },
        },
      };
    },

    /////////////////////////////////
    // Project filters change
    /////////////////////////////////

    [MutationType.CHANGE_PROJECT_FILTERS_START]: (
      state: MonitoringState,
      { payload }: ActionTypes.ChangeMonitoringProjectFiltersStartAction,
    ) =>
      setMonitoringProject(state, payload.projID, {
        changingFilters: true,
        changingFiltersFailed: false,
      }),

    [MutationType.CHANGE_PROJECT_FILTERS_FAIL]: (
      state: MonitoringState,
      { payload }: ActionTypes.ChangeMonitoringProjectFiltersFailAction,
    ) =>
      setMonitoringProject(state, payload.projID, {
        changingFilters: false,
        changingFiltersFailed: true,
      }),

    [MutationType.CHANGE_PROJECT_FILTERS_SUCCEED]: (
      state: MonitoringState,
      { payload }: ActionTypes.ChangeMonitoringProjectFiltersSucceedAction,
    ) => {
      const prevProjState = _.get(state.projectsById, payload.projID, {});
      return setMonitoringProject(state, payload.projID, {
        data: {
          ..._.get(prevProjState, "data", {}),
          filters: payload.filters,
        },
        changingFilters: false,
        changingFiltersFailed: false,
      });
    },

    [MutationType.SET_SUBSCRIBED_TO_PROJ]: (
      state: MonitoringState,
      { payload }: { payload: { projId: PrimaryKey; subscribed: boolean } },
    ) =>
      setMonitoringProjectData(state, payload.projId, {
        current_user_subscribed: payload.subscribed,
      }),
  },
  initialState,
);

export default reducer;
