import Loader from "#/components/Loader";
import { fetchCurrentUserNotifications } from "#/store/modules/session/actions";
import { DispatchProp, PrimaryKey, UserNotification, UserNotificationDerived } from "#/store/types";
import { NotificationKind } from "#/types";
import { useDebounced } from "#/util/hooks";
import cn from "classnames";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DropdownMenu } from "reactstrap";
import style from "./index.module.scss";
import { NotificationMenuTab } from "./ItemMenuTabs";
import ItemsMenuHeader from "./ItemsMenuHeader";
import NotificationMenuItem from "./NotificationMenuItem";
import { showErrorToast } from "#/components/toasts";

const CHAT_TAB_NOTIF_KINDS: NotificationKind[] = ["NEW_TASK_MESSAGE"];

const TASKS_TAB_NOTIF_KINDS: NotificationKind[] = [
  "TASK_DEADLINE_COMING",
  "TASK_IS_COMPLETED",
  "TASK_STATUS_CHANGED",
  "TASK_WAS_ASSIGNED_TO_YOU",
  "TASK_WAS_UPDATED",
  "TASK_WITH_NO_EXEC_WAS_CREATED",
];

const ItemsMenu = (props: {
  loadingNotifications: boolean;
  currentUserNotifications: UserNotificationDerived[];
  push: typeof push;
  markNotificationsAsRead: (notificationIds: PrimaryKey[]) => Promise<unknown>;
  currentlyOpened: boolean;
  fetchCurrentUserNotifications: DispatchProp<typeof fetchCurrentUserNotifications>;
  areAllUserNotificationsAlreadyFetched: boolean;
}) => {
  if (!props.currentlyOpened) {
    return null;
  }

  // Item ids that have been read this "frame"
  const [itemIdsRead, setItemIdsRead] = useState([] as PrimaryKey[]);

  // Mechanism used to only send "I've read these notifications" API
  // calls only once every N milliseconds (when unread notifications are read).
  const debouncedItemIdsRead = useDebounced(itemIdsRead, 2000);
  useEffect(() => {
    // If there have been items to read
    if (debouncedItemIdsRead.length > 0) {
      props.markNotificationsAsRead(itemIdsRead).then(() => {
        setItemIdsRead([]);
      });
    }
  }, [debouncedItemIdsRead]);

  const onItemRead = (item: UserNotification) => {
    if (!_.includes(itemIdsRead, item.pk)) {
      setItemIdsRead([...itemIdsRead, item.pk]);
    }
  };

  const containerRef = React.useRef<HTMLDivElement>(null);

  const onBottomSentinelInView = useCallback(
    _.debounce(async () => {
      if (!props.areAllUserNotificationsAlreadyFetched) {
        try {
          await props.fetchCurrentUserNotifications();
        } catch (err) {
          showErrorToast("Произошла ошибка при попытке загрузить оповещения");
          console.error(err);
          containerRef.current!.scrollTo(0, 0);
        }
      }
    }, 1000),
    [props.fetchCurrentUserNotifications, props.areAllUserNotificationsAlreadyFetched],
  );
  const [currentTab, setCurrentTab] = useState<NotificationMenuTab>(NotificationMenuTab.ALL);

  const notifs = useMemo(() => {
    switch (currentTab) {
      case NotificationMenuTab.ALL:
        return props.currentUserNotifications;
      case NotificationMenuTab.CHAT:
        return props.currentUserNotifications.filter(a => _.includes(CHAT_TAB_NOTIF_KINDS, a.kind));
      case NotificationMenuTab.TASKS:
        return props.currentUserNotifications.filter(a =>
          _.includes(TASKS_TAB_NOTIF_KINDS, a.kind),
        );
    }
  }, [currentTab, props.currentUserNotifications]);

  return (
    <DropdownMenu right={true} className={cn([style["notification-menu-dropdown"]])}>
      <ItemsMenuHeader
        currentTab={currentTab}
        changeTab={(t: NotificationMenuTab) => setCurrentTab(t)}
      />
      {(() => {
        if (notifs.length > 0) {
          return (
            <div
              ref={containerRef}
              className={style["notification-menu-dropdown__items-container"]}
            >
              {notifs.map((notification: UserNotificationDerived, idx: number) => {
                return (
                  <NotificationMenuItem
                    key={notification.pk}
                    notification={notification}
                    push={props.push}
                    onItemRead={onItemRead}
                    bottomSentinel={idx === notifs.length - 1}
                    onBottomSentinelInView={onBottomSentinelInView}
                  />
                );
              })}
            </div>
          );
        } else if (!props.loadingNotifications) {
          return (
            <div className={style["notification-menu-dropdown__no-items-message"]}>
              Нет оповещений
            </div>
          );
        }
      })()}
      <div className={style["notification-menu-dropdown__loader-container"]}>
        {props.loadingNotifications && <Loader />}
      </div>
    </DropdownMenu>
  );
};

export default ItemsMenu;
