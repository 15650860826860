import React from "react";
import { Switch, Route } from "react-router";
import Page from "#/components/Page";
import Index from "./components/Index";
import Project from "./components/Project";
import Event from "./components/Event";

export const MetrikaTop50Scene = ({ match }: any) => {
  return (
    <Switch>
      <Route path={match.url} exact={true} render={(props: any) => <Index {...props} />} />
      <Route
        path={`${match.url}/:projectPk`}
        exact={true}
        render={(props: any) => (
          <Page title="Metrika Project">
            <Project {...props} />
          </Page>
        )}
      />
      <Route
        path={`${match.url}/:projectPk(\\d+)/:eventPk(\\d+)`}
        exact={true}
        render={(props: any) => (
          <Page title="Metrika Event">
            <Event {...props} />
          </Page>
        )}
      />
    </Switch>
  );
};

export default MetrikaTop50Scene;
