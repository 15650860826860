import { MetrikaGoalTypes } from "./types";

export const goalTypeToString = (type: MetrikaGoalTypes) => {
  switch (type) {
    case MetrikaGoalTypes.action:
      return "JS-событие";
    case MetrikaGoalTypes.step:
      return "Составная цель";
    case MetrikaGoalTypes.url:
      return "URL";
  }
};
