import { falseIfUndefinedElseBoolean } from "#/util";
import cn from "classnames";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { Component } from "react";
import { Editor, EditorState as IEditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import style from "./index.module.scss";
import "./index.scss";
import { manipulateRawBlocks } from "./utils";

interface EditorWrapperConnectedProps {}

interface EditorWrapperOwnProps {
  contentValue: string;
  onChildChange: (html: string) => void;
  invalid?: boolean;
}

type EditorWrapperProps = EditorWrapperConnectedProps & EditorWrapperOwnProps;

interface EditorWrapperState {
  editorState: IEditorState;
}

class EditorWrapper extends Component<EditorWrapperProps, EditorWrapperState> {
  constructor(props: EditorWrapperProps) {
    super(props);
    const { contentValue } = this.props;
    const contentBlock = contentValue ? htmlToDraft(contentValue) : htmlToDraft("");
    if (contentBlock) {
      const editorState = EditorState.createWithContent(
        ContentState.createFromBlockArray(contentBlock.contentBlocks, contentBlock.entityMap),
      );

      this.state = {
        editorState,
      };
    }
  }

  public formatToHtml = (content: string) => {
    const rawState = convertToRaw(content);
    const blocks = manipulateRawBlocks(rawState);
    const newRawState = { ...rawState, blocks };
    const res = draftToHtml(newRawState);
    if (res === "<p></p>\n") { return ""; }
    return res;
  };

  public onEditorStateChange = (editorState: IEditorState) => {
    const content = editorState.getCurrentContent();
    const html = this.formatToHtml(content);
    this.setState({ editorState });
    this.props.onChildChange(html);
  };

  public render() {
    const { editorState } = this.state;

    const toolbarOptions = {
      options: [
        "inline",
        "fontSize",
        "list",
        "textAlign",
        "colorPicker",
        "link",
        "emoji",
        "remove",
        "history",
      ],
      inline: {
        inDropdown: false,
        className: undefined,
        component: undefined,
        dropdownClassName: undefined,
        options: ["bold", "italic", "underline", "monospace"],
      },
      textAlign: {
        inDropdown: false,
        options: ["left", "center", "right", "justify"],
      },
      fontSize: {
        options: [10, 11, 12, 14, 16, 18, 24],
      },
    };
    const invalid = falseIfUndefinedElseBoolean(this.props.invalid);
    return (
      <div>
        <Editor
          editorState={editorState}
          wrapperClassName={"wrapper-editor"}
          editorClassName={cn(["html-editor", cn({ [style.invalid]: invalid })])}
          onEditorStateChange={this.onEditorStateChange}
          localization={{ locale: "ru" }}
          toolbar={toolbarOptions}
        />
      </div>
    );
  }
}

export default EditorWrapper;
