import sessionModuleSelectors from "#/store/modules/session/selectors";
import tasksModule from "#/store/modules/tasks";
import {
  CompleteTaskData,
  PrimaryKey,
  SessionTasksState,
  StoreRootState,
  TaskData,
  TasksById,
  TaskStatus,
} from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

type Props = {} | null;

const getSessionTasksState = (state: StoreRootState, props: Props = null) => state.sessionTasks;
const getProps = (state: StoreRootState, props: Props = null) => props;

// utils

export const sortTasksByPriority = (tasks: CompleteTaskData[]): CompleteTaskData[] =>
  tasks.sort((a: CompleteTaskData, b: CompleteTaskData) =>
    a.data.priority > b.data.priority ? 1 : a.data.priority < b.data.priority ? -1 : 0,
  );

// Tasks to user

export const getAllTasksToUserIds = createSelector(
  [getSessionTasksState],
  (session: SessionTasksState | null) => (session ? session.tasksToUser : []),
);

const getAllTasksToUser = createSelector(
  [tasksModule.selectors.getTasksById, getAllTasksToUserIds],
  (tasksById: TasksById, ids: PrimaryKey[]): CompleteTaskData[] =>
    _.compact(
      ids
        .map(id => _.get(tasksById, id, null))
        .filter((a: TaskData | null) => a !== null && a.data !== null),
    ) as CompleteTaskData[],
);

const getAllTasksToUserWithExecutor = createSelector(
  [getAllTasksToUser],
  (ttu: CompleteTaskData[]): TaskData[] => ttu.filter(t => t.data.task_to !== null),
);

const getActiveTasksToUser = createSelector(
  [getAllTasksToUserWithExecutor],
  (tasksToUser: TaskData[]): CompleteTaskData[] =>
    tasksToUser.filter((el: TaskData) =>
      el.data
        ? el.data.status !== TaskStatus.FINISHED && el.data.status !== TaskStatus.DELAYED
        : false,
    ) as CompleteTaskData[],
);

const getActiveTasksToUserSortedByPriority = createSelector(
  [getActiveTasksToUser],
  (tasksToUser: CompleteTaskData[]) => sortTasksByPriority(tasksToUser),
);

const getDelayedTasksToUser = createSelector(
  [getAllTasksToUserWithExecutor],
  (tasksToUser: TaskData[]): CompleteTaskData[] =>
    (tasksToUser.filter((el: TaskData) =>
      el.data ? el.data.status === TaskStatus.DELAYED : false,
    ) as any) as CompleteTaskData[],
);

const getDelayedTasksToUserSortedByPriority = createSelector(
  [getDelayedTasksToUser],
  (tasksToUser: CompleteTaskData[]) => sortTasksByPriority(tasksToUser),
);

const getTasksToUserWithoutExecutor = createSelector(
  [getAllTasksToUser],
  (ttu: CompleteTaskData[]) =>
    ttu.filter(t => t.data.task_to === null && t.data.status !== TaskStatus.FINISHED),
);

// Tasks from user

export const getAllTasksFromUserIds = createSelector(
  [getSessionTasksState],
  session => (session ? session.tasksFromUser : []),
);

const getAllTasksFromUser = createSelector(
  [tasksModule.selectors.getTasksById, getAllTasksFromUserIds],
  (tasksById: TasksById, ids: PrimaryKey[]) => _.compact(ids.map(id => _.get(tasksById, id, null))),
);

const getTasksFromUser = createSelector(
  [getAllTasksFromUser],
  (tasksFromUser: TaskData[]): CompleteTaskData[] =>
    (tasksFromUser
      .filter((el: TaskData) => (el.data ? el.data.status !== TaskStatus.FINISHED : false))
      .concat(
        tasksFromUser.filter((el: TaskData) =>
          el.data ? el.data.status === TaskStatus.FINISHED : false,
        ),
      ) as any[]) as CompleteTaskData[],
);

const getTasksFromUserSortedByPriority = createSelector(
  [getTasksFromUser],
  (t: CompleteTaskData[]): CompleteTaskData[] =>
    t.sort((a: CompleteTaskData, b: CompleteTaskData) =>
      a.data.priority > b.data.priority ? 1 : a.data.priority < b.data.priority ? -1 : 0,
    ),
);

const getFinishedTasksForUser = createSelector(
  [sessionModuleSelectors.getCurrentUserID, getAllTasksToUser, getAllTasksFromUser],
  (
    cuid: PrimaryKey | null,
    tasksToUser: TaskData[],
    tasksFromUser: TaskData[],
  ): CompleteTaskData[] =>
    _.concat(
      tasksToUser.filter((el: TaskData) => el.data && el.data.status === TaskStatus.FINISHED),
      tasksFromUser.filter(
        (el: TaskData) =>
          el.data && el.data.status === TaskStatus.FINISHED && el.data.task_to !== cuid,
      ),
    ) as CompleteTaskData[],
);

export default {
  getFinishedTasksForUser,
  getDelayedTasksToUser,
  getDelayedTasksToUserSortedByPriority,
  getActiveTasksToUser,
  getActiveTasksToUserSortedByPriority,
  getAllTasksToUser,
  getAllTasksFromUser,
  getTasksFromUser,
  getTasksFromUserSortedByPriority,
  getTasksToUserWithoutExecutor,
};
