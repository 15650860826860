import CreateTask from "#/components/CreateTaskModal";
import { editTask, removeTask } from "#/store/modules/tasks/actions";
import { CompleteTaskData, CRMProjectData, DispatchProp, TaskStatus } from "#/store/types";
import { CODE_NON_REACHABLE } from "#/util";
import React, { useEffect, useMemo, useState } from "react";
import TaskTable from "../../../../TaskTable";

enum SelectedTasksTab {
  ALL = 0,
  ACTIVE = 1,
  FINISHED = 2,
  WITHOUT_EXECUTOR = 3,
}

const ProjectTasks = (props: {
  project: CRMProjectData | null;
  tasks: CompleteTaskData[];
  editTask: DispatchProp<typeof editTask>;
  removeTask: DispatchProp<typeof removeTask>;
  isCurrentlyDeletingTask: boolean;
}) => {
  const [selected, setSelected] = useState<SelectedTasksTab>(SelectedTasksTab.ACTIVE);
  const [right, setRight] = useState(true);
  const [createTaskModalShown, setCreateTaskModalShown] = useState(false);
  const [tasks, setTasks] = useState(props.tasks);

  useEffect(() => {
    setTasks(tasks);
  }, [props.tasks]);

  const selectedTasks = useMemo(() => {
    switch (selected) {
      case SelectedTasksTab.ALL:
        return props.tasks;
      case SelectedTasksTab.ACTIVE:
        return props.tasks.filter(e => {
          return e.data.status !== TaskStatus.FINISHED && e.data.task_to !== null;
        });
      case SelectedTasksTab.FINISHED:
        return props.tasks.filter(e => e.data.status === TaskStatus.FINISHED);
      case SelectedTasksTab.WITHOUT_EXECUTOR:
        return props.tasks.filter(e => {
          return e.data.task_to === null && e.data.status !== TaskStatus.FINISHED;
        });
      default:
        CODE_NON_REACHABLE();
        return [];
    }
  }, [props.tasks, selected]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="box">
          {/* Task table header */}
          <div className="box-header with-border">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="box-title">Список задач</h4>
                <h6 className="box-subtitle">
                  <button
                    className={
                      "btn btn-list fz-13 action-rm " +
                      (selected === SelectedTasksTab.ACTIVE ? "active" : "")
                    }
                    onClick={e => setSelected(SelectedTasksTab.ACTIVE)}
                  >
                    Активные
                  </button>
                  <button
                    className={
                      "btn btn-list ml-1 fz-13 action-rm " +
                      (selected === SelectedTasksTab.FINISHED ? "active" : "")
                    }
                    onClick={e => setSelected(SelectedTasksTab.FINISHED)}
                  >
                    Завершенные
                  </button>
                  <button
                    className={
                      "btn btn-list ml-1 fz-13 action-rm " +
                      (selected === SelectedTasksTab.ALL ? "active" : "")
                    }
                    onClick={e => setSelected(SelectedTasksTab.ALL)}
                  >
                    Все задачи
                  </button>
                  <button
                    className={
                      "btn btn-list ml-1 fz-13 action-rm " +
                      (selected === SelectedTasksTab.WITHOUT_EXECUTOR ? "active" : "")
                    }
                    onClick={e => setSelected(SelectedTasksTab.WITHOUT_EXECUTOR)}
                  >
                    Без исполнителя
                  </button>
                </h6>
              </div>

              <div className="float-right text-right">
                <button
                  className="btn btn-success"
                  onClick={(e: any) => setCreateTaskModalShown(true)}
                >
                  <i className="fas fa-plus-circle" /> Назначить
                </button>
              </div>
            </div>
          </div>

          {/* Task table */}
          <div className="box-body">
            <div className="table-responsive">
              <div id="tasks_wrapper" className="overflow-hidden">
                {props.tasks !== null && (
                  <TaskTable
                    tasks={selectedTasks}
                    direction={right}
                    changeItem={props.editTask}
                    removeItem={props.removeTask}
                    isCurrentlyDeletingTask={props.isCurrentlyDeletingTask}
                    showProjectColumn={false}
                    showManagerColumn={true}
                    showExecutorColumn={selected !== SelectedTasksTab.WITHOUT_EXECUTOR}
                  />
                )}
              </div>
            </div>
          </div>

          {/* Task creation modal */}
          <CreateTask
            isShown={createTaskModalShown}
            toggleModal={() =>
              new Promise((resolve, reject) => {
                setCreateTaskModalShown(!createTaskModalShown);
                resolve();
              })
            }
            preselectedProject={
              props.project && props.project.data ? props.project.data.pk : undefined
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ProjectTasks;
