import projectsModule from "#/store/modules/projects";
import { ProjectsById, StoreRootState, TaskWithNoExecWasCreatedNotification } from "#/store/types";
import { constructURLToTask, projectName } from "#/util";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import style from "./index.module.scss";
import ItemWrapper from "./ItemWrapper";
import { DerivedNotificationItemProps } from "./types";

const TaskWithNoExecWasCreatedNotificationItem = React.forwardRef(
  (
    props: DerivedNotificationItemProps<TaskWithNoExecWasCreatedNotification> & {
      projectsById: ProjectsById;
    },
    ref: React.Ref<any>,
  ) => {
    const taskProject = _.get(
      _.get(props.projectsById, props.notification.task_model.project, {}),
      "data",
      null,
    );
    return (
      <ItemWrapper
        ref={ref}
        onClick={() => {
          props.push(constructURLToTask(props.notification.task_model));
        }}
        notification={props.baseNotification}
      >
        <span className={style["notification-menu-item__text"]}>
          В проекте {`"${projectName(taskProject)}"`} появилась новая общая задача{" "}
          {`"${props.notification.task_model.name}"`}
        </span>
      </ItemWrapper>
    );
  },
);

const ConnectedComponent = connect(
  (store: StoreRootState) => ({
    projectsById: projectsModule.selectors.getProjectsById(store, null),
  }),
  null,
  null,
  { forwardRef: true },
)(TaskWithNoExecWasCreatedNotificationItem) as any;

export default ConnectedComponent;
