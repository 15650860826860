import Checkbox from "#/components/Checkbox";
import ClipLoaderButton from "#/components/ClipLoaderButton";
import OnEnter from "#/components/OnEnter";
import { showInfoToast, showSuccessToast } from "#/components/toasts";
import {
  addUrlsToProject,
  importUrlsFromSitemapIntoProject,
} from "#/store/modules/sitemonitoring/actions";
import { DispatchProp, MonitoringProject, PrimaryKey } from "#/store/types";
import { useDebounced } from "#/util/hooks";
import { isURLValid } from "#/util/validation";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import ProjectContext from "../../../ProjectContext";

const AddUrlModal = (props: {
  onAdd: (urls: string[]) => ReturnType<DispatchProp<typeof addUrlsToProject>>;
  isOpen: boolean;
  toggle: () => void;
}) => {
  const projectContext = useContext(ProjectContext);

  const [urlsTextField, setUrlsTextField] = useState<string>("");
  const [thereWereValidationProblems, setThereWereValidationProblems] = useState<boolean>(false);

  // If set to true, user would be prompted to enter a sitemap URL from
  // which to import urls instead.
  const [importFromSitemap, setImportFromSitemap] = useState<boolean>(false);
  const [sitemapTextField, setSitemapTextField] = useState<string>("");
  const [importingUrls, setImportingUrls] = useState<boolean>(false);

  const submit = useCallback(() => {
    if (importFromSitemap) {
      const url = sitemapTextField;
      if (!isURLValid(url)) {
        setThereWereValidationProblems(true);
        return;
      } else {
        setThereWereValidationProblems(false);
      }
      setImportingUrls(true);
      projectContext.importFromSitemap(url).then(urlsAdded => {
        setSitemapTextField("");
        props.toggle();
        showSuccessToast(`${urlsAdded} URL-ов было добавлено`);
        setImportingUrls(false);
        setTotalSMAddPrediction(null);
      });
    } else {
      setThereWereValidationProblems(false);
      let validationProblems = false;
      const array = urlsTextField
        .split("\n")
        .map((el, i) => el.trim())
        .filter(a => a.length > 0)
        .filter(a => {
          const isValid = isURLValid(a);
          if (!isValid) {
            validationProblems = true;
          }
          return isValid;
        });
      if (validationProblems) {
        setThereWereValidationProblems(true);
        return;
      }
      if (array.length === 0) { return; }
      setImportingUrls(true);
      props.onAdd(array).then(resp => {
        setUrlsTextField("");
        props.toggle();
        if (resp.diff !== null) {
          showSuccessToast(`${resp.diff} URL-ов было добавлено`);
        }
        setImportingUrls(false);
      });
    }
  }, [urlsTextField, sitemapTextField, thereWereValidationProblems]);

  const debouncedSitemapURL = useDebounced(sitemapTextField, 1000);
  const [totalSMAddPrediction, setTotalSMAddPrediction] = useState<number | null>(null);
  const [discoveringSMAddPrediction, setDiscoveringSMAddPrediction] = useState<boolean>(false);
  useEffect(() => {
    if (debouncedSitemapURL.length === 0) { return; }
    if (!isURLValid(debouncedSitemapURL)) {
      setThereWereValidationProblems(true);
      return;
    } else {
      setThereWereValidationProblems(false);
    }
    setDiscoveringSMAddPrediction(true);
    projectContext.importFromSitemap(debouncedSitemapURL, true).then(prediction => {
      setTotalSMAddPrediction(prediction);
      setDiscoveringSMAddPrediction(false);
    });
  }, [debouncedSitemapURL]);

  return (
    <OnEnter do={submit} ctrl={true}>
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader toggle={props.toggle}>Добавить URL-ы</ModalHeader>
        <form
          onSubmit={e => {
            e.preventDefault();
            submit();
          }}
        >
          <ModalBody>
            <FormGroup>
              <label>
                URLS
                <span className="small ml-2">(список, каждый новый URL - с новой строки)</span>
              </label>
              <div className="mb-2">
                {importFromSitemap ? (
                  <Input
                    type="text"
                    value={sitemapTextField}
                    onChange={e => {
                      setSitemapTextField(e.target.value);
                    }}
                    invalid={thereWereValidationProblems}
                  />
                ) : (
                  <Input
                    type="textarea"
                    value={urlsTextField}
                    onChange={e => setUrlsTextField(e.target.value)}
                    invalid={thereWereValidationProblems}
                    style={{ minHeight: "160px" }}
                  />
                )}
              </div>
              <div className="d-flex justify-content-end">
                <Label className="mr-2">Импортировать с sitemap</Label>
                <Checkbox
                  checked={importFromSitemap}
                  onChange={() => setImportFromSitemap(!importFromSitemap)}
                />
              </div>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <ClipLoaderButton
              color="primary"
              type="submit"
              disabled={
                thereWereValidationProblems ||
                importingUrls ||
                (importFromSitemap
                  ? discoveringSMAddPrediction ||
                    sitemapTextField.length === 0 ||
                    totalSMAddPrediction === 0
                  : urlsTextField.length === 0)
              }
              loading={discoveringSMAddPrediction || importingUrls}
              size="md"
            >
              {discoveringSMAddPrediction
                ? "Добавить"
                : totalSMAddPrediction !== null
                ? `Импортировать ${totalSMAddPrediction} URL-ов`
                : "Добавить"}
            </ClipLoaderButton>
          </ModalFooter>
        </form>
      </Modal>
    </OnEnter>
  );
};

export default AddUrlModal;
