import React, { useState, ChangeEvent } from "react";
import {
  MetrikaGoalTypes,
  MetrikaGoalConditionTypes,
  Condition,
} from "#/store/modules/metrika/goals/types";
import "./index.scss";
interface ConditionFormOwnProps {
  callback: any;
  deletionCallback: any;
  condition: Condition;
}
interface ConditionFormConnectedProps {}

type ConditionFormProps = ConditionFormConnectedProps & ConditionFormOwnProps;

const ConditionForm = (props: ConditionFormProps) => {
  const handleChange = (e: ChangeEvent<HTMLSelectElement | HTMLInputElement>) => {
    // console.log(e, e.target.name, e.target.value);
    props.callback({
      ...props.condition,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <div className="input-group mb-1">
      <select className="form-control" name="type" onChange={e => handleChange(e)}>
        <option
          value={MetrikaGoalConditionTypes.action}
          // selected={props.condition.type === MetrikaGoalConditionTypes.action}
        >
          Action
        </option>
        <option
          value={MetrikaGoalConditionTypes.contain}
          // selected={props.condition.type === MetrikaGoalConditionTypes.contain}
        >
          Contain
        </option>

        <option
          value={MetrikaGoalConditionTypes.exact}
          // selected={props.condition.type === MetrikaGoalConditionTypes.exact}
        >
          Exact
        </option>

        <option
          value={MetrikaGoalConditionTypes.regexp}
          // selected={props.condition.type === MetrikaGoalConditionTypes.regexp}
        >
          Regexp
        </option>
        <option
          value={MetrikaGoalConditionTypes.start}
          // selected={props.condition.type === MetrikaGoalConditionTypes.start}
        >
          Start
        </option>
      </select>
      <input type="text" name="url" className="form-control" onChange={e => handleChange(e)} />
      <div className="input-group-append">
        <button
          type="button"
          className="btn btn-outline-secondary"
          onClick={props.deletionCallback}
        >
          <i className="fas fa-times"/>
        </button>
      </div>
    </div>
    // <div className="condition-form">
    //   Type:
    //   <select name="type" onChange={e => handleChange(e)}>
    //     <option
    //       value={MetrikaGoalConditionTypes.action}
    //       // selected={props.condition.type === MetrikaGoalConditionTypes.action}
    //     >
    //       Action
    //     </option>
    //     <option
    //       value={MetrikaGoalConditionTypes.contain}
    //       // selected={props.condition.type === MetrikaGoalConditionTypes.contain}
    //     >
    //       Contain
    //     </option>

    //     <option
    //       value={MetrikaGoalConditionTypes.exact}
    //       // selected={props.condition.type === MetrikaGoalConditionTypes.exact}
    //     >
    //       Exact
    //     </option>

    //     <option
    //       value={MetrikaGoalConditionTypes.regexp}
    //       // selected={props.condition.type === MetrikaGoalConditionTypes.regexp}
    //     >
    //       Regexp
    //     </option>
    //     <option
    //       value={MetrikaGoalConditionTypes.start}
    //       // selected={props.condition.type === MetrikaGoalConditionTypes.start}
    //     >
    //       Start
    //     </option>
    //   </select>
    //   Url:
    //   <input type="text" name="url" onChange={e => handleChange(e)} />
    // </div>
  );
};

export default ConditionForm;
