import Application from "#/components/Application";
import AppWrapper from "#/components/AppWrapper";
import * as serviceWorker from "#/serviceWorker";
// import "bootstrap/dist/css/bootstrap.css"
import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";

const rootEl = document.getElementById("application_root");

ReactDOM.render(
  <AppWrapper>
    <Application />
  </AppWrapper>,
  rootEl,
);

serviceWorker.register();
