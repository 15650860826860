enum MutationTypes {
  GENERATE_INVITATION_LINK_FAIL = "is/GENERATE_INVITATION_LINK_FAIL",
  GENERATE_INVITATION_LINK_SUCCEED = "is/GENERATE_INVITATION_LINK_SUCCEED",
  GENERATE_INVITATION_LINK_START = "is/GENERATE_INVITATION_LINK_START",
  REGISTER_VIA_INVITATION_FAIL = "is/REGISTER_VIA_INVITATION_FAIL",
  REGISTER_VIA_INVITATION_SUCCEED = "is/REGISTER_VIA_INVITATION_SUCCEED",
  REGISTER_VIA_INVITATION_START = "is/REGISTER_VIA_INVITATION_START",
  RESET_INVITATION_LINK = "is/RESET_INVITATION_LINK",
}

export default MutationTypes;
