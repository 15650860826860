import React from "react";
import { Link } from "react-router-dom";

// CLEANUP: Move out this header into /components/ directory, generalize it,
//          and replace all other duplicates of this exact same layout with
//          the new component.

interface HeaderConnectedProps {}

interface HeaderOwnProps {
  headerName?: string;
}

type HeaderProps = HeaderConnectedProps & HeaderOwnProps;

const Header = (props: HeaderProps) => {
  const { headerName } = props;

  const headerToRender = headerName ? headerName : "Сервисы";

  return (
    <section className="content-header">
      <h1>
        {headerToRender} \ <small>Content-Plan</small>
      </h1>
      <ol className="breadcrumb breadcrumb-big">
        <li className="breadcrumb-item">ContentPlan</li>
        <li className="breadcrumb-item">
          <Link to="/services/contentplan/">Список проектов</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/services/contentplan/eventlist/">Список мероприятий</Link>
        </li>
      </ol>
    </section>
  );
};

export default Header;
