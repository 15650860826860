import React from "react";
import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import { PrimaryKey } from "#/store/types";

export interface Filters {
  name: string;
}

export interface IProjectListContext {
  startQueryDataCollection: () => void;
  toggleCreateProjectModal: () => void;
  toggleSettingsModal: () => void;
  filters: Filters;
  setFilters: (filters: Filters) => void;
  removeProject: (pk: PrimaryKey) => Promise<void>;
  loading: boolean;
  queryDataCollectionStatus: boolean;
}

const ProjectListContext = React.createContext<IProjectListContext>({
  startQueryDataCollection: NON_REACHABLE_EMPTY_FUNCTION,
  toggleCreateProjectModal: NON_REACHABLE_EMPTY_FUNCTION,
  toggleSettingsModal: NON_REACHABLE_EMPTY_FUNCTION,
  filters: { name: "" },
  setFilters: NON_REACHABLE_EMPTY_FUNCTION,
  removeProject: NON_REACHABLE_EMPTY_FUNCTION,
  loading: false,
  queryDataCollectionStatus: false,
});

export default ProjectListContext;
