import { createReducer } from "#/store/helpers";
import { EventSystemState } from "#/store/types";
import { getFunctionUniqueID } from "#/util";
import _ from "lodash";
import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";

// TODO: Do this automatically
export const initialState: EventSystemState = {
  events: {
    SAVE: {},
    SELECT_ALL: {},
    RUN_MONITORING: {},
    TOGGLE_SIDEBAR: {},
    FOCUS_SEARCH: {},
    OPEN_DASHBOARD: {},
    GOTO_PROJECT: {},
    GOTO_USER: {},
    ADD_MONITORING_URLS: {},
  },
};

export const reducer = createReducer<EventSystemState, any>(
  {
    // Subscription
    [MutationTypes.SUBSCRIBE_TO_EVENT]: (
      state: EventSystemState,
      { payload }: ActionTypes.SubscribeToEventAction,
    ) => ({
      ...state,
      events: {
        ...state.events,
        [payload.eventName]: {
          ..._.get(state.events, payload.eventName, {}),
          [getFunctionUniqueID(payload.cb)]: payload.cb,
        },
      },
    }),
    [MutationTypes.UNSUBSCRIBE_FROM_EVENT]: (
      state: EventSystemState,
      { payload }: ActionTypes.UnsubscribeFromEventAction,
    ) => ({
      ...state,
      events: {
        ...state.events,
        [payload.eventName]: Object.keys(_.get(state.events, payload.eventName, {}))
          .filter((k: string) => {
            return !_.isEqual(k, getFunctionUniqueID(payload.cb));
          })
          .reduce(
            (acc: any, currVal: any) => ({
              ...acc,
              [currVal]: _.get(_.get(state.events, payload.eventName, {}), currVal, {}),
            }),
            {},
          ),
      },
    }),
  },
  initialState,
);

export default reducer;
