import ProgressButton from "#/components/ProgressButton";
import React, { useContext } from "react";
import ProjectContext from "../ProjectContext";

const RunMonitoringProcessButton = () => {
  const projectContext = useContext(ProjectContext);
  const isScriptWorking = projectContext.isCheckActive;
  const progressNow = projectContext.checkProgress;
  const areThereAnyUrls = projectContext.urls.length > 0;
  return (
    <ProgressButton
      running={isScriptWorking}
      progress={progressNow}
      onClick={() => projectContext.runCheckProcess()}
      disabled={!areThereAnyUrls || isScriptWorking}
      textIfRunning="Мониторинг запущен..."
      textIfNotRunning="Запустить мониторинг"
      progressMax={100}
      title={
        areThereAnyUrls
          ? "Запустить мониторинг"
          : "Невозможно запустить мониторинг т.к. URL не настроены"
      }
    />
  );
};

export default RunMonitoringProcessButton;
