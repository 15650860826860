import { ClipLoader } from "react-spinners";
import { Button, ButtonProps } from "reactstrap";
import _ from "lodash";
import React from "react";
import cn from "classnames";

type Props = ButtonProps & {
  loading: boolean;
};

const ClipLoaderButton = (props: Props) => {
  return (
    <Button
      {..._.omit(props, ["loading", "children"])}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 14px",
      }}
    >
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <ClipLoader
          css={{ name: "mr", styles: "vertical-align: middle;" }}
          color="#fff"
          size={15}
          loading={props.loading}
        />
      </div>
      {props.children && <span className={cn({ "ml-2": props.loading })}>{props.children}</span>}
    </Button>
  );
};

export default ClipLoaderButton;
