import Loader from "#/components/Loader";
import UserAvatar, { UserAvatarSize } from "#/components/UserAvatar";
import UserRoleLabelList from "#/components/UserRoleLabelList";
import { canUser, canUserChangeUserStatus } from "#/permissions";
import UserProjectsWidget from "#/scenes/Main/components/CRM/components/Profile/components/UserProjectsWidget";
import { getActionsFromModule } from "#/store/helpers";
import projectsModule from "#/store/modules/projects";
import sessionUsersModule from "#/store/modules/sessionUsers";
import userModule from "#/store/modules/user";
import usersModule from "#/store/modules/users";
import {
  CRMProject,
  EditUserInfo,
  EditUserProjects,
  FetchUserInfo,
  PrimaryKey,
  ProjectsById,
  StoreRootState,
  User,
  EditUserAvatar,
} from "#/store/types";
import {
  constructSkypeURLFromUsername,
  constructTelegramURLFromUsername,
  userFullName,
} from "#/util";
import cn from "classnames";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button } from "reactstrap";
import style from "../index.module.scss";
import { API_ROUTES } from "#/conf/api";
import { showErrorToast } from "#/components/toasts";

interface ProfileInfoBlockProps {
  user: User | null;
  currentUser: User | null;
  fetchUserInfo: FetchUserInfo;
  editUserInfo: EditUserInfo;
  editUserAvatar: EditUserAvatar;
  editRequestInProcess: boolean;
  changeUserStatusToUser: (pk: PrimaryKey) => Promise<unknown>;
  changeUserStatusToAdmin: (pk: PrimaryKey) => Promise<unknown>;
  userProjects: CRMProject[];
  projects: ProjectsById;
  projectsListSorted: CRMProject[];
  userProjectsIds: PrimaryKey[];
  editUserProjects: EditUserProjects;
}

const Wrapper = (props: { children: React.ReactNode }) => (
  <div className={cn(["col-xl-3 col-lg-4 col-md-5 col-12", style["user-info-block-wrapper"]])}>
    <TransitionGroup key={1}>
      <CSSTransition
        classNames={"mask-top"}
        appear={true}
        timeout={{
          enter: 0,
          exit: 300,
        }}
      >
        <div className={cn(["box bg-white", style["user-info-block-wrapper__box"]])}>
          <div className={cn(["box-body box-profile", style["user-info-block-wrapper__box"]])}>
            {props.children}
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  </div>
);

const ProfileInfoBlock = (props: ProfileInfoBlockProps) => {
  if (!props.user) {
    return (
      <Wrapper>
        <div className={style["loader-wrapper"]}>
          <Loader />
        </div>
      </Wrapper>
    );
  }

  const inputAvatarRef = React.createRef() as any;
  const [user, setUser] = useState(props.user);
  const [avatar, setAvatar] = useState<{ signature: string; next: File | null }>({
    signature: props.user.avatar as string,
    next: null,
  });
  const [isEditMode, setEditMode] = useState(false);

  const toggleEditMode = () => {
    setEditMode(true);
  };

  const resetData = () => {
    setEditMode(false);
    setUser(props.user!);
    setAvatar({ signature: props.user!.avatar! as string, next: null });
  };

  const hasAccessToEdit = canUser(props.currentUser, "EDIT", "USER", { obj: props.user });

  useEffect(() => {
    setUser(props.user!);
    setAvatar({ signature: props.user!.avatar! as string, next: null });
  }, [props.user]);

  // Event handlers
  const generateOnUserFieldChangeCb = (fieldName: string) => (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setUser({
      ...user,
      [fieldName]: e.target.value,
    });
  };

  const onAvatarChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const MAX_FILE_SIZE = 1024 * 1024 - 1;
    const [file] = e.target.files!;

    if (!file) { return; }

    if (file.size > MAX_FILE_SIZE) {
      showErrorToast("Загрузка аватара не удалась: слишком большой размер файла (> 1 МБ)");
      return;
    }

    const reader = new FileReader();
    reader.onload = e => {
      setAvatar({ ...avatar, signature: e.target!.result as string, next: file });
    };
    reader.readAsDataURL(file);
  };

  const clickInputAvatar = () => {
    inputAvatarRef.current.click();
  };

  const handleSubmit = () => {
    const requests = [] as any;

    if (avatar.next) {
      const formData = new FormData();
      formData.append("avatar", avatar.next);
      requests.push(props.editUserAvatar(user.pk, formData));
    }

    Promise.all([...requests, props.editUserInfo(user.pk, user)]).then(() => {
      setEditMode(false);
    });
  };

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      handleSubmit();
    }
  };

  return (
    <Wrapper>
      <div className="profile-edit-btn">
        {hasAccessToEdit ? (
          !isEditMode ? (
            <button className="btn" type="button" onClick={toggleEditMode}>
              <i className="fas fa-user-edit" />
            </button>
          ) : (
            <>
              <button
                className="btn btn-success d-block"
                disabled={props.editRequestInProcess}
                onClick={handleSubmit}
              >
                {props.editRequestInProcess ? (
                  <i className="fas fa-spin fa-circle-notch" />
                ) : (
                  <i className="fas fa-check" />
                )}
              </button>
              {!props.editRequestInProcess && (
                <button className="btn btn-info d-block" type="button" onClick={resetData}>
                  <i className="fas fa-times" />
                </button>
              )}
            </>
          )
        ) : (
          undefined
        )}
      </div>

      {/* User avatar */}
      <UserAvatar
        user={user}
        size={UserAvatarSize.AUTO}
        isEditMode={isEditMode}
        customAvatar={avatar.signature}
        clickInputAvatar={clickInputAvatar}
      />
      {isEditMode && (
        <input type="file" className="d-none" onChange={onAvatarChange} ref={inputAvatarRef} />
      )}

      <h3 className="profile-username text-center">
        {!isEditMode ? (
          userFullName(user)
        ) : (
          <div className="form-group row">
            <div className="col-sm-12 col-md-6">
              <label>Имя</label>
              <input
                type="text"
                className="form-control"
                value={user.first_name}
                onChange={generateOnUserFieldChangeCb("first_name")}
                onKeyDown={onEnterPress}
                required={true}
              />
            </div>
            <div className="col-sm-12 col-md-6">
              <label>Фамилия</label>
              <input
                type="text"
                className="form-control col"
                value={user.last_name}
                onChange={generateOnUserFieldChangeCb("last_name")}
                onKeyDown={onEnterPress}
                required={true}
              />
            </div>
          </div>
        )}
      </h3>

      <p className="text-center fz-13">
        {!isEditMode ? (
          <UserRoleLabelList user={user} />
        ) : (
          <>
            <label>Краткое описание</label>
            <input type="text" className="form-control" placeholder="Недоступно" disabled={true} />
          </>
        )}
      </p>

      <div className="row social-states">
        <div className="col-6 text-right">
          {!isEditMode ? (
            <a
              href={constructTelegramURLFromUsername(user.telegram)}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-telegram" />
            </a>
          ) : (
            <>
              <label>Telegram</label>
              <input
                type="text"
                className="form-control"
                value={user.telegram ? user.telegram : ""}
                onChange={generateOnUserFieldChangeCb("telegram")}
                onKeyDown={onEnterPress}
                placeholder="Telegram"
                required={true}
              />
            </>
          )}
        </div>
        <div className="col-6 text-left">
          {!isEditMode ? (
            <a
              href={constructSkypeURLFromUsername(user.skype)}
              className="link"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fab fa-skype" />
            </a>
          ) : (
            <>
              <label>Skype</label>
              <input
                type="text"
                className="form-control"
                placeholder="Skype ID"
                value={user.skype ? user.skype : ""}
                onChange={generateOnUserFieldChangeCb("skype")}
                onKeyDown={onEnterPress}
                required={true}
              />
            </>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="profile-user-info">
            <div className="profile-info-block profile-info-block-info">
              {!isEditMode ? (
                <>
                  <span>О себе</span>
                  <div>{user.info}</div>
                </>
              ) : (
                <input
                  type="text"
                  className="form-control"
                  value={user.info ? user.info : ""}
                  onChange={generateOnUserFieldChangeCb("info")}
                  onKeyDown={onEnterPress}
                  required={true}
                />
              )}
            </div>

            <div className="profile-info-block profile-info-block-joined">
              <span>Зарегистрирован:</span>
              <div>{(moment as any)(user.date_joined).format("DD.MM.YYYY")}</div>
            </div>

            <div className="profile-info-block profile-info-block-email">
              {!isEditMode ? (
                <>
                  <span>Email:</span>
                  <div>{user.email}</div>
                </>
              ) : (
                <input
                  type="text"
                  className="form-control"
                  value={user.email ? user.email : ""}
                  onKeyDown={onEnterPress}
                  onChange={generateOnUserFieldChangeCb("email")}
                  required={true}
                />
              )}
            </div>

            <div className="profile-info-block profile-info-block-discord">
              {!isEditMode ? (
                <>
                  <span>Discord:</span>
                  <div>{user.discord ? user.discord : "Не заполнено"}</div>
                </>
              ) : (
                <input
                  type="text"
                  className="form-control"
                  value={user.discord ? user.discord : ""}
                  onChange={generateOnUserFieldChangeCb("discord")}
                  onKeyDown={onEnterPress}
                  placeholder="username#9876"
                  required={true}
                />
              )}
            </div>

            {/* Projects */}
            <div className="profile-info-block">
              <UserProjectsWidget
                user={props.user}
                projects={props.userProjects}
                editUserProjects={props.editUserProjects}
                editAllowed={canUser(props.currentUser, "MODIFY_PROJECT_LIST", "USER", {
                  obj: props.user,
                })}
              />
            </div>

            {/* Permissions select */}
            {canUserChangeUserStatus(props.currentUser, user) && (
              <div className="profile-info-block profile-info-block-status-modification">
                {!user.super_admin ? (
                  user.admin ? (
                    <Button
                      onClick={() => props.changeUserStatusToUser(user.pk)}
                      className={style["status-change-button"]}
                    >
                      {`Изменить статус на "Обычный"`}
                    </Button>
                  ) : (
                    <Button
                      onClick={() => props.changeUserStatusToAdmin(user.pk)}
                      className={style["status-change-button"]}
                    >
                      {`Изменить статус на "Администратор"`}
                    </Button>
                  )
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default connect(
  (store: StoreRootState) => ({
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
    userProjects: userModule.selectors.getCurrentUserProjects(store, null),
    userProjectsIds: userModule.selectors.getCurrentUserProjectsIds(store, null),
    projects: projectsModule.selectors.getProjectsById(store, null),
    projectsListSorted: projectsModule.selectors.getAllProjectsSortedByNameBare(store, null),
    editRequestInProcess: usersModule.selectors.isCurrentlyEditingUser(store, null),
  }),
  getActionsFromModule(usersModule, [
    "fetchUserInfo",
    "editUserInfo",
    "editUserAvatar",
    "changeUserStatusToUser",
    "changeUserStatusToAdmin",
    "editUserProjects",
  ]),
)(ProfileInfoBlock);
