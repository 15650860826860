import Checkbox from "#/components/Checkbox";
import { getActionsFromModule } from "#/store/helpers";
import monitoringModule from "#/store/modules/sitemonitoring";
import tasksModule from "#/store/modules/tasks";
import uiModule, { toggleAudioNotification, toggleTextNotification } from "#/store/modules/ui";
import {
  DispatchProp,
  MProjectSubscriptionInfo,
  StoreRootState,
  TaskSubscriptionInfo,
  ToggleCurrentUserTaskSubscription,
  ToggleMonitoringProjectSubscription,
  UserSettings,
} from "#/store/types";
import { compareTwoStringsAlphabetically } from "#/util";
import _ from "lodash";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import style from "./index.module.scss";
import TabSection from "./TabSection";
import TabWrapper from "./TabWrapper";
import { SettingsPageTab, TabProps } from "./types";

const SHOW_ALWAYS_AMOUNT = 5;

const MProjectsSubscriptionsList = (props: {
  userSettings: UserSettings;
  setUserSettings: any;
  toggleMonitoringProjectSubscription: ToggleMonitoringProjectSubscription;
}) => {
  const { userSettings, setUserSettings } = props;
  const [mprojectsListShow, setMprojectsListShow] = useState(false);
  const toggleMProjectsList = () => setMprojectsListShow(!mprojectsListShow);

  const updateMProjectSubscribedStatus = useCallback(
    (project: MProjectSubscriptionInfo, subscribed: boolean) => {
      setUserSettings({
        ...userSettings,
        mprojects_subscriptions: [
          ...userSettings.mprojects_subscriptions.filter(ps => ps.pk !== project.pk),
          { ...(project as any), active: subscribed },
        ].sort((a, b) => compareTwoStringsAlphabetically(a.name, b.name)),
      });
    },
    [setUserSettings, userSettings],
  );

  const Item = (props_: { project: MProjectSubscriptionInfo }) => {
    return (
      <ListGroupItem className={style["list-item"]}>
        <Checkbox
          checked={props_.project.active}
          onChange={e => {
            props
              .toggleMonitoringProjectSubscription(props_.project.pk)
              .then(subscribed => updateMProjectSubscribedStatus(props_.project, subscribed));
          }}
          className="mr-2"
        />
        <span>{props_.project.name}</span>
      </ListGroupItem>
    );
  };

  return (
    <TabSection name="Мониторинг">
      {userSettings.mprojects_subscriptions.length > 0 ? (
        <>
          <ListGroup>
            {_.take(userSettings.mprojects_subscriptions, SHOW_ALWAYS_AMOUNT).map(
              (s: MProjectSubscriptionInfo) => {
                return <Item key={s.pk} project={s} />;
              },
            )}
          </ListGroup>
          {userSettings.mprojects_subscriptions.length > SHOW_ALWAYS_AMOUNT && (
            <>
              <Collapse isOpen={mprojectsListShow}>
                {_.takeRight(
                  userSettings.mprojects_subscriptions,
                  userSettings.mprojects_subscriptions.length - SHOW_ALWAYS_AMOUNT,
                ).map((s: MProjectSubscriptionInfo) => {
                  return <Item key={s.pk} project={s} />;
                })}
              </Collapse>
              <Button color="transparent" onClick={toggleMProjectsList}>
                {mprojectsListShow ? "Скрыть" : "Показать все"}
              </Button>
            </>
          )}
        </>
      ) : (
        <div>Нет проектов</div>
      )}
    </TabSection>
  );
};

const TaskSubscriptionsList = (props: {
  userSettings: UserSettings;
  setUserSettings: any;
  toggleTaskSubscription: ToggleCurrentUserTaskSubscription;
}) => {
  const { userSettings, setUserSettings } = props;
  const [taskListShow, setTaskListShow] = useState(false);
  const toggleTaskList = () => setTaskListShow(!taskListShow);

  const updateTaskSubscriptionStatus = useCallback(
    (task: TaskSubscriptionInfo, subscribed: boolean) => {
      setUserSettings({
        ...userSettings,
        task_subscriptions: [
          ...userSettings.task_subscriptions.filter(ps => ps.pk !== task.pk),
          { ...(task as any), active: subscribed },
        ].sort((a, b) => compareTwoStringsAlphabetically(a.name, b.name)),
      });
    },
    [setUserSettings, userSettings],
  );

  const Item = (props_: { task: TaskSubscriptionInfo }) => {
    return (
      <ListGroupItem className={style["list-item"]}>
        <Checkbox
          checked={props_.task.active}
          onChange={e => {
            props
              .toggleTaskSubscription(props_.task.pk)
              .then(subscribed => updateTaskSubscriptionStatus(props_.task, subscribed));
          }}
          className="mr-2"
        />
        <span>{props_.task.name}</span>
      </ListGroupItem>
    );
  };

  return (
    <TabSection name="Задачи">
      {userSettings.task_subscriptions.length > 0 ? (
        <>
          <ListGroup>
            {_.take(userSettings.task_subscriptions, SHOW_ALWAYS_AMOUNT).map(
              (s: TaskSubscriptionInfo) => {
                return <Item key={s.pk} task={s} />;
              },
            )}
          </ListGroup>

          {userSettings.task_subscriptions.length > SHOW_ALWAYS_AMOUNT && (
            <>
              <Collapse isOpen={taskListShow}>
                {_.takeRight(
                  userSettings.task_subscriptions,
                  userSettings.task_subscriptions.length - SHOW_ALWAYS_AMOUNT,
                ).map((s: TaskSubscriptionInfo) => {
                  return <Item key={s.pk} task={s} />;
                })}
              </Collapse>
              <Button color="transparent" onClick={toggleTaskList}>
                {taskListShow ? "Скрыть" : "Показать все"}
              </Button>
            </>
          )}
        </>
      ) : (
        <div>Нет задач</div>
      )}
    </TabSection>
  );
};

const NotificationsTab = (
  props: TabProps & {
    textNotification: boolean;
    audioNotification: boolean;
    toggleMonitoringProjectSubscription: ToggleMonitoringProjectSubscription;
    toggleTaskSubscription: ToggleCurrentUserTaskSubscription;
    toggleTextNotification: DispatchProp<typeof toggleTextNotification>;
    toggleAudioNotification: DispatchProp<typeof toggleAudioNotification>;
  },
) => {
  const { userSettings, loadingUserSettings, setUserSettings } = props;
  return (
    <TabWrapper id={SettingsPageTab.NOTIFICATIONS}>
      {userSettings && (
        <>
          <TabSection name="Браузер">
            <ListGroup flush={true}>
              <ListGroupItem className={style["list-item"]}>
                <Checkbox
                  checked={props.textNotification}
                  onChange={e => {
                    props.toggleTextNotification();
                  }}
                  className="mr-2"
                />
                <span>Включить текстовые оповещения</span>
              </ListGroupItem>
              <ListGroupItem className={style["list-item"]}>
                <Checkbox
                  checked={props.audioNotification}
                  onChange={e => {
                    props.toggleAudioNotification();
                  }}
                  className="mr-2"
                />
                <span>Включить звуковые оповещения</span>
              </ListGroupItem>
            </ListGroup>
          </TabSection>

          <MProjectsSubscriptionsList
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            toggleMonitoringProjectSubscription={props.toggleMonitoringProjectSubscription}
          />
          <TaskSubscriptionsList
            userSettings={userSettings}
            setUserSettings={setUserSettings}
            toggleTaskSubscription={props.toggleTaskSubscription}
          />
        </>
      )}
    </TabWrapper>
  );
};

export default connect(
  (store: StoreRootState) => ({
    textNotification: uiModule.selectors.areTextNotificationsEnabled(store, null),
    audioNotification: uiModule.selectors.areAudioNotificationsEnabled(store, null),
  }),
  {
    ...getActionsFromModule(monitoringModule, [
      "subscribeToMonitoringProject",
      "unsubscribeFromMonitoringProject",
      "toggleMonitoringProjectSubscription",
    ]),
    ...getActionsFromModule(tasksModule, ["toggleTaskSubscription"]),
    ...getActionsFromModule(uiModule, ["toggleAudioNotification", "toggleTextNotification"]),
  },
)(NotificationsTab);
