import { apiPut } from "#/api";
import Editor from "#/components/Editor";
import TaskChecklistMinimal from "#/components/TaskChecklist/TaskChecklistMinimal";
import { showErrorToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import { CompleteTaskData, User } from "#/store/types";
import { getTaskDeadlineString, sortByKey, userFullName } from "#/util";
import classnames from "classnames";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse } from "reactstrap";
import style from "../../index.module.scss";
import "./index.scss";

interface ExpandRowConnectedProps {}

interface ExpandRowOwnProps {
  item: CompleteTaskData;
  changeTask: any;
  isManager: boolean;
  isPerformer: boolean;
  isOpen: boolean;
  taskFromUser: User | null;
}

type ExpandRowProps = ExpandRowConnectedProps & ExpandRowOwnProps;

const ExpandRow = (props: ExpandRowProps) => {
  const [item, setItem] = useState<CompleteTaskData>(props.item);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  useEffect(() => {
    setItem(props.item);
  }, [props.item]);

  const toggleChecklistItem = React.useCallback(
    async (pk: number, status: any) => {
      setFetching(true);
      try {
        const checklistElement = await apiPut(API_ROUTES.CRMPLAN.CHECKLIST_ITEM(pk), { status });
        const checklist = [...item.checklists];
        const cIndex = checklist.findIndex(c => c.pk === pk);
        if (cIndex >= 0) {
          checklist[cIndex] = checklistElement;
        }
        setItem({
          ...item,
          checklists: checklist,
        });
        setFetching(false);
        return checklistElement.status;
      } finally {
        setFetching(false);
      }
    },
    [item],
  );

  const toggleEditMode = () => {
    setItem(isEditMode ? props.item : item);
    setEditMode(!isEditMode);
  };

  const save = () => {
    if (isEqual(item.data, props.item.data)) {
      toggleEditMode();
      return;
    }
    props
      .changeTask(item.data)
      .then(() => {
        toggleEditMode();
      })
      .catch((err: any) => {
        showErrorToast("Произошла ошибка при редактировании задачи...");
      });
  };

  const onDescriptionChange = (val: string) => {
    setItem({
      ...item,
      data: { ...item.data, description: val },
    });
  };

  const onCommentChange = (val: string) => {
    setItem({
      ...item,
      data: { ...item.data, status_comment: val },
    });
  };

  const { isManager, isPerformer } = props;
  const taskFrom = props.taskFromUser;
  const deadline = getTaskDeadlineString(item.data);
  const taskFromFullName = userFullName(taskFrom);
  const hasAccessToShowComment = (isManager && !isEmpty(item.data.status_comment)) || isPerformer;
  const itemDescription = item.data.description;
  const itemComment = item.data.status_comment;

  const renderedChecklist = React.useMemo(() => {
    return (
      <TaskChecklistMinimal
        checklist={item.checklists}
        toggleItem={toggleChecklistItem}
        toggling={fetching}
        canToggle={isManager || isPerformer}
      />
    );
  }, [item.checklists, toggleChecklistItem, fetching, isManager, isPerformer]);

  return (
    <tr>
      <td
        className={classnames(["hiddenRow", style["task-expand-row"]])}
        colSpan={Object.keys(item.data).length}
      >
        <Collapse isOpen={props.isOpen}>
          <div className="card card-body card-body-expandrow">
            <div className="row">
              <div className="col-md-6">
                <div className="expandrow-block">
                  <span className="expandrow-title">Описание задачи:</span>
                  {isManager ? (
                    isEditMode ? (
                      <>
                        <Editor
                          onChildChange={onDescriptionChange}
                          contentValue={itemDescription}
                        />

                        <div className="mt-1 mb-1">
                          <button
                            className="btn btn-success row-action without-margin"
                            onClick={save}
                          >
                            <i className="fas fa-check" />
                          </button>
                          <button
                            className="btn btn-danger row-action without-margin rm"
                            onClick={toggleEditMode}
                          >
                            <i className="fas fa-times" />
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        <div>
                          <div
                            className="html-text"
                            dangerouslySetInnerHTML={{
                              __html: itemDescription,
                            }}
                          />
                          <button
                            className={`btn btn-list row-action small-opacity ${
                              isEmpty(itemDescription) ? "without-margin" : ""
                            }`}
                            onClick={toggleEditMode}
                          >
                            <i className="fas fa-pen-alt" />
                          </button>
                        </div>
                      </>
                    )
                  ) : (
                    <div
                      className="expandrow-content html-text"
                      dangerouslySetInnerHTML={{
                        __html: itemDescription,
                      }}
                    />
                  )}
                </div>

                <div className="d-flex justify-content-between">
                  <div>
                    <div className="expandrow-block">
                      <span className="expandrow-title d-block">Кто назначил:</span>
                      <Link to={`/profile/${item.data.task_from}`}>{taskFromFullName}</Link>
                    </div>
                  </div>
                  <div>
                    <div className="expandrow-block">
                      <span className="expandrow-title d-block">До завершения:</span>

                      <span className={`d-block ${deadline === "Просрочена" ? "text-danger" : ""}`}>
                        {deadline}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="expandrow-block">
                  {hasAccessToShowComment && (
                    <>
                      <span className="expandrow-title d-block">Комментарий:</span>

                      {isManager && !isPerformer && (
                        <div
                          className="html-text"
                          dangerouslySetInnerHTML={{
                            __html: itemComment,
                          }}
                        />
                      )}

                      {isPerformer &&
                        (isEditMode ? (
                          <>
                            <Editor onChildChange={onCommentChange} contentValue={itemComment} />
                            <div className="mt-1 mb-1">
                              <button
                                className="btn btn-success row-action without-margin"
                                onClick={save}
                              >
                                <i className="fas fa-check" />
                              </button>
                              <button
                                className="btn btn-danger row-action without-margin rm"
                                onClick={toggleEditMode}
                              >
                                <i className="fas fa-times" />
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <div
                                className="html-text"
                                dangerouslySetInnerHTML={{
                                  __html: itemComment,
                                }}
                              />
                              <button
                                className={`btn row-action small-opacity ${
                                  isEmpty(itemComment) ? "without-margin" : ""
                                }`}
                                onClick={toggleEditMode}
                              >
                                <i className="fas fa-pen-alt" />
                              </button>
                            </div>
                          </>
                        ))}
                    </>
                  )}
                </div>
              </div>
              <div className="col-md-6 checklist-box">{renderedChecklist}</div>
            </div>
          </div>
        </Collapse>
      </td>
    </tr>
  );
};

export default ExpandRow;
