import { apiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import { Top50Project, Top50QueryPosition, Top50Url } from "#/store/types";
import { extractHostname } from "#/util";
import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import "./index.scss";
import Header from "../Header";
import Loader from "#/components/Loader";
import YandexFavicon from "#/components/YandexFavicon";
import PageWrapper, { PageContent } from "#/components/PageWrapper";

interface QueryPositionsConnectedProps {
  match: any;
}

interface QueryPositionsOwnProps {}

type QueryPositionsProps = QueryPositionsConnectedProps & QueryPositionsOwnProps;

const QueryPositions = (props: QueryPositionsProps) => {
  // Query id
  const { id } = props.match.params;
  // State
  const [loading, setLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [positions, setPositions] = useState<Top50QueryPosition[]>([]);
  const [projects, setProjects] = useState<Top50Project[]>([]);
  const [uniquePositions, setUniquePositions] = useState<string[]>([]);
  const [currentActive, setCurrentActive] = useState<string>("");
  // On initial load
  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await apiPost(API_ROUTES.TOP50.QUERYPAGES_POSITIONS, { id });
        setQuery(response.query);
        setProjects(response.projects);
        setPositions(response.positions);
        setUniquePositions(response.unique_positions);
      } finally {
        setLoading(false);
      }
    })();
  }, []);
  const onUrlHover = React.useCallback((url: string) => setCurrentActive(url), []);
  const onUrlHoverOff = React.useCallback(() => setCurrentActive(""), []);
  const onPositionDateChange = React.useCallback(
    (index: number, date: Date) => {
      const newDate = date.toISOString().split("T")[0];
      apiPost(API_ROUTES.TOP50.QUERYPAGES_POSITIONS_BY_DATE, {
        id,
        index,
        date: newDate,
      }).then(response => {
        setPositions(
          positions.map((currPos: any) => ({
            ...currPos,
            urls_json: response.positions,
            pub_date: date,
          })),
        );
      });
    },
    [positions],
  );

  const uniqueBlockToRender = React.useMemo(
    () =>
      uniquePositions.map((pos, posIndex) => {
        const domain = extractHostname(pos);
        return (
          <a
            href={pos}
            rel="noopener noreferrer"
            target="_blank"
            className={[
              "d-block",
              currentActive.toLowerCase() === domain.toLowerCase()
                ? "query-position-active"
                : "query-position-inactive",
              projects.some(
                (p: Top50Project) =>
                  extractHostname(p.title).toLowerCase() === domain.toLowerCase(),
              )
                ? "query-position-friendly"
                : "query-position-unfriendly",
            ].join(" ")}
            onMouseEnter={() => onUrlHover(domain)}
            onMouseLeave={onUrlHoverOff}
            key={pos + posIndex}
          >
            <YandexFavicon name={domain} alt="project fav" />
            <span className="ml-2">{domain}</span>
          </a>
        );
      }),
    [uniquePositions, onUrlHover, onUrlHoverOff, projects, currentActive],
  );

  const blocksToRender = React.useMemo(
    () =>
      positions.map((pos: Top50QueryPosition, posIndex: number) => {
        const urlsToRender = pos.urls_json.length ? (
          pos.urls_json.map((url: Top50Url, urlIndex: number) => {
            const domain = extractHostname(url);
            return (
              <a
                href={url}
                rel="noopener noreferrer"
                target="_blank"
                className={[
                  "d-block",
                  currentActive.toLowerCase() === domain.toLowerCase()
                    ? "query-position-active"
                    : "query-position-inactive",
                  projects.some(
                    (p: Top50Project) =>
                      extractHostname(p.title).toLowerCase() === domain.toLowerCase(),
                  )
                    ? "query-position-friendly"
                    : "query-position-unfriendly",
                ].join(" ")}
                onMouseEnter={() => onUrlHover(domain)}
                onMouseLeave={onUrlHoverOff}
                key={urlIndex}
              >
                {domain}
              </a>
            );
          })
        ) : (
          <div />
        );
        return (
          <div className="col mt-2" key={posIndex}>
            <DatePicker
              className="form-control form-control-small-height d-block fz-13"
              selected={new Date(pos.pub_date)}
              dateFormat="yyyy-MM-dd"
              onChange={d => d && onPositionDateChange(posIndex, d)}
              locale="ru-RU"
              shouldCloseOnSelect={true}
              placeholderText="Выберите дату окончания"
              required={true}
            />
            <div className="mt-2">{urlsToRender}</div>
          </div>
        );
      }),
    [positions, currentActive, projects, onUrlHover, onUrlHoverOff, onPositionDateChange],
  );

  return (
    <PageWrapper>
      <Header />
      <PageContent>
        <div className="box h-100">
          {loading && <Loader />}
          {!loading && (
            <>
              <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                <h4 className="box-title">
                  <span>Запрос: {query}</span>
                </h4>
              </div>
              <div className="box-body">
                <div className="row topf-query-positions">
                  <div className="col mt-2 query-positions-unique">
                    <h6 className="ml-1 text-bold">Все ссылки</h6>
                    {uniqueBlockToRender}
                  </div>
                  {blocksToRender}
                </div>
              </div>
            </>
          )}
        </div>
      </PageContent>
    </PageWrapper>
  );
};

export default QueryPositions;
