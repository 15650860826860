import { PrimaryKey, Top50Event, Top50Query, Top50Configuration } from "#/store/types";
import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import { EMPTY_SELECTION_REGISTRY, SelectionRegistry } from "#/util/selectionRegistry";
import React from "react";
import { Top50Group } from "../../types";

export interface IQueryListContext {
  querySelection: SelectionRegistry;
  removeSelectedQueryPages: () => void;
  groups: Top50Group[];
  removeCurrentEventGroup: () => Promise<void>;
  createNewRequests: (body: Partial<Top50Event>) => Promise<void>;
  activeGroup: Top50Group | null;
  setActiveGroupId: (g: PrimaryKey | null) => void;
  fetching: boolean;
  toggleEvent: (pk: PrimaryKey, s: boolean) => Promise<boolean>;
  importIntoGroup: (groupPk: PrimaryKey, queries: any[]) => Promise<void>;
  queriesById: Record<PrimaryKey, Top50Query>;
  loadingActiveEvent: boolean;
  setSelectedConfig: (c: PrimaryKey | null) => void;
  selectedConfig: PrimaryKey | null;
}

const QueryListContext = React.createContext<IQueryListContext>({
  querySelection: EMPTY_SELECTION_REGISTRY,
  removeSelectedQueryPages: NON_REACHABLE_EMPTY_FUNCTION,
  groups: [],
  removeCurrentEventGroup: NON_REACHABLE_EMPTY_FUNCTION,
  createNewRequests: NON_REACHABLE_EMPTY_FUNCTION,
  activeGroup: null,
  setActiveGroupId: NON_REACHABLE_EMPTY_FUNCTION,
  fetching: false,
  toggleEvent: NON_REACHABLE_EMPTY_FUNCTION,
  importIntoGroup: NON_REACHABLE_EMPTY_FUNCTION,
  queriesById: {},
  loadingActiveEvent: false,
  selectedConfig: null,
  setSelectedConfig: NON_REACHABLE_EMPTY_FUNCTION,
});

export default QueryListContext;
