import { getActionsFromModule } from "#/store/helpers";
import esModule from "#/store/modules/eventSystem";
import { subscribeToEvent, unsubscribeFromEvent } from "#/store/modules/eventSystem/actions";
import searchModule from "#/store/modules/search";
import { DispatchProp, StoreRootState, SubmitEvent } from "#/store/types";
import { constructSearchPagePath } from "#/util";
import { useDebounced } from "#/util/hooks";
import assert from "assert";
import cn from "classnames";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import style from "../../index.module.scss";

interface SearchOwnProps {}

interface SearchConnectedProps {
  location: Location;
  push: typeof push;
  query: string | null;
  setQuery: (q: string | null) => void;
  subscribeToEvent: DispatchProp<typeof subscribeToEvent>;
  unsubscribeFromEvent: DispatchProp<typeof unsubscribeFromEvent>;
}

type SearchProps = SearchOwnProps & SearchConnectedProps;

const Search = (props: SearchProps) => {
  const [value, setValue] = useState<string>("");
  const debouncedSuggestionValue = useDebounced(value, 1000);
  const inputRef = useRef<HTMLInputElement>(null);

  const onFocusSearch = useCallback(() => {
    const elem = inputRef.current!;
    assert(elem);
    // Already focused
    if (document.activeElement === elem) {
      return;
    }
    elem.focus();
  }, []);

  useEffect(() => {
    props.subscribeToEvent("FOCUS_SEARCH", onFocusSearch);
    return () => {
      props.unsubscribeFromEvent("FOCUS_SEARCH", onFocusSearch);
    };
  }, []);

  useEffect(() => {
    // TODO: Implement suggestions
  }, [debouncedSuggestionValue]);

  const onValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  const onSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    if (value.length <= 0) {
      return;
    }
    props.setQuery(value);
    props.push(constructSearchPagePath(value));
  };

  useEffect(() => {
    setValue(props.query!);
  }, [props.query]);

  const [focused, setFocused] = useState(false);

  const onInputFocus = useCallback(() => _.delay(() => setFocused(true), 5), [setFocused]);
  const onInputBlur = useCallback(() => setFocused(false), [setFocused]);

  return (
    <div className={style["search-container"]}>
      <form onSubmit={onSubmit}>
        <Input
          innerRef={inputRef}
          className={cn([style["search-input"], "form-control"])}
          value={value ? value : ""}
          onChange={onValueChange}
          placeholder="Поиск"
          readOnly={!focused}
          onFocus={onInputFocus}
          onBlur={onInputBlur}
        />
      </form>
    </div>
  );
};

export default connect(
  (store: StoreRootState) => {
    return {
      location: store.router.location,
      query: searchModule.selectors.getQuery(store, null),
    };
  },
  {
    push,
    ...getActionsFromModule(searchModule, ["setQuery"]),
    ...getActionsFromModule(esModule, ["subscribeToEvent", "unsubscribeFromEvent"]),
  },
)(Search);
