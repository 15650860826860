import { createReducer } from "#/store/helpers";
import { KeybindingSystemState } from "#/store/types";

export const initialState: KeybindingSystemState = {
  keybindings: {
    "alt+s": "SAVE",
    "alt+a": "SELECT_ALL",
    "alt+r": "RUN_MONITORING",
    "alt+b": "TOGGLE_SIDEBAR",
    "alt+t": "OPEN_DASHBOARD",
    "alt+p": "GOTO_PROJECT",
    "alt+u": "GOTO_USER",
    "/": "FOCUS_SEARCH",
    "alt+shift+a": "ADD_MONITORING_URLS",
  },
};

export const reducer = createReducer<KeybindingSystemState, any>({}, initialState);

export default reducer;
