import {
  MProjectCheckSuccessfulNotification,
  TaskIsCompletedNotification,
  TaskStatusChangedNotification,
  TaskWithNoExecWasCreatedNotification,
  UserNewTaskMessageNotification,
  UserNotification,
  UserNotificationDerived,
  UserTaskDeadlineComingNotification,
  UserTaskWasAssignedNotification,
  UserTaskWasUpdatedNotification,
} from "#/store/types";
import { NotificationKind } from "#/types";
import { falseIfUndefinedElseBoolean } from "#/util";
import assert from "assert";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import MProjectCheckSuccessfulNotificationItem from "./MProjectCheckSuccessfulNotificationItem";
import NewTaskMessageNotificationItem from "./NewTaskMessageNotification";
import TaskDeadlineComingNotificationItem from "./TaskDeadlineComingNotification";
import TaskIsCompletedNotificationItem from "./TaskIsCompleteNotification";
import TaskStatusChangedNotificationItem from "./TaskStatusChangedNotificationItem";
import TaskWasAssignedToYouNotificationItem from "./TaskWasAssignedToYouNotification";
import TaskWasUpdatedNotificationItem from "./TaskWasUpdatedNotification";
import TaskWithNoExecWasCreatedNotificationItem from "./TaskWithNoExecWasCreatedNotificationItem";

const NotificationMenuItem = (props: {
  notification: UserNotificationDerived;
  push: typeof push;
  onItemRead: (item: UserNotification) => void;
  bottomSentinel?: boolean;
  onBottomSentinelInView?: () => void;
}) => {
  const notification = props.notification;
  const baseNotification = notification.notification_model;
  const [ref, inView, entry] = useInView();

  useEffect(() => {
    // Read mechanism
    if (inView && !baseNotification.read) {
      props.onItemRead(baseNotification);
    }
    // Sentinel
    if (inView && falseIfUndefinedElseBoolean(props.bottomSentinel)) {
      assert(props.onBottomSentinelInView);
      props.onBottomSentinelInView!();
    }
  }, [inView]);

  const commonDerivedNotificationProps = {
    baseNotification: notification,
    push: props.push,
    ref,
  };


  const notificationKindToRendererMap: Record<NotificationKind, any> = {
    TASK_DEADLINE_COMING: (n: UserTaskDeadlineComingNotification) => (
      <TaskDeadlineComingNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    NEW_TASK_MESSAGE: (n: UserNewTaskMessageNotification) => (
      <NewTaskMessageNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    TASK_WAS_UPDATED: (n: UserTaskWasUpdatedNotification) => (
      <TaskWasUpdatedNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    TASK_WAS_ASSIGNED_TO_YOU: (n: UserTaskWasAssignedNotification) => (
      <TaskWasAssignedToYouNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    TASK_IS_COMPLETED: (n: TaskIsCompletedNotification) => (
      <TaskIsCompletedNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    TASK_STATUS_CHANGED: (n: TaskStatusChangedNotification) => (
      <TaskStatusChangedNotificationItem notification={n} {...commonDerivedNotificationProps} />
    ),
    MPROJECT_CHECK_SUCCESSFUL: (n: MProjectCheckSuccessfulNotification) => (
      <MProjectCheckSuccessfulNotificationItem
        notification={n}
        {...commonDerivedNotificationProps}
      />
    ),
    TASK_WITH_NO_EXEC_WAS_CREATED: (n: TaskWithNoExecWasCreatedNotification) => (
      <TaskWithNoExecWasCreatedNotificationItem
        notification={n}
        {...commonDerivedNotificationProps}
      />
    ),
  };

  const renderer = _.get(notificationKindToRendererMap, notification.kind, null);
  if (renderer) {
    return renderer(notification);
  } else {
    assert(false, `Received invalid notification kind: ${notification.kind}`);
    return null;
  }
};

export default NotificationMenuItem;
