import { PrimaryKey } from "#/store/types";

enum TokenPermissions {
  edit = "edit",
  own = "own",
  read = "read",
}

export interface Counter {
  id: PrimaryKey;
  name: string;
  tokens: PrimaryKey[];
  goals: PrimaryKey[];
}

export enum MetrikaGoalTypes {
  action = "action",
  url = "url",
  step = "step",
}

export enum MetrikaGoalConditionTypes {
  regexp = "regexp",
  contain = "contain",
  start = "start",
  exact = "exact",
  action = "action",
}

export interface BasicGoal {
  type: MetrikaGoalTypes;
  name: string;
  id: PrimaryKey;
  comment?: string;
}

export interface Condition {
  type: MetrikaGoalConditionTypes;
  url: string;
}
export interface NonStepGoal extends BasicGoal {
  conditions: Condition[];
}

export interface ActionGoal extends NonStepGoal {
  type: MetrikaGoalTypes.action;
}

export interface UrlGoal extends NonStepGoal {
  type: MetrikaGoalTypes.url;
}

export interface StepGoal extends BasicGoal {
  type: MetrikaGoalTypes.step;

  steps: PrimaryKey[];
}

export type Goal = ActionGoal | UrlGoal | StepGoal;

// Goals without id used in creation

type WithoutId<T> = Omit<T, "id">;

export type ActionGoalWithoutId = WithoutId<ActionGoal>;
export type UrlGoalWithoutId = WithoutId<UrlGoal>;
export type StepGoalWithoutId = WithoutId<BasicGoal> & {
  type: MetrikaGoalTypes.step;
  steps: Array<ActionGoalWithoutId | UrlGoalWithoutId>;
};
export type GoalWithoutId = ActionGoalWithoutId | UrlGoalWithoutId | StepGoalWithoutId;

export interface NestedStepGoal extends BasicGoal {
  type: MetrikaGoalTypes.step;
  step: Array<ActionGoal | UrlGoal>;
}

export type TableGoal = (ActionGoal | UrlGoal | NestedStepGoal) & {
  counterName: string;
};

// The problem with having so much goal types is that we need them in different forms.
// In 1 case we need step goal have only id's of its steps ( as we store it in our canonical state)
// In other case we need to need to have them as the nested structure with id's ( like we do in table).
// In another we don't need id, but need to store steps right in "steps" property
// Not as id's ( in creation ), i should do something with this in the future
export interface Token {
  value: string;
  id: PrimaryKey;
  failedFetching: boolean;
  counters: PrimaryKey[];
}

export interface MetrikaGoalsState {
  tokens: Record<string, Token>;
  counters: Record<PrimaryKey, Counter>;
  fetching: boolean;
  goals: Record<PrimaryKey, Goal>;
  selection: Record<PrimaryKey, PrimaryKey>;
  creatingGoal: GoalWithoutId;
  selectedGoals: PrimaryKey[];
}

export enum ConditionTypes {}
