import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const ScrollSentinel = (props: { whenReached: () => void }) => {
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      props.whenReached();
    }
  }, [inView]);
  return <div ref={ref} />;
};

export default ScrollSentinel;
