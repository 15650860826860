import { WS_API_MAIN_URL } from "#/conf/api";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import { UserNotification } from "#/store/types";
import assert from "assert";
import _ from "lodash";
import { Dispatch } from "redux";
import * as ActionTypes from "./actionTypes";
import MutationType from "./mutationType";
import selectors from "./selectors";

const closeMainWebSocketSucceed = (): ActionTypes.CloseMainWebSocketSucceedAction => ({
  type: MutationType.CLOSE_MAIN_WS,
});

export const closeMainWebSocket = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    const ws = selectors.getMainWebSocketInstance(getState(), null);
    if (ws === null) {
      return reject(new Error("Main web socket is already closed"));
    } else {
      ws.close();
      dispatch(closeMainWebSocketSucceed());
      return resolve();
    }
  });
};

const setupMainWebSocketSucceed = (ws: WebSocket): ActionTypes.SetupMainWebSocketSucceedAction => ({
  type: MutationType.SETUP_MAIN_WS,
  payload: { ws },
});

export const setupMainWebSocket = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    const websocketAlreadyOpened = !selectors.isMainWebSocketClosed(getState(), null);
    if (websocketAlreadyOpened) {
      console.info("websocket already opened, returning...");
      return resolve();
    }

    const authenticated = sessionModule.selectors.isAuthenticated(getState(), null);
    if (!authenticated) {
      return reject(new Error("Not authenticated"));
    }

    const currentUser = sessionUsersModule.selectors.getCurrentUser(getState(), null);
    assert(currentUser);

    const ws = new WebSocket(WS_API_MAIN_URL(currentUser!.pk));

    type WSMessageHandler = (messageData: any) => void;
    type WSMessageType = "NOTIFICATION_ADDED";

    const handlers: Record<WSMessageType, WSMessageHandler> = {
      NOTIFICATION_ADDED: (notification: UserNotification) => {
        dispatch(sessionModule.actions.addNotification(notification));
        sessionModule.actions.incrementUnreadNotifsAmount();
      },
    };

    const handleMainWSMessage = (messageType: WSMessageType, messageData: any) => {
      if (!(messageType in handlers)) {
        console.error(`handleMainWSMessage: Message type ${messageType} is not handled currently.`);
        return false;
      }
      const f = _.get(handlers, messageType, () => {
        console.error(`Unhanbled WS message: ${messageType}`);
      });
      return f(messageData);
    };

    ws.addEventListener("close", () => {
      if (!selectors.isMainWebSocketClosed(getState(), null)) {
        dispatch(closeMainWebSocket() as any);
      }
    });

    ws.addEventListener("message", event => {
      const message = event.data;
      const messageJson = JSON.parse(message);
      const messageType = messageJson.type;
      const messageData = messageJson.message;
      handleMainWSMessage(messageType, messageData);
    });

    dispatch(setupMainWebSocketSucceed(ws));
    return resolve();
  });
};

export default { closeMainWebSocket, setupMainWebSocket };
