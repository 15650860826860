import Select, { SelectCustomProps } from "#/components/Select";
import projectsModule from "#/store/modules/projects";
import { CRMProject, PrimaryKey, ProjectsByIdBare, StoreRootState } from "#/store/types";
import { nullOr, projectName, undefinedOr } from "#/util";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

interface ProjectSelectConnectedProps {
  projectsById: ProjectsByIdBare;
}

type ProjectDisplayFunction = (p: CRMProject | null) => string;

type ProjectSelectOwnProps = Omit<SelectCustomProps, "value" | "onChange" | "type"> & {
  value: PrimaryKey | null;
  projects: CRMProject[];
  className?: string;
  onChange: (newValue: PrimaryKey | null) => void;
  disabled?: boolean;
  required?: boolean;
  invalid?: boolean;
  defaultOption?: string;
  displayFunction?: ProjectDisplayFunction;
};

type ProjectSelectProps = ProjectSelectConnectedProps & ProjectSelectOwnProps;

interface SelectOption {
  value: string;
  label: string;
}

const VALUE_NOT_SET = "default-value";

const ProjectSelect = (props: ProjectSelectProps) => {
  const defaultDisplayFunction: ProjectDisplayFunction = (p: CRMProject | null) => projectName(p);
  const df: ProjectDisplayFunction = props.displayFunction
    ? props.displayFunction
    : defaultDisplayFunction;
  const value: string = nullOr((props.value as any) as string, VALUE_NOT_SET);

  const defaultOption: SelectOption = {
    value: VALUE_NOT_SET,
    label: undefinedOr(props.defaultOption, "Выберите"),
  };

  const options: SelectOption[] = props.projects.map(el => {
    return { value: _.toString(el.pk), label: df(el) };
  });

  const selectedOption: SelectOption =
    value === VALUE_NOT_SET
      ? defaultOption
      : (() => {
          const selected = _.get(props.projectsById, _.toNumber(value), null);
          return {
            value: _.toString(value),
            label: df(selected),
          };
        })();

  return (
    <Select
      {..._.omit(props, ["defaultOption"])}
      defaultValue={defaultOption}
      options={options}
      value={selectedOption}
      onChange={(o: any) => {
        props.onChange(o.value !== VALUE_NOT_SET ? _.toNumber(o.value) : null);
      }}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default connect((store: StoreRootState) => ({
  projectsById: projectsModule.selectors.getProjectsByIdBare(store, null),
}))(ProjectSelect);
