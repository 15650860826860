import { getActionsFromModule } from "#/store/helpers";
import esm from "#/store/modules/eventSystem";
import { PrimaryKey, StoreRootState } from "#/store/types";
import _ from "lodash";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { TabPane } from "reactstrap";
import ProjectContext from "../../ProjectContext";
import { TabPage, UrlToggleAction } from "../../util";
import AddUrlModal from "./components/AddUrlModal";
import UrlItem from "./components/UrlItem";

interface ConnectedDispatchProps {
  subscribeToEvent: typeof esm.actions.subscribeToEvent;
  unsubscribeFromEvent: typeof esm.actions.unsubscribeFromEvent;
}

type Props = ConnectedDispatchProps;

const UrlComponent = (props: Props) => {
  const projectContext = useContext(ProjectContext);
  const { project, removeUrls, toggleUrls, urls, addUrls } = projectContext;

  // URL creation modal
  const [isCreateURLModalOpen, setCreateURLModalOpen] = useState<boolean>(false);
  const toggleCreateURLModal = useCallback(() => setCreateURLModalOpen(!isCreateURLModalOpen), [
    isCreateURLModalOpen,
    setCreateURLModalOpen,
  ]);

  // Item selection
  const [selectedSettingsUrls, setSelectedSettingsUrls] = useState<Record<PrimaryKey, boolean>>({});
  const isItemSelected = useCallback((pk: PrimaryKey) => _.get(selectedSettingsUrls, pk, false), [
    selectedSettingsUrls,
  ]);
  const toggleItemsSelection = (rowIds: PrimaryKey[]) => {
    setSelectedSettingsUrls({
      ...selectedSettingsUrls,
      ...rowIds.reduce((acc, pk) => ({ ...acc, [pk]: !isItemSelected(pk) }), {}),
    });
  };
  const toggleItemSelection = (rowId: PrimaryKey) => {
    setSelectedSettingsUrls({
      ...selectedSettingsUrls,
      [rowId]: !isItemSelected(rowId),
    });
  };
  const toggleAllSelectionItems = () => toggleItemsSelection(urls.map(item => item.pk));
  const resetSelected = () => setSelectedSettingsUrls({});

  useEffect(() => {
    props.subscribeToEvent("ADD_MONITORING_URLS", toggleCreateURLModal);
    return () => {
      props.unsubscribeFromEvent("ADD_MONITORING_URLS", toggleCreateURLModal);
    };
  }, []);

  // Reset selection on urls update
  useEffect(() => {
    resetSelected();
  }, [urls]);

  const selectedIds: PrimaryKey[] = useMemo(
    () =>
      _.keys(selectedSettingsUrls)
        .map(_.toNumber)
        .filter(a => isItemSelected(a)),
    [selectedSettingsUrls],
  );

  const removeSelectedUrls = useCallback(() => {
    if (selectedIds.length > 0) {
      removeUrls(selectedIds);
    }
  }, [selectedIds, removeUrls, resetSelected]);

  const toggleSelectedUrls = useCallback(
    (action: UrlToggleAction) => {
      if (selectedIds.length > 0) {
        toggleUrls(selectedIds, action);
      }
    },
    [selectedIds, toggleUrls, resetSelected],
  );

  const toggleItemStatus = (pk: PrimaryKey, action: UrlToggleAction) => toggleUrls([pk], action);

  // Initial render
  useEffect(() => {
    props.subscribeToEvent("SELECT_ALL", toggleAllSelectionItems);
    return () => {
      props.unsubscribeFromEvent("SELECT_ALL", toggleAllSelectionItems);
    };
  }, []);

  const areAllUrlItemsChecked = useMemo(() => _.every(urls, u => _.includes(selectedIds, u.pk)), [
    selectedIds,
    urls,
  ]);

  return (
    <TabPane tabId={TabPage.URL_SETTINGS} id="#urls">
      <div className="content-inner-topvisor">
        <div className="url-component-control-panel">
          <div>
            <h2 className="mb-0">Страниц для проверки: {urls.length}</h2>
          </div>
          <div className="buttons-control">
            <button className="btn btn-list mr-2" onClick={toggleCreateURLModal}>
              <i className="fas fa-plus" />
            </button>
            <button className="btn btn-list row-action mr-2" onClick={removeSelectedUrls}>
              Удалить ({selectedIds.length})
            </button>
            <button
              className="btn btn-list row-action mr-2"
              onClick={() => toggleSelectedUrls("1")}
            >
              Активировать
            </button>
            <button className="btn btn-list row-action" onClick={() => toggleSelectedUrls("0")}>
              Деактивировать
            </button>
          </div>
        </div>
        <div className="bar topvisor-content-main">
          <div className="topvisor-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th style={{ width: 1 }}>
                    <span className="table-checkbox">
                      <label className="table-checkbox-label">
                        <input
                          type="checkbox"
                          value="0"
                          checked={areAllUrlItemsChecked}
                          onChange={toggleAllSelectionItems}
                          disabled={false}
                        />
                        &nbsp;
                        <span />
                      </label>
                    </span>
                  </th>
                  <th className="url">
                    <input type="text" className="form-control" placeholder="URL" />
                  </th>
                  <th className="changes" style={{ width: 1 }}>
                    Состояние
                  </th>
                </tr>
              </thead>
              <tbody>
                {urls.map((u, i) => {
                  return (
                    <UrlItem
                      u={u}
                      key={i}
                      checked={isItemSelected(u.pk)}
                      setChecked={toggleItemSelection}
                      toggleUrl={toggleItemStatus}
                      toggling={false}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AddUrlModal onAdd={addUrls} isOpen={isCreateURLModalOpen} toggle={toggleCreateURLModal} />
    </TabPane>
  );
};

export default connect(
  (store: StoreRootState) => ({}),
  {
    ...getActionsFromModule(esm, ["subscribeToEvent", "unsubscribeFromEvent"]),
  },
)(UrlComponent);
