export enum MutationType {
  FETCH_CURRENT_USER_INFO_SUCCEED = "session/FETCH_CURRENT_USER_INFO_SUCCEED",
  FETCH_CURRENT_USER_INFO_FAIL = "session/FETCH_CURRENT_USER_INFO_FAIL",
  FETCH_CURRENT_USER_INFO_START = "session/FETCH_CURRENT_USER_INFO_START",

  USER_LOGOUT = "session/USER_LOGOUT",

  AUTHENTICATE_START = "session/AUTHENTICATE_START",
  AUTHENTICATE_SUCCEED = "session/AUTHENTICATE_SUCCEED",
  AUTHENTICATE_FAIL = "session/AUTHENTICATE_FAIL",
  // TODO: Remove this
  LOGIN_CLEAR_ERROR = "LOGIN_CLEAR_ERROR",

  FETCH_CURRENT_USER_NOTIFICATIONS_FAIL = "session/FETCH_CURRENT_USER_NOTIFICATIONS_FAIL",
  FETCH_CURRENT_USER_NOTIFICATIONS_START = "session/FETCH_CURRENT_USER_NOTIFICATIONS_START",
  FETCH_CURRENT_USER_NOTIFICATIONS_SUCCEED = "session/FETCH_CURRENT_USER_NOTIFICATIONS_SUCCEED",

  ADD_NOTIFICATIONS = "session/ADD_NOTIFICATIONS",
  MARK_NOTIFICATIONS_AS_READ = "session/MARK_NOTIFICATIONS_AS_READ",
  SET_UNREAD_NOTIFS_AMOUNT = "session/SET_UNREAD_NOTIFS_AMOUNT",
}

export default MutationType;
