import UserAvatar, { UserAvatarSize } from "#/components/UserAvatar";
import usersModule from "#/store/modules/users";
import { StoreRootState, User, UserRolesById } from "#/store/types";
import { constructUserPath, userFullName, userRolesToStringList } from "#/util";
import cn from "classnames";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import style from "../index.module.scss";

const UserSearchItem = (props: { item: User; rolesById: UserRolesById }) => {
  const user = props.item;
  return (
    <div className={cn([style["search-item"], style["user-search-item"]])} key={user.pk}>
      <div className={style["user-search-item__left"]}>
        <div className={style["user-search-item__left-upper"]}>
          <div className={style["user-search-item__avatar"]}>
            <UserAvatar user={user} size={UserAvatarSize.MEDIUM} />
          </div>
          <div className={style["user-search-item__info"]}>
            <div className={style["user-search-item__name"]}>
              <Link to={constructUserPath(user)}>{userFullName(user)}</Link>
            </div>
            {user.email && <div className={style["user-search-item__email"]}>{user.email}</div>}
          </div>
        </div>
        {user.role.length > 0 && (
          <div className={style["user-search-item__roles"]}>
            {userRolesToStringList(user, props.rolesById).map(r => (
              <span key={r} className={style["user-search-item__role-item"]}>
                {r}
              </span>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default connect((store: StoreRootState) => ({
  rolesById: usersModule.selectors.getUserRolesById(store, null),
}))(UserSearchItem);
