import ApplicationLoadingScreen from "#/components/ApplicationLoadingScreen";
import { showErrorToast } from "#/components/toasts";
import AuthScene from "#/scenes/Auth";
import MainScene from "#/scenes/Main";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import { logout } from "#/store/modules/session/actions";
import sessionUsersModule from "#/store/modules/sessionUsers";
import { fetchCurrentUserInfo } from "#/store/modules/sessionUsers/actions";
import { DispatchProp, StoreRootState } from "#/store/types";
import "animate.css/animate.css";
import { push } from "connected-react-router";
import "nprogress/nprogress.css";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

export interface ApplicationOwnProps {}

export interface ApplicationConnectedProps {
  authenticated: boolean;
  isCurrentlyFetchingCurrentUserInfo: boolean;
  token: string | null;
  push: typeof push;
  fetchCurrentUserInfo: DispatchProp<typeof fetchCurrentUserInfo>;
  logout: typeof logout;
}

type ApplicationProps = ApplicationOwnProps & ApplicationConnectedProps;

// The root component of the actual application.
const Application = (props: ApplicationProps) => {
  useEffect(() => {
    if (props.token) {
      props.fetchCurrentUserInfo().catch(() => {
        // Just log out
        showErrorToast(
          "Не удалось получить информацию о текущем пользователе, пожалуйсте перезайдите.",
        );
        props.logout();
      });
    }
  }, []);

  if (props.token && props.isCurrentlyFetchingCurrentUserInfo) {
    return <ApplicationLoadingScreen />;
  }

  return (
    <Switch>
      <Route path="/auth" component={AuthScene} />
      <Route path="*" component={MainScene} />
    </Switch>
  );
};

export default connect(
  (store: StoreRootState) => {
    return {
      isCurrentlyFetchingCurrentUserInfo: sessionModule.selectors.isCurrentlyFetchingCurrentUserInfo(
        store,
        null,
      ),
      authenticated: sessionModule.selectors.isAuthenticated(store, null),
      token: sessionModule.selectors.getAuthToken(store, null),
    };
  },
  {
    ...getActionsFromModule(sessionModule, ["logout"]),
    ...getActionsFromModule(sessionUsersModule, ["fetchCurrentUserInfo"]),
    push,
  },
)(Application);
