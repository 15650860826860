import * as actions from "./actions";
import reducer, { localStorageUpdate } from "./reducer";
import * as selectors from "./selectors";
export * from "./actions";
export * from "./reducer";
export * from "./selectors";

export default {
  actions,
  selectors,
  reducer,
  localStorageUpdate,
};
