import React from "react";
import {
  MetrikaTop50ProjectData,
  MetrikaTop50Project,
  MetrikaTop50Event,
} from "#/store/modules/metrika/top50/types";
import { makeGetEventsForProject } from "#/store/modules/metrika/top50";
import { StoreRootState } from "#/store/types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface ProjectButtonContainerOwnProps {
  project: MetrikaTop50Project;
}
interface ProjectButtonContainerConnectedProps {
  events: MetrikaTop50Event[];
}
type ProjectButtonContainerProps = ProjectButtonContainerOwnProps &
  ProjectButtonContainerConnectedProps;

const ProjectButtonContainer = ({ project, events }: ProjectButtonContainerProps) => {
  return (
    <div className="list-group">
      <button className="list-group-item list-group-item-action">
        <h5>{project.title}</h5>
      </button>

      {events.map(event => (
        <Link
          className="list-group-item list-group-item-action"
          to={`${project.pk}/${event.pk}`}
          key={event.name + event.pk}
        >
          {event.name}
        </Link>
      ))}
    </div>
  );
};

const makeMapStateToProps = () => {
  const getEventsForProject = makeGetEventsForProject();
  const mapStateToProps = (state: StoreRootState, props: ProjectButtonContainerOwnProps) => ({
    events: getEventsForProject(state, props),
  });
  return mapStateToProps;
};

const mapDispatchToProps = {};

export default connect(makeMapStateToProps, mapDispatchToProps)(ProjectButtonContainer);
