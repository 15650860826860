import { SubmitEvent } from "#/store/types";
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface APIModalConnectedProps {}

interface APIModalOwnProps {
  api_source: string;
  onSubmit: any;
  modalOpen: boolean;
  toggle: any;
}

type APIModalProps = APIModalConnectedProps & APIModalOwnProps;

interface APIModalState {}

const APIModal = (props: APIModalProps) => {
  const [api_source, setApiSource] = useState(props.api_source ? props.api_source : "");
  useEffect(() => {
    setApiSource(props.api_source);
  }, [props.api_source]);
  const onSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    props.onSubmit(api_source);
  };
  const { modalOpen, toggle } = props;

  return (
    <Modal
      isOpen={modalOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <form onSubmit={onSubmit}>
        <ModalHeader toggle={toggle}>Источник API</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label>Укажите источник API для проекта:</label>

            <input
              type="text"
              className="form-control"
              defaultValue={api_source}
              onChange={e => setApiSource(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-list row-action">
            <i className="fas fa-check" /> Сохранить
          </button>
          <button type="button" className="btn btn-list row-action" onClick={toggle}>
            <i className="fas fa-times" /> Отмена
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default APIModal;
