import { getActionsFromModule } from "#/store/helpers";
import esm from "#/store/modules/eventSystem";
import { subscribeToEvent, unsubscribeFromEvent } from "#/store/modules/eventSystem/actions";
import * as sessionModule from "#/store/modules/session";
import sitemonitoringModule from "#/store/modules/sitemonitoring";
import {
  addUrlsToProject,
  changeMonitoringProjectFilters,
  fetchCheckedUrlsForProj,
  importUrlsFromSitemapIntoProject,
  removeUrlsFromProject,
  runCheckProcess,
  subscribeToMonitoringProject,
  toggleUrlsFromProject,
  unsubscribeFromMonitoringProject,
} from "#/store/modules/sitemonitoring/actions";
import {
  AddMonitoringURLs,
  DispatchProp,
  MonitoringProject,
  MonitoringProjectCheckedUrl,
  MonitoringProjectUrl,
  PrimaryKey,
  StoreRootState,
} from "#/store/types";
import {
  constructCRMProjectPath,
  handleDispatchErrorAndDisplayToast,
  monitoringProjectBaseProject,
  monitoringProjectName,
  projectName,
} from "#/util";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import { TabContent } from "reactstrap";
import FiltersComponent from "./components/FiltersTabPane";
import Header from "./components/Header";
import ImportURLsButton from "./components/ImportURLsButton";
import ProjectSubscriptionButton from "./components/ProjectSubscriptionButton";
import RunMonitoringProcessButton from "./components/RunMonitoringProcessButton";
import Sidebar from "./components/Sidebar";
import UrlComponent from "./components/UrlComponent";
import VisorTabPane from "./components/VisorTabPane";
import style from "./index.module.scss";
import "./index.scss";
import ProjectContext from "./ProjectContext";
import {
  FieldChangesRecord,
  FieldName,
  initialFieldChangesRecord,
  LastCheckInfo,
  tabIdToPageId,
  TabName,
  UrlToggleAction,
} from "./util";

interface TOPVisorConnectedProps extends RouteComponentProps<{ id: string }> {
  project: MonitoringProject | null;
  urls: MonitoringProjectUrl[];
  fetchMonitoringProject: any;
  addUrlsToProject: DispatchProp<typeof addUrlsToProject>;
  removeUrlsFromProject: DispatchProp<typeof removeUrlsFromProject>;
  toggleUrlsFromProject: DispatchProp<typeof toggleUrlsFromProject>;
  fetchCheckedUrlsForProj: DispatchProp<typeof fetchCheckedUrlsForProj>;
  runCheckProcess: DispatchProp<typeof runCheckProcess>;
  changeMonitoringProjectFilters: DispatchProp<typeof changeMonitoringProjectFilters>;
  subscribeToEvent: DispatchProp<typeof subscribeToEvent>;
  unsubscribeFromEvent: DispatchProp<typeof unsubscribeFromEvent>;
  unsubscribeFromMonitoringProject: DispatchProp<typeof unsubscribeFromMonitoringProject>;
  subscribeToMonitoringProject: DispatchProp<typeof subscribeToMonitoringProject>;
  importUrlsFromSitemapIntoProject: DispatchProp<typeof importUrlsFromSitemapIntoProject>;
}

interface TOPVisorOwnProps {}

type TOPVisorProps = TOPVisorConnectedProps & TOPVisorOwnProps;

const Project = (props: TOPVisorProps) => {
  // Project data
  const projectId = _.toNumber(props.match.params.id);
  const { project, urls } = props;
  const projectData = _.get(project, "data", null);
  const dates = _.get(project, "checkedurls_dates", []);
  const checkedUrlsData = _.get(_.get(project, "checkedUrls", {}), "data", []);
  const creatingUrls = _.get(project, "addingUrls", false);
  const domain = _.get(projectData, "name", "").split("//")[1];
  const baseProj = monitoringProjectBaseProject(projectData);

  // Project monitoring progress info
  const projectCheckProgress = _.get(project, "checkProgress", -1);
  const isCheckActive = _.get(project, "scriptRunning", false) || projectCheckProgress !== -1;
  const checkProgress = projectCheckProgress === -1 ? 0 : Math.round(projectCheckProgress * 100);

  // Active tab
  const [activeTab, setActiveTab] = useState<TabName>(TabName.CONTENT);

  // On initial mount
  useEffect(() => {
    const { id } = props.match.params;
    props.fetchMonitoringProject(id);
    props.subscribeToEvent("RUN_MONITORING", runProjCheckProcess);
    return () => {
      props.unsubscribeFromEvent("RUN_MONITORING", runProjCheckProcess);
    };
  }, []);

  const toggleTab = useCallback(
    (id: TabName) => {
      if (activeTab !== id) {
        setActiveTab(id);
      }
    },
    [activeTab, setActiveTab],
  );

  // Calculate last check info
  const lastCheckInfo: LastCheckInfo = useMemo(() => {
    return {
      fieldChanges: (() => {
        return ([
          "code",
          "content",
          "description",
          "h1",
          "name",
          "status",
          "title",
        ] as FieldName[]).reduce(
          (acc: FieldChangesRecord, fname: FieldName) => {
            const codeLike = _.includes(["content", "code"], fname);
            const didFieldChange = (url: MonitoringProjectCheckedUrl) => {
              if (codeLike) {
                return (url as any)[fname] !== "...\n";
              } else {
                return !_.isEqual((url as any)[fname], (url as any)[`${fname}_diff`]);
              }
            };
            const amountOfChanges = checkedUrlsData.reduce(
              (acc_: number, currVal: MonitoringProjectCheckedUrl) => {
                const didChange = didFieldChange(currVal);
                return acc_ + (didChange ? 1 : 0);
              },
              0,
            );
            return { ...acc, [fname]: amountOfChanges };
          },
          { ...initialFieldChangesRecord },
        );
      })(),
    };
  }, [checkedUrlsData]);

  const loading = false;

  const subscribe = useCallback(() => {
    return props.subscribeToMonitoringProject(projectId);
  }, [projectId, props.subscribeToMonitoringProject]);

  const unsubscribe = useCallback(() => {
    return props.unsubscribeFromMonitoringProject(projectId);
  }, [projectId, props.unsubscribeFromMonitoringProject]);

  const filters = projectData ? projectData.filters : "";
  const setFilters = useCallback(
    (nf: string) => {
      return props.changeMonitoringProjectFilters(projectId, nf);
    },
    [projectId, props.changeMonitoringProjectFilters],
  );

  const addUrls = useCallback(
    (urlsToAdd: string[]) => {
      return props.addUrlsToProject(projectId, urlsToAdd);
    },
    [projectId],
  );

  const removeUrls = useCallback(
    (urlsToRemove: PrimaryKey[]) => {
      return props.removeUrlsFromProject(projectId, urlsToRemove);
    },
    [projectData],
  );

  const toggleUrls = useCallback(
    (urlsToToggle: PrimaryKey[], action: UrlToggleAction) => {
      return props.toggleUrlsFromProject(projectId, urlsToToggle, action);
    },
    [projectData],
  );

  const [fetchingCheckedUrls, setFetchingCheckedUrls] = useState<boolean>(false);
  const fetchCheckedUrls = useCallback(
    datecheck => {
      if (fetchingCheckedUrls) {
        console.warn("Trying to fetch checked urls when it's already fetching");
        return;
      }
      setFetchingCheckedUrls(true);
      return props
        .fetchCheckedUrlsForProj(projectId, datecheck)
        .then(() => {
          setFetchingCheckedUrls(false);
        })
        .catch(err => {
          setFetchingCheckedUrls(false);
          handleDispatchErrorAndDisplayToast(err);
        });
    },
    [projectId],
  );

  const runProjCheckProcess = useCallback(() => {
    return props.runCheckProcess(projectId);
  }, [projectId]);

  const activeTabPageId = useMemo(() => tabIdToPageId(activeTab), [activeTab]);

  const importFromSitemap = useCallback(
    (sitemapUrl, onlyCheck) => {
      return props.importUrlsFromSitemapIntoProject(projectId, sitemapUrl, onlyCheck);
    },
    [projectId],
  );

  return (
    <ProjectContext.Provider
      value={{
        lastCheckInfo,
        activeTab,
        toggleTab,
        project,
        subscribe,
        unsubscribe,
        isCheckActive,
        checkProgress,
        dates,
        addUrls,
        addingUrls: creatingUrls,
        checkedUrls: checkedUrlsData,
        loading,
        fetchCheckedUrls,
        removeUrls,
        runCheckProcess: runProjCheckProcess,
        toggleUrls,
        urls,
        filters,
        setFilters,
        importFromSitemap,
      }}
    >
      <div className="topvisor-container">
        <Header />
        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                  <h4 className="box-title">
                    <img src={`//favicon.yandex.net/favicon/${domain}`} alt="project fav" />
                    <span className="ml-2">
                      <>
                        {monitoringProjectName(projectData)}
                        {` (`}
                        <Link to={constructCRMProjectPath(baseProj)}>{`${projectName(
                          baseProj,
                        )}`}</Link>
                        {`)`}
                      </>
                    </span>
                  </h4>
                  <div className={style["project-actions"]}>
                    <ImportURLsButton />
                    <ProjectSubscriptionButton />
                    <RunMonitoringProcessButton />
                  </div>
                </div>
                <div className="box-body" style={{ padding: 0 }}>
                  <div className="row no-front-margins topvisor-main-content">
                    <Sidebar />
                    <div className="topvisor-content col-12 col-lg">
                      <div className="content-inner">
                        <TabContent activeTab={activeTabPageId}>
                          <VisorTabPane />
                          <UrlComponent />
                          <FiltersComponent />
                        </TabContent>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </ProjectContext.Provider>
  );
};

const mapStateToProps = (store: StoreRootState, ownProps: TOPVisorOwnProps) => {
  return {
    project: sitemonitoringModule.selectors.getMonitoringProject(store, ownProps),
    token: sessionModule.selectors.getAuthToken(store, null),
    urls: sitemonitoringModule.selectors.getMonitoringProjectUrlsGroupedByCheckState(
      store,
      ownProps,
    ),
  };
};

export default connect(
  mapStateToProps,
  {
    ...getActionsFromModule(sitemonitoringModule, [
      "fetchMonitoringProject",
      "addUrlsToProject",
      "removeUrlsFromProject",
      "toggleUrlsFromProject",
      "fetchCheckedUrlsForProj",
      "runCheckProcess",
      "changeMonitoringProjectFilters",
      "subscribeToMonitoringProject",
      "unsubscribeFromMonitoringProject",
      "importUrlsFromSitemapIntoProject",
    ]),
    ...getActionsFromModule(esm, ["subscribeToEvent", "unsubscribeFromEvent"]),
  },
)(Project);
