import { apiDelete, apiGet, apiPost } from "#/api";
import Loader from "#/components/Loader";
import PageWrapper, { PageContent } from "#/components/PageWrapper";
import { showErrorToast, showInfoToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Top50Event, Top50Query } from "#/store/types";
import { useModal } from "#/util/hooks";
import { useSelectionRegistry } from "#/util/selectionRegistry";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import { Top50Group } from "../../types";
import Header from "../Header";
import ControlSidebar from "./components/ControlSidebar";
import EventItem from "./components/EventItem";
import MaybeRemoveEvent from "./components/EventItem/MaybeRemoveEvent";
import PageHeader from "./components/PageHeader";
import "./index.scss";
import QueryListContext from "./QueryListContext";
import Top50GlobalContext, { ITop50GlobalContext } from "../../Top50GlobalContext";

interface IndexConnectedProps extends RouteComponentProps<{ alias: string }> {}

interface IndexOwnProps {}
type IndexProps = IndexConnectedProps & IndexOwnProps;

const Index = (props: IndexProps) => {
  // Project alias
  const { alias } = props.match.params;

  // Flags
  const [loading, setLoading] = React.useState<boolean>(false);
  const [fetching, setFetching] = React.useState<boolean>(false);

  // All fetched groups (only metadata)
  // Full data is loaded for an active event only
  const [groups, setGroups] = React.useState<Top50Group[]>([]);

  // Record containing info on every query related to present groups (events)
  const [queriesById, setQueriesById] = useState<Record<PrimaryKey, Top50Query>>({});

  // Currently selected event
  const [activeGroupId, setActiveGroupId] = React.useState<PrimaryKey | null>(null);
  const activeGroup: Top50Event | null = React.useMemo(() => {
    if (!activeGroupId) {
      return null;
    }
    const gi = groups.find(g => g.pk === activeGroupId);
    return gi ? { queries: [], ...gi } : null;
  }, [activeGroupId, groups]);
  const [loadingActiveEvent, setLoadingActiveEvent] = React.useState<boolean>(false);

  // Selection of current event queries
  const querySelection = useSelectionRegistry({}, activeGroup ? activeGroup.queries : [], [
    activeGroup,
    activeGroupId,
  ]);

  // Configuration
  const top50Context = React.useContext(Top50GlobalContext) as ITop50GlobalContext;
  const [selectedConfig, setSelectedConfig] = React.useState<PrimaryKey | null>(null);

  useEffect(() => {
    if (selectedConfig === null && top50Context.defaultConfiguration !== null) {
      setSelectedConfig(top50Context.defaultConfiguration!.pk);
    }
  }, [top50Context.defaultConfiguration]);

  const fetchFullGroup = React.useCallback(
    async (pk: PrimaryKey, config: PrimaryKey) => {
      setLoadingActiveEvent(true);
      try {
        const resp = await apiGet(API_ROUTES.TOP50.EVENTS_ITEM(pk), { config });
        const respQueries = resp["queries"];
        setQueriesById(_.keyBy(respQueries, "query"));
        setGroups([...groups.filter(g => g.pk !== pk), resp["event"]]);
      } finally {
        setLoadingActiveEvent(false);
      }
    },
    [groups],
  );

  // Fetch group metadata (names for the sidebar)
  const fetchGroupsMetadata = React.useCallback(
    async (selectGroupWithNameAfterUpdate: string | null = null) => {
      setLoading(true);
      try {
        const resp = await apiGet(API_ROUTES.TOP50.EVENTS, {
          metadata_only: 1,
          full_metadata: 1,
        });
        const respGroups = resp;
        setGroups(respGroups);
        if (respGroups.length === 0) {
          setActiveGroupId(null);
        } else {
          setActiveGroupId(
            selectGroupWithNameAfterUpdate
              ? // if a specific alias is specified
                respGroups.find((g: Top50Event) => g.name === selectGroupWithNameAfterUpdate).pk
              : // this is a generic update, set the first group
                respGroups[0].pk,
          );
        }
        // Reset query selection on update
        querySelection.reset();
      } finally {
        setLoading(false);
      }
    },
    [groups],
  );

  const createNewRequests = React.useCallback(
    async (body: Partial<Top50Event>) => {
      setFetching(true);
      try {
        const group = await apiPost(API_ROUTES.TOP50.EVENTS, body);
        if (group.duplicates.length) {
          const duplicates = group.duplicates.join(", ");
          showInfoToast(`Найдены следующие дубликаты, которые не были добавлены: ${duplicates}`);
        }
        fetchGroupsMetadata(group.data.name);
      } finally {
        setFetching(false);
      }
    },
    [fetchGroupsMetadata],
  );

  const fetchCurrentEvent = React.useCallback(() => {
    if (activeGroupId !== null && selectedConfig !== null) {
      fetchFullGroup(activeGroupId, selectedConfig);
    }
  }, [activeGroupId, fetchFullGroup, selectedConfig]);

  // Import queries into an already-existing group
  const importIntoGroup = useCallback(
    async (groupPk: PrimaryKey, queries: any[]) => {
      setFetching(true);
      try {
        const group = await apiPost(API_ROUTES.TOP50.IMPORT_INTO_GROUP(groupPk), { queries });
        if (group.duplicates_length) {
          showErrorToast(`${group.duplicates_length} дубликатов не было добавлено.`);
        }
        // Refetch group data
        setActiveGroupId(groupPk);
      } finally {
        setFetching(false);
      }
    },
    [fetchGroupsMetadata, fetchCurrentEvent],
  );

  // Remove currently selected group
  const removeCurrentGroup = React.useCallback(async () => {
    if (activeGroupId === null) {
      return;
    }
    setFetching(true);
    try {
      await apiDelete(API_ROUTES.TOP50.EVENTS_ITEM(activeGroupId));
      setGroups(groups.filter(g => g.pk !== activeGroupId));
      setActiveGroupId(null);
    } finally {
      setFetching(false);
    }
  }, [groups, activeGroupId]);

  const toggleEvent = React.useCallback(
    async (pk: number, active: boolean) => {
      if (fetching) {
        throw new Error("can't do that at the moment");
      }
      const arrIndex = groups.findIndex(g => g.pk === pk);
      groups[arrIndex].active = !active;
      setFetching(true);
      try {
        const response = await apiPost(
          active ? API_ROUTES.TOP50.EVENTS_TOGGLE_OFF : API_ROUTES.TOP50.EVENTS_TOGGLE_ON,
          { events: [pk] },
        );
        return true;
      } finally {
        setFetching(false);
      }
    },
    [groups],
  );

  const maybeDeleteEmptyGroupModal = useModal(false);

  const removeSelectedQueryPages = useCallback(async () => {
    try {
      const res = await apiPost(API_ROUTES.TOP50.EVENTS_REMOVE_QUERYPAGES, {
        querypages: querySelection.selectedItems,
      });
      if (res.success && activeGroup !== null) {
        const diff = activeGroup.queries_amount! - querySelection.selectedItemsAmount;
        const updatedQueriesList = [
          ...activeGroup.queries.filter(qpk => !querySelection.isItemSelected(qpk)),
        ];
        setGroups([
          ...groups.filter(g => g.pk !== activeGroup.pk),
          {
            ...activeGroup,
            queries: updatedQueriesList,
            queries_amount: diff,
          },
        ]);
        if (updatedQueriesList.length === 0) {
          maybeDeleteEmptyGroupModal.show();
        }
        querySelection.reset();
      }
    } finally {
      setFetching(false);
    }
  }, [querySelection, activeGroup, groups]);

  // Fetch groups metadata on initial load
  useEffect(() => {
    fetchGroupsMetadata(alias);
  }, []);

  // Refetch active group info on id change
  useEffect(() => {
    if (activeGroupId !== null && selectedConfig !== null) {
      fetchFullGroup(activeGroupId, selectedConfig);
    }
  }, [activeGroupId, selectedConfig]);

  return (
    <QueryListContext.Provider
      value={{
        querySelection,
        removeSelectedQueryPages,
        groups,
        removeCurrentEventGroup: removeCurrentGroup,
        createNewRequests,
        importIntoGroup,
        activeGroup,
        fetching,
        setActiveGroupId,
        toggleEvent,
        queriesById,
        loadingActiveEvent,
        selectedConfig,
        setSelectedConfig,
      }}
    >
      <PageWrapper>
        <Header />
        <PageContent>
          <div className="box h-100">
            {loading && <Loader />}
            {!loading && (
              <>
                <PageHeader />
                <div className="box-body">
                  <div className="row">
                    <ControlSidebar />
                    <EventItem />
                  </div>
                </div>
              </>
            )}
          </div>
        </PageContent>
      </PageWrapper>
      <MaybeRemoveEvent modal={maybeDeleteEmptyGroupModal} />
    </QueryListContext.Provider>
  );
};

export default Index;
