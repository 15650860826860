import React from "react";
import styled, { css } from "reshadow";
import { ButtonProps } from "reactstrap/lib/Button";

const ProgressButton = (
  props: ButtonProps & {
    progress: number;
    running: boolean;
    onClick: () => void;
    textIfNotRunning: string;
    progressMax: number;
    textIfRunning: string;
  },
) =>
  styled(css`
    button[data-running] {
      pointer-events: none;
    }
  `)(
    <button
      type="button"
      className="btn btn-list ml-2"
      data-running={props.running}
      onClick={props.onClick}
      disabled={props.running}
    >
      {props.running ? (
        <span>
          <span className="small">{props.textIfRunning}</span>
          <div className="progress w-100">
            <div
              className="progress-bar progress-bar-striped progress-bar-animated"
              role="progressbar"
              aria-valuenow={props.progress}
              aria-valuemin={0}
              aria-valuemax={props.progressMax}
              style={{ width: props.progress + "%" }}
            >
              {props.progress}%
            </div>
          </div>
        </span>
      ) : (
        <>
          <i className="fas fa-plus" />
          <span className="ml-2">{props.textIfNotRunning}</span>
        </>
      )}
    </button>,
  );

export default ProgressButton;
