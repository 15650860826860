import React, { useEffect } from "react";
import Input from "./components/TokenInput";
import TokenSelector from "./components/TokenSelector";
import GoalForm from "./components/GoalForm";
import { StoreRootState, PrimaryKey } from "#/store/types";
import {
  postGoal,
  changeCreatingGoal,
  getSelection,
  deleteGoalFromMetrika,
  fetchSavedTokens,
  getTokens,
} from "#/store/modules/metrika/goals";
import { connect } from "react-redux";
import { Goal, Token } from "#/store/modules/metrika/goals/types";
import "./index.scss";

interface IndexConnectedProps {
  changeCreatingGoal: any;
  postGoal: any;
  selection: Record<PrimaryKey, PrimaryKey>;
  creatingGoal: Goal;
  deleteGoalFromMetrika: any;
  fetchSavedTokens: any;
  tokens: Record<PrimaryKey, Token>;
}

interface IndexOwnProps {}

type IndexProps = IndexConnectedProps & IndexOwnProps;

const Index = (props: IndexProps) => {
  useEffect(() => {
    props.fetchSavedTokens();
  }, []);

  return (
    <div className="goals">
      <div className="goals__left">
        <Input />
        <TokenSelector />
      </div>
      <div className="goals__right">
        <GoalForm canBeStepGoal={true} callback={props.changeCreatingGoal} />
        <button onClick={postGoal} className="btn btn-primary">
          Создать цель
        </button>
      </div>
    </div>
  );
};

export default connect(
  (state: StoreRootState) => ({
    selection: getSelection(state),
    tokens: getTokens(state),
  }),
  {
    changeCreatingGoal,
    postGoal,
    deleteGoalFromMetrika,
    fetchSavedTokens,
  },
)(Index);
