export enum MutationType {
  CREATE_TASK_SUCCEED = "tasks/CREATE_TASK_SUCCEED",
  CREATE_TASK_FAIL = "tasks/CREATE_TASK_FAIL",
  CREATE_TASK_START = "tasks/CREATE_TASK_START",

  REMOVE_TASK_SUCCEED = "tasks/REMOVE_TASK_SUCCEED",
  REMOVE_TASK_FAIL = "tasks/REMOVE_TASK_FAIL ",
  REMOVE_TASK_START = "tasks/REMOVE_TASK_START",

  EDIT_TASK_SUCCEED = "tasks/EDIT_TASK_SUCCEED",
  EDIT_TASK_FAIL = "tasks/EDIT_TASK_FAIL",
  EDIT_TASK_START = "tasks/EDIT_TASK_START",

  FETCH_TASK_SUCCEED = "tasks/FETCH_TASK_SUCCEED",
  FETCH_TASK_FAIL = "tasks/FETCH_TASK_FAIL",
  FETCH_TASK_START = "tasks/FETCH_TASK_START",

  FETCH_TASKS_TO_USER_START = "tasks/FETCH_TASKS_TO_USER_START",
  FETCH_TASKS_TO_USER_FAIL = "tasks/FETCH_TASKS_TO_USER_FAIL",
  FETCH_TASKS_TO_USER_SUCCEED = "tasks/FETCH_TASKS_TO_USER_SUCCEED",

  FETCH_TASKS_FROM_USER_START = "tasks/FETCH_TASKS_FROM_USER_START",
  FETCH_TASKS_FROM_USER_FAIL = "tasks/FETCH_TASKS_FROM_USER_FAIL",
  FETCH_TASKS_FROM_USER_SUCCEED = "tasks/FETCH_TASKS_FROM_USER_SUCCEED",

  FETCH_TASKS_SUCCEED = "tasks/FETCH_TASKS_SUCCEED",
  FETCH_TASKS_START = "tasks/FETCH_TASKS_START",
  FETCH_TASKS_FAIL = "tasks/FETCH_TASKS_FAIL",

  FETCH_PROJECT_TASKS = "tasks/FETCH_PROJECT_TASKS",

  ADD_TASK_CHECKLIST_ITEM_SUCCEED = "tasks/ADD_TASK_CHECKLIST_ITEM_SUCCEED",
  ADD_TASK_CHECKLIST_ITEM_FAIL = "tasks/ADD_TASK_CHECKLIST_ITEM_FAIL",
  ADD_TASK_CHECKLIST_ITEM_START = "tasks/ADD_TASK_CHECKLIST_ITEM_START",

  REMOVE_TASK_CHECKLIST_ITEM_SUCCEED = "tasks/REMOVE_TASK_CHECKLIST_ITEM_SUCCEED",
  REMOVE_TASK_CHECKLIST_ITEM_FAIL = "tasks/REMOVE_TASK_CHECKLIST_ITEM_FAIL",
  REMOVE_TASK_CHECKLIST_ITEM_START = "tasks/REMOVE_TASK_CHECKLIST_ITEM_START",

  EDIT_TASK_CHECKLIST_ITEM_SUCCEED = "tasks/EDIT_TASK_CHECKLIST_ITEM_SUCCEED",
  EDIT_TASK_CHECKLIST_ITEM_FAIL = "tasks/EDIT_TASK_CHECKLIST_ITEM_FAIL",
  EDIT_TASK_CHECKLIST_ITEM_START = "tasks/EDIT_TASK_CHECKLIST_ITEM_START",

  SEND_TASK_CHAT_MESSAGE_SUCCEED = "tasks/SEND_TASK_CHAT_MESSAGE_SUCCEED",
  SEND_TASK_CHAT_MESSAGE_START = "tasks/SEND_TASK_CHAT_MESSAGE_START",
  SEND_TASK_CHAT_MESSAGE_FAIL = "tasks/SEND_TASK_CHAT_MESSAGE_FAIL",

  ADD_TASK_CHAT_MESSAGE = "tasks/ADD_TASK_CHAT_MESSAGE",

  FETCH_TASK_CHAT_MESSAGES_SUCCEED = "tasks/FETCH_TASK_CHAT_MESSAGES_SUCCEED",
  FETCH_TASK_CHAT_MESSAGES_START = "tasks/FETCH_TASK_CHAT_MESSAGES_START",
  FETCH_TASK_CHAT_MESSAGES_FAIL = "tasks/FETCH_TASK_CHAT_MESSAGES_FAIL",

  SET_TASK_SUBSCRIBED = "tasks/SET_TASK_SUBSCRIBED",
}

export default MutationType;
