import { CompleteTaskData, StoreRootState, TasksById, TasksState } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

const getTasksState = (store: StoreRootState) => store.tasks;

const getTasksById = createSelector(
  [getTasksState],
  (tasksState: TasksState) => tasksState.tasksById,
);

const getTasksByIdBare = createSelector(
  [getTasksState],
  (tasksState: TasksState) =>
    _.keyBy(
      _.compact(
        _.keys(tasksState.tasksById).map(pk => {
          const taskData = _.get(tasksState.tasksById, pk, null);
          if (taskData === null) { return null; }
          return _.get(taskData, "data", null);
        }),
      ),
      "pk",
    ),
);

const isLoadingTasks = createSelector(
  [getTasksState],
  (tasksState: TasksState) => tasksState.fetchingTasks,
);

const didLoadingTasksFail = createSelector(
  [getTasksState],
  (tasksState: TasksState) => tasksState.fetchingTasksFailed,
);

const getAllTasks = createSelector(
  [getTasksById],
  (tbid: TasksById): CompleteTaskData[] =>
    _.compact(
      _.keys(tbid)
        .map(id => _.get(tbid, id, null))
        .filter(t => t.data !== null),
    ),
);

const isCurrentlyDeletingTask = createSelector(
  [getTasksState],
  (ts: TasksState) => ts.removingTask,
);

// TODO: Implement
const isCurrentlyAddingChecklistItems = createSelector(
  [getTasksState],
  (ts: TasksState) => false,
);

export default {
  getTasksById,
  isLoadingTasks,
  getAllTasks,
  isCurrentlyDeletingTask,
  isCurrentlyAddingChecklistItems,
  didLoadingTasksFail,
  getTasksByIdBare,
};
