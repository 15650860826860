import { showErrorToast, showSuccessToast } from "#/components/toasts";
import UserRoleSelectMultiple from "#/components/UserRoleSelect/UserRoleSelectMultiple";
import usersModule from "#/store/modules/users";
import { createUser } from "#/store/modules/users/actions";
import {
  DispatchProp,
  StoreRootState,
  SubmitEvent,
  User,
  UserRole,
  PrimaryKey,
} from "#/store/types";
import { constructUserPath, falseIfUndefinedElseBoolean } from "#/util";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import _ from "lodash";

interface CreateUserConnectedProps {
  createUser: DispatchProp<typeof createUser>;
  push: typeof push;
  creating: boolean;
  roles: UserRole[];
}

interface CreateUserOwnProps {
  redirectAfterCreated?: boolean;
  isShown: boolean;
  toggleModal: () => Promise<unknown>;
}

type CreateUserProps = CreateUserConnectedProps & CreateUserOwnProps;

const CreateUser = (props: CreateUserProps) => {
  const initialUserData: Partial<User> = {
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    role: [],
    info: "",
  };
  const [userData, setUserData] = useState<Partial<User>>(initialUserData);

  const roleIsValid = () => {
    return userData.role && userData.role.length > 0;
  };

  const handleSubmit = React.useCallback(
    (e: SubmitEvent) => {
      e.preventDefault();
      if (!roleIsValid()) {
        showErrorToast("Выберите роль пользователя!");
        return;
      }
      props
        .createUser(userData)
        .then((newUser: User) => {
          props.toggleModal().then(() => {
            showSuccessToast("Пользователь успешно создан.");
            setUserData(initialUserData);
            if (falseIfUndefinedElseBoolean(props.redirectAfterCreated)) {
              props.push(constructUserPath(newUser));
            }
          });
        })
        .catch(() => {
          showErrorToast("Произошла ошибка при попытке создать пользователя");
        });
    },
    [roleIsValid, userData, props.toggleModal, initialUserData, props.redirectAfterCreated],
  );

  const handleRoleChange = (e: PrimaryKey[]) => {
    setUserData({ ...userData, role: e });
  };

  const constructUserFieldChangeCallback = (fieldName: string) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) =>
    setUserData({
      ...userData,
      [fieldName]: e.target.value,
    });

  return (
    <Modal
      isOpen={props.isShown}
      toggle={props.toggleModal}
      className="modal-dialog-centered"
      size="lg"
      contentClassName="alert alert-primary"
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={props.toggleModal}>Создать пользователя</ModalHeader>
        <ModalBody>
          <FormGroup row={true}>
            <div className="col-md-6">
              <label>Имя</label>
              <input
                type="text"
                onChange={constructUserFieldChangeCallback("first_name")}
                value={userData.first_name}
                className="form-control"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <label>Фамилия</label>
              <input
                type="text"
                onChange={constructUserFieldChangeCallback("last_name")}
                value={userData.last_name}
                className="form-control"
                required={true}
              />
            </div>
          </FormGroup>

          <FormGroup row={true}>
            <div className="col-md-6">
              <label>Номер телефона</label>
              <input
                type="text"
                onChange={constructUserFieldChangeCallback("phone")}
                value={userData.phone}
                className="form-control"
                required={true}
              />
            </div>
            <div className="col-md-6">
              <label>Адрес эл.почты</label>
              <input
                type="text"
                onChange={constructUserFieldChangeCallback("email")}
                value={userData.email}
                className="form-control"
                required={true}
              />
            </div>
          </FormGroup>

          <FormGroup row={true}>
            <div className="col-md-12">
              <Label for="selectRole">Роль</Label>
              <UserRoleSelectMultiple
                onChange={handleRoleChange}
                value={userData.role!}
                placeholder="Выберите роль"
              />
            </div>
          </FormGroup>

          <FormGroup row={true}>
            <div className="col-md-12">
              <label>Дополнительная информация</label>
              <textarea
                name="user_info"
                className="form-control"
                onChange={constructUserFieldChangeCallback("info")}
                value={userData.info}
                required={true}
                style={{ resize: "none", height: "200px" }}
              />
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button type="submit" disabled={props.creating} className="btn btn-success">
            {props.creating ? "Подождите..." : "Создать пользователя"}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  creating: usersModule.selectors.isCurrentlyCreatingUser(store, null),
  roles: usersModule.selectors.getAllUserRoles(store, null),
});

export default connect(mapStateToProps, { createUser: usersModule.actions.createUser, push })(
  CreateUser,
);
