import { EventSystem } from "#/store/types";
import _ from "lodash";
import { Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";

// Subscription
export const subscribeToEvent = (
  eventName: EventSystem.Event,
  cb: EventSystem.EventHandler,
): ActionTypes.SubscribeToEventAction => ({
  type: MutationTypes.SUBSCRIBE_TO_EVENT,
  payload: { eventName, cb },
});

export const unsubscribeFromEvent = (
  eventName: EventSystem.Event,
  cb: EventSystem.EventHandler,
): ActionTypes.UnsubscribeFromEventAction => ({
  type: MutationTypes.UNSUBSCRIBE_FROM_EVENT,
  payload: { eventName, cb },
});

// Dispatch
export const dispatchEvent = (eventName: EventSystem.Event) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const eventSystem = getState().eventSystem;
  _.compact(
    Object.keys(_.get(eventSystem.events, eventName, [])).map((a: string) =>
      _.get(eventSystem.events[eventName], a, null),
    ),
  ).forEach((handler: EventSystem.EventHandler) => handler());
};

export default {
  subscribeToEvent,
  unsubscribeFromEvent,
  dispatchEvent,
};
