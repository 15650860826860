import loader from "#/scenes/Auth/assets/login-spinner.svg";
import React from "react";

const Loader = () => (
  <div className="d-flex flex-column justify-content-center align-items-center h-100">
    <div className="flex-vertically-centered py-4 text-center">
      <img src={loader} className="m-auto" alt="Loading Spinner" />
    </div>
  </div>
);

export default Loader;
