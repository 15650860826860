import usersModule from "#/store/modules/users";
import { CRMProject, StoreRootState, UsersById } from "#/store/types";
import {
  constructCRMProjectPath,
  constructUserPath,
  projectCreator,
  projectName,
  userFullName,
} from "#/util";
import cn from "classnames";
import * as moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import style from "../index.module.scss";

const ProjectSearchItem = (props: { item: CRMProject; usersById: UsersById }) => {
  const creator = projectCreator(props.item, props.usersById);
  return (
    <div className={cn([style["search-item"], style["project-search-item"]])} key={props.item.pk}>
      <div className={style["project-search-item__left"]}>
        <div className={style["project-search-item__name"]}>
          <Link to={constructCRMProjectPath(props.item)}>{projectName(props.item)}</Link>
        </div>
        <div className={style["project-search-item__description"]}>{props.item.description}</div>
      </div>
      <div className={style["project-search-item__right"]}>
        <div className={style["project-search-item__date-info"]}>
          {(moment as any)(props.item.create_date).format("DD.MM.YYYY")}
        </div>
        <div className={style["project-search-item__creator"]}>
          <Link to={constructUserPath(creator)}>{userFullName(creator)}</Link>
        </div>
      </div>
    </div>
  );
};

export default connect((store: StoreRootState) => ({
  usersById: usersModule.selectors.getUsersById(store, null),
}))(ProjectSearchItem);
