import Page from "#/components/Page";
import React from "react";
import { Route } from "react-router";
import { Switch } from "react-router";
import ListProjects from "./components/List";
import Project from "./components/Project";
import SitemonitoringServiceWrapper from "./components/SitemonitoringServiceWrapper";

const MonitoringScene = ({ match }: any) => {
  return (
    <SitemonitoringServiceWrapper>
      <Switch>
        <Route
          path={match.url}
          exact={true}
          render={(props: any) => (
            <Page title="Мониторинг">
              <ListProjects {...props} />
            </Page>
          )}
        />
        <Route
          path={`${match.url}/:id`}
          exact={true}
          render={(props: any) => (
            <Page title="Мониторинг | Проект">
              <Project {...props} />
            </Page>
          )}
        />
      </Switch>
    </SitemonitoringServiceWrapper>
  );
};

export default MonitoringScene;
