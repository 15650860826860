import { dateObjectToAPICompatible } from "#/api";
import ProjectSelect from "#/components/ProjectSelect";
import ControllableTaskPrioritySelect from "#/components/TaskPrioritySelect";
import TaskStatusSelect from "#/components/TaskStatusSelect";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import UserSelect from "#/components/UserSelect";
import Strings from "#/conf/strings";
import { canUser } from "#/permissions";
import projectsModule from "#/store/modules/projects";
import sessionUsersModule from "#/store/modules/sessionUsers";
import { becomeTaskExecutor, editTask, removeTask } from "#/store/modules/tasks/actions";
import usersModule from "#/store/modules/users";
import {
  CRMProject,
  CRMProjectData,
  CRMTask,
  DispatchProp,
  OptionalPrimaryKey,
  PrimaryKey,
  StoreRootState,
  TaskCheckListItem,
  TaskStatus,
  User,
} from "#/store/types";
import {
  formatDateTime,
  getChecklistProgress,
  getItemStyle,
  handleDispatchErrorAndDisplayToast,
  isTaskAlreadyStarted,
  isUserApplicableToBeTaskExecutor,
  userFullName,
  userShortName,
} from "#/util";
import assert from "assert";
import ru from "date-fns/locale/ru";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { PopoverBody, PopoverHeader, UncontrolledPopover } from "reactstrap";
import style from "../../index.module.scss";
import { TaskPriority, formatPriority } from "#/taskPriority";

registerLocale("ru-RU", ru);

interface TableRowConnectedProps {
  users: User[];
  projects: CRMProject[];
}

interface TableRowOwnProps {
  // Data
  item: CRMTask;
  itemChecklists: TaskCheckListItem[];
  taskToUser: User | null;
  taskFromUser: User | null;
  project: CRMProjectData | null;
  currentUser: User | null;

  // Functions
  changeTask: DispatchProp<typeof editTask>;
  removeTask: DispatchProp<typeof removeTask>;
  becomeTaskExecutor?: DispatchProp<typeof becomeTaskExecutor>;
  onClick: () => void;

  // Flags
  selected: boolean;
  provided: any;
  snapshot: any;
  isCurrentlyDeletingTask: boolean;
  isCurrentlyEditingTask: boolean;
  showSelect: boolean;
  showProjectColumn: boolean;
  showManagerColumn: boolean;
  showExecutorColumn: boolean;
  showBecomeExecutorColumn: boolean;
}

type TableRowProps = TableRowConnectedProps & TableRowOwnProps;

const TableRow = (props: TableRowProps) => {
  const [item, setItem] = useState(props.item);
  const [itemForInput, setItemForInput] = useState(props.item);
  const [editMode, setEditMode] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);

  useEffect(() => {
    setItem(props.item);
    setItemForInput(props.item);
  }, [props.item]);

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      changeTaskHandler();
    }
  };

  const changeTaskHandler = () => {
    props
      .changeTask(itemForInput)
      .then(() => {
        showSuccessToast(Strings.TASK_EDIT_SUCCESS);
        setEditMode(!editMode);
        setItem(itemForInput);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  };

  const removeTaskHandler = () => {
    props
      .removeTask(item.pk)
      .then(() => {
        showSuccessToast(Strings.TASK_REMOVE_SUCCESS);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  };

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const toggleEditMode = () => {
    if (editMode) {
      setItem(props.item);
    }
    setItemForInput(props.item);
    setEditMode(!editMode);
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setItemForInput({
      ...itemForInput,
      name: e.target.value,
    });

  const onPriorityChange = (np: TaskPriority | null) => {
    if (_.isNull(np)) {
      showErrorToast("Задача должна иметь указанный приоритет");
    } else {
      setItemForInput({ ...itemForInput, priority: _.toNumber(np) });
    }
  };

  const onFinishDateChange = (date: Date | null) => {
    if (date !== null) {
      setItemForInput({
        ...itemForInput,
        finish_date: dateObjectToAPICompatible(date),
      });
    }
  };

  const checklistProgress = getChecklistProgress(props.itemChecklists);
  const taskProject = props.project;
  const taskFromUser = props.taskFromUser;
  const taskToUser = props.taskToUser;

  const permissionToOpen = canUser(props.currentUser, "VIEW", "TASK", {
    obj: props.item,
    taskProject: taskProject!,
  });
  const permissionToEdit = canUser(props.currentUser, "EDIT", "TASK", {
    obj: props.item,
    taskProject: taskProject!,
  });
  const permissionToDelete = canUser(props.currentUser, "DELETE", "TASK", {
    obj: props.item,
    taskProject: taskProject!,
  });

  assert(props.showBecomeExecutorColumn ? props.becomeTaskExecutor : true);

  return (
    <tr
      ref={props.provided.innerRef}
      {...props.provided.draggableProps}
      {...props.provided.dragHandleProps}
      style={getItemStyle(props.snapshot.isDragging, props.provided.draggableProps.style)}
      key={props.item.pk}
    >
      {/* Priority */}
      {!editMode ? (
        <td>{formatPriority(item.priority)}</td>
      ) : (
        <td className={style["priority-column-cell"]}>
          <ControllableTaskPrioritySelect
            value={itemForInput.priority}
            onChange={onPriorityChange}
            className="form-control"
            showEmptyOption={false}
            required={true}
          />
        </td>
      )}

      {/* Name */}
      {!editMode ? (
        <td
          onClick={() => {
            if (permissionToOpen) {
              props.onClick();
            }

            // If executor opened the task and task is not already started
            if (
              item.task_to &&
              props.currentUser!.pk === item.task_to &&
              !isTaskAlreadyStarted(item)
            ) {
              props.changeTask({ ...item, status: TaskStatus.ONGOING });
            }
          }}
        >
          {item.name}
        </td>
      ) : (
        <td>
          <input
            type="text"
            className="form-control"
            value={itemForInput.name}
            onChange={onTitleChange}
            onKeyDown={onEnterPress}
          />
        </td>
      )}

      {/* Status */}
      <td>
        <TaskStatusSelect smallSize={true} task={item} editTaskCustom={props.changeTask} />
      </td>

      {/* Project */}
      {/* TODO: Use ProjectSelect instead */}
      {props.showProjectColumn && (
        <td className="selectable-column d-none d-lg-table-cell">
          {!editMode ? (
            <Link to={`/project/${item.project}`}>
              {taskProject ? taskProject.data!.name : "N/A"}
            </Link>
          ) : (
            <ProjectSelect
              value={item.project}
              projects={props.projects}
              className="form-control"
              onChange={(newValue: PrimaryKey | null) => {
                if (newValue) {
                  setItemForInput({
                    ...itemForInput,
                    project: newValue,
                  });
                }
              }}
              required={true}
            />
          )}
        </td>
      )}

      {/* Task from */}
      {props.showManagerColumn && (
        <td className="selectable-column d-none d-lg-table-cell">
          <Link to={`/profile/${item.task_from}`}>{userShortName(taskFromUser)}</Link>
        </td>
      )}

      {/* Task to */}
      {props.showExecutorColumn && (
        <td className="selectable-column d-none d-lg-table-cell">
          {!editMode ? (
            <Link to={`/profile/${item.task_to}`}>{userShortName(taskToUser)}</Link>
          ) : (
            <UserSelect
              value={itemForInput.task_to}
              required={true}
              onChange={(newValue: OptionalPrimaryKey) =>
                setItemForInput({
                  ...itemForInput,
                  task_to: newValue,
                })
              }
              users={props.users.filter(user =>
                isUserApplicableToBeTaskExecutor(
                  user,
                  item.role,
                  taskProject ? taskProject.users : [],
                ),
              )}
            />
          )}
        </td>
      )}

      {/* Progress */}
      <td className="d-none d-lg-table-cell">
        <div className="progress">
          <div
            className="progress-bar bg-purple"
            role="progressbar"
            style={{ width: checklistProgress + "%" }}
            aria-valuenow={checklistProgress}
            aria-valuemin={0}
            aria-valuemax={100}
          >
            {checklistProgress > 0 ? checklistProgress + "%" : undefined}
          </div>
        </div>
      </td>

      {/* Creation date */}
      {permissionToOpen ? (
        <td className="d-none d-lg-table-cell">{formatDateTime(item.create_date)}</td>
      ) : (
        <td>{formatDateTime(item.create_date)}</td>
      )}

      {/* Deadline */}
      {permissionToOpen ? (
        editMode ? (
          <td className="d-none d-lg-table-cell">
            <DatePicker
              className="form-control d-block"
              selected={itemForInput.finish_date ? new Date(itemForInput.finish_date) : undefined}
              dateFormat="yyyy-MM-dd HH:mm"
              showTimeSelect={true}
              timeFormat="HH:mm"
              onChange={onFinishDateChange}
              timeCaption="Время"
              timeIntervals={30}
              locale="ru-RU"
              shouldCloseOnSelect={true}
              placeholderText="Выберите дату окончания"
              required={true}
            />
          </td>
        ) : (
          <td className="d-none d-lg-table-cell">{formatDateTime(item.finish_date)}</td>
        )
      ) : (
        <td>{formatDateTime(item.finish_date)}</td>
      )}
      {/* Creation date */}
      {permissionToOpen ? (
        <td className="d-none d-lg-table-cell">{item.manager_minutes_to_complete}</td>
      ) : (
        <td>{item.manager_minutes_to_complete}</td>
      )}
      {/* Actions */}
      <td id={`popover-row${item.pk}`} className={style["row-action-column"]}>
        {/* Become executor */}
        {props.showBecomeExecutorColumn && (
          <button
            className="btn btn-list row-action"
            onClick={() => props.becomeTaskExecutor!(item.pk)}
            title="Закрепить за собой"
          >
            <i className="fas fa-thumbtack" />
          </button>
        )}

        {/* Edit */}
        {permissionToEdit &&
          (editMode ? (
            <>
              <button
                className="btn btn-list row-action"
                onClick={changeTaskHandler}
                disabled={props.isCurrentlyEditingTask}
              >
                <i className="fas fa-check" />
              </button>
              <button className="btn btn-list row-action" onClick={toggleEditMode}>
                <i className="fas fa-times" />
              </button>
            </>
          ) : (
            <button className="btn btn-list row-action" onClick={toggleEditMode}>
              <i className="fas fa-pen-alt" />
            </button>
          ))}

        {/* Delete */}
        {permissionToDelete && (
          <>
            <button
              className="btn btn-list row-action rm"
              disabled={props.isCurrentlyDeletingTask}
              id={`popover-remove${item.pk}`}
              onClick={togglePopover}
            >
              <i className="fas fa-trash-alt" />
            </button>
            <UncontrolledPopover
              placement="top"
              isOpen={popoverOpen}
              target={`popover-remove${item.pk}`}
              trigger="legacy"
              container={`popover-row${item.pk}`}
            >
              <PopoverHeader>Подтверждение</PopoverHeader>
              <PopoverBody>
                <div>Вы действительно хотите безвозвратно удалить этот элемент?</div>
              </PopoverBody>
              <PopoverBody className="popover-border-top">
                <div className="text-right">
                  <button type="button" className="btn btn-list row-action" onClick={togglePopover}>
                    Отмена
                  </button>
                  <button
                    type="button"
                    className="btn btn-list row-action"
                    onClick={removeTaskHandler}
                    disabled={props.isCurrentlyDeletingTask}
                  >
                    Удалить
                  </button>
                </div>
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )}

        {/* View */}
        <Link to={`/task/${item.pk}`} className="btn btn-list row-action">
          <i className="fas fa-external-link-alt" />
        </Link>
      </td>
    </tr>
  );
};

export default connect((store: StoreRootState) => ({
  users: usersModule.selectors.getAllUsersBare(store, null),
  projects: projectsModule.selectors.getAllProjectsBare(store, null),
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
}))(TableRow);
