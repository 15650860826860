import {
  CRMProject,
  ProjectsById,
  ProjectsByIdBare,
  ProjectsState,
  StoreRootState,
} from "#/store/types";
import { compareStringsAlphabetically } from "#/util";
import _ from "lodash";
import { createSelector } from "reselect";

// Root
type Props = {} | null;

export const getProjectsState = (store: StoreRootState, props: Props) => store.projects;

export const getProps = (state: StoreRootState, props: Props) => props;

export const getProjectsById = createSelector(
  [getProjectsState],
  (projects: ProjectsState): ProjectsById => projects.projectsById,
);

export const getProjectsByIdBare = createSelector(
  [getProjectsState],
  (projects: ProjectsState): ProjectsByIdBare =>
    _.keyBy(
      _.compact(
        _.keys(projects.projectsById).map(p => {
          const pData = _.get(projects.projectsById, p, null);
          if (pData === null) {
            return null;
          }
          return _.get(pData, "data", null);
        }),
      ),
      "pk",
    ),
);

export const getAllProjectsBare = createSelector(
  [getProjectsById],
  (projectsById: ProjectsById) =>
    _.compact(
      _.keys(projectsById).map((key: string) => {
        const p = _.get(projectsById, key, null);
        if (p !== null) {
          return _.get(p, "data", null);
        } else {
          return null;
        }
      }),
    ),
);

export const getAllProjectsSortedByNameBare = createSelector(
  [getAllProjectsBare],
  (pb: CRMProject[]) => {
    return pb.sort((a: CRMProject, b: CRMProject) => compareStringsAlphabetically(a.name, b.name));
  },
);

// Loading flag
export const isLoadingProjects = createSelector(
  [getProjectsState],
  (project: ProjectsState) => project.fetching,
);

export const isProjectCreationInProcess = createSelector(
  [getProjectsState],
  (ps: ProjectsState) => ps.creating,
);

export const isProjectEditInProcess = createSelector(
  [getProjectsState],
  (ps: ProjectsState) => ps.editing,
);
