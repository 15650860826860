import { createReducer } from "#/store/helpers";
import TasksMutationType from "#/store/modules/tasks/mutationType";
import { Action, ProjectTasksState } from "#/store/types";

const initialState: ProjectTasksState = {
  tasks: [],
};

export default createReducer<ProjectTasksState, Action>(
  {
    [TasksMutationType.FETCH_PROJECT_TASKS]: (state: ProjectTasksState, action: any) => ({
      ...state,
      tasks: [...action.payload.tasks],
    }),
  },
  initialState,
);
