import React from "react";

const ChatHeader = () => (
  <div className="box-header with-border">
    <div className="d-flex justify-content-between">
      <div className="align-self-center">
        <h4 className="box-title">
          <i className="fas fa-comments" /> Чат
        </h4>
      </div>
    </div>
  </div>
);

export default ChatHeader;
