import React from "react";
import style from "./index.module.scss";

const TabSection = (props: { name: string; children: React.ReactNode }) => {
  return (
    <div className={style["tab-section"]}>
      <div className={style["tab-section__title"]}>
        <h4>{props.name}</h4>
      </div>
      <div className={style["tab-section__content"]}>{props.children}</div>
    </div>
  );
};

export default TabSection;
