import { apiGet } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Top50SearchEngine } from "#/store/types";
import _ from "lodash";
import React, { useEffect } from "react";

export const useSearchEngines = (options: { includeById: boolean }, deps: any[]) => {
  const [data, setData] = React.useState([]);
  const includeById = options.includeById ? options.includeById : false;
  useEffect(() => {
    (async () => {
      const resp = await apiGet(API_ROUTES.TOP50.SEARCH_ENGINES);
      setData(resp);
    })();
  }, [...deps]);
  const byId = React.useMemo<Record<PrimaryKey, Top50SearchEngine>>(() => {
    if (!includeById) {
      return {};
    }
    return _.keyBy(data, "pk");
  }, [data, ...deps]);
  return { data, byId };
};
