enum MutationTypes {
  FETCH_TOP50PROJECTS_SUCCEED = "metrika/top50/FETCH_TOP50PROJECTS_SUCCEED",
  FETCH_TOP50PROJECTS_START = "metrika/top50/FETCH_TOP50PROJECTS_START",
  FETCH_TOP50PROJECTS_FAIL = "metrika/top50/FETCH_TOP50PROJECTS_FAIL",

  SET_CURRENT_PROJECT = "metrika/top50/SET_CURRENT_PROJECT",
  SET_CURRENT_EVENT = "metrika/top50/SET_CURRENT_EVENT",

  FETCH_EVENT_SUCCEED = "metrika/top50/FETCH_EVENT_SUCCEED",
  FETCH_EVENT_START = "metrika/top50/FETCH_EVENT_START",
  FETCH_EVENT_FAIL = "metrika/top50/FETCH_EVENT_FAIL",

  FETCH_PROJECT_SUCCEED = "metrika/top50/FETCH_TOP50PROJECT_SUCCEED",
  FETCH_PROJECT_FAIL = "metrika/top50/FETCH_TOP50PROJECT_FAIL",
  FETCH_PROJECT_START = "metrika/top50/FETCH_PROJECT_START",

  FETCH_QUERIES_START = "metrika/top50/FETCH_PROJECT_START",
  FETCH_QUERIES_FAIL = "metrika/top50/FETCH_QUERIES_FAIL",
  FETCH_QUERIES_SUCCEED = "metrika/top50/FETCH_QUERIES_SUCCEED",

  ADD_QUERY_START = "metrika/top50/ADDING_QUERY_START",
  ADD_QUERY_FAIL = "metrika/top50/ADD_QUERY_FAIL",
  ADD_QUERY_SUCCEED = "metrika/top50/ADD_QUERY_SUCCEED",

  SET_MINIMUM_NUMBER_OF_VISITS = "metrika/top50/SET_MINIMUM_NUMBER_OF_VISITS",
  SET_SUBSTRING = "metrika/top50/SET_SUBSTRING",
}

export default MutationTypes;
