import { isCurrentlyLoadingUserNotifications } from "#/store/modules/session/selectors";
import {
  addUrlsToProject,
  importUrlsFromSitemapIntoProject,
} from "#/store/modules/sitemonitoring/actions";
import {
  DispatchProp,
  MonitoringProject,
  MonitoringProjectCheckedUrl,
  MonitoringProjectUrl,
  PrimaryKey,
} from "#/store/types";
import { CODE_NON_REACHABLE } from "#/util";
import _ from "lodash";

export enum TabName {
  CONTENT = 0,
  TITLE = 1,
  DESC = 2,
  H1 = 3,
  CODE = 4,
  URL_SETTINGS = 5,
  FILTERS = 6,
}

export enum TabPage {
  VISOR = "1",
  URL_SETTINGS = "2",
  FILTERS = "3",
}

export interface TabDescription {
  name: string;
  page: TabPage;
}

export type Tabs = Record<TabName, TabDescription>;

export const tabs: Tabs = {
  [TabName.CONTENT]: { name: "Content", page: TabPage.VISOR },
  [TabName.TITLE]: { name: "Title", page: TabPage.VISOR },
  [TabName.DESC]: { name: "Description", page: TabPage.VISOR },
  [TabName.H1]: { name: "H1", page: TabPage.VISOR },
  [TabName.CODE]: { name: "Code", page: TabPage.VISOR },
  [TabName.URL_SETTINGS]: { name: "Настройки URL", page: TabPage.URL_SETTINGS },
  [TabName.FILTERS]: { name: "Фильтры", page: TabPage.FILTERS },
};

export const tabName = (tab: TabName) => _.get(_.get(tabs, tab), "name");

export type FieldName = "code" | "content" | "description" | "h1" | "name" | "status" | "title";

export type FieldChangesRecord = Record<FieldName, number>;

export interface LastCheckInfo {
  fieldChanges: FieldChangesRecord;
}

export type UrlToggleAction = "0" | "1";

export interface IProjectContext {
  lastCheckInfo: LastCheckInfo;
  activeTab: TabName;
  toggleTab: (t: TabName) => void;
  unsubscribe: () => Promise<boolean>;
  subscribe: () => Promise<boolean>;
  project: MonitoringProject | null;
  isCheckActive: boolean;
  checkProgress: number;
  runCheckProcess: () => Promise<boolean>;
  loading: boolean;
  dates: string[];
  fetchCheckedUrls: (datecheck: string) => void;
  urls: MonitoringProjectUrl[];
  checkedUrls: MonitoringProjectCheckedUrl[];
  removeUrls: (pks: PrimaryKey[]) => Promise<void>;
  toggleUrls: (pks: PrimaryKey[], action: UrlToggleAction) => Promise<void>;
  addingUrls: boolean;
  addUrls: (urls: string[]) => ReturnType<DispatchProp<typeof addUrlsToProject>>;
  filters: string;
  setFilters: (filters: string) => Promise<string>;
  importFromSitemap: (
    sitemapURL: string,
    onlyCheckAmount?: boolean,
  ) => ReturnType<DispatchProp<typeof importUrlsFromSitemapIntoProject>>;
}

export const initialFieldChangesRecord = {
  code: 0,
  content: 0,
  description: 0,
  h1: 0,
  name: 0,
  status: 0,
  title: 0,
};

export const visorTabNameToFieldName = (tab: TabName): FieldName => {
  switch (_.toNumber(tab)) {
    case TabName.CODE:
      return "code";
    case TabName.TITLE:
      return "title";
    case TabName.DESC:
      return "description";
    case TabName.H1:
      return "h1";
    case TabName.CONTENT:
      return "content";
    default:
      CODE_NON_REACHABLE();
      return "code";
  }
};

export const tabIdToPageId = (tabId: TabName): TabPage => tabs[tabId].page;
