import { StoreRootState } from "#/store/types";
import cn from "classnames";
import React from "react";
import { connect } from "react-redux";
import NotificationMenu from "./components/NotificationMenu";
import Search from "./components/Search";
import SidebarToggleButton from "./components/SidebarToggleButton";
import UserProfileDropdown from "./components/UserProfileDropdown";
import style from "./index.module.scss";
import "./index.scss";

export interface NavbarOwnProps {
  isSidebarActive: boolean;
  handleClick: any;
}

interface NavBarConnectedProps {}

type NavbarProps = NavbarOwnProps & NavBarConnectedProps;

const Navbar = (props: NavbarProps) => {
  return (
    <header className="main-header">
      <nav
        className={cn({
          navbar: true,
          "navbar-static-top": true,
          [style.navbar]: true,
          "sidebar-active": props.isSidebarActive,
        })}
      >
        <SidebarToggleButton onClick={props.handleClick} />
        <Search />
        <div className="navbar-custom-menu pr-0">
          <NotificationMenu />
          <UserProfileDropdown />
        </div>
      </nav>
    </header>
  );
};

// TODO: Move isSidebarActive into the redux store
const mapStateToProps = (store: StoreRootState, ownProps: NavbarOwnProps) => ({
  isSidebarActive: ownProps.isSidebarActive,
  handleClick: ownProps.handleClick,
});

export default connect(
  mapStateToProps,
  {},
)(Navbar);
