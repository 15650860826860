import { PrimaryKey } from "#/store/types";
import { Dispatch } from "redux";
import MutationType from "./mutationType";

export const setCurrentProject = (projectId: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: MutationType.SET_CURRENT_PROJECT,
      payload: { id: projectId },
    });
    resolve();
  });
};
