import Select from "#/components/Select";
import usersModule from "#/store/modules/users";
import { PrimaryKey, StoreRootState, UserRole } from "#/store/types";
import { useReactMultiSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { SelectCustomProps } from "../Select";
import cn from "classnames";

export type UserRoleMultiSelectOwnProps = SelectCustomProps & {
  value: PrimaryKey[];
  onChange: (e: PrimaryKey[]) => void;
};

export interface UserRoleMultiSelectConnectedProps {
  roles: UserRole[];
}

export type UserRoleMultiSelectProps = UserRoleMultiSelectOwnProps &
  UserRoleMultiSelectConnectedProps;

const UserRoleSelectMultiple = (props: UserRoleMultiSelectProps) => {
  const selectState = useReactMultiSelect(
    props.roles,
    _.keyBy(props.roles, "pk"),
    r => (r ? r.name : ""),
    props.value,
    [props.roles, props.value],
  );
  return (
    <Select
      {...props}
      isSearchable={false}
      isMulti={true}
      options={selectState.options}
      value={selectState.selected}
      onChange={o => {
        props.onChange(o.map(o_ => _.toNumber(o_.value)));
      }}
      className={cn(["basic-multi-select"])}
      classNamePrefix="select"
    />
  );
};

export default connect(
  (store: StoreRootState, ownProps: UserRoleMultiSelectOwnProps): UserRoleMultiSelectProps => ({
    roles: usersModule.selectors.getAllUserRoles(store, null),
    ...ownProps,
  }),
  {},
)(UserRoleSelectMultiple);
