import { DEV_OR_TESTING_MODE } from "#/conf/constants";
import * as actions from "./actions";
import reducer, { initialState } from "./reducer";
import * as selectors from "./selectors";
export * from "./selectors";
export * from "./actions";
export * from "./reducer";

export { reducer, selectors, actions };

export default {
  reducer,
  selectors,
  actions,
  ...(DEV_OR_TESTING_MODE ? { __testInitialState: initialState } : {}),
};
