import Loader from "#/components/Loader";
import TagsListEditable from "#/components/TagsList/TagsListEditable";
import TaskChecklist from "#/components/TaskChecklist";
import ControllableTaskPrioritySelect from "#/components/TaskPrioritySelect";
import TaskStatusSelect from "#/components/TaskStatusSelect";
import { showSuccessToast } from "#/components/toasts";
import UserSelect from "#/components/UserSelect";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { canUser, canUserPerformAnyOf } from "#/permissions";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import taskModule from "#/store/modules/task";
import tasksModule from "#/store/modules/tasks";
import taskTasksModule from "#/store/modules/taskTasks";
import {
  BecomeTaskExecutor,
  CRMProject,
  CRMProjectData,
  CRMTask,
  OptionalPrimaryKey,
  StoreRootState,
  TaskChat,
  TaskChecklists,
  TaskStatus,
  ToggleCurrentUserTaskSubscription,
  User,
  UserRolesById,
} from "#/store/types";
import {
  constructCRMProjectPath,
  constructIndexPagePath,
  constructTaskTagSearchRoute,
  constructUserPath,
  getTaskDeadlineString,
  handleDispatchErrorAndDisplayToast,
  isTaskProjectUserApplicableToBeTaskExecutor,
  userFullName,
} from "#/util";
import assert from "assert";
import cn from "classnames";
import { push } from "connected-react-router";
import * as moment from "moment";
import "moment/locale/ru";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Link, Redirect, RouteComponentProps } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import Chat from "./components/Chat";
import style from "./index.module.scss";
import "./index.scss";
import { formatPriority } from "#/taskPriority";
import UserRoleSelect from "#/components/UserRoleSelect";
import usersModule from "#/store/modules/users";
import _ from "lodash";
import TaskExecutionAgreement from "./components/TaskExecutionAgreement";
import Editor from "#/components/Editor";

interface TaskPageConnectedProps extends RouteComponentProps<{ id: string }> {
  task: CRMTask | null;
  taskProject: CRMProject | null;
  loading: boolean;
  currentUser: User | null;
  taskLoading: boolean;
  taskChat: TaskChat;
  taskChecklists: TaskChecklists;
  loadingTaskFailed: boolean;
  project: CRMProjectData | null;
  taskFromUser: User | null;
  taskExecutor: User | null;
  taskProjectUsers: User[];
  roles: UserRolesById;

  fetchTask: any;
  addTaskChecklistItem: any;
  removeTaskChecklistItem: any;
  editTaskChecklistItem: any;
  sendTaskMessage: any;
  editTask: any;
  setCurrentTask: any;
  setupTaskChatWebSocket: any;
  closeTaskChatWebSocket: any;
  fetchTaskChatMessages: any;
  becomeTaskExecutor: BecomeTaskExecutor;
  toggleTaskSubscription: ToggleCurrentUserTaskSubscription;
  removeTask: any;
  push: typeof push;
}

interface TaskPageOwnProps {}

type TaskPageProps = TaskPageConnectedProps & TaskPageOwnProps;

const ContentHeader = (props: { task: CRMTask | null; project: CRMProject | null }) => {
  return (
    <section className="content-header">
      <h1>
        Задача \
        <small className="ml-1">
          {props.task ? (
            <Link to={constructCRMProjectPath(props.project)}>
              {props.project ? props.project.name : "N/A"}
            </Link>
          ) : (
            "N/A"
          )}
        </small>
      </h1>
      <ol className="breadcrumb">
        <li className="breadcrumb-item">
          <Link to="/">
            <i className="fa fa-dashboard" /> Панель Управления
          </Link>
        </li>
        <li className="breadcrumb-item active">
          <Link to="/projects/">Проекты</Link>
        </li>
      </ol>
    </section>
  );
};

const PageWrapper = ({ children }: { children: React.ReactNode }) => (
  <div className={style["page-wrapper"]}>{children}</div>
);

const TaskTagsBlock = (props: {
  task: CRMTask;
  editMode: boolean;
  setTags: (tags: string[]) => void;
}) => {
  return (
    <div className={cn([style["info-block-item"], style["task-tags-block"]])}>
      <div className="task-field-label">Тэги:</div>
      <TagsListEditable
        tags={props.task.tags}
        setTags={props.setTags}
        noTagsMessage="Нет тэгов"
        tagsClickable={true}
        constructTagLink={constructTaskTagSearchRoute}
        editMode={props.editMode}
      />
    </div>
  );
};

const TaskPage = (props: TaskPageProps) => {
  // Task probably is not found
  if (!props.loading && props.task === null && props.loadingTaskFailed) {
    return <Redirect to={constructIndexPagePath()} />;
  }
  assert(props.currentUser);
  const [task, setTask] = useState(props.task);
  const [editMode, setEditMode] = useState(false);
  const [taskOptionsDropdownOpen, setTaskOptionsDropdownOpen] = useState<boolean>(false);

  const toggleShared = () =>
    props.editTask({ ...task!, shared: !task!.shared }).catch(handleDispatchErrorAndDisplayToast);

  // Initial render
  useEffect(() => {
    // TODO: Handle 404
    props
      .fetchTask(props.match.params.id)
      .then(() => {
        props.setCurrentTask(props.match.params.id);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  }, [props.match.params.id]);

  // Reset the local task state whenever it updates globally
  useEffect(() => {
    setTask(props.task);
  }, [props.task]);

  // Display loading preloader
  if (props.loading || props.task === null || task === null) {
    return (
      <PageWrapper>
        <div className={style["loader-container"]}>
          <Loader />
        </div>
      </PageWrapper>
    );
  }

  assert(props.task);
  assert(task);

  const taskFromFullName = userFullName(props.taskFromUser);
  const { taskChecklists } = props;
  const isManager = props.currentUser!.pk === props.task.task_from;
  const isExecutor = props.currentUser!.pk === props.task.task_to;
  const canDeleteTask = canUser(props.currentUser, "DELETE", "TASK", {
    obj: task,
    taskProject: props.project!,
  });
  const anyTaskActionsAvailable = canUserPerformAnyOf(props.currentUser, [
    ["DELETE", "TASK", { obj: task, taskProject: props.project! }],
  ]);

  const showExecutionAgreement =
    task.status !== TaskStatus.FINISHED &&
    !!task.manager_minutes_to_complete &&
    !task.agreed_minutes_to_complete &&
    (isExecutor || isManager);

  const saveChanges = () => {
    setEditMode(false);
    props.editTask(task).catch(handleDispatchErrorAndDisplayToast);
  };

  const discardChanges = () => {
    setEditMode(false);
    // Reset the edited content
    setTask(props.task);
  };

  const taskProject = props.project ? props.project.data : null;
  const canEdit = canUser(props.currentUser, "EDIT", "TASK", {
    obj: task,
    taskProject: props.project!,
  });

  return (
    <PageWrapper>
      <ContentHeader task={props.task} project={taskProject} />
      <section className={cn(["content", style.content])}>
        <TransitionGroup>
          <CSSTransition
            classNames="mask"
            transitionAppear={true}
            transitionAppearTimeout={0}
            timeout={{
              enter: 0,
              exit: 300,
            }}
          >
            <>
              {/* Main info */}
              <div className="row">
                <div className="col-12 col-lg-9">
                  <div className="box box-portlet">
                    {/* Header */}
                    <div
                      className={cn(["box-header", "with-border", style["task-main-box-header"]])}
                    >
                      <div className={style["title-group"]}>
                        {/* Task name */}
                        {!editMode ? (
                          <h4 className={cn(["box-title", style["title-group__title"]])}>
                            <span>
                              <i className="fas fa-bookmark" />
                            </span>
                            <span>Задача:</span>
                            <span>{task.name}</span>
                          </h4>
                        ) : (
                          <input
                            className="form-control"
                            value={task.name}
                            onChange={(e: any) => setTask({ ...task, name: e.target.value })}
                            placeholder="Название"
                          />
                        )}
                      </div>

                      <div className="flex-spacer" />

                      {/* Edit toggle */}
                      {canEdit && (
                        <div className={style["edit-toggle-group"]}>
                          {!editMode ? (
                            <Button
                              outline={true}
                              color="primary"
                              onClick={() => setEditMode(true)}
                            >
                              Редактировать
                            </Button>
                          ) : (
                            <>
                              <Button color="primary" onClick={saveChanges}>
                                Сохранить изменения
                              </Button>
                              <Button color="secondary" onClick={discardChanges}>
                                Отменить
                              </Button>
                            </>
                          )}
                        </div>
                      )}

                      {/* Task options dropdown */}
                      {anyTaskActionsAvailable && (
                        <div className={style["task-options-dropdown"]}>
                          <Dropdown
                            isOpen={taskOptionsDropdownOpen}
                            toggle={() => setTaskOptionsDropdownOpen(!taskOptionsDropdownOpen)}
                            direction="down"
                          >
                            <DropdownToggle
                              color="transparent"
                              className={style["task-options-dropdown__toggle"]}
                            >
                              <i className="fa fa-ellipsis-v" />
                            </DropdownToggle>
                            <DropdownMenu>
                              {canDeleteTask && (
                                <DropdownItem
                                  style={{ cursor: "pointer" }}
                                  title="Удалить задачу"
                                  onClick={() => {
                                    props
                                      .removeTask(task.pk)
                                      .then(() => {
                                        showSuccessToast("Задача была успешно удалена");
                                        props.push(constructIndexPagePath());
                                      })
                                      .catch(handleDispatchErrorAndDisplayToast);
                                  }}
                                >
                                  Удалить
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      )}
                    </div>

                    <div className={cn(["box-body", style["task-main-box-description"]])}>
                      {/* Description */}
                      <div className={style["main-info-block-item"]}>
                        <div className="task-field-label">Содержание:</div>
                        {!editMode ? (
                          <div
                            className="html-text"
                            dangerouslySetInnerHTML={{
                              __html: task.description,
                            }}
                          />
                        ) : (
                          <Editor
                          contentValue={task.description}
                          onChildChange={e => setTask({ ...task, description: e})}
                          />
                        )}
                      </div>

                      {showExecutionAgreement && (
                        <TaskExecutionAgreement
                          task={task}
                          isExecutor={isExecutor}
                          isManager={isManager}
                          editTask={props.editTask}
                        />
                      )}
                    </div>
                  </div>
                </div>

                {/* Secondary info block */}
                <div className="col-12 col-lg-3">
                  <div className="box box-portlet">
                    <div
                      className={cn(["box-header with-border", style["task-info-block-header"]])}
                    >
                      <h4 className={cn(["box-title", style["info-title"]])}>
                        <i className="fas fa-info-circle" /> Информация
                      </h4>

                      <div className="flex-spacer" />

                      {/* Task subscription bell */}
                      <div className={style["task-notifications-sub-container"]}>
                        <Button
                          color="transparent"
                          onClick={() => props.toggleTaskSubscription(task.pk)}
                          disabled={task.status === TaskStatus.FINISHED && !task.subscribed}
                          title={
                            task.subscribed
                              ? "Отписаться от уведомлений о задаче"
                              : task.status === TaskStatus.FINISHED
                              ? "Нельзя подписаться на уведомления о завершенной задаче"
                              : "Подписаться на уведомления о задаче"
                          }
                        >
                          {task.subscribed ? (
                            <>
                              <i
                                className={cn(["fa", "fa-bell", style["subscribed-status-bell"]])}
                              />
                              <i
                                className={cn(["fa", "fa-bell-slash", style["unsubscribe-bell"]])}
                              />
                            </>
                          ) : (
                            <i className="far fa-bell" />
                          )}
                        </Button>
                      </div>
                    </div>

                    <div className="box-body">
                      {/* Deadline */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Завершить до:</div>
                        {!editMode ? (
                          <p>{getTaskDeadlineString(task, "Дата не назначена")}</p>
                        ) : (
                          <DatePicker
                            className="form-control d-block"
                            selected={task.finish_date ? new Date(task.finish_date) : undefined}
                            dateFormat="yyyy-MM-dd HH:mm"
                            showTimeSelect={true}
                            timeFormat="HH:mm"
                            onChange={d => setTask({ ...task, finish_date: d ? d : null })}
                            timeCaption="Время"
                            timeIntervals={30}
                            locale="ru-RU"
                            shouldCloseOnSelect={true}
                            placeholderText="Выберите дату окончания"
                            required={true}
                          />
                        )}
                      </div>

                      {/* Status */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Статус:</div>
                        <div className="mb-1 fz-16">
                          <TaskStatusSelect task={task} />
                        </div>
                        {task.status_comment && task.status_comment.length > 0 && (
                          <>
                            <div className="task-field-label mt-2">Комментарий:</div>

                            <div
                              className={style["task-status-comment"]}
                              dangerouslySetInnerHTML={{
                                __html: task.status_comment,
                              }}
                            />
                          </>
                        )}
                      </div>

                      {/* Execution start time */}
                      <div className={style["info-block-item"]}>
                        {task.execution_start_time && (
                          <>
                            <div className="task-field-label">Время начала выполнения:</div>
                            <div className="mb-1 fz-16">
                              {(() => {
                                return (moment as any)(task.execution_start_time).format(
                                  "DD.MM.YYYY [в] HH:mm:ss",
                                );
                              })()}
                            </div>
                          </>
                        )}
                      </div>

			{/* Execution start time */}
                      <div className={style["info-block-item"]}>
                        {task.execution_start_time && (
                          <>
                            <div className="task-field-label">Время оценки:</div>
                            <div className="mb-1 fz-16">
                              {task.manager_minutes_to_complete}
                            </div>
                          </>
                        )}
                      </div>
                      {/* Completion time */}
                      {task.execution_time && (
                        <div className={style["info-block-item"]}>
                          <>
                            <div className="task-field-label">Время завершения:</div>
                            <div
                              className="mb-1 fz-16"
                              title={(() => {
                                moment.locale("ru");
                                const d = (moment.duration(task.execution_time) as any).format(
                                  "W [недель], d [дней,] HH [часов,] mm [минут,] ss [секунд]",
                                );
                                return `Задача была завершена за ${d}`;
                              })()}
                            >
                              {task.completed_date
                                ? (moment as any)(task.completed_date).format(
                                    "DD.MM.YYYY [в] HH:mm:ss",
                                  )
                                : "Не завершена"}
                            </div>
                          </>
                        </div>
                      )}

                      {/* Task from */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Кто назначил:</div>
                        <p>
                          <Link to={"/profile/" + task.task_from}>{taskFromFullName}</Link>
                        </p>
                      </div>

                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Подразделение</div>
                        {!editMode ? (
                          <p>{_.get(props.roles, `${props.task.role}.name`, "Не назначено")}</p>
                        ) : (
                          <UserRoleSelect
                            value={task.role ? task.role.toString() : null}
                            onChange={value =>
                              setTask({
                                ...task,
                                role: parseInt(value),
                                task_to: null,
                              })
                            }
                            className="form-control select2-custom"
                          />
                        )}
                      </div>

                      {/* Task to */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Исполнитель:</div>
                        {!editMode ? (
                          <p>
                            {props.taskExecutor ? (
                              <Link to={constructUserPath(props.taskExecutor)}>
                                {userFullName(props.taskExecutor)}
                              </Link>
                            ) : (
                              "Не назначен"
                            )}
                          </p>
                        ) : (
                          <UserSelect
                            value={task.task_to}
                            onChange={(newExecutorId: OptionalPrimaryKey) =>
                              setTask({ ...task, task_to: newExecutorId })
                            }
                            defaultOption="Нет"
                            users={props.taskProjectUsers.filter(user =>
                              isTaskProjectUserApplicableToBeTaskExecutor(user, task.role),
                            )}
                          />
                        )}
                      </div>

                      {/* Priority */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">Приоритет:</div>
                        {!editMode ? (
                          <p>{formatPriority(task.priority)}</p>
                        ) : (
                          <ControllableTaskPrioritySelect
                            value={task.priority}
                            required={false}
                            onChange={np => {
                              setTask({ ...task, priority: np! });
                            }}
                          />
                        )}
                      </div>

                      {/* Tags */}
                      <TaskTagsBlock
                        task={task}
                        editMode={editMode}
                        setTags={(newTags: string[]) => {
                          setTask({ ...task, tags: newTags });
                        }}
                      />

                      {/* Shared */}
                      <div className={style["info-block-item"]}>
                        <div className="task-field-label">
                          <span className="table-checkbox">
                            <label className="table-checkbox-label">
                              <input
                                type="checkbox"
                                value="0"
                                checked={task.shared}
                                onChange={toggleShared}
                                disabled={
                                  !canUser(props.currentUser, "EDIT", "TASK", { obj: task })
                                }
                              />
                              &nbsp;
                              <span className="d-block" />
                            </label>
                          </span>
                          <label className="ml-2 small" onClick={toggleShared}>
                            Доступна для интеграции с сервисами
                          </label>
                        </div>
                      </div>
                      {/* Become an executor of this task */}
                      {!props.taskExecutor && !props.task.task_to && (
                        <div
                          className={style["become-executor-button"]}
                          onClick={() => props.becomeTaskExecutor(props.task!.pk)}
                        >
                          <Button color="primary">Стать исполнителем</Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                {/* Checklist */}
                <div className="col-12 col-lg-8">
                  <TaskChecklist
                    items={taskChecklists}
                    isManager={isManager}
                    isExecutor={isExecutor}
                    task={task}
                  />
                </div>

                {/* Chat */}
                <Chat task={task} />
              </div>
            </>
          </CSSTransition>
        </TransitionGroup>
      </section>
    </PageWrapper>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    task: taskTasksModule.selectors.getCurrentTaskBare(store),
    taskChecklists: taskTasksModule.selectors.getCurrentTaskChecklistsSortedByPrimaryKey(store),
    loading: taskModule.selectors.isLoading(store),
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
    loadingTaskFailed: tasksModule.selectors.didLoadingTasksFail(store),
    project: taskTasksModule.selectors.getCurrentTaskProjectData(store, null),
    taskFromUser: taskTasksModule.selectors.getCurrentTaskUserFrom(store, null),
    taskExecutor: taskTasksModule.selectors.getCurrentTaskExecutor(store, null),
    taskProjectUsers: taskTasksModule.selectors.getCurrentTaskProjectUsers(store, null),
    roles: usersModule.selectors.getUserRolesById(store, null),
  };
};

export default connect(mapStateToProps, {
  ...getActionsFromModule(tasksModule, [
    "fetchTask",
    "editTask",
    "becomeTaskExecutor",
    "toggleTaskSubscription",
    "removeTask",
  ]),
  ...getActionsFromModule(taskModule, ["setCurrentTask"]),
})(withAuthentication<TaskPageProps>(UserAuthenticationStatus.AUTHENTICATED)(TaskPage));
