import YandexFavicon from "#/components/YandexFavicon";
import { fetchSitesWithTypes, getSites, getSiteTypes } from "#/store/modules/accounts";
import { AccountsSite, AccountsSiteType } from "#/store/modules/accounts/types";
import { DispatchProp, PrimaryKey, StoreRootState } from "#/store/types";
import React, { Fragment, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter, Link } from "react-router-dom";
import { Button, Collapse, ListGroup, ListGroupItem } from "reactstrap";
import _ from "lodash";
import "./index.scss";
import { extractHostname } from "#/util";

interface IndexOwnProps extends RouteComponentProps<any> {
  siteTypes: Record<number, AccountsSiteType>;
  sites: Record<number, AccountsSite>;
  fetchSitesWithTypes: DispatchProp<typeof fetchSitesWithTypes>;
}

const Index: React.FC<IndexOwnProps> = props => {
  const [openSites, setOpenSites] = useState<Record<string, boolean>>({});

  const isSiteCollapseOpen = (id: string) => _.get(openSites, id, false);
  const toggleSite = (id: string) => setOpenSites({ ...openSites, [id]: !isSiteCollapseOpen(id) });

  const renderedButtons = useMemo(
    () =>
      _.keys(props.siteTypes).length > 0 ? (
        <div className="card">
          <ListGroup flush={true}>
            {Object.entries(props.siteTypes).map(([id, type]: [any, AccountsSiteType]) => (
              <Fragment key={id.toString()}>
                <ListGroupItem
                  className="list-group-item-action mb-0"
                  onClick={() => toggleSite(id)}
                >
                  <Button color="link" style={{ color: "#000" }} outline={false}>
                    <span className="lead">{type.name}</span>
                  </Button>
                </ListGroupItem>
                <Collapse isOpen={isSiteCollapseOpen(id)}>
                  <ListGroup>
                    {type.site_set.map((pk: PrimaryKey) => {
                      const site = props.sites[pk];
                      return (
                        <Link to={`${pk}`}>
                          <ListGroupItem
                            className={`list-group-item-action container pl-3 ${
                              !site.number_of_accounts ? "disabled" : ""
                            }`}
                            tag={"a"}
                            href={`${pk}`}
                            key={pk}
                          >
                            <div className="justify-content-between align-items-center d-flex mx-3">
                              <div>
                                <YandexFavicon name={extractHostname(site.url)} alt="" />
                                <span className="ml-2">{site.name}</span>
                              </div>
                              <div>
                                <small className="accountInfo">
                                  Всего аккаунтов: {site.number_of_accounts}
                                </small>
                              </div>
                            </div>
                          </ListGroupItem>
                        </Link>
                      );
                    })}
                  </ListGroup>
                </Collapse>
              </Fragment>
            ))}
          </ListGroup>
        </div>
      ) : (
        <div style={{ width: "fit-content", margin: "auto", padding: "80px 0" }}>Нет аккаунтов</div>
      ),
    [props.siteTypes, props.sites, openSites],
  );

  return (
    <>
      <div className="box-header with-border flex-vertically-centered-x justify-content-between">
        <h4 className="box-title">Accounts</h4>
      </div>
      <div className="box-body">{renderedButtons}</div>
    </>
  );
};

export default withRouter(
  connect(
    (store: StoreRootState, ownProps: IndexOwnProps) => ({
      sites: getSites(store, ownProps),
      siteTypes: getSiteTypes(store, ownProps),
    }),
    {
      fetchSitesWithTypes,
    },
  )(Index),
);
