import React from "react";
import { Link } from "react-router-dom";
import style from "./index.module.scss";

const TagsList = (props: {
  tags: string[];
  noTagsMessage?: string;
  tagsClickable: boolean;
  constructTagLink: (tag: string) => string;
}) => {
  return (
    <div className={style["tags-list"]}>
      {(() => {
        if (props.tags && props.tags.length > 0) {
          return props.tags.map(tag => (
            <span className={style.tag} key={tag}>
              {props.tagsClickable ? (
                <Link to={props.constructTagLink(tag)}>{`#${tag}`}</Link>
              ) : (
                `#${tag}`
              )}
            </span>
          ));
        } else {
          return props.noTagsMessage ? <div className="">{props.noTagsMessage}</div> : null;
        }
      })()}
    </div>
  );
};

export default TagsList;
