import { TaskCheckListItem } from "#/store/types";
import { formatContent, formatDateTime } from "#/util";
import isEqual from "lodash/isEqual";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TableCheckbox from "#/components/TableCheckbox";

interface ChecklistItemConnectedProps {}

interface ChecklistItemOwnProps {
  item: TaskCheckListItem;
  taskLoading: boolean;
  isManager: boolean;
  isExecutor: boolean;
  task_finish_date: string | Date;
  onStatusToggle: (item: TaskCheckListItem) => Promise<unknown>;
  onRemove: (item: TaskCheckListItem) => Promise<unknown>;
  onEdit: (payload: TaskCheckListItem) => Promise<unknown>;
  selectionEnabled: boolean;
  showTaskCompletionDate: boolean;
}

type ChecklistItemProps = ChecklistItemConnectedProps & ChecklistItemOwnProps;

const ChecklistItem = (props: ChecklistItemProps) => {
  const [itemForInput, setItemForInput] = useState(props.item);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    if (editMode) {
      setItemForInput(props.item);
    }
  }, [props.item]);
  const toggleEditMode = () => setEditMode(!editMode);

  const save = () => {
    if (!isEqual(itemForInput, props.item)) {
      props.onEdit(itemForInput).then(() => {
        setEditMode(false);
      });
    } else {
      setEditMode(false);
    }
  };

  const discard = () => {
    setEditMode(false);
    setItemForInput(props.item);
  };

  const onEnterPress = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      save();
    }
  };

  return (
    <tr>
      {props.selectionEnabled && (
        <td>
          <TableCheckbox
            checked={itemForInput.status}
            onChange={() => {
              if (props.isManager || props.isExecutor) {
                props.onStatusToggle(itemForInput);
              }
            }}
          />
        </td>
      )}
      <td>
        {editMode ? (
          <input
            type="text"
            className="form-control"
            value={itemForInput.name}
            onChange={e => setItemForInput({ ...itemForInput, name: e.target.value })}
            placeholder="Введите содержиме"
            onKeyDown={onEnterPress}
          />
        ) : (
          formatContent(itemForInput.name)
        )}
      </td>
      {props.showTaskCompletionDate && (
        <td>
          {itemForInput.finish_date ? formatDateTime(itemForInput.finish_date) : "Не завершено"}
        </td>
      )}
      {props.isManager && (
        <td className="text-right">
          {editMode ? (
            <>
              <button
                className="btn btn-list row-action"
                type="button"
                onClick={save}
                disabled={props.taskLoading}
              >
                <i className="fas fa-check" />
              </button>
              <button
                className="btn btn-list row-action"
                type="button"
                onClick={discard}
                disabled={props.taskLoading}
              >
                <i className="fas fa-times" />
              </button>
            </>
          ) : (
            <>
              <button className="btn btn-list row-action" type="button" onClick={toggleEditMode}>
                <i className="fas fa-pen-alt" />
              </button>
              <button
                className="btn btn-list row-action"
                type="button"
                onClick={() => {
                  props.onRemove(itemForInput);
                }}
                disabled={props.taskLoading}
              >
                <i className="fas fa-trash-alt" />
              </button>
            </>
          )}
        </td>
      )}
    </tr>
  );
};

export default ChecklistItem;
