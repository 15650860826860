import * as actions from "./actions";
import reducer from "./reducer";
import * as selectors from "./selectors";

/*
  This store module is responsible for storing and handling CRM project data.
*/

const projectsModule = {
  reducer,
  actions,
  selectors,
};

export default projectsModule;
