import { StoreRootState } from "#/store/types";
import { constructContactUsPagePath, constructIndexPagePath } from "#/util";
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import style from "./style.module.scss";

const NotFoundPage = (props: { push: typeof push }) => {
  return (
    <div className={style.root}>
      <div className={style.message}>
        <div className={style.message__title}>
          <h2>404: Страница не найдена.</h2>
        </div>
        <div className={style.message__text}>
          Страница {`"${window.location.pathname}"`} не была найдена. Перейдите на главную страницу
          или обратитесь в поддержку.
        </div>
        <div className={style.message__button}>
          <Button
            className={style.message__button__button}
            onClick={() => props.push(constructIndexPagePath())}
            color="primary"
          >
            Перейти на главную страницу
          </Button>
          <Button
            className={style.message__button__button}
            onClick={() => props.push(constructContactUsPagePath())}
            color="secondary"
          >
            Обратиться в поддерку
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (store: StoreRootState) => ({}),
  { push },
)(NotFoundPage);
