import { createReducer } from "#/store/helpers";
import { TaskState } from "#/store/types";
import _ from "lodash";
import { Action } from "redux";
import MutationType from "./mutationType";

const initialState: TaskState = {
  taskId: null,
  fetching: false,
  fetchingFailed: false,
  taskChatWebSocket: null,
};

export const reducer = createReducer<TaskState, Action>(
  {
    [MutationType.SET_CURRENT_TASK]: (state: TaskState, action: any) => ({
      ...state,
      taskId: _.toNumber(action.payload.id),
    }),
    [MutationType.SET_TASK_CHAT_WEBSOCKET]: (state: TaskState, action: any) => ({
      ...state,
      taskChatWebSocket: action.payload.ws,
    }),
  },
  initialState,
);

export default reducer;
