import {
  apiGet,
  apiPatch,
  apiPost
} from "#/api";
import {
  API_ROUTES
} from "#/conf/api";
import {
  AccountsRequestBody,
  AccountsSite,
  AccountsSiteType,
  Account,
  AccountsQueryParameter,
  AccountsQuery,
} from "#/store/modules/accounts/types";
import {
  PrimaryKey
} from "#/store/types";
import {
  undefinedOr
} from "#/util";
import {
  Set
} from "immutable";
import _ from "lodash";
import {
  Dispatch
} from "redux";
import MutationTypes from "./mutationTypes";
import {
  getCurrentSiteId,
  getRequestBody,
  getSelection,
  getSites,
  getCurrentSite,
  getQueryParams,
} from "./selectors";
import {
  action
} from "typesafe-actions";
import {
  showErrorToast
} from "#/components/toasts";
import ActionTypes from "./actionTypes";
import accounts from ".";
import thunk from "redux-thunk";

export const setSites = (data: Record < PrimaryKey, AccountsSite > ) => ({
  type: MutationTypes.SITES_SET,
  payload: data,
});

export const setSiteTypes = (data: Record < PrimaryKey, AccountsSiteType > ) => ({
  type: MutationTypes.SITE_TYPES_SET,
  payload: data,
});
export const startFetchingSites = () => ({
  type: MutationTypes.SITES_FETCH_START,
});

export const finishFetchingSites = () => ({
  type: MutationTypes.SITES_FETCH_FINISH,
});

const languages = [{
  lang: 'English'
}]

export const fetchSitesWithTypes = () => (dispatch: Dispatch, getState: any) => {
  dispatch(startFetchingSites());
  apiGet(API_ROUTES.ACCOUNTS.GET_TYPES_OF_SITES).then(res => {
    const sites = {};
    const siteTypes = _.chain(res)
      .keyBy("pk")
      .mapValues(val => {
        val.site_set = _.chain(val.site_set)
          .keyBy("pk")
          .value();
        _.assign(sites, val.site_set);
        val.site_set = _.chain(val.site_set)
          .keys()
          .map(_.toNumber)
          .value();
        return val;
      })
      .value() as unknown;
    dispatch(setSites(sites));
    dispatch(setSiteTypes(siteTypes as Record < PrimaryKey, AccountsSiteType > ));
    dispatch(finishFetchingSites());
  });
};

// export const setCurrentSitePk = (pk: PrimaryKey) => ({
//   type: MutationTypes.CURRENT_SITE_PK_SET,
//   payload: pk,
// });

// export const setCurrentSite = (pk: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
//   dispatch(setCurrentSitePk(pk));
//   if (!getSites(getState(), {})[pk]) {
//     dispatch(startFetchingSites());
//     dispatch(setCurrentSitePk(pk));
//     apiGet(API_ROUTES.ACCOUNTS.GET_SITE(pk))
//       .then(res => {
//         dispatch(setSites({ [pk]: res, ...getSites(getState(), {}) }));
//         dispatch(finishFetchingSites());
//       })
//       .catch(err => {
//         dispatch(finishFetchingSites());
//       });
//   }
// };

export const startFetchingAccounts = () => ({
  type: MutationTypes.ACCOUNTS_FETCH_START,
});

// export const finishFetchingAccounts = () => ({
//   type: MutationTypes.ACCOUNTS_FETCH_FINISH,
// });

export const setAccounts = (accounts: Account[]): ActionTypes.SetAccounts =>
  action(MutationTypes.ACCOUNTS_SET, {
    accounts,
  });

// export const fetchAccounts = (request, sitePk) => (dispatch: Dispatch, getState: any) => {
//   // dispatch(startFetchingAccounts());
//   apiPost(API_ROUTES.ACCOUNTS.GET_SITE_ACCOUNTS(sitePk), request)
//     .then(res => {
//       console.log("Response", res);
//       dispatch(setAccounts(res.accounts));
//       dispatch(setMaxPages(res.max_pages));
//       dispatch(finishFetchingAccounts());
//     })
//     .catch(err => {
//       showErrorToast("Ошибка во время загрузки аккаунтов" + err.message);
//     });
// };

export const setSelection = (selection: PrimaryKey[]) => ({
  type: MutationTypes.ACCOUNTS_SELECTION_SET,
  payload: selection,
});

export const setMaxPages = (max_pages: number) => ({
  type: MutationTypes.ACCOUNTS_MAX_PAGES_SET,
  payload: max_pages,
});

export const setRequestParams = (params: AccountsRequestBody) => ({
  type: MutationTypes.ACCOUNTS_REQUEST_PARAMS_SET,
  payload: params,
});

export const removeAccounts = (pks: PrimaryKey[], sitePk: PrimaryKey) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const query = "?pk=" + pks.join();
  apiPatch(API_ROUTES.ACCOUNTS.SITE_ACCOUNTS_REQUEST_WITH_QUERY(sitePk, query), {
    use: true
  }).then(
    res => {
      const params: AccountsRequestBody = getState().accounts.params;
      dispatch(setSelection([]));
      fetchAccounts()(dispatch, getState);
      // dispatch<any>(fetchAccounts(params));
    },
  );
};

export const resetParams = () => ({
  type: MutationTypes.ACCOUNTS_REQUEST_PARAMS_RESET,
});

export const finishFetchingSite = (site: AccountsSite) => action(MutationTypes.SITE_FETCH_SUCCESS);

export const failFetcingSite = () => action(MutationTypes.SITE_FETCH_FAILURE);

export const fetchSite = (pk: number) => (dispatch: Dispatch, getState: any) => {
  dispatch(startFetchingSites());
  apiGet(API_ROUTES.ACCOUNTS.GET_SITE(pk))
    .then(res => {
      dispatch(finishFetchingSite(res));
    })
    .catch(err => {
      dispatch(failFetcingSite());
    });
};

export const setCurrentSite = (sitePk: PrimaryKey): ActionTypes.SetCurrentSite =>
  action(MutationTypes.CURRENT_SITE_SET, {
    site: sitePk,
  });

export const successFetchAccounts = (
    _accounts: Account[],
    maxPages: number,
  ): ActionTypes.FetchAccountsSuccess =>
  action(MutationTypes.ACCOUNTS_FETCH_SUCCESS, {
    accounts: _accounts,
    maxPages,
  });

export const fetchAccounts = () => (dispatch: Dispatch, getState: any) => {
  const sitePk = getCurrentSite(getState(), {}).pk;
  const requestBody = getRequestBody(getState(), {});
  apiPost(API_ROUTES.ACCOUNTS.GET_SITE_ACCOUNTS(sitePk), requestBody).then(res => {
    dispatch(successFetchAccounts(res.accounts, res.max_pages));
  });
};

export const debouncedFetchAccounts = _.debounce(fetchAccounts(), 1500)

export const setQuery = (query: AccountsQuery): ActionTypes.SetQuery => action(MutationTypes.QUERY_SET, {
  query
})

export const changeFilterSettings = (way: string, value: AccountsQueryParameter < any > ) =>
  (
    dispatch: Dispatch,
    getState: any,
  ) => {
    const params = getQueryParams(getState(), {});
    if (Object.entries(value).length === 0) {
      return dispatch(setQuery(_.omit(params, way)))
    }
    console.log("ARE THESE SONS OF WHORES EQUAL", params === _.set({
      ...params || {}
    }, way, value))
    dispatch(setQuery(_.set({
      ...params || {}
    }, way, value)))
    debouncedFetchAccounts(dispatch, getState)
  }

export const changeSortSettings = (way: String | null, value ? : boolean) => (dispatch: Dispatch, getState: any) => {
  let params: AccountsQuery = getQueryParams(getState(), {}) || {};
  console.log("Params before removing order by", params, way, value)
  const removeOrderBy = (obj: Object) => Object.keys(obj).forEach((key) => {
    if (key === "balance_set") {
      removeOrderBy(obj[key])
    }
    obj[key] = _.omit(obj[key], "order_by")
  })

  removeOrderBy(params)
  if (way) {
    console.log("Setting new params")
    params = _.set({
      ...params
    }, `${way}.order_by`, value)
  }
  dispatch(setQuery(params))
  fetchAccounts()(dispatch, getState)

}

export const resetSettings = () => action(MutationTypes.RESET_SETTINGS)

export const changeCurrentSite = (pk: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  const currentPk = getCurrentSiteId(getState(), {})
  if (currentPk !== pk) {
    dispatch(resetSettings())
  }
  dispatch(setCurrentSite(pk))
}

export const setPage = (page: number): ActionTypes.SetPage => action(MutationTypes.PAGE_SET, {
  page
})

export const changePage = (page: number) => (dispatch: Dispatch, getState: any) => {
  dispatch(setPage(page))
  fetchAccounts()(dispatch, getState)
}

export const setPageSize = (pageSize: number): ActionTypes.SetPageSize => action(MutationTypes.PAGE_SIZE_SET, {
  pageSize
});


export const changePageSize = (pageSize: number) => (dispatch: Dispatch, getState: any) => {
  dispatch(setPageSize(pageSize))
  fetchAccounts()(dispatch, getState)
}

// export const