import React from "react";
import Select from "react-select";
import { SelectComponentsProps } from "react-select/src/Select";
import { undefinedOr } from "#/util";
import cn from "classnames";
import "./index.scss";

export interface SelectOption {
  value: string | number;
  label: string;
}

export const VALUE_NOT_SET = "default-value";

export type SelectCustomProps = Omit<SelectComponentsProps, "isDisabled"> & {
  disabled?: boolean;
  invalid?: boolean;
  placeholder?: string;
  isMulti?: boolean;
};

const SelectCustom = (props: SelectCustomProps) => {
  const disabled = undefinedOr(props.disabled, false);
  const invalid = undefinedOr(props.invalid, false);
  const isMulti = undefinedOr(props.isMulti, false);
  return (
    <Select
      {...props}
      isDisabled={disabled}
      isMulti={isMulti}
      classNamePrefix={cn([props.classNamePrefix, "select-custom-container"])}
      placeholder={props.placeholder ? props.placeholder : "Выберите..."}
      className={cn([
        cn({
          "select-custom-container": true,
          "select-custom-container_invalid": invalid,
        }),
        undefinedOr(props.className, ""),
      ])}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default SelectCustom;
