import { showErrorToast } from "#/components/toasts";
import { ChatMessage, FetchCurrentTaskChatMessages, User, UsersById } from "#/store/types";
import { scrollElementToTheBottom } from "#/util";
import assert from "assert";
import _ from "lodash";
import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import style from "../index.module.scss";
import ChatItem from "./ChatItem";

const ChatMessagesContainer = (props: {
  fetchTaskChatMessages: FetchCurrentTaskChatMessages;
  chatMessages: ChatMessage[];
  usersById: UsersById;
  currentUser: User;
  allTaskMessagesAlreadyLoaded: boolean;
}) => {
  const chatContainer = useRef(null);
  const scrollToBottomChatContainer = () => {
    const element: Element | null = chatContainer.current;
    if (element === null) { return; }
    scrollElementToTheBottom(element);
  };
  // Are we currently loading more messages from the past?
  const [loadingMoreMessages, setLoadingMoreMessages] = useState(false);
  // Are we currently loading messages from the past?
  const [loadingPastMessages, setLoadingPastMessages] = useState(false);

  const [topmostElementHistory, setTopmostElementHistory] = useState<Element[]>([]);

  // Load more messages from the past
  const loadMore = useCallback(
    _.debounce((thenScrollTo: Element) => {
      props
        .fetchTaskChatMessages()
        .then((messages: ChatMessage[]) => {
          setLoadingMoreMessages(false);
          // Add previous topmost element to history
          thenScrollTo.scrollIntoView();
        })
        .catch(err => {
          showErrorToast("Failed to load more messages");
          setLoadingMoreMessages(false);
        });
    }, 1000),
    [props.fetchTaskChatMessages, topmostElementHistory],
  );

  const onTopSentinelInView = (ref: Element) => {
    // Load more past messages
    if (props.allTaskMessagesAlreadyLoaded) { return; }
    setLoadingMoreMessages(true);
    setLoadingPastMessages(true);
    setTopmostElementHistory([...topmostElementHistory, ref]);
    loadMore(ref);
  };

  // Scroll to the bottom of the chat when chat updates
  useLayoutEffect(() => {
    if (!loadingPastMessages) {
      // New message appeared
      scrollToBottomChatContainer();
    }
  }, [props.chatMessages]);

  return (
    <div className={style["chat-messages-container"]}>
      <div className="chat-content" ref={chatContainer}>
        {props.chatMessages.map((msg, i) => {
          const isPreviousMessageForeign =
            i === 0 ? true : props.chatMessages[i].sender !== props.chatMessages[i - 1].sender;
          const user = _.get(_.get(props.usersById, msg.sender, {}), "data", null);
          const topmost = i === 0;
          return (
            <ChatItem
              isForeign={props.currentUser.pk !== msg.sender}
              item={msg}
              user={user}
              isPreviousMessageForeign={isPreviousMessageForeign}
              key={i}
              topSentinel={topmost}
              onSentinelInView={onTopSentinelInView}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ChatMessagesContainer;
