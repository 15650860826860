import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { PrimaryKey, Top50SearchEngine } from "#/store/types";
import { useDebounced } from "#/util/hooks";
import { useReactSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";
import { useSearchEngines } from "./useSearchEngines";

type ControllableSearchEngineSelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: PrimaryKey | null;
  onChange: (p: Top50SearchEngine | null) => void;
};

type SEDisplayFunction = (se: Top50SearchEngine | null) => string;

const defaultDF = (se: Top50SearchEngine | null) => (se ? se.name : "");

const ControllableSearchEngineSelect = (props: ControllableSearchEngineSelectProps) => {
  const [query, setQuery] = React.useState<string>("");
  const debouncedQuery = useDebounced(query, 1000);
  const searchEngines = useSearchEngines({ includeById: true }, [debouncedQuery]);
  const df: SEDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;
  const selectState = useReactSelect(searchEngines.data, searchEngines.byId, df, props.value, {}, [
    searchEngines,
    props.users,
    props.usersById,
    df,
    props.value,
  ]);
  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={true}
      options={selectState.options}
      value={selectState.selected}
      onInputChange={e => setQuery(e)}
      onChange={(option: SelectOption) => {
        props.onChange(option.value ? _.get(searchEngines.byId, _.toNumber(option.value)) : null);
      }}
      isDisabled={props.disabled}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default ControllableSearchEngineSelect;
