import { APIError } from "#/api";
import ShortcutsModal from "#/components/ShortcutsModal";
import { showErrorToast } from "#/components/toasts";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import { Logout, StoreRootState, User } from "#/store/types";
import {
  constructIndexPagePath,
  constructSettingsPagePath,
  constructUserPath,
  userEmail,
  userFirstName,
  userFullName,
} from "#/util";
import { constructChangelogPath } from "#/util/paths";
import cn from "classnames";
import { push } from "connected-react-router";
import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import style from "./index.module.scss";
import _ from "lodash";

export interface UserProfileDropdownConnectedProps {
  logout: Logout;
  user: User | null;
  push: typeof push;
}

type UserProfileDropdownProps = UserProfileDropdownConnectedProps;

const ItemWrapper = (props: {
  toggleDropdown: () => void;
  children: React.ReactNode;
  onClick: () => void;
}) => {
  return (
    <DropdownItem onClick={props.onClick} className={cn([style.item, "col-12 text-left"])}>
      {props.children}
    </DropdownItem>
  );
};

const UserProfileDropdown = (props: UserProfileDropdownProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const logout = () => {
    props.logout().catch((err: APIError) => {
      showErrorToast(`Произошла ошибка при попытке выхода: ${err.message}`);
    });
  };
  const [shortcutsModalOpen, setShortcutsModalOpen] = useState<boolean>(false);
  const commonItemWrapperProps = {
    toggleDropdown: () => setIsOpen(!isOpen),
  };

  const onToggle = useCallback(() => setIsOpen(!isOpen), [setIsOpen, isOpen]);

  return (
    <>
      <Dropdown
        isOpen={isOpen}
        toggle={onToggle}
        direction="down"
        className={cn([style.dropdown])}
        size="lg"
      >
        <DropdownToggle className={style["menu-toggle"]} outline={false}>
          <span className={style["menu-toggle__username"]}>{userFirstName(props.user)}</span>
          {userFirstName(props.user) && (
            <span className="k-badge k-badge--username k-badge--lg k-badge--brand">
              {userFirstName(props.user).split("")[0]}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu right={true}>
          <div className={style["user-header-section"]}>
            <img
              src={
                props.user!.avatar
                  ? (props.user!.avatar as string)
                  : "https://avatars.servers.getgo.com/2205256774854474505_medium.jpg"
              }
              className={cn([style["user-avatar"]])}
              alt="User"
            />
            <div className={style["user-info"]}>
              <div className={style["user-info__name"]}>{userFullName(props.user)}</div>
              <div className={style["user-info__email"]}>{userEmail(props.user)}</div>

              {props.user && (
                <div className={style["user-info__balance"]}>
                  Баланс: {props.user.minutes_fullfilled}
                </div>
              )}


              <div className={style["user-info__balance"]}>
                Токен: {localStorage.getItem("token")}
              </div>
            </div>
          </div>
          <div className={cn([style["user-body-section"], "w-100"])}>
            <ItemWrapper
              {...commonItemWrapperProps}
              onClick={() => props.push(constructUserPath(props.user))}
            >
              <i className="far fa-user" />
              <span>Моя Страница</span>
            </ItemWrapper>
            <ItemWrapper
              {...commonItemWrapperProps}
              onClick={() => props.push(constructIndexPagePath())}
            >
              <i className="fas fa-tasks" />
              <span>Задачи</span>
            </ItemWrapper>
            <ItemWrapper
              {...commonItemWrapperProps}
              onClick={() => props.push(constructSettingsPagePath())}
            >
              <i className="fas fa-cog" />
              <span>Настройки</span>
            </ItemWrapper>
            <ItemWrapper {...commonItemWrapperProps} onClick={_.partial(logout)}>
              <i className="fas fa-sign-out-alt" />
              <span>Выход</span>
            </ItemWrapper>
            <DropdownItem divider={true} />
            <ItemWrapper
              {...commonItemWrapperProps}
              onClick={_.partial(setShortcutsModalOpen, true)}
            >
              <i className="fa fa-keyboard" />
              Горячие клавиши
            </ItemWrapper>
            <ItemWrapper
              {...commonItemWrapperProps}
              onClick={() => props.push(constructChangelogPath())}
            >
              <i className="fa fa-file-invoice" />
              Список изменений
            </ItemWrapper>
          </div>
        </DropdownMenu>
      </Dropdown>
      {shortcutsModalOpen && (
        <ShortcutsModal
          isOpen={shortcutsModalOpen}
          toggle={_.partial(setShortcutsModalOpen, !shortcutsModalOpen)}
        />
      )}
    </>
  );
};

export default connect(
  (store: StoreRootState) => ({
    user: sessionUsersModule.selectors.getCurrentUser(store, null),
  }),
  {
    ...getActionsFromModule(sessionModule, ["logout"]),
    push,
  },
)(UserProfileDropdown);
