import React from "react";
import { DropdownItem } from "reactstrap";
import style from "./index.module.scss";
import ItemsMenuTabs, { NotificationMenuTab } from "./ItemMenuTabs";

const ItemsMenuHeader = (props: {
  currentTab: NotificationMenuTab;
  changeTab: (t: NotificationMenuTab) => void;
}) => (
  <DropdownItem className={style["notification-menu-header"]} header={true}>
    <span>Оповещения</span>
    <ItemsMenuTabs currentTab={props.currentTab} changeTab={props.changeTab} />
  </DropdownItem>
);

export default ItemsMenuHeader;
