import { SelectOption } from "#/components/Select";
import { PrimaryKey } from "#/store/types";
import _ from "lodash";
import React from "react";
import { undefinedOr } from ".";

export interface DataItem {
  pk: PrimaryKey;
}

export const useReactMultiSelect = <T>(
  data: Array<T & DataItem>,
  dataById: Record<PrimaryKey, T & DataItem>,
  df: (di: T | null) => string,
  value: PrimaryKey[],
  deps: any[],
): { options: SelectOption[]; selected: SelectOption[] } => {
  const options: SelectOption[] = React.useMemo(() => {
    if (!data) {
      return [];
    }
    return data.map(el => {
      return { value: _.toString(el.pk), label: df(el) };
    });
  }, [data, df, ...deps]);
  const selected: SelectOption[] = React.useMemo(() => {
    if (value.length === 0) {
      return [];
    }
    return value.map(v => {
      const selectedInfo = _.get(dataById, _.toNumber(v), null);
      return {
        value: _.toString(v),
        label: df(selectedInfo),
      };
    });
  }, [...deps]);
  return { options, selected };
};

export const useReactSelect = <K extends number | string, T>(
  data: T[],
  dataById: Record<K, T>,
  df: (di: T | null) => string,
  value: K | null,
  settings: {
    pkFieldName?: string;
  },
  deps: any[],
): { options: SelectOption[]; selected: SelectOption | undefined } => {
  const pkFieldName = undefinedOr(settings.pkFieldName, "pk");
  const options: SelectOption[] = React.useMemo(
    () =>
      data.map(el => {
        return { value: _.get(el, pkFieldName), label: df(el) };
      }),
    [data, df],
  );
  const selected: SelectOption | undefined = React.useMemo(
    () =>
      value === null
        ? undefined
        : (() => {
            const vinfo = _.get(dataById, value, null);
            return {
              value,
              label: df(vinfo),
            };
          })(),
    [value, dataById, df],
  );
  return { options, selected };
};

export const useReactMultiSelectRegion = <T>(
  data: Array<T & DataItem>,
  dataById: Record<PrimaryKey, T & DataItem>,
  df: (di: T | null) => string,
  value: SelectOption[],
  deps: any[],
): { options: SelectOption[]; selected: SelectOption[] } => {
  const options: SelectOption[] = (() => {
    if (!data) {
      return [];
    }
    return data.map(el => {
      return { value: _.toString(el.pk), label: df(el) };
    });
  })();

  const selected: SelectOption[] = (() => {
    if (value.length === 0) {
      return [];
    }
    return value.map(v => {
      const selectedInfo = _.get(dataById, _.toNumber(v.value), null);
      return {
        value: _.toString(v.value),
        label: df(selectedInfo),
      };
    });
  })();

  return { options, selected };
};
