import React from "react";
import { ModalState } from "#/util/hooks";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import _ from "lodash";
import EventItemContext, { IEventItemContext } from "./EventItemContext";

const MaybeRemoveEvent = (props: { modal: ModalState }) => {
  const { removeCurrentGroup, activeGroup } = React.useContext(
    EventItemContext,
  ) as IEventItemContext;
  return (
    <Modal
      isOpen={props.modal.shown}
      toggle={props.modal.toggle}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
      size="lg"
    >
      <ModalHeader toggle={props.modal.toggle}>Эта группа пуста</ModalHeader>
      <ModalBody>Эта группа пуста. Удалить её?</ModalBody>
      <ModalFooter>
        <button
          type="button"
          className="btn btn-primary"
          onClick={() => {
            removeCurrentGroup();
            props.modal.hide();
          }}
        >
          {`Да, удалить группу "${_.get(activeGroup, "name", "")}"`}
        </button>
        <button type="button" className="btn btn-secondary" onClick={props.modal.hide}>
          Нет
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default MaybeRemoveEvent;
