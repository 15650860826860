import { User } from "#/store/types";
import classnames from "classnames";
import React, { useMemo } from "react";
import style from "./index.module.scss";

export enum UserAvatarSize {
  SMALL = 0,
  MEDIUM = 1,
  LARGE = 2,
  AUTO = 3,
}

const UserAvatar = (props: {
  user: User;
  size: UserAvatarSize;
  isEditMode?: boolean;
  customAvatar?: string;
  clickInputAvatar?: () => void;
}) => {
  const userBadgeSubclassForUser = useMemo(
    () =>
      props.user.super_admin
        ? style["user-avatar_super-admin"]
        : props.user.admin
        ? style["user-avatar_admin"]
        : style["user-avatar_user"],
    [props.user],
  );
  const sizeSubclass = useMemo(
    () =>
      props.size === UserAvatarSize.SMALL
        ? "sm"
        : props.size === UserAvatarSize.LARGE
        ? "lg"
        : props.size === UserAvatarSize.MEDIUM
        ? "md"
        : null,
    [props.size],
  );
  const userTitleStr = useMemo(() => {
    if (props.user.super_admin) {
      return "Глав. Администратор";
    }
    if (props.user.admin) {
      return "Администратор";
    }
    return "Пользователь";
  }, [props.user]);
  const badgeContents = useMemo(() => {
    if (!props.user.admin) {
      return null;
    }
    return userTitleStr;
  }, [props.user, userTitleStr]);
  return (
    <div
      className={classnames([
        style["user-avatar"],
        ...(sizeSubclass ? [style[`user-avatar_${sizeSubclass}`]] : []),
        userBadgeSubclassForUser,
      ])}
    >
      <div className={style["user-avatar__overlay"]}>
        {props.size >= UserAvatarSize.LARGE && badgeContents && (
          <div className={classnames([style["user-badge"]])}>{badgeContents}</div>
        )}
        {props.size < UserAvatarSize.LARGE && (
          <div className={classnames([style["dot-user-badge"]])} title={userTitleStr} />
        )}
        {props.isEditMode ? (
          <>
            <img
              className="profile-user-img rounded-circle img-fluid mx-auto d-block cursor-pointer profile-user-img__editable"
              src={props.customAvatar}
              alt="Profile Card"
              onClick={props.clickInputAvatar}
            />
            <div
              className={classnames([style["user-avatar__overlay__upload"], "cursor-pointer"])}
              onClick={props.clickInputAvatar}
            >
              <i className="fas fa-upload"/>
            </div>
          </>
        ) : (
          <img
            className="profile-user-img rounded-circle img-fluid mx-auto d-block"
            src={
              props.user.avatar
                ? (props.user.avatar as string)
                : "https://avatars.servers.getgo.com/2205256774854474505_medium.jpg"
            }
            alt="Profile Card"
          />
        )}
      </div>
    </div>
  );
};

export default UserAvatar;
