import { apiGet, apiPost, apiPut } from "#/api";
import InputFilter from "#/components/DebouncedInput";
import { showErrorToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import { ContentPlanEvent, ContentPlanProject, SortType } from "#/store/types";
import { constructCRMProjectPath, formatDateTime, projectName, sortTable } from "#/util";
import React from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import Header from "../Header";
import APIModal from "./components/APIModal";
import EventItem from "./components/EventItem";
import "./index.scss";
import DebouncedInput from "#/components/DebouncedInput";

// CLEANUP: Isolate separate components embedded straight into the layout of this one into
//          reusable modules.
// CLEANUP: Refactor this component into using react hooks.
// CLEANUP: Move API interactions out of this component into ContentPlan service store module.

export interface ProjectOwnProps {}

interface ProjectConnectedProps extends RouteComponentProps<{ alias: string }> {}

type ProjectProps = ProjectOwnProps & ProjectConnectedProps;

interface ProjectState {
  loading: boolean;
  sortType: SortType;
  copyProject: ContentPlanProject | null;
  project: ContentPlanProject | null;
  events: ContentPlanEvent[];
  copyEvents: ContentPlanEvent[];
  editMode: boolean;
  modalOpen: boolean;
}

class Project extends React.Component<ProjectProps, ProjectState> {
  public state = {
    loading: false,
    sortType: SortType.DESC,
    copyProject: null,
    project: null,
    events: [],
    copyEvents: [],
    editMode: false,
    modalOpen: false,
  };

  public componentDidMount() {
    this.fetchProject();
  }

  public toggleModal = () => {
    this.setState({ ...this.state, modalOpen: !this.state.modalOpen });
  };

  public fetchProject = () => {
    const { alias } = this.props.match.params;
    this.setState({ ...this.state, loading: true });
    apiGet(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(alias))
      .then(response => {
        const { project, events } = response;
        this.setState({
          ...this.state,
          project,
          copyProject: project,
          events,
          copyEvents: events,
          loading: false,
        });
      })
      .catch((err: Error) => {
        console.error(err);
        this.setState({ loading: false });
      });
  };

  public onEventItemChange = (pk: number, payload: Partial<ContentPlanEvent>, callback: any) => {
    apiPut(API_ROUTES.CONTENTPLAN.EVENTS_ITEM(pk), payload)
      .then(response => {
        callback();
      })
      .catch((err: Error) => {
        this.setState({ loading: false });
      });
  };

  public onProjectFieldChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    const { value } = e.target;
    this.setState({
      ...this.state,
      project: {
        ...(this.state.project ? this.state.project : ({} as any)),
        [field]: value,
      },
    });
  };

  public saveEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.state.project !== null) {
      const project = (this.state.project as any) as ContentPlanProject;
      apiPut(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(project.pk), {
        name: project.name,
        description: project.description,
      }).then(response => {
        this.setState({
          ...this.state,
          project: response,
          copyProject: response,
          editMode: false,
        });
      });
    }
  };

  public apiChangeSubmit = (url: string) => {
    if (this.state.project !== null) {
      const project = (this.state.project as any) as ContentPlanProject;
      apiPut(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(project.pk), {
        apisource: url,
      }).then(response => {
        this.setState({
          ...this.state,
          project: response,
          copyProject: response,
          modalOpen: false,
        });
      });
    }
  };

  public resetEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({
      ...this.state,
      editMode: false,
      project: this.state.copyProject,
    });
  };

  public fetchData = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (this.state.project !== null) {
      const project = (this.state.project as any) as ContentPlanEvent;
      this.setState({ ...this.state, loading: true });
      apiPost(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM_FETCH(project.pk)).then(response => {
        if (response.status === "success") {
          this.setState({
            project: response.project,
            copyProject: response.project,
            events: response.events,
            copyEvents: response.events,
            loading: false,
          });
        } else {
          if (response.detail) {
            showErrorToast(response.detail);
          }
          this.setState({ loading: false });
        }
      });
    }
  };

  public onSort = (e: React.MouseEvent<HTMLElement>, sortKey: string, isNumeric: boolean) => {
    const { events, sortType } = this.state;
    const direction = sortType === SortType.DESC ? SortType.ASC : SortType.DESC;
    const newEvents = sortTable(events, sortKey, isNumeric);
    if (direction === SortType.DESC) {
      newEvents.reverse();
    }
    this.setState({ ...this.state, events: newEvents, sortType: direction });
  };

  public render() {
    if (this.state.project === null) {
      return null;
    }

    const { loading, events, editMode } = this.state;
    const project = (this.state.project as any) as ContentPlanProject;

    const eventsToRender = events.length
      ? events.map((event, eventsIndex) => (
          <EventItem onChange={this.onEventItemChange} event={event} key={eventsIndex} />
        ))
      : [];

    if (project === null) {
      return <></>;
    }

    return (
      <div>
        <Header />

        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border">
                  <div className="d-flex justify-content-between">
                    {/* Project name */}
                    {!editMode && (
                      <h4 className="box-title">
                        <img
                          src={"//favicon.yandex.net/favicon/" + project.name}
                          alt="project fav"
                        />
                        <span className="ml-2">
                          {project.name}
                          {` (`}
                          <Link
                            to={constructCRMProjectPath(project.base_project_model)}
                          >{`${projectName(project.base_project_model)}`}</Link>
                          {`)`}
                          <button
                            className="btn btn-list row-action ml-2"
                            onClick={e => this.setState({ editMode: true })}
                          >
                            <i className="fas fa-pen" />
                          </button>
                        </span>
                      </h4>
                    )}
                    {editMode && (
                      <div className="d-flex ml-2">
                        <input
                          type="text"
                          value={project.name}
                          onChange={e => this.onProjectFieldChange(e, "name")}
                          className="form-control"
                          placeholder="Название проекта"
                        />
                        <button
                          type="button"
                          className="btn btn-list row-action ml-2"
                          onClick={this.saveEdit}
                        >
                          <i className="fas fa-check" />
                        </button>
                        <button
                          type="button"
                          className="btn btn-list row-action ml-2"
                          onClick={this.resetEdit}
                        >
                          <i className="fas fa-times" />
                        </button>
                      </div>
                    )}
                    {!loading && (
                      <div className="mr-2">
                        <button
                          className="btn btn-list row-action"
                          disabled={loading}
                          onClick={this.fetchData}
                        >
                          <i className="fas fa-database" />
                          <span className="ml-1">Выгрузить данные</span>
                        </button>
                        <button
                          className="btn btn-list row-action"
                          disabled={loading}
                          onClick={this.toggleModal}
                        >
                          <i className="fas fa-link" />
                          <span className="ml-1">Источник API</span>
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="ml-2 mt-2">
                    {!editMode && <h6>{project.description}</h6>}
                    {editMode && (
                      <input
                        type="text"
                        value={project.description}
                        onChange={e => this.onProjectFieldChange(e, "description")}
                        className="form-control"
                        placeholder="Название проекта"
                      />
                    )}
                  </div>
                </div>
                <div className="box-body">
                  {loading && (
                    <div className="flex-vertically-centered py-4 text-center">
                      <img src={loader} className="m-auto" alt="Loading Spinner" />
                    </div>
                  )}
                  {!loading && (
                    <React.Fragment>
                      <div className="row">
                        <div className="col-12">
                          <div className="box">
                            <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                              <h5 className="box-subtitle mt-0">
                                <i className="fas fa-layer-group" />
                                <span className="ml-2">Мероприятия</span>
                              </h5>

                              <small>
                                {project.last_fetch ? (
                                  <span>
                                    Последнее снятие: {formatDateTime(project.last_fetch)}
                                  </span>
                                ) : (
                                  <span>Снятий не происходило</span>
                                )}
                              </small>
                            </div>
                            <div className="box-body">
                              <table className="table table-contentplan-project">
                                <thead>
                                  <tr>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "name", false)}
                                    >
                                      Название
                                      <DebouncedInput
                                        className="fz-13 form-control-small form-control-small-height form-control"
                                        placeholder="Фильтр"
                                        onChange={(v: string) =>
                                          this.setState({
                                            events: this.state.copyEvents.filter(
                                              (e: ContentPlanEvent) =>
                                                e.name.toLowerCase().includes(v.toLowerCase()),
                                            ),
                                          })
                                        }
                                        ms={1000}
                                      />
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "link", false)}
                                    >
                                      Ссылка
                                      <DebouncedInput
                                        className="fz-13 form-control-small form-control-small-height form-control"
                                        placeholder="Фильтр"
                                        onChange={(v: string) =>
                                          this.setState({
                                            events: this.state.copyEvents.filter(
                                              (e: ContentPlanEvent) =>
                                                e.link
                                                  ? e.link.toLowerCase().includes(v.toLowerCase())
                                                  : v === "",
                                            ),
                                          })
                                        }
                                        ms={1000}
                                      />
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "wordstat", true)}
                                    >
                                      WordStat
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "query", false)}
                                    >
                                      Запрос
                                      <DebouncedInput
                                        className="fz-13 form-control-small form-control-small-height form-control"
                                        placeholder="Фильтр"
                                        onChange={(v: string) =>
                                          this.setState({
                                            events: this.state.copyEvents.filter(
                                              (e: ContentPlanEvent) =>
                                                e.query
                                                  ? e.query.toLowerCase().includes(v.toLowerCase())
                                                  : v === "",
                                            ),
                                          })
                                        }
                                        ms={1000}
                                      />
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "seo_text", true)}
                                    >
                                      SEO-текст
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "status", false)}
                                    >
                                      Статус
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "short_text", true)}
                                    >
                                      Короткое описание
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "tech_info", true)}
                                    >
                                      Тех. Инфо
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "sujet_text", true)}
                                    >
                                      Описание сюжета
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "video", true)}
                                    >
                                      Видео
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "reviews", true)}
                                    >
                                      Отзывы
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "image", true)}
                                    >
                                      Кол-во картинок
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "comment", false)}
                                    >
                                      Комментарий
                                      <DebouncedInput
                                        className="fz-13 form-control-small form-control-small-height form-control"
                                        placeholder="Фильтр"
                                        onChange={(v: string) =>
                                          this.setState({
                                            events: this.state.copyEvents.filter(
                                              (e: ContentPlanEvent) =>
                                                e.comment ? e.comment.includes(v) : v === "",
                                            ),
                                          })
                                        }
                                        ms={1000}
                                      />
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "position", true)}
                                    >
                                      Съемщик позиций
                                    </th>
                                    <th
                                      className="cursor-pointer"
                                      onClick={e => this.onSort(e, "links", true)}
                                    >
                                      Ссылки
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>{eventsToRender}</tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>

        <APIModal
          toggle={this.toggleModal}
          modalOpen={this.state.modalOpen}
          api_source={project.apisource}
          onSubmit={this.apiChangeSubmit}
        />
      </div>
    );
  }
}

export default Project;
