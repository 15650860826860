import { getActionsFromModule } from "#/store/helpers";
import React, { useEffect, useState } from "react";
import { API_ROUTES, WS_API_TOP50_URL } from "#/conf/api";
import { apiGet } from "#/api";
import Top50GlobalContext, { ITop50GlobalContext } from "./Top50GlobalContext";
import { Top50Configuration } from "#/store/types";

export interface SitemonitoringPageProps {
  children: React.ReactElement;
}

export interface SitemonitoringPageConnectedProps {}

type WSMessageHandler = (messageData: any) => void;
type WSMessageType = "UPDATE_QUERY_DATA_COL_STATUS";

interface UpdateQueryDataColStatus {
  progress: number;
}

const Top50ServiceWrapper = (props: SitemonitoringPageProps & SitemonitoringPageConnectedProps) => {
  const [queryDataColProgress, setQueryDataColProgress] = React.useState<number>(0);
  const queryDataColStatus = React.useMemo(() => {
    if (queryDataColProgress === -1) { return false; }
    return true;
  }, [queryDataColProgress]);
  // Websocket interaction
  const [webSocket, setWebSocket] = React.useState<WebSocket | null>(null);
  const handlers: Record<WSMessageType, WSMessageHandler> = {
    UPDATE_QUERY_DATA_COL_STATUS: ({ progress }: UpdateQueryDataColStatus) => {
      setQueryDataColProgress(progress);
    },
  };
  const handleWSMessage = React.useCallback((messageType: WSMessageType, messageData: any) => {
    if (!(messageType in handlers)) {
      console.error(`handleWSMessage: Message type ${messageType} is not handled currently.`);
    }
    const f = handlers[messageType];
    return f(messageData);
  }, []);
  const setupSitemonitoringWebSocket = React.useCallback(() => {
    const ws = new WebSocket(WS_API_TOP50_URL);
    ws.addEventListener("close", event => {
      setWebSocket(null);
    });
    ws.addEventListener("message", event => {
      const message = event.data;
      const messageJson = JSON.parse(message);
      const messageType = messageJson.type;
      const messageData = messageJson.message;
      handleWSMessage(messageType, messageData);
    });
  }, [webSocket]);
  const closeSitemonitoringWebSocket = React.useCallback(() => {
    setWebSocket(null);
  }, []);

  const [defaultConfiguration, setDefaultConfiguration] = useState<Top50Configuration | null>(null);

  useEffect(() => {
    // Setup WS
    setupSitemonitoringWebSocket();

    // Fetch status
    (async () => {
      const qdcs: { progress: number } = await apiGet(API_ROUTES.TOP50.QUERY_DATA_COL_STATUS);
      setQueryDataColProgress(qdcs.progress);
    })();

    // Fetch default configuration
    (async () => {
      const resp = await apiGet(API_ROUTES.TOP50.DEFAULT_CONFIGURATION);
      setDefaultConfiguration(resp);
    })();

    return () => closeSitemonitoringWebSocket();
  }, []);

  return (
    <Top50GlobalContext.Provider
      value={{ queryDataColStatus, queryDataColProgress, defaultConfiguration }}
    >
      {props.children}
    </Top50GlobalContext.Provider>
  );
};

export default Top50ServiceWrapper;
