import ProjectSelect from "#/components/ProjectSelect";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import projectsModule from "#/store/modules/projects";
import {
  CRMProject,
  PrimaryKey,
  StoreRootState,
  SubmitEvent,
  Top50Project,
  Top50Configuration,
} from "#/store/types";
import { handleDispatchErrorAndDisplayToast, top50ProjectName } from "#/util";
import React, { useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ControllableConfigurationSelect from "../../ConfigurationsSelect/ControllableConfigurationsSelect";
import ProjectConfigurationModificationForm, {
  Top50ConfigurationCreate,
} from "../../ProjectConfigurationModificationForm";
import _ from "lodash";
import { useConfigurations } from "../../ConfigurationsSelect/useConfigurations";

interface CreateProjectModalOwnProps {
  onCreateProject: (payload: Partial<Top50Project>) => Promise<Top50Project>;
  isOpen: boolean;
  toggle: any;
  fetching?: boolean;
}

interface CreateProjectModalConnectedStateProps {
  projects: CRMProject[];
}

interface CreateProjectModalConnectedDispatchProps {}

type Props = CreateProjectModalConnectedStateProps & CreateProjectModalOwnProps;

const CreateProjectModal = (props: Props) => {
  const { isOpen, toggle, fetching = false } = props;
  const [projectName, setProjectName] = React.useState("");
  const [linkedProject, setLinkedProject] = useState<PrimaryKey | null>(null);
  const [linkedConfigurations, setLinkedConfigurations] = useState<Top50ConfigurationCreate[]>([]);

  const handleFormSubmit = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    // Validate input
    if (!projectName) {
      showErrorToast("Введите название проекта");
      return null;
    }
    if (linkedProject === null) {
      showErrorToast("Укажите связанный проект");
      return null;
    }
    if (linkedConfigurations === null) {
      showErrorToast("Укажите изначальную конфигурацию");
      return null;
    }
    // Create project
    return props
      .onCreateProject({
        title: projectName,
        base_project: linkedProject,
        configurations: (linkedConfigurations.map(c => ({
          region: c.region,
          search_engine: c.search_engine,
        })) as any[]) as Top50Configuration[],
      })
      .then((project: Top50Project) => {
        showSuccessToast(`Проект ${top50ProjectName(project)} был успешно создан`);
        setProjectName("");
        setLinkedProject(null);
        setLinkedConfigurations([]);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  };

  const configs = linkedConfigurations;

  const addingConfiguration = false;

  const onAddConfigurationSubmit = React.useCallback(
    (c: Top50ConfigurationCreate) => {
      if (_.find(linkedConfigurations, c) !== undefined) {
        return;
      }
      setLinkedConfigurations([...linkedConfigurations, c]);
    },
    [linkedConfigurations],
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader>Создать проект TOP50</ModalHeader>
      <ModalBody>
        <h6 className="mt-2 mb-2 fsz-18">Основное</h6>
        <FormGroup>
          <label className="my-2">Название проекта</label>
          <input
            className="form-control"
            value={projectName}
            onChange={evt => setProjectName(evt.target.value)}
            required={true}
          />
        </FormGroup>
        <FormGroup>
          <label className="my-2">Связанный проект</label>
          <ProjectSelect
            projects={props.projects}
            onChange={(newId: PrimaryKey | null) => setLinkedProject(newId)}
            value={linkedProject}
            required={true}
          />
        </FormGroup>

        <h6 className="mt-5 mb-2 fsz-18">Конфигурации</h6>
        <FormGroup>
          <ProjectConfigurationModificationForm
            configs={configs.map((c, idx) => ({
              region: c.region!,
              search_engine: c.search_engine!,
              search_engine_model: c.search_engine_model!,
              region_model: c.region_model!,
              pk: idx,
            }))}
            onAdd={onAddConfigurationSubmit}
            addingConfiguration={addingConfiguration}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <button
          type="submit"
          className="btn btn-success"
          disabled={props.fetching}
          onClick={handleFormSubmit}
        >
          {fetching ? "Подождите..." : "Продолжить"}
        </button>
        <button type="button" className="btn btn-secondary" onClick={toggle}>
          Отмена
        </button>
      </ModalFooter>
    </Modal>
  );
};

export default connect<
  CreateProjectModalConnectedStateProps,
  CreateProjectModalConnectedDispatchProps,
  CreateProjectModalOwnProps,
  StoreRootState
>((store: StoreRootState, ownProps: CreateProjectModalOwnProps) => {
  return {
    projects: projectsModule.selectors.getAllProjectsBare(store, null),
    ...ownProps,
  };
}, {})(CreateProjectModal);
