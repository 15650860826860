import { createReducer } from "#/store/helpers";
import TasksActionTypes from "#/store/modules/tasks/actionTypes";
import TasksMutationType from "#/store/modules/tasks/mutationType";
import { Action, PrimaryKey, SessionTasksState } from "#/store/types";

const initialState: SessionTasksState = {
  tasksToUser: [],
  tasksFromUser: [],
};

export default createReducer<SessionTasksState, Action>(
  {
    // Handle user tasks to fetching
    [TasksMutationType.FETCH_TASKS_TO_USER_SUCCEED]: (state: SessionTasksState, action: any) => ({
      ...state,
      tasksToUser: [...action.payload.tasks],
    }),

    // Handle user tasks from fetching
    [TasksMutationType.FETCH_TASKS_FROM_USER_SUCCEED]: (state: SessionTasksState, action: any) => ({
      ...state,
      tasksFromUser: [...action.payload.tasks],
    }),

    // Create task
    [TasksMutationType.CREATE_TASK_SUCCEED]: (
      state: SessionTasksState,
      action: TasksActionTypes.CreateTaskSucceed,
    ) => ({
      ...state,
      tasksFromUser: [...state.tasksFromUser, action.payload.task.pk],
    }),

    // Remove task
    [TasksMutationType.REMOVE_TASK_SUCCEED]: (
      state: SessionTasksState,
      action: TasksActionTypes.RemoveTaskSucceed,
    ) => ({
      ...state,
      tasksFromUser: state.tasksFromUser.filter((a: PrimaryKey) => a !== action.payload.id),
      tasksToUser: state.tasksToUser.filter((a: PrimaryKey) => a !== action.payload.id),
    }),
  },
  initialState,
);
