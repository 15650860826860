import React from "react";
import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { Top50Configuration } from "#/store/types";
import { useConfigurations } from "./useConfigurations";
import { useReactMultiSelectRegion } from "#/util/reactSelect";
import cn from "classnames";
import _ from "lodash";

type ControllableMultiConfigurationsSelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: SelectOption[];
  onChange: (p: SelectOption[]) => void;
  filter?: (c: Top50Configuration) => boolean;
};

type RegionDisplayFunction = (c: Top50Configuration | null) => string;

const defaultDF: RegionDisplayFunction = (c: any) =>
  c ? `${c.region_model!.name} - ${c.search_engine_model!.name}` : "Не выбрано";

const ControllableMultiConfigurationsSelect = (
  props: ControllableMultiConfigurationsSelectProps,
) => {
  const configs = useConfigurations({ includeById: true, filter: props.filter }, [props.filter]);
  const selectState = useReactMultiSelectRegion(
    configs.data,
    configs.byId,
    defaultDF,
    props.value,
    [configs, props.value],
  );
  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={true}
      options={selectState.options}
      value={props.value}
      onChange={(options: SelectOption[]) => {
        props.onChange(options);
      }}
      isDisabled={props.disabled}
      getOptionValue={(option: any) => option.value}
      isMulti={true}
      className={cn(["basic-multi-select"])}
      classNamePrefix="select"
    />
  );
};

export default ControllableMultiConfigurationsSelect;
