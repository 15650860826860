import projectProjectsModule from "#/store/modules/projectProjects";
import usersModule from "#/store/modules/users";
import { CRMProjectData, PrimaryKey, User, UserData, UsersById } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

const getCurrentProjectCreator = createSelector(
  [projectProjectsModule.selectors.getCurrentProjectData, usersModule.selectors.getUsersById],
  (cpd: CRMProjectData | null, uid: UsersById): User | null => {
    if (!cpd) {
      return null;
    }
    const puid = cpd.data!.creator;
    return _.get(_.get(uid, puid, null), "data", null);
  },
);

const getCurrentProjectUsersIds = createSelector(
  [projectProjectsModule.selectors.getCurrentProjectData],
  (cpd: CRMProjectData | null): PrimaryKey[] => (cpd ? cpd.users : []),
);

const getCurrentProjectUsersSortedByName = createSelector(
  [getCurrentProjectUsersIds, usersModule.selectors.getUsersById],
  (ids: PrimaryKey[], uid: UsersById): User[] =>
    _.compact(_.compact(ids.map(id => _.get(uid, id, null))).map(ud => _.get(ud, "data", null))),
);

export default {
  getCurrentProjectCreator,
  getCurrentProjectUsersSortedByName,
};
