import { useEffect } from "react";

export interface PageProps {
  title: string;
  children: JSX.Element;
}

const Page = (props: PageProps) => {
  useEffect(() => {
    document.title = props.title;
  }, []);
  return props.children;
};

export default Page;
