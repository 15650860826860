import { APIError } from "#/api";
import { showErrorToast } from "#/components/toasts";
import { WS_API_URL } from "#/conf/api";
import {
  APIOptionalDate,
  CRMProject,
  CRMProjectData,
  CRMTask,
  MonitoringProject,
  MonitoringProjectData,
  PrimaryKey,
  ProjectsById,
  SearchKind,
  TaskCheckListItem,
  TaskChecklists,
  TaskStatus,
  Top50Project,
  User,
  UserRolesById,
  UsersById,
} from "#/store/types";
import assert from "assert";
import _ from "lodash";
import * as moment from "moment";
import React from "react";
import { TaskPriority } from "#/taskPriority";

export const constructWSProjectChangesListenPath = (projID: number) =>
  `${WS_API_URL}/sitemonitoring/project/${projID}`;

export const sortByKey = function<T>(array: T[], key: string) {
  return array.sort((a: T, b: T) => {
    const x = parseInt((a as any)[key], 10);
    const y = parseInt((b as any)[key], 10);
    return x < y ? -1 : x > y ? 1 : 0;
  });
};

export const shadeColor = (color: string, percent: number) => {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR = R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
};

export const sortByTwoKeys = (array: Array<Record<string, any>>, fKey: string, sKey: string) => {
  return array.sort((a, b) => {
    const x1 = parseInt(a[fKey], 10);
    const x2 = parseInt(a[sKey], 10);
    const y1 = parseInt(b[fKey], 10);
    const y2 = parseInt(b[sKey], 10);

    return x1 < y1 ? -1 : x1 > y1 ? 1 : x2 < y2 ? -1 : x2 > y2 ? 1 : 0;
  });
};

export const sortTable = (arr: any[], key: string, isNumeric = true) => {
  if (isNumeric === false) {
    return arr.sort((a, b) => {
      const x = a[key];
      const y = b[key];
      if (!x) {
        return 1;
      }
      if (!y) {
        return -1;
      }
      return x.localeCompare(y);
    });
  } else {
    return arr.sort((a, b) => {
      if (!a[key]) {
        return 1;
      }
      if (!b[key]) {
        return -1;
      }
      const x = parseInt(a[key], 10);
      const y = parseInt(b[key], 10);
      return compareTwoNumbers(x, y);
    });
  }
};

export const objectWithoutKey = (object: Record<string, any>, key: string) => {
  const { [key]: deletedKey, ...otherKeys } = object;
  return otherKeys;
};

///////////////////////////////////////////////////////
// Date
///////////////////////////////////////////////////////

export const dateDiffInDays = (a: Date, b: Date) => {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24;

  const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor(Math.abs(utc2 - utc1) / _MS_PER_DAY);
};

export const dateDiffInHours = (date1: any, date2: any) => {
  return Math.round(Math.abs(date2 - date1) / 36e5);
};

export const formatDateTime = (date: Date | string | null) => {
  if (!date) {
    return "N/A";
  }
  return new Date(date).toLocaleString("ru", {
    month: "short",
    day: "numeric",
    timeZone: "UTC",
    hour: "numeric",
    minute: "numeric",
  });
};

export const formatDateLong = (date: Date | string) => {
  if (date === null || date === undefined) {
    return date;
  }

  return new Date(date).toLocaleString("ru", {
    month: "long",
    day: "numeric",
    timeZone: "UTC",
  });
};

export const formatDateWithYear = (date: Date) => {
  if (date === null || date === undefined) {
    return date;
  }

  return new Date(date).toLocaleString("ru", {
    month: "numeric",
    day: "numeric",
    year: "numeric",
    timeZone: "UTC",
  });
};

export const formatDate = (date: Date) => {
  if (date === null || date === undefined) {
    return date;
  }

  return new Date(date).toLocaleString("ru", {
    month: "numeric",
    day: "numeric",
    timeZone: "UTC",
  });
};

///////////////////////////////////////////////////////
// Other
///////////////////////////////////////////////////////

export const declOfNum = (number: number, titles: string[]) => {
  const cases = [2, 0, 1, 1, 1, 2];

  return (
    number +
    " " +
    titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  );
};

export const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
});

export const reorder = function<T>(list: T[], startIndex: number, endIndex: number) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging: boolean, draggableStyle: Record<string, any>) => ({
  userSelect: "none",
  background: isDragging ? "lightgrey" : "white",
  ...draggableStyle,
});

export const returnStorageItemOrTrue = (stringTitle: string) => {
  return localStorage.getItem(stringTitle) !== null
    ? JSON.parse(localStorage.getItem(stringTitle) as string)
    : true;
};

export const returnStorageItemOrFalse = (stringTitle: string) => {
  return localStorage.getItem(stringTitle) !== null
    ? JSON.parse(localStorage.getItem(stringTitle) as string)
    : false;
};

export const setLocalStorageBooleans = (itemsMap: Record<string, boolean>) =>
  _.keys(itemsMap).forEach((key: string) =>
    localStorage.setItem(key, JSON.stringify(itemsMap[key])),
  );

export const formatContent = (text: string) => {
  const content = text.split(/\s/);

  return content.map((token, i) => {
    const hasSpace = i !== content.length - 1;
    const maybeSpace = hasSpace ? " " : "";

    if (token.match(/^(http|https):/)) {
      return (
        <a key={i} href={token} target="_blank" rel="noopener noreferrer">
          {token} {maybeSpace}
        </a>
      );
    }

    return token + maybeSpace;
  });
};

export const doPaging = (
  current: number,
  { range, pages, start = 1 }: { range: number; pages: number; start?: number },
) => {
  const paging: number[] = [];

  let i = Math.min(pages + start - range, Math.max(start, current - ((range / 2) | 0)));
  const end = i + range;
  while (i < end) {
    paging.push(i++);
  }
  return paging;
};

export const getFunctionUniqueID = (f: Function) => {
  if (!f) {
    return "";
  }
  const fCode = f.toString();
  if (fCode.length <= 0) {
    return "";
  }
  let hash = 0;
  for (let currPos = 0; currPos < fCode.length; ++currPos) {
    hash ^= fCode[currPos].charCodeAt(0);
  }
  return f.name + hash.toString();
};

////////////////////////////////////////////////////////////
// Monitoring project helpers
////////////////////////////////////////////////////////////

export const isCurrentUserSubscribedToMonitoringProject = (mp: MonitoringProject | null) =>
  mp ? (mp.data ? mp.data.current_user_subscribed : false) : false;

export const getMonitoringProjectId = (mp: MonitoringProject | null) =>
  mp ? (mp.data ? mp.data.pk : null) : null;

export const constructURLToMonitoringProject = (mp: MonitoringProjectData | null) =>
  mp ? `/services/topvisor/${mp.pk}/` : ``;

////////////////////////////////////////////////////////////
// User helpers
////////////////////////////////////////////////////////////

export const userFullName = (user: User | null) =>
  user ? `${user.first_name} ${user.last_name}` : "N/A";

export const userShortName = (user: User | null) =>
  user ? `${user.first_name} ${user.last_name.charAt(0)}.` : "N/A";

export const isUserAdmin = (user: User | null) => (user ? user.admin : false);

export const userStatus = (user: User | null) =>
  user
    ? user.super_admin
      ? "Глав. Администратор"
      : user.admin
      ? "Администратор"
      : "Пользователь"
    : "N/A";

export const userRoles = (user: User | null) => (user ? (user.role ? user.role : []) : []);

export const forEachUserRole = (user: User | null, fn: (r: PrimaryKey) => void) =>
  userRoles(user).map(fn);

export const userFirstName = (user: User | null) =>
  user ? (user.first_name ? user.first_name : "N/A") : "N/A";

export const userEmail = (user: User | null) => (user ? (user.email ? user.email : "N/A") : "N/A");

// task helpers

export const getTaskExecutor = (task: CRMTask | null, users: UsersById) => {
  if (!task) {
    return null;
  }
  if (!task.task_to) {
    return null;
  }
  const executor = _.get(_.get(users, task.task_to, {}), "data", null);
  return executor;
};

export const getTaskManager = (task: CRMTask, users: UsersById) =>
  _.get(_.get(users, task.task_from, {}), "data", null);

export const getTaskProject = (t: CRMTask, pid: ProjectsById) =>
  _.get(_.get(pid, t.project, {}), "data", null);

export const taskStatusToString = (s: TaskStatus) => {
  switch (s) {
    case TaskStatus.CREATED:
      return "Новая";
    case TaskStatus.DELAYED:
      return "Отложена";
    case TaskStatus.FINISHED:
      return "Завершена";
    case TaskStatus.ONGOING:
      return "В процессе";
    case TaskStatus.REVISION:
      return "На доработку";
    case TaskStatus.TEST:
      return "На проверку";
    default:
      CODE_NON_REACHABLE();
      return "N/A";
  }
};
export const taskStatus = (t: CRMTask | null) => {
  return t ? taskStatusToString(t.status) : "N/A";
};

////////////////////////////////////////////////////////////
// Top50 helpers
////////////////////////////////////////////////////////////

export const top50ProjectName = (p: Top50Project | null) => (p ? p.title : "");

export const top50BaseProjectName = (p: Top50Project | null) =>
  p ? projectName(p.base_project_model) : null;

export const top50BaseProjectPath = (project: Top50Project | null) =>
  project ? constructCRMProjectPath(project.base_project_model) : "";

////////////////////////////////////////////////////////////
// CRM project helpers
////////////////////////////////////////////////////////////

export const isUserInProject = (project: CRMProjectData | null, user: User | null) =>
  project && user && _.includes(project.users, user.pk);

export const removeItemByPrimaryKeyAndSort = (list: Array<Record<string, any>>, pk: number) => {
  return sortByKey(
    list.filter(t => t.pk !== pk),
    "pk",
  );
};

export const projectName = (project: CRMProject | null) => (project ? project.name : "");

////////////////////////////////////////////////////////////
// Other
////////////////////////////////////////////////////////////

export const extractHostname = (url: string) => {
  let hostname = url.includes("//") ? url.split("/")[2] : url.split("/")[0];
  hostname = hostname.split(":")[0];
  hostname = hostname.split("?")[0];

  return hostname;
};

export const setLocalStorageValues = (values: Record<string, any>) =>
  _.keys(values).forEach((key: string) =>
    localStorage.setItem(key, JSON.stringify(_.get(values, key, null))),
  );

export const compareStringsAlphabetically = (a: string, b: string) => (a > b ? 1 : a < b ? -1 : 0);

export const compareTwoNumbers = (a: number, b: number) => (a > b ? 1 : a < b ? -1 : 0);

export const handleDispatchErrorAndDisplayToast = (error: APIError) => {
  showErrorToast(error.message);
};

interface ConstructURLToTaskCRMTask extends Partial<CRMTask> {
  pk: CRMTask["pk"];
}

export const constructURLToTask = (task: ConstructURLToTaskCRMTask) => {
  if (!task) {
    assert(
      false,
      "Invalid task given to the construct_url_to_task() function, it's probably null.",
    );
  }
  return `/task/${task.pk}`;
};

export const getTaskDeadlineString = (task: CRMTask, stringIfNotPresent?: string) => {
  if (!task.finish_date) {
    return stringIfNotPresent ? stringIfNotPresent : "N/A";
  }
  moment.locale("ru");
  const dd = (moment as any)(task.finish_date).format("D MMMM, HH:mm");

  const taskDateExpired = (() => {
    if (task.finish_date && task.completed_date) {
      return new Date(task.completed_date) > new Date(task.finish_date);
    } else if (task.finish_date) {
      return new Date(Date.now()) > new Date(task.finish_date);
    } else {
      // Task with no deadline set can't be expired
      return false;
    }
  })();

  if (taskDateExpired) {
    return `${dd} (просрочена)`;
  } else {
    return dd;
  }
};

export const compareTwoAPIFormatDates = (a: APIOptionalDate, b: APIOptionalDate) => {
  if (a === null && b !== null) {
    return -1;
  }
  if (a !== null && b === null) {
    return 1;
  }
  if (a === null && b === null) {
    return 0;
  }
  const aa = new Date(a!);
  const bb = new Date(b!);
  return aa > bb ? 1 : aa < bb ? -1 : 0;
};

export const compareTwoStringDates = (a: APIOptionalDate, b: APIOptionalDate) =>
  compareTwoAPIFormatDates(a, b);

export const compareTwoStringDatesAsc = (a: APIOptionalDate, b: APIOptionalDate) =>
  compareTwoStringDates(a, b);

export const getShortStringText = (str: string | null) => {
  if (!str) {
    return "...";
  }
  const maxlen = 75;
  const strlen = str.length;
  const newString = str.substr(0, maxlen);
  return `${newString}${strlen > maxlen ? "..." : ""}`;
};

// URLs

export const constructSkypeURLFromUsername = (skypeUsername: string) => `skype:${skypeUsername}`;
export const constructTelegramURLFromUsername = (telegramUsername: string) =>
  `https://t.me/${telegramUsername}`;

////////////////////////////////////////////////////////////
// Route path construction
////////////////////////////////////////////////////////////

export const constructIndexPagePath = () => "/";

export const constructCRMProjectPath = (project: CRMProject | null) =>
  project ? `/project/${project.pk}` : constructIndexPagePath();

export const constructUserPath = (user: User | null) =>
  user ? `/profile/${user.pk}` : constructIndexPagePath();

export const constructContactUsPagePath = () => "/contact/";

////////////////////////////////////////////////////////////
// User Interactions
////////////////////////////////////////////////////////////

export const downloadBlob = (filename: string, text: string) => {
  const element = document.createElement("a");
  element.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
  element.setAttribute("download", filename);
  element.style.display = "none";
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const copyToClipBoardEvent = (evt: React.MouseEvent<HTMLButtonElement>, value: string) => {
  const textField = document.createElement("textarea");
  textField.innerText = value;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};

export const CODE_NON_REACHABLE = () => assert(0, "Code not reachable");

export const trueIfUndefinedElseBoolean = (a: boolean | undefined) => (a !== undefined ? a : true);

export const falseIfUndefinedElseBoolean = (a: boolean | undefined) =>
  a !== undefined ? a : false;

export const getChecklistProgress = (taskChecklist: TaskChecklists): number => {
  const elementsCount = taskChecklist.length;
  const activeElements = taskChecklist.filter((i: TaskCheckListItem) => i.status).length;
  return elementsCount > 0 ? Math.round((activeElements / elementsCount) * 100) : 0;
};

export const compareTwoStringsAlphabetically = (a: string, b: string) =>
  a > b ? 1 : a < b ? -1 : 0;

export const scrollElementToTheBottom = (element: Element) => {
  const scrollHeight = element.scrollHeight;
  const height = element.clientHeight;
  const maxScrollTop = scrollHeight - height;
  element.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
};

export const scrollContainerToTheChildElement = (container: Element, child: Element) => {
  assert(container, "Container is null/undefined.");
  assert(child, "Child is null/undefined");
  container.scrollTop = (child as any).offsetTop;
};

export const constructSearchPagePath = (query: string) =>
  `/search/?query=${encodeURIComponent(query)}`;

export const constructTaskTagSearchRoute = (tag: string) => {
  const query = encodeURIComponent(`#${tag}`);
  const kind = encodeURIComponent(SearchKind.TASKS);
  return `/search?query=${query}&kind=${kind}`;
};

export const getAllPossibleValuesFromTSEnum = (E: any) => {
  const keys = Object.keys(E).filter(k => typeof E[k as any] === "number");
  const values = keys.map(k => E[k as any]);
  return values;
};

export const emptyStringIfUndefinedElseBoolean = (v: string) => {
  return v ? v : "";
};

export const projectCreator = (project: CRMProject, usersById: UsersById): User | null => {
  if (!project) {
    return null;
  }
  const user = _.get(usersById, project.creator, null);
  return user ? user.data : null;
};

export const projectCreatorName = (project: CRMProject, usersById: UsersById) => {
  const user = projectCreator(project, usersById);
  return user ? userFullName(user) : "N/A";
};

export const userRolesToStringList = (user: User | null, rolesById: UserRolesById) => {
  if (!user) {
    return [];
  }
  return _.compact(user.role.map(r => _.get(rolesById, r, { name: null }).name));
};

export const compareTwoBooleans = (a: boolean, b: boolean) =>
  compareTwoNumbers((a as any) as number, (b as any) as number);

export const undefinedOr = function<A>(a: A | undefined, b: A) {
  return a === undefined ? b : a;
};

export const nullOr = function<A>(a: A | null, b: A) {
  return a === null ? b : a;
};

export const isUserApplicableToBeTaskExecutor = (
  user: User,
  taskRoleType: PrimaryKey | null,
  projectUsers: PrimaryKey[],
) => taskRoleType && _.includes(user.role, taskRoleType) && _.includes(projectUsers, user.pk);

export const isTaskProjectUserApplicableToBeTaskExecutor = (
  user: User,
  taskRoleType: PrimaryKey | null,
) => taskRoleType && _.includes(user.role, taskRoleType);

export const isTaskAlreadyStarted = (task: CRMTask) => task.status !== TaskStatus.CREATED;

export const constructSettingsPagePath = () => "/settings/";

export const NON_REACHABLE_EMPTY_FUNCTION: any = () => CODE_NON_REACHABLE() as any;

export const monitoringProjectName = (mp: MonitoringProjectData | null) => (mp ? mp.name : "N/A");

export const monitoringProjectBaseProject = (mp: MonitoringProjectData | null) =>
  mp ? mp.base_project_model : null;

// tslint:disable-next-line
export const noop: any = () => {};

export const stopPropagationHandler = (e: any) => e.stopPropagation();
