import { showErrorToast } from "#/components/toasts";
import { SubmitEvent, Top50Event, PrimaryKey, Region } from "#/store/types";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";
import DatePicker from "#/components/DatePicker";
import Select, { SelectOption } from "#/components/Select";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";
import { parse_csv } from "../../utils";
import Checkbox from "#/components/Checkbox";
import assert from "assert";
import ControllableMultiRegionSelect from "../../../RegionSelect/ControllableMultiRegionSelect";
import ControllableConfigurationSelect from "../../ConfigurationsSelect/ControllableConfigurationsSelect";
import ControllableMultiConfigurationsSelect from "../../ConfigurationsSelect/ControllableMultiConfigurationSelect";

type QueryPayload = Array<{
  text: string;
  wordstat: number;
  wordstat1: number;
  wordstat2: number;
}>;

const formatImportQueryListText = (text: string): QueryPayload => {
  return text
    .split("\n")
    .map((str: string) => {
      const currentStr = str.split(";");
      const phrase = currentStr[0];
      const baseFreq = Number.isInteger(parseInt(currentStr[1], 10))
        ? parseInt(currentStr[1], 10)
        : 0;
      const partialFreq = Number.isInteger(parseInt(currentStr[2], 10))
        ? parseInt(currentStr[2], 10)
        : 0;
      const directFreq = Number.isInteger(parseInt(currentStr[3], 10))
        ? parseInt(currentStr[3], 10)
        : 0;
      return {
        text: phrase,
        wordstat: baseFreq,
        wordstat1: partialFreq,
        wordstat2: directFreq,
      };
    })
    .filter((str: any) => str.text);
};

interface CreateRequestsModalProps {
  toggle: () => void;
  onCreateNewGroup: (payload: any) => Promise<void>;
  onImportIntoGroup: (groupPk: PrimaryKey, queries: any[]) => Promise<void>;
  groups: any[];
  isOpen: boolean;
}

const parseFile = (file: any): Promise<[string, string[]]> =>
  parse_csv(file).then(result => {
    const unformatted = result.data.filter(el => el["Фраза"]) as string[];
    const formatted = unformatted
      .map((el: any) =>
        [el.Фраза, el["Базовая частота [YW]"], el["Частота * * [YW]"], el["Частота *!* [YW]"]].join(
          ";",
        ),
      )
      .join("\n");
    return [formatted, unformatted];
  });

const CreateRequestsModal = (props: CreateRequestsModalProps) => {
  // File input ref for query list file import
  const fileInput = React.createRef() as any;

  // Query list to import
  const [data, setData] = React.useState<{ formatted: string; unformatted: string[] }>({
    formatted: "",
    unformatted: [],
  });

  // Selected already-existing group import option
  const [selectedAlreadyExistingGroup, setSelectedAlreadyExistingGroup] = React.useState<{
    label: string; // name
    value: string; // pk
  } | null>(null);
  const selectedGroupInfo: Top50Event = React.useMemo(() => {
    if (!selectedAlreadyExistingGroup) {
      return null;
    }
    const g = props.groups.find(gg => gg.pk === _.toNumber(selectedAlreadyExistingGroup.value));
    return g;
  }, [selectedAlreadyExistingGroup]);

  // New group info
  const [importingIntoNewGroup, setImportingIntoNewGroup] = useState<boolean>(false);
  const [newGroupName, setNewGroupName] = useState<string>("");
  const [newGroupExpDate, setNewGroupExpDate] = React.useState<Date | undefined>(undefined);
  const [newGroupIsGeneric, setNewGroupIsGeneric] = useState<boolean>(false);
  const [newGroupSelectedConfigurations, setNewGroupSelectedConfigurations] = useState<
    SelectOption[]
  >([]);
  const newGroupNameInput = React.useRef<HTMLInputElement>(null);

  const resetAllData = React.useCallback(() => {
    setSelectedAlreadyExistingGroup(null);
    setNewGroupName("");
    setImportingIntoNewGroup(false);
    setNewGroupExpDate(undefined);
    setNewGroupIsGeneric(false);
    setNewGroupSelectedConfigurations([]);
  }, []);

  const onNewGroupNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewGroupName(e.target.value);
    },
    [setNewGroupName],
  );

  const onQueryListFileSubmit = React.useCallback(
    (evt: any) => {
      evt.preventDefault();
      fileInput.current.click();
    },
    [fileInput],
  );

  // On upload of a file containing request queries listing
  const onFileUpload = useCallback(
    async (event: any) => {
      event.preventDefault();
      if (!event.target.files.length) {
        return;
      }
      const file = fileInput.current.files[0];
      const filename = file.name.split(".")[0];
      const [formatted, unformatted] = await parseFile(file);
      setData({ formatted, unformatted });
      setSelectedAlreadyExistingGroup({ label: filename, value: filename });
      event.target.value = "";
    },
    [fileInput, setData, setSelectedAlreadyExistingGroup],
  );

  // Group options
  const renderedGroupOptions = useMemo(
    () =>
      props.groups.map((g: any) => ({
        value: _.toNumber(g.pk),
        label: g.name,
      })),
    [props.groups],
  );

  const onDiscard = useCallback(() => {
    resetAllData();
    props.toggle();
  }, [props.toggle, resetAllData]);

  // Validation
  const isExpDateValid = React.useMemo(() => {
    if (newGroupIsGeneric) {
      return true;
    }
    if (selectedGroupInfo && selectedGroupInfo.finishdate === null) {
      return true;
    }
    return newGroupExpDate ? new Date(newGroupExpDate).getTime() > new Date().getTime() : false;
  }, [newGroupExpDate, newGroupIsGeneric, selectedGroupInfo]);

  const existingGroupNames = React.useMemo(
    () => props.groups.map(g => g.name).reduce((acc, name) => ({ ...acc, [name]: true }), {}),
    [props.groups],
  );

  const isNewGroupNameAlreadyPresent = useMemo(
    () =>
      importingIntoNewGroup && newGroupName && newGroupName.length > 0
        ? existingGroupNames[newGroupName]
        : false,
    [importingIntoNewGroup, newGroupName, props.groups],
  );

  const areAllFieldsValid = useMemo(() => {
    return importingIntoNewGroup
      ? !isNewGroupNameAlreadyPresent && isExpDateValid
      : selectedAlreadyExistingGroup &&
          selectedAlreadyExistingGroup.label.length > 0 &&
          selectedAlreadyExistingGroup.value !== null;
  }, [
    selectedAlreadyExistingGroup,
    importingIntoNewGroup,
    isNewGroupNameAlreadyPresent,
    isExpDateValid,
  ]);

  const [fetching, setFetching] = useState<boolean>(false);

  const onExpDateChange = React.useCallback(
    (d: Date) => {
      setNewGroupExpDate(d);
      // if we are importing to an already existing group and its finishdate does not match
      // the one user selected, reset the group selection
      if (
        selectedGroupInfo &&
        !importingIntoNewGroup &&
        d !== new Date(selectedGroupInfo.finishdate)
      ) {
        setSelectedAlreadyExistingGroup(null);
      }
    },
    [selectedGroupInfo, importingIntoNewGroup],
  );

  // Submit
  const onSubmit = React.useCallback(
    async (evt: SubmitEvent) => {
      evt.preventDefault();
      const { formatted } = data;

      if (!areAllFieldsValid || !formatted) {
        if (importingIntoNewGroup && !isExpDateValid) {
          showErrorToast("Неверный срок активности!");
        } else {
          showErrorToast("Заполните все поля!");
        }
        return;
      }

      const queries = formatImportQueryListText(formatted);
      setFetching(true);

      try {
        const configurations = newGroupSelectedConfigurations.map(sConfiguration =>
          _.toNumber(sConfiguration.value),
        );

        if (importingIntoNewGroup) {
          // when importing into a new group
          // TODO: startdate param needs to be handled from the user interface
          const startdate = null;
          const finishdate = newGroupIsGeneric ? null : newGroupExpDate;
          await props.onCreateNewGroup({
            name: newGroupName,
            category: "Другое",
            queries,
            startdate,
            finishdate,
            configurations,
          });
        } else {
          // on import into an already existing group
          assert(selectedAlreadyExistingGroup !== null);
          await props.onImportIntoGroup(
            _.toNumber(selectedAlreadyExistingGroup!.value),
            queries,
          );
        }
        // close the modal after everything is ok
        props.toggle();
        resetAllData();
      } catch (err) {
        showErrorToast(err.message);
      } finally {
        setFetching(false);
      }
    },
    [
      data,
      selectedAlreadyExistingGroup,
      areAllFieldsValid,
      newGroupName,
      props.onCreateNewGroup,
      props.onImportIntoGroup,
      props.toggle,
    ],
  );

  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="modal-dialog-centered"
        contentClassName="alert alert-primary"
        size="lg"
      >
        <ModalHeader toggle={props.toggle}>Импорт запросов</ModalHeader>

        <form onSubmit={onSubmit}>
          <ModalBody>
            <FormGroup>
              <div className="d-flex flex-vertically-centered-x justify-content-between my-2">
                <label htmlFor="top50_requests_modal">
                  Поисковые запросы (каждый с новой строки)
                </label>
                <button
                  type="button"
                  className="btn btn-list row-action"
                  onClick={onQueryListFileSubmit}
                  title="Формат у файла должен быть таким же, как и в текстовом поле"
                >
                  <i className="fas fa-file-excel excel-icon mr-2" />
                  <span>Загрузить CSV</span>
                </button>
                <input
                  className="d-none"
                  type="file"
                  ref={fileInput}
                  name="file_csv_upload"
                  onChange={onFileUpload}
                />
              </div>
              <Input
                type="textarea"
                value={data.formatted}
                className="form-control col-12"
                onChange={(e: any) => setData({ ...data, formatted: e.target.value })}
                invalid={data.formatted.trim().length === 0}
                rows={8}
              />
            </FormGroup>

            {/* Import into an already existing group */}
            <FormGroup>
              <label className="my-2" title="Добавить запросы в существующую группу">
                Импортировать в группу
              </label>
              <Select
                className="select-custom-style"
                value={selectedAlreadyExistingGroup}
                onChange={pk => setSelectedAlreadyExistingGroup(pk)}
                options={renderedGroupOptions}
                placeholder="Выберите группу"
                noOptionsMessage={() => "Ничего не найдено"}
                required={!importingIntoNewGroup}
                invalid={!importingIntoNewGroup ? selectedAlreadyExistingGroup === null : false}
                disabled={importingIntoNewGroup}
              />
            </FormGroup>

            <FormGroup>
              <Checkbox
                checked={importingIntoNewGroup}
                className="mr-2"
                onChange={() => {
                  const newState = !importingIntoNewGroup;
                  setImportingIntoNewGroup(newState);
                  if (newState && newGroupNameInput.current) {
                    newGroupNameInput.current.focus();
                  }
                }}
              />
              <label className="my-2" title="Создать новую группу содержащую указанные запросы">
                Создать новую группу
              </label>
            </FormGroup>

            {/* Expiration date */}
            {importingIntoNewGroup && (
              <>
                <FormGroup>
                  <label title="Дата, до которой запросы будут обрабатываться/отображаться системой">
                    Срок активности
                  </label>
                  <DatePicker
                    className="form-control d-block mb-2"
                    selected={newGroupExpDate}
                    dateFormat="yyyy-MM-dd HH:mm"
                    showTimeSelect={true}
                    timeFormat="HH:mm"
                    onChange={onExpDateChange}
                    timeCaption="Время"
                    timeIntervals={30}
                    locale="ru-RU"
                    shouldCloseOnSelect={true}
                    placeholderText="Выберите дату окончания"
                    required={true}
                    invalid={!isExpDateValid}
                    title={!isExpDateValid ? "Неверная дата" : "Выберите дату"}
                    disabled={
                      importingIntoNewGroup
                        ? newGroupIsGeneric
                        : selectedGroupInfo && selectedGroupInfo.finishdate === null
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Input
                    type="text"
                    className="form-control"
                    innerRef={newGroupNameInput}
                    onChange={onNewGroupNameChange}
                    value={newGroupName}
                    placeholder="Введите название группы"
                    required={true}
                    invalid={importingIntoNewGroup && isNewGroupNameAlreadyPresent}
                    disabled={!importingIntoNewGroup}
                  />
                </FormGroup>

                <FormGroup>
                  <Checkbox
                    checked={newGroupIsGeneric}
                    onChange={() => setNewGroupIsGeneric(!newGroupIsGeneric)}
                  />
                  <label className="ml-2" title="Группа запросов не имеет сроков истечения">
                    Общая группа
                  </label>
                </FormGroup>
              </>
            )}

            <FormGroup>
              <ControllableMultiConfigurationsSelect
                value={newGroupSelectedConfigurations}
                onChange={(regions: SelectOption[]) => {
                  setNewGroupSelectedConfigurations(regions);
                }}
              />
            </FormGroup>
          </ModalBody>

          <ModalFooter>
            <button
              type="submit"
              className="btn btn-success"
              disabled={!!(fetching || !areAllFieldsValid)}
              title={
                fetching
                  ? "Подождите..."
                  : !isExpDateValid
                  ? "Выбранная дата не верна"
                  : isNewGroupNameAlreadyPresent
                  ? "Группа с таким именем уже существует"
                  : undefined
              }
            >
              {fetching
                ? "Подождите..."
                : importingIntoNewGroup
                ? "Создать новую группу"
                : "Импортировать запросы"}
            </button>
            <button type="button" className="btn btn-secondary" onClick={onDiscard}>
              Отмена
            </button>
          </ModalFooter>
        </form>
      </Modal>
    </>
  );
};

export default CreateRequestsModal;
