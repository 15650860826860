import { CRMProjectData, PrimaryKey, ProjectsById, StoreRootState } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";
import { ProjectState } from "./reducer";

// Root
type Props = {} | null;

export const getProjectState = (store: StoreRootState, props: Props) => store.project;
export const getProps = (state: StoreRootState, props: Props) => props;

export const getCurrentProjectId = createSelector(
  [getProjectState],
  (ps: ProjectState) => ps.projectId,
);

// Loading flag
export const isLoading = createSelector(
  [getProjectState],
  (ps: ProjectState) => ps.fetching,
);
