import UserDropdown from "#/components/UserDropdown";
import { canUserAssignTasks, canUserCreateNewProjects, canUserCreateNewUsers } from "#/permissions";
import { getActionsFromModule } from "#/store/helpers";
import esModule from "#/store/modules/eventSystem";
import { subscribeToEvent, unsubscribeFromEvent } from "#/store/modules/eventSystem/actions";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import uiModule, { toggleAudioNotification, toggleTextNotification } from "#/store/modules/ui";
import { CRMProject, DispatchProp, StoreRootState, User } from "#/store/types";
import { constructCRMProjectPath, constructUserPath } from "#/util";
import { push } from "connected-react-router";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import CreateProjectModal from "../CreateProjectModal";
import CreateTask from "../CreateTaskModal";
import CreateUserModal from "../CreateUserModal";
import ProjectDropdown from "../ProjectDropdown";
import "./index.scss";
import cn from "classnames";
import style from "./index.module.scss";

interface SidebarConnectedProps {
  logout: any;
  isActionsListShown: boolean;
  isDirListShown: boolean;
  textNotification: boolean;
  audioNotification: boolean;
  toggleTextNotification: DispatchProp<typeof toggleTextNotification>;
  toggleAudioNotification: DispatchProp<typeof toggleAudioNotification>;
  toggleActionsListShown: any;
  toggleDirectionsListShown: any;
  currentUser: User | null;
  push: typeof push;
  subscribeToEvent: DispatchProp<typeof subscribeToEvent>;
  unsubscribeFromEvent: DispatchProp<typeof unsubscribeFromEvent>;
}

interface SidebarOwnProps {
  isSidebarActive: boolean;
}

type SidebarProps = SidebarConnectedProps & SidebarOwnProps;

const Sidebar = (props: SidebarProps) => {
  const [createTaskModalShown, setCreateTaskModalShown] = useState(false);
  const [createProjectModalShown, setCreateProjectModalShown] = useState<boolean>(false);
  const [createUserModalShown, setCreateUserModalShown] = useState<boolean>(false);
  const [isProjectDropdownOpen, setProjectDropdownOpen] = useState<boolean>(false);
  const [isUserDropdownOpen, setUserDropdownOpen] = useState<boolean>(false);

  const openDirListIfNeeded = () => !props.isDirListShown && props.toggleDirectionsListShown();

  const onGotoProject = () => {
    if (isUserDropdownOpen) {
      setUserDropdownOpen(false);
    }
    openDirListIfNeeded();
    setProjectDropdownOpen(true);
  };

  const onGotoUser = () => {
    if (isProjectDropdownOpen) {
      setProjectDropdownOpen(false);
    }
    openDirListIfNeeded();
    setUserDropdownOpen(true);
  };

  useEffect(() => {
    props.subscribeToEvent("GOTO_PROJECT", onGotoProject);
    props.subscribeToEvent("GOTO_USER", onGotoUser);
    return () => {
      props.unsubscribeFromEvent("GOTO_PROJECT", onGotoProject);
      props.unsubscribeFromEvent("GOTO_USER", onGotoUser);
    };
  }, []);

  return (
    <div className={cn({ "main-sidebar": true, active: props.isSidebarActive })}>
      <section className="sidebar">
        <div className="user-panel">
          <div className="ulogo">
            <a href="/">
              <span>
                <b>SEO Rocket</b> CRM
              </span>
            </a>
          </div>

          <div className="image">
            <img
              src="https://img.freepik.com/free-vector/rocket-flat-design-concept-for-project-start-up-and-development-process_1561-39.jpg?size=338c&ext=jpg"
              className="rounded-circle"
              alt="User"
            />
          </div>
        </div>

        <ul className="sidebar-menu tree">
          {/* Main navigation */}
          <div className="flex-1">
            <li className="nav-divider" />
            <li className="header">Панель управления</li>
            <li>
              <Link to="/" className="treeview-link">
                <i className="fas fa-th" />
                <span>Мои задачи </span>
                <span className="pull-right-container">
                  <i className="fas fa-angle-right pull-right" />
                </span>
              </Link>
            </li>
            <li className={"treeview " + (props.isActionsListShown ? "active" : "hidden")}>
              <span className="treeview-link" onClick={props.toggleActionsListShown}>
                <i className="fas fa-plus" />
                <span>Действия</span>
                <span className="pull-right-container">
                  <i className="fas fa-angle-down pull-right" />
                </span>
              </span>
              <ul className={"treeview-menu " + (props.isActionsListShown ? "active" : "hidden")}>
                {/* Create new user */}
                {canUserCreateNewUsers(props.currentUser!) && (
                  <>
                    <li>
                      <span
                        className="treeview-link-item"
                        onClick={() => setCreateUserModalShown(true)}
                      >
                        Добавить пользователя
                      </span>
                    </li>
                    <CreateUserModal
                      isShown={createUserModalShown}
                      toggleModal={() =>
                        new Promise(resolve => {
                          setCreateUserModalShown(!createUserModalShown);
                          resolve();
                        })
                      }
                      redirectAfterCreated={true}
                    />
                  </>
                )}

                {/* Create new project */}
                {canUserCreateNewProjects(props.currentUser) && (
                  <>
                    <li>
                      <span
                        className="treeview-link-item"
                        onClick={() => setCreateProjectModalShown(true)}
                      >
                        Добавить проект
                      </span>
                    </li>
                    <CreateProjectModal
                      isShown={createProjectModalShown}
                      toggleModal={() =>
                        new Promise(resolve => {
                          setCreateProjectModalShown(!createProjectModalShown);
                          resolve();
                        })
                      }
                      redirectAfterCreation={true}
                    />
                  </>
                )}

                {/* Assign a new task */}
                {canUserAssignTasks(props.currentUser!) && (
                  <>
                    <li>
                      <span
                        className="treeview-link-item"
                        onClick={() => setCreateTaskModalShown(true)}
                      >
                        Добавить задачу
                      </span>
                    </li>
                    <CreateTask
                      isShown={createTaskModalShown}
                      toggleModal={() =>
                        new Promise(resolve => {
                          setCreateTaskModalShown(!createTaskModalShown);
                          resolve();
                        })
                      }
                    />
                  </>
                )}
              </ul>
            </li>

            <li className={"treeview " + (props.isDirListShown ? "active" : "hidden")}>
              <span className="treeview-link" onClick={props.toggleDirectionsListShown}>
                <i className="fas fa-directions" />
                <span>Перейти</span>
                <span className="pull-right-container">
                  <i className="fab fas fa-angle-down pull-right" />
                </span>
              </span>
              <ul
                className={
                  "treeview-menu overflow-visible " + (props.isDirListShown ? "active" : "hidden")
                }
              >
                <li className="list-select-item">
                  <ProjectDropdown
                    onClick={(project: CRMProject) => props.push(constructCRMProjectPath(project))}
                    toggleInnerContent={() => "Проект"}
                    direction="down"
                    dropdownToggleProps={{
                      className: "btn btn-transparent dropdown-toggle",
                    }}
                    isOpen={isProjectDropdownOpen}
                    toggle={() => setProjectDropdownOpen(!isProjectDropdownOpen)}
                  />
                </li>
                <li className="list-select-item">
                  <UserDropdown
                    onClick={(user: User) => props.push(constructUserPath(user))}
                    toggleInnerContent={() => "Пользователь"}
                    direction="down"
                    dropdownToggleProps={{
                      className: "btn btn-transparent dropdown-toggle",
                    }}
                    isOpen={isUserDropdownOpen}
                    toggle={() => setUserDropdownOpen(!isUserDropdownOpen)}
                  />
                </li>

                <li>
                  <Link to="/projects" className="treeview-link-item">
                    Список проектов
                  </Link>
                </li>
                <li>
                  <Link to="/profiles" className="treeview-link-item">
                    Список пользователей
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/services/" className="treeview-link">
                <i className="fas fa-chart-line" />
                <span>Сервисы </span>
                <span className="pull-right-container">
                  <i className="fas fa-angle-right pull-right" />
                </span>
              </Link>
            </li>
            <li className="nav-divider" />
          </div>

          {/* UI Settings */}
          <div className={style["settings-block"]}>
            <li className="header">Настройки</li>
            <li>
              <span className="treeview-link settings-item">
                Оповещения: текст
                <Switch
                  onChange={props.toggleTextNotification}
                  disabled={false}
                  className="rc-switch-offset-right"
                  checked={props.textNotification}
                />
              </span>
            </li>
            <li>
              <span className="treeview-link settings-item">
                Оповещения: звук
                <Switch
                  onChange={props.toggleAudioNotification}
                  disabled={false}
                  className="rc-switch-offset-right"
                  checked={props.audioNotification}
                />
              </span>
            </li>
          </div>
        </ul>
      </section>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
    isActionsListShown: uiModule.selectors.isActionsListShown(store, null),
    isDirListShown: uiModule.selectors.isDirListShown(store, null),
    textNotification: uiModule.selectors.areTextNotificationsEnabled(store, null),
    audioNotification: uiModule.selectors.areAudioNotificationsEnabled(store, null),
  };
};

const mapDispatchToProps = {
  push,
  ...getActionsFromModule(sessionModule, ["logout"]),
  ...getActionsFromModule(esModule, ["subscribeToEvent", "unsubscribeFromEvent"]),
  ...getActionsFromModule(uiModule, [
    "toggleActionsListShown",
    "toggleDirectionsListShown",
    "toggleAudioNotification",
    "toggleTextNotification",
  ]),
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
