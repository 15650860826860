import cn from "classnames";
import React from "react";
import { CSSTransition } from "react-transition-group";
import Loader from "../Loader";
import style from "./index.module.scss";

const ApplicationLoadingScreen = () => {
  return (
    <CSSTransition
      in={true}
      timeout={300}
      appear={true}
      classNames={{
        appearDone: cn([style.screen, style.screen_appear]),
        enterDone: cn([style.screen, style.screen_enter]),
        exit: cn([style.screen, style.screen_exit]),
      }}
    >
      <div className={style.screen}>
        <Loader />
      </div>
    </CSSTransition>
  );
};

export default ApplicationLoadingScreen;
