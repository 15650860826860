import { TaskCheckListItem } from "#/store/types";
import React, { useState } from "react";
import { Input, InputGroup } from "reactstrap";
import style from "../AddCheckListItem/index.module.scss";

interface AddChecklistListProps {
  items: string;
  onChange: (content: string) => void;
  onDiscard: () => void;
  onSave: () => void;
  addingMethod: string;
}

const AddChecklistList = React.forwardRef((props: AddChecklistListProps, ref: any) => {
  const computedStylings = props.addingMethod ? {} : { display: "none" };

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      e.stopPropagation();
      props.onSave();
    }
  };

  return (
    <div className={style["add-checklist-item"]} style={computedStylings}>
      <InputGroup>
        <textarea
          className="form-control"
          value={props.items}
          onChange={e => props.onChange(e.target.value)}
          placeholder="Введите содержание..."
          onKeyDown={onKeyDown}
          rows={4}
          ref={ref}
        />
        <button className="btn btn-list row-action" type="button" onClick={() => props.onSave()}>
          <i className="fas fa-check" />
        </button>
        <button type="button" className="btn btn-list row-action" onClick={props.onDiscard}>
          <i className="fas fa-trash-alt" />
        </button>
      </InputGroup>
    </div>
  );
});

export default AddChecklistList;
