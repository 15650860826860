import TableCheckbox from "#/components/TableCheckbox";
import { PrimaryKey, Top50QueryPage } from "#/store/types";
import React, { useContext } from "react";
import ProjectContext from "../../../ProjectContext";
import DebouncedInput from "#/components/DebouncedInput";
import _ from "lodash";
import QueryTableItem from "./QueryTableItem";
import { ClipLoader } from "react-spinners";

interface QueryTableOwnProps {
  selectedEventID: PrimaryKey | null;
}

const QueryTable = (props: QueryTableOwnProps) => {
  const { selectedEventID } = props;

  const projectContext = useContext(ProjectContext);

  const [eventQueries, setEventQueries] = React.useState<Top50QueryPage[]>([]);
  const [eventQueriesFiltered, setEventQueriesFiltered] = React.useState<Top50QueryPage[]>([]);
  const [massChangeUrlState, setMassChangeUrlState] = React.useState<{
    fetching: boolean;
    url: string;
  }>({ fetching: false, url: "" });
  const [toggledItems, setToggledItems] = React.useState<PrimaryKey[]>([]);

  React.useEffect(() => {
    const f = projectContext.projectQueries.filter(q => q.event_model?.pk === selectedEventID);
    setEventQueries(f);
    setEventQueriesFiltered(f);
  }, [props]);

  const filterQueries = React.useCallback(
    (text: string) => {
      setEventQueriesFiltered(
        eventQueries.filter(q => q.query_model?.text.toLowerCase().includes(text.toLowerCase())),
      );
    },
    [eventQueries],
  );

  // A flag to check whether all items are toggled or not
  const allItemsChecked =
    !!eventQueriesFiltered.length && toggledItems.length === eventQueriesFiltered.length;

  // A function to toggle all the items
  const toggleAll = () => {
    if (allItemsChecked) {
      setToggledItems([]);
    } else {
      setToggledItems(eventQueriesFiltered.map(f => f.pk));
    }
  };

  // A function to toggle only one item
  const toggleItem = (pk: PrimaryKey) => {
    if (toggledItems.includes(pk)) {
      setToggledItems(toggledItems.filter(f => f !== pk));
    } else {
      setToggledItems([...toggledItems, pk]);
    }
  };

  const renderedQueries = React.useMemo(
    () =>
      eventQueriesFiltered.map(queryPage => (
        <QueryTableItem
          key={queryPage.pk}
          queryPage={queryPage}
          checked={toggledItems.includes(queryPage.pk)}
          toggle={_.partial(toggleItem, queryPage.pk)}
          context={projectContext}
        />
      )),
    [eventQueriesFiltered, toggledItems],
  );

  const submitMassUrlChange = () => {
    if (massChangeUrlState.fetching || !massChangeUrlState.url || !toggledItems.length) { return; }
    setMassChangeUrlState({ ...massChangeUrlState, fetching: true });
    projectContext.massQueryPageUrlChange(toggledItems, massChangeUrlState.url);
    setToggledItems([]);
    setMassChangeUrlState({ fetching: false, url: "" });
  };

  if (!eventQueriesFiltered.length) {
    return null;
  }

  return (
    <>
      <table className="table topf-queries-table table-striped-custom">
        <thead>
          <tr>
            <th>
              <TableCheckbox onChange={toggleAll} checked={allItemsChecked} />
            </th>
            <th>Запрос</th>
            <th>W1</th>
            <th>W2</th>
            <th>W3</th>
            <th>Позиция</th>
            <th>Группа</th>
            <th>URL</th>
            <th/>
          </tr>
          <tr>
            <th/>
            <th
              colSpan={6}
              style={{
                textAlign: "left",
              }}
            >
              <DebouncedInput
                type="text"
                className="form-control form-control-small form-control-small-height fz-14"
                onChange={e => filterQueries(e)}
                placeholder="Фильтр по тексту"
                ms={500}
              />
            </th>

            <th>
              <input
                type="text"
                className="form-control form-control-small form-control-small-height fz-14"
                onChange={e =>
                  setMassChangeUrlState({ ...massChangeUrlState, url: e.target.value })
                }
                value={massChangeUrlState.url}
                placeholder="Массовая замена URL"
              />
            </th>
            <th>
              <button
                type="button"
                className="btn btn-list row-action ml-2"
                onClick={submitMassUrlChange}
                disabled={massChangeUrlState.fetching}
              >
                <>
                  <ClipLoader
                    css={{ name: "mr", styles: "vertical-align: middle;" }}
                    color="inherit"
                    size={13}
                    loading={massChangeUrlState.fetching}
                  />
                  {!massChangeUrlState.fetching && <i className="fas fa-check" />}
                </>
              </button>
            </th>
          </tr>
        </thead>
        <tbody>{renderedQueries}</tbody>
      </table>
    </>
  );
};

export default QueryTable;
