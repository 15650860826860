import { extractHostname } from "#/util";
import React from "react";

interface YandexFaviconOwnProps {
  name: string;
  alt: string;
  className?: string;
}

const YandexFavicon = (props: YandexFaviconOwnProps) => {
  return (
    <img
      alt={props.alt}
      src={`//favicon.yandex.net/favicon/${props.name}`}
      className={props.className ? props.className : ""}
    />
  );
};

export default YandexFavicon;
