import { showErrorToast, showSuccessToast } from "#/components/toasts";
import UserSelect from "#/components/UserSelect";
import { canUser } from "#/permissions";
import { CRMProjectData, PrimaryKey, User } from "#/store/types";
import {
  constructUserPath,
  formatDateTime,
  formatDateWithYear,
  isUserAdmin,
  isUserInProject,
  userFullName,
} from "#/util";
import _ from "lodash";
import { number } from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from "reactstrap";
import style from "../../index.module.scss";
import "./index.scss";

interface IListContext {
  currentUser: User | null;
  project: CRMProjectData;
  removeUser: (userId: PrimaryKey) => Promise<void>;
}

const ListContext = React.createContext<IListContext>({} as any);

const ProjectUsersListItem = (props: { user: User }) => {
  const user = props.user;
  const userRemovalButtonID = `project-user-remove-${user.pk}`;
  const context = React.useContext(ListContext);

  const [userRemovalConfirmationDialogOpened, setUserRemovalConfirmationDialogOpened] = useState(
    false,
  );
  const toggleUserRemovalConfirmationDialog = () =>
    setUserRemovalConfirmationDialogOpened(!userRemovalConfirmationDialogOpened);
  const showUserRemovalModal = () => setUserRemovalConfirmationDialogOpened(true);

  return (
    <div className="flexbox align-items-center mt-1">
      <Link className="text-gray no-front-margins" to={constructUserPath(user)}>
        {/* User avatar */}
        <img
          className="user-image-s rounded-circle"
          src="https://avatars.servers.getgo.com/2205256774854474505_medium.jpg"
          alt="user avatar"
        />
        {/* User name */}
        <span className="ml-2">{userFullName(user)}</span>
      </Link>

      {/* Remove user */}
      {canUser(context.currentUser, "REMOVE_MEMBER", "PROJECTCRM", {
        obj: context.project.data!,
        projectMember: user,
      }) && (
        <>
          <button
            id={userRemovalButtonID}
            className="btn btn-list row-action"
            title={`Исключить ${userFullName(user)} из проекта`}
            onClick={() => {
              showUserRemovalModal();
            }}
          >
            <i className="fas fa-times" />
          </button>

          <UncontrolledPopover
            placement="top"
            isOpen={userRemovalConfirmationDialogOpened}
            target={userRemovalButtonID}
            trigger="click"
          >
            <PopoverHeader>Подтверждение</PopoverHeader>
            <PopoverBody>
              <div>
                Вы действительно хотите убрать пользователя {`"${userFullName(user)}"`} с этого
                проекта?
              </div>
            </PopoverBody>
            <PopoverBody className="popover-border-top">
              <div className="text-right">
                <button
                  type="button"
                  className="btn btn-list row-action"
                  onClick={toggleUserRemovalConfirmationDialog}
                >
                  Отмена
                </button>
                <button
                  type="button"
                  className="btn btn-list row-action"
                  onClick={() => {
                    context
                      .removeUser(user.pk)
                      .then(() => {
                        showSuccessToast(
                          `Пользователь ${userFullName(user)} был успешно исключен из проекта.`,
                        );
                      })
                      .catch(() => {
                        showErrorToast(`Во время удаления пользователя произошла ошибка.`);
                      });
                  }}
                >
                  Удалить
                </button>
              </div>
            </PopoverBody>
          </UncontrolledPopover>
        </>
      )}
    </div>
  );
};

const ProjectUsersList = (props: {
  users: User[];
  projectUsers: User[];
  project: CRMProjectData;
  currentUser: User;
  onUserAdd: (pk: PrimaryKey) => Promise<unknown>;
  onUserRemove: (pk: PrimaryKey) => Promise<unknown>;
}) => {
  const [addUserModalOpened, setAddUserModalOpened] = useState(false);
  const [userToInvite, setUserToInvite] = useState<PrimaryKey | null>(null);

  const showAddUserModal = () => setAddUserModalOpened(true);
  const toggleAddUserModal = () => setAddUserModalOpened(!addUserModalOpened);
  const onUserToProjectAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (userToInvite) {
      props
        .onUserAdd(userToInvite)
        .then(() => {
          showSuccessToast("Пользователь был успешно добавлен в проект");
          setAddUserModalOpened(false);
        })
        .catch(() => {
          showErrorToast("Во время добавления пользователя произошла ошибка");
        });
    }
  };

  return (
    <div className={style["project-users"]}>
      <div className="text-gray small mt-3">Пользователи</div>
      <div className={style["project-users-listing"]}>
        {props.projectUsers.length > 0 ? (
          props.projectUsers.map(user => <ProjectUsersListItem key={`pu-${user.pk}`} user={user} />)
        ) : (
          <div className={style["no-users-in-proj-message"]}>
            <small>Пользователей нет</small>
          </div>
        )}
      </div>

      {/* User project invitation functionality*/}
      {isUserAdmin(props.currentUser) && (
        <>
          <div className={style["users-bottom-row"]}>
            <Button onClick={showAddUserModal} title="Добавить пользователя в проект">
              <i className="fas fa-plus-circle" />
              {` `}
              Добавить пользователя
            </Button>
          </div>
          <Modal
            isOpen={addUserModalOpened}
            toggle={showAddUserModal}
            className="modal-dialog-centered"
            size="lg"
            contentClassName="alert alert-primary"
          >
            <ModalHeader toggle={toggleAddUserModal}>Добавить пользователя</ModalHeader>
            <ModalBody>
              <label>Пользователь</label>
              <UserSelect
                value={userToInvite}
                required={true}
                onChange={(upk: PrimaryKey | null) => setUserToInvite(upk)}
                users={props.users.filter(u => !isUserInProject(props.project, u))}
              />
            </ModalBody>
            <ModalFooter>
              <Button onClick={onUserToProjectAdd} color="primary">
                Добавить
              </Button>
            </ModalFooter>
          </Modal>
        </>
      )}
    </div>
  );
};

interface ProjectHeadConnectedProps {}

interface ProjectHeadOwnProps {
  project: CRMProjectData | null;
  creator: User | null;
  onSave: any;
  fetching: boolean;
  projectUsers: User[];
  currentUser: User;
  users: User[];
  onUserToProjectAdd: (uid: PrimaryKey) => Promise<unknown>;
  onUserRemove: (uid: PrimaryKey) => Promise<void>;
}

type ProjectHeadProps = ProjectHeadConnectedProps & ProjectHeadOwnProps;

const ProjectHead = (props: ProjectHeadProps) => {
  const [editMode, setEditMode] = useState(false);
  const [editedProject, setEditedProject] = useState(
    props.project!.data ? props.project!.data : { name: "", description: "", date_seo: "", url: "", url_admin: "", ya_m_access: "", google_a_access: "", google_s_access: "", phone_access: "", rk_access: "" },
  );
  const toggleEditMode = () => setEditMode(!editMode);

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedProject({
      ...editedProject,
      name: e.target.value,
    });

  const onDateSeoChange = (e: React.ChangeEvent<HTMLInputElement>) =>
    setEditedProject({
      ...editedProject,
      date_seo: e.target.value,
    });

  const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      description: e.target.value,
    });

  const onUrlChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      url: e.target.value,
    });
  
  const onUrl_adminChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      url_admin: e.target.value,
    });

  const onYa_m_accessChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      ya_m_access: e.target.value,
    });

  const onGoogle_a_accessChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      google_a_access: e.target.value,
    });

  const onPhone_accessChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      phone_access: e.target.value,
    });

  const onRk_accessChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      rk_access: e.target.value,
    });

  const onGoogle_s_accessChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
    setEditedProject({
      ...editedProject,
      google_s_access: e.target.value,
    });

  const onEnterPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.keyCode === 13) {
      toggleEditMode();
    }
  };

  const onTextAreaEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      toggleEditMode();
    }
  };

  useEffect(() => {
    // If exited edit mode
    if (!editMode && !_.isEqual(editedProject, props.project)) {
      props.onSave(editedProject);
    }
  }, [editMode]);

  if (!props.project) {
    return null;
  }

  return (
    <ListContext.Provider
      value={{
        currentUser: props.currentUser,
        removeUser: props.onUserRemove,
        project: props.project,
      }}
    >
      <div className="box">
        <div className="row no-gutters">
          <img
            className="col-4 proj-img d-none d-md-block"
            alt="Project Card"
            src="https://previews.123rf.com/images/redeer/redeer1702/redeer170200089/72886756-crm-concept-design-with-vector-elements-flat-icons-of-accounting-system-clients-support-deal-organiz.jpg"
          />
          <div className="col-md-8">
            <div className="box-body">
              <div className="d-flex justify-content-between">
                <div className="project-title">
                  {!editMode ? (
                    <h4>Проект {props.project.data!.name}</h4>
                  ) : (
                    <input
                      type="text"
                      className="form-control"
                      value={editedProject.name}
                      onChange={onTitleChange}
                      onKeyDown={onEnterPress}
                    />
                  )}
                </div>

                <div>
                  {canUser(props.currentUser, "EDIT", "PROJECTCRM", {
                    obj: props.project.data!,
                    projectFullData: props.project,
                  }) && (
                    <button
                      className="btn btn-outline-primary"
                      onClick={toggleEditMode}
                      disabled={props.fetching}
                    >
                      {!editMode ? "Редактировать" : "Сохранить изменения"}
                    </button>
                  )}
                </div>
              </div>
              <hr className="ml-0 bt-2 mt-2" />
              <label>Описание:</label>
              {!editMode ? (
                <p>{props.project.data!.description}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onDescriptionChange}
                  value={editedProject.description}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Адрес сайта: </label>
               {!editMode ? (
                <p>{props.project.data!.url}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onUrlChange}
                  value={editedProject.url}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Дата создания: </label>
              {!editMode ? (
                  <p>{formatDateWithYear(new Date(props.project.data!.date_seo))}</p>
                ) : (
                  <input
                    type="date"
                    className="form-control"
                    value={editedProject.date_seo}
                    onChange={onDateSeoChange}
                    onKeyDown={onEnterPress}
                  />
                )}  
              <label>Доступ к сайту (админка): </label>           
              {!editMode ? (
                <p>{props.project.data!.url_admin}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onUrl_adminChange}
                  value={editedProject.url_admin}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Доступ Яндекс метрика: </label>
              {!editMode ? (
                <p>{props.project.data!.ya_m_access}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onYa_m_accessChange}
                  value={editedProject.ya_m_access}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Доступ Google Analytics: </label>
              {!editMode ? (
                <p>{props.project.data!.google_a_access}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onGoogle_a_accessChange}
                  value={editedProject.google_a_access}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Доступ Google search console: </label>
              {!editMode ? (
                <p>{props.project.data!.google_s_access}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onGoogle_s_accessChange}
                  value={editedProject.google_s_access}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Доступ к телефонии: </label>
              {!editMode ? (
                <p>{props.project.data!.phone_access}</p>
              ) : (
                <textarea
                  className="form-control"
                  onChange={onPhone_accessChange}
                  value={editedProject.phone_access}
                  onKeyDown={onTextAreaEnterPress}
                  rows={3}
                />
              )}
              <label>Доступ к Рекламному кабинету:</label>
              {!editMode ? (
                <p>{props.project.data!.rk_access}</p>
              ) : (              
                <textarea
                  className="form-control"
                  onChange={onRk_accessChange}
                  value={editedProject.rk_access}
                  onKeyDown={onTextAreaEnterPress}
                  
                  placeholder="Доступ к Рекламному кабинету"
                  rows={3}
                />
              )}

              {/* Active tasks count */}
              <div className="text-gray small">
                Активных задач: {props.project.data!.active_tasks}
              </div>

              {/* Project creation date */}
              <div className="text-gray small">
                Дата создания: {formatDateTime(props.project.data!.create_date)}
              </div>

              {/* Project creator */}
              <div className="text-gray small mt-3">Ответственный</div>
              <div className="flexbox align-items-center mt-1">
                <Link className="text-gray no-front-margins" to={constructUserPath(props.creator)}>
                  <img
                    className="user-image-s rounded-circle"
                    src="https://avatars.servers.getgo.com/2205256774854474505_medium.jpg"
                    alt="why are you"
                  />
                  <span className="ml-2">{userFullName(props.creator)}</span>
                </Link>
              </div>

              {/* Project users list */}
              <ProjectUsersList
                users={props.users}
                onUserAdd={props.onUserToProjectAdd}
                onUserRemove={props.onUserRemove}
                project={props.project}
                currentUser={props.currentUser}
                projectUsers={props.projectUsers}
              />
            </div>
          </div>
        </div>
      </div>
    </ListContext.Provider>
  );
};

export default ProjectHead;
