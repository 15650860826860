import { apiGet, apiPut } from "#/api";
import { API_ROUTES } from "#/conf/api";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import { ContentPlanProject } from "#/store/types";
import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import TechItem from "../TechItem";

// TODO: Make this component to use the Redux store
// TODO: Use react hooks in this component

interface TechProps {}

interface TechState {
  projects: ContentPlanProject[];
  projectsCopy: ContentPlanProject[];
  loading: boolean;
}

class Tech extends React.Component<TechProps, TechState> {
  public state: TechState = {
    projects: [],
    projectsCopy: [],
    loading: false,
  };

  public componentDidMount() {
    this.setState({ loading: true }, () => {
      apiGet(API_ROUTES.CONTENTPLAN.PROJECTS)
        .then(projects => {
          this.setState({
            ...this.state,
            projects,
            projectsCopy: projects,
            loading: false,
          });
        })
        .catch((err: Error) => this.setState({ ...this.state, loading: false }));
    });
  }

  public onEditProject = (pk: number, payload: Partial<ContentPlanProject>) =>
    apiPut(API_ROUTES.CONTENTPLAN.PROJECTS_ITEM(pk), payload);

  public onFilterChange = (value: string) => {
    const { projectsCopy } = this.state;

    const filtered = projectsCopy.filter(str =>
      str.name.toLowerCase().includes(value.toLowerCase()),
    );

    this.setState({ projects: filtered });
  };

  public render() {
    const { loading } = this.state;

    const projectsToRender = this.state.projects
      .filter(e => e.active)
      .map((p, i) => <TechItem project={p} key={i} editProject={this.onEditProject} />);

    return (
      <div>
        <Header />

        <section className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                  <h4 className="box-title">
                    <Link to="/services/contentplan/" className="btn-list">
                      <i className="fas fa-long-arrow-alt-left" />
                    </Link>
                    <span className="ml-2">Маршруты URL для проектов</span>
                  </h4>
                </div>
                <div className="box-body">
                  {loading && (
                    <div className="flex-vertically-centered py-4 text-center">
                      <img src={loader} className="m-auto" alt="Loading Spinner" />
                    </div>
                  )}
                  {!loading && (
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="minimum-column" />
                          <th>Название проекта</th>
                          <th>API Ссылка</th>
                        </tr>
                      </thead>
                      <tbody>{projectsToRender}</tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Tech;
