import Page from "#/components/Page";
import Login from "#/scenes/Auth/components/LoginPage";
import RegisterViaInvitationLink from "#/scenes/Auth/components/RegisterViaInvitation";
import RestorePassword from "#/scenes/Auth/components/RestorePassword";
import * as sessionModule from "#/store/modules/session";
import { StoreRootState } from "#/store/types";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from "react-router";
import NotFoundPage from "../Main/components/CRM/components/NotFoundPage";

interface AuthSceneOProps {
  children: React.ReactNode;
}

interface AuthSceneCProps extends RouteComponentProps<{}> {
  authenticated: boolean;
}

type AuthSceneProps = AuthSceneCProps & AuthSceneOProps;

const AuthScene = (props: AuthSceneProps) => {
  const { authenticated, children, history, match } = props;

  // Authenticated already, redirect to the app index page
  if (authenticated) {
    console.info("authenticated already -> redirecting to '/'");
    return <Redirect to="/" />;
  }

  return (
    <div className="login">
      <div className="login-root">
        <div className="login-root-inner">
          <div className="login-root-form">
            <div className="login-root-form-inner">
              <Switch>
                <Route
                  path={`${match.url}/login`}
                  exact={true}
                  render={(renderProps: any) => (
                    <Page title="Войти">
                      <Login {...renderProps} />
                    </Page>
                  )}
                />

                <Route
                  path={`${match.url}/register-via-invite/:invitationLink?`}
                  exact={true}
                  render={(renderProps: any) => (
                    <Page title="Зарегистрироваться с помощью приглашения">
                      <RegisterViaInvitationLink {...renderProps} />
                    </Page>
                  )}
                />

                <Route
                  path={`${match.url}/reset-password/`}
                  exact={true}
                  render={(renderProps: any) => (
                    <Page title="Восстановить пароль">
                      <RestorePassword {...renderProps} />
                    </Page>
                  )}
                />

                <Route
                  path="*"
                  render={(renderProps: any) => {
                    return <NotFoundPage {...renderProps} />;
                  }}
                />
              </Switch>
            </div>
          </div>
          <div className="login-root-image">
            <div className="login-root-image-inner">
              <h3 className="login-image-welcome">Наша Rocket CRM!</h3>
              <p className="login-image-msg">
                Система управления взаимоотношениями с клиентами - прикладное программное
                обеспечение <br />
                для организаций, предназначенное для автоматизации стратегий взаимодействия с
                заказчиками <br />
                (клиентами), в частности для повышения уровня продаж, оптимизации.
              </p>
              <p className="login-image-msg">
                Мы разработали гибкую, автономную, удобную для каждого пользователя систему
                управления.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  authenticated: sessionModule.selectors.isAuthenticated(store, null),
});

export default withRouter(
  connect(
    mapStateToProps,
    {},
  )(AuthScene),
);
