import { UserTaskDeadlineComingNotification } from "#/store/types";
import { constructURLToTask } from "#/util";
import React from "react";
import style from "./index.module.scss";
import ItemWrapper from "./ItemWrapper";
import { DerivedNotificationItemProps } from "./types";

const TaskDeadlineComingNotification = React.forwardRef(
  (
    props: DerivedNotificationItemProps<UserTaskDeadlineComingNotification>,
    ref: React.Ref<any>,
  ) => (
    <ItemWrapper
      ref={ref}
      onClick={() => {
        props.push(constructURLToTask(props.notification.task_model));
      }}
      notification={props.baseNotification}
    >
      <span className={style["notification-menu-item__text"]}>
        Срок выполнения задачи {`"${props.notification.task_model.name}`} приближается
      </span>
    </ItemWrapper>
  ),
);

export default TaskDeadlineComingNotification;
