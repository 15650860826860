import { getActionsFromModule } from "#/store/helpers";
import searchModule from "#/store/modules/search";
import { RetreiveTagSuggestions } from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import { useDebounced } from "#/util/hooks";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Popover } from "reactstrap";
import TagsList from ".";
import style from "./index.module.scss";

const compileTagsText = (tagsText: string) => {
  const newTags = tagsText.split(" ").map(tag => tag.substr(1));
  return newTags.filter(t => t.length > 0);
};

const SuggestionsPopup = (props: {
  useSuggestion: (s: string) => void;
  suggestions: string[];
  clearSuggestions: () => void;
  isOpen: boolean;
  setIsOpen: (a: boolean) => void;
  target: string;
}) => {
  const renderedSuggestions = useMemo(() => {
    return props.suggestions.map(s => {
      return (
        <div
          key={s}
          className={style["suggestion-item"]}
          onClick={() => {
            props.useSuggestion(s);
            props.setIsOpen(false);
            props.clearSuggestions();
          }}
        >
          {s}
        </div>
      );
    });
  }, [props.suggestions]);
  return (
    <Popover
      placement="auto"
      hideArrow={true}
      target={props.target}
      isOpen={props.isOpen}
      toggle={() => props.setIsOpen(!props.isOpen)}
      className={style["suggestions-list"]}
    >
      {renderedSuggestions}
    </Popover>
  );
};

const TagsListEditable = (props: {
  setTags: (tags: string[]) => void;
  tags: string[];
  noTagsMessage: string;
  tagsClickable: boolean;
  constructTagLink: (tag: string) => string;
  editMode: boolean;
  retreiveTagSuggesstions: RetreiveTagSuggestions;
}) => {
  const [tagsText, setTagsText] = useState(props.tags.map(tag => `#${tag}`).join(" "));
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const onTagsTextChange = (newTagsText: string) => {
    setTagsText(newTagsText);
  };
  const [suggestionsPopoverOpen, setSuggestionsPopoverOpen] = useState<boolean>(false);
  const [isEditorFocused, setEditorFocused] = useState<boolean>(false);

  // Is it possible to autocomplete at the moment
  const canAutocomplete = useMemo(() => {
    if (tagsText.length === 0) { return false; }
    const editorElem = textareaRef.current;
    if (!editorElem) { return false; }
    // If textarea is not focused right now
    // eslint-disable-next-line react/no-find-dom-node
    if (document.activeElement !== ReactDOM.findDOMNode(editorElem)) { return false; }
    const currPos = editorElem.selectionEnd;
    const lastChar = tagsText[currPos - 1];
    return lastChar !== " ";
  }, [tagsText]);

  // Suggestion source
  const suggestionSource: string | null = useMemo(() => {
    if (canAutocomplete) {
      const editorElem = textareaRef.current;
      if (!editorElem) { return null; }
      const currPos = editorElem.selectionEnd;
      const prevText = tagsText.substr(0, currPos);
      const text = tagsText.substr(prevText.lastIndexOf("#") + 1, currPos);
      return text;
    } else {
      return null;
    }
  }, [canAutocomplete, textareaRef, tagsText, isEditorFocused]);

  // Suggestion source is used in the debounced form in order to reduce load
  const suggestionSourceDebounced: string | null = useDebounced(suggestionSource, 1000);

  // Clear all suggestions
  const clearSuggestions = useCallback(() => setSuggestions([]), [setSuggestions]);

  // Clear suggestions when can no longer autocomplete
  useEffect(() => {
    if (!canAutocomplete) {
      clearSuggestions();
    }
  }, [canAutocomplete]);

  // Request suggestions if it is possible
  useEffect(() => {
    if (suggestionSourceDebounced !== null) {
      props
        .retreiveTagSuggesstions(suggestionSourceDebounced)
        .then((proposedSuggestions: string[]) => {
          setSuggestions(proposedSuggestions);
        })
        .catch(handleDispatchErrorAndDisplayToast);
    }
  }, [suggestionSourceDebounced]);

  // When user leaves the textarea
  const onBlur = useCallback(() => {
    setEditorFocused(false);
    // Compile textarea contents and notify higher component
    props.setTags(compileTagsText(tagsText));
    // Clear suggestions
    _.delay(clearSuggestions, 1000);
  }, [tagsText, props.setTags, clearSuggestions, setEditorFocused]);

  const onFocus = useCallback(() => {
    setEditorFocused(true);
  }, [setEditorFocused]);

  // Use suggestion and change current text accordingly
  const useSuggestion = useCallback(
    (s: string) => {
      const editorElem = textareaRef.current;
      if (!editorElem) { return false; }
      const currPos = editorElem.selectionEnd;
      const prevText = tagsText.substr(0, currPos);
      const currTagStartingPosition = prevText.lastIndexOf("#") + 1;
      const unchangedPart = tagsText.substr(0, currTagStartingPosition);
      const newPart = `${s} `;
      const nextPart = tagsText.substr(currPos + 1);
      const newTagsText = `${unchangedPart}${newPart}${nextPart}`;
      setTagsText(newTagsText);
      props.setTags(compileTagsText(newTagsText));
    },
    [textareaRef, tagsText, setTagsText],
  );

  // Close/open suggestion popover depending on whether there are suggestions or not
  useEffect(() => {
    if (suggestions.length !== 0) {
      setSuggestionsPopoverOpen(true);
    } else {
      setSuggestionsPopoverOpen(false);
    }
  }, [suggestions]);

  if (!props.editMode) {
    return (
      <TagsList
        tags={props.tags}
        noTagsMessage={props.noTagsMessage}
        tagsClickable={props.tagsClickable}
        constructTagLink={props.constructTagLink}
      />
    );
  } else {
    return (
      <div className={style["task-tags-list-editor"]}>
        <>
          <textarea
            className="form-control"
            value={tagsText}
            onChange={e => onTagsTextChange(e.target.value)}
            onBlur={onBlur}
            ref={textareaRef}
            onFocus={onFocus}
            id="tags-list-editor-suggestions-target"
          />
          {textareaRef.current && (
            <SuggestionsPopup
              useSuggestion={useSuggestion}
              suggestions={suggestions}
              isOpen={suggestionsPopoverOpen}
              setIsOpen={setSuggestionsPopoverOpen}
              clearSuggestions={clearSuggestions}
              target="tags-list-editor-suggestions-target"
            />
          )}
        </>
      </div>
    );
  }
};

export default connect(
  () => ({}),
  { ...getActionsFromModule(searchModule, ["retreiveTagSuggesstions"]) },
)(TagsListEditable);
