import ProjectDropdown from "#/components/ProjectDropdown";
import projectsModule from "#/store/modules/projects";
import {
  CRMProject,
  EditUserProjects,
  PrimaryKey,
  ProjectsById,
  StoreRootState,
  User,
} from "#/store/types";
import {
  constructCRMProjectPath,
  handleDispatchErrorAndDisplayToast,
  projectName,
  userFullName,
} from "#/util";
import cn from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button, ListGroup, ListGroupItem } from "reactstrap";
import Loader from "../../../../../../../../components/Loader";
import style from "./index.module.scss";

const UserProjectsWidget = (props: {
  user: User | null;
  projects: CRMProject[];
  editUserProjects: EditUserProjects;
  projectsById: ProjectsById;
  editAllowed?: boolean;
}) => {
  // Edit is only allowed if said so
  const editAllowed = props.editAllowed !== undefined ? props.editAllowed : false;
  // State
  const [editingProjects, setEditingProjects] = useState(false);
  const [, setProjectsEditSelectOpen] = useState<boolean>(false);
  const [editedProjects, setEditedProjects] = useState<PrimaryKey[]>(props.projects.map(a => a.pk));
  const [savingInProgress, setSavingInProgress] = useState(false);
  const saveProjectsEdit = () => {
    if (!_.isEqual(editedProjects, props.projects.map(a => a.pk))) {
      setSavingInProgress(true);
      props
        .editUserProjects(props.user!.pk, editedProjects)
        .then(() => {
          setEditingProjects(false);
          setSavingInProgress(false);
        })
        .catch(err => {
          handleDispatchErrorAndDisplayToast(err);
          setSavingInProgress(false);
        });
    } else {
      setEditingProjects(false);
    }
  };
  const [isProjectDropdownOpen, setProjectDropdownOpen] = useState<boolean>(false);

  const resetEditedProjectsToProps = () => setEditedProjects(props.projects.map(a => a.pk));

  const cancelEdit = () => {
    resetEditedProjectsToProps();
    setEditingProjects(false);
  };

  // Hooks
  useEffect(() => {
    resetEditedProjectsToProps();
    setEditingProjects(false);
  }, [props.projects]);

  return (
    <div className={style["user-projects-widget"]}>
      <div className={style["user-projects-widget__header"]}>
        {/* Title */}
        <div className={style["user-projects-title"]}>Проекты</div>
        {`(`}
        <div
          className={style["user-projects-count"]}
          title={`Пользователь ${userFullName(props.user)} состоит в ${
            props.projects.length
          } проектах`}
        >
          {props.projects.length}
        </div>
        {`)`}

        <div className="flex-spacer" />

        {/* Action row */}
        <div className={style["user-projects-actions"]}>
          {editingProjects ? (
            <>
              <button
                className={cn([style["user-projects-action-button"]])}
                onClick={() => saveProjectsEdit()}
                title="Сохранить изменения"
              >
                {" "}
                <i className="fas fa-check" />
              </button>
              <button
                className={style["user-projects-action-button"]}
                onClick={() => cancelEdit()}
                title="Отменить изменения"
              >
                {" "}
                <i className="fas fa-times" />
              </button>
            </>
          ) : (
            editAllowed && (
              <button
                className={cn([style["user-projects-action-button"]])}
                onClick={() => setEditingProjects(!editingProjects)}
                title="Редактировать"
              >
                <i className="fas fa-edit" />
              </button>
            )
          )}
        </div>
      </div>

      {!editingProjects ? (
        <ul className={style["user-projects-minimized-list"]}>
          {props.projects.map(p => {
            return (
              <li className={style["user-projects-minimized-list__item"]} key={p.pk}>
                <Link to={constructCRMProjectPath(p)}>{projectName(p)}</Link>
              </li>
            );
          })}
        </ul>
      ) : (
        <div className={style.multiselect} onClick={() => setProjectsEditSelectOpen(true)}>
          <div className={style["multiselect-items-container"]}>
            {savingInProgress ? (
              <Loader />
            ) : editedProjects.length > 0 ? (
              <ListGroup flush={true}>
                {editedProjects.map((pk: PrimaryKey) => {
                  const proj = _.get(_.get(props.projectsById, pk, {}), "data", null);
                  const projName = projectName(proj);
                  return (
                    <ListGroupItem key={pk} className={style["multiselect-item"]}>
                      <div className={style["multiselect-item__name"]}>
                        <Link to={constructCRMProjectPath(proj)} target="blank_">
                          {projName}
                        </Link>
                      </div>
                      <button
                        className={cn([style["multiselect-item__remove-button"]])}
                        onClick={() => {
                          setEditedProjects(editedProjects.filter(id => id !== pk));
                        }}
                        title={`Исключить пользователя из проекта "${projName}"`}
                      >
                        <i className="fas fa-minus" />
                      </button>
                    </ListGroupItem>
                  );
                })}
              </ListGroup>
            ) : (
              <div className={style["multiselect__no-items-message"]}>Нет проектов</div>
            )}
          </div>
          <div className={style["dropdown-container"]}>
            <ProjectDropdown
              direction="right"
              isOpen={isProjectDropdownOpen}
              toggle={() => setProjectDropdownOpen(!isProjectDropdownOpen)}
              filterFunction={(proj: CRMProject) => {
                return !_.includes(editedProjects, proj.pk);
              }}
              onClick={(p: CRMProject) => setEditedProjects([...editedProjects, p.pk])}
              dropdownToggleClassname={style["multiselect__show-item-select-button"]}
              dropdownToggleProps={{
                color: "primary",
                outline: false,
                size: "sm",
              }}
              toggleInnerContent={() => (
                <>
                  <span className={style["multiselect__show-item-select-button__text"]}>
                    Добавить новый проект
                  </span>
                  <i className="fas fa-plus" />
                </>
              )}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default connect((store: StoreRootState) => ({
  projectsById: projectsModule.selectors.getProjectsById(store, null),
}))(UserProjectsWidget);
