import React, { useState, useReducer, ChangeEvent, useEffect, useCallback } from "react";
import {
  Goal,
  MetrikaGoalTypes,
  NonStepGoal,
  UrlGoal,
  ActionGoal,
  StepGoal,
  Condition,
  MetrikaGoalConditionTypes,
  GoalWithoutId,
  ActionGoalWithoutId,
  UrlGoalWithoutId,
  StepGoalWithoutId,
} from "#/store/modules/metrika/goals/types";
import { postGoal, goalTypeToString } from "#/store/modules/metrika/goals";
import "./index.scss";
import { createReducer } from "#/store/helpers";
import { Action } from "redux";
import { setupSitemonitoringWebSocket } from "#/store/modules/sitemonitoring/actions";
import _ from "lodash";
import ConditionForm from "./components/ConditionForm";
import { State } from "react-select/src/Select";
import { connect } from "react-redux";

interface GoalFormOwnProps {
  canBeStepGoal: boolean;
  deletionCallback?: any;
  callback?: any;
  goal?: Goal;
}

interface GoalFormConnectedProps {}

type GoalFormProps = GoalFormOwnProps & GoalFormConnectedProps;

enum MutationTypes {
  CHANGE_TYPE_TO_ACTION = "CHANGE_TYPE_TO_ACTION",
  CHANGE_TYPE_TO_URL = "CHANGE_TYPE_TO_URL",
  CHANGE_TYPE_TO_STEP = "CHANGE_TYPE_TO_STEP",
  CHANGE_NAME = "CHANGE_NAME",
  CHANGE_STEP = "CHANGE_STEP",
  ADD_STEP = "ADD_STEP",
  DELETE_STEP = "REMOVE_STEP",
  CHANGE_CONDITION = "CHANGE_CONDITION",
  ADD_CONDITION = "ADD_CONDITION",
  DELETE_CONDITION = "DELETE_CONDITION",
}

const actionGoal: GoalWithoutId = {
  name: "",
  type: MetrikaGoalTypes.action,
  conditions: [],
};

const urlGoal: GoalWithoutId = {
  name: "",
  type: MetrikaGoalTypes.url,
  conditions: [],
};

const stepGoal: GoalWithoutId = {
  name: "",
  type: MetrikaGoalTypes.step,
  steps: [],
};

const genericCondition: Condition = {
  type: MetrikaGoalConditionTypes.action,
  url: "",
};

declare namespace ActionTypes {
  export interface ChangeName extends Action {
    payload: {
      name: string;
    };
  }

  export interface ChangeStep extends Action {
    payload: {
      index: number;
      goal: ActionGoalWithoutId | UrlGoalWithoutId;
    };
  }

  export interface ChangeCondition extends Action {
    payload: {
      index: number;
      condition: Condition;
    };
  }
  export interface DeleteStep extends Action {
    payload: {
      index: number;
    };
  }
}

const reducer = createReducer<GoalWithoutId, Action>(
  {
    [MutationTypes.CHANGE_TYPE_TO_ACTION]: (state, action) => ({
      ...actionGoal,
      conditions: [],
      name: state.name,
    }),
    [MutationTypes.CHANGE_TYPE_TO_URL]: (state: GoalWithoutId) => ({
      ...urlGoal,
      name: state.name,
      conditions: [],
    }),
    [MutationTypes.CHANGE_TYPE_TO_STEP]: (state: GoalWithoutId) => ({
      ...stepGoal,
      name: state.name,
    }),
    [MutationTypes.CHANGE_NAME]: (state: GoalWithoutId, action: ActionTypes.ChangeName) => ({
      ...state,
      name: action.payload.name,
    }),
    [MutationTypes.CHANGE_STEP]: (state: GoalWithoutId, action: ActionTypes.ChangeStep) => {
      const newState = { ...state };
      console.log("Changing step", action);
      if ("steps" in newState) {
        newState.steps[action.payload.index] = action.payload.goal;
      }
      return newState;
    },
    [MutationTypes.ADD_STEP]: (state: GoalWithoutId) => {
      const newState = { ...state };
      if ("steps" in newState) {
        newState.steps = [...newState.steps, { ...actionGoal }];
      }
      return newState;
    },
    [MutationTypes.CHANGE_CONDITION]: (
      state: GoalWithoutId,
      action: ActionTypes.ChangeCondition,
    ) => {
      const newState = { ...state };

      if ("conditions" in newState) {
        newState.conditions[action.payload.index] = action.payload.condition;
      }
      console.log("State", newState, "Action", action);
      return newState;
    },
    [MutationTypes.ADD_CONDITION]: (state: GoalWithoutId) => {
      const newState = { ...state };
      if ("conditions" in newState) {
        newState.conditions = [...newState.conditions, { ...genericCondition }];
      }
      return newState;
    },
    [MutationTypes.DELETE_STEP]: (state: GoalWithoutId, action: ActionTypes.DeleteStep) => {
      const newState = { ...state };

      if ("steps" in newState) {
        newState.steps = [
          ...newState.steps.slice(0, action.payload.index),
          ...newState.steps.slice(action.payload.index + 1),
        ];
      }
      return newState;
    },
    [MutationTypes.DELETE_CONDITION]: (state: GoalWithoutId, action: ActionTypes.DeleteStep) => {
      const newState = { ...state };

      if ("conditions" in newState) {
        newState.conditions = [
          ...newState.conditions.slice(0, action.payload.index),
          ...newState.conditions.slice(action.payload.index + 1),
        ];
      }
      return newState;
    },
  },
  actionGoal,
);

const GoalForm = (props: GoalFormProps) => {
  // const [goal, dispatch] = props.goal
  //   ? useReducer(reducer, props.goal)
  //   : useReducer(reducer, actionGoal);
  const [goal, dispatch] = useReducer(reducer, actionGoal);
  useEffect(() => {
    console.count("Goal has changed");
    if (props.callback) {
      props.callback(goal);
    }
  }, [goal]);
  const changeStep = useCallback((_goal: Goal, index: number) => {
    dispatch({
      type: MutationTypes.CHANGE_STEP,
      payload: {
        index,
        goal: _goal,
      },
    });
  }, []);
  const deleteStep = useCallback((index: number) => {
    dispatch({
      type: MutationTypes.DELETE_STEP,
      payload: {
        index,
      },
    });
  }, []);
  const changeCondition = useCallback((condition: Condition, index: number) => {
    dispatch({
      type: MutationTypes.CHANGE_CONDITION,
      payload: {
        index,
        condition,
      },
    });
  }, []);
  const deleteCondition = useCallback((index: number) => {
    dispatch({
      type: MutationTypes.DELETE_CONDITION,
      payload: {
        index,
      },
    });
  }, []);
  return (
    <form className="goal-form">
      <div className="d-flex justify-content-between">
        <div className="btn-group btn-group-toggle" data-toggle="buttons">
          <label
            className={`btn btn-secondary ${goal.type === MetrikaGoalTypes.action ? "active" : ""}`}
          >
            <input
              type="radio"
              name="options"
              id="option1"
              checked={goal.type === MetrikaGoalTypes.action}
              onChange={() => dispatch({ type: MutationTypes.CHANGE_TYPE_TO_ACTION })}
            />{" "}
            {goalTypeToString(MetrikaGoalTypes.action)}
          </label>
          <label
            className={`btn btn-secondary ${goal.type === MetrikaGoalTypes.url ? "active" : ""}`}
          >
            <input
              type="radio"
              name="options"
              id="option2"
              checked={goal.type === MetrikaGoalTypes.url}
              onChange={() => dispatch({ type: MutationTypes.CHANGE_TYPE_TO_URL })}
            />{" "}
            {goalTypeToString(MetrikaGoalTypes.url)}
          </label>
          {props.canBeStepGoal && (
            <label
              className={`btn btn-secondary ${goal.type === MetrikaGoalTypes.step ? "active" : ""}`}
            >
              <input
                type="radio"
                name="options"
                id="option3"
                checked={goal.type === MetrikaGoalTypes.step}
                onChange={() => dispatch({ type: MutationTypes.CHANGE_TYPE_TO_STEP })}
              />{" "}
              {goalTypeToString(MetrikaGoalTypes.step)}
            </label>
          )}
        </div>
        {props.deletionCallback && (
          <button onClick={props.deletionCallback} type="button" className="btn btn-link">
            <i className="far fa-trash-alt" />
          </button>
        )}
      </div>
      <label>
        Имя цели:
        <input
          type="email"
          className="form-control"
          id="exampleInputEmail1"
          aria-describedby="emailHelp"
          placeholder="Введите имя цели"
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: MutationTypes.CHANGE_NAME, payload: { name: e.target.value } })
          }
        />
      </label>
      {/* TODO make some kind of memoization, currently change in 1 step or condition rerenders all
      steps and conditions since callack function is always different */}
      {"steps" in goal && (
        <>
          Подцели:
          {goal.steps.map((step: any, i: number) => (
            <GoalForm
              canBeStepGoal={false}
              callback={(_goal: Goal) => changeStep(_goal, i)}
              deletionCallback={() => deleteStep(i)}
            />
          ))}
          <div>
            <button
              type="button"
              className="btn btn-primary btn-sm"
              onClick={() => dispatch({ type: MutationTypes.ADD_STEP })}
            >
              <i className="fas fa-plus" />
            </button>
          </div>
        </>
      )}
      {"conditions" in goal && (
        <>
          Условия:
          {goal.conditions.map((condition: Condition, index: number) => (
            <ConditionForm
              callback={(newCondition: Condition) => changeCondition(newCondition, index)}
              deletionCallback={() => deleteCondition(index)}
              condition={condition}
            />
          ))}
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => dispatch({ type: MutationTypes.ADD_CONDITION })}
          >
            <i className="fas fa-plus" />
          </button>
        </>
      )}
    </form>
  );
};

export default GoalForm;
