import { APIError } from "#/api";
import { showErrorToast } from "#/components/toasts";
import withAuthentication, {
  UserAuthenticationStatus,
  WANTED_TO_ACCESS_LC,
} from "#/components/withAuthentication";
import loading_bar from "#/scenes/Auth/assets/login-spinner.svg";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import { StoreRootState } from "#/store/types";
import { push } from "connected-react-router";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "./index.scss";

interface LoginPageConnectedProps {
  login: any;
  token: string | null;
  authenticating: boolean;
  errorMessage: string | null;
  authenticated: boolean;
  push: typeof push;
  fetchCurrentUserInfo: any;
  clearErrorMessage: any;
}

export interface LoginPageOwnProps {}

type LoginPageProps = LoginPageConnectedProps & LoginPageOwnProps;

const LoginPage = (props: LoginPageProps) => {
  const { authenticating, errorMessage } = props;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const handleSubmit = (e: any) => {
    e.preventDefault();
    props
      .login(username, password)
      .then(() => {
        props
          .fetchCurrentUserInfo()
          .then(() => {
            // If user is authenticated and there was an attempt to access a certain
            // path before, redirect the user to the attempted page.
            const wantedToAccessURL = window.localStorage.getItem(WANTED_TO_ACCESS_LC);
            if (wantedToAccessURL !== null) {
              window.localStorage.removeItem(WANTED_TO_ACCESS_LC);
              props.push(wantedToAccessURL);
            } else {
              props.push("/");
            }
          })
          .catch((err: APIError) => {
            showErrorToast(err.message);
          });
      })
      .catch((err: APIError) => {
        showErrorToast(err.message);
      });
  };
  const clearErrorMesage = () => {
    props.clearErrorMessage();
  };

  return (
    <div>
      <div className="login-item-fluid">
        <div className="login-wrapper">
          <div
            className={
              "alert alert-danger text-center mb-4 login-error " +
              (errorMessage ? "active" : undefined)
            }
          >
            {errorMessage}
          </div>

          <div className="login-logo">
            <img src="https://exemetrics.com/img/rocket.png" alt="rocket" />
          </div>
          <div className="login-sign-in">
            <div className="login-head">
              <h3 className="login-title">Войти в Rocket CRM</h3>
            </div>
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={username}
                  onChange={e => setUsername(e.target.value)}
                  onClick={clearErrorMesage}
                  autoComplete="off"
                  required={true}
                />
              </div>
              <div className="form-group">
                <input
                  className="form-control login-input-last"
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  onClick={clearErrorMesage}
                  required={true}
                />
              </div>
              <div className="row login-form-sub">
                <div className="col text-right">
                  <Link className="login-link" to="/auth/reset-password">
                    Забыли пароль?
                  </Link>
                </div>
              </div>
              <div className="login-form-action">
                <button
                  className={"btn " + (authenticating ? "loading" : undefined)}
                  type="submit"
                  disabled={authenticating}
                >
                  {authenticating ? (
                    <img src={loading_bar} style={{ height: "30px" }} alt="Loading..." />
                  ) : (
                    "Войти"
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div className="login-item-center">
        <div className="text-center">
          <span className="login-account-msg">
            Свяжитесь с менеджером, чтобы получить доступ. Если у вас уже есть приглашение для
            регистрации, нажмите <Link to="/auth/register-via-invite">сюда</Link>.
          </span>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  token: sessionModule.selectors.getAuthToken(store, null),
  authenticating: sessionModule.selectors.isAuthenticationInProgress(store, null),
  errorMessage: sessionModule.selectors.getLoginErrorMessage(store, null),
  authenticated: sessionModule.selectors.isAuthenticated(store, null),
});

export default connect(
  mapStateToProps,
  {
    login: sessionModule.actions.login,
    ...getActionsFromModule(sessionUsersModule, ["fetchCurrentUserInfo"]),
    clearErrorMessage: sessionModule.actions.clearLoginErrorMessage,
    push,
  },
)(withAuthentication<LoginPageProps>(UserAuthenticationStatus.GUEST)(LoginPage));
