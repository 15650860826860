import { apiDelete, APIError, apiGet, apiPost, apiPut } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { CRMProject, CRMTask, GetState, PrimaryKey, ThunkResult, User } from "#/store/types";
import { Dispatch } from "redux";
import { action, createCustomAction } from "typesafe-actions";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

// Fetch CRM projects list
export const fetchProjectsListSucceed = createCustomAction(
  MutationType.FETCH_PROJECTS_LIST_SUCCEED,
  type => {
    return (projects: CRMProject[]) => ({
      type,
      payload: {
        projects,
      },
    });
  },
);

export const fetchProjectsListStart = () => action(MutationType.FETCH_PROJECTS_LIST_START);

export const fetchProjectsListFail = (error: Error) =>
  action(MutationType.FETCH_PROJECTS_LIST_FAIL, {
    error,
  });

export const fetchProjectsList = (minimal = false): ThunkResult<Promise<CRMProject[]>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch(fetchProjectsListStart());
  return apiGet(API_ROUTES.CRMPLAN.PROJECTS, { minimal })
    .then((json: CRMProject[]) => {
      dispatch(fetchProjectsListSucceed(json));
      return json;
    })
    .catch((err: Error) => {
      dispatch(fetchProjectsListFail(err));
      throw err;
    });
};

// Fetch project
export const fetchProject = (id: PrimaryKey): ThunkResult<Promise<CRMProject>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.FETCH_PROJ_START });
  return apiGet(API_ROUTES.CRMPLAN.PROJECTS_ITEM(id))
    .then((project: CRMProject) => {
      dispatch({
        type: MutationType.FETCH_PROJ_SUCCEED,
        payload: { id, project, users: project.users },
      });
      return project;
    })
    .catch((error: APIError) => {
      dispatch({
        type: MutationType.FETCH_PROJ_FAIL,
        payload: { id, error },
      });
      throw error;
    });
};

// Create project
export const createProject = (data: Partial<CRMProject>): ThunkResult<Promise<CRMProject>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.CREATE_PROJ_START });
  return apiPost(API_ROUTES.CRMPLAN.PROJECTS, data)
    .then(project => {
      dispatch({
        type: MutationType.CREATE_PROJ_SUCCEED,
        payload: { project },
      });
      return project;
    })
    .catch((error: APIError) => {
      dispatch({
        type: MutationType.CREATE_PROJ_FAIL,
        payload: { error },
      });
      throw error;
    });
};

// Remove project
export const removeProject = (id: number): ThunkResult<Promise<unknown>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.REMOVE_PROJ_START });
  return apiDelete(API_ROUTES.CRMPLAN.PROJECTS_ITEM(id))
    .then((json: {}) => {
      dispatch({ type: MutationType.REMOVE_PROJ_SUCCEED, payload: { id } });
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.REMOVE_PROJ_FAIL,
        payload: { error },
      });
      throw error;
    });
};

// Edit project
export const editProject = (
  payload: { pk: number } & Partial<CRMProject>,
): ThunkResult<Promise<CRMProject>> => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.EDIT_PROJ_START });
  return apiPut(API_ROUTES.CRMPLAN.PROJECTS_ITEM(payload.pk), payload)
    .then(project => {
      dispatch({
        type: MutationType.EDIT_PROJ_SUCCEED,
        payload: { id: payload.pk, project },
      });
      return project;
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.EDIT_PROJ_FAIL,
        payload: { id: payload.pk },
      });
      throw error;
    });
};

export const addUserToProject = (
  projectId: PrimaryKey,
  userId: PrimaryKey,
): ThunkResult<Promise<PrimaryKey[]>> => (dispatch: Dispatch, getState: GetState) =>
  apiPost(API_ROUTES.CRMPLAN.ADD_USER_TO_PROJECT(projectId), { user: userId })
    .then((users: PrimaryKey[]) => {
      dispatch({
        type: MutationType.ADD_USER_TO_PROJ_SUCCEED,
        payload: { projectId, users },
      });
      return users;
    })
    .catch((err: APIError) => {
      throw err;
    });

export const removeUserFromProject = (
  projectId: PrimaryKey,
  userId: PrimaryKey,
): ThunkResult<Promise<PrimaryKey[]>> => (dispatch: Dispatch, getState: GetState) =>
  apiPost(API_ROUTES.CRMPLAN.REMOVE_USER_FROM_PROJECT(projectId), { user: userId })
    .then((users: PrimaryKey[]) => {
      dispatch({
        type: MutationType.REMOVE_USER_FROM_PROJ_SUCCEED,
        payload: { projectId, users },
      });
      return users;
    })
    .catch((err: APIError) => {
      throw err;
    });
