import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface ErrorMsgProps extends RouteComponentProps<any> {
  children: string;
}

const ErrorMsg = (props: ErrorMsgProps) => (
  <div className="box-header">
    <h4 className="box-title">{props.children}</h4>
    <div className="mt-2">
      <button
        className="btn btn-primary row-action ml-0"
        type="button"
        onClick={() => props.history.goBack()}
      >
        Вернуться назад
      </button>
    </div>
  </div>
);

export default withRouter(ErrorMsg);
