import { APIError } from "#/api";
import ResourceDeletionModal from "#/components/ResourceDeletionModal";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import Strings from "#/conf/strings";
import { canUserPerformAnyOf, mdAny } from "#/permissions";
import { getActionsFromModule } from "#/store/helpers";
import projectsModule from "#/store/modules/projects";
import sessionUsersModule from "#/store/modules/sessionUsers";
import usersModule from "#/store/modules/users";
import { CRMProject, StoreRootState, User, UsersById } from "#/store/types";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ListItem from "../ListItem";
import "./index.scss";

interface ListConnectedProps {
  listProjects: CRMProject[];
  loading: boolean;
  fetchProjectsList: any;
  removeProject: any;
  editProject: any;
  editingProject: boolean;
  usersById: UsersById;
  currentUser: User | null;
}

interface ListOwnProps {}

type ListProps = ListConnectedProps & ListOwnProps;

const List = (props: ListProps) => {
  const { listProjects, loading } = props;
  const [item, setItem] = useState({});
  const [isModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    props.fetchProjectsList();
  }, []);

  const toggleModal = () => setModalOpen(!isModalOpen);
  const setActive = (i: CRMProject) => {
    setItem(i);
    setModalOpen(true);
  };

  const onSave = (i: CRMProject, callback: any) => {
    props.editProject(i).then(() => {
      showSuccessToast(Strings.PROJECT_EDIT_SUCCESS(i.name));
      callback();
    });
  };

  const onRemove = (i: CRMProject) => {
    props
      .removeProject(i.pk)
      .then(() => {
        showSuccessToast(Strings.PROJECT_DELETE_SUCCESS(i.name));
        setItem({});
        setModalOpen(false);
      })
      .catch((error: APIError) => {
        showErrorToast(Strings.PROJECT_DELETE_FAIL);
      });
  };

  // TODO: Implement loader
  if (loading) {
    return null;
  }

  const renderedProjects = listProjects.map((el, i) => {
    const creator = _.get(props.usersById, el.creator, null);
    return (
      <ListItem
        key={i}
        setActive={setActive}
        onSave={onSave}
        item={el}
        creator={creator ? creator.data : null}
        editing={props.editingProject}
        currentUser={props.currentUser}
      />
    );
  });

  return (
    <div>
      <TransitionGroup>
        <CSSTransition
          classNames="mask"
          appear={true}
          timeout={{
            enter: 0,
            exit: 300,
          }}
        >
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="box">
                  <div className="row no-gutters py-2">
                    <div className="box-body">
                      <table className="table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Проект</th>
                            <th>Описание</th>
                            <th>Руководитель</th>
                            <th>Статус</th>
                            <th>Дата создания</th>
                            {canUserPerformAnyOf(props.currentUser, [
                              [
                                "EDIT",
                                "PROJECTCRM",
                                { many: true, mdfn: mdAny, objs: props.listProjects },
                              ],
                              [
                                "DELETE",
                                "PROJECTCRM",
                                { many: true, mdfn: mdAny, objs: props.listProjects },
                              ],
                            ]) && <th>Действия</th>}
                            <th />
                          </tr>
                        </thead>
                        <tbody>{renderedProjects}</tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CSSTransition>
      </TransitionGroup>
      <ResourceDeletionModal
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
        onRemove={() => (item !== {} ? onRemove(item as any) : null)}
        whatToDelete="проект"
        removeString="УДАЛИТЬ"
        additionalText="После удаления этого проекта будут также удалены все связанные с ним проекты."
      />
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  listProjects: projectsModule.selectors.getAllProjectsSortedByNameBare(store, null),
  loading: projectsModule.selectors.isLoadingProjects(store, null),
  editingProject: projectsModule.selectors.isProjectEditInProcess(store, null),
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  usersById: usersModule.selectors.getUsersById(store, null),
});

export default connect(
  mapStateToProps,
  getActionsFromModule(projectsModule, ["fetchProjectsList", "removeProject", "editProject"]),
)(List);
