import React from "react";
import { PrimaryKey, TaskCheckListItem } from "#/store/types";
import TableCheckbox from "#/components/TableCheckbox";

const TaskChecklistMinimal = (props: {
  checklist: TaskCheckListItem[];
  canToggle: boolean;
  toggleItem: (pk: PrimaryKey, status: boolean) => Promise<boolean>;
  toggling: boolean;
}) => {
  return props.checklist.length > 0 ? (
    <div className="checklist card d-flex flex-column">
      {props.checklist.map((el: TaskCheckListItem, i: number) => (
        <div key={i} className="card-body card-body-checklist d-flex flex-row">
          <TableCheckbox
            checked={el.status}
            onChange={() => {
              props.toggleItem(el.pk, !el.status);
            }}
          />
          <span className="ml-2">{el.name}</span>
        </div>
      ))}
    </div>
  ) : (
    <></>
  );
};

export default TaskChecklistMinimal;
