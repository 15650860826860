import React from "react";
import QueryListContext, { IQueryListContext } from "../QueryListContext";
import CreateRequestsModal from "./CreateRequestsModal";
import RemoveTopFQueries from "./RemoveTopFQueries";
import ControllableConfigurationSelect from "../../ConfigurationsSelect/ControllableConfigurationsSelect";
import style from "../index.module.scss";

const PageHeader = () => {
  // Context
  const {
    querySelection,
    removeSelectedQueryPages,
    groups,
    removeCurrentEventGroup: removeEvent,
    createNewRequests,
    importIntoGroup,
    selectedConfig,
    setSelectedConfig,
  } = React.useContext(QueryListContext) as IQueryListContext;

  // Modals
  const [removeRequestsModalShown, setRemoveRequestsModalShown] = React.useState<boolean>(false);
  const toggleRemoveRequestsModal = React.useCallback(
    () => setRemoveRequestsModalShown(!removeRequestsModalShown),
    [removeRequestsModalShown],
  );
  const [createRequestsModalActive, setCreateRequestsModalActive] = React.useState<boolean>(false);
  const toggleCreateRequestsModalActive = () =>
    setCreateRequestsModalActive(!createRequestsModalActive);

  return (
    <div className="box-header with-border flex-vertically-centered-x justify-content-between">
      <h4 className="box-title flex-1">Поисковые запросы</h4>
      <div className={style["actions-list"]}>
        <ControllableConfigurationSelect
          value={selectedConfig}
          onChange={c => setSelectedConfig(c)}
          className={style["configuration-select"]}
        />
        {querySelection.selectedItemsAmount > 0 && (
          <button className="btn btn-list row-action" onClick={removeSelectedQueryPages}>
            <i className="fas fa-trash-alt mr-2" />
            {querySelection.areAllItemsSelected
              ? "Удалить все запросы из группы"
              : `Удалить выбранные запросы (${querySelection.selectedItemsAmount})`}
          </button>
        )}
        <button className="btn btn-list row-action" onClick={toggleRemoveRequestsModal}>
          <i className="fas fa-trash-alt" />
          <span className="ml-2">Удалить группу запросов</span>
        </button>
        <button className="btn btn-list row-action" onClick={toggleCreateRequestsModalActive}>
          <i className="fas fa-file-import" />
          <span className="ml-2">Импорт запросов</span>
        </button>
      </div>
      <CreateRequestsModal
        groups={groups}
        onCreateNewGroup={createNewRequests}
        onImportIntoGroup={importIntoGroup}
        toggle={toggleCreateRequestsModalActive}
        isOpen={createRequestsModalActive}
      />
      <RemoveTopFQueries
        isOpen={removeRequestsModalShown}
        toggle={toggleRemoveRequestsModal}
        onRemove={removeEvent}
      />
    </div>
  );
};

export default PageHeader;
