import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import "./index.scss";
import PageWrapper from "#/components/PageWrapper";

const Index = (props: {}) => (
  <PageWrapper>
    <section className="content-header">
      <h1>
        Пользователь \ <small>Панель управления</small>
      </h1>
      <ol className="breadcrumb d-none d-sm-flex">
        <li className="breadcrumb-item">
          <Link to="/">
            <i className="fa fa-dashboard" /> Главная
          </Link>
        </li>
        <li className="breadcrumb-item active">Панель управления</li>
      </ol>
    </section>
    <Dashboard />
  </PageWrapper>
);

export default withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(Index);
