import React, { useEffect } from "react";
import { apiGet } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Region } from "#/store/types";
import _ from "lodash";

export const useRegions = (options: { includeById: boolean; query: string }, deps: any[]) => {
  const [data, setData] = React.useState([]);
  const includeById = options.includeById ? options.includeById : false;

  useEffect(() => {
    if (!options.query) {
      setData([]);
      return;
    }
    (async () => {
      const resp = await apiGet(API_ROUTES.TOP50.REGIONS, { query: options.query });
      setData(resp);
    })();
  }, [options.query]);

  const byId = React.useMemo<Record<PrimaryKey, Region>>(() => {
    if (!includeById) {
      return {};
    }
    return _.keyBy(data, "pk");
  }, [data, includeById]);

  return { data, byId };
};
