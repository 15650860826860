import { MetrikaGoalsState, MetrikaGoalTypes } from "./types";
import { createReducer } from "#/store/helpers";
import MutationTypes from "./mutationTypes";
import ActionTypes from "./actionTypes";
import _ from "lodash";

export const initialState: MetrikaGoalsState = {
  tokens: {},
  counters: {},
  fetching: false,
  goals: {},
  selection: {},
  creatingGoal: {
    name: "",
    type: MetrikaGoalTypes.action,
    conditions: [],
  },
  selectedGoals: [],
};

export const reducer = createReducer(
  {
    [MutationTypes.SELECT_TOKEN]: (state: MetrikaGoalsState, action: ActionTypes.SelectToken) => ({
      ...state,
      selection: action.payload.tokenId
        ? { ...state.selection, [action.payload.counterId]: action.payload.tokenId }
        : _.omit(state.selection, action.payload.counterId),
    }),

    [MutationTypes.ADD_GOALS]: (state: MetrikaGoalsState, action: ActionTypes.AddGoals) => ({
      ...state,
      goals: {
        ...state.goals,
        ..._.mapValues(action.payload.goals, goal => ({
          ...goal,
          counter: action.payload.counterId,
        })),
      },
      counters: {
        ...state.counters,
        [action.payload.counterId]: {
          ...state.counters[action.payload.counterId],
          goals: Array.from(
            new Set([
              ...state.counters[action.payload.counterId].goals,
              ...Object.values(action.payload.goals).map(g => g.id),
            ]),
          ),
        },
      },
    }),

    [MutationTypes.CHANGE_CREATING_GOAL]: (
      state: MetrikaGoalsState,
      action: ActionTypes.ChangeCreatingGoal,
    ) => ({
      ...state,
      creatingGoal: action.payload.goal,
    }),

    [MutationTypes.FETCH_COUNTERS_START]: (state: MetrikaGoalsState) => ({
      ...state,
      fetching: true,
    }),

    [MutationTypes.FETCH_COUNTERS_FAILURE]: (state: MetrikaGoalsState) => ({
      ...state,
      fetching: false,
    }),

    [MutationTypes.FETCH_COUNTERS_SUCCEED]: (
      state: MetrikaGoalsState,
      action: ActionTypes.FetchTokenSucceed,
    ) => ({
      ...state,
      counters: {
        ...state.counters,
        ...action.payload.counters,
      },
      tokens: {
        ...state.tokens,
        ...action.payload.tokens,
      },
    }),

    [MutationTypes.FETCH_TOKENS_SUCCEED]: (
      state: MetrikaGoalsState,
      action: ActionTypes.FetchTokenSucceed,
    ) => ({
      ...state,
      counters: {
        ...state.counters,
        ...action.payload.counters,
      },
      tokens: {
        ...state.tokens,
        ...action.payload.tokens,
      },
    }),

    [MutationTypes.SELECT_GOALS]: (state: MetrikaGoalsState, action: ActionTypes.SelectGoals) => ({
      ...state,
      selectedGoals: action.payload.selection,
    }),
  },
  initialState,
);

export default reducer;
