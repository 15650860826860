import style from "./index.module.scss";
import React from "react";
import cn from "classnames";

const PageWrapper = (props: { children: any }) => (
  <div className={style["page-wrapper"]}>{props.children}</div>
);

export const PageContent = (props: { children: any }) => (
  <section className={cn(["content", style["page-content"]])}>
    <div className="row h-100">
      <div className="col-12 h-100 d-flex flex-column">{props.children}</div>
    </div>
  </section>
);

export default PageWrapper;
