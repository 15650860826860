import { showErrorToast } from "#/components/toasts";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import { fetchCurrentUserNotifications } from "#/store/modules/session/actions";
import { DispatchProp, PrimaryKey, StoreRootState, UserNotificationDerived } from "#/store/types";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown } from "reactstrap";
import style from "./index.module.scss";
import ItemsMenu from "./ItemsMenu";
import MenuToggleButton from "./MenuToggleButton";

interface NotificationMenuConnectedProps {
  currentUserNotifications: UserNotificationDerived[];
  loadingNotifications: boolean;
  fetchCurrentUserNotifications: DispatchProp<typeof fetchCurrentUserNotifications>;
  unreadNotifications: number;
  push: typeof push;
  markNotificationsAsRead: (ids: PrimaryKey[]) => Promise<unknown>;
  areAllUserNotificationsAlreadyFetched: boolean;
}

interface NotificationMenuOwnProps {}

type NotificationMenuProps = NotificationMenuConnectedProps & NotificationMenuOwnProps;

const NotificationMenu = (props: NotificationMenuProps) => {
  const [userNotificationsDropdownOpen, setUserNotificationsDropdownOpen] = useState<boolean>(
    false,
  );
  const [fetchedInitialNotifs, setFetchedInitialNotifs] = useState<boolean>(false);

  // Initial render
  useEffect(() => {
    props.fetchCurrentUserNotifications({ onlyUnreadAmount: true });
  }, []);
  // On dropdown open
  useEffect(() => {
    if (userNotificationsDropdownOpen && !fetchedInitialNotifs) {
      props.fetchCurrentUserNotifications().catch(() => {
        showErrorToast("Невозможно получить список оповещений пользователя");
      });
      setFetchedInitialNotifs(true);
    }
  }, [userNotificationsDropdownOpen, fetchedInitialNotifs]);

  const onToggle = useCallback(
    _.partial(setUserNotificationsDropdownOpen, !userNotificationsDropdownOpen),
    [setUserNotificationsDropdownOpen, userNotificationsDropdownOpen],
  );

  return (
    <div className="header-navbar-item">
      <div className="header-navbar-wrapper">
        <Dropdown
          size="lg"
          inNavbar={true}
          isOpen={userNotificationsDropdownOpen}
          className={style["notification-menu"]}
          toggle={onToggle}
        >
          <MenuToggleButton unreadNotifications={props.unreadNotifications} />
          <ItemsMenu currentlyOpened={userNotificationsDropdownOpen} {...props} />
        </Dropdown>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  unreadNotifications: sessionModule.selectors.getUnreadNotificationsAmount(store, null),
  loadingNotifications: sessionModule.selectors.isCurrentlyLoadingUserNotifications(store, null),
  currentUserNotifications: sessionModule.selectors.getCurrentUserNotificationsSortedByDateAsc(
    store,
    null,
  ),
  areAllUserNotificationsAlreadyFetched: sessionModule.selectors.areAllUserNotificationsAlreadyFetched(
    store,
    null,
  ),
});

export default connect(mapStateToProps, {
  ...getActionsFromModule(sessionModule, [
    "logout",
    "fetchCurrentUserNotifications",
    "markNotificationsAsRead",
  ]),
  push,
})(NotificationMenu);
