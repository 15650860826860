// import kbSystemModule from "#/store/modules/keybindingSystem";
import { Keybindings, StoreRootState } from "#/store/types";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import {
  Button,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import style from "./index.module.scss";

interface KeybindingDescription {
  shortcut: string;
  description: string;
}

const keybindings: Record<string, KeybindingDescription[]> = {
  Общее: [
    {
      shortcut: "/",
      description: "Поиск",
    },
    {
      shortcut: "Alt + S",
      description: "Сохранить",
    },
    {
      shortcut: "Alt + A",
      description: "Выделить всё",
    },
    {
      shortcut: "Alt + B",
      description: "Скрыть/показать сайдбар",
    },
    {
      shortcut: "Alt + P",
      description: "Перейти к проекту",
    },
    {
      shortcut: "Alt + L",
      description: "Перейти к пользователю",
    },
  ],
  "Система задач": [
    {
      shortcut: "Alt + D",
      description: "Перейти к задачам",
    },
  ],
  Мониторинг: [
    {
      shortcut: "Alt + R",
      description: "Запустить мониторинг проекта",
    },
    {
      shortcut: "Alt + Shift + A",
      description: "Открыть окно конфигурации URL проекта",
    },
  ],
};

const ShortcutsModal = (props: { isOpen: boolean; toggle: () => void }) => {
  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} size="lg">
      <ModalHeader toggle={props.toggle}>Горячие клавиши</ModalHeader>
      <ModalBody className={style.sections}>
        {_.keys(keybindings).map((sectionName: string) => {
          const sectionItems = _.get(keybindings, sectionName);
          return (
            <div key={sectionName} className={style["keybindings-section"]}>
              <div className={style["keybindings-section__body"]}>
                <ListGroup className={style["keybindings-section__keybindings-list"]} flush={true}>
                  <ListGroupItem className={style["keybindings-section__title"]}>
                    {sectionName}
                  </ListGroupItem>
                  {sectionItems.map((item: KeybindingDescription) => {
                    return (
                      <ListGroupItem key={item.shortcut} className={style["keybinding-item"]}>
                        <span className={style["keybinding-item__description"]}>
                          {item.description}
                        </span>
                        <span className={style["keybinding-item__shortcut"]}>{item.shortcut}</span>
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              </div>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <Button color="transparent" outline={true} onClick={props.toggle}>
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default connect((store: StoreRootState) => ({}))(ShortcutsModal);
