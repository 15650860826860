import React, { useCallback } from "react";
import YandexFavicon from "#/components/YandexFavicon";
import { connect, useDispatch } from "react-redux";
import { selectToken } from "#/store/modules/metrika/goals";

interface BlockConnectedProps {}

interface BlockOwnProps {
  isSite: boolean;
  text: string;
  counterId: number;
  tokenId: number;
  isChecked: boolean;
  onChevronClick?: () => void;
}

type BlockProps = BlockConnectedProps & BlockOwnProps;

export const Block = (props: BlockProps) => {
  const dispatch = useDispatch();
  const onCheck = useCallback(
    event => {
      console.log(event.target);
      if (!event.target.checked) { return dispatch(selectToken(props.counterId, null)); }
      console.log("Event is not checked");
      return dispatch(selectToken(props.counterId, props.tokenId));
    },
    [props.counterId, props.tokenId],
  );
  return (
    <div className="list-group-item d-flex justify-content-between">
      <span>
        {props.isSite && <YandexFavicon name={props.text} alt="icon" />}
        {props.text}
      </span>

      <div>
        {props.isSite && (
          <button className="btn btn-link" type="button" onClick={props.onChevronClick}>
            <i className="fas fa-chevron-right"/>
          </button>
        )}
        <input type="checkbox" className="ml-2" checked={props.isChecked} onChange={onCheck} />
      </div>
    </div>
  );
};

export default Block;

// const mapStateToProps = state => ({});

// const mapDispatchToProps = {
//   selectToken,
// };

// export default connect(null, mapDispatchToProps)(Block);
