import { AnyFunction } from "#/types";
import { undefinedOr } from "#/util";
import React from "react";
import Hotkeys from "react-hot-keys";

const OnEnter = (props: { do: AnyFunction; ctrl?: boolean; children: React.ReactElement }) => {
  const ctrl = undefinedOr(props.ctrl, false);
  const keysToListen = ["enter", ...(ctrl ? ["ctrl+enter", "control+enter"] : [])].join(",");
  return (
    <Hotkeys
      keyName={keysToListen}
      onKeyDown={(keyName, e, handle) => {
        props.do();
      }}
    >
      {props.children}
    </Hotkeys>
  );
};

export default OnEnter;
