import { Proxy } from "#/store/types";
import { dateDiffInDays, dateDiffInHours } from "#/util";
import React from "react";

interface ProxyTableItemProps {
  proxy: Proxy;
  onClickHandler: any;
}

const ProxyTableItem = (props: ProxyTableItemProps) => {
  const { proxy, onClickHandler } = props;

  const currentDate = new Date();
  const expirationDate = new Date(proxy.expiration_date);
  const daysLeft = dateDiffInDays(currentDate, expirationDate);
  const hoursLeft = dateDiffInHours(currentDate, expirationDate);
  const deadline = daysLeft > 0 ? `${daysLeft} д.` : hoursLeft > 0 ? `${hoursLeft} ч.` : "OFF";

  return (
    <React.Fragment>
      <tr className="topf-project-list-item">
        <td>{proxy.pk}</td>
        <td>{proxy.label}</td>
        <td onClick={e => onClickHandler(e, proxy.name)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.name}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td onClick={e => onClickHandler(e, proxy.ip)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.ip}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td onClick={e => onClickHandler(e, proxy.port)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.port}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td onClick={e => onClickHandler(e, proxy.login)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.login ? proxy.login : "-"}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td onClick={e => onClickHandler(e, proxy.password)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.password ? proxy.password : "-"}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td onClick={e => onClickHandler(e, proxy.type_value)}>
          <span className={"copyable-text"} title={"Скопировать в буфер"}>
            {proxy.type_value}
            <span className="ml-2 small">
              <i className="far fa-copy" />
            </span>
          </span>
        </td>
        <td>{deadline}</td>
      </tr>
    </React.Fragment>
  );
};

export default ProxyTableItem;
