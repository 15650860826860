import React from "react";
import { cssTransition, toast } from "react-toastify";

const zoomTransition = cssTransition({
  enter: "zoomInRight",
  exit: "zoomOutRight",
  duration: 600,
});

export const MessageError = (props: { text: string }) => (
  <div>
    <div className="toast-title">Ошибка!</div>
    <div className="toast-message">{props.text}</div>
  </div>
);

const MessageSuccess = (props: { text: string; onClickCallback?: () => void }) => (
  <div onClick={props.onClickCallback}>
    <div className="toast-title">Оповещение</div>
    <div className="toast-message">{props.text}</div>
  </div>
);

const MessageAlert = (props: { text: string; onClickCallback?: () => void }) => (
  <div onClick={props.onClickCallback}>
    <div className="toast-title">Оповещение</div>
    <div className="toast-message">{props.text}</div>
  </div>
);

export const showErrorToast = (text: string) => {
  toast.error(<MessageError text={text} />, {
    autoClose: 5000,
    className: "toast-red",
    bodyClassName: "toast-red-body",
    transition: zoomTransition,
  });
};

export const showSuccessToast = (text: string, onClickCallback?: () => void) => {
  toast.success(<MessageSuccess text={text} onClickCallback={onClickCallback} />, {
    autoClose: 3000,
    className: "toast-green",
    bodyClassName: "toast-green-body",
    transition: zoomTransition,
  });
};

export const showInfoToast = (text: string, onClickCallback?: () => void, delay = 5000) => {
  toast.info(<MessageAlert text={text} onClickCallback={onClickCallback} />, {
    autoClose: delay,
    className: "toast-primary",
    bodyClassName: "toast-primary-body",
    transition: zoomTransition,
  });
};
