enum MutationTypes {
  SITES_FETCH_START = "accounts/SITES_FETCH_START",
  SITES_FETCH_FINISH = "accounts/SITES_FETCH_SUCCESS",

  CURRENT_SITE_SET = "accounts/CURRENT_SITE_SET",
  SITE_TYPES_SET = "accounts/SITE_TYPES_SET",
  SITES_SET = "accounts/SITE_SET",

  ACCOUNTS_SET = "accounts/ACCOUNTS_SET",

  ACCOUNTS_SELECTION_SET = "accounts/ACCOUNTS_SELECTION_SET",
  ACCOUNTS_MAX_PAGES_SET = "accounts/ACCOUNTS_MAX_PAGES_SET",
  ACCOUNTS_REQUEST_PARAMS_SET = "accounts/ACCOUNTS_REQUEST_PARAMS_SET",

  ACCOUNTS_REQUEST_PARAMS_RESET = "accounts/ACCOUNTS_REQUEST_PARAMS_RESET",

  SITE_FETCH_SUCCESS = "accounts/SITE_FETCH_SUCCESS",
  SITE_FETCH_FAILURE = "accounts/SITE_FETCH_FAILURE",
  SITE_FETCH_START = "accounts/SITE_FETCH_START",

  REQUEST_BODY_SET = "accounts/REQUEST_BODY_SET",

  ACCOUNTS_FETCH_START = "accounts/ACCOUNTS_FETCH_START",
  ACCOUNTS_FETCH_SUCCESS = "accounts/ACCOUNTS_FETCH_SUCCESS",
  ACCOUNTS_FETCH_FAILURE = "accounts/ACCOUNTS_FETCH_FAILURE",

  QUERY_SET = "accounts/ACCOUNTS_QUERY_SET",

  RESET_SETTINGS = "accounts/RESET_ACCOUNTS",

  PAGE_SET = "accounts/PAGE_SET",
  PAGE_SIZE_SET = "accounts/PAGE_SIZE_SET"
}

export default MutationTypes;
