import Page from "#/components/Page";
import React, { useEffect } from "react";
import { Route, Switch } from "react-router";
import AccountsPage from "./components/AccountsPage/index";
import Header from "./components/Header";
import Index from "./components/Index";
import { isLoading, fetchSitesWithTypes } from "#/store/modules/accounts";
import { connect } from "react-redux";
import Loader from "#/components/Loader";
import { DispatchProp, StoreRootState } from "#/store/types";

interface AccountsSceneOwnProps {
  match: any;
}

interface AccountsSceneConnectedProps {
  loading: boolean;
  fetchSitesWithTypes: DispatchProp<typeof fetchSitesWithTypes>;
}

type AccountsSceneProps = AccountsSceneConnectedProps & AccountsSceneOwnProps;

const AccountsScene = ({ match, loading, fetchSitesWithTypes }: AccountsSceneProps) => {
  useEffect(() => {
    fetchSitesWithTypes();
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <>
          <Header />
          <section className="content w-100">
            <div className="row">
              <div className="col-12">
                <div className="box">
                  <Switch>
                    <Route
                      path={match.url}
                      exact={true}
                      render={(props: any) => (
                        <Page title="Accounts">
                          <Index {...props} />
                        </Page>
                      )}
                    />
                    <Route
                      path={`${match.url}/:pk`}
                      render={(props: any) => (
                        <Page title="Accounts category">
                          <AccountsPage {...props} />
                        </Page>
                      )}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default connect(
  (state: StoreRootState) => ({
    loading: isLoading(state, null),
  }),
  {
    fetchSitesWithTypes,
  },
)(AccountsScene);
