import { createReducer } from "#/store/helpers";
import { UserState } from "#/store/types";
import MutationType from "./mutationType";

export const initialState: UserState = {
  userId: null,
};

export const reducer = createReducer<UserState, any>(
  {
    // Set current user
    [MutationType.SET_CURRENT_USER]: (state: UserState, action: any) => ({
      ...state,
      userId: action.payload.id,
    }),
  },
  initialState,
);

export default reducer;
