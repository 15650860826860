import { createContext } from "react";
import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import { Top50Event } from "#/store/types";

export interface IEventItemContext {
  activeGroup: Top50Event | null;
  removeCurrentGroup: () => Promise<void>;
}

const EventItemContext = createContext<IEventItemContext>({
  activeGroup: null,
  removeCurrentGroup: NON_REACHABLE_EMPTY_FUNCTION,
});

export default EventItemContext;
