import React, { useCallback } from "react";
import { makeGetFilterValue, changeFilterSettings } from "#/store/modules/accounts";
import { connect } from "react-redux";
import { AccountsQueryParameter } from "#/store/modules/accounts/types";
import { StoreRootState } from "#/store/types";
import _ from "lodash";

interface FilterComponentOwnProps {
  type: "number" | "date";
  way: string;
}

interface FilterComponentConnectedProps {
  filterValue: AccountsQueryParameter<any>;
  changeFilterSettings: any;
}

type FilterComponentProps = FilterComponentOwnProps & FilterComponentConnectedProps;

const FilterComponent = ({
  type,
  way,
  filterValue,
  changeFilterSettings,
}: FilterComponentProps) => {
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      const value = target.value;
      const newValue = value.length
        ? { ...filterValue, [target.dataset.extremum || ""]: value }
        : _.omit(filterValue, target.dataset.extremum || "");
      changeFilterSettings(way, newValue);
    },
    [way, filterValue],
  );

  return (
    <div className="input-group">
      {["min", "max"].map(extremum => {
        return (
          <input
            type={type}
            key={extremum}
            className="form-control"
            placeholder={extremum.charAt(0).toUpperCase() + extremum.slice(1)}
            onChange={onChange}
            data-extremum={extremum}
            value={filterValue[extremum] || ""}
          />
        );
      })}
    </div>
  );
};

export default connect(
  (state: StoreRootState, props) => ({
    filterValue: makeGetFilterValue()(state, props),
  }),
  {
    changeFilterSettings,
  },
)(FilterComponent);
