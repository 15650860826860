import { CODE_NON_REACHABLE } from "#/util";
import { SettingsPageTab } from "./types";

export const tabKindToString = (kind: SettingsPageTab) => {
  switch (kind) {
    case SettingsPageTab.GENERAL: {
      return "Общее";
    }
    case SettingsPageTab.SECURITY: {
      return "Безопасность";
    }
    case SettingsPageTab.NOTIFICATIONS: {
      return "Оповещения";
    }
    default: {
      CODE_NON_REACHABLE();
      return "";
    }
  }
};
