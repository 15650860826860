import { apiPost } from "#/api";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { API_ROUTES } from "#/conf/api";
import loading_bar from "#/scenes/Auth/assets/login-spinner.svg";
import * as sessionModule from "#/store/modules/session";
import { StoreRootState } from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import style from "./index.module.scss";

export interface ResetPasswordOwnProps {}

interface ResetPasswordConnectedProps {
  token: string | null;
  authenticated: boolean;
}

type LoginProps = ResetPasswordOwnProps & ResetPasswordConnectedProps;

enum Stage {
  FIRST_STAGE = 1,
  SECOND_STAGE = 2,
  THIRD_STAGE = 3,
  FOURTH_STAGE = 4,
}

enum RCWT {
  INITIAL = -0x8239823,
  READY = -0x2737237,
}

// Seconds
const RCWT_MAX = 20;

// 1 second
const RCWT_INTERVAL_MS = 1000;

const RestorePassword = (props: LoginProps) => {
  // Form dadta
  const [username, setUsername] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordRepeat, setNewPasswordRepeat] = useState<string>("");
  // Other
  const [code, setCode] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [stage, setStage] = useState<Stage>(Stage.FIRST_STAGE);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const clearMessage = () => setErrorMessage("");

  const approveRequest = useCallback(
    (approvalCode: string) => {
      setLoading(true);
      apiPost(API_ROUTES.MAIN.RESET_PASSWORD_APPROVE_CODE, { approvalCode })
        .then(res => {
          setLoading(false);
          setStage(res.is_valid ? 3 : 2);
          setErrorMessage(!res.is_valid ? "Введен неверный код" : null);
        })
        .catch(err => {
          setLoading(false);
          handleDispatchErrorAndDisplayToast(err);
        });
    },
    [setLoading, code, setStage, setErrorMessage],
  );

  const changePassword = useCallback(
    (email: string, password: string, approvalCode: string) => {
      setLoading(true);
      apiPost(API_ROUTES.MAIN.RESET_PASSWORD_FINAL, {
        username: email,
        password,
        code: approvalCode,
      })
        .then(res => {
          setLoading(false);
          setStage(res.success ? Stage.FOURTH_STAGE : Stage.THIRD_STAGE);
          setErrorMessage(res.res ? res.res : null);
        })
        .catch(err => {
          setLoading(false);
          handleDispatchErrorAndDisplayToast(err);
        });
    },
    [setLoading, setStage, setErrorMessage],
  );

  const sendRequest = useCallback(
    (email: string) => {
      setLoading(true);
      apiPost(API_ROUTES.MAIN.RESET_PASSWORD, { username: email })
        .then(res => {
          setLoading(false);
          setStage(res.created ? 2 : 1);
          setErrorMessage(res.res ? res.res : null);
        })
        .catch(err => {
          handleDispatchErrorAndDisplayToast(err);
          setLoading(false);
        });
    },
    [setLoading, setStage, setErrorMessage],
  );

  const [requestCodeWaitTimer, setRequestCodeWaitTimer] = useState<number | null>(null);

  const transitionFromFirstStageToSecond = () => {
    if (username) {
      sendRequest(username);
      startRequestCodeWaitTimer();
    }
  };

  const handleSubmit = useCallback(
    (e: any) => {
      e.preventDefault();
      switch (stage) {
        case Stage.FIRST_STAGE: {
          transitionFromFirstStageToSecond();
          break;
        }
        case Stage.SECOND_STAGE: {
          if (code) {
            approveRequest(code);
          }
          break;
        }
        case Stage.THIRD_STAGE: {
          if (newPassword && newPasswordRepeat) {
            if (newPassword === newPasswordRepeat) {
              changePassword(username, newPassword, code);
              return;
            }
            setErrorMessage("Пароли должны быть одинаковые!");
          }
          break;
        }
        default: {
          console.error("Invalid stage number");
          return;
        }
      }
    },
    [stage, username, sendRequest, approveRequest, newPassword, newPasswordRepeat, setErrorMessage],
  );

  const startRequestCodeWaitTimer = () => {
    setRequestCodeWaitTimer(RCWT_MAX);
  };

  const requestCodeWaitTimerReady = useMemo(() => requestCodeWaitTimer === RCWT.READY, [
    requestCodeWaitTimer,
  ]);

  const requestCodeWaitTimerWaiting = useMemo(
    () =>
      requestCodeWaitTimer !== null &&
      (requestCodeWaitTimer >= 0 && requestCodeWaitTimer <= RCWT_MAX),
    [requestCodeWaitTimer],
  );

  // Timer updater
  useEffect(() => {
    if (requestCodeWaitTimer !== null && requestCodeWaitTimer !== RCWT.INITIAL) {
      if (requestCodeWaitTimerWaiting) {
        _.delay(() => {
          setRequestCodeWaitTimer(requestCodeWaitTimer - 1);
        }, RCWT_INTERVAL_MS);
      } else {
        setRequestCodeWaitTimer(RCWT.READY);
      }
    }
  }, [requestCodeWaitTimer, requestCodeWaitTimerWaiting]);

  const requestCodeWindow = useMemo(() => {
    return (
      <div className={style["request-code-wait-timer-window"]}>
        {requestCodeWaitTimerReady ? (
          <>
            <span className="mr-1">Код не пришел?</span>
            <Button
              color="link"
              onClick={() => {
                transitionFromFirstStageToSecond();
              }}
            >
              Отправить заново
            </Button>
          </>
        ) : requestCodeWaitTimerWaiting ? (
          <span>Следующий код можно будет отправить через {requestCodeWaitTimer}</span>
        ) : null}
      </div>
    );
  }, [requestCodeWaitTimer]);

  return (
    <div>
      <div className="login-item-fluid">
        <div className="login-wrapper">
          {errorMessage && (
            <div
              className={
                "alert alert-danger text-center mb-4 login-error " +
                (errorMessage ? "active" : undefined)
              }
            >
              {errorMessage}
            </div>
          )}
          <div className="login-logo">
            <img src="https://exemetrics.com/img/rocket.png" alt="rocket" />
          </div>
          <div className="login-sign-in">
            <div className="login-head">
              <h3 className="login-title">Восстановление доступа</h3>
              {requestCodeWindow}
            </div>
            <form className="login-form" onSubmit={handleSubmit}>
              {stage === 1 && (
                <React.Fragment>
                  <h6 className="text-center">
                    Введите Ваш адрес электронной почты и мы вышлем Вам сообщение с кодом и
                    дальнейшими инструкциями.
                  </h6>
                  <div className="form-group mt-4 pt-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Введите адрес email"
                      value={username}
                      onChange={e => setUsername(e.target.value)}
                      onClick={clearMessage}
                      required={true}
                    />
                  </div>
                </React.Fragment>
              )}
              {stage === 2 && (
                <React.Fragment>
                  <h6 className="text-center">
                    Введите код, отправленный на Вашу электронную почту.
                  </h6>
                  <div className="form-group mt-4 pt-4">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="XXXXXX"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                      onClick={clearMessage}
                      required={true}
                    />
                  </div>
                </React.Fragment>
              )}
              {stage === 3 && (
                <React.Fragment>
                  <h6 className="text-center">Введите новый пароль.</h6>
                  <small className="mt-2 mb-2 text-center">
                    Этот пароль будет использоваться для входа в Ваш аккаунт
                  </small>
                  <div className="form-group mt-4 pt-4">
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Пароль"
                      value={newPassword}
                      onChange={e => setNewPassword(e.target.value)}
                      onClick={clearMessage}
                      required={true}
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control login-input-last"
                      type="password"
                      placeholder="Подтвердите пароль"
                      value={newPasswordRepeat}
                      onChange={e => setNewPasswordRepeat(e.target.value)}
                      onClick={clearMessage}
                      required={true}
                    />
                  </div>
                </React.Fragment>
              )}
              {stage === 4 && (
                <div className="alert alert-success text-center mb-4">
                  Пароль был успешно изменен
                </div>
              )}
              {stage !== 4 && (
                <div className="login-form-action">
                  <button
                    className={"btn " + (loading ? "loading" : undefined)}
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? (
                      <img src={loading_bar} style={{ height: "30px" }} alt="Loading..." />
                    ) : (
                      "Продолжить"
                    )}
                  </button>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>

      <div className={style["form-bottom"]}>
        <div className="login-item-center">
          <div className="text-center">
            <span className="login-account-msg">
              <Link to="/auth/login" className="login-link">
                <i className="fas fa-long-arrow-alt-left" />
                <span className="ml-2">Вернуться к логину</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  token: sessionModule.selectors.getAuthToken(store, null),
});

export default connect(
  mapStateToProps,
  {},
)(withAuthentication<LoginProps>(UserAuthenticationStatus.GUEST)(RestorePassword));
