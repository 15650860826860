import { APIError, apiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, User } from "#/store/types";
import { Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";

/////////////////////////////////////
// Generate invitation link
/////////////////////////////////////

const generateInvitationLinkFail = (
  error: Error,
): ActionTypes.GenerateInvitationLinkFailAction => ({
  type: MutationTypes.GENERATE_INVITATION_LINK_FAIL,
  payload: {
    error,
  },
});

const generateInvitationLinkStart = (): ActionTypes.GenerateInvitationLinkStartAction => ({
  type: MutationTypes.GENERATE_INVITATION_LINK_START,
  payload: {},
});

const generateInvitationLinkSucceed = (
  invitationLink: string,
): ActionTypes.GenerateInvitationLinkSucceedAction => ({
  type: MutationTypes.GENERATE_INVITATION_LINK_SUCCEED,
  payload: {
    invitationLink,
  },
});

export const generateInvitationLink = (
  userRoles: PrimaryKey[] = [],
  expirationDate: Date | null = null,
) => (dispatch: Dispatch, getState: any) => {
  return apiPost(API_ROUTES.INVITATION_SYSTEM.GENERATE_INVITATION_LINK, {
    ...(userRoles ? { user_roles: userRoles } : {}),
    ...(expirationDate ? { expiration_date: expirationDate } : {}),
  })
    .then((json: { invitation_link: string }) => {
      dispatch(generateInvitationLinkSucceed(json.invitation_link));
    })
    .catch((error: APIError) => {
      dispatch(generateInvitationLinkFail(error));
      throw error;
    });
};

export const resetInvitationLink = () => (dispatch: Dispatch, getState: any) =>
  new Promise((resolve, reject) => {
    dispatch({ type: MutationTypes.RESET_INVITATION_LINK });
    resolve();
  });

/////////////////////////////////////
// Register via invitation
/////////////////////////////////////

const registerViaInvitationFail = (error: Error): ActionTypes.RegisterViaInvitationFailAction => ({
  type: MutationTypes.REGISTER_VIA_INVITATION_FAIL,
  payload: {
    error,
  },
});

const registerViaInvitationSucceed = (): ActionTypes.RegisterViaInvitationSucceedAction => ({
  type: MutationTypes.REGISTER_VIA_INVITATION_SUCCEED,
  payload: {},
});

const registerViaInvitationStart = (): ActionTypes.RegisterViaInvitationStartAction => ({
  type: MutationTypes.REGISTER_VIA_INVITATION_START,
  payload: {},
});

const registerViaInvitation = (invitationLink: string, userData: Partial<User>) => (
  dispatch: Dispatch,
  getState: any,
) => {
  const reqPayload = {
    invitation_link: invitationLink,
    email: userData.email,
    password: userData.password,
    username: userData.username,
    first_name: userData.first_name,
    last_name: userData.last_name,
    info: userData.info,
    phone: userData.phone,
    skype: userData.skype,
    telegram: userData.telegram,
    discord: userData.discord,
  };
  return apiPost(API_ROUTES.INVITATION_SYSTEM.REGISTER_VIA_INVITATION, reqPayload)
    .then((json: {}) => {
      dispatch(registerViaInvitationSucceed());
    })
    .catch((error: APIError) => {
      dispatch(registerViaInvitationFail(error));
      throw error;
    });
};

export default {
  generateInvitationLink,
  registerViaInvitation,
  resetInvitationLink,
};
