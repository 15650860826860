import React from "react";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";

interface ServiceConnectedProps {}

interface ServiceOwnProps {}

type ServiceProps = ServiceConnectedProps & ServiceOwnProps;

const Service = (props: ServiceProps) => {
  return (
    <React.Fragment>
      <TransitionGroup>
        <CSSTransition
          classNames="mask"
          appear={true}
          timeout={{
            enter: 0,
            exit: 300,
          }}
        >
          <>
            <section className="content-header">
              <h1>
                Сервисы \ <small>Панель управления</small>
              </h1>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="fa fa-dashboard" /> Главная
                  </Link>
                </li>
                <li className="breadcrumb-item active">Панель управления</li>
              </ol>
            </section>

            <section className="content">
              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="box-header with-border">
                      <h4 className="box-title">Доступные сервисы (5)</h4>
                    </div>

                    <div className="box-body">
                      <div className="list-group">
                        <Link
                          to="/services/topvisor/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <h5 className="mb-1">Мониторинг</h5>
                            <small>Последнее обновление: давно</small>
                          </div>
                          <p className="mb-1">
                            Статистика изменений контента SEO - заголовки, содержимое страницы и т.д
                          </p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                        <Link
                          to="/services/top50/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex">
                              <h5 className="mb-1">TOP50</h5>
                            </div>
                            <small>Последнее обновление: 26 марта</small>
                          </div>
                          <p className="mb-1">Статистика поисковых запросов по ключевым словам</p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                        <Link
                          to="/services/contentplan/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex">
                              <h5 className="mb-1">Content-Plan</h5>
                            </div>
                            <small>Последнее обновление: 5 апреля</small>
                          </div>
                          <p className="mb-1">Статистика поисковых запросов по ключевым словам</p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                        <Link
                          to="/services/proxy/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex">
                              <h5 className="mb-1">Proxy</h5>
                            </div>
                            <small>Последнее обновление: 28 августа</small>
                          </div>
                          <p className="mb-1">
                            Управление списком прокси-серверов, получение случайного прокси,
                            мониторинг всех прокси-серверов, что есть в нашей системе
                          </p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                        <Link
                          to="/services/accounts/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex">
                              <h5 className="mb-1">Accounts</h5>
                            </div>
                            <small>Последнее обновление: 14 авгусста</small>
                          </div>
                          <p className="mb-1">
                            Управление аккаунтами различных сервисов, их ранжировка и фильтрация.
                          </p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                        <Link
                          to="/services/metrika/"
                          className="list-group-item list-group-item-action flex-column align-items-start"
                        >
                          <div className="d-flex w-100 justify-content-between">
                            <div className="d-flex">
                              <h5 className="mb-1">Metrika</h5>
                            </div>
                            <small>Последнее обновление: 19 сентября</small>
                          </div>
                          <p className="mb-1">
                            Работа с метрикой. Аудит и консалтинг запросов для топ 50.
                          </p>
                          <small className="text-muted">Откроется в текущем окне</small>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </>
        </CSSTransition>
      </TransitionGroup>
    </React.Fragment>
  );
};

export default Service;
