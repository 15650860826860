import {
  MonitoringProject,
  MonitoringProjectUrl,
  MonitoringState,
  StoreRootState,
} from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

const getMonitoring = (store: StoreRootState, props: any): MonitoringState => store.monitoring;
const getComponentProps = (store: StoreRootState, props: any): any => props;

export const getMonitoringProjectsById = createSelector(
  [getMonitoring],
  (monitoring: MonitoringState) => monitoring.projectsById,
);

export const getMonitoringProject = createSelector(
  [getMonitoring, getComponentProps],
  (monitoring: MonitoringState, props: any) =>
    _.get(monitoring.projectsById, props.match.params.id, null),
);

export const getMonitoringProjectUrls = createSelector(
  [getMonitoringProject, getComponentProps],
  (project: MonitoringProject, props: any) =>
    _.get<MonitoringProject, "urls", number[]>(project, "urls", [])
      .map((pk: number) =>
        _.get<MonitoringProject["urlsById"], any, any>(
          _.get<MonitoringProject, "urlsById", {}>(project, "urlsById", {}),
          pk,
          {},
        ),
      )
      .filter((a: MonitoringProjectUrl) => !!a),
);

export const getMonitoringProjectUrlsGroupedByCheckState = createSelector(
  [getMonitoringProjectUrls],
  (urls: MonitoringProjectUrl[]) => _.sortBy(urls, (u: MonitoringProjectUrl) => !u.check),
);

export const getMonitoringProjects = createSelector(
  getMonitoring,
  monitoring =>
    _.compact(monitoring.projects.map(projID => _.get(monitoring.projectsById, projID, null))),
);

export const isMonitoringProjectsLoading = createSelector(
  getMonitoring,
  monitoring => monitoring.loading,
);

export const getSitemonitoringWebSocketInstance = createSelector(
  getMonitoring,
  monitoring => monitoring.ws,
);

export const isSitemonitoringWebSocketClosed = createSelector(
  getSitemonitoringWebSocketInstance,
  (ws: any) => ws === null,
);

export const isCurrentlyCreatingProject = createSelector(
  getMonitoring,
  (monitoring: MonitoringState) => monitoring.creatingProject,
);

export const isCurrentlyDeletingProject = createSelector(
  getMonitoring,
  (monitoring: MonitoringState) => monitoring.deletingProject,
);
