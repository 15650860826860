import { apiDelete, APIError, apiGet, apiPost, apiPut, multipartApiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import TasksMutationType from "#/store/modules/tasks/mutationType";
import { CRMTask, GetState, PrimaryKey, ThunkResult, User, UserRole } from "#/store/types";
import { Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

///////////////////////////////////////////////
// Fetch users
///////////////////////////////////////////////

// Fetch users
const fetchProfilesListSucceed = (users: User[]): ActionTypes.FetchProfilesListSucceedAction => ({
  type: MutationType.FETCH_USERS_SUCCEED,
  payload: {
    users,
  },
});

const fetchProfilesListStart = (): ActionTypes.FetchProfilesListStartAction => ({
  type: MutationType.FETCH_USERS_FAIL,
});

const fetchProfilesListFail = (error: Error): ActionTypes.FetchProfilesListFailAction => ({
  type: MutationType.FETCH_USERS_FAIL,
  payload: {
    error,
  },
});

export const fetchProfilesList = (minimal = false): ThunkResult<Promise<User[]>> => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch(fetchProfilesListStart());
  return apiGet(API_ROUTES.CRMPLAN.MANAGERS, { minimal })
    .then((users: User[]) => {
      dispatch(fetchProfilesListSucceed(users));
      return users;
    })
    .catch((err: APIError) => {
      dispatch(fetchProfilesListFail(err));
      throw err;
    });
};

export const fetchRoles = () => (dispatch: Dispatch, getState: GetState) => {
  return apiGet(API_ROUTES.CRMPLAN.USER_ROLES)
    .then((roles: UserRole[]) => {
      dispatch({ type: MutationType.FETCH_USER_ROLES_SUCCEED, payload: { roles } });
    })
    .catch((err: APIError) => {
      throw err;
    });
};

// Create user
export const createUser = (payload: Partial<User>) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.CREATE_USER_START });
  return apiPost(API_ROUTES.CRMPLAN.MANAGERS, payload)
    .then((user: User) => {
      dispatch({ type: MutationType.CREATE_USER_SUCCEED, payload: user });
      return user;
    })
    .catch((err: Error) => {
      dispatch({ type: MutationType.CREATE_USER_FAIL });
      throw err;
    });
};

// Fetch full user info
const fetchUserInfo = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.FETCH_USER_INFO_START });
  return Promise.all([
    apiGet(API_ROUTES.CRMPLAN.MANAGERS_ITEM_INFO(id)).then((user: User) => {
      dispatch({
        type: MutationType.FETCH_USER_INFO_SUCCEED,
        payload: {
          id,
          user,
        },
      });
      return user;
    }),
    apiGet(API_ROUTES.CRMPLAN.USER_TASKS_FROM(id)).then((tasks: CRMTask[]) => {
      dispatch({
        type: TasksMutationType.FETCH_TASKS_SUCCEED,
        payload: {
          tasks,
        },
      });
      return tasks;
    }),
    apiGet(API_ROUTES.CRMPLAN.USER_TASKS_TO(id)).then((tasks: CRMTask[]) => {
      dispatch({
        type: TasksMutationType.FETCH_TASKS_SUCCEED,
        payload: {
          tasks,
        },
      });
      return tasks;
    }),
  ]).catch((error: Error) => {
    dispatch({
      type: MutationType.FETCH_USER_INFO_FAIL,
      payload: { id, error },
    });
    throw error;
  });
};

// Edit user
export const editUserInfo = (userId: PrimaryKey, payload: Partial<User>) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.EDIT_USER_START, payload: { id: payload.pk } });
  return apiPut(API_ROUTES.CRMPLAN.MANAGERS_ITEM(userId), payload)
    .then((user: User) => {
      dispatch({
        type: MutationType.EDIT_USER_SUCCEED,
        payload: { user, id: user.pk },
      });
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.EDIT_USER_FAIL,
        payload: { error, id: payload.pk },
      });
      throw error;
    });
};

export const editUserAvatar = (userId: PrimaryKey, payload: FormData) => (
  dispatch: Dispatch,
  getState: any,
) => {
  return multipartApiPost(API_ROUTES.CRMPLAN.EDIT_USER_AVATAR(userId), payload).then(
    (user: User) => {
      dispatch({ type: MutationType.EDIT_USER_SUCCEED, payload: { user, id: user.pk } });
    },
  );
};

// Remove user
export const removeUser = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.REMOVE_USER_START });
  return apiDelete(API_ROUTES.CRMPLAN.MANAGERS_ITEM(id))
    .then(response => {
      dispatch({ type: MutationType.REMOVE_USER_SUCCEED, payload: { id } });
    })
    .catch((error: Error) => {
      dispatch({ type: MutationType.REMOVE_USER_FAIL, error });
      throw error;
    });
};

// Fetch full user info
export const fetchFullUserInfoSucceed = (
  id: PrimaryKey,
  user: User,
): ActionTypes.FetchFullUserInfoSucceedAction => ({
  type: MutationType.FETCH_FULL_USER_INFO_SUCCEED,
  payload: {
    id,
    user,
  },
});

export const changeUserStatusToUser = (id: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.CRMPLAN.CHANGE_USER_STATUS_TO_USER(id))
    .then(() => {
      dispatch({ type: MutationType.CHANGE_USER_STATUS_TO_USER, payload: { userId: id } });
    })
    .catch((err: APIError) => {
      throw err;
    });

export const changeUserStatusToAdmin = (id: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.CRMPLAN.CHANGE_USER_STATUS_TO_ADMIN(id))
    .then(() => {
      dispatch({ type: MutationType.CHANGE_USER_STATUS_TO_ADMIN, payload: { userId: id } });
    })
    .catch((err: APIError) => {
      throw err;
    });

export const editUserProjects = (userId: PrimaryKey, projects: PrimaryKey[]) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.CRMPLAN.EDIT_USER_PROJECTS(userId), { projects })
    .then((editedProjects: PrimaryKey[]) => {
      dispatch({
        type: MutationType.EDIT_USER_PROJECTS,
        payload: { userId, projects: editedProjects },
      });
      return editedProjects;
    })
    .catch((err: APIError) => {
      throw err;
    });

export default {
  createUser,
  editUserInfo,
  editUserAvatar,
  fetchProfilesList,
  removeUser,
  fetchUserInfo,
  fetchFullUserInfoSucceed,
  changeUserStatusToUser,
  changeUserStatusToAdmin,
  fetchRoles,
  editUserProjects,
};
