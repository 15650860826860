import EntityDropdown from "#/components/EntityDropdown";
import { Entity, User } from "#/store/types";
import { userFullName } from "#/util";
import { useLocalUsersWithQuery } from "#/util/dataHooks";
import React from "react";
import { DropdownProps, DropdownToggleProps } from "reactstrap";

type UserDropdownProps = Omit<DropdownProps, "onClick"> & {
  onClick: (user: User) => void;
  toggleInnerContent: () => any;
  toggle: () => void;
  isOpen: boolean;
  dropdownToggleProps?: DropdownToggleProps;
};

const UserDropdown = (props: UserDropdownProps) => {
  return (
    <EntityDropdown
      {...props}
      isOpen={props.isOpen}
      toggle={props.toggle}
      noItemsMessage="Нет пользователей"
      onClick={(props.onClick as any) as (p: Entity) => void}
      entityDisplayFunction={(user_: any) => {
        const user = user_ as User;
        return userFullName(user);
      }}
      entityFetchWithQueryHook={useLocalUsersWithQuery}
      toggleInnerContent={props.toggleInnerContent}
    />
  );
};

export default UserDropdown;
