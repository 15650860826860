import { createReducer } from "#/store/helpers";
import { EmptyAction, Top50State } from "#/store/types";
import { Action } from "redux";
import MutationType from "./mutationType";

export const initialState: Top50State = {
  queryDataCollectionRunning: false,
  queryDataCollectionFailed: false,
};

export const reducer = createReducer<Top50State, Action>(
  {
    [MutationType.QUERY_DATA_COLLECTION_START]: (state: Top50State, action: EmptyAction) => ({
      ...state,
      queryDataCollectionRunning: true,
      queryDataCollectionFailed: false,
    }),
    [MutationType.QUERY_DATA_COLLECTION_FAIL]: (state: Top50State, action: EmptyAction) => ({
      ...state,
      queryDataCollectionRunning: false,
      queryDataCollectionFailed: true,
    }),
    [MutationType.QUERY_DATA_COLLECTION_SUCCEED]: (state: Top50State, action: EmptyAction) => ({
      ...state,
      queryDataCollectionRunning: false,
      queryDataCollectionFailed: false,
    }),
  },
  initialState,
);

export default reducer;
