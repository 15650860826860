//////////////////////////////////////////
// Common action types
//////////////////////////////////////////

import { AccountsState } from "#/store/modules/accounts/types";
import { MetrikaState } from "#/store/modules/metrika/types";
import { ThunkAction } from "redux-thunk";
import { TaskPriority } from "#/taskPriority";

export interface Action {
  type: string;
}

export interface CommonFailAction extends Action {
  payload: {
    error: Error;
  };
}

export interface EmptyAction extends Action {}

//////////////////////////////////////////
// Global Types
//////////////////////////////////////////

export type SubmitEvent = React.FormEvent<HTMLFormElement>;

export type PaginationCursor = string | null;

//////////////////////////////////////////
// Data types
//////////////////////////////////////////

export enum TaskStatus {
  CREATED = 1,
  ONGOING = 2,
  TEST = 3,
  REVISION = 4,
  FINISHED = 5,
  DELAYED = 6,
}

export interface UserRole {
  pk: PrimaryKey;
  name: string;
}

export type PrimaryKey = number;

export interface MonitoringProjectUrl {
  pk: PrimaryKey;
  name: string;
  check: boolean;
  project: number;
}

export interface MonitoringProjectData {
  pk: PrimaryKey;
  name: string;
  last_checked: string;
  last_amount_of_urls_checked: number;
  filters: string;
  base_project: PrimaryKey;
  base_project_model: CRMProject;
  current_user_subscribed: boolean;
  script_running: boolean;
}

export interface MonitoringProject {
  data?: MonitoringProjectData;
  checkedurls_dates: string[];
  urlsById: Record<PrimaryKey, MonitoringProjectUrl>;
  urls: PrimaryKey[];
  loading: boolean;
  loadingFailed: boolean;
  checkProgress: number;
  checkedUrls?: {
    loading: boolean;
    loadingFailed: boolean;
    data: CheckedUrl[];
  };
  removingUrls: boolean;
  removingUrlsFailed: boolean;
  addingUrls: boolean;
  addingUrlsFailed: boolean;
  scriptRunning: boolean;
  checkProgressFailed: boolean;
  changingFilters: boolean;
  changingFiltersFailed: boolean;
}

export interface MonitoringProjectCheckedUrl {
  pk: PrimaryKey;
  name: string;
  status: number;
  datecheck: string;
  url: string;
  url_m: MonitoringProjectUrl;
  title: string;
  titlechanged: string;
  description: string;
  descriptionchanged: string;
  h1: string;
  h1changed: string;
  code: string;
  code_diff: string;
  content: string;
  content_diff: string;
}

export declare namespace EventSystem {
  export type Event =
    | "SAVE"
    | "SELECT_ALL"
    | "RUN_MONITORING"
    | "TOGGLE_SIDEBAR"
    | "FOCUS_SEARCH"
    | "OPEN_DASHBOARD"
    | "GOTO_PROJECT"
    | "GOTO_USER"
    | "ADD_MONITORING_URLS";
  export type EventHandler = () => void;
}

export type CheckedUrl = any;

export interface User {
  pk: PrimaryKey;
  avatar: File | string | null;
  email: string;
  password: string;
  username: string;
  first_name: string;
  last_name: string;
  skype: string;
  info: string;
  phone: string;
  telegram: string;
  discord: string;
  hash: string;
  role: PrimaryKey[];
  admin: boolean;
  super_admin: boolean;
  minutes_fullfilled: number;
  date_joined: string;
  projects: PrimaryKey[];
  tasks_to: PrimaryKey[];
  tasks_from: PrimaryKey[];
}

export interface CRMProject {
  pk: PrimaryKey;
  name: string;
  description: string;
  creator: PrimaryKey;
  creator_model: User;
  active_tasks: number;
  create_date: string;
  users: PrimaryKey[];

  date_seo: string;
  url: string;
  url_admin: string;
  ya_m_access: string;
  google_a_access: string;
  google_s_access: string;
  phone_access: string;
  rk_access: string;
}

export type UserProfile = User;

export interface TaskCheckListItem {
  pk: PrimaryKey;
  status: boolean;
  name: string;
  finish_date: Date;
  task: PrimaryKey;
}

export interface ChatMessage {
  pk: PrimaryKey;
  sender: PrimaryKey;
  content: string;
  date_create: string;
  task: PrimaryKey;
}

export type TaskChecklists = TaskCheckListItem[];

export type TaskChat = ChatMessage[];

export type APIDate = Date | string;

export type APIOptionalDate = APIDate | null;

export interface TaskProgressInfo {
  total: number;
  completed: number;
}

export interface CRMTask {
  pk: PrimaryKey;
  name: string;
  shared: boolean;
  status: TaskStatus;
  task_from: PrimaryKey;
  task_to: PrimaryKey | null;
  project: PrimaryKey;
  description: string;
  priority: TaskPriority;
  create_date: APIDate;
  finish_date: APIOptionalDate;
  execution_time: string;
  execution_start_time: APIOptionalDate;
  executor_minutes_to_complete: number | null;
  manager_minutes_to_complete: number | null;
  agreed_minutes_to_complete: number | null;
  completed_date: APIOptionalDate;
  tags: string[];
  progress?: TaskProgressInfo;
  subscribed?: boolean;
  role: PrimaryKey;
  status_comment: string;
}

export type TaskChatMessage = any;

export interface ContentPlanProject {
  pk: PrimaryKey;
  name: string;
  description: string;
  create_date: Date;
  apisource: string;
  active: boolean;
  last_fetch: string;
  base_project: PrimaryKey;
  base_project_model: CRMProject;
}

export interface ContentPlanEvent {
  pk: PrimaryKey;
  name: string;
  wordstat: string;
  query: string;
  link: string;
  links: boolean;
  status: string;
  short_text: boolean;
  tech_info: boolean;
  sujet_text: boolean;
  video: boolean;
  seo_text: boolean;
  reviews: boolean;
  image: string;
  comment: string;
  position: boolean;
}

export interface Proxy {
  pk: PrimaryKey;
  name: string;
  label: string;
  ip: string;
  port: string;
  login: string;
  password: string;
  kind: number;
  type_value?: number;
  expiration_date: string;
}

export interface Region {
  pk: PrimaryKey;
  lr: number;
  name: string;
  general_region: string;
}

export interface Top50SearchEngine {
  pk: PrimaryKey;
  name: string;
}

export interface Top50ProjectConfiguration {
  pk: PrimaryKey;
  region: PrimaryKey;
  searchEngine: PrimaryKey;
}

export interface Top50Configuration {
  pk: PrimaryKey;
  search_engine: PrimaryKey;
  search_engine_model?: Top50SearchEngine;
  region: PrimaryKey;
  region_model?: Region;
}

export interface Top50Project {
  pk: PrimaryKey;
  title: string;
  favicon: string;
  metrika_id: number;
  webmaster_id: string;
  base_project: PrimaryKey;
  base_project_model: CRMProject;
  configurations?: Top50Configuration[];
}

export type Top50PositionsStatisticsGraph = Array<{
  // Date of check
  date: string;

  // Check results
  top_3: number;
  top_10: number;
  top_30: number;
}>;

export interface Top50QueryPage {
  pk: PrimaryKey;
  query: PrimaryKey;
  query_model?: Top50Query;
  url: string | null;
  count: number;
  event?: PrimaryKey;
  event_model?: Top50Event;
  project?: PrimaryKey;
  project_model?: Top50Project;
  pos_diff?: number;
}

export interface Top50Query {
  pk: PrimaryKey;
  text: string;
  wordstat: number;
  wordstat1: number;
  wordstat2: number;
  tag: string;
  qpages: Top50QueryPage[];
  url: string;
  qp_pk: PrimaryKey;
  event: Top50Event;
  position: number | string;
  positions: any[];
  pos_url: string;
  pos0?: number;
  pos1?: number;
  pos2?: number;
  pos3?: number;
  pos4?: number;
  pos5?: number;
  pos6?: number;
  dynamic_position_1?: number;
  dynamic_position_2?: number;
}

export interface Top50Event {
  pk: PrimaryKey;
  name: string;
  category: string;
  queries: PrimaryKey[];
  startdate: Date;
  finishdate: Date;
  active: boolean;
  expired?: boolean;
  queries_amount?: number;
}

export type Top50EventFullData = Omit<Top50Event, "queries"> & {
  queries: Top50Query[];
};

export enum SortType {
  DESC = "desc",
  ASC = "asc",
}

export type Top50Url = string;

export interface Top50QueryPosition {
  urls_json: Top50Url[];
  pub_date: Date;
}

export interface UserData {
  data: User | null;
  loading: boolean;
  loadingFailed: boolean;
}

export type UsersById = Record<PrimaryKey, UserData>;
export type UsersByIdBare = Record<PrimaryKey, User>;

export type UserRolesById = Record<PrimaryKey, UserRole>;

export interface UsersState {
  // Users
  usersById: UsersById;
  users: PrimaryKey[];

  loadingUsers: boolean;
  loadingUsersFailed: boolean;

  removingUser: boolean;
  removingUserFailed: boolean;

  creatingUser: boolean;
  creatingUserFailed: boolean;

  editingUser: boolean;
  editingUserFailed: boolean;

  // Roles
  rolesById: UserRolesById;
  roles: PrimaryKey[];
}

export interface TaskData {
  data: CRMTask | null;
  editing: boolean;
  editingFailed: boolean;
  checklists: TaskCheckListItem[];
  chat: ChatMessage[];
  chatPrev: PaginationCursor;
  chatNext: PaginationCursor;
}

export interface CompleteTaskData extends TaskData {
  data: CRMTask;
  checklists: TaskCheckListItem[];
}

export type TasksById = Record<PrimaryKey, TaskData>;

export type TasksByIdBare = Record<PrimaryKey, CRMTask>;

export interface TasksState {
  tasksById: TasksById;
  fetchingTasks: boolean;
  fetchingTasksFailed: boolean;
  removingTask: boolean;
  removingTaskFailed: boolean;
}

export interface CRMProjectData {
  data: CRMProject | null;
  users: PrimaryKey[];
  editing: boolean;
  editingFailed: boolean;
}

export type ProjectsById = Record<PrimaryKey, CRMProjectData>;

export type ProjectsByIdBare = Record<PrimaryKey, CRMProject>;

export interface ProjectState {
  projectId: PrimaryKey;
  fetching: boolean;
  fetchingFailed: boolean;
}

export interface ProjectsState {
  projectsById: ProjectsById;
  fetching: boolean;
  fetchingFailed: boolean;
  creating: boolean;
  creatingFailed: boolean;
  editing: boolean;
  editingFailed: boolean;
}

export interface UIState {
  isTextNotificationActive: boolean;
  isAudioNotificationActive: boolean;
  isActionsListShown: boolean;
  isDirectionsListShown: boolean;
}

export interface Top50State {
  queryDataCollectionRunning: boolean;
  queryDataCollectionFailed: boolean;
}

export interface TaskState {
  taskId: PrimaryKey | null;
  fetching: boolean;
  fetchingFailed: boolean;
  taskChatWebSocket: WebSocket | null;
}

export type SitemonitoringProjectsById = Record<PrimaryKey, MonitoringProject>;

export interface MonitoringState {
  projectsById: SitemonitoringProjectsById;
  projects: PrimaryKey[];
  loading: boolean;
  loadingFailed: boolean;
  creatingProject: boolean;
  projectCreationFailed: boolean;
  deletingProject: boolean;
  deletingProjectFailed: boolean;
  ws: any;
}

export type AuthToken = string | null;

export interface SessionState {
  currentUserId: PrimaryKey | null;
  authenticating: boolean;
  loginErrMsg: string | null;
  token: AuthToken;
  isTextNotificationActive: boolean;
  isAudioNotificationActive: boolean;
  isActionRunning: boolean;
  fetching: boolean;
  fetchingCurrentUserInfo: boolean;
  fetchingCurrentUserInfoFailed: boolean;
  loadingUserNotifications: boolean;
  loadingUserNotificationsFailed: boolean;
  notifications: UserNotificationDerived[];
  notificationsNext: PaginationCursor;
  notificationsPrev: PaginationCursor;
  notificationsUnread: number;
}

export interface SessionTasksState {
  tasksToUser: PrimaryKey[];
  tasksFromUser: PrimaryKey[];
}

export type Keybindings = Record<string, EventSystem.Event>;

export interface KeybindingSystemState {
  keybindings: Keybindings;
}

export interface InvitationSystemState {
  invitationLink: string | null;
  generating: boolean;
  generationFailed: boolean;
  registering: boolean;
  registrationFailed: boolean;
}

export interface EventSystemState {
  events: Record<EventSystem.Event, Record<string, EventSystem.EventHandler>>;
}

export interface MainWSState {
  ws: WebSocket | null;
}

export interface ProjectTasksState {
  tasks: PrimaryKey[];
}

export interface SearchState {
  query: string | null;
}

export interface UserState {
  userId: PrimaryKey | null;
}

export interface StoreRootState {
  router: any;
  eventSystem: EventSystemState;
  invitationSystem: InvitationSystemState;
  keybindingSystem: KeybindingSystemState;
  project: ProjectState;
  projects: ProjectsState;
  qwerty: any;
  monitoring: MonitoringState;
  task: TaskState;
  tasks: TasksState;
  ui: UIState;
  users: UsersState;
  top50: Top50State;
  mainWS: MainWSState;
  sessionTasks: SessionTasksState;
  accounts: AccountsState;
  projectTasks: ProjectTasksState;
  user: UserState;
  metrika: MetrikaState;
  search: SearchState;
}

//////////////////////////////////////////////////////
// Notifications
//////////////////////////////////////////////////////

export interface UserNotification {
  pk: PrimaryKey;
  create_date: string;
  kind: string;
  read: boolean;
}

export interface UserNotificationDerived {
  pk: PrimaryKey;
  notification_model: UserNotification;
  kind: string;
}

export interface UserTaskDeadlineComingNotificationTask extends Partial<CRMTask> {
  pk: CRMTask["pk"];
  name: CRMTask["name"];
}

export interface UserTaskDeadlineComingNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserTaskDeadlineComingNotificationTask;
}

export interface UserNewTaskMessageNotificationTask extends Partial<CRMTask> {
  pk: CRMTask["pk"];
  name: CRMTask["name"];
}

export interface UserNewTaskMessageNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserNewTaskMessageNotificationTask;
}

export interface UserTaskWasUpdatedNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserNewTaskMessageNotificationTask;
}

export interface UserTaskWasAssignedToYouNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserNewTaskMessageNotificationTask;
}

export interface UserTaskWasAssignedNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserNewTaskMessageNotificationTask;
}

export interface TaskIsCompletedNotification extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: UserNewTaskMessageNotificationTask;
}

export interface TaskStatusChangedNotificationTask extends Partial<CRMTask> {
  pk: CRMTask["pk"];
  name: CRMTask["name"];
  status: CRMTask["status"];
}

interface UserTaskNotificationDerived extends UserNotificationDerived {
  task: PrimaryKey;
  task_model: TaskStatusChangedNotificationTask;
}

export interface TaskStatusChangedNotification extends UserTaskNotificationDerived {
  new_status: TaskStatus;
}

export interface TaskWithNoExecWasCreatedNotification extends UserNotificationDerived {
  task_model: {
    pk: PrimaryKey;
    name: string;
    project: PrimaryKey;
  };
}

export type GetState = () => StoreRootState;

export type EditUserProjects = (
  userId: PrimaryKey,
  projects: PrimaryKey[],
) => Promise<PrimaryKey[]>;

export type AddMonitoringURLs = (
  projectId: PrimaryKey,
  urls: string[],
) => Promise<MonitoringProject>;

export type UnsubscribeFromMonitoringProject = (id: PrimaryKey) => Promise<boolean>;

export type SubscribeToMonitoringProject = (id: PrimaryKey) => Promise<boolean>;

export type ToggleMonitoringProjectSubscription = (id: PrimaryKey) => Promise<boolean>;

export interface MProjectCheckSuccessfulNotification extends UserNotificationDerived {
  project_model: MonitoringProjectData;
  urls_checked: number;
}

export type FetchUserInfo = (userId: PrimaryKey) => Promise<User>;

export type EditUserInfo = (userId: PrimaryKey, payload: Partial<User>) => Promise<User>;

export type EditUserAvatar = (userId: PrimaryKey, payload: FormData) => Promise<User>;

export type EditTask = (payload: Partial<CRMTask>) => Promise<CRMTask>;

export type RemoveTask = (pk: PrimaryKey) => Promise<boolean>;

export type FetchCurrentTaskChatMessages = () => Promise<ChatMessage[]>;

export type SetupCurrentTaskChatWebSocket = () => Promise<boolean>;

export type CloseCurrentTaskChatWebSocket = () => Promise<boolean>;

export type SendTaskChatMessage = (
  taskId: PrimaryKey,
  payload: Partial<ChatMessage>,
) => Promise<boolean>;

export type BecomeTaskExecutor = (taskId: PrimaryKey) => Promise<CRMTask>;

export type FetchCurrentUserNotifications = () => Promise<boolean>;

export enum SearchKind {
  USERS = 0,
  PROJECTS = 1,
  TASKS = 2,
  ALL = 3,
}

export interface SearchParams {
  kind: SearchKind;
}

export type SearchItem = User | CRMProject | CRMTask;

export interface SearchResult {
  kind: SearchKind;
  items: SearchItem[];
  total_items: number;
  next: PaginationCursor;
}

export type MakeSearchRequest = (
  query: string | null,
  searchParams: SearchParams,
  nextCursor: PaginationCursor,
) => Promise<SearchResult>;

export type Logout = () => Promise<boolean>;

export type RetreiveTagSuggestions = (text: string | null) => Promise<string[]>;

export interface MProjectSubscriptionInfo {
  pk: PrimaryKey;
  name: string;
  active: boolean;
}

export interface TaskSubscriptionInfo {
  pk: PrimaryKey;
  name: string;
  active: boolean;
}

export interface UserSettings {
  mprojects_subscriptions: MProjectSubscriptionInfo[];
  task_subscriptions: TaskSubscriptionInfo[];
}

export type FetchCurrentUserFullSettings = () => Promise<UserSettings>;

export type ToggleCurrentUserTaskSubscription = (taskId: PrimaryKey) => Promise<boolean>;

type Actions = any;

export type ThunkResult<R> = ThunkAction<R, StoreRootState, undefined, Actions>;

export type DispatchProp<R extends (...args: any[]) => any> = (
  ...args: Parameters<R>
) => ReturnType<ReturnType<R>>;

export type Entity = User | CRMProject;

export type OptionalPrimaryKey = PrimaryKey | null;
