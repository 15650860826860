import ProjectSelect from "#/components/ProjectSelect";
import projectsModule from "#/store/modules/projects";
import {
  ContentPlanProject,
  CRMProject,
  OptionalPrimaryKey,
  PrimaryKey,
  StoreRootState,
  SubmitEvent,
} from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface CreateProjectConnectedProps {}

interface CreateProjectOwnProps {
  onCreateProject: (payload: Partial<ContentPlanProject>) => Promise<ContentPlanProject>;
  isCurrentlyCreatingProject: boolean;
  isOpen: boolean;
  toggle: any;
  projects: CRMProject[];
}

type CreateProjectProps = CreateProjectConnectedProps & CreateProjectOwnProps;

const CreateProject = (props: CreateProjectProps) => {
  const { isOpen, toggle } = props;
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [apisource, setApiSource] = useState("");
  const [selectedLinkedProjectID, setSelectedLinkedProjectID] = useState<PrimaryKey | null>(null);
  const handleFormSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    if (!name) { return null; }
    if (!description) { return null; }
    if (!apisource) { return null; }
    if (!selectedLinkedProjectID) { return null; }
    props
      .onCreateProject({
        name,
        description,
        apisource,
        base_project: selectedLinkedProjectID,
      })
      .then(() => {
        setName("");
        setDescription("");
        setApiSource("");
        setSelectedLinkedProjectID(null);
        toggle();
      })
      .catch(handleDispatchErrorAndDisplayToast);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader>Создать проект ContentPlan</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        <ModalBody>
          <FormGroup>
            <div className="d-flex">
              <label className="my-1">Название проекта</label>
            </div>

            <input
              className="form-control"
              value={name}
              onChange={e => setName(e.target.value)}
              placeholder="Название"
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <div className="d-flex">
              <label className="my-1">Описание проекта</label>
            </div>

            <textarea
              className="form-control"
              value={description}
              onChange={e => setDescription(e.target.value)}
              rows={4}
              placeholder="Описание"
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <div className="d-flex">
              <label className="my-1">Источник (URL)</label>
            </div>
            <input
              className="form-control"
              value={apisource}
              onChange={e => setApiSource(e.target.value)}
              placeholder="Ссылка"
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <div className="d-flex">
              <label className="my-1">Связанный проект</label>
            </div>
            <ProjectSelect
              projects={props.projects}
              value={selectedLinkedProjectID}
              onChange={(id: OptionalPrimaryKey) => setSelectedLinkedProjectID(id)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button
            type="submit"
            className="btn btn-success"
            disabled={props.isCurrentlyCreatingProject}
          >
            {props.isCurrentlyCreatingProject ? "Подождите..." : "Продолжить"}
          </button>
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            Отмена
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default connect((store: StoreRootState) => ({
  projects: projectsModule.selectors.getAllProjectsBare(store, null),
}))(CreateProject);
