import Checkbox from "#/components/Checkbox";
import DebouncedInput from "#/components/DebouncedInput";
import Loader from "#/components/Loader";
import TableCheckbox from "#/components/TableCheckbox";
import cn from "classnames";
import _ from "lodash";
import * as moment from "moment";
import React from "react";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import styled, { use, css } from "reshadow";
import style from "../../index.module.scss";
import QueryListContext, { IQueryListContext } from "../../QueryListContext";

const EventItem = (props: {}) => {
  const context = React.useContext(QueryListContext) as IQueryListContext;
  const { loadingActiveEvent, queriesById } = context;
  const activeGroup = context.activeGroup!;

  const tableColumns = React.useMemo(
    () => [
      {
        accessor: "checked",
        cellClassName: "minimum-column",
        className: "minimum-column vertical-align-middle",
        Header: () => (
          <Checkbox
            onChange={context.querySelection.toggleAll}
            checked={context.querySelection.areAllItemsSelected}
          />
        ),
        Cell: ({ row, querySelection }) => {
          const item = row.original;
          const selected = row.original.checked;
          return (
            <TableCheckbox
              onChange={() => {
                querySelection.toggleItemSelection(item.pk);
              }}
              checked={selected}
            />
          );
        },
      },

      {
        accessor: "text",
        className: "flex-vertically-centered-x justify-content-between",
        Header: () => (
          <>
            <span>Запрос</span>
          </>
        ),
      },
      {
        Header: "WORDSTAT",
        accessor: "wordstat",
        className: "vertical-align-middle",
        Cell: ({ row }) => (row.original.wordstat ? row.original.wordstat : "-"),
      },
      {
        Header: '"',
        accessor: "wordstat1",
        className: "vertical-align-middle",
        Cell: ({ row }) => (row.original.wordstat1 ? row.original.wordstat1 : "-"),
      },
      {
        Header: "!",
        accessor: "wordstat2",
        className: "vertical-align-middle",
        Cell: ({ row }) => (row.original.wordstat2 ? row.original.wordstat2 : "-"),
      },
    ],
    [context.querySelection],
  );

  const queries = React.useMemo(() => {
    return activeGroup
      ? _.compact(activeGroup.queries.map(pk => _.get(queriesById, pk, null)))
      : [];
  }, [activeGroup, queriesById]);

  const tableData = React.useMemo(() => {
    const data = queries.map(q => ({
      ...q,
      checked: context.querySelection.isItemSelected(q.pk),
    }));
    return data;
  }, [queries, context.querySelection]);

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    [],
  );

  const table = useTable(
    {
      columns: tableColumns,
      data: tableData,
      filterTypes,
      autoResetPage: false,
      querySelection: context.querySelection,
    },
    useGlobalFilter,
    useSortBy,
  );

  const NoItemsMessage = React.useCallback(
    () =>
      styled(css`
        tr {
          pointer-events: none;
        }
        message {
          min-height: 50px;
        }
      `)(
        <tr>
          <td colSpan={tableColumns.length}>
            <message as="div" className="w-100 d-flex justify-content-center align-items-center">
              Пусто
            </message>
          </td>
        </tr>,
      ),
    [tableColumns],
  );

  return (
    <div className="col-9">
      {activeGroup ? (
        <>
          <div className={style["group-info-header"]}>
            <h5 className="mb-0">{`Группа "${activeGroup.name}"`}</h5>
            <div
              className={style["group-exp-date"]}
              title={
                activeGroup.finishdate === null
                  ? "Группа не имеет указанного срока истечения"
                  : "После этой даты данная группа запросов не будет обрабатываться системой"
              }
            >
              {activeGroup.finishdate === null ? (
                <span>Общая группа</span>
              ) : (
                <>
                  <span className="mr-1">активно до</span>
                  <span>
                    {(() => {
                      moment.locale("ru");
                      const dd = (moment as any)(activeGroup.finishdate).format(
                        "D MMMM YYYY, HH:mm",
                      );
                      return dd;
                    })()}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Event queries table */}
          <DebouncedInput
            className="fz-13 form-control mb-2 w-100"
            placeholder="Фильтр"
            onChange={e => table.setGlobalFilter(e)}
            ms={200}
          />
          {loadingActiveEvent ? (
            <Loader />
          ) : (
            <table className="table table-hover table-bordered table-group-queries">
              <thead>
                {table.headerGroups.map(headerGroup => {
                  return (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => {
                        return (
                          <th
                            {...column.getHeaderProps([
                              {
                                ...column.getSortByToggleProps(),
                                className: cn([
                                  column.className,
                                  cn({
                                    "cursor-pointer": column.canSort,
                                    "disable-user-select": true,
                                  }),
                                ]),
                                style: column.style,
                              },
                            ])}
                          >
                            {column.render("Header")}
                            {column.isSorted ? (
                              <span className="ml-1">
                                {column.isSortedDesc ? (
                                  <i className="fa fa-caret-down" />
                                ) : (
                                  <i className="fa fa-caret-up" />
                                )}
                              </span>
                            ) : (
                              ""
                            )}
                          </th>
                        );
                      })}
                    </tr>
                  );
                })}
              </thead>
              <tbody>
                {table.rows.length > 0 ? (
                  table.rows.map((row, i) => {
                    table.prepareRow(row);
                    const item = row.original;
                    return (
                      <tr
                        {...row.getRowProps()}
                        className={cn({
                          [style["query-list-item"]]: true,
                        })}
                      >
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps([
                                {
                                  className: cell.column.cellClassName,
                                },
                              ])}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <NoItemsMessage />
                )}
              </tbody>
            </table>
          )}
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center h-100">
          Группа запросов не выбрана
        </div>
      )}
    </div>
  );
};

export default EventItem;
