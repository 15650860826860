import { dateObjectToAPICompatible, changeSheduledDays } from "#/api";
import DatePicker from "#/components/DatePicker";
import Editor from "#/components/Editor";
import Checkbox from "#/components/Checkbox";
import ProjectSelect from "#/components/ProjectSelect";
import TagsListEditable from "#/components/TagsList/TagsListEditable";
import TaskChecklistBare from "#/components/TaskChecklist/TaskChecklistBare";
import ControllableTaskPrioritySelect from "#/components/TaskPrioritySelect";
import UserRoleSelect from "#/components/UserRoleSelect";
import { TaskPeriods, TaskType, WeekDays } from "#/conf/strings";
import projectProjectsModule from "#/store/modules/projectProjects";
import projectsModule from "#/store/modules/projects";
import tasksModule from "#/store/modules/tasks";
import { createTask } from "#/store/modules/tasks/actions";
import usersModule from "#/store/modules/users";
import {
  APIOptionalDate,
  CRMProject,
  CRMTask,
  DispatchProp,
  PrimaryKey,
  ProjectsById,
  StoreRootState,
  TaskCheckListItem,
  User,
} from "#/store/types";
import {
  compareTwoNumbers,
  constructTaskTagSearchRoute,
  handleDispatchErrorAndDisplayToast,
  isUserApplicableToBeTaskExecutor,
} from "#/util";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import UserSelect from "../UserSelect";
import "./index.scss";
import ControllableTaskTypeSelect from "../TaskTypeSelect/ControllableTaskTypeSelect";
import { TaskPriority } from "#/taskPriority";
import { useModal } from "#/util/hooks";

const ActionConfirmationModal = (props: {
  isOpen: boolean;
  toggle: () => void;
  onOk: () => void;
  onCancel?: () => void;
  confirmationText: string;
  yesText?: string;
  noText?: string;
}) => {
  const onConfirmClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      props.onOk();
      props.toggle();
    },
    [props.onOk, props.toggle],
  );
  const onCancelClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (props.onCancel) {
        props.onCancel();
      }
      props.toggle();
    },
    [props.onOk, props.toggle],
  );
  return (
    <Modal isOpen={props.isOpen}>
      <ModalHeader>{props.confirmationText}</ModalHeader>
      <ModalFooter>
        <Button color="primary" onClick={onConfirmClick}>
          {props.yesText ? props.yesText : "Да"}
        </Button>
        <Button color="secondary" onClick={onCancelClick}>
          {props.noText ? props.noText : "Нет"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

let __currFakeChecklistItemID = 0;
const nextFakeChecklistItemID = () => {
  const prev = __currFakeChecklistItemID;
  __currFakeChecklistItemID++;
  return prev;
};

export interface CreateTaskOwnProps {
  preselectedProject?: PrimaryKey;
  isShown: boolean;
  toggleModal: () => Promise<unknown>;
}

interface CreateTaskConnectedProps extends RouteComponentProps<{}> {
  createTask: DispatchProp<typeof createTask>;
  push: typeof push;
  users: User[];
  projects: CRMProject[];
  currentProjectSelected: CRMProject | null;
  projectCreationInProcess: boolean;
  projectsById: ProjectsById;
}

type CreateTaskProps = CreateTaskOwnProps & CreateTaskConnectedProps;

const CreateTask = (props: CreateTaskProps) => {
  // Task data
  interface CreateTaskState {
    name: string;
    project_id: PrimaryKey | null;
    task_to_id: PrimaryKey | null;
    finish_date: APIOptionalDate;
    manager_minutes_to_complete: number | null;
    description: string;
    priority: TaskPriority | null;
    role: PrimaryKey | null;
    task_type: TaskType | null;
    task_scheduled_days: number[];
    task_period_type: string | null;
    task_daily_period_time: string | null;
    shared: boolean;
    tags: string[];
  }

  const initialTaskState: CreateTaskState = {
    name: "",
    project_id: props.preselectedProject ? props.preselectedProject : null,
    task_to_id: null,
    finish_date: null,
    manager_minutes_to_complete: null,
    description: "",
    priority: null,
    role: null,
    task_type: TaskType.SINGLE,
    task_scheduled_days: [],
    task_period_type: null,
    task_daily_period_time: null,
    shared: false,
    tags: [],
  };
  const [task, setTask] = useState(initialTaskState);

  // Show validation problems in the form
  const [showValidationProblems, setShowValidationProblems] = useState<boolean>(false);

  useEffect(() => {
    if (props.preselectedProject) {
      setTask({ ...task, project_id: props.preselectedProject });
    }
  }, [props.preselectedProject]);

  // Checklist data
  const initialChecklist: Array<Partial<TaskCheckListItem>> = [];
  const [checklist, setChecklist] = useState(initialChecklist);

  const resetTaskToBeCreatedData = () =>
    new Promise(resolve => {
      setTask(initialTaskState);
      setChecklist(initialChecklist);
      resolve();
    });

  const [displayWithoutExecutorConfirmation, setDisplayWithoutExecutorConfirmation] = useState(
    false,
  );
  const [confirmedWithoutExecutor, setConfirmedWithoutExecutor] = useState(false);

  const createTaskWithoutCheck = () =>
    props
      .createTask((task as any) as CRMTask, checklist)
      .then((createdTask: CRMTask) => {
        resetTaskToBeCreatedData().then(() => {
          setShowValidationProblems(false);
          return props.toggleModal().then(() => props.push(`/task/${createdTask.pk}`));
        });
      })
      .catch(handleDispatchErrorAndDisplayToast);

  // When it is confirmed that the task can be created without a specified executor
  useEffect(() => {
    if (confirmedWithoutExecutor) {
      createTaskWithoutCheck();
    }
  }, [confirmedWithoutExecutor]);

  // Checklist item event actions
  const onItemToAddSave = (items: string[]) =>
    new Promise(resolve => {
      setChecklist([
        ...checklist,
        ...items.map((item: string) => ({
          pk: nextFakeChecklistItemID(),
          name: item,
          status: false,
        })),
      ]);
      resolve();
    });

  const onItemRemove = (item: TaskCheckListItem) =>
    new Promise(resolve => {
      setChecklist(checklist.filter(ci => ci.pk !== item.pk));
      resolve();
    });

  const onItemEdit = (newItem: Partial<TaskCheckListItem>) => {
    return new Promise(resolve => {
      const oldItemData = _.find(checklist, a => a.pk === newItem.pk) || null;
      if (oldItemData === null) {
        return;
      }
      setChecklist([
        ...checklist.filter(ci => ci.pk !== newItem.pk),
        {
          ...oldItemData,
          ...newItem,
        },
      ]);
      resolve();
    });
  };

  const onItemToggle = (item: TaskCheckListItem) => {
    return new Promise(resolve => {
      const oldItemData = _.find(checklist, a => a.pk === item.pk) || null;
      if (oldItemData === null) {
        return;
      }
      setChecklist([
        ...checklist.filter(ci => ci.pk !== item.pk),
        {
          ...oldItemData,
          status: !oldItemData.status,
          finish_date: !oldItemData.status ? new Date() : null,
        } as any,
      ]);
      resolve();
    });
  };

  const selectedProjectUserIds = useMemo(() => {
    if (task.project_id) {
      const project = _.get(props.projectsById, task.project_id, null);
      return project ? project.users : [];
    }
    return [];
  }, [task]);

  const taskNameValid = task.name && task.name.length > 0;
  const taskDescriptionValid = task.description && task.description.length > 0;
  const taskProjectValid = task.project_id !== null;
  const taskRoleValid = task.role !== null;
  const taskExecutorValid = true;
  const taskFinishDateValid = task.task_to_id ? !!task.finish_date : true;
  const taskPriorityValid = task.priority !== null;
  const somethingIsInvalid = !_.every([
    taskNameValid,
    taskDescriptionValid,
    taskProjectValid,
    taskRoleValid,
    taskExecutorValid,
    taskFinishDateValid,
    taskPriorityValid,
  ]);

  // On submit
  const onSubmit = (e: React.MouseEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowValidationProblems(true);
    if (somethingIsInvalid) {
      return;
    }
    if (!task.task_to_id && !confirmedWithoutExecutor) {
      setDisplayWithoutExecutorConfirmation(true);
      return;
    }
    createTaskWithoutCheck();
  };

  const onTaskNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setTask({ ...task, name: e.target.value }),
    [task],
  );
  const onTaskDescriptionChange = useCallback(
    (val: string) => setTask({ ...task, description: val }),
    [task],
  );
  const onTaskProjectSelect = useCallback(
    (newValue: PrimaryKey | null) =>
      setTask({ ...task, project_id: newValue, task_to_id: null, role: null }),
    [task],
  );
  const onTaskUserRoleSelect = useCallback(
    value =>
      setTask({
        ...task,
        role: value,
        task_to_id: null,
      }),
    [task],
  );
  const onTaskExecutorSelect = useCallback(
    (newValue: PrimaryKey | null) => setTask({ ...task, task_to_id: newValue }),
    [task],
  );
  const onTaskTypeChange = useCallback((e: TaskType | null) => setTask({ ...task, task_type: e }), [
    task,
  ]);
  const onTaskPeriodTypeChange = useCallback(
    e =>
      setTask({
        ...task,
        task_period_type: e.target.value,
        task_daily_period_time: null,
      }),
    [task],
  );
  const onTaskDailyPeriodTimeChange = useCallback(
    e =>
      setTask({
        ...task,
        task_daily_period_time: e.target.value,
      }),
    [task],
  );
  const onIntervalChange = useCallback(
    e =>
    setTask({
      ...task,
      task_scheduled_days: changeSheduledDays(parseInt(e.target.value), task.task_scheduled_days)
    }),
    [task],
  );
  const onTaskManagerMinutesToComplete = useCallback(
    e => setTask({ ...task, manager_minutes_to_complete: e.target.value }),
    [task],
  );
  const onTaskDeadlineDateChange = useCallback(
    d => setTask({ ...task, finish_date: d ? dateObjectToAPICompatible(d) : null }),
    [task],
  );
  const onTaskPriorityChange = useCallback(
    newPriority => setTask({ ...task, priority: newPriority }),
    [task],
  );
  const onTaskTagsChange = useCallback((newTags: string[]) => setTask({ ...task, tags: newTags }), [
    task,
  ]);
  const toggleTaskSharedStatus = useCallback(() => setTask({ ...task, shared: !task.shared }), [
    task,
  ]);

  const onActualToggle = React.useCallback(() => {
    resetTaskToBeCreatedData();
    props.toggleModal();
  }, [props.toggleModal, resetTaskToBeCreatedData]);

  const confirmationDialog = useModal(false);

  const onConfirmationDialogYes = React.useCallback(() => {
    confirmationDialog.toggle();
    onActualToggle();
  }, [confirmationDialog, onActualToggle]);
  const onConfirmationDialogNo = confirmationDialog.hide;

  const onToggle = React.useCallback(() => {
    // If user already edited the task info
    if (!_.isEqual(task, initialTaskState) || !_.isEqual(checklist, initialChecklist)) {
      // Ask for confirmation
      confirmationDialog.show();
    } else {
      onActualToggle();
    }
  }, [onActualToggle, confirmationDialog, task, checklist]);

  return (
    <>
      <Modal
        isOpen={props.isShown}
        toggle={onToggle}
        className="modal-dialog-centered"
        size="lg"
        contentClassName="alert alert-primary"
      >
        <form onSubmit={onSubmit}>
          <ModalHeader toggle={onToggle}>Создать задачу</ModalHeader>
          <ModalBody>
            {/* Task name */}
            <FormGroup>
              <Label>Название задачи</Label>
              <Input
                type="text"
                value={task.name}
                onChange={onTaskNameChange}
                className="form-control"
                invalid={showValidationProblems && !taskNameValid}
                required={true}
              />
            </FormGroup>

            {/* Task description */}
            <FormGroup>
              <Label>Описание</Label>
              <Editor
                contentValue={task.description}
                onChildChange={onTaskDescriptionChange}
                invalid={showValidationProblems && !taskDescriptionValid}
              />
            </FormGroup>

            {/* Project select */}
            <FormGroup>
              <Label>Проект</Label>
              <ProjectSelect
                value={task.project_id}
                projects={props.projects}
                disabled={!!props.preselectedProject}
                onChange={onTaskProjectSelect}
                required={true}
                invalid={showValidationProblems && !taskProjectValid}
              />
            </FormGroup>

            {/* Task role selection */}
            <div className="row">
              <div className="form-group col-6">
                <Label>Подразделение</Label>
                <UserRoleSelect
                  value={task.role ? task.role.toString() : null}
                  disabled={!taskProjectValid}
                  invalid={showValidationProblems && (!taskProjectValid ? false : !taskRoleValid)}
                  onChange={onTaskUserRoleSelect}
                  placeholder={"Выберите подразделение"}
                  className="form-control select2-custom"
                />
              </div>

              {/* User to assign this task to */}
              <div className="form-group col-6">
                <Label>Кому назначить</Label>
                <UserSelect
                  value={task.task_to_id}
                  onChange={onTaskExecutorSelect}
                  invalid={showValidationProblems && (!taskRoleValid ? false : !taskExecutorValid)}
                  disabled={!taskRoleValid}
                  placeholder={"Выберите пользователя"}
                  users={props.users.filter(user =>
                    isUserApplicableToBeTaskExecutor(user, task.role, selectedProjectUserIds),
                  )}
                />
              </div>
            </div>

            {/* Task type */}
            <div className="row">
              <div className="form-group col-6">
                <Label>Тип задачи</Label>
                <ControllableTaskTypeSelect value={task.task_type} onChange={onTaskTypeChange} />
              </div>
              {task.task_type === TaskType.SCHEDULED && (
              <div className="form-group col-6">
                <Label>Дни недели</Label>
                {Object.values(WeekDays).map((i) => (
                  <div className="form-check">
                    <Input
                      type="checkbox"
                      value={i.value}
                      name="intervalType"
                      onChange={onIntervalChange}
                      id={`intervalTypeId${i.value}`} />
                    <Label 
                      className="form-check-label" 
                      for={`intervalTypeId${i.value}`}>
                      {i.name}
                    </Label>
                  </div>
                  ))}
              </div>
            )}
            </div>

            {task.task_type === TaskType.PERIODIC && (
              <div className="row">
                <div className="form-group col-6">
                  <Label>Тип интервала</Label>
                  <Input
                    type="select"
                    value={task.task_period_type ? task.task_period_type : "default-value"}
                    onChange={onTaskPeriodTypeChange}
                    className="form-control select2-custom"
                    required={true}
                  >
                    <option value="default-value">Выберите</option>
                    {Object.values(TaskPeriods).map((t, i) => (
                      <option key={i} value={t.tag}>
                        {t.value}
                      </option>
                    ))}
                  </Input>
                </div>
                {task.task_period_type === TaskPeriods.DAILY.tag && (
                  <div className="form-group col-6">
                    <Label>Интервал дней</Label>
                    <Input
                      type="text"
                      value={task.task_daily_period_time || ""}
                      onChange={onTaskDailyPeriodTimeChange}
                      className="form-control"
                      required={true}
                    />
                  </div>
                )}
              </div>
            )}

            <div className="row">
              {/* Task deadline date */}
              <div className="form-group col-md-6 col-12">
                <Label>Дата окончания</Label>
                <DatePicker
                  className="form-control d-block"
                  selected={task.finish_date ? new Date(task.finish_date) : undefined}
                  dateFormat="yyyy-MM-dd HH:mm"
                  showTimeSelect={true}
                  timeFormat="HH:mm"
                  onChange={onTaskDeadlineDateChange}
                  timeCaption="Время"
                  timeIntervals={30}
                  locale="ru-RU"
                  shouldCloseOnSelect={true}
                  placeholderText="Выберите дату окончания"
                  required={task.task_to_id !== null}
                  invalid={showValidationProblems && !taskFinishDateValid}
                />
              </div>

              {/* Task oriented deadline */}
              <div className="form-group col-md-6 col-12">
                <Label>Ориентировочное время (минуты)</Label>
                <Input
                  type="text"
                  value={task.manager_minutes_to_complete || ""}
                  onChange={onTaskManagerMinutesToComplete}
                  className="form-control"
                  required={true}
                />
              </div>
            </div>

            {/* Task priority */}
            <div className="form-group">
              <Label>Приоритет</Label>
              <ControllableTaskPrioritySelect
                value={task.priority}
                placeholder="Выберите приоритет"
                onChange={onTaskPriorityChange}
                invalid={showValidationProblems && !taskPriorityValid}
              />
            </div>

            {/* Tags */}
            <div className="form-group">
              <Label>Тэги</Label>
              <TagsListEditable
                tags={task.tags}
                setTags={onTaskTagsChange}
                noTagsMessage="Нет тэгов"
                tagsClickable={true}
                constructTagLink={constructTaskTagSearchRoute}
                editMode={true}
              />
            </div>

            {/* Task initial checklist */}
            <div className="form-group">
              <TaskChecklistBare
                task={(task as any) as CRMTask}
                items={((checklist as any) as TaskCheckListItem[]).sort((a, b) =>
                  compareTwoNumbers(a.pk, b.pk),
                )}
                onAdd={onItemToAddSave}
                onItemEdit={onItemEdit}
                onItemRemove={onItemRemove}
                isManager={true}
                isExecutor={true}
                adding={false}
                onItemToggle={onItemToggle}
                smallHeight={true}
                selectionEnabled={false}
                showTaskCompletionDate={false}
              />
            </div>
            <div />
          </ModalBody>

          <ModalFooter style={{ justifyContent: "space-between" }}>
            {/* Should task be shared */}
            <div>
              <Checkbox checked={task.shared} onChange={toggleTaskSharedStatus} />
              <Label className="ml-2 small">Доступна для интеграции с сервисами</Label>
            </div>
            {/* Submit button */}
            <div>
              <Button type="submit" color="success" disabled={props.projectCreationInProcess}>
                {props.projectCreationInProcess ? "Подождите..." : "Добавить задачу"}
              </Button>
            </div>
          </ModalFooter>
        </form>
      </Modal>

      {/* This modal is shown in order to confirm no-executor task creation */}
      <ActionConfirmationModal
        isOpen={displayWithoutExecutorConfirmation}
        toggle={_.partial(
          setDisplayWithoutExecutorConfirmation,
          !displayWithoutExecutorConfirmation,
        )}
        onOk={_.partial(setConfirmedWithoutExecutor, true)}
        confirmationText="Вы действительно хотите создать задачу без назначенного исполнителя?"
      />

      <Modal
        isOpen={confirmationDialog.shown}
        toggle={confirmationDialog.toggle}
        className="modal-dialog-centered"
        size="md"
        contentClassName="alert alert-primary"
      >
        <ModalHeader toggle={confirmationDialog.toggle}>Подтвердите действие</ModalHeader>
        <ModalBody>
          Вы действительно хотите закрыть это модальное окно и удалить внесенные данные?
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={onConfirmationDialogYes}>
            Да
          </Button>
          <Button color="secondary" onClick={onConfirmationDialogNo}>
            Нет
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    users: usersModule.selectors.getAllUsersSortedByNameBare(store, null),
    projects: projectsModule.selectors.getAllProjectsSortedByNameBare(store, null),
    projectsById: projectsModule.selectors.getProjectsById(store, null),
    currentProjectSelected: projectProjectsModule.selectors.getCurrentProject(store, null),
    projectCreationInProcess: projectsModule.selectors.isProjectCreationInProcess(store, null),
  };
};

export default withRouter(
  connect(mapStateToProps, { createTask: tasksModule.actions.createTask, push })(CreateTask),
);
