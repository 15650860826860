import Page from "#/components/Page";
import React from "react";
import { Route, Switch } from "react-router";
import AccountsScene from "./components/Accounts";
import ContentPlanScene from "./components/ContentPlan";
import MetrikaScene from "./components/Metrika";
import ProxyScene from "./components/Proxy";
import ServicesList from "./components/ServicesList";
import TopfiftyScene from "./components/TOP50";
import MonitoringScene from "./components/TOPVisor";

const ServicesScene = ({ match }: { match: any }) => (
  <Switch>
    <Route
      path={`${match.url}`}
      exact={true}
      render={(props: any) => (
        <Page title="Сервисы">
          <ServicesList {...props} />
        </Page>
      )}
    />
    <Route path={`${match.url}/topvisor`} component={MonitoringScene} />
    <Route path={`${match.url}/top50`} component={TopfiftyScene} />
    <Route path={`${match.url}/contentplan`} component={ContentPlanScene} />
    <Route path={`${match.url}/proxy`} component={ProxyScene} />
    <Route path={`${match.url}/accounts`} component={AccountsScene} />
    <Route path={`${match.url}/metrika`} component={MetrikaScene}/>
  </Switch>
);

export default ServicesScene;
