import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { formatDate, formatDateLong, compareTwoAPIFormatDates } from "#/util";
import { Top50ProjectGraph } from "../../../../../../types";

// Formatter function for graph legend
const legendFormatter = (v, n, p) => {
  const name = v === "top_3" ? "Топ 3" : v === "top_10" ? "Топ 10" : "Топ 30";
  return [name];
};

// Formatter function for graph tooltip
const tooltipFormatter = (v, n, p) => {
  const name = n === "top_3" ? "Топ 3" : n === "top_10" ? "Топ 10" : "Топ 30";
  return [v + "%", name];
};

// Formatter function for graph label tooltip
const tooltipLabelFormatter = (v, n, p) => {
  return [formatDateLong(v)];
};

// Comparator function for graph sorting
const graphSortingCriteria = (a, b) => compareTwoAPIFormatDates(a.date, b.date);

// Tick formatter for graph X Axis
const XAxisTickFormatter = (v, n, p) => formatDate(v);

// Tick formatter for graph Y Axis
const YAxisTickFormatter = (v, n, p) => v + "%";

const Graph = (props: { graph: Top50ProjectGraph | null }) => {
  const { graph } = props;
  const data = React.useMemo(
    () => (graph && graph.graph ? graph.graph.sort(graphSortingCriteria) : []),
    [graph],
  );
  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data} margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
        <XAxis tickFormatter={XAxisTickFormatter} dataKey="date" interval={0} />
        <YAxis tickFormatter={YAxisTickFormatter} />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip formatter={tooltipFormatter} labelFormatter={tooltipLabelFormatter} />
        <Legend formatter={legendFormatter} />
        <Line type="monotone" dataKey="top_3" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line type="monotone" dataKey="top_10" stroke="#82ca9d" />
        <Line type="monotone" dataKey="top_30" stroke="#b45cc3" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Graph;
