import React, { useMemo, useState, useEffect, useCallback } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/src/simplebar.css";
import { AccountsSite, Account } from "#/store/modules/accounts/types";
import {
  fetchAccounts,
  setSelection,
  getCurrentSite,
  getAccountsArray,
  changeSortSettings,
} from "#/store/modules/accounts";
import { connect } from "react-redux";
import {
  useTable,
  useExpanded,
  useRowSelect,
  useFilters,
  useFlexLayout,
  useSortBy,
} from "react-table";
import { DispatchProp, StoreRootState } from "#/store/types";
import "./index.scss";
import FilterComponent from "./FilterComponent";

interface TableOwnProps {}

interface TableConnectedProps {
  accounts: Account[];
  fetchAccounts: DispatchProp<typeof fetchAccounts>;
  setSelection: DispatchProp<typeof setSelection>;
  site: AccountsSite;
  changeSortSettings: DispatchProp<typeof changeSortSettings>;
}

type TableProps = TableConnectedProps & TableOwnProps;

const Table = (props: TableProps) => {
  const defaultColumn = useMemo(
    () => ({
      Filter: ({ column }) => <FilterComponent type="number" way={column.id} />,
      sortType: "basic",
    }),
    [],
  );

  const columns = useMemo(
    () => [
      {
        // Make an expander cell
        Header: () => null, // No header
        id: "expander", // It needs an ID
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>{row.isExpanded ? "👇" : "👉"}</span>
        ),
        maxWidth: 30,
        disableFilters: true,
      },
      {
        Header: "Дата регистрации",
        accessor: "reg",
        Filter: ({ column }) => <FilterComponent type="date" way={column.id} />,
        minWidth: 200,
      },
      {
        Header: "Сообщения",
        accessor: "messages",
      },
      ...props.site.specifications.map(spec => ({
        Header: spec,
        accessor: `balance_set[${spec}]`,
      })),
    ],
    [props.site],
  );
  const [] = useState([]);
  const [] = useState(false);
  const [pageSize] = useState(10);
  const pageCount = useMemo(() => Math.ceil(props.site.number_of_accounts / pageSize), [
    props.site,
    pageSize,
  ]);

  const getRowId = useCallback(row => row.pk, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { selectedRowIds, sortBy },
  } = useTable(
    {
      columns,
      data: props.accounts,
      manualPagination: true,
      pageCount,
      getRowId,
      defaultColumn,
      disableMultiSort: true,
      manualSortBy: true,
      autoResetSortBy: false,
    },
    useFilters,
    useSortBy,
    useExpanded,
    useRowSelect,
    useFlexLayout,
    hooks => {
      hooks.visibleColumns.push(columns => [
        {
          id: "selection",
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <input type="checkbox" {...getToggleAllRowsSelectedProps()} />
          ),
          Cell: ({ row }) => <input type="checkbox" {...row.getToggleRowSelectedProps()} />,
          maxWidth: 30,
          disableFilters: true,
        },
        ...columns,
      ]);
    },
  );

  useEffect(() => {
    props.setSelection(Object.keys(selectedRowIds).map(pk => parseInt(pk)));
  }, [selectedRowIds]);

  useEffect(() => {
    if (sortBy[0]) {
      props.changeSortSettings(sortBy[0].id, sortBy[0].desc);
    } else {
      props.changeSortSettings(null);
    }
  }, [sortBy]);

  const renderSubComponent = useCallback(({ row }) => {
    const account = row.original;
    return (
      <div className="container-fluid">
        <div className="row">
          {[
            [
              ["Логин:", account.login],
              ["Пароль:", account.password],
              ["Email:", account.mail],
              ["Email pass:", account.mailpassword],
            ],
            [
              ["Старая почта:", account.oldmail],
              ["Id:", account.name],
              ["Пол:", account.pol ? "Мужчина" : "Женщина"],
              ["Регион:", account.region],
            ],
            [
              ["Прокси:", account.proxy],
              ["Ссылка:", account.url],
            ],
          ].map((block, blockIndex) => {
            return (
              <dl key={blockIndex} className="col-3 border-right row ml-1">
                {block.map((prop, i) => {
                  return (
                    <React.Fragment key={i}>
                      <dt className="col-5">{prop[0]}</dt>
                      <dd className="col-7 text-break">{prop[1]}</dd>
                    </React.Fragment>
                  );
                })}
              </dl>
            );
          })}

          <div className="col-3">
            <h6 className="text-center">Дополнительная информация</h6>
            <div className="mx-4 text-break">{account.comment}</div>
          </div>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="box-body">
      <div {...getTableProps()} className=" account_table">
        <div className="thead">
          {headerGroups.map(headerGroup => (
            <div {...headerGroup.getHeaderGroupProps()} className="tr">
              {headerGroup.headers.map(column => (
                <div {...column.getHeaderProps()} className="th">
                  <div {...column.getSortByToggleProps()}>
                    {column.render("Header")}
                    <span>{column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}</span>
                  </div>
                  <div>{column.canFilter ? column.render("Filter") : null}</div>
                </div>
              ))}
            </div>
          ))}
        </div>

        <SimpleBar {...getTableBodyProps()} className="tbody">
          {rows.map((row, i) => {
            prepareRow(row, i);
            return (
              <>
                <div {...row.getRowProps()} className="tr">
                  {row.cells.map(cell => {
                    return (
                      <div {...cell.getCellProps()} className="td">
                        {cell.render("Cell")}
                      </div>
                    );
                  })}
                </div>
                {row.isExpanded ? (
                  <div className="tr">
                    <div>{renderSubComponent({ row })}</div>
                  </div>
                ) : null}
              </>
            );
          })}
        </SimpleBar>
      </div>
    </div>
  );
};

export default connect(
  (state: StoreRootState) => ({
    accounts: getAccountsArray(state, null),
    site: getCurrentSite(state, null),
  }),
  {
    fetchAccounts,
    setSelection,
    changeSortSettings,
  },
)(Table);
