import { showSuccessToast } from "#/components/toasts";
import TaskTable from "#/scenes/Main/components/CRM/components/TaskTable";
import { getActionsFromModule } from "#/store/helpers";
import projectModule from "#/store/modules/project";
import projectProjectsModule from "#/store/modules/projectProjects";
import projectsModule from "#/store/modules/projects";
import projectTasksModule from "#/store/modules/projectTasks";
import projectUsersModule from "#/store/modules/projectUsers";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import tasksModule from "#/store/modules/tasks";
import usersModule from "#/store/modules/users";
import {
  CompleteTaskData,
  CRMProject,
  CRMProjectData,
  CRMTask,
  PrimaryKey,
  StoreRootState,
  TaskStatus,
  User,
} from "#/store/types";
import assert from "assert";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import ProjectHead from "../Head";
import ProjectTasks from "./components/ProjectTasks";
import "./index.scss";

interface ProjectWrapperConnectedProps {
  project: CRMProjectData | null;
  creator: User | null;
  tasks: CompleteTaskData[];
  loading: boolean;
  projectUsers: User[];
  userId: PrimaryKey | null;
  editProject: any;
  editTask: any;
  removeTask: any;
  isCurrentlyDeletingTask: boolean;
  currentUser: User | null;
  users: User[];
  addUserToProject: (projectId: PrimaryKey, userId: PrimaryKey) => Promise<any>;
  removeUserFromProject: (projectId: PrimaryKey, userId: PrimaryKey) => Promise<any>;
}

interface ProjectWrapperOwnProps {}

type ProjectWrapperProps = ProjectWrapperConnectedProps & ProjectWrapperOwnProps;

const ProjectWrapper = (props: ProjectWrapperProps) => {
  // TODO: Show that this project is loading
  if (props.loading) {
    return null;
  }
  assert(props.project !== null);
  const [project, setProject] = useState(props.project);

  useEffect(() => {
    setProject(project);
  }, [props.project]);

  const editProjectProp = (item: number) =>
    props.editProject(item, () => showSuccessToast("Текущее изменение проекта успешно выполнено."));

  return (
    <>
      <ProjectHead
        project={props.project}
        projectUsers={props.projectUsers}
        creator={props.creator}
        currentUser={props.currentUser!}
        fetching={props.loading}
        onSave={editProjectProp}
        users={props.users}
        onUserToProjectAdd={(userId: PrimaryKey) =>
          props.addUserToProject(props.project!.data!.pk, userId)
        }
        onUserRemove={(userId: PrimaryKey) =>
          props.removeUserFromProject(props.project!.data!.pk, userId)
        }
      />

      {/* Project tasks */}
      <ProjectTasks
        project={props.project}
        tasks={props.tasks}
        editTask={props.editTask}
        removeTask={props.removeTask}
        isCurrentlyDeletingTask={props.isCurrentlyDeletingTask}
      />
    </>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    project: projectProjectsModule.selectors.getCurrentProjectData(store, null),
    users: usersModule.selectors.getAllUsersBare(store, null),
    creator: projectUsersModule.selectors.getCurrentProjectCreator(store, null),
    loading: projectModule.selectors.isLoading(store, null),
    tasks: projectTasksModule.selectors.getCurrentProjectTasksSortedByPriority(store, null),
    projectUsers: projectUsersModule.selectors.getCurrentProjectUsersSortedByName(store, null),
    userId: sessionModule.selectors.getCurrentUserID(store, null),
    isCurrentlyDeletingTask: tasksModule.selectors.isCurrentlyDeletingTask(store),
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  };
};

export default connect(mapStateToProps, {
  ...getActionsFromModule(projectsModule, [
    "editProject",
    "addUserToProject",
    "removeUserFromProject",
  ]),
  ...getActionsFromModule(tasksModule, ["editTask", "removeTask"]),
})(ProjectWrapper);
