import React from "react";
import cn from "classnames";
import style from "../../index.module.scss";
import { CRMTask } from "#/store/types";

const TaskExecutionAgreement = (props: {
  task: CRMTask;
  isManager: boolean;
  isExecutor: boolean;
  editTask: any;
}) => {
  const { task, isExecutor, isManager } = props;
  const [agreed, setAgreed] = React.useState<boolean>(true);
  const [minutesExecution, setExecutionMinutes] = React.useState<string>("");
  const [minutesManager, setManagerMinutes] = React.useState<{ edit: boolean; minutes: string }>({
    edit: false,
    minutes: "",
  });

  const saveExecutionMinutes = (mins: number | null) => {
    if (isExecutor) {
      props.editTask({ ...task!, executor_minutes_to_complete: mins });
    }
  };

  const saveManagerMinutes = (mins: number | null) => {
    if (isManager) {
      props.editTask({ ...task!, manager_minutes_to_complete: mins });
    }
  };

  React.useEffect(() => {
    if (!task!.executor_minutes_to_complete) {
      setExecutionMinutes(task!.manager_minutes_to_complete!.toString());
      setManagerMinutes({
        ...minutesManager,
        minutes: task!.manager_minutes_to_complete!.toString(),
      });
    } else {
      setExecutionMinutes(task!.executor_minutes_to_complete!.toString());
    }
  }, [task]);

  const executorMinutesAgreed = () => {
    saveExecutionMinutes(task!.manager_minutes_to_complete);
  };

  const executorCustomMinutesSubmit = () => {
    saveExecutionMinutes(parseInt(minutesExecution));
    setAgreed(true);
  };

  const managerEditMinutes = () => {
    saveManagerMinutes(parseInt(minutesManager.minutes));
    setManagerMinutes({ ...minutesManager, edit: false });
  };

  const setManagerAgreed = () => {
    if (isManager) {
      props.editTask({ ...task!, agreed_minutes_to_complete: task.executor_minutes_to_complete });
    }
  };

  return (
    <div
      className={cn([
        "box",
        style["task-main-box-description__agreement"],
        "alert",
        "alert-primary",
      ])}
    >
      <div className="box-header">Согласование времени</div>
      <div className="box-body">
        {isExecutor && !isManager && (
          <div className={style["title-group"]}>
            Время, оцененное менеджером: {task.manager_minutes_to_complete} мин.
          </div>
        )}

        {isManager && (
          <>
            {!minutesManager.edit ? (
              <div className="d-flex align-items-center">
                <div className={style["title-group"]}>
                  Вы оценили данную задачу исполнителю в: {task.manager_minutes_to_complete} мин.
                </div>
                <button
                  className="btn-list btn row-action ml-2"
                  onClick={() => setManagerMinutes({ ...minutesManager, edit: true })}
                >
                  <i className="fas fa-pen mr-1"/> Изменить
                </button>
              </div>
            ) : (
              <div className="form-group">
                <label>Оцените время исполнения (в минутах):</label>
                <div className="d-flex align-items-center">
                  <div>
                    <input
                      type="number"
                      className="form-control"
                      onChange={e =>
                        setManagerMinutes({ ...minutesManager, minutes: e.target.value })
                      }
                      value={minutesManager.minutes}
                      min="0"
                      max="800"
                    />
                  </div>

                  <button className="btn-list btn row-action ml-2" onClick={managerEditMinutes}>
                    <i className="fas fa-check mr-1"/> Сохранить
                  </button>
                </div>
              </div>
            )}
            <div>
              {!!task.executor_minutes_to_complete && (
                <div className="d-flex align-items-center">
                  <div>
                    Исполнитель оценил данную задачу в {task.executor_minutes_to_complete} мин.
                  </div>
                  <button className="btn-primary btn btn-primary ml-2" onClick={setManagerAgreed}>
                    <i className="fas fa-check mr-1"/> Согласен
                  </button>
                </div>
              )}
            </div>
          </>
        )}

        {isExecutor &&
          (task.executor_minutes_to_complete && agreed ? (
            <div className="d-flex align-items-center">
              <div>Вы оценили данную задачу в {task.executor_minutes_to_complete} мин.</div>
              <button className="btn-list btn row-action ml-2" onClick={() => setAgreed(false)}>
                <i className="fas fa-pen mr-1"/> Изменить
              </button>
            </div>
          ) : agreed ? (
            <div className={cn([style["task-main-box-description__row"]])}>
              <div className={style["title-group"]}>Согласны?</div>
              <div className="ml-2">
                <button className="btn-list btn row-action" onClick={executorMinutesAgreed}>
                  <i className="fas fa-check"/> Да
                </button>
                <button className="btn-list btn row-action ml-2" onClick={() => setAgreed(false)}>
                  <i className="fas fa-times"/> Нет
                </button>
              </div>
            </div>
          ) : (
            <div className="form-group">
              <label>Оцените время исполнения (в минутах):</label>
              <div className="d-flex align-items-center">
                <div>
                  <input
                    type="number"
                    className="form-control"
                    onChange={e => setExecutionMinutes(e.target.value)}
                    value={minutesExecution}
                    min="0"
                    max="800"
                  />
                </div>

                <button
                  className="btn-list btn row-action ml-2"
                  onClick={executorCustomMinutesSubmit}
                >
                  <i className="fas fa-check mr-1"/> Продолжить
                </button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default TaskExecutionAgreement;
