import { apiDelete, APIError, apiGet, apiPost } from "#/api";
import Loader from "#/components/Loader";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import { canUser } from "#/permissions";
import Top50GlobalContext, {
  ITop50GlobalContext,
} from "#/scenes/Main/components/Services/components/TOP50/Top50GlobalContext";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsers from "#/store/modules/sessionUsers";
import top50Module from "#/store/modules/top50";
import { PrimaryKey, StoreRootState, Top50Project, User } from "#/store/types";
import { constructtop50ProjectInfoPagePath } from "#/util/paths";
import cn from "classnames";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Header from "../Header";
import ScriptSettingsModal from "../ScriptSettingsModal";
import CreateProjectModal from "./components/CreateProjectModal";
import HeaderActions from "./components/HeaderActions";
import ProjectItem from "./components/ProjectItem";
import style from "./index.module.scss";
import "./index.scss";
import ProjectListContext, { Filters } from "./ProjectListContext";
import { ListProjectItemData } from "./types";

export interface ProjectListOwnProps {}

interface ProjectListConnectedProps {
  startQueryDataCollection: any;
  isQueryDataCollectionRunning: boolean;
  currentUser: User | null;
  push: typeof push;
}

type ProjectListProps = ProjectListOwnProps & ProjectListConnectedProps;

const ProjectList = (props: ProjectListProps) => {
  const top50GlobalContext = React.useContext<ITop50GlobalContext>(Top50GlobalContext);
  const { queryDataColStatus } = top50GlobalContext;

  // Modals
  const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState<boolean>(false);
  const toggleCreateProjectModal = useCallback(
    () => setIsCreateProjectModalOpen(!isCreateProjectModalOpen),
    [isCreateProjectModalOpen],
  );
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState<boolean>(false);
  const toggleSettingsModal = useCallback(() => setIsSettingsModalOpen(!isSettingsModalOpen), [
    isSettingsModalOpen,
  ]);

  // WordStat script
  const [wordstatScriptActive, setWordstatScriptActive] = useState<boolean>(false);
  const [wordstatScriptProgress, setWordstatScriptProgress] = useState(null);
  const [wordstatScriptFetching, setWordstatScriptFetching] = useState<boolean>(false);

  // Projects
  const [filters, setFilters] = useState<Filters>({ name: "" });
  const [projects, setProjects] = useState<ListProjectItemData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [numPages, setNumPages] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number | null>(null);

  const fetchWordStatScriptStatus = useCallback(async () => {
    try {
      const jsonRes = await apiGet(API_ROUTES.TOP50.WORDSTAT_SCRIPT_STATUS);
      const { status } = jsonRes;
      setWordstatScriptActive(status === "active");
      setWordstatScriptProgress(
        jsonRes.hasOwnProperty("progress") ? jsonRes.progress : wordstatScriptProgress,
      );
    } catch (e) {
      showErrorToast("Произошла ошибка при попытке получить статус скрипта");
    }
  }, [wordstatScriptActive, wordstatScriptProgress]);

  const refreshWordstat = useCallback(async () => {
    setWordstatScriptFetching(true);
    const res = await apiGet(API_ROUTES.TOP50.WORDSTAT_SCRIPT_RUN);
    setWordstatScriptFetching(false);
  }, []);

  const fetchProjects = useCallback(
    async (page: number = 1) => {
      setLoading(true);
      try {
        const res = await apiGet(
          API_ROUTES.TOP50.PROJECTS_PAG(page, filters.name ? filters.name : ""),
        );
        setNumPages(res.page_count);
        setCurrentPage(res.page);
        setProjects(res.projects);
      } catch (err) {
        showErrorToast(`Произошла ошибка при загрузке проектов: ${err.message}`);
      } finally {
        setLoading(false);
      }
    },
    [filters],
  );

  const onCreateProject = useCallback(
    async (payload: Partial<Top50Project>): Promise<Top50Project> => {
      setLoading(true);
      try {
        const project = await apiPost(API_ROUTES.TOP50.PROJECTS, payload);
        const nextProjects = [...projects, project];
        setProjects(nextProjects);
        props.push(constructtop50ProjectInfoPagePath(project));
        return project;
      } catch (err) {
        throw err;
      } finally {
        setLoading(false);
        setIsCreateProjectModalOpen(false);
      }
    },
    [projects],
  );

  const removeProject = useCallback(
    async (pk: PrimaryKey) => {
      try {
        const projInfo = projects.find(p => p.pk === pk);
        if (!projInfo) { return; }
        await apiDelete(API_ROUTES.TOP50.PROJECTS_ITEM(pk));
        setProjects([...projects].filter(p => p.pk !== pk));
        showSuccessToast(`Проект "${projInfo.title}" был удален`);
      } catch (err) {
        showErrorToast("Произошла ошибка при удалении проекта");
        console.error(err);
      }
    },
    [projects],
  );

  // Refetch when filters change (every 1 second)
  useEffect(() => {
    fetchProjects();
  }, [filters]);

  const startQueryDataCollection = useCallback(() => {
    return props
      .startQueryDataCollection()
      .then(() => {
        // Started
      })
      .catch((err: APIError) => {
        showErrorToast(err.message);
      });
  }, []);

  const projectsToRender = useMemo(
    () => projects.map((p, i) => <ProjectItem rightBorder={i % 2 !== 0} p={p} key={i} />),
    [projects, props.currentUser],
  );

  const renderedPaginator = React.useMemo(() => {
    return (
      <nav className="mt-5">
        <ul className="pagination pagination-sm justify-content-center">
          {_.range(numPages).map((num, i) => {
            const n = num + 1;
            const onThisPage = _.toNumber(currentPage) === n;
            return (
              <li
                key={i}
                className={cn({ "page-item": true, active: onThisPage })}
                onClick={() => fetchProjects(n)}
              >
                <button className="page-link" disabled={onThisPage}>
                  {n}
                  {onThisPage && <span className="sr-only">(current)</span>}
                </button>
              </li>
            );
          })}
        </ul>
      </nav>
    );
  }, [numPages, currentPage, fetchProjects]);

  return (
    <ProjectListContext.Provider
      value={{
        startQueryDataCollection,
        toggleCreateProjectModal,
        toggleSettingsModal,
        filters,
        removeProject,
        setFilters,
        loading,
        queryDataCollectionStatus: queryDataColStatus,
      }}
    >
      <Header />

      <section className="content" style={{ width: "100%", height: "100%" }}>
        <div className="row" style={{ height: "100%" }}>
          <div className="col-12">
            <div className="box" style={{ height: "100%" }}>
              <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                <h4 className="box-title">Проекты</h4>
                <HeaderActions />
              </div>
              <div className="box-body d-flex flex-column">
                {loading ? (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <Loader />
                  </div>
                ) : projectsToRender.length <= 0 ? (
                  <div className={style["no-projects-message"]}>Проектов нет</div>
                ) : (
                  <>
                    <div className={cn([style["top50-projects-list"], "d-flex-wrap"])}>
                      {projectsToRender}
                    </div>
                    {renderedPaginator}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      {canUser(props.currentUser, "CREATE", "PROJECTM") && (
        <>
          <CreateProjectModal
            isOpen={isCreateProjectModalOpen}
            toggle={toggleCreateProjectModal}
            onCreateProject={onCreateProject}
          />
          <ScriptSettingsModal isOpen={isSettingsModalOpen} toggle={toggleSettingsModal} />
        </>
      )}
    </ProjectListContext.Provider>
  );
};

const mapStateToProps = (state: StoreRootState) => ({
  isQueryDataCollectionRunning: top50Module.selectors.isQueryDataCollectionRunning(state, null),
  currentUser: sessionUsers.selectors.getCurrentUser(state, null),
});

const mapDispatchToProps = {
  ...getActionsFromModule(top50Module, ["startQueryDataCollection"]),
  push,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
