import React from "react";
import { connect } from "react-redux";
import {
  getMaxPages,
  getCurrentPage,
  getPageSize,
  changePage,
  changePageSize,
} from "#/store/modules/accounts";
import { DispatchProp, StoreRootState } from "#/store/types";

interface PaginationSliderOwnProps {}

interface PaginationSliderConnectedProps {
  maxPages: number;
  page: number;
  pageSize: number;
  changePage: DispatchProp<typeof changePage>;
  changePageSize: DispatchProp<typeof changePageSize>;
}

type PaginationSliderProps = PaginationSliderConnectedProps & PaginationSliderOwnProps;

const PaginationSlider = (props: PaginationSliderProps) => {
  const onPageChange = e => props.changePage(parseInt(e.target.value));
  const onPageSizeChange = e => props.changePageSize(parseInt(e.target.value));

  return (
    <div className="d-flex align-items-center mx-3">
      <div className="d-flex align-items-center">
        <span>Текущая страница</span>
        <select onChange={onPageChange} className="ml-2 form-control" style={{ width: "unset" }}>
          {Array(props.maxPages)
            .fill(null)
            .map((_, i) => (
              <option selected={i + 1 === props.page} value={i + 1}>
                {i + 1}
              </option>
            ))}
        </select>
      </div>

      <div className="d-flex ml-3 align-items-center">
        <span>Размер страницы</span>

        <select
          onChange={onPageSizeChange}
          className="ml-2 form-control"
          style={{ width: "unset" }}
        >
          {[10, 25, 50, 100, 250].map(size => (
            <option value={size} selected={size === props.pageSize}>
              {size}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default connect(
  (state: StoreRootState) => ({
    maxPages: getMaxPages(state, null),
    page: getCurrentPage(state, null),
    pageSize: getPageSize(state, null),
  }),
  {
    changePage,
    changePageSize,
  },
)(PaginationSlider);
