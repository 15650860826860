import { ContentPlanEvent, PrimaryKey } from "#/store/types";
import Switch from "rc-switch";
import React, { useEffect, useState } from "react";
import "./index.scss";

const PlusOrMinus = (status: boolean) =>
  status ? (
    <i className="fas fa-plus-circle text-success" />
  ) : (
    <i className="fas fa-minus-circle text-danger" />
  );

interface OwnProps {
  event: ContentPlanEvent;
  onChange: (pk: PrimaryKey, payload: Partial<ContentPlanEvent>, callback: () => void) => void;
}

interface ConnectedStateProps {}

type Props = ConnectedStateProps & OwnProps;

const EventItem = (props: Props) => {
  const [event, setEvent] = useState<ContentPlanEvent>(props.event);
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    setEvent(props.event);
  }, [props.event]);

  const changeBooleanField = (pk: PrimaryKey, fieldname: string, value: boolean) => {
    const payload = { ...event, [fieldname]: value };
    props.onChange(pk, payload, () => {
      setEvent(payload);
    });
  };

  const onReset = () => {
    setEvent(props.event);
    setEditMode(false);
  };

  const onSave = () => {
    props.onChange(event.pk, event, () => {
      setEditMode(false);
    });
  };

  const toggleEditMode = () => setEditMode(!editMode);

  return (
    <tr>
      <td>{event.name}</td>
      {editMode ? (
        <td className="d-flex">
          <input
            type="text"
            className="form-control form-control-small form-control-small-height"
            value={event.link ? event.link : ""}
            onChange={e => setEvent({ ...event, link: e.target.value })}
            placeholder="Ссылка"
          />
        </td>
      ) : (
        <td>
          {event.link ? (
            <a href={event.link} target="_blank" rel="noopener noreferrer">
              {event.link.length > 60 ? event.link.substr(0, 50) + "..." : event.link}
            </a>
          ) : (
            "-"
          )}
        </td>
      )}
      <td>{event.wordstat !== null ? event.wordstat : "-"}</td>
      {editMode ? (
        <td className="d-flex">
          <input
            type="text"
            className="form-control form-control-small form-control-small-height"
            value={event.query}
            onChange={e => setEvent({ ...event, query: e.target.value })}
            placeholder="Запрос"
          />
        </td>
      ) : (
        <td>{event.query ? event.query : "-"}</td>
      )}
      <td>{PlusOrMinus(event.seo_text)}</td>
      <td>{event.status ? event.status : "-"}</td>
      <td>{PlusOrMinus(event.short_text)}</td>
      <td>{PlusOrMinus(event.tech_info)}</td>
      <td>{PlusOrMinus(event.sujet_text)}</td>
      <td>{PlusOrMinus(event.video)}</td>
      <td>
        <Switch
          onChange={() => changeBooleanField(event.pk, "reviews", !event.reviews)}
          checked={event.reviews}
        />
      </td>
      <td>{event.image !== null ? event.image : "-"}</td>
      {editMode ? (
        <td className="d-flex">
          <input
            type="text"
            className="form-control form-control-small form-control-small-height"
            value={event.comment}
            onChange={e => setEvent({ ...event, comment: e.target.value })}
            placeholder="Комментарий"
          />
        </td>
      ) : (
        <td>{event.comment ? event.comment : "-"}</td>
      )}
      <td>
        <Switch
          onChange={() => changeBooleanField(event.pk, "position", !event.position)}
          checked={event.position}
        />
      </td>
      <td>
        <Switch
          onChange={() => changeBooleanField(event.pk, "links", !event.links)}
          checked={event.links}
        />
      </td>

      {!editMode ? (
        <td>
          <button type="button" className="btn btn-list row-action ml-1" onClick={toggleEditMode}>
            <i className="fas fa-pen" />
          </button>
        </td>
      ) : (
        <td className="d-flex">
          <button type="button" className="btn btn-list row-action ml-1" onClick={onSave}>
            <i className="fas fa-check" />
          </button>
          <button type="button" className="btn btn-list row-action ml-1" onClick={onReset}>
            <i className="fas fa-times" />
          </button>
        </td>
      )}
    </tr>
  );
};

export default EventItem;
