import { getActionsFromModule } from "#/store/helpers";
import mm from "#/store/modules/sitemonitoring";
import React, { useEffect } from "react";
import { connect } from "react-redux";

export interface SitemonitoringPageProps {
  children: JSX.Element;
}

export interface SitemonitoringPageConnectedProps {
  setupSitemonitoringWebSocket: any;
  closeSitemonitoringWebSocket: any;
}

const SitemonitoringPage = (props: SitemonitoringPageProps & SitemonitoringPageConnectedProps) => {
  useEffect(() => {
    props.setupSitemonitoringWebSocket();
    return () => props.closeSitemonitoringWebSocket();
  }, []);
  return props.children;
};

export default connect(
  null,
  getActionsFromModule(mm, ["setupSitemonitoringWebSocket", "closeSitemonitoringWebSocket"]),
)(SitemonitoringPage);
