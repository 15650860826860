import { SubmitEvent } from "#/store/types";
import React from "react";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface RemoveQueriesModalProps {
  isOpen: boolean;
  fetching?: boolean;
  toggle: () => void;
  onRemove: () => Promise<void>;
}

const RemoveQueriesModal = (props: RemoveQueriesModalProps) => {
  const { isOpen, toggle, fetching = false } = props;

  const handleFormSubmit = async (evt: SubmitEvent) => {
    evt.preventDefault();
    await props.onRemove();
    props.toggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-dialog-centered"
      size="lg"
      contentClassName="alert alert-primary"
    >
      <ModalHeader>Удаление запросов</ModalHeader>
      <form onSubmit={handleFormSubmit}>
        <ModalBody>
          <FormGroup>
            <h4>Вы действительно хотите удалить эту группу запросов?</h4>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success" disabled={fetching}>
            {fetching ? "Подождите..." : "Удалить"}
          </button>
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            Отмена
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default RemoveQueriesModal;
