import {
  PrimaryKey,
  StoreRootState
} from "#/store/types";
import {
  createSelector
} from "reselect";
import {
  AccountsSite,
  AccountsState,
  Account,
  AccountsRequestBody,
  AccountsQuery,
  AccountsQueryParameter
} from "./types";
import _ from "lodash";
type Props = any;

const getAccountsStore = (store: StoreRootState, props: Props): AccountsState => store.accounts;
const getProps = (state: StoreRootState, props: Props): Props => props;

export const getSiteTypes = createSelector(
  [getAccountsStore, getProps],
  (as: AccountsState) => as.siteTypesById,
);

export const getSites = createSelector(
  [getAccountsStore, getProps],
  (as: AccountsState) => as.sitesById,
);
export const getCurrentSiteId = createSelector(
  [getAccountsStore, getProps],
  (as: AccountsState) => as.currentSite,
);

// export const getCurrentSite = createSelector(
//   [getSites, getCurrentSiteId],
//   (sites: Record<number, AccountsSite>, pk: PrimaryKey) => sites[pk],
// );

// export const isSiteLoading = createSelector(
//   [getAccountsStore, getProps],
//   (as: AccountsState) => as.sitesLoading,
// );

export const getAccounts = createSelector([getAccountsStore], (as: AccountsState) => as.accounts);

export const getAccountsArray = createSelector([getAccounts], accs => Object.values(accs));

export const getMaxPages = createSelector(
  [getAccountsStore, getProps],
  (as: AccountsState) => as.max_pages,
);

// export const getCurrentSiteSpecification = createSelector(
//   [getCurrentSite],
//   (site: AccountsSite) => site.specifications,
// );

export const isLoading = createSelector([getAccountsStore], (as: AccountsState) => as.loading);

export const getSelection = createSelector([getAccountsStore], (as: AccountsState) => as.selection);

// export const getParams = createSelector([getAccountsStore], (as: AccountsState) => as.params);

export const getCurrentSite = createSelector(
  [getAccountsStore],
  (as: AccountsState): AccountsSite => as.sitesById[as.currentSite],
);

export const getAccountsForSite = createSelector(
  [getCurrentSite, getAccounts],
  (site: AccountsSite, accounts: Record < number, Account > ) =>
  Object.values(accounts).filter(acc => acc.site === site.pk),
);

export const getRequestBody = createSelector(
  [getAccountsStore],
  (as: AccountsState) => as.requestBody,
);

export const getQueryParams = createSelector(
  [getRequestBody], (body: AccountsRequestBody) => {
    console.log("Query params recalculated", body)
    return body.query
  }
)
// export const getAccountsForSite = createSelector([getAccounts])
// export const getSpecifications = createSelector(
//     [getCurrentSiteId],
//     ()=>getAccounts(getAccountsStore, getProps)[0]
// )
export const makeGetFilterValue = () => createSelector(
  [getQueryParams, getProps], (query: AccountsQuery, props: any): AccountsQueryParameter < any > => {
    console.log("Inside getFilterValue", query, props)
    return _.get(query, props.way, ({
      default: true
    }))
  }
)

export const getCurrentPage = createSelector([getRequestBody], body => body.page)

export const getPageSize = createSelector([getRequestBody], body => body.pageSize)

export const getSelectedAccounts = createSelector(
  [getSelection, getAccounts],
  (selection, accounts) => selection.reduce((prev: Account[], pk) => {
    const account = accounts.find(acc => acc.pk === pk)
    return account ? [...prev, account] : prev
  }, []))