import React from "react";

const FAIconButton = (props: { type: "fas" | "fa"; name: string; onClick: () => void }) => (
  <i
    className={`${props.type} fa-${props.name}`}
    onClick={props.onClick as any}
    style={{ cursor: "pointer" }}
  />
);

export default FAIconButton;
