import React from "react";
import { Link } from "react-router-dom";

const Header = () => (
  <section className="content-header">
    <h1>
      Сервисы \ <small>TopVisor</small>
    </h1>
    <ol className="breadcrumb breadcrumb-big">
      <li className="breadcrumb-item">TopVisor</li>
      <li className="breadcrumb-item">
        <Link to="/services/topvisor/">Список проектов</Link>
      </li>
    </ol>
  </section>
);

export default Header;
