import sessionUsersModule from "#/store/modules/sessionUsers";
import tasksModule from "#/store/modules/tasks";
import userModule from "#/store/modules/user";
import { CompleteTaskData, PrimaryKey, TasksById, UserData } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

const getTasksAssignedToCurrentUser = createSelector(
  [tasksModule.selectors.getTasksById, userModule.selectors.getCurrentUserData],
  (tbid: TasksById, cu: UserData | null): CompleteTaskData[] => {
    if (cu === null) { return []; }
    const userTasksToIds = _.get(cu, "tasksTo", []);
    return _.compact(userTasksToIds.map((tid: PrimaryKey) => _.get(tbid, tid, null)));
  },
);

const getTasksAssignedByCurrentUser = createSelector(
  [tasksModule.selectors.getTasksById, userModule.selectors.getCurrentUserData],
  (tbid: TasksById, cu: UserData | null): CompleteTaskData[] => {
    if (cu === null) { return []; }
    const userTasksFromIds = _.get(cu, "tasksFrom", []);
    return _.compact(userTasksFromIds.map((tid: PrimaryKey) => _.get(tbid, tid, null)));
  },
);

export default {
  getTasksAssignedToCurrentUser,
  getTasksAssignedByCurrentUser,
};
