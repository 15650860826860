import { StoreRootState } from "#/store/types";
import { createSelector } from "reselect";
import _ from "lodash";
import { Goal, TableGoal } from "./types";

type Props = any;

const getState = (store: StoreRootState) => store.metrika.goals;

const getProps = (store: StoreRootState, props: Props) => props;

export const getTokens = createSelector([getState], state => state.tokens);

export const getValidTokens = createSelector([getTokens], tokens => {
  return _.pickBy(tokens, token => !token.failedFetching);
});

export const makeGetTokensForCounter = () =>
  createSelector([getValidTokens, getCounters, getProps], (tokens, counters, props) => {
    return counters[props.counter.id].tokens.map(t => tokens[t]);
  });

export const getCounters = createSelector([getState], state => {
  return state.counters;
});

export const getSelection = createSelector([getState], state => state.selection);

export const getCreatingGoal = createSelector([getState], state => state.creatingGoal);

export const getSelectedTokenForCounter = () =>
  createSelector([getSelection, getProps], (selection, props) => selection[props.counter.id]);

export const getGoals = createSelector([getState], state => state.goals);

// TODO: Rewrite this into FP
export const getGoalsAsNestedStructure = createSelector(
  [getGoals, getCounters],
  (goals, counters): TableGoal[] => {
    const obj = _.mapValues(_.cloneDeep(goals), goal => ({
      ...goal,
      counterName: Object.values(counters).find(counter => counter.goals.includes(goal.id))?.name,
    }));
    const stepGoals = Object.values(obj)
      .filter(x => x.type === "step")
      .map((goal: Goal): any => {
        delete obj[goal.id];
        // @ts-ignore
        goal.steps = goal.steps.map(stepId => {
          const step = obj[stepId];
          delete obj[stepId];
          return step;
        });
        return goal;
      });

    return [...stepGoals, ...Object.values(obj)] as TableGoal[];
  },
);

export const getSelectedGoals = createSelector([getState], state => state.selectedGoals);
