import { createReducer } from "#/store/helpers";
import { Action, MainWSState } from "#/store/types";
import * as ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const initialState: MainWSState = {
  ws: null,
};

export default createReducer<MainWSState, Action>(
  {
    [MutationType.SETUP_MAIN_WS]: (
      state: MainWSState,
      action: ActionTypes.SetupMainWebSocketSucceedAction,
    ) => ({ ...state, ws: action.payload.ws }),

    [MutationType.CLOSE_MAIN_WS]: (
      state: MainWSState,
      action: ActionTypes.CloseMainWebSocketSucceedAction,
    ) => ({ ...state, ws: null }),
  },
  initialState,
);
