import Box from "#/components/Box";
import Checkbox from "#/components/Checkbox";
import Loader from "#/components/Loader";
import { showInfoToast, showSuccessToast } from "#/components/toasts";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import sessionTasksModule from "#/store/modules/sessionTasks";
import tasksModule from "#/store/modules/tasks";
import { becomeTaskExecutor, editTask, removeTask } from "#/store/modules/tasks/actions";
import {
  CompleteTaskData,
  CRMTask,
  DispatchProp,
  PrimaryKey,
  StoreRootState,
  TasksByIdBare,
  TaskStatus,
} from "#/store/types";
import _ from "lodash";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Label } from "reactstrap";
import TaskTable, { SortTasksBy } from "../../../TaskTable";
import style from "./index.module.scss";
import {
  getIconClass,
  getTabBackgroundTint,
  getTabTitle,
  getTasksTypeBySelectedOption,
  tabTitles,
  TaskTableSelectedTab,
} from "./SelectedTab";
import {
  fetchTasksToCurrentUser,
  fetchTasksFromCurrentUser,
} from "#/store/modules/sessionTasks/actions";
import { PageContent } from "#/components/PageWrapper";

interface TableWrapperConnectedProps {
  tasksToUser: CompleteTaskData[];
  tasksFromUser: CompleteTaskData[];
  finishedTasks: CompleteTaskData[];
  delayedTasks: CompleteTaskData[];
  tasksWithoutExecutor: CompleteTaskData[];
  userId: PrimaryKey | null;
  editTask: DispatchProp<typeof editTask>;
  removeTask: DispatchProp<typeof removeTask>;
  loadingTasks: boolean;
  isCurrentlyDeletingTask: boolean;
  becomeTaskExecutor: DispatchProp<typeof becomeTaskExecutor>;
  tasksById: TasksByIdBare;
  fetchTasksToCurrentUser: DispatchProp<typeof fetchTasksToCurrentUser>;
  fetchTasksFromCurrentUser: DispatchProp<typeof fetchTasksFromCurrentUser>;
}

interface TableWrapperOwnProps {}

type TableWrapperProps = TableWrapperConnectedProps & TableWrapperOwnProps;

type TaskListActiveFilter = "active" | "finished" | "all";

interface AssignedTasksTabFilters {
  userTaskHidden: boolean;
  activeFilter: TaskListActiveFilter;
}

interface CompletedTasksTabFilters {
  onlyMine: boolean;
}

const Dashboard = (props: TableWrapperProps) => {
  const [selected, setSelected] = useState<TaskTableSelectedTab>(0);
  const [right, setRight] = useState(false);
  const [managedTaskListFilters, setManagedTaskListFilters] = useState<AssignedTasksTabFilters>({
    userTaskHidden: false,
    activeFilter: "active",
  });
  const [completedTasksTabFilters, setCompletedTasksTabFilters] = useState<
    CompletedTasksTabFilters
  >({ onlyMine: true });

  useEffect(() => {
    props.fetchTasksToCurrentUser();
    props.fetchTasksFromCurrentUser();
  }, []);

  const changeSelected = useCallback(
    (type: TaskTableSelectedTab) => {
      setRight(selected < type);
      setSelected(type);
    },
    [selected],
  );

  const tasksContainer = useMemo(
    () => [
      props.tasksToUser,
      props.finishedTasks,
      props.delayedTasks,
      props.tasksFromUser,
      props.tasksWithoutExecutor,
    ],
    [
      props.tasksToUser,
      props.finishedTasks,
      props.delayedTasks,
      props.tasksFromUser,
      props.tasksWithoutExecutor,
    ],
  );

  const selectedTasks = useMemo(() => {
    let taskListToRender = tasksContainer[selected];
    if (selected === TaskTableSelectedTab.FROM_USER) {
      switch (managedTaskListFilters.activeFilter) {
        case "active": {
          taskListToRender = taskListToRender.filter(e => e.data.status !== TaskStatus.FINISHED);
          break;
        }
        case "finished": {
          taskListToRender = taskListToRender.filter(e => e.data.status === TaskStatus.FINISHED);
          break;
        }
        default: {
          break;
        }
      }
      if (managedTaskListFilters.userTaskHidden) {
        taskListToRender = taskListToRender.filter(e => e.data.task_to !== props.userId);
      }
    } else if (selected === TaskTableSelectedTab.FINISHED) {
      if (completedTasksTabFilters.onlyMine) {
        taskListToRender = taskListToRender.filter(e => e.data.task_to === props.userId);
      }
    }
    return taskListToRender;
  }, [selected, managedTaskListFilters, completedTasksTabFilters, tasksContainer]);

  const onUserTaskHiddenToggle = useCallback(() => {
    setManagedTaskListFilters({
      ...managedTaskListFilters,
      userTaskHidden: !managedTaskListFilters.userTaskHidden,
    });
  }, [managedTaskListFilters, setManagedTaskListFilters]);

  const changeManagedTaskListFilters = useCallback(
    (type: TaskListActiveFilter) => () =>
      setManagedTaskListFilters({ ...managedTaskListFilters, activeFilter: type }),
    [managedTaskListFilters],
  );

  return (
    <PageContent>
      <>
        <div className="row">
          <div className="col-12">
            <div className="row dashboard-boxes">
              {tasksContainer.map((el, i) => (
                <Box
                  grid-class="col"
                  boxContentClassName="box"
                  onClick={() => changeSelected(i as any)}
                  title={getTabTitle(i)}
                  barbg={getTabBackgroundTint(i)}
                  count={tasksContainer[i].length}
                  active={selected === i}
                  iconClass={getIconClass(i)}
                  key={i}
                />
              ))}
            </div>
          </div>
        </div>

        <div className="row d-flex flex-column h-100">
          <div className="col-12 d-flex flex-column flex-grow-1 flex-fill">
            <div className="box h-100 m-0">
              {props.loadingTasks ? (
                <Loader />
              ) : (
                <>
                  <div className="box-header with-border d-flex justify-content-between flex-vertically-centered-x">
                    <div>
                      <h4 className="box-title">Список задач</h4>
                      <h6 className="box-subtitle">
                        {getTasksTypeBySelectedOption(selected)} ({selectedTasks.length})
                      </h6>
                    </div>
                    <div>
                      {/* "Assigned" tab options */}
                      {selected === TaskTableSelectedTab.FROM_USER && (
                        <>
                          <div className="mb-2">
                            <button
                              className={`btn btn-list fz-13 action-rm ${
                                managedTaskListFilters.activeFilter === "active" ? "active" : ""
                              }`}
                              onClick={changeManagedTaskListFilters("active")}
                            >
                              Активные
                            </button>
                            <button
                              className={`btn btn-list fz-13 action-rm ${
                                managedTaskListFilters.activeFilter === "finished" ? "active" : ""
                              }`}
                              onClick={changeManagedTaskListFilters("finished")}
                            >
                              Завершенные
                            </button>
                            <button
                              className={`btn btn-list fz-13 action-rm ${
                                managedTaskListFilters.activeFilter === "all" ? "active" : ""
                              }`}
                              onClick={changeManagedTaskListFilters("all")}
                            >
                              Все задачи
                            </button>
                          </div>
                          <div className={style["table-filters-container"]}>
                            <div className={style["user-self-assigned-task-list-hidden-group"]}>
                              <Label
                                className={
                                  style["user-self-assigned-task-list-hidden-group__label"]
                                }
                              >
                                Назначенные самому себе
                              </Label>
                              <Checkbox
                                onChange={onUserTaskHiddenToggle}
                                checked={!managedTaskListFilters.userTaskHidden}
                                size="small"
                              />
                            </div>
                          </div>
                        </>
                      )}

                      {/* "Completed" tab options */}
                      {selected === TaskTableSelectedTab.FINISHED && (
                        <>
                          <div className={style["table-filters-container"]}>
                            <div className={style["user-self-assigned-task-list-hidden-group"]}>
                              <Label
                                className={
                                  style["user-self-assigned-task-list-hidden-group__label"]
                                }
                              >
                                Только назначенные мне
                              </Label>
                              <Checkbox
                                onChange={() =>
                                  setCompletedTasksTabFilters({
                                    ...completedTasksTabFilters,
                                    onlyMine: !completedTasksTabFilters.onlyMine,
                                  })
                                }
                                checked={completedTasksTabFilters.onlyMine}
                                size="small"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="box-body">
                    <div className="table-responsive overflow-visible">
                      <div id="tasks_wrapper">
                        <TaskTable
                          key={selected}
                          tasks={selectedTasks}
                          sortByPreselected={SortTasksBy.PRIORITY}
                          sortingDirectionPreselected={true}
                          direction={right}
                          changeItem={item =>
                            props.editTask(item).then(newItem => {
                              const prevItem = _.get(props.tasksById, item.pk, null);
                              if (prevItem !== null) {
                                if (prevItem.status !== newItem.status) {
                                  if (newItem.status === TaskStatus.FINISHED) {
                                    showInfoToast(
                                      `Задача была перенесена во вкладку "${
                                        tabTitles[TaskTableSelectedTab.FINISHED]
                                      }"`,
                                    );
                                  } else if (newItem.status === TaskStatus.DELAYED) {
                                    showInfoToast(
                                      `Задача была перенесена во вкладку "${
                                        tabTitles[TaskTableSelectedTab.DELAYED]
                                      }"`,
                                    );
                                  }
                                }
                              }
                              return newItem;
                            })
                          }
                          removeItem={(id: PrimaryKey) => props.removeTask(id)}
                          becomeTaskExecutor={(pk: PrimaryKey) =>
                            props.becomeTaskExecutor(pk).then((task: CRMTask) => {
                              const newTaskTab =
                                task.status === TaskStatus.DELAYED
                                  ? TaskTableSelectedTab.DELAYED
                                  : task.status === TaskStatus.FINISHED
                                  ? TaskTableSelectedTab.FINISHED
                                  : TaskTableSelectedTab.TO_USER;
                              const newTabName = tabTitles[newTaskTab];
                              showSuccessToast(
                                `Задача "${task.name}" была прикреплена к вам и перемещена во вкладку "${newTabName}".`,
                              );
                              return task;
                            })
                          }
                          isCurrentlyDeletingTask={props.isCurrentlyDeletingTask}
                          showExecutorColumn={
                            selected !== TaskTableSelectedTab.TO_USER &&
                            selected !== TaskTableSelectedTab.WITHOUT_EXECUTOR &&
                            selected !== TaskTableSelectedTab.DELAYED
                          }
                          showManagerColumn={selected !== TaskTableSelectedTab.FROM_USER}
                          showBecomeExecutorColumn={
                            selected === TaskTableSelectedTab.WITHOUT_EXECUTOR
                          }
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </>
    </PageContent>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    tasksToUser: sessionTasksModule.selectors.getActiveTasksToUserSortedByPriority(store, null),
    delayedTasks: sessionTasksModule.selectors.getDelayedTasksToUserSortedByPriority(store, null),
    finishedTasks: sessionTasksModule.selectors.getFinishedTasksForUser(store, null),
    tasksWithoutExecutor: sessionTasksModule.selectors.getTasksToUserWithoutExecutor(store, null),
    tasksFromUser: sessionTasksModule.selectors.getTasksFromUser(store, null),
    userId: sessionModule.selectors.getCurrentUserID(store, null),
    loadingTasks: tasksModule.selectors.isLoadingTasks(store),
    isCurrentlyDeletingTask: tasksModule.selectors.isCurrentlyDeletingTask(store),
    tasksById: tasksModule.selectors.getTasksByIdBare(store),
  };
};

export default connect(mapStateToProps, {
  ...getActionsFromModule(tasksModule, ["editTask", "removeTask", "becomeTaskExecutor"]),
  ...getActionsFromModule(sessionTasksModule, [
    "fetchTasksToCurrentUser",
    "fetchTasksFromCurrentUser",
  ]),
})(withAuthentication<TableWrapperProps>(UserAuthenticationStatus.AUTHENTICATED)(Dashboard));
