import Page from "#/components/Page";
import React from "react";
import { Route, Switch } from "react-router";
import ProxyIndex from "./components/Index";

const ProxyScene = ({ match }: any) => (
  <Switch>
    <Route
      path={match.url}
      exact={true}
      render={(props: any) => (
        <Page title="CRMPlan | Proxy">
          <ProxyIndex {...props} />
        </Page>
      )}
    />
  </Switch>
);

export default ProxyScene;
