import KeybindingOverlay from "#/components/KeybindingOverlay";
import history from "#/history";
import store from "#/store";
import { ConnectedRouter } from "connected-react-router";
import React from "react";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";

export interface AppWrapperProps {
  children: React.ReactNode;
}

const AppWrapper = ({ children }: AppWrapperProps) => (
  <Provider store={store}>
    <KeybindingOverlay>
      <ConnectedRouter history={history}>
        <React.Fragment>
          {children}
          <ToastContainer />
        </React.Fragment>
      </ConnectedRouter>
    </KeybindingOverlay>
  </Provider>
);

export default AppWrapper;
