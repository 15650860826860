import { combineReducers } from "redux";
import top50Module from "./top50";
import goalsModule from "./goals";

export const reducer = combineReducers({
  top50: top50Module.reducer,
  goals: goalsModule.reducer,
});

export default reducer;
