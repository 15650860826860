import { StoreRootState, TaskState } from "#/store/types";
import { createSelector } from "reselect";

const getTaskState = (store: StoreRootState) => store.task;

const getCurrentTaskId = createSelector(
  [getTaskState],
  (ts: TaskState) => ts.taskId,
);

const isLoading = createSelector(
  [getTaskState],
  (ts: TaskState) => ts.fetching,
);

const getTaskChatWS = createSelector(
  [getTaskState],
  (ts: TaskState) => ts.taskChatWebSocket,
);

const isTaskChatWSClosed = createSelector(
  [getTaskChatWS],
  (ws: WebSocket | null): boolean => !!ws,
);

export default {
  getCurrentTaskId,
  isLoading,
  getTaskChatWS,
  isTaskChatWSClosed,
};
