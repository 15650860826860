import Loader from "#/components/Loader";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import userModule from "#/store/modules/user";
import usersModule from "#/store/modules/users";
import { StoreRootState, User } from "#/store/types";
import { handleDispatchErrorAndDisplayToast, userFullName } from "#/util";
import cn from "classnames";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import ProfileInfoBlock from "./components/ProfileInfoBlock";
import ProfileTasks from "./components/Tasks";
import style from "./index.module.scss";
import "./index.scss";
import _ from 'lodash';

interface ProfileConnectedProps extends RouteComponentProps<{ id: string }> {
  currentUser: User | null;
  user: User | null;
  userLoading: boolean;
  editRequestInProcess: boolean;
  fetchUserInfo: any;
  setCurrentUser: any;
}

interface ProfileOwnProps {}

type ProfileProps = ProfileConnectedProps & ProfileOwnProps;

const Profile = (props: ProfileProps) => {
  const { id } = props.match.params;
  const userFullNameStr = props.user ? userFullName(props.user) : "N/A";
  // Initial mount
  useEffect(() => {
    props
      .fetchUserInfo(id)
      .then(() => {
        props.setCurrentUser(_.toNumber(id));
      })
      .catch(handleDispatchErrorAndDisplayToast);
    // Cleanup
    return () => {
      props.setCurrentUser(null);
    };
  }, []);

  return (
    <div className={style.root}>
      <section className="content-header">
        <h1>
          Профиль \<small>{userFullNameStr}</small>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/profiles">Перейти ко всем пользователям</Link>
          </li>
        </ol>
      </section>
      <div className={style.content}>
        {props.userLoading ? (
          <div className={style["loader-wrapper"]}>
            <Loader />
          </div>
        ) : (
          <section className={cn(["content", style.content])}>
            <div className={cn(["row", style.row])}>
              <ProfileInfoBlock user={props.user} />
              <ProfileTasks />
            </div>
          </section>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  user: userModule.selectors.getCurrentUser(store, null),
  userLoading: usersModule.selectors.isLoadingUsers(store, null),
  editRequestInProcess: usersModule.selectors.isCurrentlyEditingUser(store, null),
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      ...getActionsFromModule(userModule, ["setCurrentUser"]),
      ...getActionsFromModule(usersModule, ["fetchUserInfo"]),
    },
  )(withAuthentication<ProfileProps>(UserAuthenticationStatus.AUTHENTICATED)(Profile)),
);
