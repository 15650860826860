enum MutationType {
  FETCH_PROJECTS_LIST_SUCCEED = "projects/FETCH_PROJECTS_LIST_SUCCEED",
  FETCH_PROJECTS_LIST_FAIL = "projects/FETCH_PROJECTS_LIST_FAIL",
  FETCH_PROJECTS_LIST_START = "projects/FETCH_PROJECTS_LIST_START",

  EDIT_PROJ_SUCCEED = "projects/EDIT_PROJ_SUCCEED",
  EDIT_PROJ_START = "projects/EDIT_PROJ_START",
  EDIT_PROJ_FAIL = "projects/EDIT_PROJ_FAIL",

  FETCH_PROJ_SUCCEED = "projects/FETCH_PROJ_SUCCEED",
  FETCH_PROJ_START = "projects/FETCH_PROJ_START",
  FETCH_PROJ_FAIL = "projects/FETCH_PROJ_FAIL",

  CREATE_PROJ_SUCCEED = "projects/CREATE_PROJ_SUCCEED",
  CREATE_PROJ_START = "projects/CREATE_PROJ_START",
  CREATE_PROJ_FAIL = "projects/CREATE_PROJ_FAIL",

  REMOVE_PROJ_SUCCEED = "projects/REMOVE_PROJ_SUCCEED",
  REMOVE_PROJ_START = "projects/REMOVE_PROJ_START",
  REMOVE_PROJ_FAIL = "projects/REMOVE_PROJ_FAIL",

  ADD_USER_TO_PROJ_SUCCEED = "projects/ADD_USER_TO_PROJ_SUCCEED",
  REMOVE_USER_FROM_PROJ_SUCCEED = "projects/REMOVE_USER_FROM_PROJ_SUCCEED",
}

export default MutationType;
