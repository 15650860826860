import actions from "./actions";
import reducer from "./reducer";
import selectors from "./selectors";

/**
 * This module deals with data embedded into the CRMTask type.
 */

export default {
  reducer,
  selectors,
  actions,
};
