import * as actions from "./actions";
import reducer from "./reducer";
import * as selectors from "./selectors";

/**
 * This module is responsible for all the data related to the Sitemonitoring service.
 */

export default {
  actions,
  reducer,
  selectors,
};
