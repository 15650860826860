import { getActionsFromModule } from "#/store/helpers";
import esm from "#/store/modules/eventSystem";
import { StoreRootState } from "#/store/types";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, TabPane } from "reactstrap";
import ProjectContext from "../ProjectContext";
import { TabPage } from "../util";

interface ConnectedDispatchProps {
  subscribeToEvent: typeof esm.actions.subscribeToEvent;
  unsubscribeFromEvent: typeof esm.actions.unsubscribeFromEvent;
}

type Props = {} & ConnectedDispatchProps;

// Project monitoring filters tab pane.
const FiltersComponent = (props: Props) => {
  const { filters, setFilters } = useContext(ProjectContext);
  const [editedFilters, setEditedFilters] = useState<string>(filters);

  const onFiltersSave = () => setFilters(editedFilters);

  const onSubmit = (e: any) => {
    e.preventDefault();
    return onFiltersSave();
  };

  const areThereUnsavedChanges = () => !_.isEqual(editedFilters, filters);

  // Initial render
  useEffect(() => {
    props.subscribeToEvent("SAVE", onFiltersSave);
    return () => {
      props.unsubscribeFromEvent("SAVE", onFiltersSave);
    };
  }, []);

  // Reset filters textarea on external update
  useEffect(() => {
    setEditedFilters(filters);
  }, [filters]);

  return (
    <TabPane tabId={TabPage.FILTERS}>
      <div className="content-inner-topvisor">
        <div className="bar topvisor-content-main">
          <h2>Фильтры</h2>
          <label>
            Фильтры должны быть в формате CSS-селекторов. Каждый отдельный фильтр должен быть на
            отдельной строке.
          </label>
          <form action="#" onSubmit={onSubmit}>
            <textarea
              className="form-control mt-2"
              rows={4}
              onChange={e => setEditedFilters(e.target.value)}
              value={editedFilters}
              placeholder="Фильтры"
            />
            <div className="mt-2" style={{ marginTop: "1rem" }}>
              <Button type="submit" color="success" disabled={!areThereUnsavedChanges()}>
                Сохранить изменения
              </Button>
            </div>
          </form>
        </div>
      </div>
    </TabPane>
  );
};

export default connect(
  (store: StoreRootState) => ({}),
  {
    ...getActionsFromModule(esm, ["subscribeToEvent", "unsubscribeFromEvent"]),
  },
)(FiltersComponent);
