import React from "react";
import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { PrimaryKey, Top50Configuration } from "#/store/types";
import { useReactSelect } from "#/util/reactSelect";
import { useConfigurations } from "./useConfigurations";
import _ from "lodash";

type ControllableConfigurationSelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: PrimaryKey | null;
  onChange: (p: PrimaryKey | null) => void;
  filter?: (c: Top50Configuration) => boolean;
};

type RegionDisplayFunction = (c: Top50Configuration | null) => string;

const defaultDF: RegionDisplayFunction = c =>
  c ? `${c.region_model!.name} - ${c.search_engine_model!.name}` : "Не выбрано";

const ControllableConfigurationSelect = (props: ControllableConfigurationSelectProps) => {
  const configs = useConfigurations({ includeById: true, filter: props.filter }, [props.filter]);
  const df: RegionDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;
  const selectState = useReactSelect(configs.data, configs.byId, df, props.value, {}, [
    configs,
    df,
    props.value,
  ]);
  return (
    <Select
      placeholder="Выберите конфигурацию"
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={true}
      options={selectState.options}
      value={selectState.selected}
      onChange={(option: SelectOption) => {
        props.onChange(option.value ? _.toNumber(option.value) : null);
      }}
    />
  );
};

export default ControllableConfigurationSelect;
