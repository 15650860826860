import React from "react";
import TableCheckbox from "#/components/TableCheckbox";
import { Top50QueryPage, PrimaryKey } from "#/store/types";
import cn from "classnames";
import _ from "lodash";
import style from "../index.module.scss";
import { Link } from "react-router-dom";
import { POS_NOT_TOP50 } from "../../../../../util";
import { ClipLoader } from "react-spinners";
import { IProjectContext } from "../../../ProjectContext";
import { Input } from "reactstrap";

const getWordStatContent = (wordstat: string | null | number | undefined) => {
  return wordstat ? wordstat : "-";
};

const QueryTableItem = (props: {
  queryPage: Top50QueryPage;
  checked: boolean;
  toggle: () => void;
  context: IProjectContext;
}) => {
  const { context, queryPage, toggle, checked } = props;

  const [editUrlState, setEditUrlState] = React.useState<{
    status: boolean;
    fetching: boolean;
    url: string;
  }>({
    fetching: false,
    status: false,
    url: queryPage.url || "",
  });

  React.useEffect(() => {
    setEditUrlState({ ...editUrlState, url: queryPage.url || "" });
  }, [queryPage]);

  const renderedUrlInputCell = (() => {
    return editUrlState.status ? (
      <Input
        type="text"
        className="form-control d-inline"
        value={editUrlState.url}
        onChange={e => {
          setEditUrlState({ ...editUrlState, url: e.target.value });
        }}
        onBlur={e => submitUrl()}
      />
    ) : (
      getWordStatContent(queryPage.url)
    );
  })();

  const submitUrl = async () => {
    if (queryPage.url !== editUrlState.url) {
      setEditUrlState({ ...editUrlState, fetching: true });
      try {
        await context.editQuery(queryPage.pk, { url: editUrlState.url });
        setEditUrlState({ ...editUrlState, status: false });
      } finally {
        setEditUrlState({ ...editUrlState, fetching: false, status: false });
      }
    }
  };

  const renderedUrlActionCell = (() => {
    return editUrlState.status ? (
      <>
        <button
          className="btn btn-list row-action"
          title="Сохранить изменения"
          disabled={editUrlState.fetching}
          onClick={submitUrl}
        >
          <>
            <ClipLoader
              css={{ name: "mr", styles: "vertical-align: middle;" }}
              color="inherit"
              size={13}
              loading={editUrlState.fetching}
            />
            {!editUrlState.fetching && <i className="fas fa-check" />}
          </>
        </button>
        <button
          className="btn btn-list row-action"
          title="Отменить"
          disabled={editUrlState.fetching}
          onClick={() => {
            setEditUrlState({
              ...editUrlState,
              status: false,
              fetching: false,
              url: queryPage.url || "",
            });
          }}
        >
          <i className="fas fa-times" />
        </button>
      </>
    ) : (
      <button
        className="btn btn-list row-action"
        title="Редактировать"
        onClick={() => {
          setEditUrlState({
            ...editUrlState,
            status: true,
          });
        }}
      >
        <i className="fas fa-edit" />
      </button>
    );
  })();

  return (
    <tr
      key={queryPage.pk}
      className={cn({
        [style["query-position-item-row"]]: true,
        [style["query-position-item_no-expiration-date"]]: !queryPage.event_model!.finishdate,
      })}
    >
      <td>
        <TableCheckbox checked={checked} onChange={toggle} />
      </td>
      <td>
        {queryPage.query_model!.text}
        <Link
          className="float-right pr-2"
          target="_blank"
          to={`/services/top50/query_positions/${queryPage.query_model!.pk}`}
        >
          <i className="fas fa-chart-bar"/>
          <span className="ml-1">TOP50</span>
        </Link>
      </td>
      <td>{getWordStatContent(queryPage.query_model!.wordstat)}</td>
      <td>{getWordStatContent(queryPage.query_model!.wordstat1)}</td>
      <td>{getWordStatContent(queryPage.query_model!.wordstat2)}</td>
      <td>
        {queryPage.query_model!.position === POS_NOT_TOP50
          ? "Не в ТОП50"
          : queryPage.query_model!.position === "Нет инфы"
          ? "pospospos"
          : queryPage.query_model!.position}
        {queryPage.query_model!.pos_url && queryPage.url ? (
          queryPage.url === queryPage.query_model!.pos_url ? (
            <i className="fas fa-link text-success ml-1" />
          ) : (
            <i className="fas fa-unlink text-danger ml-1" title={queryPage.query_model!.pos_url} />
          )
        ) : (
          <i className="fas fa-link text-muted-3 ml-1" />
        )}
      </td>
      <td>
        <Link to={`/services/top50/queries/${queryPage.event_model!.name}`} target="_blank">
          {queryPage.event_model!.name}
        </Link>
      </td>
      <td>{renderedUrlInputCell}</td>
      <td>{renderedUrlActionCell}</td>
    </tr>
  );
};

export default QueryTableItem;
