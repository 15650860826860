import UserRoleLabel from "#/components/UserRoleLabel";
import usersModule from "#/store/modules/users";
import { PrimaryKey, StoreRootState, User, UserRolesById } from "#/store/types";
import { forEachUserRole } from "#/util";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";

const UserRoleLabelList = (props: { user: User; roles: UserRolesById }) => {
  return _.keys(props.roles).length > 0 ? (
    forEachUserRole(props.user, (role: PrimaryKey) => {
      const roleTitle = _.get(props.roles, role, null);
      if (roleTitle === null) { return null; }
      return <UserRoleLabel key={roleTitle.name} name={roleTitle.name} />;
    })
  ) : (
    <></>
  );
};

export default connect(
  (store: StoreRootState) => ({
    roles: usersModule.selectors.getUserRolesById(store, null),
  }),
  null,
)(UserRoleLabelList as any) as any;
