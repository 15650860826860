import DebouncedInput from "#/components/DebouncedInput";
import FAIconButton from "#/components/FAIconButton";
import Loader from "#/components/Loader";
import YandexFavicon from "#/components/YandexFavicon";
import { Top50Query, Top50QueryPage } from "#/store/types";
import { formatDateLong } from "#/util";
import { useDocumentTitle } from "#/util/hooks";
import { constructtop50ProjectInfoPagePath } from "#/util/paths";
import cn from "classnames";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { useGlobalFilter, useSortBy, useTable } from "react-table";
import ProjectContext, { IProjectContext } from "../../ProjectContext";
import Graph from "./components/Graph";
import style from "./index.module.scss";
import "./index.scss";
import WordstatIcon from "../../../WordstatIcon";
import ControllableConfigurationSelect from "../../../ConfigurationsSelect/ControllableConfigurationsSelect";
const wordstat = require("#/assets/wordstat.ico");

type Dynamics = "up" | "neutral" | "down";

interface Filters {
  rating: number | null;
  dynamics: Dynamics | null;
}
const DEFAULT_FILTERS = { rating: null, dynamics: null };

const DAYS_COUNT = 7;

interface ProjectStatsConnectedProps extends RouteComponentProps<{ alias: string }> {
  push: typeof push;
}

interface ProjectStatsOwnProps {}

type ProjectStatsProps = ProjectStatsConnectedProps & ProjectStatsOwnProps;

const ProjectStats = (props: ProjectStatsProps) => {
  const projectContext = React.useContext<IProjectContext>(ProjectContext);
  const { project, projectLoading, projectQueries, graphLoading } = projectContext;
  const alias = project ? project.title : "";
  const graph = projectContext.graph;
  const loading = projectLoading || graphLoading;
  const supportedConfigs = projectContext.supportedConfigs;

  useDocumentTitle(() => (project ? `Статистика "${project.title}" | TOP50` : "Загрузка..."), [
    project,
  ]);

  // Initial mount
  useEffect(() => {
    projectContext.setProjectFetchConfig({
      daysCount: DAYS_COUNT,
      config: null,
      fetchPositions: true,
    });
  }, []);

  // Table
  const tableColumns = React.useMemo(
    () => [
      {
        className: "cursor-pointer",
        accessor: "query_model.text",
        sortable: false,
        Header: "Запрос",
        Cell: ({ row }) => {
          const item = row.original as Top50QueryPage;
          return (
            <>
              <a
                href={`https://yandex.ru/search/?lr=213&text=${item.query_model!.text}`}
                target="_blank"
                rel="noopener noreferrer"
                className={style["query-position-item__name"]}
              >
                {item.query_model!.text}
              </a>
              <Link
                to={"/services/top50/query_positions/" + item.query_model!.pk}
                className="float-right pr-2"
                target="_blank"
              >
                <i className="fas fa-chart-bar" />
                <span className="ml-1">TOP50</span>
              </Link>
            </>
          );
        },
      },

      {
        className: "cursor-pointer text-center",
        accessor: "query_model.wordstat",
        sortable: false,
        cellClassName: "text-center wordstat-style",
        Header: () => (
          <div>
            <WordstatIcon />
            <span className="ml-1">WordStat</span>
          </div>
        ),
        Cell: ({ row }) =>
          row.original.query_model!.wordstat ? row.original.query_model!.wordstat : "-",
      },

      {
        className: "cursor-pointer text-center",
        accessor: "query_model.wordstat1",
        cellClassName: "text-center wordstat-style",
        sortable: false,
        Header: '"',
        Cell: ({ row }) =>
          row.original.query_model!.wordstat1 ? row.original.query_model!.wordstat1 : "-",
      },

      {
        className: "cursor-pointer text-center bordered-column-pos",
        accessor: "query_model.wordstat2",
        cellClassName: "text-center bordered-column-pos wordstat-style",
        sortable: false,
        Header: "!",
        Cell: ({ row }) =>
          row.original.query_model!.wordstat2 ? row.original.query_model!.wordstat2 : "-",
      },

      // Position delta
      {
        className: "cursor-pointer text-center",
        cellClassName: "text-center",
        Header: () => (
          <div title="Изменение позиции за последние 24 часа" className="d-inline">
            <i className="fa fa-caret-square-down" />
          </div>
        ),
        accessor: `pos_diff`,
        Cell: ({ row }) => {
          const qpage = row.original as Top50QueryPage;
          return (
            <>
              {qpage.pos_diff! > 0 ? (
                <div className="text-success">
                  <i className="fas fa-chevron-up ml-1 small" />
                  <span className="small-3" style={{ marginLeft: "2px" }}>
                    {qpage.pos_diff}
                  </span>
                </div>
              ) : qpage.pos_diff! < 0 ? (
                <div className="text-danger">
                  <i className="fas fa-chevron-down ml-1 small" />
                  <span className="small-3" style={{ marginLeft: "2px" }}>
                    {qpage.pos_diff}
                  </span>
                </div>
              ) : (
                "-"
              )}
            </>
          );
        },
      },

      // Dates
      ..._.compact(
        projectContext.graphDates.map((date: any, index: number) => {
          if (index >= DAYS_COUNT) {
            return null;
          }
          return {
            className: "cursor-pointer text-center",
            cellClassName: "text-center",
            Header: formatDateLong(date),
            accessor: `query_model.pos${index}`,
            Cell: ({ row }) => {
              const item = row.original as Top50QueryPage;
              const pos = item.query_model![`pos${index}`];
              return pos ? (pos === 666 ? "Не в ТОП50" : pos) : "-";
            },
          };
        }),
      ),
    ],
    [projectQueries, projectContext.graphDates],
  );

  const [filters, setFilters] = React.useState<Filters>(DEFAULT_FILTERS);
  const resetFiltersAndApply = React.useCallback(
    (updatedFilters: Partial<Filters>) => setFilters({ ...DEFAULT_FILTERS, ...updatedFilters }),
    [],
  );
  const setRatingFilter = React.useCallback(
    (rating: number | null) => resetFiltersAndApply({ rating }),
    [filters],
  );

  const [projectQueriesWithDynamics, dynamicsPrecalc] = React.useMemo((): [
    Top50QueryPage[],
    { up: Top50QueryPage[]; down: Top50QueryPage[]; neutral: Top50QueryPage[] },
  ] => {
    const dates = projectContext.graphDates;
    const withDynamics: Top50QueryPage[] = _.cloneDeep(projectQueries);
    const upArray: Top50QueryPage[] = [];
    const downArray: Top50QueryPage[] = [];
    const neutralArray: Top50QueryPage[] = [];
    for (const qpage of withDynamics) {
      let newPosIdx: null | string = null,
        oldPosIdx: null | string = null;

      for (const [idx, date] of dates.entries()) {
        const posIndex = `pos${idx}`;
        if (qpage.query_model![posIndex]) {
          if (!newPosIdx) {
            newPosIdx = posIndex;
          } else {
            oldPosIdx = posIndex;
          }
        }

        if (newPosIdx && oldPosIdx) {
          qpage.query_model!["dynamic_position_1"] = qpage.query_model![newPosIdx];
          qpage.query_model!["dynamic_position_2"] = qpage.query_model![oldPosIdx];
          break;
        }
      }

      const newPos = qpage.query_model!["dynamic_position_1"];
      const oldPos = qpage.query_model!["dynamic_position_2"];

      if (newPos === undefined || oldPos === undefined || newPos === oldPos) {
        neutralArray.push(qpage);
      } else if (newPos > oldPos) {
        downArray.push(qpage);
      } else if (newPos < oldPos) {
        upArray.push(qpage);
      }
    }
    return [
      withDynamics,
      {
        up: upArray,
        down: downArray,
        neutral: neutralArray,
      },
    ];
  }, [projectContext.graphDates, projectQueries]);

  const tableData = React.useMemo(() => {
    const dataThreshold = 666;

    let qrys = projectQueriesWithDynamics;
    if (filters.rating !== null) {
      qrys = qrys.filter((q: Top50QueryPage) => {
        let available_pos;
        for (const [i, date] of projectContext.graphDates.entries()) {
          const posIndex = `pos${i}`;
          const currPos = q.query_model![posIndex];
          if (currPos !== null) {
            available_pos = posIndex;
            break;
          }
        }
        return available_pos ? q.query_model![available_pos as any] <= filters.rating! : false;
      });
    }
    if (filters.dynamics !== null) {
      qrys = _.intersection(qrys, dynamicsPrecalc[filters.dynamics]);
    }
    return qrys.map(qpage => {
      const qry = qpage.query_model!;
      const pos1 = qry.dynamic_position_1 ? qry.dynamic_position_1 : 0;
      const pos2 = qry.dynamic_position_2 ? qry.dynamic_position_2 : 0;

      const diff = (() => {
        if ((pos1 === dataThreshold && pos2 === dataThreshold) || pos1 === dataThreshold) {
          return 0;
        } else if (pos2 === dataThreshold) {
          return pos1;
        }
        return pos2 - pos1;
      })();

      return {
        ...qpage,
        pos_diff: diff,
      };
    });
  }, [projectQueriesWithDynamics, filters, dynamicsPrecalc, projectContext.graphDates]);

  const table = useTable(
    {
      columns: tableColumns,
      data: tableData,
    },
    useGlobalFilter,
    useSortBy,
  );

  const setDynamicsFilter = React.useCallback((dynamics: Dynamics | null) => {
    resetFiltersAndApply({ dynamics });
  }, []);

  const graphData = graph ? graph.graph : [];

  const configurationFilter = React.useCallback(c => _.includes(supportedConfigs, c.pk), [
    supportedConfigs,
  ]);

  return (
    <div className="box h-100">
      <div className="box-header with-border flex-vertically-centered-x justify-content-between">
        <h4 className="box-title d-flex align-items-center">
          <FAIconButton
            type="fas"
            name="arrow-left"
            onClick={() => {
              props.push(constructtop50ProjectInfoPagePath(project));
            }}
          />
          <span className="ml-2" />
          <YandexFavicon name={alias} alt="favicon" />
          <span className="ml-2">{alias ? alias : "N/A"}</span>
        </h4>

        <div className="projectstats-metrics">
          <ControllableConfigurationSelect
            filter={configurationFilter}
            value={
              projectContext.projectFetchConfig && projectContext.projectFetchConfig.config
                ? projectContext.projectFetchConfig.config
                : supportedConfigs.length > 0
                ? supportedConfigs[0]
                : null
            }
            onChange={c =>
              projectContext.setProjectFetchConfig({
                ...projectContext.projectFetchConfig!,
                config: c,
              })
            }
            className={"mb-2"}
          />

          <div className="projectstats-block">
            {/* Rating filter */}
            {[3, 10, 30].map(num => {
              const isActive = filters.rating !== null && filters.rating === num;
              return (
                <button
                  key={num}
                  className={cn({
                    btn: true,
                    "mr-1": true,
                    "btn-light": true,
                    "active-rating": isActive,
                  })}
                  onClick={e => setRatingFilter(isActive ? null : num)}
                  disabled={graphData.length <= 0}
                >
                  {`В топ ${num}: ${_.get(graphData[graphData.length - 1], `top_${num}`, 0)}%`}
                </button>
              );
            })}
          </div>

          {/* Dynamics filter */}
          {[
            {
              btnClassName: "btn-success",
              icon: "fa-angle-double-up",
              title: "Запросы с положительным изменением позиции",
              filter: "up",
            },
            {
              btnClassName: "btn-warning",
              icon: null,
              title: "Запросы без изменения позиции",
              filter: "neutral",
            },
            {
              btnClassName: "btn-danger",
              icon: "fa-angle-double-down",
              title: "Запросы с отрицательным изменением позиции",
              filter: "down",
            },
          ].map(b => {
            const isActive = filters.dynamics === b.filter;
            return (
              <button
                className={cn({
                  btn: true,
                  "ml-4": true,
                  [b.btnClassName]: true,
                  active: isActive,
                })}
                title={b.title}
                disabled={graphData.length <= 0}
                onClick={() => {
                  setDynamicsFilter(isActive ? null : (b.filter as Dynamics));
                }}
                key={b.filter}
              >
                {dynamicsPrecalc[b.filter].length}
                {b.icon && <i className={cn(["fas", "ml-1", b.icon])} />}
              </button>
            );
          })}
        </div>
      </div>

      <div className="box-body">
        {loading && <Loader />}
        {!loading && (
          <>
            <div>
              {graphData.length > 0 ? (
                <Graph graph={graph} />
              ) : (
                <h6 className="text-center mt-5">
                  {`За последние ${DAYS_COUNT} дней не происходило съема позиций.`}
                </h6>
              )}
            </div>
            <div className="pr-3 pl-3 mt-5 pt-5">
              <table
                {...table.getTableProps()}
                className="table topf-project-stats-queries table-striped-custom"
              >
                <thead>
                  {table.headerGroups.map(headerGroup => {
                    return (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => {
                          return (
                            <th
                              {...column.getHeaderProps([
                                {
                                  ...column.getSortByToggleProps(),
                                  className: column.className,
                                  style: column.style,
                                },
                              ])}
                            >
                              {column.render("Header")}
                              {column.isSorted ? (
                                <span className="ml-1">
                                  {column.isSortedDesc ? (
                                    <i className="fa fa-caret-down" />
                                  ) : (
                                    <i className="fa fa-caret-up" />
                                  )}
                                </span>
                              ) : null}
                            </th>
                          );
                        })}
                      </tr>
                    );
                  })}

                  {/* Filter */}
                  <tr>
                    <th
                      colSpan={tableColumns.length}
                      style={{
                        textAlign: "left",
                      }}
                    >
                      <DebouncedInput
                        className="fz-14 form-control-small form-control-small-height form-control"
                        placeholder="Фильтр"
                        onChange={e => table.setGlobalFilter(e)}
                        ms={500}
                      />
                    </th>
                  </tr>
                </thead>

                <tbody {...table.getTableBodyProps()}>
                  {table.rows.length > 0 ? (
                    table.rows.map((row, i) => {
                      table.prepareRow(row);
                      const item = row.original;
                      return (
                        <tr
                          {...row.getRowProps()}
                          className={cn({
                            [style["query-position-item_no-expiration-date"]]: item
                              ? !item.event_model.finishdate
                              : false,
                          })}
                        >
                          {row.cells.map(cell => {
                            return (
                              <td
                                {...cell.getCellProps([
                                  {
                                    className: cell.column.cellClassName,
                                  },
                                ])}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan={tableColumns.length}>
                        <div className="no-table-items-message d-flex justify-content-center align-items-center">
                          Пусто
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default withRouter(
  connect(null, {
    push,
  })(ProjectStats),
);
