import { APIError, apiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { Dispatch } from "redux";
import MutationType from "./mutationType";

export const startQueryDataCollection = () => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.QUERY_DATA_COLLECTION_START });
  return apiPost(API_ROUTES.TOP50.START_QUERY_DATA_COLLECTION)
    .then((json: {}) => {
      dispatch({ type: MutationType.QUERY_DATA_COLLECTION_SUCCEED });
    })
    .catch((error: APIError) => {
      dispatch({ type: MutationType.QUERY_DATA_COLLECTION_FAIL });
      throw error;
    });
};
