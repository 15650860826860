import Page from "#/components/Page";
import React from "react";
import { Route, Switch } from "react-router";
import EventList from "./components/EventList";
import Project from "./components/Project";
import Index from "./components/ProjectList";
import Tech from "./components/Tech";

const ContentPlanScene = ({ match }: any) => (
  <Switch>
    <Route
      path={match.url}
      exact={true}
      render={(props: any) => (
        <Page title="ContentPlan">
          <Index {...props} />
        </Page>
      )}
    />

    <Route
      path={`${match.url}/projects/:alias`}
      exact={true}
      render={(props: any) => (
        <Page title="Проект | ContentPlan">
          <Project {...props} />
        </Page>
      )}
    />

    <Route
      path={`${match.url}/tech`}
      exact={true}
      render={(props: any) => (
        <Page title="Настройки | ContentPlan">
          <Tech {...props} />
        </Page>
      )}
    />

    <Route
      path={`${match.url}/eventlist`}
      exact={true}
      render={(props: any) => (
        <Page title="Список мероприятий">
          <EventList {...props} />
        </Page>
      )}
    />
  </Switch>
);

export default ContentPlanScene;
