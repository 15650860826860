import Box from "#/components/Box";
import TaskTable from "#/scenes/Main/components/CRM/components/TaskTable";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import tasksModule from "#/store/modules/tasks";
import userTasksModule from "#/store/modules/userTasks";
import { CompleteTaskData, PrimaryKey, StoreRootState, TaskStatus } from "#/store/types";
import { CODE_NON_REACHABLE } from "#/util";
import React, { useMemo, useState } from "react";
import { connect } from "react-redux";

enum SelectedTab {
  FROM_USER = 1,
  TO_USER = 0,
}

const getTasksTypeBySelectedOption = (selected: SelectedTab) => {
  switch (selected) {
    case SelectedTab.TO_USER:
      return "Задачи, которые назначены этому пользователю";
    case SelectedTab.FROM_USER:
      return "Задачи, которые пользователь назначил другим пользователям";
    default:
      CODE_NON_REACHABLE();
      return "Задачи";
  }
};

enum Type {
  ALL = "all",
  ACTIVE = "active",
  FINISHED = "finished",
}

interface ProfileTasksConnectedProps {
  tasksToUser: CompleteTaskData[];
  tasksFromUser: CompleteTaskData[];
  userId: PrimaryKey | null;
  editTask: any;
  removeTask: any;
  isCurrentlyDeletingTask: boolean;
}

interface ProfileTasksOwnProps {}

type ProfileTasksProps = ProfileTasksConnectedProps & ProfileTasksOwnProps;

const ProfileTasks = (props: ProfileTasksProps) => {
  // State
  const [selectedTab, setSelectedTab] = useState<SelectedTab>(SelectedTab.TO_USER);
  const [right, setRight] = useState(true);
  const [tasksFilter, setTasksFilter] = useState("active");

  // Selected task list
  const selectedTasks = useMemo<CompleteTaskData[]>(() => {
    const tasks = (() => {
      switch (selectedTab) {
        case SelectedTab.TO_USER:
          return props.tasksToUser;
        case SelectedTab.FROM_USER:
          return props.tasksFromUser;
        default:
          CODE_NON_REACHABLE();
          return [];
      }
    })();
    switch (tasksFilter) {
      case Type.ALL:
        return tasks;
      case Type.ACTIVE:
        return tasks.filter(e => e.data.status !== TaskStatus.FINISHED);
      case Type.FINISHED:
        return tasks.filter(e => e.data.status === TaskStatus.FINISHED);
      default:
        CODE_NON_REACHABLE();
        return [];
    }
  }, [selectedTab, tasksFilter, props.tasksToUser, props.tasksFromUser]);

  // Change selected tab
  const changeTab = (type: SelectedTab) => {
    setSelectedTab(type);
    setRight(selectedTab < type);
  };

  return (
    <div className="col-xl-9 col-lg-8 col-md-7 col-12">
      <div className="">
        <div className="row pb-2">
          <React.Fragment>
            <Box
              boxContentClassName="box"
              onClick={(e: any) => changeTab(SelectedTab.TO_USER)}
              grid-class="col-lg-6 col-12"
              title="Назначенных задач"
              count={props.tasksToUser.length}
              barbg="bg-info"
              active={selectedTab === SelectedTab.TO_USER}
            />
            <Box
              boxContentClassName="box"
              onClick={(e: any) => changeTab(SelectedTab.FROM_USER)}
              grid-class="col-lg-6 col-12"
              title="Он назначил"
              count={props.tasksFromUser.length}
              barbg="bg-info"
              active={selectedTab === SelectedTab.FROM_USER}
            />
          </React.Fragment>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                <div>
                  <h4 className="box-title">Список задач</h4>
                  <h6 className="box-subtitle">
                    {getTasksTypeBySelectedOption(selectedTab)} ({selectedTasks.length})
                  </h6>
                </div>

                <div>
                  <h6 className="box-subtitle">
                    <button
                      className={
                        "btn btn-list fz-13 action-rm " + (tasksFilter === "active" ? "active" : "")
                      }
                      onClick={e => setTasksFilter(Type.ACTIVE)}
                    >
                      Активные
                    </button>
                    <button
                      className={
                        "btn btn-list ml-1 fz-13 action-rm " +
                        (tasksFilter === "finished" ? "active" : "")
                      }
                      onClick={e => setTasksFilter(Type.FINISHED)}
                    >
                      Завершенные
                    </button>
                    <button
                      className={
                        "btn btn-list ml-1 fz-13 action-rm " +
                        (tasksFilter === "all" ? "active" : "")
                      }
                      onClick={e => setTasksFilter(Type.ALL)}
                    >
                      Все задачи
                    </button>
                  </h6>
                </div>
              </div>
              <div className="box-body">
                <div className="table-responsive">
                  <div id="tasks_wrapper" className="overflow-hidden">
                    <TaskTable
                      key={selectedTab}
                      tasks={selectedTasks}
                      direction={right}
                      changeItem={props.editTask}
                      removeItem={props.removeTask}
                      isCurrentlyDeletingTask={props.isCurrentlyDeletingTask}
                      showProjectColumn={true}
                      showExecutorColumn={selectedTab === SelectedTab.FROM_USER}
                      showManagerColumn={selectedTab === SelectedTab.TO_USER}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => {
  return {
    tasksToUser: userTasksModule.selectors.getTasksAssignedToCurrentUser(store, null),
    tasksFromUser: userTasksModule.selectors.getTasksAssignedByCurrentUser(store, null),
    userId: sessionModule.selectors.getCurrentUserID(store, null),
    isCurrentlyDeletingTask: tasksModule.selectors.isCurrentlyDeletingTask(store),
  };
};

export default connect(
  mapStateToProps,
  getActionsFromModule(tasksModule, ["editTask", "removeTask"]),
)(ProfileTasks);
