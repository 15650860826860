import { apiGet, apiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { METRIKA_ROUTES, metrikaGet } from "#/conf/metrika";
import { PrimaryKey } from "#/store/types";
import _ from "lodash";
import { Dispatch } from "redux";
import MutationTypes from "./mutationTypes";
import { YandexQuery } from "./types";

export const fetchTop50Projects = (page = 1) => (dispatch: Dispatch) => {
  dispatch({ type: MutationTypes.FETCH_TOP50PROJECTS_START, payload: page });
  return apiGet(API_ROUTES.TOP50.PROJECTS_WITH_EVENTS_PAG(page))
    .then(res => {
      dispatch({
        type: MutationTypes.FETCH_TOP50PROJECTS_SUCCEED,
        payload: res,
      });
    })
    .catch((err: Error) => {
      dispatch({ type: MutationTypes.FETCH_TOP50PROJECTS_FAIL });
      throw err;
    });
};

export const setCurrentProject = (project: PrimaryKey) => ({
  type: MutationTypes.SET_CURRENT_PROJECT,
  payload: project,
});

export const setCurrentEvent = (event: PrimaryKey) => ({
  type: MutationTypes.SET_CURRENT_EVENT,
  payload: event,
});

export const fetchEvent = (pk: PrimaryKey) => (dispatch: Dispatch) => {
  dispatch({
    type: MutationTypes.FETCH_EVENT_START,
    payload: event,
  });
  return apiGet(API_ROUTES.TOP50.GET_EVENT_FOR_METRIKA(pk))
    .then(res => {
      dispatch({
        type: MutationTypes.FETCH_EVENT_SUCCEED,
        payload: res,
      });
    })
    .catch((err: Error) => {
      dispatch({ type: MutationTypes.FETCH_EVENT_FAIL });
      throw err;
    });
};

export const fetchQueries = (metrikaId: number) => (dispatch: Dispatch) => {
  dispatch({ type: MutationTypes.FETCH_QUERIES_START });
  return metrikaGet(METRIKA_ROUTES.YANDEX_QUERIES_BY_VISITS(metrikaId))
    .then(res => {
      dispatch({
        type: MutationTypes.FETCH_QUERIES_SUCCEED,
        payload: res,
      });
    })
    .catch(() => {
      dispatch({ type: MutationTypes.FETCH_EVENT_FAIL });
    });
};

export const addQueries = (eventPk: PrimaryKey, queries: YandexQuery[]) => (dispatch: Dispatch) => {
  dispatch({ type: MutationTypes.ADD_QUERY_START });
  return apiPost(API_ROUTES.TOP50.EVENTS, {
    pk: eventPk,
    querys: queries.map(query => _.pick(query, "text")),
  })
    .then(() => {
      dispatch({
        type: MutationTypes.ADD_QUERY_SUCCEED,
        payload: queries,
      });
    })
    .catch((err: Error) => {
      dispatch({ type: MutationTypes.ADD_QUERY_FAIL });
      throw err;
    });
};

export const setMinimumNumberOfVisits = (num: number) => ({
  type: MutationTypes.SET_MINIMUM_NUMBER_OF_VISITS,
  payload: num,
});

export const setSubstring = (substr: string) => ({
  type: MutationTypes.SET_SUBSTRING,
  payload: substr,
});
