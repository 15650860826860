import { UserNotificationDerived } from "#/store/types";
import * as moment from "moment";
import React from "react";
import { DropdownItem } from "reactstrap";
import style from "./index.module.scss";

const ItemWrapper = React.forwardRef(
  (
    props: {
      onClick: (ev: any) => void;
      children: React.ReactNode;
      notification: UserNotificationDerived;
    },
    ref,
  ) => {
    return (
      <DropdownItem className={style["notification-menu-item"]}>
        <div
          className={style["notification-menu-item__inner-wrapper"]}
          ref={ref as any}
          onClick={props.onClick}
        >
          <div className={style["notification-menu-item__left-side"]}>
            {!props.notification.notification_model.read && (
              <div className={style["notification-menu-item__status-circle-container"]}>
                <span className={style["notification-menu-item__status-circle"]} />
              </div>
            )}
            <div className={style["notification-menu-item__body"]}>{props.children}</div>
          </div>
          <span className={style["notification-menu-item__date"]}>
            {(() => {
              moment.locale("ru");
              return (moment as any)(props.notification.notification_model.create_date).fromNow();
            })()}
          </span>
        </div>
      </DropdownItem>
    );
  },
);

export default ItemWrapper;
