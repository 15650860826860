import { apiGet } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Top50Configuration } from "#/store/types";
import _ from "lodash";
import React, { useEffect } from "react";

export const useConfigurations = (
  options: { includeById: boolean; filter?: (c: Top50Configuration) => boolean },
  deps: any[],
) => {
  const [data, setData] = React.useState<Top50Configuration[]>([]);
  const includeById = options.includeById ? options.includeById : false;
  useEffect(() => {
    (async () => {
      const resp: Top50Configuration[] = await apiGet(API_ROUTES.TOP50.CONFIGURATIONS);
      const newData = options.filter ? resp.filter(options.filter) : resp;
      setData(newData);
    })();
  }, [...deps]);
  const byId = React.useMemo<Record<PrimaryKey, Top50Configuration>>(() => {
    if (!includeById) {
      return {};
    }
    return _.keyBy(data, "pk");
  }, [data]);
  return { data, byId };
};
