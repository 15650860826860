import React from "react";
import { Link } from "react-router-dom";

interface HeaderConnectedProps {}

interface HeaderOwnProps {
  headerName?: string;
}

type HeaderProps = HeaderConnectedProps & HeaderOwnProps;

const Header = (props: HeaderProps) => {
  const { headerName } = props;
  const headerToRender = headerName ? headerName : "Сервисы";
  return (
    <section className="content-header">
      <h1>
        {headerToRender} \ <small>Proxy</small>
      </h1>
      <ol className="breadcrumb breadcrumb-big">
        <li className="breadcrumb-item">Proxy</li>
        <li className="breadcrumb-item">
          <Link to="/services/proxy/">Список</Link>
        </li>
      </ol>
    </section>
  );
};

export default Header;
