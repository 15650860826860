import { DEV_OR_TESTING_MODE } from "#/conf/constants";
import actions from "./actions";
import reducer, { initialState } from "./reducer";
import selectors from "./selectors";

const eventSystemModule = {
  actions,
  selectors,
  reducer,
  ...(DEV_OR_TESTING_MODE ? { __testInitialState: initialState } : {}),
};

export default eventSystemModule;
