import { PrimaryKey, Top50Event } from "#/store/types";
import { PrimaryKeySelect2Option } from "#/types";
import assert from "assert";
import _ from "lodash";
import React, { useMemo, useContext } from "react";
import Select from "react-select";
import { useAllGroups } from "../hooks";
import { undefinedOr } from "#/util";

const EventSelect = (props: {
  onChange: (pk: PrimaryKey | null) => void;
  selected: PrimaryKey | null;
  filter?: (ev: Top50Event) => boolean;
}) => {
  const filter = undefinedOr(props.filter, () => true);
  const groupsById = useAllGroups(
    {
      byId: true,
      onlyMetadata: true,
      filter,
    },
    [],
  );

  // Options rendered
  const options: PrimaryKeySelect2Option[] = groupsById.data
    ? _.compact(
        _.keys(groupsById.data).map((pk: string) => {
          const ev = _.get(groupsById.data, pk, null);
          assert(ev !== null);
          return {
            value: _.toString(pk),
            label: ev ? (ev.name as string) : "N/A",
          };
        }),
      )
    : [];

  // Selected option
  const selectedOption: PrimaryKeySelect2Option = useMemo(() => {
    const selectedEventData: Top50Event | null = props.selected
      ? _.get(groupsById.data, props.selected, null)
      : null;
    return {
      value: props.selected ? _.toString(props.selected) : "",
      label: selectedEventData ? selectedEventData.name : "Не выбрано",
    };
  }, [props.selected, groupsById]);

  return (
    <Select
      className="select-custom-style"
      value={selectedOption}
      onChange={(option: any) => {
        props.onChange(option ? option.value : null);
      }}
      options={options}
      placeholder="Выберите группу запросов"
      noOptionsMessage={() => "Ничего не найдено"}
    />
  );
};

export default EventSelect;
