import { createReducer } from "#/store/helpers";
import { AccountsQuery, AccountsState } from "#/store/modules/accounts/types";
import { PrimaryKey } from "#/store/types";
import { Set } from "immutable";
// import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";
import _ from "lodash";
import ActionTypes from "./actionTypes";

const initialState: AccountsState = {
  loading: false,
  currentSite: 0,
  sitesById: {},
  siteTypesById: {},
  selection: [],
  accounts: [],
  max_pages: 1,
  requestBody: {
    page: 1,
    pageSize: 25,
  },
};

export const reducer = createReducer<AccountsState, any>(
  {
    [MutationTypes.SITE_TYPES_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      siteTypesById: payload,
    }),

    [MutationTypes.SITES_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      sitesById: payload,
    }),

    [MutationTypes.SITES_FETCH_START]: (state: AccountsState, { payload }) => ({
      ...state,
      loading: true,
    }),

    [MutationTypes.SITES_FETCH_FINISH]: (state: AccountsState, { payload }) => ({
      ...state,
      loading: false,
    }),

    [MutationTypes.CURRENT_SITE_SET]: (
      state: AccountsState,
      { payload }: ActionTypes.SetCurrentSite,
    ) => ({
      ...state,
      currentSite: payload.site,
    }),

    [MutationTypes.ACCOUNTS_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      accounts: payload,
    }),

    [MutationTypes.ACCOUNTS_FETCH_START]: (state: AccountsState, { payload }) => ({
      ...state,
      loading: true,
    }),

    [MutationTypes.ACCOUNTS_SELECTION_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      selection: payload,
    }),

    // [MutationTypes.ACCOUNTS_FETCH_FINISH]: (state: AccountsState, { payload }) => ({
    //   ...state,
    //   loading: false,
    // }),

    [MutationTypes.ACCOUNTS_MAX_PAGES_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      max_pages: payload,
    }),

    [MutationTypes.ACCOUNTS_REQUEST_PARAMS_SET]: (state: AccountsState, { payload }) => ({
      ...state,
      params: payload,
    }),

    [MutationTypes.ACCOUNTS_REQUEST_PARAMS_RESET]: (state: AccountsState, { payload }) => ({
      ...state,
      requestBody: initialState.requestBody,
    }),

    [MutationTypes.SITE_FETCH_SUCCESS]: (state: AccountsState, { payload }) => {
      if (!payload.site) { return state; }
      return {
        ...state,
        loading: false,
        sitesById: {
          ...state.sitesById,
          [payload.site.pk]: payload.site,
        },
      };
    },

    [MutationTypes.SITES_FETCH_START]: (state: AccountsState) => ({
      ...state,
      loading: true,
    }),
    [MutationTypes.SITES_FETCH_FINISH]: (state: AccountsState) => ({
      ...state,
      loading: false,
    }),
    [MutationTypes.ACCOUNTS_FETCH_SUCCESS]: (
      state: AccountsState,
      { payload }: ActionTypes.FetchAccountsSuccess,
    ) => ({
      ...state,
      max_pages: payload.maxPages,
      accounts: payload.accounts,
      requestBody: {
        ...state.requestBody,
        page: state.requestBody.page > state.max_pages ? payload.maxPages : state.requestBody.page
      }
    }),
    [MutationTypes.QUERY_SET]: (
      state: AccountsState,
      {payload}: ActionTypes.SetQuery
    ) => ({
      ...state,
      requestBody: {
        ...state.requestBody,
        query: payload.query
      }
    }),
    [MutationTypes.RESET_SETTINGS]: (
      state: AccountsState,

    ) => ({
      ...state,
      selection: initialState.selection,
      max_pages: initialState.max_pages,
      requestBody: initialState.requestBody,
      accounts: initialState.accounts,
    }),
    [MutationTypes.PAGE_SET]: (state: AccountsState, {payload}: ActionTypes.SetPage) => ({
      ...state,
      requestBody: {
        ...state.requestBody,
        page: payload.page
      }
    }),
    [MutationTypes.PAGE_SIZE_SET]: (state: AccountsState, {payload}: ActionTypes.SetPageSize) => ({
      ...state,
      requestBody: {
        ...state.requestBody,
        pageSize: payload.pageSize,
        page: 1,
      }
    })
    // [MutationTypes.ACCOUNTS_SELECTION_SET]: (
    //     state: AccountsState,
    //     {payload}
    // ) => ({
    //     ...state,
    //     selection: payload
    // })
  },
  initialState,
);

export default reducer;
