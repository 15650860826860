import { APIError } from "#/api";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import projectsModule from "#/store/modules/projects";
import { CRMProject, StoreRootState, SubmitEvent } from "#/store/types";
import { constructCRMProjectPath } from "#/util";
import { push } from "connected-react-router";
import React, { useCallback, useRef, useState } from "react";
import { connect } from "react-redux";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface CreateProjectConnectedProps {
  fetching: boolean;
  createProject: (payload: Partial<CRMProject>) => Promise<CRMProject>;
  push: typeof push;
}

interface CreateProjectOwnProps {
  redirectAfterCreation?: boolean;
  isShown: boolean;
  toggleModal: () => Promise<unknown>;
}

type CreateProjectProps = CreateProjectConnectedProps & CreateProjectOwnProps;

const CreateProject = (props: CreateProjectProps) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  const resetFormData = () => {
    setName("");
    setDescription("");
  };

  const createProject = () =>
    props
      .createProject({ name, description })
      .then((project: CRMProject) => {
        props.toggleModal().then(() => {
          showSuccessToast("Проект был успешно создан!");
          resetFormData();

          // Close modal
          props.toggleModal();

          // Redirect to the project page
          if (props.redirectAfterCreation) {
            props.push(constructCRMProjectPath(project));
          }
        });
      })
      .catch(() => {
        showErrorToast("Проект с таким названием уже существует.");
      });

  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    return createProject();
  };

  const onNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setName(e.target.value),
    [setName],
  );

  const onDescriptionChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => setDescription(e.target.value),
    [setDescription],
  );

  return (
    <Modal
      isOpen={props.isShown}
      toggle={props.toggleModal}
      className="modal-dialog-centered"
      size="md"
      contentClassName="alert alert-primary"
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={props.toggleModal}>Создать проект</ModalHeader>
        <ModalBody>
          <FormGroup>
            <label>Название проекта</label>
            <input
              type="text"
              value={name}
              onChange={onNameChange}
              className="form-control"
              required={true}
            />
          </FormGroup>
          <FormGroup>
            <label>Описание проекта</label>
            <textarea
              value={description}
              onChange={onDescriptionChange}
              className="form-control"
              required={true}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success" disabled={props.fetching}>
            {props.fetching ? "Подождите..." : "Создать проект"}
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  fetching: projectsModule.selectors.isProjectCreationInProcess(store, null),
});

export default connect(mapStateToProps, {
  createProject: projectsModule.actions.createProject,
  push,
})(CreateProject);
