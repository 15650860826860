import { PrimaryKey } from "#/store/types";
import { Dispatch } from "redux";
import MutationType from "./mutationType";

const setCurrentUser = (id: PrimaryKey | null) => (dispatch: Dispatch, getState: any) =>
  new Promise((resolve, reject) => {
    dispatch({ type: MutationType.SET_CURRENT_USER, payload: { id } });
    resolve();
  });

export default {
  setCurrentUser,
};
