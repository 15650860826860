import { apiGet, apiPost, apiPut } from "#/api";
import PageWrapper, { PageContent } from "#/components/PageWrapper";
import { showErrorToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import { APIDate, PrimaryKey, Top50Event, Top50Project, Top50QueryPage } from "#/store/types";
import { compareTwoBooleans, compareTwoStringDates, formatDateLong } from "#/util";
import { constructTop50ProjectsListPath } from "#/util/paths";
import { useSelectionRegistry } from "#/util/selectionRegistry";
import assert from "assert";
import { push } from "connected-react-router";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteComponentProps, Switch } from "react-router-dom";
import { Top50ProjectGraph } from "../../types";
import Header from "../Header";
import ProjectInfoPage from "./components/ProjectInfo";
import ProjectContext, { ProjectFetchConfig } from "./ProjectContext";
import ProjectStatsPage from "./components/ProjectStats";

export interface ProjectConnectedProps extends RouteComponentProps<{ projectId: string }> {
  push: typeof push;
}

export interface ProjectOwnProps {}

type ProjectProps = ProjectConnectedProps & ProjectOwnProps;

const Project = (props: ProjectProps) => {
  const projectId = _.toNumber(props.match.params.projectId);

  // Loading flags
  const [loading, setLoading] = useState<boolean>(false);
  const [graphLoading, setGraphLoading] = useState<boolean>(false);
  const [addingGroups, setAddingGroups] = useState<boolean>(false);
  const [fetching, setFetching] = useState<boolean>(false);

  // Graph
  const [graph, setGraph] = useState<Top50ProjectGraph | null>(null);
  const isGraphAvailable: boolean = React.useMemo(() => !!graph && graph.graph.length > 0, [graph]);
  const [graphDates, setGraphDates] = useState<string[]>([]);

  // Project data
  const [queryPositionsDataPresent, setQueryPositionsDataPresent] = useState<boolean>(false);
  const [project, setProject] = useState<Top50Project | null>(null);
  const [projectQueriesById, setProjectQueriesById] = useState<Record<PrimaryKey, Top50QueryPage>>(
    {},
  );
  const [projectGroups, setProjectGroups] = useState<Top50Event[]>([]);
  const groupSelection = useSelectionRegistry(
    {},
    projectGroups.map(a => a.pk),
    [projectGroups],
  );

  // Fetch project info & graph
  const [projectFetchSettings, setProjectFetchSettings] = useState<ProjectFetchConfig | null>(null);

  const [loadingConfiguratinos, setLoadingConfigurations] = useState<boolean>(false);
  const [supportedConfigs, setSupportedConfigs] = useState<PrimaryKey[]>([]);

  const projectQueries: Top50QueryPage[] = useMemo(() => {
    let qpages: Top50QueryPage[] = projectGroups
      .reduce(
        (acc: any[], event: Top50Event) =>
          _.concat(
            acc,
            _.compact(
              event.queries.map(queryPk => {
                const qpageData = _.get(projectQueriesById, queryPk, null);
                if (qpageData === null) {
                  return null;
                }
                // Insert event information into the query for easier access
                return {
                  ...qpageData,
                  event_model: {
                    ..._.pickBy(event, (v, k) => k !== "queries"),
                  },
                };
              }),
            ),
          ),
        [],
      )
      // Sort by the expiration date
      .sort((a: Top50QueryPage, b: Top50QueryPage) =>
        compareTwoStringDates(a.event_model!.finishdate, b.event_model!.finishdate),
      )
      // Group by whether a query is generic (has exp date) or not
      .sort(
        (a: Top50QueryPage, b: Top50QueryPage) =>
          -1 * compareTwoBooleans(!!a.event_model!.finishdate, !!b.event_model!.finishdate),
      );
    if (queryPositionsDataPresent) {
      // TODO: Use functional approach
      qpages = qpages.map((item: Top50QueryPage) => {
        const itemPositions = item.query_model ? item.query_model!.positions : [];
        if (!itemPositions) {
          return item;
        }
        graphDates.forEach((date: string, dateIndex: number) => {
          const dateString = formatDateLong(new Date(date));
          const currentPosition = itemPositions.find(
            (qry: any) => formatDateLong(qry.pub_date) === dateString,
          );
          if (!currentPosition) {
            item.query_model![`pos${dateIndex}`] = null;
          } else if (formatDateLong(currentPosition.pub_date) === dateString) {
            item.query_model![`pos${dateIndex}`] = currentPosition.position;
          }
        });
        return item;
      });
    }
    return qpages;
  }, [projectGroups, projectQueriesById, graphDates, queryPositionsDataPresent]);

  // Fetch project graph
  const fetchGraph = React.useCallback(
    async (daysCount: number, config: PrimaryKey) => {
      setGraphLoading(true);
      try {
        const graphResponse = await apiGet(API_ROUTES.TOP50.PROJECT_GRAPH(projectId), {
          days_count: daysCount,
          config,
        });
        setGraph(graphResponse);
        setGraphDates(graphResponse.dates);
        return true;
      } finally {
        setGraphLoading(false);
      }
    },
    [projectId],
  );

  const fetchProject = React.useCallback(async () => {
    setLoading(true);
    if (projectFetchSettings === null) {
      console.warn("Tried to fetch project info without a specified fetch configuration");
      return;
    }
    const daysCount = projectFetchSettings.daysCount;
    const fetchPositions = projectFetchSettings.fetchPositions;
    const config = projectFetchSettings.config;
    try {
      // Fetch available project configurations
      setLoadingConfigurations(true);
      try {
        const sc: PrimaryKey[] = await apiGet(API_ROUTES.TOP50.PROJECT_CONFIGARTIONS(projectId));
        setSupportedConfigs(sc);
      } catch (err) {
        showErrorToast("Произошла ошибка при попытке получить конфигурации");
      } finally {
        setLoadingConfigurations(false);
      }

      // Fetch project info
      const response: {
        config: PrimaryKey;
        project: Top50Project;
        project_events: Top50Event[];
        project_query_pages: Top50QueryPage[];
        now: APIDate;
      } = await apiGet(API_ROUTES.TOP50.PROJECT_FULL_INFO(projectId), {
        include_positions: fetchPositions,
        days_count: daysCount,
        ...(config ? { config } : {}),
      });
      if (fetchPositions) {
        setQueryPositionsDataPresent(true);
      } else {
        setQueryPositionsDataPresent(false);
      }
      setProject(response.project);
      setProjectGroups(response.project_events);
      // Query pages record has it's related query as a primary key
      const queriesById = response.project_query_pages.reduce(
        (acc, q) => ({ ...acc, [_.toNumber(q.query)]: q }),
        {},
      );
      setProjectQueriesById(queriesById);
      fetchGraph(daysCount, response.config);
    } catch (err) {
      console.error(err);
      props.push(constructTop50ProjectsListPath());
    } finally {
      setLoading(false);
    }
  }, [projectId, fetchGraph, projectFetchSettings]);

  useEffect(() => {
    if (!_.isEqual(projectFetchSettings, null)) {
      fetchProject();
    }
  }, [projectFetchSettings]);

  const removeGroups = useCallback(
    async (pks: PrimaryKey[]) => {
      if (!project) {
        return false;
      }
      setFetching(true);
      try {
        const response = apiPost(API_ROUTES.TOP50.PROJ_REMOVE_EVENTS(project!.pk), {
          events: pks,
        });
        fetchProject();
        return true;
      } finally {
        setFetching(false);
      }
    },
    [project, fetchProject],
  );

  const addGroupsToProject = useCallback(
    async (pks: PrimaryKey[], configPk: PrimaryKey) => {
      if (!project) {
        return false;
      }
      setFetching(true);
      try {
        const response = await apiPost(API_ROUTES.TOP50.PROJ_ADD_EVENTS(project!.pk), {
          events: pks,
          config: configPk,
        });
        fetchProject();
        return true;
      } finally {
        setFetching(false);
      }
    },
    [project, fetchProject],
  );

  const queryPagePKToQueryPK = React.useCallback(
    (qpagePk: PrimaryKey): PrimaryKey | null => {
      const mapping = _.values(projectQueriesById).reduce(
        (acc, v) => ({ ...acc, [v.pk]: v.query }),
        {},
      );
      const pk = _.get(mapping, qpagePk, null);
      return pk as PrimaryKey;
    },
    [projectQueriesById],
  );

  const editQuery = useCallback(
    async (pk: PrimaryKey, payload: Partial<Top50QueryPage>) => {
      setFetching(true);
      try {
        const updatedQuery = await apiPut(API_ROUTES.TOP50.QUERYPAGES_ITEM(pk), payload);
        const queryPk = queryPagePKToQueryPK(pk);
        setProjectQueriesById({
          ...projectQueriesById,
          [queryPk!]: {
            ..._.get(projectQueriesById, queryPk!, {}),
            ...payload,
          } as Top50QueryPage,
        });
        return true;
      } finally {
        setFetching(false);
      }
    },
    [projectId, projectQueriesById],
  );

  const massQueryPageUrlChange = async (pks: PrimaryKey[], url: string) => {
    setFetching(true);
    try {
      await apiPost(API_ROUTES.TOP50.QUERYPAGES_URLS, { pks, url });

      const projectQueries = Object.values(projectQueriesById);
      setProjectQueriesById({
        ...projectQueriesById,
        ...pks.reduce((acc, _pk) => {
          const pk = queryPagePKToQueryPK(_pk);
          return {
            ...acc,
            [pk!]: {
              ..._.get(projectQueriesById, pk!, {}),
              url,
            },
          };
        }, {}),
      });
      return true;
    } finally {
      setFetching(false);
    }
  };

  const getQueryPage = React.useCallback(
    (pk: PrimaryKey) => {
      const qpk = queryPagePKToQueryPK(pk);
      if (qpk) {
        return projectQueriesById[qpk];
      }
      return null;
    },
    [projectQueriesById, queryPagePKToQueryPK],
  );

  return (
    <ProjectContext.Provider
      value={{
        getQueryPage,
        fetching,
        fetchProject,
        graph,
        projectQueries,
        massQueryPageUrlChange,
        project,
        graphLoading,
        projectGroups,
        groupSelection,
        removeGroups,
        addGroupsToProject,
        projectQueriesById,
        isGraphAvailable,
        projectLoading: loading,
        graphDates,
        fetchGraph,
        projectId,
        editQuery,
        setProject,
        supportedConfigs,
        projectFetchConfig: projectFetchSettings,
        setProjectFetchConfig: setProjectFetchSettings,
      }}
    >
      <PageWrapper>
        <Header />
        <PageContent>
          <Switch>
            <Route exact={true} path={`${props.match.url}`} component={ProjectInfoPage} />
            <Route exact={true} path={`${props.match.url}/stats`} component={ProjectStatsPage} />
          </Switch>
        </PageContent>
      </PageWrapper>
    </ProjectContext.Provider>
  );
};

export default connect(null, { push })(Project);
