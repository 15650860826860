import { WS_API_TASK_CHAT_URL } from "#/conf/api";
import * as sessionModule from "#/store/modules/session";
import taskModule from "#/store/modules/task";
import MutationType from "#/store/modules/task/mutationType";
import selectors from "#/store/modules/task/selectors";
import tasksModule from "#/store/modules/tasks";
import { ChatMessage } from "#/store/types";
import assert from "assert";
import _ from "lodash";
import { Dispatch } from "redux";

export const closeTaskChatWebSocket = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    if (selectors.getTaskChatWS(getState()) === null) {
      return resolve();
    }
    const ws = selectors.getTaskChatWS(getState());
    ws!.close();
    dispatch({
      type: MutationType.SET_TASK_CHAT_WEBSOCKET,
      payload: { ws: null },
    });
    return resolve();
  });
};

export const setupTaskChatWebSocket = () => (dispatch: Dispatch, getState: any) => {
  const taskId = selectors.getCurrentTaskId(getState());
  assert(taskId !== null, "Task is not set");
  assert(selectors.getTaskChatWS(getState()) === null, "Web socket is already opened");
  assert(
    sessionModule.selectors.isAuthenticated(getState(), null),
    "Can't create task chat socket not authenticated",
  );

  return new Promise((resolve, reject) => {
    type WSMessageHandler = (messageData: any) => void;
    type WSMessageType = "TASK_MESSAGE_ADDED";
    type MessageAdded = ChatMessage;

    const ws = new WebSocket(WS_API_TASK_CHAT_URL(taskId!));

    const handlers: Record<WSMessageType, WSMessageHandler> = {
      TASK_MESSAGE_ADDED: (msg: MessageAdded) => {
        dispatch(tasksModule.actions.addTaskChatMessageSucceed(msg));
      },
    };

    const handleMainWSMessage = (messageType: WSMessageType, messageData: any) => {
      if (!(messageType in handlers)) {
        console.error(`handleMainWSMessage: Message type ${messageType} is not handled currently.`);
        return false;
      }
      const f = _.get(handlers, messageType, () => {
        console.error(`Unhanbled WS message: ${messageType}`);
      });
      return f(messageData);
    };

    // TODO: Should we handle this event?
    // ws.addEventListener("open", event => {});

    ws.addEventListener("close", event => {
      if (!selectors.isTaskChatWSClosed(getState())) {
        dispatch(closeTaskChatWebSocket() as any);
      }
    });

    ws.addEventListener("message", event => {
      const message = event.data;
      const messageJson = JSON.parse(message);
      const messageType = messageJson.type;
      const messageData = messageJson.message;
      handleMainWSMessage(messageType, messageData);
    });

    dispatch({
      type: MutationType.SET_TASK_CHAT_WEBSOCKET,
      payload: { ws },
    });
    return resolve();
  });
};

const fetchTaskChatMessages = () => (dispatch: Dispatch, getState: any) => {
  const currTaskId = taskModule.selectors.getCurrentTaskId(getState());
  assert(currTaskId !== null);
  return dispatch(tasksModule.actions.fetchTaskChatMessages(currTaskId!) as any);
};

export default {
  setupTaskChatWebSocket,
  closeTaskChatWebSocket,
  fetchTaskChatMessages,
};
