import {
  PrimaryKey,
  StoreRootState,
  User,
  UserData,
  UserRole,
  UserRolesById,
  UsersById,
  UsersByIdBare,
  UsersState,
} from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

type Props = {
  id: PrimaryKey;
} | null;

// Root
const getUsers = (store: StoreRootState, props: Props) => store.users;
const getProps = (store: StoreRootState, props: Props) => props;

// Roles
const getUserRolesById = createSelector(
  [getUsers],
  (users: UsersState): UserRolesById => users.rolesById,
);

const getAllUserRoles = createSelector(
  [getUserRolesById],
  (roles: UserRolesById): UserRole[] => _.keys(roles).map((pk: string) => _.get(roles, pk)),
);

// Users
const getUsersById = createSelector(
  [getUsers, getProps],
  (users: UsersState, props: Props) => users.usersById,
);

// Users
const getUsersByIdBare = createSelector(
  [getUsers],
  (users: UsersState): UsersByIdBare =>
    _.keyBy(
      _.compact(
        _.keys(users.usersById).map(pk => {
          const userData = _.get(users.usersById, pk);
          if (!userData) {
            return null;
          }
          return _.get(userData, "data", null);
        }),
      ),
      "pk",
    ),
);

const getAllUsersBare = createSelector(
  [getUsers],
  (users: UsersState): User[] =>
    _.compact(_.values(users.usersById).map(a => (a.data ? a.data : null))),
);

const getUserWithId = createSelector(
  [getUsersById, getProps],
  (usersById: UsersById, props: Props) => (props ? _.get(usersById, props.id, null) : null),
);

const getUserWithIdBare = createSelector(
  [getUserWithId],
  (user: UserData | null): User | null => (user ? user.data : null),
);

const getAllUsersSortedByName = createSelector(
  [getUsersById],
  (usersById: UsersById) => _.sortBy(usersById, ["first_name", "last_name"]),
);

const getAllUsersSortedByNameBare = createSelector(
  [getAllUsersSortedByName],
  (users): User[] => users.map(user => (user ? user.data : null)).filter(a => !!a) as User[],
);

// Users deletion
const isCurrentlyRemovingUser = createSelector(
  [getUsers],
  (us: UsersState) => us.removingUser,
);

const didUserRemovalFail = createSelector(
  [getUsers],
  (us: UsersState) => us.removingUserFailed,
);

// User editing
const isCurrentlyEditingUser = createSelector(
  [getUsers],
  (us: UsersState) => us.editingUser,
);

const didUserEditFail = createSelector(
  [getUsers],
  (us: UsersState) => us.editingUser,
);

// User creation
const isCurrentlyCreatingUser = createSelector(
  [getUsers],
  (us: UsersState): boolean => us.creatingUser,
);

const didCreationEditFail = createSelector(
  [getUsers],
  (us: UsersState) => us.creatingUserFailed,
);

const isLoadingUsers = createSelector(
  [getUsers],
  (us: UsersState) => us.loadingUsers,
);

export default {
  getAllUsersBare,
  getUserRolesById,
  getAllUserRoles,
  getUsersById,
  getUserWithId,
  getUserWithIdBare,
  getAllUsersSortedByName,
  getAllUsersSortedByNameBare,
  isCurrentlyRemovingUser,
  didUserRemovalFail,
  isCurrentlyEditingUser,
  didUserEditFail,
  isCurrentlyCreatingUser,
  didCreationEditFail,
  isLoadingUsers,
  getUsersByIdBare,
};
