import { EMPTY_SELECTION_REGISTRY, SelectionRegistry } from "#/util/selectionRegistry";
import React from "react";
import { NON_REACHABLE_EMPTY_FUNCTION } from "#/util";
import { PrimaryKey } from "#/store/types";

export interface IProjectInfoContext {
  querySelection: SelectionRegistry;
  fetchEventGraph: (evid: PrimaryKey) => Promise<boolean>;
}

const initialProjectContext: IProjectInfoContext = {
  querySelection: EMPTY_SELECTION_REGISTRY,
  fetchEventGraph: NON_REACHABLE_EMPTY_FUNCTION,
};

const ProjectContext = React.createContext<IProjectInfoContext>(initialProjectContext);

export default ProjectContext;
