import projectsModule from "#/store/modules/projects";
import taskModule from "#/store/modules/task";
import tasksModule from "#/store/modules/tasks";
import usersModule from "#/store/modules/users";
import {
  ChatMessage,
  CRMProject,
  CRMProjectData,
  CRMTask,
  PrimaryKey,
  ProjectsById,
  TaskCheckListItem,
  TaskData,
  TasksById,
  User,
  UsersById,
} from "#/store/types";
import {
  compareTwoAPIFormatDates,
  compareTwoNumbers,
  compareTwoStringsAlphabetically,
  getTaskExecutor,
  userFullName,
} from "#/util";
import _ from "lodash";
import { createSelector } from "reselect";

const getCurrentTask = createSelector(
  [taskModule.selectors.getCurrentTaskId, tasksModule.selectors.getTasksById],
  (id: PrimaryKey | null, tasksById: TasksById) => {
    if (id !== null) {
      return _.get(tasksById, id, null);
    } else {
      return null;
    }
  },
);

const getCurrentTaskBare = createSelector(
  [getCurrentTask],
  (ct: TaskData | null) => (ct ? _.get(ct, "data", null) : null),
);

const getCurrentTaskChecklists = createSelector(
  [getCurrentTask],
  (ct: TaskData | null) => (ct ? _.get(ct, "checklists", []) : []),
);

const getCurrentTaskChecklistsSortedByStatus = createSelector(
  [getCurrentTaskChecklists],
  (l: TaskCheckListItem[]) => {
    const res = l.sort((a, b) => (a.status > b.status ? 1 : a.status < b.status ? -1 : 0));
    return res;
  },
);

const getCurrentTaskChecklistsSortedByPrimaryKey = createSelector(
  [getCurrentTaskChecklists],
  (l: TaskCheckListItem[]) => l.sort((a, b) => compareTwoNumbers(a.pk, b.pk)),
);

const getCurrentTaskChat = createSelector(
  [getCurrentTask],
  (ct: TaskData | null) => (ct ? _.get(ct, "chat", []) : []),
);

const getCurrentTaskChatSortedByDate = createSelector(
  [getCurrentTaskChat],
  (chatMessages: ChatMessage[]) =>
    chatMessages.sort((a, b) => compareTwoAPIFormatDates(a.date_create, b.date_create)),
);

const areThereNoMoreCurrentTaskMessagesToLoad = createSelector(
  [getCurrentTask],
  (ct: TaskData | null) => (ct ? ct.chatNext === null : false),
);

const getCurrentTaskProjectData = createSelector(
  [getCurrentTaskBare, projectsModule.selectors.getProjectsById],
  (ct: CRMTask | null, pid: ProjectsById): CRMProjectData | null =>
    ct ? _.get(pid, ct.project, null) : null,
);

const getCurrentTaskProject = createSelector(
  [getCurrentTaskBare, projectsModule.selectors.getProjectsById],
  (ct: CRMTask | null, pid: ProjectsById): CRMProject | null =>
    ct ? _.get(_.get(pid, ct.project, {}), "data", null) : null,
);

const getCurrentTaskUserFrom = createSelector(
  [getCurrentTaskBare, usersModule.selectors.getUsersById],
  (ct: CRMTask | null, pid: UsersById): User | null =>
    ct ? _.get(_.get(pid, ct.task_from, {}), "data", null) : null,
);

const getCurrentTaskExecutor = createSelector(
  [getCurrentTaskBare, usersModule.selectors.getUsersById],
  (ct: CRMTask | null, pid: UsersById): User | null => getTaskExecutor(ct, pid),
);

const getCurrentTaskProjectUsersSortedByName = createSelector(
  [getCurrentTaskProject, usersModule.selectors.getUsersById],
  (p: CRMProject | null, ubid: UsersById): User[] => {
    if (!p) { return []; }
    return _.compact(p.users.map(id => _.get(_.get(ubid, id, {}), "data", null))).sort((a, b) =>
      compareTwoStringsAlphabetically(userFullName(a), userFullName(b)),
    );
  },
);

export default {
  getCurrentTaskChatSortedByDate,
  getCurrentTaskChecklistsSortedByPrimaryKey,
  getCurrentTaskChecklistsSortedByStatus,
  getCurrentTask,
  getCurrentTaskBare,
  getCurrentTaskChecklists,
  getCurrentTaskProject,
  getCurrentTaskUserFrom,
  getCurrentTaskExecutor,
  getCurrentTaskProjectUsers: getCurrentTaskProjectUsersSortedByName,
  areThereNoMoreCurrentTaskMessagesToLoad,
  getCurrentTaskProjectData,
};
