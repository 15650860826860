import sessionModuleSelectors from "#/store/modules/session/selectors";
import usersModule from "#/store/modules/users";
import { CRMProject, PrimaryKey, ProjectsById, User, UserData, UsersById } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";
import { getProjectsById } from "../projects/selectors";

const getCurrentUserData = createSelector(
  [sessionModuleSelectors.getCurrentUserID, usersModule.selectors.getUsersById],
  (currentUserId: PrimaryKey | null, usersById: UsersById) => {
    if (currentUserId === null) {
      return null;
    }
    return _.get(usersById, currentUserId, null);
  },
);

const getCurrentUser = createSelector(
  [getCurrentUserData],
  (cud: UserData | null) => {
    if (cud) {
      return _.get(cud, "data", null);
    } else {
      return null;
    }
  },
);

const getCurrentUserProjects = createSelector(
  [getCurrentUser, getProjectsById],
  (cu: User | null, pid: ProjectsById): CRMProject[] => {
    if (!cu) {
      return [];
    }
    if (!cu.projects) {
      return [];
    }
    return _.compact(cu.projects.map(p => _.get(_.get(pid, p, {}), "data", null)));
  },
);

export default {
  getCurrentUserData,
  getCurrentUser,
  getCurrentUserProjects,
};
