import { Action, CommonFailAction, EmptyAction } from "#/store/types";

//////////////////////////////
// Action helpers
//////////////////////////////

export const emptyReduxActionWithType = function(type: string): () => EmptyAction {
  return () => ({
    type,
  });
};

export const commonFailAction = function(type: string): (error: Error) => CommonFailAction {
  return (error: Error) => ({
    type,
    payload: { error },
  });
};

//////////////////////////////
// Reducer helpers
//////////////////////////////

export type CreateReducerReducer<T, K> = (state: T, action: K) => T;

export type CreateReducerComponents<T, K> = Record<string, CreateReducerReducer<T, K>>;

export const createReducer = <T, A extends Action>(
  components: CreateReducerComponents<T, A & any>,
  initialState: T,
) => (state: T = initialState, action: A & any) =>
  components.hasOwnProperty(action.type) ? components[action.type](state, action) : state;

export const constructEmptyStartFetchActionReducer = function<S, A>(
  fetchingFieldName: string & keyof S,
  fetchingFailedFieldName: string & keyof S,
) {
  return function(state: S, _: A) {
    return {
      ...state,
      [fetchingFieldName]: true,
      [fetchingFailedFieldName]: false,
    };
  };
};

export const constructEmptyFailFetchActionReducer = function<S, A>(
  fetchingFieldName: string,
  fetchingFailedFieldName: string,
) {
  return function(state: S, _: A) {
    return {
      ...state,
      [fetchingFieldName]: false,
      [fetchingFailedFieldName]: true,
    };
  };
};

export const constructEmptySucceedFetchActionReducer = function<S, A>(
  fetchingFieldName: string,
  fetchingFailedFieldName: string,
) {
  return function(state: S, _: A) {
    return {
      ...state,
      [fetchingFieldName]: false,
      [fetchingFailedFieldName]: false,
    };
  };
};

//////////////////////////////
// High-level interface
//////////////////////////////

export const getActionsFromModule = function<MA>(
  m: { actions: MA },
  actionNames: Array<string & keyof MA>,
): Record<string & keyof MA, any> {
  type MAA = string & keyof MA;
  return actionNames.reduce(
    (acc: Record<MAA, any>, actionName: MAA) => ({
      ...acc,
      [actionName]: m.actions[actionName],
    }),
    {} as any,
  );
};
