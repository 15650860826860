import { MetrikaTop50State, YandexQuery } from "./types";
import { createReducer } from "#/store/helpers";
import { Action, PrimaryKey } from "#/store/types";
import ActionTypes from "./actionTypes";
import MutationTypes from "./mutationTypes";
import _ from "lodash";

export const initialState: MetrikaTop50State = {
  top50ProjectsById: {},
  top50EventsById: {},
  projectPages: {},
  loading: false,
  metrikaLoading: false,
  currentProjectPage: 1,
  apiFetchingFailed: false,
  metrikaFetchingFailed: false,
  currentProject: 0,
  currentEvent: 0,
  token: "AgAAAAAa0vmHAAXR3iToz7hZP0EysBR47dho07U",
  addingQuery: false,
  addingQueryFailed: false,
  queryTableOptions: {
    showExisting: false,
    minimum: 0,
    substring: "",
  },
};
export const reducer = createReducer<MetrikaTop50State, Action>(
  {
    [MutationTypes.FETCH_TOP50PROJECTS_START]: (
      state: MetrikaTop50State,
      action: ActionTypes.FetchTop50ProjectsStart,
    ) => ({
      ...state,
      loading: true,
      currentProjectPage: action.payload,
    }),

    [MutationTypes.FETCH_TOP50PROJECTS_SUCCEED]: (
      state: MetrikaTop50State,
      action: ActionTypes.FetchTop50ProjectsSucceed,
    ) => {
      // TODO: Rewrite into FP
      const projectPks: PrimaryKey[] = [];
      let eventsById = {};
      const projectsById = _.keyBy(
        action.payload.projects.map(proj => {
          const eventPks = proj.events.map(event => {
            const { pk: eventPk, ...eventData } = event;
            eventsById = {
              [eventPk]: {
                data: eventData,
              },
              ...eventsById,
            };
            return eventPk;
          });
          projectPks.push(proj.pk);
          eventsById = {
            ...eventsById,
            ..._.keyBy(
              proj.events.map(ev => ({ data: ev })),
              x => x.data.pk,
            ),
          };
          return {
            data: {
              ...proj,
              events: eventPks,
              queries: [],
            },
          };
        }),
        x => x.data.pk,
      );

      return {
        ...state,
        top50EventsById: {
          ...state.top50EventsById,
          ...eventsById,
        },
        top50ProjectsById: {
          ...state.top50ProjectsById,
          ...projectsById,
        },
        projectPages: {
          ...Array(action.payload.page_count)
            .fill(undefined)
            .reduce((acc, val, i) => ({ ...acc, [i + 1]: { projects: [] } }), {}),
          ...state.projectPages,
          [state.currentProjectPage]: {
            projects: projectPks,
          },
        },
        loading: false,
        apiFetchingFailed: false,
      };
    },

    [MutationTypes.FETCH_TOP50PROJECTS_FAIL]: (state: MetrikaTop50State) => ({
      ...state,
      apiFetchingFailed: true,
    }),

    [MutationTypes.SET_CURRENT_PROJECT]: (
      state: MetrikaTop50State,
      action: ActionTypes.SetCurrentMetrikaProject,
    ) => ({
      ...state,
      currentProject: action.payload,
    }),

    [MutationTypes.SET_CURRENT_EVENT]: (
      state: MetrikaTop50State,
      action: ActionTypes.SetCurrentMetrikaEvent,
    ) => ({
      ...state,
      currentEvent: action.payload,
    }),

    [MutationTypes.FETCH_EVENT_START]: (state: MetrikaTop50State) => ({
      ...state,
      loading: true,
    }),

    [MutationTypes.FETCH_EVENT_FAIL]: (state: MetrikaTop50State) => ({
      ...state,
      loading: false,
      apiFetchingFailed: true,
    }),

    [MutationTypes.FETCH_EVENT_SUCCEED]: (
      state: MetrikaTop50State,
      action: ActionTypes.FetchEventSucceed,
    ) => ({
      ...state,
      top50EventsById: {
        ...state.top50EventsById,
        [action.payload.pk]: {
          data: {
            name: action.payload.name,
            pk: action.payload.pk,
            queries: action.payload.queries,
          },
        },
      },
      top50ProjectsById: {
        ...state.top50ProjectsById,
        ..._.keyBy(
          action.payload.projects.map(proj => ({
            data: {
              ...proj,
              events: proj.events.map(ev => ev.pk),
              queries: [],
            },
          })),
          x => x.data.pk,
        ),
      },
      loading: false,
      apiFetchingFailed: false,
    }),

    [MutationTypes.FETCH_QUERIES_START]: (state: MetrikaTop50State) => ({
      ...state,
      metrikaLoading: true,
    }),

    [MutationTypes.FETCH_QUERIES_FAIL]: (state: MetrikaTop50State) => ({
      ...state,
      metrikaLoading: false,
      metrikaFetchingFailed: true,
    }),

    [MutationTypes.FETCH_QUERIES_SUCCEED]: (
      state: MetrikaTop50State,
      action: ActionTypes.FetchQueriesSucceed,
    ) => ({
      ...state,
      top50ProjectsById: {
        ...state.top50ProjectsById,
        [state.currentProject]: {
          data: {
            ...state.top50ProjectsById[state.currentProject].data,
            queries: action.payload.data.map(
              (x): YandexQuery => ({
                text: x.dimensions[0].name,
                visits: x.metrics[0],
              }),
            ),
          },
        },
      },
      metrikaLoading: false,
    }),

    [MutationTypes.ADD_QUERY_START]: (state: MetrikaTop50State) => ({
      ...state,
      addingQuery: true,
    }),

    [MutationTypes.ADD_QUERY_FAIL]: (state: MetrikaTop50State) => ({
      ...state,
      addingQuery: false,
      addingQueryFailed: true,
    }),

    [MutationTypes.ADD_QUERY_SUCCEED]: (
      state: MetrikaTop50State,
      action: ActionTypes.AddQueriesSucceed,
    ) => ({
      ...state,
      top50EventsById: {
        ...state.top50EventsById,
        [state.currentEvent]: {
          data: {
            ...state.top50EventsById[state.currentEvent].data,
            queries: [
              ...(state.top50EventsById[state.currentEvent].data.queries || []),
              ...action.payload.map(query => query.text),
            ],
          },
        },
      },
      addingQuery: false,
      addingQueryFailed: false,
    }),

    [MutationTypes.SET_MINIMUM_NUMBER_OF_VISITS]: (
      state: MetrikaTop50State,
      action: ActionTypes.SetMinimumNumberOfVisits,
    ) => ({
      ...state,
      queryTableOptions: {
        ...state.queryTableOptions,
        minimum: action.payload,
      },
    }),

    [MutationTypes.SET_SUBSTRING]: (
      state: MetrikaTop50State,
      action: ActionTypes.SetSubstring,
    ) => ({
      ...state,
      queryTableOptions: {
        ...state.queryTableOptions,
        substring: action.payload,
      },
    }),
  },
  initialState,
);

export default reducer;
