import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { PrimaryKey } from "#/store/types";
import { useDebounced } from "#/util/hooks";
import { useReactSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";
import { taskTypesById, TaskType, TaskTypeInfo } from "#/conf/strings";

type ControllableTaskTypeSelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: TaskType | null;
  onChange: (p: TaskType | null) => void;
};

type TaskTypeDisplayFunction = (tt: TaskTypeInfo | null) => string;

const defaultDF = (tt: TaskTypeInfo | null) => (tt ? tt.name : "");

const ControllableTaskTypeSelect = (props: ControllableTaskTypeSelectProps) => {
  const df: TaskTypeDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;
  const selectState = useReactSelect<string, TaskTypeInfo>(
    _.values(taskTypesById),
    taskTypesById,
    df,
    props.value,
    { pkFieldName: "tag" },
    [df, props.value],
  );
  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={false}
      options={selectState.options}
      value={selectState.selected}
      onChange={(option: SelectOption) => {
        props.onChange(option.value ? (option.value as TaskType) : null);
      }}
    />
  );
};

export default ControllableTaskTypeSelect;
