import React from "react";
import { Top50Configuration } from "#/store/types";

export interface ITop50GlobalContext {
  queryDataColStatus: boolean;
  defaultConfiguration: Top50Configuration | null;
  queryDataColProgress: number;
}

const Top50GlobalContext = React.createContext<ITop50GlobalContext>({
  queryDataColStatus: true,
  defaultConfiguration: null,
  queryDataColProgress: -1,
});

export default Top50GlobalContext;
