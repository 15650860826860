import ResourceDeletionModal from "#/components/ResourceDeletionModal";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import Strings from "#/conf/strings";
import { canUser, canUserPerformAll, mdAny } from "#/permissions";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import usersModule from "#/store/modules/users";
import { PrimaryKey, StoreRootState, User } from "#/store/types";
import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ListItem from "./components/ListItem";
import UserInvitationModal from "./components/UserInvitationModal";
import style from "./index.module.scss";

interface ConnectedStateProps {
  currentUser: User | null;
  users: User[];
  loading: boolean;
  fetchProfilesList: any;
}

export interface OwnProps {
  currentUser: User | null;
  users: User[];
  loading: boolean;
  fetchProfilesList: any;
  editUserInfo: any;
  removeUser: any;
}

type Props = OwnProps & ConnectedStateProps;

const ListProfiles = (props: Props) => {
  const [invitationLinkCreationModalShown, setInvitationLinkCreationModalShown] = useState(false);
  const showInvitationLinkCreationModal = () => setInvitationLinkCreationModalShown(true);
  const toggleInvitationLinkCreationModalShown = () =>
    setInvitationLinkCreationModalShown(!invitationLinkCreationModalShown);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [id, setId] = useState<PrimaryKey | null>(null);

  // On load
  useEffect(() => {
    props.fetchProfilesList();
  }, []);

  const toggleModal = () => setModalOpen(!isModalOpen);

  const setActive = (userId: PrimaryKey) => {
    setId(userId);
    setModalOpen(true);
  };

  const onSave = (item: User) =>
    props.editUserInfo(item.pk, item).then(() => {
      showSuccessToast(Strings.USER_EDIT_SUCCESS(item.first_name));
    });

  const onRemove = (userId: PrimaryKey | null) => {
    if (userId !== null) {
      props
        .removeUser(userId)
        .then(() => {
          showSuccessToast(Strings.USER_REMOVE_SUCCESS);
          setId(null);
          setModalOpen(false);
        })
        .catch(() => {
          showErrorToast(Strings.USER_REMOVE_FAIL);
        });
    }
  };

  return (
    <div>
      <section className={classnames(["content-header", style["profiles-list-header"]])}>
        <h1>
          Список \ <small>Пользователи</small>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/projects">
              <i className="fa fa-dashboard" />
            </Link>
          </li>
        </ol>
        {canUser(props.currentUser, "INVITE_USERS") && (
          <button className="btn btn-list" onClick={showInvitationLinkCreationModal}>
            <i className="fas fa-user-plus" />
            <span className="ml-2">Пригласить нового пользователя</span>
          </button>
        )}
      </section>

      {/* Items list */}

      <div>
        <TransitionGroup key="1">
          <CSSTransition
            classNames="mask"
            appear={true}
            timeout={{
              enter: 0,
              exit: 300,
            }}
          >
            <section className="content">
              <div className="row">
                <div className="col-12">
                  <div className="box">
                    <div className="row no-gutters py-2">
                      <div className="box-body border-light br-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th />
                              <th>Имя</th>
                              <th>Почта</th>
                              <th>Телефон</th>
                              <th>Telegram</th>
                              <th>Возраст</th>
                              <th>Дата регистрации</th>
                              {canUserPerformAll(props.currentUser, [
                                ["EDIT", "USER", { objs: props.users, many: true, mdfn: mdAny }],
                                ["DELETE", "USER", { objs: props.users, many: true, mdfn: mdAny }],
                              ]) && <th>Действия</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {props.users.map((el, i) => {
                              return (
                                <ListItem
                                  key={i}
                                  onSave={onSave}
                                  setActive={setActive}
                                  item={el}
                                  currentUser={props.currentUser!}
                                />
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </CSSTransition>
        </TransitionGroup>

        <ResourceDeletionModal
          isModalOpen={isModalOpen}
          toggleModal={toggleModal}
          onRemove={() => onRemove(id)}
          whatToDelete="пользователя"
          removeString="УДАЛИТЬ"
        />
      </div>

      {canUser(props.currentUser, "INVITE_USERS") && (
        <UserInvitationModal
          isOpen={invitationLinkCreationModalShown}
          toggle={toggleInvitationLinkCreationModalShown}
        />
      )}
    </div>
  );
};

export default connect(
  (store: StoreRootState) => ({
    users: usersModule.selectors.getAllUsersSortedByNameBare(store, null),
    loading: usersModule.selectors.isLoadingUsers(store, null),
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  }),
  { ...getActionsFromModule(usersModule, ["fetchProfilesList", "editUserInfo", "removeUser"]) },
)(ListProfiles);
