import store from "#/store";
import * as sessionModuleSelectors from "#/store/modules/session/selectors";
import { PaginationCursor, StoreRootState } from "#/store/types";
import _ from "lodash";

// eslint-disable-next-line
type JSONType = any;

////////////////////////////////////////////////
// Exceptions
////////////////////////////////////////////////

export type APIErrorCode =
  | "GENERIC_ERROR"
  | "INVALID_REQUEST"
  | "NETWORK_ERROR"
  | "VALIDATION_ERROR"
  | "PROGRAMMING_ERROR"
  | "ACCESS_FORBIDDEN";

export interface APIError extends Error {
  name: APIErrorCode;
}

export interface APINetworkError extends APIError {
  message: string;
}

export interface APIAccessForbiddenError extends APIError {
  message: string;
}

export const constructAPINetworkError = (message = "Ошибка сети"): APINetworkError => ({
  name: "NETWORK_ERROR",
  message,
});

export const constructAPIAccessForbiddenError = (message: string): APINetworkError => ({
  name: "ACCESS_FORBIDDEN",
  message,
});

export interface APIProgrammingError extends APIError {
  message: string;
}

export const constructAPIProgrammingError = (message: string): APIProgrammingError => ({
  name: "PROGRAMMING_ERROR",
  message,
});

export interface APIGenericError extends APIError {
  message: string;
}

export const constructAPIGenericError = (message: string): APIGenericError => ({
  name: "GENERIC_ERROR",
  message,
});

export interface APIInvalidRequestError extends APIError {
  message: string;
}

export const constructAPIInvalidRequestError = (message: string): APIInvalidRequestError => ({
  name: "INVALID_REQUEST",
  message,
});

export interface APIValidationError extends APIError {
  // Map from field name to field validation errors
  validationErrors: Record<string, string[]>;
}

export const constructAPIValidationError = (
  message: Record<string, string[]>,
): APIValidationError => ({
  name: "VALIDATION_ERROR",
  validationErrors: message,
  message: (() => {
    // Take the first validation problem
    const firstFieldName = _.get(_.keys(message), 0, null);
    if (firstFieldName === null) {
      console.error("There are no validation errors in the JSON reponse.");
      return "Unknown";
    }
    const errMsg = message[firstFieldName][0];
    return errMsg;
  })(),
});

export const apiErrorToString = (err: APIError) => {
  return err.message;
};

const constructAPIErrorFromJSONResponse = (json: JSONType) => {
  const errorCode: APIErrorCode = json.code as APIErrorCode;
  const errorMessage = json.error;
  switch (errorCode) {
    case "INVALID_REQUEST":
      throw constructAPIInvalidRequestError(errorMessage);
    case "VALIDATION_ERROR":
      throw constructAPIValidationError(errorMessage as Record<string, string[]>);
    case "GENERIC_ERROR":
      throw constructAPIGenericError(errorMessage);
    case "ACCESS_FORBIDDEN":
      throw constructAPIAccessForbiddenError(errorMessage);
    default:
      console.error(`Invalid API error code received: ${errorCode}`);
  }
};

////////////////////////////////////////////////
// Request helpers
////////////////////////////////////////////////

export const isApiResponseFailed = (json: JSONType) => json.status === "failed";

export const isApiResponseOk = (json: JSONType) => !isApiResponseFailed(json);

export const apiResponseGetErrorMessage = (json: JSONType): string => json.error as string;

const constsructGenericReqHeaders = () => ({
  "Content-Type": "application/json",
});

const constructGenericMultipartHeaders = () => ({});

const constructPostReqHeaders = (authToken: string | null) => ({
  ...constsructGenericReqHeaders(),
  ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
});

const constructDeleteReqHeaders = (authToken: string | null) => ({
  ...constsructGenericReqHeaders(),
  ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
});

const constructGetReqHeaders = (authToken: string | null) => ({
  ...constsructGenericReqHeaders(),
  ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
});

const constructPutReqHeaders = (authToken: string | null) => ({
  ...constsructGenericReqHeaders(),
  ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
});

const constructPutMultipartHeaders = (authToken: string | null) => ({
  ...constructGenericMultipartHeaders(),
  ...(authToken ? { Authorization: `Token ${authToken}` } : {}),
});

const handleApiJsonPossibleErrors = (json: JSONType) => {
  if (isApiResponseFailed(json)) {
    throw constructAPIErrorFromJSONResponse(json);
  } else {
    return json;
  }
};

export const apiPost = (apiPath: string, body = {}) => {
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  return fetch(apiPath, {
    method: "POST",
    headers: constructPostReqHeaders(token),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Произошла ошибка при обработке POST запроса: ${response.status}`);
      }
      return response.json();
    })
    .catch(() => {
      throw constructAPINetworkError();
    })
    .then(handleApiJsonPossibleErrors);
};

export const multipartApiPost = (apiPath: string, body: FormData) => {
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  return fetch(apiPath, {
    method: "POST",
    headers: constructPutMultipartHeaders(token),
    body,
  })
    .then(response => {
      if (!response.ok) {
        throw constructAPINetworkError(
          `Произошла ошибка при обработке PUT запроса: ${response.status}`,
        );
      }
      return response.json();
    })
    .catch(() => {
      throw constructAPINetworkError();
    });
};

export const apiPut = (apiPath: string, body = {}) => {
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  return fetch(apiPath, {
    method: "PUT",
    headers: constructPutReqHeaders(token),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        throw constructAPINetworkError(
          `Произошла ошибка при обработке PUT запроса: ${response.status}`,
        );
      }
      return response.json();
    })
    .catch(() => {
      throw constructAPINetworkError();
    })
    .then(handleApiJsonPossibleErrors);
};

export const apiDelete = (apiPath: string) => {
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  return fetch(apiPath, {
    method: "DELETE",
    headers: constructDeleteReqHeaders(token),
  })
    .then(response => {
      if (response.ok || response.status === 204) {
        return true;
      }
      throw constructAPINetworkError(
        `Произошла ошибка при обработке DELETE запроса: ${response.status}`,
      );
    })
    .catch(() => {
      throw constructAPINetworkError();
    })
    .then(handleApiJsonPossibleErrors);
};

type RequestQueryParameters = Record<string, string | boolean | number>;

const constructURLFromPathAndQueryParams = (
  p: string,
  queryParams?: RequestQueryParameters,
  encode = true,
) => {
  if (!queryParams) {
    return p;
  }
  const s = _.compact(
    Object.entries(queryParams).map(([k, v]) => {
      if (typeof v === "boolean") {
        const vb = v ? "1" : "0";
        return `${k}=${vb}`;
      } else if (typeof v === "string") {
        const encodedValue = encode ? encodeURIComponent(v) : v;
        return v.length > 0 ? `${k}=${encodedValue}` : null;
      } else if (typeof v === "number") {
        const vn = v.toString();
        return `${k}=${vn}`;
      }
      return null;
    }),
  );
  return s.length > 0 ? `${p}?${s.join("&")}` : p;
};

interface GetRequestOptions {
  encodeQueryParams: boolean;
}

export const apiGet = (
  apiPath: string,
  queryParams?: RequestQueryParameters,
  options?: GetRequestOptions,
) => {
  const actualOptions = options || {};
  const encodeQueryParams = _.get(actualOptions, "encodeQueryParams", true);
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  const requestURL = constructURLFromPathAndQueryParams(apiPath, queryParams, encodeQueryParams);
  return fetch(requestURL, {
    method: "GET",
    headers: constructGetReqHeaders(token),
  })
    .then(response => {
      if (!response.ok) {
        throw constructAPINetworkError(
          `Произошла ошибка при обработке GET запроса: ${response.status}`,
        );
      }
      return response.json();
    })
    .catch(() => {
      throw constructAPINetworkError();
    })
    .then(handleApiJsonPossibleErrors);
};

export const apiGetPaginated = (
  apiPath: string,
  prevCursor: PaginationCursor,
  nextCursor: PaginationCursor,
  queryParams?: RequestQueryParameters,
  options?: GetRequestOptions,
) => {
  return apiGet(
    apiPath,
    {
      ...(nextCursor ? { cursor: nextCursor } : {}),
      ...(queryParams ? queryParams : {}),
    },
    options ? options : { encodeQueryParams: false },
  );
};

export const apiPatch = (apiPath: string, body = {}) => {
  const token = sessionModuleSelectors.getAuthToken(store.getState() as StoreRootState, null);
  return fetch(apiPath, {
    method: "PATCH",
    headers: constructGetReqHeaders(token),
    body: JSON.stringify(body),
  })
    .then(response => {
      if (!response.ok) {
        return constructAPINetworkError(
          `Произошла ошибка при обработке PATCH запроса ${response.status}`,
        );
      }
      return response.json();
    })
    .catch(() => {
      throw constructAPINetworkError();
    })
    .then(handleApiJsonPossibleErrors);
};

export const extractURLQueryParam = (url: string, requestedKey: string): string | null => {
  const qryIdx = _.findIndex(url, b => b === "?");
  if (qryIdx === -1) {
    return null;
  }
  const params = url.substr(qryIdx + 1);
  if (params.length === 0) {
    return null;
  }
  const paramPairs = params.split("&");
  for (const pair of paramPairs) {
    const [key, val] = pair.split("=");
    if (key === requestedKey) {
      return val;
    }
  }
  return null;
};

export const extractCursorFromURL = (url: string | null): PaginationCursor =>
  url ? extractURLQueryParam(url, "cursor") : null;

export const dateObjectToAPICompatible = (d: Date) => d.toISOString();

export const changeSheduledDays = (val: number, list: number[]) => list.indexOf(val) > -1 ? list.filter(i => i != val) : [...list, val];