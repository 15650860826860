enum MutationType {
  FETCH_PROJECTS_START = "monitoring/FETCH_PROJECTS_START",
  FETCH_PROJECTS_FAIL = "monitoring/FETCH_PROJECTS_FAIL",
  FETCH_PROJECTS_SUCCEED = "monitoring/FETCH_PROJECTS_SUCCEED",

  ADD_URLS_TO_PROJECT_START = "monitoring/ADD_URLS_TO_PROJECT_START",
  ADD_URLS_TO_PROJECT_FAIL = "monitoring/ADD_URLS_TO_PROJECT_FAIL",
  ADD_URLS_TO_PROJECT_SUCCEED = "monitoring/ADD_URLS_TO_PROJECT_SUCCEED",

  FETCH_PROJECT_START = "monitoring/FETCH_PROJECT_START",
  FETCH_PROJECT_FAIL = "monitoring/FETCH_PROJECT_FAIL",
  FETCH_PROJECT_SUCCEED = "monitoring/FETCH_PROJECT_SUCCEED",

  FETCH_CHECKEDURLS_FOR_PROJ_START = "monitoring/FETCH_CHECKEDURLS_FOR_PROJ_START",
  FETCH_CHECKEDURLS_FOR_PROJ_SUCCEED = "monitoring/FETCH_CHECKEDURLS_FOR_PROJ_SUCCEED",
  FETCH_CHECKEDURLS_FOR_PROJ_FAIL = "monitoring/FETCH_CHECKEDURLS_FOR_PROJ_FAIL",

  CREATE_PROJECT_START = "monitoring/CREATE_PROJECT_START",
  CREATE_PROJECT_SUCCEED = "monitoring/CREATE_PROJECT_SUCCEED",
  CREATE_PROJECT_FAIL = "monitoring/CREATE_PROJECT_FAIL",

  REMOVE_URLS_FROM_PROJECT_FAIL = "monitoring/REMOVE_URLS_FROM_PROJECT_FAIL",
  REMOVE_URLS_FROM_PROJECT_START = "monitoring/REMOVE_URLS_FROM_PROJECT_START",
  REMOVE_URLS_FROM_PROJECT_SUCCEED = "monitoring/REMOVE_URLS_FROM_PROJECT_SUCCEED",

  TOGGLE_URLS_START = "monitoring/TOGGLE_URLS_START",
  TOGGLE_URLS_FAIL = "monitoring/TOGGLE_URLS_FAIL",
  TOGGLE_URLS_SUCCEED = "monitoring/TOGGLE_URLS_SUCCEED",

  REMOVE_PROJECT_START = "monitoring/REMOVE_PROJECT_START",
  REMOVE_PROJECT_FAIL = "monitoring/REMOVE_PROJECT_FAIL",
  REMOVE_PROJECT_SUCCEED = "monitoring/REMOVE_PROJECT_SUCCEED",

  CHANGE_PROJECT_FILTERS_START = "monitoring/CHANGE_PROJECT_FILTERS_START",
  CHANGE_PROJECT_FILTERS_FAIL = "monitoring/CHANGE_PROJECT_FILTERS_FAIL",
  CHANGE_PROJECT_FILTERS_SUCCEED = "monitoring/CHANGE_PROJECT_FILTERS_SUCCEED",

  TOGGLE_URLS_FROM_PROJECT_START = "monitoring/TOGGLE_URLS_FROM_PROJECT_START",
  TOGGLE_URLS_FROM_PROJECT_FAIL = "monitoring/TOGGLE_URLS_FROM_PROJECT_FAIL",
  TOGGLE_URLS_FROM_PROJECT_SUCCEED = "monitoring/TOGGLE_URLS_FROM_PROJECT_SUCCEED",

  RUN_CHECK_PROCESS_START = "monitoring/RUN_CHECK_PROCESS_START",
  RUN_CHECK_PROCESS_FAIL = "monitoring/RUN_CHECK_PROCESS_FAIL",
  RUN_CHECK_PROCESS_SUCCEED = "monitoring/RUN_CHECK_PROCESS_SUCCEED",
  UPDATE_PROJECT_CHECK_PROGRESS = "monitoring/UPDATE_PROJECT_CHECK_PROGRES",

  SETUP_WS = "monitoring/SETUP_SITEWS",
  CLOSE_WS = "monitoring/CLOSE_SITEWS",

  SET_SUBSCRIBED_TO_PROJ = "monitoring/SET_SUBSCRIBED_TO_PROJ",
}

export default MutationType;
