import React, { useState, useCallback } from "react";
import { PrimaryKey } from "#/store/types";
import { Link } from "react-router-dom";

const ProjectItem = (props: {
  project: any;
  editProject: (pk: PrimaryKey, payload: any) => void;
}) => {
  const [apisource, setApisource] = useState<string>(props.project.apisource);
  const [project, setProject] = useState<any>(props.project);
  const changeProjectLink = useCallback(() => {
    props.editProject(project.pk, { apisource });
  }, [props.editProject, project, apisource]);
  const handleSubmit = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        changeProjectLink();
      }
    },
    [changeProjectLink],
  );
  const onApisourceChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setApisource(e.target.value),
    [setApisource],
  );
  return (
    <React.Fragment>
      <tr className="topf-project-list-item">
        <td className="minimum-column">
          <img src={"//favicon.yandex.net/favicon/" + project.name} alt="project fav" />
        </td>
        <td>
          <Link to={"/services/contentplan/projects/" + project.pk}>{project.name}</Link>
        </td>
        <td className="d-flex">
          <input
            type="text"
            className="form-control"
            value={apisource}
            onChange={onApisourceChange}
            onKeyDown={handleSubmit}
          />
          <button type="submit" className="btn btn-list row-action" onClick={changeProjectLink}>
            <i className="fas fa-pen-alt" />
          </button>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default ProjectItem;
