import {
  apiDelete,
  APIError,
  apiGet,
  apiGetPaginated,
  apiPost,
  apiPut,
  extractCursorFromURL,
} from "#/api";
import { API_ROUTES } from "#/conf/api";
import { commonFailAction, emptyReduxActionWithType } from "#/store/helpers";
import tasksModule from "#/store/modules/tasks";
import {
  ChatMessage,
  CRMTask,
  GetState,
  PrimaryKey,
  TaskChatMessage,
  TaskCheckListItem,
} from "#/store/types";
import assert from "assert";
import _ from "lodash";
import { Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

// Fetch tasks assigned to a particular user
const fetchUserTaskList = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.FETCH_TASKS_TO_USER_START, payload: { id } });
  return apiPost(API_ROUTES.CRMPLAN.TASKS_TO_USER, { user: id })
    .then((tasks: CRMTask[]) => {
      dispatch({
        type: MutationType.FETCH_TASKS_SUCCEED,
        payload: { tasks },
      });
      dispatch({
        type: MutationType.FETCH_TASKS_TO_USER_SUCCEED,
        payload: { id, tasks: tasks.map(t => t.pk) },
      });
    })
    .catch((error: APIError) => {
      dispatch({
        type: MutationType.FETCH_TASKS_FAIL,
        payload: { error },
      });
      throw error;
    });
};

// Fetch tasks user assigned
const fetchUserTaskFromList = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  return apiPost(API_ROUTES.CRMPLAN.TASKS_BY_USER, { user: id })
    .then((tasks: CRMTask[]) => {
      dispatch({
        type: MutationType.FETCH_TASKS_SUCCEED,
        payload: { tasks },
      });
      dispatch({
        type: MutationType.FETCH_TASKS_FROM_USER_SUCCEED,
        payload: { id, tasks: tasks.map(t => t.pk) },
      });
    })
    .catch((error: APIError) => {
      dispatch({
        type: MutationType.FETCH_TASKS_FAIL,
        payload: { error },
      });
      throw error;
    });
};

// Fetch task
const fetchTask = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.FETCH_TASK_START });
  return apiGet(API_ROUTES.CRMPLAN.TASKS_ITEM(id))
    .then((task: CRMTask) => {
      dispatch({
        type: MutationType.FETCH_TASK_SUCCEED,
        payload: { id: task.pk, task },
      });
      return task;
    })
    .catch((error: APIError) => {
      dispatch({
        type: MutationType.FETCH_TASK_FAIL,
        payload: { id, error },
      });
      throw error;
    });
};

// Remove task
export const removeTask = (pk: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.REMOVE_TASK_START });
  return apiDelete(API_ROUTES.CRMPLAN.TASKS_ITEM(pk))
    .then((json: {}) => {
      dispatch({
        type: MutationType.REMOVE_TASK_SUCCEED,
        payload: { id: pk },
      });
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.REMOVE_TASK_FAIL,
        payload: { id: pk, error },
      });
      throw error;
    });
};

// Edit task
export const editTask = (payload: { pk: PrimaryKey } & Partial<CRMTask>) => async (
  dispatch: Dispatch,
) => {
  dispatch({ type: MutationType.EDIT_TASK_START });
  try {
    const task = await apiPut(API_ROUTES.CRMPLAN.TASKS_ITEM(payload.pk), payload);
    dispatch({
      type: MutationType.EDIT_TASK_SUCCEED,
      payload: {
        id: payload.pk,
        task,
      },
    });
    return task;
  } catch (err) {
    dispatch({
      type: MutationType.EDIT_TASK_FAIL,
      error: err,
    });
    throw err;
  }
};

// Create task
const createTaskSucceed = (task: CRMTask): ActionTypes.CreateTaskSucceed => ({
  type: MutationType.CREATE_TASK_SUCCEED,
  payload: {
    task,
  },
});

const createTaskFail = commonFailAction(MutationType.CREATE_TASK_FAIL);

const createTaskStart = emptyReduxActionWithType(MutationType.CREATE_TASK_START);

export const createTask = (
  task: Partial<CRMTask>,
  initialChecklist: Array<Partial<TaskCheckListItem>> = [],
) => (dispatch: Dispatch, getState: any) => {
  dispatch(createTaskStart());
  return apiPost(API_ROUTES.CRMPLAN.TASKS, {
    task,
    initial_checklist: initialChecklist,
  })
    .then(createdTask => {
      dispatch(createTaskSucceed(createdTask));
      return createdTask;
    })
    .catch((error: APIError) => {
      dispatch(createTaskFail(error));
      throw error;
    });
};

// Add task checklist item
const addTaskChecklistItem = (id: PrimaryKey, payload: TaskCheckListItem) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({
    type: MutationType.ADD_TASK_CHECKLIST_ITEM_START,
    payload: { id },
  });
  return apiPost(API_ROUTES.CRMPLAN.CHECKLIST_ITEMS_MASS_CREATE, {
    task: id,
    items: payload,
  })
    .then((checklist: any) => {
      dispatch({
        type: MutationType.ADD_TASK_CHECKLIST_ITEM_SUCCEED,
        payload: { id, checklist },
      });

      return checklist;
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.ADD_TASK_CHECKLIST_ITEM_FAIL,
        payload: { id, error },
      });
      throw error;
    });
};

// Remove task checklist item
const removeTaskChecklistItem = (item: TaskCheckListItem) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.REMOVE_TASK_CHECKLIST_ITEM_START });
  return apiDelete(API_ROUTES.CRMPLAN.CHECKLIST_ITEM(item.pk))
    .then((json: {}) => {
      dispatch({
        type: MutationType.REMOVE_TASK_CHECKLIST_ITEM_SUCCEED,
        payload: { taskId: item.task, itemId: item.pk },
      });
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.REMOVE_TASK_CHECKLIST_ITEM_FAIL,
        payload: { error },
      });
      throw error;
    });
};

// Edit task checklist item
const editTaskChecklistItem = (
  id: PrimaryKey, // id of the checklist item in the database
  contents: Partial<TaskCheckListItem>,
) => (dispatch: Dispatch, getState: any) => {
  dispatch({ type: MutationType.EDIT_TASK_CHECKLIST_ITEM_START });
  return apiPut(API_ROUTES.CRMPLAN.CHECKLIST_ITEM(id), contents)
    .then(item => {
      dispatch({
        type: MutationType.EDIT_TASK_CHECKLIST_ITEM_SUCCEED,
        payload: { id: item.task, item },
      });
      return item;
    })
    .catch((error: Error) => {
      dispatch({
        type: MutationType.EDIT_TASK_CHECKLIST_ITEM_FAIL,
        payload: { error },
      });
      throw error;
    });
};

const addTaskChatMessage = (msg: ChatMessage) => ({
  type: MutationType.ADD_TASK_CHAT_MESSAGE,
  payload: { taskId: msg.task, msg },
});

const sendTaskMessage = (taskId: PrimaryKey, payload: TaskChatMessage) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch({ type: MutationType.SEND_TASK_CHAT_MESSAGE_START });
  return apiPost(API_ROUTES.CRMPLAN.TASK_CHAT_ADD_MSG(taskId), payload)
    .then((chatItem: any) => {
      dispatch({ type: MutationType.SEND_TASK_CHAT_MESSAGE_SUCCEED });
      return chatItem;
    })
    .catch((error: APIError) => {
      dispatch({ type: MutationType.SEND_TASK_CHAT_MESSAGE_FAIL });
      throw error;
    });
};

const fetchProjectTasks = (projectId: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  return apiGet(API_ROUTES.CRMPLAN.PROJECT_TASKS(projectId))
    .then((tasks: CRMTask[]) => {
      dispatch({
        type: MutationType.FETCH_TASKS_SUCCEED,
        payload: { tasks },
      });
      dispatch({
        type: MutationType.FETCH_PROJECT_TASKS,
        payload: { id: projectId, tasks: tasks.map(t => t.pk) },
      });
    })
    .catch((error: APIError) => {
      throw error;
    });
};

const fetchTaskChatMessages = (taskId: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  const task = _.get(tasksModule.selectors.getTasksById(getState()), taskId, null);
  const prev = task ? _.get(task, "chatPrev", null) : null;
  const next = task ? _.get(task, "chatNext", null) : null;
  return apiGetPaginated(API_ROUTES.CRMPLAN.TASK_CHAT_GET_ALL(taskId), prev, next)
    .then((json: { messages: ChatMessage[]; next: string; prev: string }) => {
      dispatch({
        type: MutationType.FETCH_TASK_CHAT_MESSAGES_SUCCEED,
        payload: {
          taskId,
          messages: json.messages,
          next: extractCursorFromURL(json.next),
          prev: extractCursorFromURL(json.prev),
        },
      });
      return json.messages;
    })
    .catch((error: APIError) => {
      throw error;
    });
};

export const becomeTaskExecutor = (taskId: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  return apiPost(API_ROUTES.CRMPLAN.TASK_BECOME_EXECUTOR(taskId))
    .then((updatedTask: CRMTask) => {
      dispatch({
        type: MutationType.FETCH_TASK_SUCCEED,
        payload: { id: taskId, task: updatedTask },
      });
      return updatedTask;
    })
    .catch(err => {
      throw err;
    });
};

const toggleTaskSubscription = (taskId: PrimaryKey) => (dispatch: Dispatch, getState: GetState) =>
  apiPut(API_ROUTES.CRMPLAN.TOGGLE_TASK_SUBSCRIPTION(taskId))
    .then((subscribed: boolean) => {
      dispatch({
        type: MutationType.SET_TASK_SUBSCRIBED,
        payload: { id: taskId, subscribed },
      });
      return subscribed;
    })
    .catch(err => {
      throw err;
    });

export default {
  addTaskChecklistItem,
  createTask,
  editTask,
  sendTaskMessage,
  removeTaskChecklistItem,
  removeTask,
  fetchUserTaskList,
  fetchUserTaskFromList,
  fetchTask,
  editTaskChecklistItem,
  fetchProjectTasks,
  addTaskChatMessageSucceed: addTaskChatMessage,
  fetchTaskChatMessages,
  becomeTaskExecutor,
  toggleTaskSubscription,
};
