import { apiGet, apiPost } from "#/api";
import Loader from "#/components/Loader";
import { API_ROUTES } from "#/conf/api";
import { Proxy } from "#/store/types";
import {
  copyToClipBoardEvent,
  doPaging,
  downloadBlob,
  handleDispatchErrorAndDisplayToast,
} from "#/util";
import React from "react";
import { ProxiesPayload } from "../../types";
import CreateProxyModal from "../CreateProxyModal";
import Header from "../Header";
import RandomProxyModal from "../RandomProxyModal";
import ProxyTableItem from "./ProxyTableItem";

// TODO: Rewrite this component in react hooks

interface ProxyIndexState {
  loading: boolean;
  proxies: Proxy[];
  page_count: number;
  current_page: number;
  proxyModalOpen: boolean;
  createProxyModalOpen: boolean;
}

interface ProxyIndexProps {}

class ProxyIndex extends React.Component<ProxyIndexProps, ProxyIndexState> {
  public state: ProxyIndexState = {
    loading: false,
    proxies: [],
    page_count: 0,
    current_page: 0,
    proxyModalOpen: false,
    createProxyModalOpen: false,
  };

  public componentDidMount() {
    this.getProxies();
  }

  public toggleCreateProxyModalOpen = () => {
    this.setState({
      ...this.state,
      createProxyModalOpen: !this.state.createProxyModalOpen,
    });
  };

  public getProxyForOmegaService = () => {
    const PROXY_COLOR = "#47b";
    const REVISION = "166aba67bb9";
    const { proxies } = this.state;

    const stringifiedProxies = proxies.reduce((acc: any, proxy: Proxy) => {
      const property = `+${proxy.ip}`;
      const nextObject = {
        auth: {
          fallbackProxy: {
            password: proxy.password,
            username: proxy.login,
          },
        },
        bypassList: [],
        color: PROXY_COLOR,
        fallbackProxy: {
          host: proxy.ip,
          port: parseInt(proxy.port, 10),
          scheme: proxy.type_value,
        },
        name: proxy.ip,
        profileType: "FixedProfile",
        revision: REVISION,
      };
      return { ...acc, [property]: nextObject };
    }, {});

    const downloadedProxies = {
      ...stringifiedProxies,
      "-addConditionsToBottom": false,
      "-confirmDeletion": true,
      "-downloadInterval": 1440,
      "-enableQuickSwitch": false,
      "-exportLegacyRuleList": true,
      "-monitorWebRequests": true,
      "-quickSwitchProfiles": [],
      "-refreshOnProfileChange": true,
      "-revertProxyChanges": true,
      "-showExternalProfile": true,
      "-showInspectMenu": true,
      "-startupProfileName": "",
      schemaVersion: 2,
    };

    downloadBlob("OmegaProxies.bak", JSON.stringify(downloadedProxies));
  };

  public toggleProxyModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    this.setState(prevState => ({
      proxyModalOpen: !prevState.proxyModalOpen,
    }));
  };

  public getProxies = (page = 1) => {
    if (page === this.state.current_page) {
      return;
    }

    this.setState({ loading: true }, () => {
      apiGet(API_ROUTES.PROXY.PROXIES, { page })
        .then(response => {
          const { items, page_count } = response;
          this.setState({
            ...this.state,
            proxies: items,
            page_count,
            current_page: page,
            loading: false,
          });
        })
        .catch((err: Error) => {
          console.error(err);
          this.setState({ loading: false });
        });
    });
  };

  public createProxies = (data: ProxiesPayload) =>
    new Promise(resolve => {
      this.setState({ loading: true }, () => {
        apiPost(API_ROUTES.PROXY.CREATE_PROXIES, data)
          .then(res => {
            this.setState(prevState => ({
              proxies: prevState.proxies.concat(res),
              loading: false,
              createProxyModalOpen: false,
            }));
            resolve();
          })
          .catch(handleDispatchErrorAndDisplayToast);
      });
    });

  public render() {
    const {
      loading,
      proxies,
      page_count,
      current_page,
      proxyModalOpen,
      createProxyModalOpen,
    } = this.state;

    const proxyToRender = proxies.map((proxy, index) => (
      <ProxyTableItem proxy={proxy} key={index} onClickHandler={copyToClipBoardEvent} />
    ));

    return (
      <div>
        <Header />

        <div className="content">
          <div className="row">
            <div className="col-12">
              <div className="box">
                {loading && <Loader />}
                {!loading && (
                  <>
                    <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                      <h4 className="box-title">Proxy</h4>
                      <div>
                        <button className="btn btn-list" onClick={this.getProxyForOmegaService}>
                          <span>Список для Omega Proxy</span>
                        </button>
                        <button className="btn btn-list ml-2" onClick={this.toggleProxyModal}>
                          <i className="fas fa-wrench" />
                          <span className="ml-2">Рандомный прокси</span>
                        </button>
                        <button
                          type="button"
                          className="btn btn-list ml-2"
                          onClick={this.toggleCreateProxyModalOpen}
                        >
                          <i className="fas fa-plus" />
                          <span className="ml-2">Добавить прокси</span>
                        </button>
                      </div>
                    </div>
                    <div className="box-body">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th />
                            <th>Лейбл</th>
                            <th>Прокси</th>
                            <th>Прокси IP</th>
                            <th>Прокси Port</th>
                            <th>Логин</th>
                            <th>Пароль</th>
                            <th>Тип прокси</th>
                            <th>Истекает</th>
                          </tr>
                        </thead>
                        <tbody>{proxyToRender}</tbody>
                      </table>

                      <nav className="mt-4">
                        <ul className="pagination pagination-sm justify-content-center">
                          {page_count > 1 &&
                            doPaging(current_page, {
                              range: page_count >= 5 ? 5 : page_count,
                              pages: page_count,
                            }).map((num, i) => {
                              return (
                                <li
                                  key={i}
                                  className={"page-item " + (current_page === num ? "active" : "")}
                                  onClick={() => this.getProxies(num)}
                                >
                                  <span className="page-link">
                                    {num}
                                    {current_page === num && (
                                      <span className="sr-only">(current)</span>
                                    )}
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <RandomProxyModal isOpen={proxyModalOpen} toggleModal={this.toggleProxyModal} />
        <CreateProxyModal
          isOpen={createProxyModalOpen}
          toggleModal={this.toggleCreateProxyModalOpen}
          onCreate={this.createProxies}
        />
      </div>
    );
  }
}

export default ProxyIndex;
