import {
  constructEmptyFailFetchActionReducer,
  constructEmptyStartFetchActionReducer,
  createReducer,
} from "#/store/helpers";
import { CRMProject, CRMProjectData, PrimaryKey, ProjectsById, ProjectsState } from "#/store/types";
import _ from "lodash";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const defaultProjectFields: CRMProjectData = {
  data: null,
  users: [],
  editing: false,
  editingFailed: false,
};

const initialState: ProjectsState = {
  projectsById: {},
  fetching: false,
  fetchingFailed: false,
  creating: false,
  creatingFailed: false,
  editing: false,
  editingFailed: false,
};

export const reducer = createReducer(
  {
    // Fetch single project
    [MutationType.FETCH_PROJ_START]: constructEmptyStartFetchActionReducer(
      "fetching",
      "fetchingFailed",
    ),
    [MutationType.FETCH_PROJ_FAIL]: constructEmptyFailFetchActionReducer(
      "fetching",
      "fetchingFailed",
    ),
    [MutationType.FETCH_PROJ_SUCCEED]: (
      state: ProjectsState,
      action: ActionTypes.FetchCRMProjectSucceed,
    ) => {
      return {
        ...state,
        fetching: false,
        fetchingFailed: false,
        projectsById: {
          ...state.projectsById,
          [action.payload.id]: {
            ...defaultProjectFields,
            data: action.payload.project,
            users: action.payload.users,
          },
        },
      };
    },

    // Fetch projects
    [MutationType.FETCH_PROJECTS_LIST_SUCCEED]: (
      state: ProjectsState,
      action: ActionTypes.FetchProjectsListSucceedAction,
    ) => ({
      ...state,
      projectsById: {
        ...state.projectsById,
        ...action.payload.projects.reduce((acc: ProjectsById, p: CRMProject) => {
          const prevProject = _.get(state.projectsById, p.pk, defaultProjectFields);
          return {
            ...acc,
            [p.pk]: {
              ...prevProject,
              data: {
                ...(prevProject.data ? prevProject.data : {}),
                ...p,
              },
              users: p.users,
            },
          };
        }, {}),
      },
    }),

    // Remove project
    [MutationType.REMOVE_PROJ_SUCCEED]: (state: ProjectsState, action: any) => ({
      ...state,
      projectsById: _.pickBy(state.projectsById, (a: CRMProjectData) =>
        a.data ? a.data.pk !== action.payload.id : true,
      ),
    }),

    // Create project
    [MutationType.CREATE_PROJ_SUCCEED]: (state: ProjectsState, action: any) => ({
      ...state,
      projectsById: {
        ...state.projectsById,
        [action.payload.id]: {
          ...defaultProjectFields,
          ...action.payload.project,
        },
      },
    }),

    // Edit project
    [MutationType.EDIT_PROJ_SUCCEED]: (state: ProjectsState, action: any) => {
      const oldProjData = _.get(state.projectsById, action.payload.id, defaultProjectFields);
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.payload.id]: {
            ...oldProjData,
            data: {
              ..._.get(oldProjData, "data", {} as any),
              ...action.payload.project,
            },
          },
        },
      };
    },

    [MutationType.ADD_USER_TO_PROJ_SUCCEED]: (
      state: ProjectsState,
      action: { payload: { projectId: PrimaryKey; users: PrimaryKey[] } },
    ) => {
      const oldProjData = _.get(state.projectsById, action.payload.projectId, defaultProjectFields);
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.payload.projectId]: {
            ...oldProjData,
            users: action.payload.users,
          },
        },
      };
    },

    [MutationType.REMOVE_USER_FROM_PROJ_SUCCEED]: (
      state: ProjectsState,
      action: { payload: { projectId: PrimaryKey; users: PrimaryKey[] } },
    ) => {
      const oldProjData = _.get(state.projectsById, action.payload.projectId, defaultProjectFields);
      return {
        ...state,
        projectsById: {
          ...state.projectsById,
          [action.payload.projectId]: {
            ...oldProjData,
            users: action.payload.users,
          },
        },
      };
    },
  },
  initialState,
);

export default reducer;
