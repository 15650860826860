enum MutationTypes {
  FETCH_COUNTERS_START = "metrika/goals/FETCH_COUNTERS_START",
  FETCH_COUNTERS_SUCCEED = "metrika/goals/FETCH_COUNTERS_SUCCEED",
  FETCH_COUNTERS_FAILURE = "metrika/goals/FETCH_COUNTERS_FAILURE",

  SELECT_TOKEN = "metrika/goals/SELECT_TOKEN",

  ADD_GOALS = "metrika/goals/ADD_GOALS",

  CHANGE_CREATING_GOAL = "metrika/goals/CHANGE_CREATING_GOAL",

  FETCH_TOKENS_START = "metrika/goals/FETCH_TOKENS_START",
  FETCH_TOKENS_SUCCEED = "metrika/goals/FETCH_TOKENS_SUCCEED",
  FETCH_TOKENS_FAILURE = "metrika/goals/FETCH_TOKENS_FAILURE",

  POST_GOAL_START = "metrika/goals/POST_GOAL_START",
  POST_GOAL_SUCCEED = "metrika/goals/POST_GOAL_SUCCEED",
  POST_GOAL_FAILURE = "metrika/goals/POST_GOAL_FAILURE",

  SELECT_GOALS = "metrika/goals/SELECT_GOALS",
}

export default MutationTypes;
