import { APIError, apiGet, constructAPIProgrammingError } from "#/api";
import { API_ROUTES } from "#/conf/api";
import * as sessionModule from "#/store/modules/session";
import usersModule from "#/store/modules/users";
import { GetState, ThunkResult, User } from "#/store/types";
import { ActionCreator, Dispatch } from "redux";
import {
  fetchCurrentUserInfoFail,
  fetchCurrentUserInfoStart,
  fetchCurrentUserInfoSucceed,
} from "../session/actions";

export const fetchCurrentUserInfo = (): ThunkResult<Promise<User | Error | APIError>> => async (
  dispatch: Dispatch,
  getState: GetState,
) => {
  // Check if auth token is present
  const isAuthTokenPresent = sessionModule.selectors.getAuthToken(getState(), null);
  if (!isAuthTokenPresent) {
    const msg = "authentication token is not present";
    const err = new Error(msg);
    dispatch(fetchCurrentUserInfoFail(err));
    return err;
  }

  dispatch(fetchCurrentUserInfoStart());
  if (sessionModule.selectors.isAuthenticated(getState(), null)) {
    return new Error("already authenticated");
  }

  try {
    const user = await apiGet(API_ROUTES.MAIN.GET_CURRENT_USER_INFO);
    dispatch(usersModule.actions.fetchFullUserInfoSucceed(user.pk, user));
    dispatch(fetchCurrentUserInfoSucceed(user.pk));
    return user;
  } catch (err) {
    dispatch(fetchCurrentUserInfoFail(err));
    return err;
  }
};

export default { fetchCurrentUserInfo };
