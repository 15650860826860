import emojiRegex from "emoji-regex";
import { EditorState } from "react-draft-wysiwyg";

const getEmojiIndexes = (text: string) => {
  const regex = emojiRegex();
  const result: Array<{ index: number; length: number }> = [];
  let match;
  while ((match = regex.exec(text))) {
    const emoji = match[0];
    const index = text.indexOf(match[0]);
    const { length } = emoji;
    result.push({ index, length });
  }
  return result;
};

/*
 Check if inlineStyleRange object's range (offset, length) includes an emoji
 */
const indexMatch = (
  range: { offset: number; length: number },
  emojiIndex: number,
  emojiLength: number,
) => {
  const { offset, length } = range;
  const rangeEnd = offset + length;
  const emojiEnd = emojiIndex + emojiLength;
  return offset < emojiEnd && rangeEnd >= emojiIndex;
};

/*
 emojis may be treated as a single index inside draft block's inlineStyleRanges
 calculate appropriate offset / length for an inlineStyleRange of a raw draft block;
 */
const manipulateStyleRange = (
  range: { offset: number; length: number },
  emojiIndex: number,
  emojiLength: number,
) => {
  const { offset, length } = range;
  const newOffset = Math.min(offset, emojiIndex);
  const emojiEnd = emojiIndex + emojiLength;
  const rangeEnd = offset + length;
  const newLength = Math.max(emojiEnd, rangeEnd) - newOffset;
  return { offset: newOffset, length: newLength };
};

/*
 find inlineStyleRange objects covering emoji indexes inside a raw draft block,
 update offset and length attributes of these inlineStyleRange objects with an appropriate values
 return manipulated raw editor state object
 */
export const manipulateRawBlocks = (rawState: EditorState) =>
  rawState.blocks.map(entry => {
    const emojiIndexes = getEmojiIndexes(entry.text);
    let { inlineStyleRanges } = entry;
    emojiIndexes.forEach(({ index, length }) => {
      inlineStyleRanges = inlineStyleRanges.map(inline => {
        const matches = indexMatch(inline, index, length);
        if (matches) {
          const newRangeConfig = manipulateStyleRange(inline, index, length);
          return { ...inline, ...newRangeConfig };
        }
        return inline;
      });
    });
    return { ...entry, inlineStyleRanges };
  });
