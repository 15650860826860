import { CRMProject, CRMTask, SearchItem, SearchKind, User } from "#/store/types";
import { CODE_NON_REACHABLE, getAllPossibleValuesFromTSEnum, noop } from "#/util";
import _ from "lodash";
import React from "react";
import ProjectSearchItem from "./components/ProjectSearchItem";
import TaskSearchItem from "./components/TaskSearchItem";
import UserSearchItem from "./components/UserSearchItem";

export const searchKindRenderer = (searchKind: SearchKind) => {
  switch (searchKind) {
    case SearchKind.USERS: {
      return (item: SearchItem) => <UserSearchItem key={item.pk} item={item as User} />;
    }
    case SearchKind.TASKS: {
      return (item: SearchItem) => <TaskSearchItem key={item.pk} item={item as CRMTask} />;
    }
    case SearchKind.PROJECTS: {
      return (item: SearchItem) => <ProjectSearchItem key={item.pk} item={item as CRMProject} />;
    }
    case SearchKind.ALL: {
      // TODO: Implement
      return (item: SearchItem) => null;
    }
    default: {
      CODE_NON_REACHABLE();
      return noop;
    }
  }
};

export const isSearchKindValid = (k: string | null) => {
  if (k === null) {
    return false;
  }
  const kk = _.toNumber(k);
  const possibleValues = getAllPossibleValuesFromTSEnum(SearchKind);
  return kk in possibleValues;
};

export const searchKindToPageTitle = (searchKind: SearchKind) => {
  const whats = {
    [SearchKind.USERS]: " по пользователям",
    [SearchKind.PROJECTS]: " по проектам",
    [SearchKind.TASKS]: " по задачам",
    [SearchKind.ALL]: "",
  };
  return `Результаты поиска${whats[searchKind]}`;
};
