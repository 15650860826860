import loading_bar from "#/scenes/Auth/assets/login-spinner.svg";
import { MonitoringProjectCheckedUrl, PrimaryKey } from "#/store/types";
import { formatDateTime } from "#/util";
import { SitemonitoringCheckedUrlsDateStatus } from "#/util/constants";
import * as Diff from "diff";
import _ from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { TabPane } from "reactstrap";
import ProjectContext from "../ProjectContext";
import { tabName, TabPage } from "../util";

interface ConnectedProps {}

interface OwnProps {}

type Props = ConnectedProps & OwnProps;

// Project check history view tab pane.
const VisorTabPane = (props: Props) => {
  const projectContext = useContext(ProjectContext);
  const activeTab = projectContext.activeTab;
  const loading = projectContext.loading;
  const dates = projectContext.dates;
  const fetchCheckedUrls = projectContext.fetchCheckedUrls;
  const activeTabName = tabName(activeTab);
  const checkedUrls = projectContext.checkedUrls;

  // Current selected date of check
  const [currentSelected, setCurrentSelected] = useState<string>(dates[0]);

  // Current selected filter option
  const [currentSelectedOption, setCurrentSelectedOption] = useState<
    SitemonitoringCheckedUrlsDateStatus
  >(SitemonitoringCheckedUrlsDateStatus.ALL);

  // When dates prop updates, reset the selected date
  // to the first and fetch urls for the updated date
  useEffect(() => {
    if (dates.length > 0) {
      setCurrentSelected(dates[0]);
    } else {
      setCurrentSelected("");
    }
  }, [dates]);

  // When datecheck changes, fetch new urls for the updated date
  useEffect(() => {
    if (!currentSelected) {
      return;
    }
    fetchCheckedUrls(currentSelected);
  }, [currentSelected]);

  const onDateCheckSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const datecheck = e.target.value;
    setCurrentSelected(datecheck);
  };

  const handleOptionChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    setCurrentSelectedOption((e.target.value as any) as SitemonitoringCheckedUrlsDateStatus);

  const itemsToRender = useMemo(() => {
    const filteredCheckedUrls = (() => {
      const currentSelectedTabName = activeTabName.toLowerCase();
      const currentSelectedUrlField = _.includes(["content", "code"], currentSelectedTabName)
        ? `${currentSelectedTabName}_diff`
        : `${currentSelectedTabName}changed`;
      const didCurrentUrlFieldChange = (url: MonitoringProjectCheckedUrl) => {
        const cf = (url as any)[currentSelectedUrlField];
        const ctbn = (url as any)[currentSelectedTabName];
        return cf !== "...\n" && !_.isEqual(cf, ctbn);
      };
      return currentSelectedOption === SitemonitoringCheckedUrlsDateStatus.WITH_CHANGES
        ? checkedUrls.filter(didCurrentUrlFieldChange)
        : currentSelectedOption === SitemonitoringCheckedUrlsDateStatus.WITHOUT_CHANGES
        ? checkedUrls.filter(_.negate(didCurrentUrlFieldChange))
        : checkedUrls;
    })();

    return filteredCheckedUrls.map((el: MonitoringProjectCheckedUrl, i: number) => {
      const elemName = activeTabName.toLowerCase();
      const previousChanges = _.get(el, elemName, null);
      if (previousChanges === null) {
        return null;
      }

      const diffsToRender = (() => {
        if (_.includes(["content", "code"], elemName)) {
          const diff = _.get(el, `${elemName}_diff`, null);
          if (diff === null) {
            return;
          }
          return diff.split("\n").map((line: string, idx: number) => {
            if (_.startsWith(line, "+")) {
              return <ins key={idx}>{line.substring(1)}</ins>;
            } else if (_.startsWith(line, "-")) {
              return <del key={idx}>{line.substring(1)}</del>;
            } else {
              return line;
            }
          });
        } else {
          const nextChanges = _.get(el, `${elemName}changed`, null);
          if (nextChanges === null) {
            return;
          }
          return nextChanges
            ? Diff.diffChars(previousChanges, nextChanges).map((elem, idx) => {
                if (elem.added) {
                  return <ins key={idx}>{elem.value}</ins>;
                } else if (elem.removed) {
                  return <del key={idx}>{elem.value}</del>;
                } else {
                  return "...";
                }
              })
            : "";
        }
      })();

      if (diffsToRender === null) {
        return;
      }

      return (
        <tr key={`${currentSelected}${i}`}>
          {/* Name */}
          <td key="name">
            <a href={el.url_m.name} target="_blank" rel="noopener noreferrer">
              {el.url_m.name}
            </a>
          </td>

          {/* Previous */}
          <td
            key="prev-version"
            style={{
              maxWidth: "240px",
              overflowX: "hidden",
            }}
          >
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {_.get(el, elemName, "").substr(0, 1000)}...
            </div>
          </td>

          {/* Changed */}
          <td
            key="next-version"
            style={{
              maxWidth: "240px",
            }}
          >
            <div
              style={{
                maxHeight: "400px",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              {diffsToRender}
            </div>
          </td>

          {/* Status */}
          <td key="status">{el.status}</td>
        </tr>
      );
    });
  }, [activeTabName, checkedUrls]);

  return (
    <TabPane tabId={TabPage.VISOR}>
      <div className="content-inner-topvisor">
        <h2>{activeTabName}</h2>
        <div className="bar row">
          <div className="form-group col-6 col-xl-3">
            <select className="form-control" onChange={onDateCheckSelect} value={currentSelected}>
              {dates.length > 0 ? (
                dates.map((el, i) => (
                  <option key={i} value={el}>
                    {formatDateTime(el)}
                  </option>
                ))
              ) : (
                <option value="null">Записей не найдено</option>
              )}
            </select>
          </div>
          <div className="form-group col-6 col-xl-3">
            <select
              className="form-control"
              value={currentSelectedOption}
              onChange={handleOptionChange}
            >
              <option value={SitemonitoringCheckedUrlsDateStatus.ALL}>Все</option>
              <option value={SitemonitoringCheckedUrlsDateStatus.WITH_CHANGES}>
                С изменениями
              </option>
              <option value={SitemonitoringCheckedUrlsDateStatus.WITHOUT_CHANGES}>
                Без изменений
              </option>
            </select>
          </div>
        </div>
        <div className="bar topvisor-content-main">
          {loading ? (
            <div className="title-component-loader-container text-center">
              <img src={loading_bar} alt="loading..." />
            </div>
          ) : (
            <div className="topvisor-table">
              {itemsToRender.length > 0 ? (
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th className="url">
                        <input type="text" className="form-control" placeholder="URL" />
                      </th>
                      <th className="value">
                        <input type="text" className="form-control" placeholder="Значение" />
                      </th>
                      <th className="changes">Изменения</th>
                      <th className="code  minimal-column">
                        <input type="text" className="form-control" placeholder="HTTP Code" />
                      </th>
                    </tr>
                  </thead>
                  <tbody>{itemsToRender}</tbody>
                </table>
              ) : (
                <div
                  className="title-component-no-items-container"
                  style={{ display: "flex", width: "100%", height: "100%" }}
                >
                  Здесь пусто
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </TabPane>
  );
};

export default VisorTabPane;
