import { getActionsFromModule } from "#/store/helpers";
import tasksModule from "#/store/modules/tasks";
import { CRMTask, PrimaryKey, StoreRootState, TaskCheckListItem } from "#/store/types";
import React from "react";
import { connect } from "react-redux";
import TaskChecklistBare from "./TaskChecklistBare";

interface TaskChecklistConnectedProps {
  adding: boolean;
  addTaskChecklistItem: any;
  removeTaskChecklistItem: any;
  editTaskChecklistItem: any;
}

interface TaskChecklistOwnProps {
  items: TaskCheckListItem[];
  isManager: boolean;
  isExecutor: boolean;
  task: CRMTask;
}

type TaskChecklistProps = TaskChecklistConnectedProps & TaskChecklistOwnProps;

const TaskChecklist = (props: TaskChecklistProps) => {
  // Record event actions
  const onItemToAddSave = (items: string[]) =>
    props.addTaskChecklistItem(props.task.pk, items);

  const onItemRemove = (item: TaskCheckListItem) => props.removeTaskChecklistItem(item);

  const onItemEdit = (payload: TaskCheckListItem) =>
    props.editTaskChecklistItem(payload.pk, payload);

  const onItemToggle = (item: TaskCheckListItem) =>
    new Promise((resolve, reject) => {
      const oldStatus = item.status;
      const newStatus = !oldStatus;
      const newFinishDate = !oldStatus ? new Date() : null;
      props.editTaskChecklistItem(item.pk, {
        status: newStatus,
        finish_date: newFinishDate,
      });
      resolve();
    });

  return (
    <TaskChecklistBare
      task={props.task}
      items={props.items}
      onAdd={onItemToAddSave}
      onItemEdit={onItemEdit}
      onItemRemove={onItemRemove}
      isManager={props.isManager}
      isExecutor={props.isExecutor}
      adding={props.adding}
      onItemToggle={onItemToggle}
    />
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  adding: tasksModule.selectors.isCurrentlyAddingChecklistItems(store),
});

const mapDispatchToProps = {
  ...getActionsFromModule(tasksModule, [
    "addTaskChecklistItem",
    "removeTaskChecklistItem",
    "editTaskChecklistItem",
  ]),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TaskChecklist);
