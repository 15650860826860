import Loader from "#/components/Loader";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import {
  addQueries,
  fetchQueries,
  getCurrentEventPk,
  getFetchedQueries,
  getMetrikaId,
  getQueries,
  isMetrikaLoading,
  setMinimumNumberOfVisits,
  setSubstring,
} from "#/store/modules/metrika/top50";
import { YandexQuery } from "#/store/modules/metrika/top50/types";
import { StoreRootState } from "#/store/types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import style from "./index.module.scss";
import _ from "lodash";

interface QueryTableOwnProps {}

interface QueryTableConnectedProps {
  queries: YandexQuery[];
  fetchQueries: any;
  metrikaId: number;
  loading: boolean;
  addQueries: any;
  eventPk: number;
  setMinimumNumberOfVisits: any;
  setSubstring: any;
}

type QueryTableProps = QueryTableConnectedProps & QueryTableOwnProps;

const QueryTable = (props: QueryTableProps) => {
  useEffect(() => {
    props.fetchQueries(props.metrikaId).catch((err: Error) => showErrorToast(err.message));
  }, []);

  const [modal, useModal] = useState(false);
  const toggleModal = () => useModal(!modal);

  if (props.loading) {
    return <Loader />;
  }

  return (
    <div>
      <table className={style.table}>
        <thead>
          <tr>
            <th scope="col">Текст</th>
            <th scope="col">Визиты</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          <tr>
            <th>
              <input type="text" onChange={e => props.setSubstring(e.target.value)} />
            </th>
            <th>
              <input
                type="number"
                name="minimum"
                id="minimum"
                min="0"
                onChange={e => props.setMinimumNumberOfVisits(_.toNumber(e.target.value) || 0)}
              />
            </th>
            <th>
              <button className="btn btn-primary btn-sm btn-block" onClick={toggleModal}>
                Добавить все запросы
              </button>
            </th>
          </tr>
          {props.queries.map(query => (
            <tr key={`${query.text}${query.visits}`}>
              <th>{query.text}</th>
              <th>{query.visits}</th>
              <th>
                <button
                  onClick={() =>
                    props
                      .addQueries(props.eventPk, [query])
                      .then(() => showSuccessToast("Запросы были успешно добавлены"))
                      .catch((err: Error) => showErrorToast(err.message))
                  }
                  className="btn btn-primary btn-sm"
                >
                  Добавить запрос в ТОП50
                </button>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal isOpen={modal} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Modal title</ModalHeader>
        <ModalBody>Вы уверены что хотите добавить {props.queries.length} запросов?</ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              props
                .addQueries(props.eventPk, props.queries)
                .then(() => showSuccessToast("Запросы были успешно добавлены"))
                .catch((err: Error) => showErrorToast(err.message));
              toggleModal();
            }}
          >
            Продолжить
          </Button>{" "}
          <Button color="secondary" onClick={toggleModal}>
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default connect(
  (state: StoreRootState, props: QueryTableOwnProps) => ({
    metrikaId: getMetrikaId(state),
    queries: getQueries(state),
    loading: isMetrikaLoading(state),
    eventPk: getCurrentEventPk(state),
  }),
  { fetchQueries, addQueries, setMinimumNumberOfVisits, setSubstring },
)(QueryTable);
