import React from "react";
import { Link } from "react-router-dom";

const Index = () => {
  return (
    <>
      <div className="box-header with-border">
        <h4 className="box-title">Доступные сервисы</h4>
      </div>
      <div className="box-body">
        <div className="list-group">
          <Link
            to="top50"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <div className="d-flex">
                <h5 className="mb-1">Топ-50</h5>
              </div>
              <small>Последнее обновление: где-то осенью</small>
            </div>
            <p className="mb-1">Добавление запросов из яндекс метрики в топ 50.</p>
            <small className="text-muted">Откроется в текущем окне</small>
          </Link>
          <Link
            to="goals"
            className="list-group-item list-group-item-action flex-column align-items-start"
          >
            <div className="d-flex w-100 justify-content-between">
              <div className="d-flex">
                <h5 className="mb-1">Создание целей</h5>
              </div>
              <small>Последнее обновление: 24 декабря</small>
            </div>
            <p className="mb-1">Создание одинаковых целей для множества проектов</p>
            <small className="text-muted">Откроется в текущем окне</small>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Index;
