import { apiDelete, apiGet, apiPost } from "#/api";
import { API_ROUTES, WS_API_SITEMONITORING_URL } from "#/conf/api";
import {
  GetState,
  MonitoringProjectCheckedUrl,
  MonitoringProjectData,
  MonitoringProjectUrl,
  PrimaryKey,
} from "#/store/types";
import _ from "lodash";
import { Dispatch } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";
import * as selectors from "./selectors";

/////////////////////////////////////////////////
// Fetch projects
/////////////////////////////////////////////////

export const fetchProjectsStart = (): ActionTypes.FetchMonitoringProjectsStartAction => ({
  type: MutationType.FETCH_PROJECTS_START,
});

export const fetchProjectsSucceed = (
  projects: MonitoringProjectData[],
): ActionTypes.FetchMonitoringProjectsSucceedAction => ({
  type: MutationType.FETCH_PROJECTS_SUCCEED,
  payload: { projects },
});

export const fetchProjectsFail = (error: Error): ActionTypes.FetchMonitoringProjectsFailAction => ({
  type: MutationType.FETCH_PROJECTS_FAIL,
  payload: { error },
});

export const fetchProjectsList = () => (dispatch: Dispatch, getState: any) => {
  dispatch(fetchProjectsStart());
  apiGet(API_ROUTES.MONITORING.PROJECTS)
    .then(projects => {
      dispatch(fetchProjectsSucceed(projects));
    })
    .catch(err => {
      dispatch(fetchProjectsFail(err));
      console.error(err);
    });
};

/////////////////////////////////////////////////
// Create monitoring project
/////////////////////////////////////////////////

export const createMonitoringProjectStart = (): ActionTypes.CreateMonitoringProjectStartAction => ({
  type: MutationType.CREATE_PROJECT_START,
});

export const createMonitoringProjectSucceed = (
  newProject: MonitoringProjectData,
): ActionTypes.CreateMonitoringProjectSucceedAction => ({
  type: MutationType.CREATE_PROJECT_SUCCEED,
  payload: { newProject },
});

export const createMonitoringProjectFail = (
  error: Error,
): ActionTypes.CreateMonitoringProjectFailAction => ({
  type: MutationType.CREATE_PROJECT_FAIL,
  payload: { error },
});

export const createMonitoringProject = (payload: Partial<MonitoringProjectData>) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch(createMonitoringProjectStart());
  return apiPost(API_ROUTES.MONITORING.PROJECTS, payload)
    .then(project => {
      dispatch(createMonitoringProjectSucceed(project));
    })
    .catch(err => {
      dispatch(createMonitoringProjectFail(err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Fetch checked URLs for a project
/////////////////////////////////////////////////

export const fetchCheckedUrlsForProjStart = (
  projID: number,
): ActionTypes.FetchCheckedUrlsForProjectStartAction => ({
  type: MutationType.FETCH_CHECKEDURLS_FOR_PROJ_START,
  payload: {
    projID,
  },
});

export const fetchCheckedUrlsForProjSucceed = (
  projID: number,
  checkedUrls: MonitoringProjectCheckedUrl[],
): ActionTypes.FetchCheckedUrlsForProjectSucceedAction => ({
  type: MutationType.FETCH_CHECKEDURLS_FOR_PROJ_SUCCEED,
  payload: {
    projID,
    checkedUrls,
  },
});

export const fetchCheckedUrlsForProjFail = (
  projID: number,
  error: Error,
): ActionTypes.FetchCheckedUrlsForProjectFailAction => ({
  type: MutationType.FETCH_CHECKEDURLS_FOR_PROJ_FAIL,
  payload: {
    projID,
    error,
  },
});

export const fetchCheckedUrlsForProj = (projID: number, datecheck: string) => (
  dispatch: Dispatch,
) => {
  dispatch(fetchCheckedUrlsForProjStart(projID));
  return apiGet(API_ROUTES.MONITORING.PROJECT_CHECKED_URLS(projID), {
    datecheck,
  })
    .then(json => {
      dispatch(fetchCheckedUrlsForProjSucceed(projID, json));
    })
    .catch(error => {
      dispatch(fetchCheckedUrlsForProjFail(projID, error));
      throw error;
    });
};

/////////////////////////////////////////////////
// Remove monitoring project
/////////////////////////////////////////////////

export const removeMonitoringProjectStart = (
  projID: number,
): ActionTypes.RemoveMonitoringProjectStartAction => ({
  type: MutationType.REMOVE_PROJECT_START,
  payload: { projID },
});

export const removeMonitoringProjectFail = (
  projID: number,
  error: Error,
): ActionTypes.RemoveMonitoringProjectFailAction => ({
  type: MutationType.REMOVE_PROJECT_FAIL,
  payload: { projID, error },
});

export const removeMonitoringProjectSucceed = (
  projID: number,
): ActionTypes.RemoveMonitoringProjectSucceedAction => ({
  type: MutationType.REMOVE_PROJECT_SUCCEED,
  payload: { projID },
});

export const removeMonitoringProject = (id: number) => (dispatch: Dispatch, getState: any) => {
  dispatch(removeMonitoringProjectStart(id));
  apiDelete(API_ROUTES.MONITORING.PROJECTS_ITEM(id))
    .then(response => {
      dispatch(removeMonitoringProjectSucceed(id));
    })
    .catch(error => {
      console.error(error);
      dispatch(removeMonitoringProjectFail(id, error));
    });
};

/////////////////////////////////////////////////
// Fetch monitoring project
/////////////////////////////////////////////////

export const fetchMonitoringProjectStart = (
  projID: number,
): ActionTypes.FetchMonitoringProjectStartAction => ({
  type: MutationType.FETCH_PROJECT_START,
  payload: { projID },
});

export const fetchMonitoringProjectFail = (
  projID: number,
  error: Error,
): ActionTypes.FetchMonitoringProjectFailAction => ({
  type: MutationType.FETCH_PROJECT_FAIL,
  payload: { projID, error },
});

export const fetchMonitoringProjectSucceed = (
  projID: number,
  data: {
    project: MonitoringProjectData;
    urls: MonitoringProjectUrl[];
    checkedurls_dates: string[];
    filters: string;
  },
): ActionTypes.FetchMonitoringProjectSucceedAction => ({
  type: MutationType.FETCH_PROJECT_SUCCEED,
  payload: {
    projID,
    project: data.project,
    urls: data.urls,
    checkedurls_dates: data.checkedurls_dates,
  },
});

export const fetchMonitoringProject = (projID: number) => (dispatch: Dispatch, getState: any) => {
  apiGet(API_ROUTES.MONITORING.URLS_BY_PROJECT(projID))
    .then(json => {
      dispatch(fetchMonitoringProjectSucceed(projID, json));
      return json;
    })
    .catch(err => {
      dispatch(fetchMonitoringProjectFail(projID, err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Add URLs to a project
/////////////////////////////////////////////////

export const addUrlsToProjectStart = (projID: number): ActionTypes.AddUrlsToProjectStartAction => ({
  type: MutationType.ADD_URLS_TO_PROJECT_START,
  payload: { projID },
});

export const addUrlsToProjectFail = (
  projID: number,
  error: Error,
): ActionTypes.AddUrlsToProjectFailAction => ({
  type: MutationType.ADD_URLS_TO_PROJECT_FAIL,
  payload: { projID, error },
});

export const addUrlsToProjectSucceed = (
  projID: number,
  urls: MonitoringProjectUrl[],
): ActionTypes.AddUrlsToProjectSucceedAction => ({
  type: MutationType.ADD_URLS_TO_PROJECT_SUCCEED,
  payload: { projID, urls },
});

export const addUrlsToProject = (projID: number, urls: string[]) => (
  dispatch: Dispatch,
  getState: GetState,
) => {
  dispatch(addUrlsToProjectStart(projID));
  const initialProj = _.get(selectors.getMonitoringProjectsById(getState(), null), projID, null);
  const initialURLAmount = initialProj ? initialProj.urls.length : null;
  return apiPost(API_ROUTES.MONITORING.URLS_MASS_CREATE, { id: projID, urls })
    .then(project => {
      dispatch(addUrlsToProjectSucceed(projID, project.urls));
      return {
        urls: project.urls,
        diff: initialURLAmount ? project.urls.length - initialURLAmount : null,
      };
    })
    .catch(err => {
      dispatch(addUrlsToProjectFail(projID, err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Remove URLs to a project
/////////////////////////////////////////////////

export const removeUrlsFromProjectStart = (
  projID: number,
): ActionTypes.RemoveUrlsFromProjectStartAction => ({
  type: MutationType.REMOVE_URLS_FROM_PROJECT_START,
  payload: { projID },
});

export const removeUrlsFromProjectFail = (
  projID: number,
  error: Error,
): ActionTypes.RemoveUrlsFromProjectFailAction => ({
  type: MutationType.REMOVE_URLS_FROM_PROJECT_FAIL,
  payload: { projID, error },
});

export const removeUrlsFromProjectSucceed = (
  projID: number,
  urls: number[],
): ActionTypes.RemoveUrlsFromProjectSucceedAction => ({
  type: MutationType.REMOVE_URLS_FROM_PROJECT_SUCCEED,
  payload: { projID, urls },
});

export const removeUrlsFromProject = (projID: number, urls: number[]) => (
  dispatch: Dispatch,
  getState: any,
) => {
  dispatch(removeUrlsFromProjectStart(projID));
  return apiPost(API_ROUTES.MONITORING.URLS_MASS_DELETE, { urls })
    .then(response => {
      dispatch(removeUrlsFromProjectSucceed(projID, urls));
    })
    .catch(err => {
      dispatch(removeUrlsFromProjectFail(projID, err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Toggle URLs from a project
/////////////////////////////////////////////////

export const toggleUrlsFromProjectStart = (
  projID: number,
  urls: number[],
): ActionTypes.ToggleUrlsFromProjectStartAction => ({
  type: MutationType.TOGGLE_URLS_FROM_PROJECT_START,
  payload: { projID },
});

export const toggleUrlsFromProjectFail = (
  projID: number,
  error: Error,
): ActionTypes.ToggleUrlsFromProjectFailAction => ({
  type: MutationType.TOGGLE_URLS_FROM_PROJECT_FAIL,
  payload: { projID, error },
});

export const toggleUrlsFromProjectSucceed = (
  projID: number,
  urls: number[],
  action: string,
): ActionTypes.ToggleUrlsFromProjectSucceedAction => ({
  type: MutationType.TOGGLE_URLS_FROM_PROJECT_SUCCEED,
  payload: { projID, urls, newToggledState: action },
});

export const toggleUrlsFromProject = (projID: number, urls: number[], action: string) => (
  dispatch: Dispatch,
) => {
  toggleUrlsFromProjectStart(projID, urls);
  return apiPost(API_ROUTES.MONITORING.URLS_MASS_TOGGLE, { urls, action })
    .then(json => {
      dispatch(toggleUrlsFromProjectSucceed(projID, urls, json.action));
    })
    .catch(err => {
      dispatch(toggleUrlsFromProjectFail(projID, err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Run check process
/////////////////////////////////////////////////

export const runCheckProcessStart = (projID: number): ActionTypes.RunCheckProcessStartAction => ({
  type: MutationType.RUN_CHECK_PROCESS_START,
  payload: { projID },
});

export const runCheckProcessFail = (
  projID: number,
  error: Error,
): ActionTypes.RunCheckProcessFailAction => ({
  type: MutationType.RUN_CHECK_PROCESS_FAIL,
  payload: { projID, error },
});

export const runCheckProcessSucceed = (
  projID: number,
): ActionTypes.RunCheckProcessSucceedAction => ({
  type: MutationType.RUN_CHECK_PROCESS_SUCCEED,
  payload: { projID },
});

export const runCheckProcess = (projID: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  dispatch(runCheckProcessStart(projID));
  return apiPost(API_ROUTES.MONITORING.RUN_CHECK_PROCESS(projID))
    .then(json => {
      dispatch(runCheckProcessSucceed(projID));
      return true;
    })
    .catch(err => {
      dispatch(runCheckProcessFail(projID, err));
      throw err;
    });
};

/////////////////////////////////////////////////
// Monitoring WS
/////////////////////////////////////////////////

const closeSitemonitoringWebSocketSucceed = (): ActionTypes.CloseSitemonitoringWebSocketSucceedAction => ({
  type: MutationType.CLOSE_WS,
});

export const closeSitemonitoringWebSocket = () => (dispatch: Dispatch, getState: any) => {
  const ws = selectors.getSitemonitoringWebSocketInstance(getState(), null);
  if (ws !== null) {
    ws.close();
    dispatch(closeSitemonitoringWebSocketSucceed());
  }
};

const setupSitemonitoringWebSocketSucceed = (
  ws: WebSocket,
): ActionTypes.SetupSitemonitoringWebSocketSucceedAction => ({
  type: MutationType.SETUP_WS,
  payload: { ws },
});

export const updateMonitoringProjectCheckProgress = (
  projID: number,
  progress: number,
): ActionTypes.UpdateMonitoringProjectCheckProgressAction => ({
  type: MutationType.UPDATE_PROJECT_CHECK_PROGRESS,
  payload: { projID, progress },
});

export const setupSitemonitoringWebSocket = () => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    if (!selectors.isSitemonitoringWebSocketClosed(getState(), null)) {
      return resolve();
    }
    const ws = new WebSocket(WS_API_SITEMONITORING_URL);

    type WSMessageHandler = (messageData: any) => void;
    type WSMessageType = "UPDATE_PROGRESS" | "COMPLETE_PROJECT_CHECK";
    interface UpdateProgressWSMessageData {
      id: number;
      progress: number;
    }
    interface CompleteProjectCheckWSMessageData {
      id: number;
      datecheck: string;
    }
    const handlers: Record<WSMessageType, WSMessageHandler> = {
      UPDATE_PROGRESS: ({ id, progress }: UpdateProgressWSMessageData) => {
        dispatch(updateMonitoringProjectCheckProgress(id, progress));
      },
      COMPLETE_PROJECT_CHECK: ({ id, datecheck }: CompleteProjectCheckWSMessageData) => {
        dispatch(updateMonitoringProjectCheckProgress(id, -1));
        dispatch(fetchMonitoringProject(id) as any);
        dispatch(fetchCheckedUrlsForProj(id, datecheck) as any);
      },
    };

    const handleSitemonitoringWSMessage = (messageType: WSMessageType, messageData: any) => {
      if (!(messageType in handlers)) {
        console.error(
          `handleSitemonitoringWSMessage: Message type ${messageType} is not handled currently.`,
        );
      }
      const f = handlers[messageType];
      return f(messageData);
    };

    // TODO: Is this event listener needed?
    // ws.addEventListener("open", event => {});

    ws.addEventListener("close", event => {
      if (!selectors.isSitemonitoringWebSocketClosed(getState(), null)) {
        dispatch(closeSitemonitoringWebSocket() as any);
      }
    });

    ws.addEventListener("message", event => {
      const message = event.data;
      const messageJson = JSON.parse(message);
      const messageType = messageJson.type;
      const messageData = messageJson.message;
      handleSitemonitoringWSMessage(messageType, messageData);
    });

    dispatch(setupSitemonitoringWebSocketSucceed(ws));
    resolve();
  });
};

export const changeMonitoringProjectFiltersStart = (
  projID: number,
): ActionTypes.ChangeMonitoringProjectFiltersStartAction => ({
  type: MutationType.CHANGE_PROJECT_FILTERS_START,
  payload: { projID },
});

export const changeMonitoringProjectFiltersFail = (
  projID: number,
  error: Error,
): ActionTypes.ChangeMonitoringProjectFiltersFailAction => ({
  type: MutationType.CHANGE_PROJECT_FILTERS_FAIL,
  payload: { projID, error },
});

export const changeMonitoringProjectFiltersSucceed = (
  projID: number,
  filters: string,
): ActionTypes.ChangeMonitoringProjectFiltersSucceedAction => ({
  type: MutationType.CHANGE_PROJECT_FILTERS_SUCCEED,
  payload: { projID, filters },
});

export const changeMonitoringProjectFilters = (projID: PrimaryKey, newFilters: string) => (
  dispatch: Dispatch,
) => {
  dispatch(changeMonitoringProjectFiltersStart(projID));
  return apiPost(API_ROUTES.MONITORING.CHANGE_PROJECT_FILTERS(projID), {
    filters: newFilters,
  })
    .then(json => {
      dispatch(changeMonitoringProjectFiltersSucceed(projID, json.filters));
      return json.filters;
    })
    .catch(err => {
      dispatch(changeMonitoringProjectFiltersFail(projID, err));
      throw err;
    });
};

export const subscribeToMonitoringProject = (projID: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.MONITORING.SUBSCRIBE_TO_PROJECT(projID))
    .then(subscribed => {
      dispatch({
        type: MutationType.SET_SUBSCRIBED_TO_PROJ,
        payload: { projId: projID, subscribed },
      });
      return subscribed;
    })
    .catch(err => {
      throw err;
    });

export const unsubscribeFromMonitoringProject = (projID: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.MONITORING.UNSUBSCRIBE_FROM_PROJECT(projID))
    .then(subscribed => {
      dispatch({
        type: MutationType.SET_SUBSCRIBED_TO_PROJ,
        payload: { projId: projID, subscribed },
      });
      return subscribed;
    })
    .catch(err => {
      throw err;
    });

export const toggleMonitoringProjectSubscription = (projID: PrimaryKey) => (
  dispatch: Dispatch,
  getState: GetState,
) =>
  apiPost(API_ROUTES.MONITORING.TOGGLE_PROJECT_SUBSCRIPTION(projID))
    .then(subscribed => {
      dispatch({
        type: MutationType.SET_SUBSCRIBED_TO_PROJ,
        payload: { projId: projID, subscribed },
      });
      return subscribed;
    })
    .catch(err => {
      throw err;
    });

export const importUrlsFromSitemapIntoProject = (
  projID: PrimaryKey,
  sitemapUrl: string,
  onlyCheckAmount = false,
) => async (dispatch: Dispatch) => {
  const resp: { urls?: MonitoringProjectUrl[]; urls_amount: number } = await apiPost(
    API_ROUTES.MONITORING.IMPORT_URLS_FROM_SITEMAP(projID),
    { sitemap_url: sitemapUrl, only_count: onlyCheckAmount },
  );
  if (!onlyCheckAmount && resp.urls) {
    dispatch(addUrlsToProjectSucceed(projID, resp.urls));
  }
  return resp.urls_amount;
};
