import { CRMTask, TaskCheckListItem } from "#/store/types";
import { handleDispatchErrorAndDisplayToast, undefinedOr } from "#/util";
import classnames from "classnames";
import hash from "object-hash";
import React, { useEffect, useRef, useState } from "react";
import AddChecklistItem from "./components/AddCheckListItem";
import AddChecklistList from "./components/AddChecklistList";
import ChecklistItem from "./components/ChecklistItem";
import style from "./index.module.scss";
import TableCheckbox from "../TableCheckbox";

interface TaskChecklistBareProps {
  task: CRMTask;
  items: TaskCheckListItem[];
  isManager: boolean;
  adding: boolean;
  onAdd: (payload: string[]) => Promise<unknown>;
  onItemEdit: (payload: TaskCheckListItem) => Promise<unknown>;
  onItemRemove: (payload: TaskCheckListItem) => Promise<unknown>;
  onItemToggle: (payload: TaskCheckListItem) => Promise<unknown>;
  isExecutor: boolean;
  smallHeight?: boolean | false;
  selectionEnabled?: boolean;
  showTaskCompletionDate?: boolean;
}

const TaskChecklistBare = (props: TaskChecklistBareProps) => {
  // State
  const initialItemToAdd: Partial<TaskCheckListItem> = {
    status: false,
    name: "",
    finish_date: new Date(),
    task: props.task.pk,
  };
  const [itemToAdd, setItemToAdd] = useState(initialItemToAdd);
  const [itemsToAdd, setItemsToAdd] = useState("");
  const [addingMethod, setAddingMethod] = useState("");
  const _singleAddingItemInputRef = useRef(null);
  const _multipleAddingItemsInputRef = useRef(null);
  const selectionEnabled = undefinedOr(props.selectionEnabled, true);
  const showTaskCompletionDate = undefinedOr(props.showTaskCompletionDate, true);

  // Focus add item input ref each time the addingItem flag changes to true
  useEffect(() => {
    if (addingMethod === "single") {
      (_singleAddingItemInputRef.current as any).focus();
    } else if (addingMethod === "multiple") {
      (_multipleAddingItemsInputRef.current as any).focus();
    }
  }, [addingMethod]);

  // On new item events
  const onItemToAddEdit = (contents: string) => {
    setItemToAdd({
      ...itemToAdd,
      name: contents,
    });
  };

  const resetItemToAdd = () => setItemToAdd({ ...initialItemToAdd });
  const resetItemsToAdd = () => setItemsToAdd("");

  const onItemToAddDiscard = () => {
    resetItemToAdd();
    setAddingMethod("");
  };

  const onItemsToAddDiscard = () => {
    resetItemsToAdd();
    setAddingMethod("");
  };

  const onItemToAddSave = (hideInputAfterSave = false) =>
    new Promise((resolve, reject) => {
      if (itemToAdd.name && itemToAdd.name.length > 0) {
        props
          .onAdd([itemToAdd.name])
          .then(() => {
            resetItemToAdd();
            if (hideInputAfterSave) {
              setAddingMethod("");
            }
            resolve();
          })
          .catch(handleDispatchErrorAndDisplayToast);
      } else {
        reject();
      }
    });

  const onItemsToAddSave = () =>
    new Promise((resolve, reject) => {
      const checklistElements = itemsToAdd.split("\n").filter(el => el.length);
      if (checklistElements.length) {
        props
          .onAdd(checklistElements)
          .then(() => {
            onItemsToAddDiscard();
            resolve();
          })
          .catch(handleDispatchErrorAndDisplayToast);
      } else {
        reject();
      }
    });

  return (
    <div className={classnames(["box", "box-portlet", style.checklist])}>
      {/* Header */}
      <div className="box-header with-border">
        <div className="d-flex justify-content-between">
          <div className="align-self-center">
            <h4 className="box-title">
              <i className="fas fa-tasks mr-2" />
              <span>Список подзадач</span>
            </h4>
          </div>
          {props.isManager && (
            <div>
              <button
                className="btn btn-list row-action"
                type="button"
                disabled={props.adding}
                onClick={() => setAddingMethod("single")}
              >
                <i className="fas fa-plus" />
                <span className="ml-2">Добавить</span>
              </button>
              <button
                className="btn btn-list row-action"
                type="button"
                disabled={props.adding}
                onClick={() => setAddingMethod("multiple")}
              >
                <i className="fas fa-list" />
                <span className="ml-2">Добавить список</span>
              </button>
            </div>
          )}
        </div>
      </div>

      {/* List */}
      <div
        className={classnames([
          "box-body no-border",
          style["checklist-items-container"],
          ...(!props.smallHeight ? [style["checklist-items-container-big"]] : []),
          ...(props.items.length <= 0 ? ["flex-vertically-centered", "text-center"] : []),
        ])}
      >
        <div className={style["items-container"]}>
          {props.items.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  {selectionEnabled && (
                    <th>
                      <TableCheckbox checked={false} onChange={() => {}} />
                    </th>
                  )}
                  <th>Содержание</th>
                  {showTaskCompletionDate && <th>Дата завершения</th>}
                  {props.isManager && <th className="text-right" />}
                </tr>
              </thead>
              <tbody>
                {props.items.map((el: TaskCheckListItem) => (
                  <ChecklistItem
                    key={hash(el)}
                    selectionEnabled={selectionEnabled}
                    showTaskCompletionDate={showTaskCompletionDate}
                    item={el}
                    onEdit={props.onItemEdit}
                    onStatusToggle={props.onItemToggle}
                    onRemove={props.onItemRemove}
                    isManager={props.isManager}
                    isExecutor={props.isExecutor}
                    taskLoading={false}
                    task_finish_date={el.finish_date}
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className={classnames(["text-center", style["no-items-msg"]])}>Чеклист пуст.</div>
          )}
        </div>

        {addingMethod === "single" ? (
          <AddChecklistItem
            addingMethod={addingMethod}
            item={itemToAdd}
            onChange={onItemToAddEdit}
            onSave={onItemToAddSave}
            onDiscard={onItemToAddDiscard}
            ref={_singleAddingItemInputRef}
          />
        ) : (
          <AddChecklistList
            addingMethod={addingMethod}
            items={itemsToAdd}
            onChange={setItemsToAdd}
            onSave={onItemsToAddSave}
            onDiscard={onItemsToAddDiscard}
            ref={_multipleAddingItemsInputRef}
          />
        )}
      </div>
    </div>
  );
};

export default TaskChecklistBare;
