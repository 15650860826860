import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { TaskType } from "#/conf/strings";
import { taskPrioritiesById, TaskPriority, TaskPriorityInfo } from "#/taskPriority";
import { useReactSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";

const VALUE_NOT_SET = "-10";

export type TaskPrioritySelectProps = SelectCustomProps & {
  value: TaskPriority | null;
  onChange: (np: TaskPriority | null) => void;
};

type TaskPriorityDisplayFunction = (tt: TaskPriorityInfo | null) => string;

const defaultDF = (tt: TaskPriorityInfo | null) => (tt ? tt.name : "");

const ControllableTaskPrioritySelect = (props: TaskPrioritySelectProps) => {
  const df: TaskPriorityDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;
  const selectState = useReactSelect<number, TaskPriorityInfo>(
    _.values(taskPrioritiesById),
    taskPrioritiesById,
    df,
    props.value,
    { pkFieldName: "level" },
    [df, props.value],
  );
  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={false}
      options={selectState.options}
      value={selectState.selected}
      onChange={(option: SelectOption) => {
        props.onChange(option.value ? (option.value as TaskPriority) : null);
      }}
    />
  );
};

export default ControllableTaskPrioritySelect;
