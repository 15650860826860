import React from "react";
import style from "./index.module.scss";
import { SettingsPageTab } from "./types";
import { tabKindToString } from "./util";

const TabWrapper = (props: { id: SettingsPageTab; children: React.ReactNode }) => {
  const name = tabKindToString(props.id);
  return (
    <div className={style["tab-container"]}>
      <div className={style["tab-title"]}>
        <h3>{name}</h3>
      </div>
      <div className={style["tab-body"]}>{props.children}</div>
    </div>
  );
};

export default TabWrapper;
