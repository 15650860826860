import { apiGet, apiPut } from "#/api";
import InputFilter from "#/components/DebouncedInput";
import { API_ROUTES } from "#/conf/api";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import { ContentPlanEvent, SortType } from "#/store/types";
import { doPaging, handleDispatchErrorAndDisplayToast, sortTable } from "#/util";
import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import EventItem from "../Project/components/EventItem";
import DebouncedInput from "#/components/DebouncedInput";

export interface EventListOwnProps {}
export interface EventListConnectedProps {}
type EventListProps = EventListOwnProps & EventListConnectedProps;

const EventListHooks = (props: EventListProps) => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState({ events: [], copy: [] });
  const [project, setProject] = useState({ project: null, copy: null });
  const [sortType, setSortType] = useState(SortType.DESC);
  const [editMode, setEditMode] = useState(false);
  const [filters, setFilters] = useState({ name: "", link: "", query: "", comment: "" });
  const [pagination, setPagination] = useState({ page_count: 1, current_page: 1 });

  const fetchEvents = (page = 1) => {
    const params = [
      `?page=${page}`,
      `&name_filter=${filters.name}`,
      `&link_filter=${filters.link}`,
      `&query_filter=${filters.query}`,
      `&comment_filter=${filters.comment}`,
    ].join("");

    setLoading(true);

    apiGet(API_ROUTES.CONTENTPLAN.EVENTS(params))
      .then((response: any) => {
        setEvents({ events: response.events, copy: response.events });
        setPagination(state => ({ ...pagination, page_count: response.page_count }));
        setLoading(false);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  };

  useEffect(() => {
    fetchEvents();
  }, [filters, pagination.current_page]);

  const onEventItemChange = (pk: number, payload: Partial<ContentPlanEvent>, callback: any) => {
    apiPut(API_ROUTES.CONTENTPLAN.EVENTS_ITEM(pk), payload)
      .then(response => {
        callback();
      })
      .catch((err: Error) => {
        setLoading(false);
      });
  };

  const resetEdit = (evt: any) => {
    setEditMode(false);
    setProject(state => ({ ...state, project: state.copy }));
  };

  const onSort = (e: any, sortKey: string, isNumeric: boolean) => {
    const direction = sortType === SortType.DESC ? SortType.ASC : SortType.DESC;
    const newEvents = sortTable(events.events, sortKey, isNumeric);
    if (direction === SortType.DESC) {
      newEvents.reverse();
    }
    setEvents(state => ({ ...state, events: newEvents as any }));
    setSortType(direction);
  };

  const constructFilterByCb = (by: string) => (filter: string) => {
    setFilters(state => ({ ...state, [by]: filter }));
    // fetchEvents();
  };

  ////////////////////////
  // RENDER
  ////////////////////////

  const { page_count, current_page } = pagination;

  const renderedEvents = events.events.map((event: ContentPlanEvent, index: number) => (
    <EventItem onChange={onEventItemChange} event={event} key={index} />
  ));

  return (
    <div>
      <Header />

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="box-header with-border">
                <div className="d-flex justify-content-between">
                  {!editMode && (
                    <h4 className="box-title">
                      <i className="fas fa-layer-group" />
                      <span className="ml-2">Список всех мероприятий</span>
                    </h4>
                  )}
                </div>
              </div>
              <div className="box-body">
                {loading && (
                  <div className="flex-vertically-centered py-4 text-center">
                    <img src={loader} className="m-auto" alt="Loading Spinner" />
                  </div>
                )}

                <div className="row">
                  <div className="col-12">
                    <div className="box">
                      <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                        <h5 className="box-subtitle mt-0">
                          <span className="ml-2">Мероприятия</span>
                        </h5>
                      </div>
                      <div className="box-body">
                        <table className="table table-contentplan-project">
                          <thead>
                            <tr>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "name", false)}
                              >
                                Название
                                <DebouncedInput
                                  className="fz-13 form-control-small form-control-small-height form-control"
                                  placeholder="Фильтр"
                                  onChange={constructFilterByCb("name")}
                                  ms={1000}
                                />
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "link", false)}
                              >
                                Ссылка
                                <DebouncedInput
                                  className="fz-13 form-control-small form-control-small-height form-control"
                                  placeholder="Фильтр"
                                  onChange={constructFilterByCb("link")}
                                  ms={1000}
                                />
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "wordstat", true)}
                              >
                                WordStat
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "query", false)}
                              >
                                Запрос
                                <DebouncedInput
                                  className="fz-13 form-control-small form-control-small-height form-control"
                                  placeholder="Фильтр"
                                  onChange={constructFilterByCb("query")}
                                  ms={1000}
                                />
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "seo_text", true)}
                              >
                                SEO-текст
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "status", false)}
                              >
                                Статус
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "short_text", true)}
                              >
                                Короткое описание
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "tech_info", true)}
                              >
                                Тех. Инфо
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "sujet_text", true)}
                              >
                                Описание сюжета
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "video", true)}
                              >
                                Видео
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "reviews", true)}
                              >
                                Отзывы
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "image", true)}
                              >
                                Кол-во картинок
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "comment", false)}
                              >
                                Комментарий
                                <DebouncedInput
                                  className="fz-13 form-control-small form-control-small-height form-control"
                                  placeholder="Фильтр"
                                  onChange={constructFilterByCb("comment")}
                                  ms={1000}
                                />
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "position", true)}
                              >
                                Съемщик позиций
                              </th>
                              <th
                                className="cursor-pointer"
                                onClick={e => onSort(e, "links", true)}
                              >
                                Ссылки
                              </th>
                            </tr>
                          </thead>

                          {!loading && <tbody>{renderedEvents}</tbody>}
                        </table>
                        <nav className="mt-5">
                          <ul className="pagination pagination-sm justify-content-center">
                            {page_count > 1 &&
                              doPaging(current_page, {
                                range: page_count >= 5 ? 5 : page_count,
                                pages: page_count,
                              }).map((num: number, i: number) => {
                                return (
                                  <li
                                    key={i}
                                    className={
                                      "page-item " + (current_page === num ? "active" : "")
                                    }
                                    onClick={() =>
                                      setPagination(state => ({ ...state, current_page: num }))
                                    }
                                  >
                                    <span className="page-link">
                                      {num}
                                      {current_page === num && (
                                        <span className="sr-only">(current)</span>
                                      )}
                                    </span>
                                  </li>
                                );
                              })}
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EventListHooks;
