import FAIconText from "#/components/FAIconText";
import { declOfNum } from "#/util";
import cn from "classnames";
import _ from "lodash";
import React, { useContext } from "react";
import { Button } from "reactstrap";
import style from "../index.module.scss";
import ProjectContext from "../ProjectContext";
import { TabName, tabName, TabPage, tabs, visorTabNameToFieldName } from "../util";

const SidebarTab = (props: { id: TabName; children: any }) => {
  const projectContext = useContext(ProjectContext);
  const currentlySelected = projectContext.activeTab === props.id;
  return (
    <Button
      className={cn({
        [style["sidebar-tab"]]: true,
        [style["sidebar-tab_active"]]: currentlySelected,
      })}
      onClick={() => {
        projectContext.toggleTab(props.id);
      }}
      outline={false}
    >
      {props.children}
    </Button>
  );
};

const MonitoringPageTab = (props: { id: TabName }) => {
  const tabId = props.id;
  const thisTabName = tabName(props.id);
  const projectContext = useContext(ProjectContext);
  const correspFieldName = visorTabNameToFieldName(props.id);
  const correspFieldAmountOfChanges = projectContext.lastCheckInfo.fieldChanges[correspFieldName];
  const text = `${thisTabName} (${declOfNum(correspFieldAmountOfChanges, [
    "изменение",
    "изменения",
    "изменений",
  ])})`;
  return (
    <SidebarTab id={props.id}>
      <FAIconText type="far" name="list-alt" text={text} />
    </SidebarTab>
  );
};

const Sidebar = () => {
  const tabsToRender = ((_.keys(tabs) as any) as TabName[]).map((id: TabName) => {
    const tabDesc = _.get(tabs, id);
    const isMonitoringPageTab = tabDesc.page === TabPage.VISOR;
    if (isMonitoringPageTab) {
      return <MonitoringPageTab key={id} id={id} />;
    } else {
      return (
        <SidebarTab key={id} id={id}>
          <FAIconText type="far" name="list-alt" text={tabName(id)} />
        </SidebarTab>
      );
    }
  });

  return (
    <div className="sidebar">
      <div className="sidebar-inner">
        <ul role="tablist">{tabsToRender}</ul>
      </div>
    </div>
  );
};

export default Sidebar;
