import { APIError } from "#/api";
import Checkbox from "#/components/Checkbox";
import CopyInput from "#/components/CopyInput";
import { showErrorToast } from "#/components/toasts";
import UserRoleSelectMultiple from "#/components/UserRoleSelect/UserRoleSelectMultiple";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import ism from "#/store/modules/invitationSystem";
import {
  generateInvitationLink,
  resetInvitationLink,
} from "#/store/modules/invitationSystem/actions";
import { DispatchProp, PrimaryKey, StoreRootState } from "#/store/types";
import { constructRegisterViaInvitePath } from "#/util/paths";
import classnames from "classnames";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import style from "../index.module.scss";

interface UserInvitationModalProps {
  isOpen: boolean;
  toggle: () => void;
  invitationLink: string | null;
  generatingInvitationLink: boolean;
  generatingInvitationLinkFailed: boolean;
  generateInvitationLink: DispatchProp<typeof generateInvitationLink>;
  resetInvitationLink: DispatchProp<typeof resetInvitationLink>;
}

const UserInvitationModal = (props: UserInvitationModalProps) => {
  const [selectedUserRoles, setSelectedUserRoles] = useState<PrimaryKey[]>([]);
  const [showLinkVersion, setShowLinkVersion] = useState<boolean>(true);

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader toggle={props.toggle}>Пригласить пользователя</ModalHeader>
      <ModalBody>
        {props.generatingInvitationLink ? (
          "...loading"
        ) : (
          // <ClipLoader />
          <React.Fragment>
            <div className="form-group">
              <label className="mb-2">Код приглашения</label>
              <CopyInput
                className={classnames([style["invitation-code-display"], "form-control"])}
                value={(() => {
                  const code = props.invitationLink;
                  if (!code) {
                    return "";
                  }
                  if (showLinkVersion) {
                    return constructRegisterViaInvitePath(code);
                  }
                  return code;
                })()}
              />
              <div style={{ display: "flex", justifyContent: "flex-end" }} className="mt-2">
                <label className="mr-2">Ссылка</label>
                <Checkbox
                  checked={showLinkVersion}
                  onChange={() => setShowLinkVersion(!showLinkVersion)}
                  title="Показывать ссылку"
                />
              </div>
            </div>

            <div className="mt-2 form-group">
              <label className="mb-1">Роль пользователя</label>
              <UserRoleSelectMultiple
                value={selectedUserRoles}
                onChange={e => {
                  setSelectedUserRoles(e);
                  props.resetInvitationLink();
                }}
                className="form-control select2-custom"
              />
            </div>
            <div className="form-group">
              <button
                type="submit"
                className={classnames([
                  "btn",
                  "btn-success",
                  ...[props.generatingInvitationLink ? "disabled" : null],
                ])}
                disabled={props.generatingInvitationLink}
                onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                  props.generateInvitationLink(selectedUserRoles).catch((error: APIError) => {
                    showErrorToast(error.message);
                  });
                }}
              >
                Создать ссылку для приглашения
              </button>
            </div>
          </React.Fragment>
        )}
      </ModalBody>
    </Modal>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  invitationLink: ism.selectors.getInvitationLink(store),
  generatingInvitationLink: ism.selectors.isGeneratingInvitationLink(store),
  generatingInvitationLinkFailed: ism.selectors.didInvitationLinkGenerationFail(store),
});

export default connect(mapStateToProps, {
  ...getActionsFromModule(ism, ["generateInvitationLink", "resetInvitationLink"]),
})(
  withAuthentication<UserInvitationModalProps>(UserAuthenticationStatus.AUTHENTICATED)(
    UserInvitationModal,
  ),
);
