import MutationTypes from "./mutationTypes";
import ActionTypes from "./actionTypes";
import { Counter, Goal, Token } from "./types";
import { Dispatch } from "redux";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import { PrimaryKey, StoreRootState } from "#/store/types";
import { apiGet, apiPost } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { action } from "typesafe-actions";
import _ from "lodash";

export const fetchCountersStart = () => action(MutationTypes.FETCH_COUNTERS_START);

export const fetchCountersFailure = () => action(MutationTypes.FETCH_COUNTERS_FAILURE);

export const fetchCountersSucceed = (
  tokens: Record<PrimaryKey, Token>,
  counters: Counter[],
): ActionTypes.FetchCountersSucceed =>
  action(MutationTypes.FETCH_TOKENS_START, { tokens, counters });

export const fetchCounters = (token: string) => (dispatch: Dispatch) => {
  dispatch(fetchCountersStart());
  apiPost(API_ROUTES.METRIKA.FETCH_COUNTERS, {
    token,
  })
    .then(res => {
      dispatch(fetchCountersSucceed(res.tokens, res.counters));
    })
    .catch(err => {
      showErrorToast(err.message);
    });
};

export const selectToken = (
  counterId: PrimaryKey,
  tokenId: PrimaryKey | null,
): ActionTypes.SelectToken => ({
  type: MutationTypes.SELECT_TOKEN,
  payload: {
    counterId,
    tokenId,
  },
});

export const addGoals = (
  goals: Record<PrimaryKey, Goal>,
  counterId: PrimaryKey,
): ActionTypes.AddGoals => ({
  type: MutationTypes.ADD_GOALS,
  payload: {
    goals,
    counterId,
  },
});

export const changeCreatingGoal = (goal: Goal) => ({
  type: MutationTypes.CHANGE_CREATING_GOAL,
  payload: { goal },
});

export const deleteGoalFromMetrika = (goalName: string, token: string, counterId: number) => (
  dispatch: Dispatch,
) => {
  fetch(`https://api-metrika.yandex.net/management/v1/counter/${counterId}/goals`, {
    headers: {
      Authorization: `OAuth ${token}`,
    },
  })
    .then(res => res.json())
    .then(response => {
      if (response.code !== 200 && response.errors) {
        throw response.errors[0].message;
      }
      dispatch(addGoals(response.goals, counterId));
      response.goals
        .filter((goal: Goal) => goal.name === goalName.toString().trim())
        .forEach((goal: Goal) => {
          fetch(
            `https://api-metrika.yandex.net/management/v1/counter/${counterId}/goal/${goal.id}`,
            {
              method: "DELETE",
              headers: {
                Authorization: `OAuth ${token}`,
              },
            },
          )
            .then(res => res.json())
            .then(res => {
              if (res.code !== 200 && res.errors) {
                throw res.errors[0].message;
              }
              return showSuccessToast(
                `Успешно удалена цель с именем ${goal.name}, id ${goal.id} на счетчике ${counterId}`,
              );
            })
            .catch(err => {
              showErrorToast(err);
            });
        });
    });
};

export const fetchTokensStart = () => action(MutationTypes.FETCH_TOKENS_START);

export const fetchTokensFailure = () => action(MutationTypes.FETCH_TOKENS_FAILURE);

export const fetchTokensSucceed = (
  tokens: Record<PrimaryKey, Token>,
  counters: Record<PrimaryKey, Counter>,
): ActionTypes.FetchTokenSucceed =>
  action(MutationTypes.FETCH_TOKENS_SUCCEED, {
    tokens,
    counters,
  });

export const fetchSavedTokens = () => (dispatch: Dispatch) => {
  dispatch(fetchTokensStart());
  apiGet(API_ROUTES.METRIKA.ALL_TOKENS)
    .then(res => {
      res.errors.forEach(err => showErrorToast(err.message));
      dispatch(fetchTokensSucceed(res.tokens, res.counters));
    })
    .catch(err => {
      showErrorToast(err.message);
      dispatch(fetchTokensFailure());
    });
};

export const postGoalStart = () => action(MutationTypes.POST_GOAL_START);

export const postGoalFailure = () => action(MutationTypes.POST_GOAL_FAILURE);

export const postGoalSuccess = (goals: Record<PrimaryKey, Goal>) =>
  action(MutationTypes.POST_GOAL_SUCCEED, { goals });

export const postGoal = () => (dispatch: Dispatch, getState: () => StoreRootState) => {
  const metrikaState = getState().metrika.goals;
  const goal = metrikaState.creatingGoal;
  const selection = metrikaState.selection;
  dispatch(postGoalStart());
  apiPost(API_ROUTES.METRIKA.ADD_GOAL, {
    selection,
    goal,
  }).then(res => {
    res.errors.forEach(err => showErrorToast(err.message));
  });
};

export const fetchGoals = (counterId: PrimaryKey) => (
  dispatch: Dispatch,
  getState: () => StoreRootState,
) => {
  const state = getState().metrika.goals;
  //   //add exception?
  const tokenId = state.counters[counterId].tokens[0];
  console.log("Inside fetchGoals");
  apiPost(API_ROUTES.METRIKA.FETCH_GOALS, {
    token: tokenId,
    counter: counterId,
  })
    .then(res => {
      dispatch(addGoals(res.goals, counterId));
    })
    .catch(err => {
      showErrorToast(err.message);
    });
};

// export const fetchGoalsForCounter = (counterId: PrimaryKey) => (
//   dispatch: Dispatch,
//   getState: () => StoreRootState,
// ) => {
//   const state = getState().metrika.goals;
//   //add exception?
//   const tokenId = state.counters[counterId].tokens[0];
//   console.log("Inside fetchGoalsForCounter", tokenId);

//   fetchGoals(tokenId, counterId);
// };

// export const editGoalInMetrika = (goal:)

export const selectGoals = (selection: PrimaryKey[]) =>
  action(MutationTypes.SELECT_GOALS, {
    selection,
  });

export const deleteSelectedGoals = () => (dispatch: Dispatch, getState: () => StoreRootState) => {
  console.log("Inside deleting");
  const state = getState().metrika.goals;
  const goals = state.selectedGoals;
  const allCounters = state.counters;
  // я пишу этот недокод не спав 30 часов, переписать
  const counters = _.groupBy(
    goals,
    goal => Object.values(allCounters).find(counter => counter.goals.includes(goal))?.id,
  );
  const allTokens = state.tokens;
  const tokens = _.groupBy(Object.keys(counters), counter =>
    Object.values(allTokens).find(token => token.counters.includes(parseInt(counter, 10))),
  );
  console.log(counters, tokens);
};
