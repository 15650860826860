import { CODE_NON_REACHABLE, falseIfUndefinedElseBoolean } from "#/util";
import cn from "classnames";
import React from "react";
import { SortTasksBy } from "../..";
import style from "../../index.module.scss";

const sorkTasksByToId = (sb: SortTasksBy) => {
  switch (sb) {
    case SortTasksBy.NAME:
      return "name";
    case SortTasksBy.PRIORITY:
      return "priority";
    case SortTasksBy.COMPLETION_DATE:
      return "completion-date";
    case SortTasksBy.CREATION_DATE:
      return "createion-date";
    case SortTasksBy.EXECUTOR_NAME:
      return "executor-name";
    case SortTasksBy.MANAGER_NAME:
      return "manager-name";
    case SortTasksBy.PROGRESS:
      return "progress";
    case SortTasksBy.PROJECT_NAME:
      return "project-name";
    case SortTasksBy.STATUS:
      return "status";
    case SortTasksBy.MANAGER_MINUTES_TO_COMPLETE:
      return "manager_minutes_to_complete";
    default:
      CODE_NON_REACHABLE();
      return "";
  }
};

const TableHeadRow = (props: {
  showExecutorColumn: boolean;
  showManagerColumn: boolean;
  showProjectColumn: boolean;
  onSortToggle: (a: SortTasksBy) => void;
  sortingBy: SortTasksBy;
  sortingDirection: boolean;
}) => {
  const ColumnHeader = (props_: { children: React.ReactNode; sortMethod: SortTasksBy; large?: boolean }) => {
    const large = falseIfUndefinedElseBoolean(props_.large);
    return (
      <th
        className={cn([
          style["column-head-button"],
          style[`column-head-button_${sorkTasksByToId(props_.sortMethod)}`],
          ...(large ? ["d-none d-lg-table-cell"] : []),
        ])}
        onClick={() => props.onSortToggle(props_.sortMethod)}
      >
        <span className={style["column-head-content"]}>{props_.children}</span>
        <span className={style["column-head-sorting-caret"]}>
          {props.sortingBy === props_.sortMethod && props.sortingDirection ? (
            <i className="fas fa-caret-down" />
          ) : (
            <i className="fas fa-caret-up" />
          )}
        </span>
      </th>
    );
  };

  return (
    <tr>
      <ColumnHeader sortMethod={SortTasksBy.PRIORITY}>
        <i className="fas fa-flag" />
      </ColumnHeader>
      <ColumnHeader sortMethod={SortTasksBy.NAME}>Задача</ColumnHeader>
      <ColumnHeader sortMethod={SortTasksBy.STATUS}>Статус</ColumnHeader>
      {props.showProjectColumn && (
        <ColumnHeader sortMethod={SortTasksBy.PROJECT_NAME} large={true}>
          Проект
        </ColumnHeader>
      )}
      {props.showManagerColumn && (
        <ColumnHeader sortMethod={SortTasksBy.MANAGER_NAME} large={true}>
          Назначивший
        </ColumnHeader>
      )}
      {props.showExecutorColumn && (
        <ColumnHeader sortMethod={SortTasksBy.EXECUTOR_NAME} large={true}>
          Исполнитель
        </ColumnHeader>
      )}
      <ColumnHeader sortMethod={SortTasksBy.PROGRESS} large={true}>
        Прогресс
      </ColumnHeader>
      <ColumnHeader sortMethod={SortTasksBy.CREATION_DATE} large={true}>
        Создание
      </ColumnHeader>
      <ColumnHeader sortMethod={SortTasksBy.COMPLETION_DATE} large={true}>
        Окончание
      </ColumnHeader>
      <ColumnHeader sortMethod={SortTasksBy.MANAGER_MINUTES_TO_COMPLETE} large={true}>
        Время оценки
      </ColumnHeader>
      <th />
    </tr>
  );
};

export default TableHeadRow;
