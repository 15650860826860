import { APIError, apiErrorToString } from "#/api";
import { showErrorToast, showSuccessToast } from "#/components/toasts";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import loading_bar from "#/scenes/Auth/assets/login-spinner.svg";
import invitationSystemModule from "#/store/modules/invitationSystem";
import { StoreRootState, User } from "#/store/types";
import cn from "classnames";
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { Link } from "react-router-dom";

interface RegisterViaInvitationConnectedProps
  extends RouteComponentProps<{ invitationLink: string | undefined }> {
  registering: boolean;
  registrationFailed: boolean;
  registerViaInvitation: any;
  isAuthenticated: boolean;
  invitationLink: string | undefined;
  push: typeof push;
}

interface RegisterViaInvitationState {
  invitationLink: string;
  passwordRepeat: string;
  userData: Partial<User>;
}

export interface RegisterViaInvitationOwnProps {}

type RegisterViaInvitationProps = RegisterViaInvitationConnectedProps &
  RegisterViaInvitationOwnProps;

class RegisterViaInvitationPage extends React.Component<
  RegisterViaInvitationProps,
  RegisterViaInvitationState
> {
  public state = {
    passwordRepeat: "",
    invitationLink: "",
    userData: {
      password: "",
      email: "",
      first_name: "",
      last_name: "",
      skype: "",
      discord: "",
      info: "",
      phone: "",
      telegram: "",
    },
  };

  public onSubmit = (e: any) => {
    e.preventDefault();
    if (this.state.invitationLink) {
      this.props
        .registerViaInvitation(this.state.invitationLink, this.state.userData)
        .then(() => {
          showSuccessToast("Успешная регистрация");
          this.props.push("/auth/login");
        })
        .catch((err: APIError) => {
          showErrorToast(apiErrorToString(err));
        });
    }
  };

  public clearErrorMessage = (e: any) => {
    // ???
  };

  public onFieldChange = (fieldName: string) => (e: any) => {
    this.setState({
      ...this.state,
      [fieldName]: e.target.value,
    });
  };

  public onUserFieldChange = (fieldName: string) => (e: any) => {
    this.setState({
      ...this.state,
      userData: {
        ...this.state.userData,
        [fieldName]: e.target.value,
      },
    });
  };

  public componentDidMount() {
    if (this.props.invitationLink) {
      this.setState({
        ...this.state,
        invitationLink: this.props.invitationLink,
      });
    }
  }

  public render() {
    const { userData, invitationLink } = this.state;
    const { registering } = this.props;
    const { email, password, first_name, last_name } = userData;

    // TODO: remove
    const errorMessage = null;

    return (
      <div>
        <div className="login-item-fluid">
          <div className="login-wrapper">
            <div
              className={
                "alert alert-danger text-center mb-4 login-error " +
                (errorMessage ? "active" : undefined)
              }
            >
              {errorMessage}
            </div>

            <div className="login-logo">
              <img src="https://exemetrics.com/img/rocket.png" alt="rocket" />
            </div>
            <div className="login-sign-in">
              <div className="login-head">
                <h3 className="login-title">Регистрация по приглашению</h3>
              </div>

              {/* Invitation code */}
              <form className="login-form" onSubmit={this.onSubmit}>
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Код приглашения"
                    name="invitation"
                    value={invitationLink ? invitationLink : ""}
                    onChange={this.onFieldChange("invitationLink")}
                    onClick={this.clearErrorMessage}
                    autoComplete="off"
                    required={true}
                  />
                </div>

                {/* Email */}
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="E-Mail"
                    name="email"
                    value={email}
                    onChange={this.onUserFieldChange("email")}
                    onClick={this.clearErrorMessage}
                    autoComplete="off"
                    required={true}
                  />
                </div>

                {/* Password */}
                <div className="form-group">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Пароль"
                    name="password"
                    value={password}
                    onChange={this.onUserFieldChange("password")}
                    onClick={this.clearErrorMessage}
                    required={true}
                  />
                </div>

                {/* First name */}
                <div className="form-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Имя"
                    name="first_name"
                    value={first_name}
                    onChange={this.onUserFieldChange("first_name")}
                    onClick={this.clearErrorMessage}
                    required={true}
                  />
                </div>

                {/* Last name */}
                <div className="form-group">
                  <input
                    className="form-control login-input-last"
                    type="text"
                    placeholder="Фамилия"
                    name="last_name"
                    value={last_name}
                    onChange={this.onUserFieldChange("last_name")}
                    onClick={this.clearErrorMessage}
                    required={true}
                  />
                </div>

                <div className="login-form-action">
                  <button
                    className={cn({ btn: true, loading: registering })}
                    type="submit"
                    disabled={registering}
                  >
                    {registering ? (
                      <img
                        src={loading_bar}
                        style={{ height: "30px" }}
                        alt="Registration in progress..."
                      />
                    ) : (
                      "Зарегистрироваться"
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="login-item-center">
          <div className="text-center">
            <span className="login-account-msg">
              <Link to="/auth/login" className="login-link">
                <i className="fas fa-long-arrow-alt-left" />
                <span className="ml-2">Вернуться к логину</span>
              </Link>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: StoreRootState, ownProps: RegisterViaInvitationProps) => ({
  registering: invitationSystemModule.selectors.isRegistrationInProcess(store),
  registrationFailed: invitationSystemModule.selectors.didRegistrationFail(store),
  invitationLink: ownProps.match.params.invitationLink,
});

const mapDispatchToProps = {
  push,
  registerViaInvitation: invitationSystemModule.actions.registerViaInvitation,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withAuthentication<RegisterViaInvitationProps>(UserAuthenticationStatus.GUEST)(
      RegisterViaInvitationPage,
    ),
  ),
);
