import React from "react";
import { Route, Switch } from "react-router";
import CreateScene from "./components/CreateScene";
import EditScene from "./components/EditScene";

const MetrikaGoalsScene = ({ match }: any) => {
  return (
    <Switch>
      <Route
        path={`${match.url}/edit`}
        exact={true}
        render={(props: any) => <EditScene {...props} />}
      />
      <Route path={match.url} exact={false} render={(props: any) => <CreateScene {...props} />} />
    </Switch>
  );
};

export default MetrikaGoalsScene;
