import { APIError, extractCursorFromURL } from "#/api";
import Loader from "#/components/Loader";
import ScrollSentinel from "#/components/ScrollSentinel";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import searchModule from "#/store/modules/search";
import {
  MakeSearchRequest,
  PaginationCursor,
  SearchItem,
  SearchKind,
  SearchResult,
  StoreRootState,
} from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import { useDocumentTitle } from "#/util/hooks";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Card } from "reactstrap";
import SearchKindsBlock from "./components/SearchKindsBlock";
import style from "./index.module.scss";
import { isSearchKindValid, searchKindRenderer, searchKindToPageTitle } from "./util";

interface SearchPageProps extends RouteComponentProps<{}> {
  makeSearchRequest: MakeSearchRequest;
  query: string | null;
  setQuery: (q: string | null) => void;
}

const SearchPage = (props: SearchPageProps) => {
  // Get URL params
  const urlParams = new URLSearchParams(props.location.search);
  const urlSearchKind: string | null = urlParams.get("kind");
  const urlQuery: string | null = urlParams.get("query");
  // Flags
  const [loading, setLoading] = useState(false);
  const [searchKind, setSearchKind] = useState<SearchKind>(
    isSearchKindValid(urlSearchKind)
      ? (_.toNumber(urlSearchKind)! as SearchKind)
      : SearchKind.TASKS,
  );
  const [items, setItems] = useState<SearchItem[]>([]);
  const [totalItemsFound, setTotalItemsFound] = useState<number | null>(null);
  // Pagination cursor
  const [nextCursor, setNextCursor] = useState<PaginationCursor>(null);
  const [fetchedEverything, setFetchedEverything] = useState<boolean>(false);

  // Change query in the redux store when URL param changes
  useEffect(() => {
    if (!_.isEqual(urlQuery, props.query)) {
      props.setQuery(urlQuery);
    }
  }, [urlQuery]);

  // Sync current page path with search params
  useEffect(() => {
    if (_.isEqual(urlQuery, props.query)) {
      props.history.push({
        search: new URLSearchParams({
          query: props.query ? props.query : "",
          kind: _.toString(searchKind),
        }).toString(),
      });
    }
  }, [props.query, searchKind]);

  useEffect(() => {
    // When user leaves the page, search params are reset
    return () => {
      props.setQuery("");
    };
  }, []);

  // Set appropriate document title
  useDocumentTitle(() => searchKindToPageTitle(searchKind), [searchKind]);

  const fetchNextItems = useCallback(
    (
      query_: string,
      searchKind_: SearchKind,
      cursor_: PaginationCursor,
      fetchedEverything_: boolean,
      items_: SearchItem[],
    ) => {
      if (loading) {
        return;
      }
      if (fetchedEverything_) {
        return;
      }
      setLoading(true);
      props
        .makeSearchRequest(query_, { kind: searchKind_ }, cursor_)
        .then((searchResult: SearchResult) => {
          const loadedItemsIds = items_.map(i_ => i_.pk);
          setItems([
            ...items_,
            ...searchResult.items.filter(i => !_.includes(loadedItemsIds, i.pk)),
          ]);
          setTotalItemsFound(searchResult.total_items);
          if (!searchResult.next) {
            // There is nothing left to fetch
            setFetchedEverything(true);
          } else {
            // There is something left to fetch
            setNextCursor(extractCursorFromURL(searchResult.next));
          }
          // Loaded
          setLoading(false);
        })
        .catch((err: APIError) => {
          handleDispatchErrorAndDisplayToast(err);
          setLoading(false);
        });
    },
    [
      setLoading,
      setItems,
      props.history,
      loading,
      props.makeSearchRequest,
      setTotalItemsFound,
      setFetchedEverything,
      setLoading,
    ],
  );

  // On query change reset everything and fetch new data
  useEffect(() => {
    setItems([]);
    setNextCursor(null);
    if (props.query !== null) {
      fetchNextItems(props.query ? props.query : "", searchKind, null, false, []);
    }
  }, [props.query, searchKind]);

  const currentSearchKindItemRenderer = useMemo(() => searchKindRenderer(searchKind), [items]);

  return (
    <div className={style.page}>
      <Card className={style["left-block"]}>
        <div className={style["search-header"]}>
          <div className={style["search-title"]}>
            <h2>{`Поиск по запросу "${props.query ? props.query : ""}"`}</h2>
          </div>
          <div className={style["total-items-found"]}>
            {(() => {
              const whats = {
                [SearchKind.USERS]: "пользователей",
                [SearchKind.PROJECTS]: "проектов",
                [SearchKind.TASKS]: "задач",
                [SearchKind.ALL]: "",
              };
              const what = _.get(whats, searchKind, "");
              if (!totalItemsFound) {
                return `0 ${what} найдено`;
              }
              return `${totalItemsFound} ${what} найдено`;
            })()}
          </div>
        </div>
        {(() => {
          const renderedItems = (() => {
            if (items.length !== 0 || (!loading && items.length === 0)) {
              return (
                <div className={style["items-container"]}>
                  {items.length !== 0
                    ? items.map((item_: SearchItem) => {
                        const renderer = currentSearchKindItemRenderer;
                        return renderer(item_);
                      })
                    : !loading && <div className={style["no-items-found"]}>Ничего не найдено</div>}
                </div>
              );
            }
            return null;
          })();
          return (
            <>
              {renderedItems}
              {!fetchedEverything && (
                <div className={style["loader-container"]}>{loading && <Loader />}</div>
              )}
              <ScrollSentinel
                whenReached={() =>
                  fetchNextItems(
                    props.query ? props.query : "",
                    searchKind,
                    nextCursor,
                    fetchedEverything,
                    items,
                  )
                }
              />
            </>
          );
        })()}
      </Card>
      <Card className={style["right-block"]}>
        <SearchKindsBlock
          setCurrentSearchKind={setSearchKind}
          currentSelectedSearchKind={searchKind}
        />
        <div className={style["filters-block"]}/>
        <div className={style["sorting-block"]}/>
      </Card>
    </div>
  );
};

export default connect(
  (store: StoreRootState) => ({
    query: searchModule.selectors.getQuery(store, null),
  }),
  { ...getActionsFromModule(searchModule, ["makeSearchRequest", "setQuery"]) },
)(withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(SearchPage as any));
