import { useDebounced } from "#/util/hooks";
import React from "react";
import { InputProps } from "reactstrap";
import "./index.scss";
import { undefinedOr } from "#/util";

interface DebouncedInputConnectedProps {}

export type DebouncedInputOwnProps = Omit<InputProps, "onChange"> & {
  onChange: (v: string) => void;
  ms?: number;
};

type DebouncedInputProps = DebouncedInputConnectedProps & DebouncedInputOwnProps;

const DebouncedInput = (props: DebouncedInputProps) => {
  const ms = undefinedOr(props.ms, 1000);
  const [value, setValue] = React.useState<string>("");
  const debouncedValue = useDebounced(value, ms);
  React.useEffect(() => props.onChange(debouncedValue), [debouncedValue]);
  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value),
    [],
  );
  return <input {...props} onChange={onChange} />;
};

export default DebouncedInput;
