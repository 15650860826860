import { SubmitEvent } from "#/store/types";
import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface RandomProxyModalProps {
  isOpen: boolean | false;
  toggleModal: any;
}

const RandomProxyModal = ({ isOpen, toggleModal }: RandomProxyModalProps) => {
  const [listTitle, setListTitle] = useState("");
  const [labelTitle, setLabelTitle] = useState("");

  const handleSubmit = (evt: SubmitEvent) => {
    evt.preventDefault();
    const pathName = `http://seo-rocket.ru/api/proxy/proxies/get_by_label/?format=json&label=${labelTitle}&random=True`;
    const win = window.open(pathName, "_blank");
    if (win) { win.focus(); }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader toggle={toggleModal}>Получить рандомный прокси</ModalHeader>
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <div className="form-group">
            <label>Название лэйбла</label>
            <input
              type="text"
              className="form-control"
              onChange={e => setLabelTitle(e.target.value)}
              value={labelTitle}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success">
            Получить прокси
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default RandomProxyModal;
