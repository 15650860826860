import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import * as sessionModule from "#/store/modules/session";
import { FetchCurrentUserFullSettings, StoreRootState, UserSettings } from "#/store/types";
import { CODE_NON_REACHABLE, handleDispatchErrorAndDisplayToast } from "#/util";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Card } from "reactstrap";
import GeneralTab from "./GeneralTab";
import style from "./index.module.scss";
import NotificationsTab from "./NotificationsTab";
import SecurityTab from "./SecurityTab";
import Tabs from "./Tabs";
import { SettingsPageTab } from "./types";

interface ConnectedStateProps {}

interface ConnectedDispatchProps {
  fetchCurrentUserFullSettings: FetchCurrentUserFullSettings;
}

type ConnectedProps = ConnectedStateProps & ConnectedDispatchProps;

interface OwnProps {}

type SettingsProps = OwnProps & ConnectedProps;

const SettingsPage = (props: SettingsProps) => {
  const [currentTab, setCurrentTab] = useState<SettingsPageTab>(SettingsPageTab.GENERAL);

  const [userSettings, setUserSettings] = useState<UserSettings | null>(null);
  const [loadingUserSettings, setLoadingUserSettings] = useState<boolean>(false);

  useEffect(() => {
    setLoadingUserSettings(true);
    props
      .fetchCurrentUserFullSettings()
      .then((us: UserSettings) => {
        setUserSettings(us);
        setLoadingUserSettings(false);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  }, []);

  const renderedTab = useMemo(() => {
    const commonTabProps = {
      userSettings: userSettings!,
      setUserSettings,
      loadingUserSettings,
    };
    switch (currentTab) {
      case SettingsPageTab.GENERAL:
        return <GeneralTab {...commonTabProps} />;
      case SettingsPageTab.SECURITY:
        return <SecurityTab {...commonTabProps} />;
      case SettingsPageTab.NOTIFICATIONS:
        return <NotificationsTab {...commonTabProps} />;
      default: {
        CODE_NON_REACHABLE();
        return null;
      }
    }
  }, [currentTab, userSettings, setUserSettings, loadingUserSettings]);

  return (
    <div className={style.page}>
      <section className="content-header">
        <h1>
          Настройки \ <small>Пользователь</small>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/">
              <i className="fa fa-dashboard" /> Главная
            </Link>
          </li>
          <li className="breadcrumb-item active">Панель управления</li>
        </ol>
      </section>
      <div className={style["page-content"]}>
        <Card className={style["left-block"]}>
          <Tabs onChange={(id: SettingsPageTab) => setCurrentTab(id)} currentTab={currentTab} />
        </Card>
        <Card className={style["right-block"]}>{renderedTab}</Card>
      </div>
    </div>
  );
};

export default connect<ConnectedStateProps, ConnectedDispatchProps, OwnProps, StoreRootState>(
  (store: StoreRootState) => ({}),
  {
    ...getActionsFromModule(sessionModule, ["fetchCurrentUserFullSettings"]),
  },
)(withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(SettingsPage as any));
