import Page from "#/components/Page";
import React from "react";
import { Switch, Route } from "react-router";

import Index from "./components/Index";
import MetrikaTop50Scene from "./components/Top50";
import MetrikaGoalsScene from "./components/Goals";
import Wrapper from "../Metrika/components/Wrapper";

const MetrikaScene = ({ match }: any) => {
  return (
    <Switch>
      <Route
        path={match.url}
        exact={true}
        render={() => (
          <Wrapper title="Metrika" headerName="Панель управления">
            <Index />
          </Wrapper>
        )}
      />

      <Route
        path={`${match.url}/top50`}
        exact={false}
        render={(props: any) => (
          <Wrapper title="Топ-50" headerName="Топ-50">
            <MetrikaTop50Scene {...props} />
          </Wrapper>
        )}
      />

      <Route
        path={`${match.url}/goals`}
        exact={false}
        render={(props: any) => (
          <Wrapper
            title="Цели"
            headerName="Цели"
            headerLinks={[
              ["Создание целей", "/services/metrika/goals/create"],
              ["Изменение целей", "/services/metrika/goals/edit"],
            ]}
          >
            <MetrikaGoalsScene {...props} />
          </Wrapper>
        )}
      />
    </Switch>
  );
};
export default MetrikaScene;
