import { createReducer } from "#/store/helpers";
import { UIState } from "#/store/types";
import { returnStorageItemOrTrue, setLocalStorageBooleans } from "#/util";
import { Action } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const initialState: UIState = {
  isTextNotificationActive: returnStorageItemOrTrue("isTextNotificationActive"),
  isAudioNotificationActive: returnStorageItemOrTrue("isAudioNotificationActive"),
  isActionsListShown: returnStorageItemOrTrue("isActionsListShown"),
  isDirectionsListShown: returnStorageItemOrTrue("isDirectionsListShown"),
};

export const reducer = createReducer<UIState, Action>(
  {
    [MutationType.TOGGLE_TEXT_NOTIFICATION]: (
      state: UIState,
      action: ActionTypes.ToggleTextNotification,
    ) => {
      return {
        ...state,
        isTextNotificationActive: !state.isTextNotificationActive,
      };
    },

    [MutationType.TOGGLE_AUDIO_NOTIFICATION]: (
      state: UIState,
      action: ActionTypes.ToggleAudioNotification,
    ) => {
      return {
        ...state,
        isAudioNotificationActive: !state.isAudioNotificationActive,
      };
    },

    [MutationType.TOGGLE_ACTIONS_LIST_SHOWN]: (
      state: UIState,
      action: ActionTypes.ToggleActionsListShown,
    ) => {
      return {
        ...state,
        isActionsListShown: !state.isActionsListShown,
      };
    },

    [MutationType.TOGGLE_DIR_LIST_SHOWN]: (
      state: UIState,
      action: ActionTypes.ToggleDirectionsListShown,
    ) => {
      return {
        ...state,
        isDirectionsListShown: !state.isDirectionsListShown,
      };
    },
  },
  initialState,
);

export const localStorageUpdate = (newState: UIState) => {
  setLocalStorageBooleans({
    isAudioNotificationActive: newState.isAudioNotificationActive,
    isTextNotificationActive: newState.isTextNotificationActive,
    isActionsListShown: newState.isActionsListShown,
    isDirectionsListShown: newState.isDirectionsListShown,
  });
};

export default reducer;
