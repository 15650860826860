import Select, { SelectCustomProps, SelectOption, VALUE_NOT_SET } from "#/components/Select";
import usersModule from "#/store/modules/users";
import { PrimaryKey, StoreRootState, User, UsersByIdBare } from "#/store/types";
import { nullOr, undefinedOr, userFullName } from "#/util";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { useReactSelect } from "#/util/reactSelect";

interface UserSelectConnectedProps {}

export type UserDisplayFunction = (user: User | null) => string;

export type UserSelectOwnProps = Omit<SelectCustomProps, "value" | "onChange" | "type"> & {
  value: PrimaryKey | null;
  onChange: (newValue: PrimaryKey | null) => void;
  users: User[];
  userDisplayFunction?: UserDisplayFunction;
  defaultOption?: string;
  usersById: UsersByIdBare;
};

type UserSelectProps = UserSelectConnectedProps & UserSelectOwnProps;

const UserSelect = (props: UserSelectProps) => {
  const defaultUserDisplayFunction: UserDisplayFunction = user => userFullName(user);
  const df: UserDisplayFunction = props.userDisplayFunction
    ? props.userDisplayFunction
    : defaultUserDisplayFunction;
  const selectState = useReactSelect(props.users, props.usersById, df, props.value, {}, [
    props.users,
    props.usersById,
    df,
    props.value,
  ]);
  return (
    <Select
      {..._.omit(props, ["defaultOption"])}
      options={selectState.options}
      value={selectState.selected}
      onChange={(o: any) => {
        props.onChange(o.value !== VALUE_NOT_SET ? _.toNumber(o.value) : null);
      }}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default connect((store: StoreRootState) => ({
  usersById: usersModule.selectors.getUsersByIdBare(store, null),
}))(UserSelect);
