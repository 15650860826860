import eventSystemModule from "#/store/modules/eventSystem";
import { dispatchEvent } from "#/store/modules/eventSystem/actions";
import keybindingSystemModule from "#/store/modules/keybindingSystem";
import { DispatchProp, KeybindingSystemState, StoreRootState } from "#/store/types";
import assert from "assert";
import hotkeys from "hotkeys-js";
import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";

interface KeybindingOverlayConnectedProps {
  keybindings: KeybindingSystemState["keybindings"];
  dispatchEvent: DispatchProp<typeof dispatchEvent>;
}

interface KeybindingOverlayOwnProps {
  children: React.ReactNode;
}

type KeybindingOverlayProps = KeybindingOverlayConnectedProps & KeybindingOverlayOwnProps;

const KeybindingOverlay = (props: KeybindingOverlayProps) => {
  useEffect(() => {
    _.keys(props.keybindings).map(key => {
      hotkeys(key, () => {
        const keyBindingHandlingEvent = _.get(props.keybindings, key, null);
        assert(keyBindingHandlingEvent !== null);
        props.dispatchEvent(keyBindingHandlingEvent!);
      });
    });
    return () => {
      _.keys(props.keybindings).map(hotkeys.unbind);
    };
  }, []);

  const { children } = props;

  return children;
};

const mapStateToProps = (store: StoreRootState) => ({
  keybindings: keybindingSystemModule.selectors.getKeybindings(store),
});

export default connect(
  mapStateToProps,
  { dispatchEvent: eventSystemModule.actions.dispatchEvent },
)(KeybindingOverlay as any);
