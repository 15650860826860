import Select, { SelectCustomProps, SelectOption } from "#/components/Select";
import { PrimaryKey, Region } from "#/store/types";
import { useDebounced } from "#/util/hooks";
import { useReactSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";
import { useRegions } from "./useRegions";
import { defaultDF, RegionDisplayFunction } from "./util";

type ControllableRegionSelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: PrimaryKey | null;
  onChange: (p: Region | null) => void;
};

const ControllableRegionSelect = (props: ControllableRegionSelectProps) => {
  const [query, setQuery] = React.useState<string>("");
  const debouncedQuery = useDebounced(query, 600);
  const regions = useRegions({ includeById: true, query: debouncedQuery }, [debouncedQuery]);
  const df: RegionDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;
  const selectState = useReactSelect(regions.data, regions.byId, df, props.value, {}, [
    regions,
    df,
    props.value,
  ]);
  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      isSearchable={true}
      options={selectState.options}
      value={selectState.selected}
      noOptionsMessage={() => "Пусто"}
      placeholder="Начните вводить регион"
      onInputChange={(e, t) => {
        const a = t.action;
        if (a === "input-change") {
          setQuery(e);
        }
      }}
      onChange={(option: SelectOption) => {
        props.onChange(option.value ? _.get(regions.byId, _.toNumber(option.value)) : null);
      }}
    />
  );
};

export default ControllableRegionSelect;
