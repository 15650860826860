import { useState, useCallback, useMemo } from "react";
import _ from "lodash";
import { PrimaryKey } from "#/store/types";
import { NON_REACHABLE_EMPTY_FUNCTION } from ".";

type SelectionRegistryID = string | number;
type SelectionRegistryItems = Record<SelectionRegistryID, boolean>;

export interface SelectionRegistry {
  selectedItems: SelectionRegistryItems;
  toggleItemSelection: (pk: PrimaryKey) => void;
  isItemSelected: (pk: PrimaryKey) => boolean;
  reset: () => void;
  selectedItemsAmount: number;
  areAllItemsSelected: boolean;
  toggleAll: () => void;
}

export const EMPTY_SELECTION_REGISTRY: SelectionRegistry = {
  areAllItemsSelected: true,
  isItemSelected: NON_REACHABLE_EMPTY_FUNCTION,
  reset: NON_REACHABLE_EMPTY_FUNCTION,
  selectedItems: {},
  selectedItemsAmount: 0,
  toggleAll: NON_REACHABLE_EMPTY_FUNCTION,
  toggleItemSelection: NON_REACHABLE_EMPTY_FUNCTION,
};

export const useSelectionRegistry = (
  initialValue: SelectionRegistryItems = {},
  items: PrimaryKey[],
  deps: any[],
): SelectionRegistry => {
  const [selectedItems, setSelectedItems] = useState<SelectionRegistryItems>(initialValue);
  const isItemSelected = useCallback(
    (pk: SelectionRegistryID) => {
      return _.get(selectedItems, pk, false);
    },
    [selectedItems, ...deps],
  );
  const toggleItemSelection = useCallback(
    (pk: SelectionRegistryID) => {
      setSelectedItems({ ...selectedItems, [pk]: !isItemSelected(pk) });
    },
    [selectedItems, ...deps],
  );
  const reset = useCallback(() => setSelectedItems({}), [...deps]);
  const selectedItemsAmount = useMemo(() => _.values(selectedItems).filter(Boolean).length, [
    selectedItems,
    ...deps,
  ]);
  const totalItemsAmount = useMemo(() => items.length, [items, ...deps]);
  const areAllItemsSelected = useMemo(() => selectedItemsAmount === totalItemsAmount, [
    totalItemsAmount,
    selectedItems,
    ...deps,
  ]);
  const noItemsSelected = useMemo(() => selectedItemsAmount === 0, [selectedItemsAmount, ...deps]);
  const toggleAll = useCallback(() => {
    if (noItemsSelected) {
      const newRegistry = items.reduce((acc, k) => ({ ...acc, [k]: true }), {});
      setSelectedItems(newRegistry);
    } else {
      setSelectedItems({});
    }
  }, [noItemsSelected, items, ...deps]);
  return {
    selectedItems,
    toggleItemSelection,
    isItemSelected,
    reset,
    selectedItemsAmount,
    areAllItemsSelected,
    toggleAll,
  };
};
