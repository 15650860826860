import history from "#/history";
import { rootLevelReducer, updateLocalStorage } from "#/store/modules";
import { StoreRootState } from "#/store/types";
import { routerMiddleware } from "connected-react-router";
import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: any;
  }
}

const initialState: StoreRootState = {} as any;

const store = (() => {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware, routerMiddleware(history)));
  return createStore(rootLevelReducer as any, initialState as any, enhancer);
})();

// Local storage integration
store.subscribe(() => {
  const state: StoreRootState = store.getState() as StoreRootState;
  updateLocalStorage(state);
});

export default store;
