import { apiGet, apiPut } from "#/api";
import ControllableProxySelect from "#/components/ProxySelect/ControllableProxySelect";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Proxy } from "#/store/types";
import _ from "lodash";
import React, { FormEvent, useEffect } from "react";
import { Link } from "react-router-dom";
import { FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface ScriptSettingsModalProps {
  toggle: any;
  isOpen: boolean;
  fetching?: boolean;
}

const ScriptSettingsModal = (props: ScriptSettingsModalProps) => {
  const [updating, setUpdating] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);
  const [proxy, setProxy] = React.useState<PrimaryKey | null>(null);

  useEffect(() => {
    setFetching(true);
    // Load current settings
    apiGet(API_ROUTES.SETTINGS.SETTINGS_LIST).then((json: { top50_proxy_model: Proxy }) => {
      const updatedProxy = _.get(json.top50_proxy_model, "pk", null);
      setProxy(updatedProxy);
      setFetching(false);
    });
  }, []);

  const handleFormSubmit = React.useCallback(
    (evt: FormEvent<Element>) => {
      evt.preventDefault();
      if (fetching) { return; }
      if (proxy) {
        setUpdating(true);
        apiPut(API_ROUTES.SETTINGS.SETTINGS_LIST, {
          top50_proxy: proxy,
        })
          .then(() => {
            setUpdating(false);
            props.toggle();
          })
          .catch(err => {
            setUpdating(false);
          });
      }
    },
    [proxy, fetching, props.toggle],
  );

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className={`modal-dialog-centered`}
      contentClassName="alert alert-primary"
      size="lg"
    >
      <ModalHeader toggle={props.toggle}>Настройки съемщика позиций</ModalHeader>
      <form onSubmit={handleFormSubmit} className={`${fetching ? "low-opacity" : ""}`}>
        <ModalBody>
          <FormGroup>
            <label className="my-2">Заменить прокси для сбора позиций</label>
            <ControllableProxySelect value={proxy} onChange={g => setProxy(g ? g.pk : null)} />
            <div className="small text-muted mt-2">
              Все необходимые прокси можно найти
              <Link to="/services/proxy/" target="_blank" className="ml-1">
                здесь
              </Link>
            </div>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-success" disabled={updating}>
            {updating ? "Сохранение..." : "Сохранить"}
          </button>
          <button type="button" className="btn btn-secondary" onClick={props.toggle}>
            Отмена
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ScriptSettingsModal;
