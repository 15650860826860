import { showErrorToast } from "#/components/toasts";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { canUserCreateMonitoringProjects, canUserPerformAnyOf, mdAny } from "#/permissions";
import loader from "#/scenes/Auth/assets/login-spinner.svg";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import sitemonitoringModule from "#/store/modules/sitemonitoring";
import {
  MonitoringProject,
  MonitoringProjectData,
  PrimaryKey,
  StoreRootState,
  User,
} from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import ListItem from "../ListItem";
import TopvisorProjectModal from "./components/TopvisorProjectModal";
import style from "./index.module.scss";

interface ListConnectedProps {
  fetchProjectsList: any;
  createMonitoringProject: (payload: Partial<MonitoringProjectData>) => Promise<unknown>;
  removeMonitoringProject: (pk: PrimaryKey) => Promise<unknown>;
  projects: MonitoringProject[];
  isCurrentlyCreatingProject: boolean;
  isCurrentlyDeletingProject: boolean;
  loadingProjects: boolean;
  currentUser: User | null;
}

interface ListOwnProps {}

type ListProps = ListConnectedProps & ListOwnProps;

const List = (props: ListProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  useEffect(() => {
    props.fetchProjectsList();
  }, []);
  const toggleModal = () => setModalOpen(!isModalOpen);

  const onNewProjectCreate = (payload: Partial<MonitoringProjectData>) =>
    props
      .createMonitoringProject(payload)
      .then(() => {
        setModalOpen(false);
        return null;
      })
      .catch(err => {
        showErrorToast(err);
        throw err;
      });

  return (
    <div>
      <section className="content-header">
        <h1>
          Мониторинг \ <small>Проекты</small>
        </h1>
      </section>

      <TransitionGroup>
        <CSSTransition
          classNames="mask"
          appear={true}
          timeout={{
            enter: 0,
            exit: 300,
          }}
        >
          <section className="content">
            <div className="row">
              <div className="col-12">
                <div className="box">
                  <div className="box-header with-border flex-vertically-centered-x justify-content-between">
                    <h4 className="box-title">Список проектов</h4>
                    <div>
                      {canUserCreateMonitoringProjects(props.currentUser) && (
                        <button className="btn btn-list row-action" onClick={toggleModal}>
                          <i className="fas fa-plus-circle" />
                          <span className="ml-1">Добавить проект</span>
                        </button>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters py-2">
                    <div className="box-body">
                      {props.loadingProjects ? (
                        <div className="flex-vertically-centered py-4 text-center">
                          <img src={loader} className="m-auto" alt="Loading Spinner" />
                        </div>
                      ) : props.projects.length > 0 ? (
                        <table className="table">
                          <thead>
                            <tr>
                              <th>Проект</th>
                              <th>Дата последней проверки</th>
                              <th>Количество проверенных в прошлый раз URL-ов</th>
                              <th>Title</th>
                              <th>H1</th>
                              <th>Description</th>
                              <th>Content</th>
                              <th>Code</th>
                              {canUserPerformAnyOf(props.currentUser, [
                                [
                                  "DELETE",
                                  "PROJECTM",
                                  {
                                    many: true,
                                    mdfn: mdAny,
                                    objs: props.projects,
                                  },
                                ],
                              ]) && <th className="text-right">Действия</th>}
                            </tr>
                          </thead>
                          <tbody>
                            {props.projects.map((el: MonitoringProject, i: number) => (
                              <ListItem
                                key={i}
                                item={el}
                                onRemove={props.removeMonitoringProject}
                                currentUser={props.currentUser}
                              />
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <div className={style["no-projects-message"]}>Проектов пока нет.</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </CSSTransition>
      </TransitionGroup>

      {/* Project creation modal */}
      {canUserCreateMonitoringProjects(props.currentUser) && (
        <TopvisorProjectModal
          onCreate={onNewProjectCreate}
          isModalOpen={isModalOpen}
          isCurrentlyCreatingProject={props.isCurrentlyCreatingProject}
          toggleModal={toggleModal}
        />
      )}
    </div>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  projects: sitemonitoringModule.selectors.getMonitoringProjects(store, null),
  creatingProject: sitemonitoringModule.selectors.isCurrentlyCreatingProject(store, null),
  loadingProjects: sitemonitoringModule.selectors.isMonitoringProjectsLoading(store, null),
  isCurrentlyCreatingProject: sitemonitoringModule.selectors.isCurrentlyCreatingProject(
    store,
    null,
  ),
  isCurrentlyDeletingProject: sitemonitoringModule.selectors.isCurrentlyDeletingProject(
    store,
    null,
  ),
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
});

export default connect(
  mapStateToProps,
  getActionsFromModule(sitemonitoringModule, [
    "fetchProjectsList",
    "createMonitoringProject",
    "removeMonitoringProject",
  ]),
)(withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(List as any));
