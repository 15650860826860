import { PrimaryKey } from "#/store/types";
import { Dispatch } from "redux";
import MutationType from "./mutationType";

const setCurrentTask = (id: PrimaryKey) => (dispatch: Dispatch, getState: any) => {
  return new Promise((resolve, reject) => {
    dispatch({
      type: MutationType.SET_CURRENT_TASK,
      payload: { id },
    });
    resolve();
  });
};

export default {
  setCurrentTask,
};
