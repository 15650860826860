import React from "react";

const SidebarToggleButton = (props: { onClick: () => void }) => {
  return (
    <button className="btn sidebar-toggle" data-toggle="push-menu" onClick={props.onClick}>
      <i className="fas fa-bars" />
    </button>
  );
};

export default SidebarToggleButton;
