import { showErrorToast } from "#/components/toasts";
import { PrimaryKey } from "#/store/types";
import _ from "lodash";
import React, { useCallback, useContext, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import ProjectContext, { IProjectContext } from "../../../ProjectContext";
import AddEventModal from "./AddEventModal";
import TableCheckbox from "#/components/TableCheckbox";
import ProjectInfoContext, { IProjectInfoContext } from "../ProjectInfoContext";

const EventTable = () => {
  const projectContext = useContext(ProjectContext) as IProjectContext;
  const projectInfoContext = useContext(ProjectInfoContext) as IProjectInfoContext;
  const { groupSelection, projectGroups: projectEvents } = projectContext;

  const removeAllSelectedGroups = useCallback(() => {
    if (groupSelection.selectedItemsAmount === 0) {
      return;
    }
    try {
      const res = projectContext.removeGroups(
        (_.keys(groupSelection.selectedItems) as any[]) as PrimaryKey[],
      );
      groupSelection.reset();
    } catch (err) {
      showErrorToast("Произошла ошибка при удалении групп запросов");
    }
  }, [groupSelection, projectContext.removeGroups]);

  // Add modal
  const [addEventModalOpen, setAddEventModalOpen] = useState<boolean>(false);
  const toggleAddEventModal = () => setAddEventModalOpen(!addEventModalOpen);

  // Table items
  const renderedEvents = useMemo(() => {
    return projectEvents.map((event, idx) => (
      <tr key={idx}>
        {/* Is selected */}
        <td className="minimum-column">
          <TableCheckbox
            onChange={() => groupSelection.toggleItemSelection(event.pk)}
            checked={groupSelection.isItemSelected(event.pk)}
          />
        </td>

        {/* Event name */}
        <td>
          <Link to={`/services/top50/queries/${event.name}`} target="_blank">
            {event.name}
          </Link>
        </td>

        {/* Show on graph button */}
        <td className="text-right">
          <button
            className="btn btn-list row-action"
            disabled={!projectContext.isGraphAvailable}
            onClick={() => {
              projectInfoContext.fetchEventGraph(event.pk);
            }}
            title="Показать на графике"
          >
            <i className="fas fa-chart-bar" />
            <span className="ml-2">Показать на графике</span>
          </button>
        </td>
      </tr>
    ));
  }, [projectEvents, groupSelection, projectContext.isGraphAvailable]);

  return (
    <div className="row">
      <div className="col-12">
        <div className="box">
          <div className="box-header with-border flex-vertically-centered-x justify-content-between">
            {/* Title */}
            <h5 className="box-subtitle">
              <i className="fas fa-layer-group" />
              <span className="ml-2">Группы запросов</span>
            </h5>

            {/* Event actions */}
            <div>
              <button className="btn btn-list row-action" onClick={toggleAddEventModal}>
                <i className="fas fa-plus" />
                <span className="ml-2">Добавить группу запросов</span>
              </button>
              <button
                className="btn btn-list row-action"
                onClick={removeAllSelectedGroups}
                disabled={groupSelection.selectedItemsAmount === 0}
              >
                <i className="fas fa-minus" />
                <span className="ml-2">
                  Открепить выбранные группы запросов
                  <span className="ml-1">
                    {groupSelection.selectedItemsAmount > 0 &&
                      `(${groupSelection.selectedItemsAmount})`}
                  </span>
                </span>
              </button>
            </div>
          </div>

          {/* Table */}
          <div className="box-body">
            {projectEvents.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                Нет прикрепленных групп запросов
              </div>
            ) : (
              <table className="table">
                <thead>
                  <tr>
                    <th className="minimum-column">
                      <TableCheckbox
                        onChange={groupSelection.toggleAll}
                        checked={groupSelection.areAllItemsSelected}
                      />
                    </th>
                    <th>Название</th>
                    <th />
                  </tr>
                </thead>
                <tbody>{renderedEvents}</tbody>
              </table>
            )}
          </div>
        </div>
      </div>

      <AddEventModal isOpen={addEventModalOpen} toggle={toggleAddEventModal} />
    </div>
  );
};

export default EventTable;
