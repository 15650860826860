import Select from "#/components/Select";
import usersModule from "#/store/modules/users";
import { StoreRootState, UserRole } from "#/store/types";
import { useReactSelect } from "#/util/reactSelect";
import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { SelectCustomProps } from "../Select";

export type UserRoleSelectOwnProps = SelectCustomProps & {
  value: string | null;
  onChange: (e: string) => void;
};

interface UserRoleSelectConnectedProps {
  roles: UserRole[];
}

type UserRoleSelectProps = UserRoleSelectConnectedProps & UserRoleSelectOwnProps;

const UserRoleSelect = (props: UserRoleSelectProps) => {
  const selectState = useReactSelect(
    props.roles,
    _.keyBy(props.roles, "pk"),
    r => (r ? r.name : "Выберите"),
    props.value ? _.toNumber(props.value) : null,
    {},
    [props.roles, props.value],
  );
  return (
    <Select
      isSearchable={false}
      options={selectState.options}
      value={selectState.selected}
      onChange={o => props.onChange(o.value)}
    />
  );
};

export default connect(
  (store: StoreRootState, ownProps: UserRoleSelectOwnProps): UserRoleSelectProps => ({
    roles: usersModule.selectors.getAllUserRoles(store, null),
    ...ownProps,
  }),
  {},
)(UserRoleSelect);
