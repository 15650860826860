import cn from "classnames";
import React from "react";
import style from "./index.module.scss";
import _ from 'lodash';

export enum NotificationMenuTab {
  ALL = 0,
  CHAT = 1,
  TASKS = 2,
}

const ItemsMenuTabs = (props: {
  currentTab: NotificationMenuTab;
  changeTab: (t: NotificationMenuTab) => void;
}) => {
  const constructStyleForTab = (id: NotificationMenuTab) => {
    return cn({
      [style.tab]: true,
      [style.tab_active]: id === props.currentTab,
    });
  };

  return (
    <div className={style["tabs-container"]}>
      <div
        className={constructStyleForTab(NotificationMenuTab.ALL)}
        onClick={_.partial(props.changeTab, NotificationMenuTab.ALL)}
      >
        Все
      </div>
      <div
        className={constructStyleForTab(NotificationMenuTab.CHAT)}
        onClick={_.partial(props.changeTab, NotificationMenuTab.CHAT)}
      >
        Чаты
      </div>
      <div
        className={constructStyleForTab(NotificationMenuTab.TASKS)}
        onClick={_.partial(props.changeTab, NotificationMenuTab.TASKS)}
      >
        Задачи
      </div>
    </div>
  );
};

export default ItemsMenuTabs;
