import projectModule from "#/store/modules/project";
import projectsModule from "#/store/modules/projects";
import { CRMProject, CRMProjectData, PrimaryKey, ProjectsById } from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";

const getCurrentProjectData = createSelector(
  [projectsModule.selectors.getProjectsById, projectModule.selectors.getCurrentProjectId],
  (projectsById: ProjectsById, projectId: PrimaryKey | null): CRMProjectData | null =>
    projectId !== null ? _.get(projectsById, projectId, null) : null,
);

const getCurrentProject = createSelector(
  [getCurrentProjectData],
  (pd: CRMProjectData | null) => (pd ? _.get(pd, "data", null) : null),
);

export default { getCurrentProjectData, getCurrentProject };
