import * as papaparse from "papaparse";

export const parse_csv = (file: File | string): Promise<papaparse.ParseResult> =>
  new Promise(resolve => {
    papaparse.parse(file, {
      header: true,
      dynamicTyping: true, // Для чисел!
      encoding: "windows-1251",
      complete: resolve,
    });
  });
