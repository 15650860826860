import { StoreRootState, UIState } from "#/store/types";
import { createSelector } from "reselect";

type Props = {} | null;

const getUserInterface = (store: StoreRootState, props: Props) => store.ui;

export const isActionsListShown = createSelector(
  [getUserInterface],
  (ui: UIState) => ui.isActionsListShown,
);

export const isDirListShown = createSelector(
  [getUserInterface],
  (ui: UIState) => ui.isDirectionsListShown,
);

export const areTextNotificationsEnabled = createSelector(
  [getUserInterface],
  (ui: UIState) => ui.isTextNotificationActive,
);

export const areAudioNotificationsEnabled = createSelector(
  [getUserInterface],
  (ui: UIState) => ui.isAudioNotificationActive,
);
