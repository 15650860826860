import React from "react";
import { Link } from "react-router-dom";

const Header = () => {
  return (
    <section className="content-header">
      <h1>
        Сервисы \ <small>TOP50</small>
      </h1>
      <ol className="breadcrumb breadcrumb-big">
        <li className="breadcrumb-item">TOP 50</li>
        <li className="breadcrumb-item">
          <Link to="/services/top50/">Список проектов</Link>
        </li>
        <li className="breadcrumb-item">
          <Link to="/services/top50/queries">Список запросов</Link>
        </li>
      </ol>
    </section>
  );
};

export default Header;
