import { getMonitoringProjectId, isCurrentUserSubscribedToMonitoringProject } from "#/util";
import cn from "classnames";
import React, { useContext } from "react";
import style from "../index.module.scss";
import ProjectContext from "../ProjectContext";

const ProjectSubscriptionButton = () => {
  const projectContext = useContext(ProjectContext);
  return (
    <div className={style["project-subscribe-action-group"]}>
      {(() => {
        const isSubscribed = isCurrentUserSubscribedToMonitoringProject(projectContext.project);
        const id = getMonitoringProjectId(projectContext.project);
        return (
          <button
            className={cn({
              btn: true,
              "btn-list": true,
              [style["project-sub-button"]]: true,
              [style["project-sub-button_subscribed"]]: isSubscribed,
            })}
            onClick={() => {
              if (isSubscribed) {
                projectContext.unsubscribe();
              } else {
                projectContext.subscribe();
              }
            }}
            title={
              isSubscribed
                ? "Отписаться от оповещений о проекте"
                : "Подписаться на изменения в проекте"
            }
          >
            <div className={style["project-sub-button__unsub-text"]}>
              <i className="fas fa-times" />
              <span className={cn(["ml-2"])}>Отписаться</span>
            </div>
            <div className={style["project-sub-button__subbed-text"]}>
              <i className="fas fa-check" />
              <span className={cn(["ml-2"])}>Вы подписаны</span>
            </div>
            <div className={style["project-sub-button__sub-text"]}>
              <span className={cn([])}>Подписаться</span>
            </div>
          </button>
        );
      })()}
    </div>
  );
};

export default ProjectSubscriptionButton;
