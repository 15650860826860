import React from "react";
import { Link } from "react-router-dom";

interface HeaderOwnProps {
  headerName?: string;
  links?: Array<[string, string]>;
}

const Header = (props: HeaderOwnProps) => {
  // const { headerName } = props;
  // const headerToRender = headerName ? headerName : "ТОП-50";
  return (
    <section className="content-header">
      <h1>
        Metrika \ <small>{props.headerName || ""}</small>
      </h1>
      <ol className="breadcrumb breadcrumb-big">
        <li className="breadcrumb-item">
          <Link to="/services/metrika/">Metrika</Link>
        </li>
        {(props.links || [["Список сервисов", "/services/"]]).map(([name, link]) => (
          <li className="breadcrumb-item">
            <Link to={link}>{name}</Link>
          </li>
        ))}
      </ol>
    </section>
  );
};

export default Header;
