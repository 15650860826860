import classnames from "classnames";
import React from "react";
import style from "./index.module.scss";
import IconBoxList from "#/assets/box-list.svg";
import "./index.scss";

export interface BoxProps {
  boxContentClassName?: string;
  iconClass?: string;
  active: boolean;
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
  title: string;
  count: number;
  barbg: string;
  "grid-class": string;
}

const Box = (props: BoxProps) => {
  const { iconClass, boxContentClassName } = props;
  const boxClassName = [
    "selected-task-type",
    ...[props["grid-class"]],
    ...(props.active ? ["box-active"] : ["box-inactive"]),
  ].join(" ");

  return (
    <div className={boxClassName} onClick={props.onClick}>
      <div className={classnames(["cursor-pointer", boxContentClassName])}>
        <div className={classnames([style["box-body"], "border-light"])}>
          <div className={style["box-wrap"]}>
            <div className={classnames([style["box-wrap__icon"], props.barbg])}>
              <span>
                <i className={iconClass ? iconClass : ""} />
              </span>
            </div>
            <div className={style["box-wrap__text"]}>
              <div className={classnames([style["box-wrap__text__count"]])}>{props.count}</div>
              <div className={classnames([style["box-wrap__text__container"]])}>
                <span>{props.title}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Box;
