import cn from "classnames";
import _ from "lodash";
import React, { useCallback } from "react";
import { Input, InputProps } from "reactstrap";
import style from "./index.module.scss";

interface CheckboxConnectedProps {}

type CheckboxOwnProps = Omit<InputProps, "onChange" | "size"> & {
  checked: boolean;
  onChange: (newStatus: boolean) => void;
  size?: "small" | "medium" | "large";
};

type CheckboxProps = CheckboxConnectedProps & CheckboxOwnProps;

const Checkbox = (props: CheckboxProps) => {
  const size = props.size ? props.size : "medium";
  const onChange = useCallback(() => props.onChange(!props.checked), [
    props.onChange,
    props.checked,
  ]);
  return (
    <span className={cn([style.checkbox, style[`checkbox_${size}`], props.className])}>
      <label className={cn([style["checkbox-label"]])}>
        <Input
          {..._.omit(props, ["onChange", "checked", "size", "type"])}
          type="checkbox"
          checked={props.checked}
          onChange={onChange}
        />
        &nbsp;
        <span className="d-block" />
      </label>
    </span>
  );
};

export default Checkbox;
