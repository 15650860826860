import DebouncedInput from "#/components/DebouncedInput";
import { PrimaryKey, Top50Event } from "#/store/types";
import { declOfNum, sortTable, stopPropagationHandler } from "#/util";
import cn from "classnames";
import Fuse from "fuse.js";
import Switch from "rc-switch";
import React, { useMemo } from "react";
import QueryListContext, { IQueryListContext } from "../QueryListContext";
import style from "../index.module.scss";
import { GroupFilter } from "../types";

// Let the user focus on active event groups.
// Instead of sorting by "active" property, we can just
// get active groups at first, and then concat with inactive ones
const orderGroups = (groupsToOrder: Top50Event[]) => {
  const activeGroupsSortedByName = sortTable(
    groupsToOrder.filter(group => group.active),
    "name",
    false,
  );
  const inactiveGroupsSortedByName = sortTable(
    groupsToOrder.filter(group => !group.active),
    "name",
    false,
  );
  return [...activeGroupsSortedByName, ...inactiveGroupsSortedByName];
};

const ControlSidebar = () => {
  const {
    groups,
    activeGroup,
    setActiveGroupId,
    querySelection,
    fetching,
    toggleEvent,
  } = React.useContext(QueryListContext) as IQueryListContext;

  const onGroupItemClickHandler = React.useCallback((pk: PrimaryKey) => {
    setActiveGroupId(pk);
    querySelection.reset();
  }, []);

  const onGroupItemCheckboxToggle = (pk: number, isActive: boolean) => {
    toggleEvent(pk, isActive);
  };

  // Group filters
  const [groupFilter, setGroupFilter] = React.useState<GroupFilter>({ name: "" });

  // Groups
  const onGroupFilterChange = (v: string) => {
    setGroupFilter({ name: v });
  };
  const filteredGroups = useMemo(() => {
    if (groupFilter.name.trim().length === 0) {
      return groups;
    }
    const fuse = new Fuse(groups, {
      shouldSort: false,
      threshold: 0.6,
      location: 0,
      distance: 100,
      maxPatternLength: 32,
      minMatchCharLength: 1,
      keys: ["name"],
    });
    return fuse.search(groupFilter.name);
  }, [groups, groupFilter]);
  const sortedFilteredGroups: Top50Event[] = useMemo(() => orderGroups(filteredGroups), [
    filteredGroups,
  ]);
  const renderedGroups = useMemo(() => {
    return sortedFilteredGroups.map((group, index) => {
      return (
        <li
          className={cn({
            "list-group-item": true,
            [style["sidebar-group-item"]]: true,
            active: activeGroup && group.pk === activeGroup.pk,
          })}
          onClick={() => onGroupItemClickHandler(group.pk)}
          key={group.pk}
        >
          <div className="d-flex justify-content-between">
            <div>
              <span onClick={stopPropagationHandler}>
                <Switch
                  onChange={() => onGroupItemCheckboxToggle(group.pk, group.active)}
                  disabled={fetching}
                  checked={group.active}
                  className="m-0"
                />
              </span>
              <span className="ml-2">{group.name}</span>
            </div>
            <span className="badge badge-success flex-vertically-centered">
              {declOfNum(group.queries_amount!, ["запрос", "запроса", "запросов"])}
            </span>
          </div>
        </li>
      );
    });
  }, [sortedFilteredGroups, activeGroup, onGroupItemCheckboxToggle, onGroupItemClickHandler]);

  return (
    <div className="col-3">
      <DebouncedInput
        className="fz-14 form-control-small form-control w-100 mb-2"
        placeholder="Фильтр"
        onChange={onGroupFilterChange}
        ms={500}
      />
      <ul className={cn(["list-group", style["queries-group-list"]])}>{renderedGroups}</ul>
    </div>
  );
};

export default ControlSidebar;
