import { MainWSState, StoreRootState } from "#/store/types";
import { createSelector } from "reselect";

type Props = {} | null;

const getMainWebSocketState = (store: StoreRootState, props: Props) => store.mainWS;
const getMainWebSocketProps = (state: StoreRootState, props: Props) => props;

export const getMainWebSocketInstance = createSelector(
  [getMainWebSocketState],
  (mws: MainWSState) => mws.ws,
);

export const isMainWebSocketClosed = createSelector(
  [getMainWebSocketState],
  (mws: MainWSState) => mws.ws === null,
);

export default {
  getMainWebSocketInstance,
  isMainWebSocketClosed,
};
