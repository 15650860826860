import React from "react";
import { SelectCustomProps, VALUE_NOT_SET, SelectOption } from "../Select";
import { PrimaryKey, Proxy } from "#/store/types";
import { useProxies } from "#/util/hooks";
import { nullOr, undefinedOr } from "#/util";
import _ from "lodash";
import Select from "#/components/Select";

type ControllableProxySelectProps = Omit<SelectCustomProps, "onChange" | "value"> & {
  value: PrimaryKey | null;
  onChange: (p: Proxy | null) => void;
};

type ProxyDisplayFunction = (proxy: Proxy | null) => string;

const proxyToString = (proxy: Proxy | null) =>
  proxy ? `${proxy.login}@${proxy.ip}:${proxy.port}` : "Выберите";

const defaultDF: ProxyDisplayFunction = proxyToString;

const ControllableProxySelect = (props: ControllableProxySelectProps) => {
  const proxies = useProxies({ includeById: true, minimal: true }, []);
  const value: string = nullOr((props.value as any) as string, VALUE_NOT_SET);
  const df: ProxyDisplayFunction = props.displayFunction ? props.displayFunction : defaultDF;

  const defaultOption: SelectOption = React.useMemo(
    () => ({
      value: VALUE_NOT_SET,
      label: undefinedOr(props.defaultOption, "Выберите"),
    }),
    [props.defaultOption],
  );

  const options: SelectOption[] = React.useMemo(
    () =>
      proxies.proxies.map((el: Proxy, i: number) => {
        return { value: _.toString(el.pk), label: df(el) };
      }),
    [proxies, df],
  );

  const selectedOption: SelectOption = React.useMemo(
    () =>
      value === VALUE_NOT_SET
        ? defaultOption
        : (() => {
            const selected = _.get(proxies.byId, _.toNumber(value), null);
            return {
              value: _.toString(value),
              label: df(selected),
            };
          })(),
    [defaultOption, df, proxies.byId, value],
  );

  return (
    <Select
      {..._.omit(props, ["onChange", "value"])}
      defaultValue={defaultOption}
      options={options}
      value={selectedOption}
      onChange={(o: any) => {
        const pid = o.value !== VALUE_NOT_SET ? _.toNumber(o.value) : null;
        const p = pid ? _.get(proxies.byId, pid, null) : null;
        props.onChange(p);
      }}
      isDisabled={props.disabled}
      getOptionValue={(option: any) => option.value}
    />
  );
};

export default ControllableProxySelect;
