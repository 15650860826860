import Navbar from "#/components/Navbar";
import Sidebar from "#/components/Sidebar";
import { showErrorToast } from "#/components/toasts";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import { getActionsFromModule } from "#/store/helpers";
import eventSystemModule from "#/store/modules/eventSystem";
import { subscribeToEvent, unsubscribeFromEvent } from "#/store/modules/eventSystem/actions";
import mwsModule from "#/store/modules/mainWS";
import { closeMainWebSocket, setupMainWebSocket } from "#/store/modules/mainWS/actions";
import projectsModule from "#/store/modules/projects";
import { fetchProjectsList } from "#/store/modules/projects/actions";
import * as sessionModule from "#/store/modules/session";
import sessionUsersModule from "#/store/modules/sessionUsers";
import usersModule from "#/store/modules/users";
import { fetchProfilesList, fetchRoles } from "#/store/modules/users/actions";
import { DispatchProp, StoreRootState, User } from "#/store/types";
import { constructIndexPagePath, returnStorageItemOrTrue } from "#/util";
import classnames from "classnames";
import { push } from "connected-react-router";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import AuthContext from "./AuthContext";
import CRM from "./components/CRM";
import ServicesScene from "./components/Services";

interface MainSceneWrapperCProps {
  fetchProfilesList: DispatchProp<typeof fetchProfilesList>;
  fetchProjectsList: DispatchProp<typeof fetchProjectsList>;
  setupMainWebSocket: DispatchProp<typeof setupMainWebSocket>;
  closeMainWebSocket: DispatchProp<typeof closeMainWebSocket>;
  unsubscribeFromEvent: DispatchProp<typeof unsubscribeFromEvent>;
  subscribeToEvent: DispatchProp<typeof subscribeToEvent>;
  fetchRoles: DispatchProp<typeof fetchRoles>;
  authenticated: boolean;
  push: typeof push;
  currentUser: User | null;
}

interface MainSceneWrapperOProps {}

type MainSceneWrapperProps = MainSceneWrapperCProps & MainSceneWrapperOProps;

const MainSceneWrapper = (props: MainSceneWrapperProps) => {
  const [sidebarActive, setSitebarActive] = useState(returnStorageItemOrTrue("sidebarStatus"));
  const toggleSidebarStatus = () => {
    const newStatus = !sidebarActive;
    setSitebarActive(newStatus);
    localStorage.setItem("sidebarStatus", JSON.stringify(newStatus));
  };

  const openDashboard = () => {
    props.push(constructIndexPagePath());
  };

  useEffect(() => {
    props.subscribeToEvent("TOGGLE_SIDEBAR", toggleSidebarStatus);
    props.subscribeToEvent("OPEN_DASHBOARD", openDashboard);
    return () => {
      props.unsubscribeFromEvent("TOGGLE_SIDEBAR", toggleSidebarStatus);
      props.unsubscribeFromEvent("OPEN_DASHBOARD", openDashboard);
    };
  }, [sidebarActive]);

  // On initial render
  useEffect(() => {
    props.fetchProfilesList(true);
    props.fetchProjectsList(true);
    props.fetchRoles();
    props.setupMainWebSocket().catch(() => {
      showErrorToast("Failed to establish WebSocket connection");
    });
    return () => {
      props.closeMainWebSocket().catch(() => {
        showErrorToast("Failed to close main websocket connection");
      });
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser: props.currentUser,
        isLoggedIn: props.currentUser !== null,
      }}
    >
      <div className="skin-blue">
        <div className={classnames(["wrapper"])}>
          <div className="bg-sidebar">
            <Navbar handleClick={toggleSidebarStatus} isSidebarActive={sidebarActive} />
            <Sidebar isSidebarActive={sidebarActive} />
            <div className={classnames(["content-wrapper", sidebarActive ? "sidebar-active" : ""])}>
              <Switch>
                <Route path="/services" component={ServicesScene} />
                <Route path="*" component={CRM} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </AuthContext.Provider>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  authenticated: sessionModule.selectors.isAuthenticated(store, null),
  currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
});

export default connect(mapStateToProps, {
  push,
  fetchProjectsList: projectsModule.actions.fetchProjectsList,
  ...getActionsFromModule(eventSystemModule, ["subscribeToEvent", "unsubscribeFromEvent"]),
  ...getActionsFromModule(usersModule, ["fetchProfilesList", "fetchRoles"]),
  ...getActionsFromModule(mwsModule, ["setupMainWebSocket", "closeMainWebSocket"]),
})(withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(MainSceneWrapper as any));
