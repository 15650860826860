import * as sessionModule from "#/store/modules/session";
import { StoreRootState } from "#/store/types";
import { push } from "connected-react-router";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

// CLEANUP: Maybe this HOC should pass a currentUser
//          prop to the wrapped component for convenience?

interface OnlyNonAuthenticatedUsersProps {
  authenticated: boolean;
  push: typeof push;
}

type P<T> = T & OnlyNonAuthenticatedUsersProps;

export enum UserAuthenticationStatus {
  ANY = 0,
  AUTHENTICATED = 1,
  GUEST = 2,
}

export const WANTED_TO_ACCESS_LC = "WA:wantedToAccessURL";

const withAuthentication = function<T>(authenticated: UserAuthenticationStatus) {
  return function(Component: React.ComponentType<T>): React.ComponentType<T> {
    const WrappedComponent = (props: P<T>) => {
      if (authenticated === UserAuthenticationStatus.GUEST) {
        // User needs to be a guest
        if (!props.authenticated) {
          return <Component {...(props as T)} />;
        } else {
          return <Redirect to="/" />;
        }
      } else if (authenticated === UserAuthenticationStatus.AUTHENTICATED) {
        // User needs to be authenticated
        if (props.authenticated) {
          return <Component {...(props as T)} />;
        } else {
          // Save page user wanted to access to the local storage to retreive it later
          window.localStorage.setItem(WANTED_TO_ACCESS_LC, window.location.pathname);
          return <Redirect to="/auth/login" />;
        }
      } else {
        // User does not need to have any specific authentication status
        return <Redirect to="/" />;
      }
    };

    const mapStateToProps = (store: StoreRootState) => ({
      authenticated: sessionModule.selectors.isAuthenticated(store, null),
    });

    const mapDispatchToProps = { push };

    return (connect as any)(mapStateToProps, mapDispatchToProps)(WrappedComponent);
  };
};

export default withAuthentication;
