import { StoreRootState } from "#/store/types";
import { Keybindings, KeybindingSystemState } from "#/store/types";
import { createSelector } from "reselect";

const getKBSystem = (state: StoreRootState) => state.keybindingSystem;

const getKeybindings = createSelector(
  getKBSystem,
  (kbs: KeybindingSystemState): Keybindings => kbs.keybindings,
);

export default {
  getKBSystem,
  getKeybindings,
};
