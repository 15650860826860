import React from "react";
import { UncontrolledPopover, PopoverHeader, PopoverBody } from "reactstrap";
import { Link } from "react-router-dom";
import { Switch } from "react-router";

// TODO: Use react hooks in this component

class ProjectItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false,
      project: props.p,
    };
  }

  togglePopover = () => {
    this.setState(state => ({ popoverOpen: !state.popoverOpen }));
  };

  changeProjectStatus = () => {
    const { project } = this.state;

    const payload = {
      active: !project.active,
    };

    this.props.editProject(project.pk, payload, () => {
      this.setState({ project: { ...project, active: !project.active } });
    });
  };

  render() {
    const { removeProject } = this.props;
    const { project } = this.state;

    return (
      <React.Fragment>
        <tr className="topf-project-list-item">
          <td className="minimum-column">
            <img src={"//favicon.yandex.net/favicon/" + project.name} alt="project fav" />
          </td>
          <td>
            <Link to={"/services/contentplan/projects/" + project.pk}>{project.name}</Link>
          </td>
          <td>
            {project.description.substring(0, 100) +
              (project.description.length > 100 ? "..." : "")}
          </td>
          <td className="text-right">
            <Switch onChange={this.changeProjectStatus} checked={project.active} />
          </td>
          <td className="topf-project-list-item-hidden">
            <button
              type="button"
              className="btn btn-list row-action"
              id={"popover-topf-project-remove-" + project.pk}
              onClick={this.togglePopover}
            >
              Удалить
            </button>
          </td>
        </tr>
        <UncontrolledPopover
          placement="top"
          isOpen={this.state.popoverOpen}
          target={"popover-topf-project-remove-" + project.pk}
          trigger="click"
        >
          <PopoverHeader>Подтверждение</PopoverHeader>
          <PopoverBody>
            <div>Вы действительно хотите безвозвратно удалить этот проект?</div>
          </PopoverBody>
          <PopoverBody className="popover-border-top">
            <div className="text-right">
              <button
                type="button"
                className="btn btn-list row-action"
                onClick={this.togglePopover}
              >
                Отмена
              </button>
              <button
                type="button"
                className="btn btn-list row-action"
                onClick={() => {
                  removeProject(project.pk);
                }}
                disabled={this.state.fetching}
              >
                Удалить
              </button>
            </div>
          </PopoverBody>
        </UncontrolledPopover>
      </React.Fragment>
    );
  }
}

export default ProjectItem;
