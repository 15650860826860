import React from "react";

const FAIconText = (props: { type: "fas" | "fa" | "far"; name: string; text: string }) => (
  <>
    <i className={`${props.type} fa-${props.name}`} style={{ marginRight: 8 }} />
    {props.text}
  </>
);

export default FAIconText;
