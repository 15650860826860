import React from "react";
import { StoreRootState, PrimaryKey } from "#/store/types";
import { getCounters, getTokens, getSelection, selectToken } from "#/store/modules/metrika/goals";
import { Counter, Token } from "#/store/modules/metrika/goals/types";
import { connect } from "react-redux";
import CounterButtons from "./components/CounterButtons";

interface TokenSelectorOwnprops {}

interface TokenSelectorConnectedProps {
  counters: Record<PrimaryKey, Counter>;
  tokens: Record<PrimaryKey, Token>;
  selection: Record<PrimaryKey, PrimaryKey>;
  selectToken: any;
}

type TokenSelectorProps = TokenSelectorConnectedProps & TokenSelectorOwnprops;

const TokenSelector = (props: TokenSelectorProps) => {
  const memoizedCounters = React.useMemo(() => Object.values(props.counters), [props.counters]);

  const renderedButtons = React.useMemo(
    () => memoizedCounters.map(c => <CounterButtons counter={c} />),
    [memoizedCounters],
  );

  return <div className="list-group">{renderedButtons}</div>;
};

export default connect(
  (state: StoreRootState) => ({
    counters: getCounters(state),
    tokens: getTokens(state),
    selection: getSelection(state),
  }),
  {
    selectToken,
  },
)(TokenSelector);
