import Loader from "#/components/Loader";
import { showErrorToast } from "#/components/toasts";
import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import projectModule from "#/store/modules/project";
import projectProjectsModule from "#/store/modules/projectProjects";
import projectsModule from "#/store/modules/projects";
import tasksModule from "#/store/modules/tasks";
import { CRMProject, CRMProjectData, StoreRootState } from "#/store/types";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps } from "react-router-dom";
import ProjectWrapper from "./components/Wrapper";
import style from "./index.module.scss";
import PageWrapper, { PageContent } from "#/components/PageWrapper";

interface ProjectConnectedProps extends RouteComponentProps<{ id: string }> {
  fetchProject: any;
  project: CRMProject | null;
  loading: boolean;
  setCurrentProject: any;
  fetchProjectTasks: any;
}

interface ProjectOwnProps {}

type ProjectProps = ProjectConnectedProps & ProjectOwnProps;

const Project = (props: ProjectProps) => {
  useEffect(() => {
    props
      .fetchProject(props.match.params.id)
      .then(() => {
        props.fetchProjectTasks(props.match.params.id);
        props.setCurrentProject(props.match.params.id);
      })
      .catch(() => showErrorToast("Произошла ошибка при загрузке проекта"));
  }, []);

  return (
    <PageWrapper>
      <section className="content-header">
        <h1>
          Проект \ <small>{props.project ? props.project.name : "N/A"}</small>
        </h1>
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <Link to="/projects">Перейти ко всем проектам</Link>
          </li>
        </ol>
      </section>
      <PageContent>
        {props.loading ? (
          <div className={style["loader-container"]}>
            <Loader />
          </div>
        ) : props.project ? (
          <ProjectWrapper />
        ) : null}
      </PageContent>
    </PageWrapper>
  );
};

const mapStateToProps = (store: StoreRootState) => ({
  project: projectProjectsModule.selectors.getCurrentProject(store, null),
  loading: projectModule.selectors.isLoading(store, null),
});

export default connect(mapStateToProps, {
  fetchProject: projectsModule.actions.fetchProject,
  setCurrentProject: projectModule.actions.setCurrentProject,
  fetchProjectTasks: tasksModule.actions.fetchProjectTasks,
})(withAuthentication<ProjectProps>(UserAuthenticationStatus.AUTHENTICATED)(Project));
