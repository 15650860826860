import { SubmitEvent } from "#/store/types";
import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

interface RemoveModalConnectedProps {}

interface RemoveModalOwnProps {
  isModalOpen: boolean;
  toggleModal: any;
  onRemove: () => void;
  removeString: string;
  whatToDelete: string;
  additionalText?: string;
}

type RemoveModalProps = RemoveModalConnectedProps & RemoveModalOwnProps;

const ResourceDeletionModal = (props: RemoveModalProps) => {
  const { isModalOpen, toggleModal } = props;
  const [removeString, setRemoveString] = useState("");

  const handleSubmit = (e: SubmitEvent) => {
    e.preventDefault();
    if (removeString === props.removeString) {
      props.onRemove();
    }
  };

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <form onSubmit={handleSubmit}>
        <ModalHeader toggle={toggleModal}>Подтверждение удаления</ModalHeader>
        <ModalBody>
          <div className="form-group">
            <label className="fz-14">
              Введите <b>{props.removeString}</b>, чтобы удалить {props.whatToDelete}.
              {` `}
              {props.additionalText}
            </label>

            <input
              type="text"
              value={removeString}
              onChange={e => setRemoveString(e.target.value)}
              placeholder=""
              className="form-control mt-1"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="submit" className="btn btn-list action-rm">
            Продолжить
          </button>
          <button type="button" className="btn btn-list action-rm" onClick={toggleModal}>
            Закрыть
          </button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default ResourceDeletionModal;
