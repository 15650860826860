import { apiGet } from "#/api";
import { showErrorToast } from "#/components/toasts";
import { API_ROUTES } from "#/conf/api";
import { PrimaryKey, Top50EventFullData, Top50Event } from "#/store/types";
import { useEffect, useState } from "react";
import { Top50Group } from "./types";

export const useEventFullData = (pk: PrimaryKey | null, deps: any[]) => {
  const [event, setEvent] = useState<Top50EventFullData | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (!pk) {
        setEvent(null);
        return;
      }
      setLoading(true);
      try {
        const response = await apiGet(API_ROUTES.TOP50.EVENTS_ITEM(pk!));
        setEvent(response);
      } catch (err) {
        console.error(err);
        showErrorToast(
          "Произошла ошибка при попытке прогрузить группу запросов. Пожалуйста, перезагрузите страницу",
        );
      } finally {
        setLoading(false);
      }
    })();
  }, [pk]);

  return { data: event, loading };
};

export const useAllGroups = (
  options: { onlyMetadata: boolean; byId: boolean; filter: (ev: Top50Event) => boolean } = {
    onlyMetadata: true,
    byId: false,
    filter: () => true,
  },
  deps: any[],
) => {
  const [data, setData] = useState<Top50Group[] | Record<PrimaryKey, Top50Group> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    (async () => {
      try {
        const response = await apiGet(API_ROUTES.TOP50.EVENTS, {
          metadata_only: options.onlyMetadata,
        });
        const newData = response.filter(options.filter);
        if (options.byId) {
          const byId = newData.reduce((acc, item) => ({ ...acc, [item.pk]: item }), {});
          setData(byId);
        } else {
          setData(newData);
        }
      } finally {
        setLoading(false);
      }
    })();
  }, [...deps]);
  return { loading, data };
};
