import {
  ContentPlanProject,
  CRMProject,
  CRMProjectData,
  CRMTask,
  MonitoringProject,
  Top50Project,
  User,
} from "#/store/types";
import assert from "assert";
import _ from "lodash";

// TODO: Maybe implement memoization for permission checks?

////////////////////////////////////////////
// Types
////////////////////////////////////////////

export type Action =
  | "CREATE"
  | "DELETE"
  | "VIEW"
  | "EDIT"
  | "MODIFY_PROJECT_LIST"
  | "INVITE_USERS"
  | "ADD_MESSAGES"
  | "REMOVE_MEMBER";

export type ActionObjInstanceType =
  | "PROJECTCRM"
  | "PROJECTCP"
  | "PROJECTM"
  | "PROJECTTOP50"
  | "USER"
  | "TASK"
  | "NONE";

export type ActionObjInstance =
  | CRMProject
  | User
  | MonitoringProject
  | ContentPlanProject
  | Top50Project
  | CRMTask;

export type MultipleValueDeciderFunction = (values: boolean[]) => boolean;

export interface CommonActionsParams {
  // Whether there are multiple objects to check and use "objs" instead
  many?: boolean;

  // Multiple objects field
  objs?: ActionObjInstance[];

  // Single object field
  obj?: ActionObjInstance;

  // Multiple values decider function
  mdfn?: MultipleValueDeciderFunction;

  // Edit payload
  payload?: Partial<ActionObjInstance>;

  // CRM Project full data
  projectFullData?: CRMProjectData;

  taskProject?: CRMProjectData;

  projectMember?: User;
}

export interface CreateActionParams extends CommonActionsParams {}

export interface EditActionParams extends CommonActionsParams {}

export interface DeleteActionParams extends CommonActionsParams {}

export type ActionParams = EditActionParams | DeleteActionParams | CreateActionParams;

////////////////////////////////////////
// Multiple values decider functions
////////////////////////////////////////

export const mdAny = (results: boolean[]) => results.some(_.identity);

export const mdAll = (results: boolean[]) => results.every(_.identity);

export const mdNone = (results: boolean[]) => !mdAny(results);

/////////////////////////////////////////////
// Permission checkers
/////////////////////////////////////////////

// CRM User checkers
export const canUserCreateNewUsers = (subjectUser: User) => subjectUser.super_admin;
export const canUserChangeUserStatus = (subjectUser: User | null, objectUser: User) =>
  subjectUser && subjectUser.super_admin;

// CRM Task checkers
export const canUserAssignTasks = (subjectUser: User) => true;

// CRM Project checkers
export const canUserCreateNewProjects = (subjectUser: User | null): boolean =>
  subjectUser ? subjectUser.super_admin : false;

export const canUserCreateMonitoringProjects = (subjectUser: User | null) =>
  canUserCreateNewProjects(subjectUser);

export const canUserCreateContentPlanProjects = (subjectUser: User | null) =>
  canUserCreateNewProjects(subjectUser);

export const canUserDeleteProject = (subjectUser: User | null, objectProject: CRMProject | null) =>
  subjectUser && objectProject ? subjectUser.super_admin : false;

/////////////////////////////////////////////
// Generic permission checker
/////////////////////////////////////////////

const DEFAULT_MDFN = mdAll;

const getActionParamsManyObjsOptions = <T extends CommonActionsParams>(
  p: T,
): [ActionObjInstance[], MultipleValueDeciderFunction] => {
  assert(p.objs && p.objs instanceof Array);
  assert(p.many && p.many === true);
  const decider = p.mdfn ? p.mdfn : DEFAULT_MDFN;
  return [p.objs!, decider];
};

// Multiple value permission check requests are processed on the checker level
// because of optimization opportunity. For example, it is possible that you
// don't actually need to go through all of the object instances in order to figure
// out that you can delete all of them - maybe because the user is a super admin.

const ACTION_CHECKER_RESOLVE_MAP: Record<
  ActionObjInstanceType,
  Record<Action | string, (user: User | null, actionParams: ActionParams) => boolean>
> = {
  NONE: {
    INVITE_USERS: (user, p) => {
      if (!user) { return false; }
      return user.super_admin;
    },
  },

  PROJECTCRM: {
    CREATE: (user, p) => {
      return canUserCreateNewProjects(user);
    },
    EDIT: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      if (!p.many) {
        if (!user.admin) { return false; }
        const projectFullData = p.projectFullData;
        if (!projectFullData) { return false; }
        return _.includes(projectFullData.users, user.pk);
      } else {
        const [objs, decider] = getActionParamsManyObjsOptions(p);
        return decider(objs.map(o => canUser(user, "EDIT", "PROJECTCRM", { obj: o })));
      }
    },
    DELETE: (user, p) => {
      if (!p.many) {
        return canUserDeleteProject(user, p.obj as any);
      } else {
        const [objs, decider] = getActionParamsManyObjsOptions(p);
        return decider(objs.map(o => canUserDeleteProject(user, o as CRMProject)));
      }
    },
    REMOVE_MEMBER: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) {
        return true;
      }
      const objectUser = p.projectMember!;
      if (!objectUser) {
        console.error("Permission check problem: projectMember was not provided");
      }
      if (user.admin) {
        return !objectUser.admin;
      }
      return false;
    },
  },

  PROJECTCP: {
    CREATE: (user, p) => canUser(user, "CREATE", "PROJECTCRM", p),
    EDIT: (user, p) => canUser(user, "EDIT", "PROJECTCRM", p),
    DELETE: (user, p) => canUser(user, "DELETE", "PROJECTCRM", p),
  },

  PROJECTM: {
    CREATE: (user, p) => canUser(user, "CREATE", "PROJECTCRM", p),
    EDIT: (user, p) => canUser(user, "EDIT", "PROJECTCRM", p),
    DELETE: (user, p) => canUser(user, "DELETE", "PROJECTCRM", p),
  },

  PROJECTTOP50: {
    CREATE: (user, p) => canUser(user, "CREATE", "PROJECTCRM", p),
    EDIT: (user, p) => canUser(user, "EDIT", "PROJECTCRM", p),
    DELETE: (user, p) => canUser(user, "DELETE", "PROJECTCRM", p),
  },

  TASK: {
    VIEW: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      const taskProject = p.taskProject!;
      return taskProject && _.includes(taskProject.users, user.pk);
    },

    CREATE: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      const taskProject = p.taskProject!;
      return taskProject && _.includes(taskProject.users, user.pk);
    },

    EDIT: (user, p) => {
      if (!user) { return false; }
      const p_ = (p as any) as EditActionParams;
      const task = (p_.obj as any) as CRMTask;
      if (user.super_admin) { return true; }
      if (task.task_from === user.pk) { return true; }
      const taskProject = p.taskProject!;
      // Allow admin users from task project edit the task
      if (user.admin && taskProject && _.includes(taskProject.users, user.pk)) {
        return true;
      }
      if (task.task_to === user.pk) { return false; }
      return false;
    },

    DELETE: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      const taskProject = p.taskProject!;
      if (!taskProject) {
        return false;
      }
      if (!_.includes(taskProject.users, user.pk)) { return false; }
      const task: CRMTask = p.obj! as CRMTask;
      if (task.task_from === user.pk) {
        return true;
      }
      return false;
    },

    ADD_MESSAGES: (user, p) => {
      if (!user) { return false; }
      const task = (p.obj as any) as CRMTask;
      if (!task) { return false; }
      return task.task_from === user.pk || task.task_to === user.pk;
    },
  },

  USER: {
    CREATE: (user, p) => (user ? user.super_admin : false),
    EDIT: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      if (!p.many) {
        const objUser = (p.obj as any) as User;
        return user.pk === objUser.pk;
      } else {
        const [objs, decider] = getActionParamsManyObjsOptions(p);
        return decider(((objs as any[]) as CRMProject[]).map(o => o.pk === user.pk));
      }
    },
    DELETE: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      return false;
    },
    MODIFY_PROJECT_LIST: (user, p) => {
      if (!user) { return false; }
      if (user.super_admin) { return true; }
      return false;
    },
  },
};

export const canUser = (
  user: User | null,
  action: Action,
  instanceType?: ActionObjInstanceType,
  actionParams?: ActionParams,
) => {
  if (!user) { return false; }
  const instanceActionsMap = instanceType
    ? _.get(ACTION_CHECKER_RESOLVE_MAP, instanceType, null)
    : _.get(ACTION_CHECKER_RESOLVE_MAP, "NONE", null);
  assert(instanceActionsMap !== null);
  const checker = _.get(instanceActionsMap, action, null);
  assert(instanceActionsMap !== null);
  return checker!(user, actionParams ? actionParams : {});
};

export type ActionDescriptionTuple = [Action, ActionObjInstanceType, ActionParams];

export const canUserPerformAll = (user: User | null, actions: ActionDescriptionTuple[]) => {
  assert(actions.length >= 0, "There should be at least some actions specified.");
  assert(actions[0] instanceof Array, "Invalid actions list format");
  return actions.every(a => canUser(user, a[0], a[1], a[2]));
};

export const canUserPerformAnyOf = (user: User | null, actions: ActionDescriptionTuple[]) => {
  assert(actions.length >= 0, "There should be at least some actions specified.");
  assert(actions[0] instanceof Array, "Invalid actions list format");
  return actions.some(a => canUser(user, a[0], a[1], a[2]));
};
