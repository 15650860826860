import { SearchState, StoreRootState } from "#/store/types";
import { createSelector } from "reselect";

// Root
type Props = {} | null;
export const getSearchState = (store: StoreRootState, props: Props) => store.search;

export const getProps = (state: StoreRootState, props: Props) => props;

const getQuery = createSelector(
  [getSearchState],
  (search: SearchState) => search.query,
);

export default { getQuery };
