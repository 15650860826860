import usersModule from "#/store/modules/users";
import {
  CRMProject,
  PrimaryKey,
  ProjectsById,
  StoreRootState,
  User,
  UserData,
  UsersById,
  UserState,
} from "#/store/types";
import _ from "lodash";
import { createSelector } from "reselect";
import { getProjectsById } from "../projects/selectors";

type Props = {} | null;

const getUserState = (store: StoreRootState, props: Props = null) => store.user;
const getUserProps = (state: StoreRootState, props: Props = null) => props;

const getCurrentUserId = createSelector(
  [getUserState],
  (us: UserState) => us.userId,
);

const getCurrentUserData = createSelector(
  [usersModule.selectors.getUsersById, getCurrentUserId],
  (usersById: UsersById, cuid: PrimaryKey | null): UserData | null => {
    if (!cuid) {
      return null;
    }
    return _.get(usersById, cuid, null);
  },
);

const getCurrentUser = createSelector(
  [getCurrentUserData],
  (cu: UserData | null): User | null => {
    if (!cu) {
      return null;
    }
    return _.get(cu, "data", null);
  },
);

const getCurrentUserProjectsIds = createSelector(
  [getCurrentUser],
  (cu: User | null): PrimaryKey[] => (cu ? (cu.projects ? cu.projects : []) : []),
);

const getCurrentUserProjects = createSelector(
  [getCurrentUserProjectsIds, getProjectsById],
  (cpids: PrimaryKey[], pid: ProjectsById): CRMProject[] => {
    return _.compact(cpids.map(id => _.get(_.get(pid, id, {}), "data", null)));
  },
);

export default {
  getCurrentUserId,
  getCurrentUserData,
  getCurrentUser,
  getCurrentUserProjects,
  getCurrentUserProjectsIds,
};
