import Loader from "#/components/Loader";
import { showErrorToast } from "#/components/toasts";
import {
  fetchEvent,
  getCurrentEvent,
  getCurrentEventPk,
  getCurrentProjectPk,
  getMetrikaId,
  getProjectsForCurrentEvent,
  hasApiFailed,
  isLoading,
  setCurrentEvent,
  setCurrentProject,
} from "#/store/modules/metrika/top50";
import { PrimaryKey, StoreRootState } from "#/store/types";
import { MetrikaTop50Event, MetrikaTop50Project } from "#/store/modules/metrika/top50/types";
import _ from "lodash";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import QueryTable from "./components/QueryTable";

interface EventOwnProps {
  match: any;
}

interface EventConnectedProps {
  event: MetrikaTop50Event | undefined;
  fetchEvent: any;
  setCurrentProject: any;
  setCurrentEvent: any;
  eventPk: PrimaryKey;
  loading: boolean;
  projects: MetrikaTop50Project[];
  projectPk: number;
  metrikaId: number;
  loadingFailed: boolean;
}

type EventProps = EventOwnProps & EventConnectedProps;

const Event = (props: EventProps) => {
  useEffect(() => {
    props.setCurrentEvent(_.toNumber(props.match.params.eventPk));
    props.setCurrentProject(_.toNumber(props.match.params.projectPk));
  }, [props.match.params.eventPk, props.match.params.projectPk]);

  useEffect(() => {
    if (props.eventPk) {
      props.fetchEvent(props.eventPk).catch((err: Error) => {
        showErrorToast(err.message);
      });
    }
  }, [props.eventPk]);

  if (props.loading) {
    return <Loader />;
  }

  if (props.loadingFailed) {
    return <div>Ошибка при загрузке</div>;
  }

  if (props.event && _.some(props.projects, proj => proj.pk === props.projectPk)) {
    return (
      <>
        <div className="box-header with-border">
          <h4 className="box-title">{props.event.name}</h4>
        </div>
        <div className="box-body">
          <span>Проекты:</span>
          <div>
            {props.projects.map((proj, i, arr) => (
              <div key={proj.pk}>
                <Link to={`../${proj.pk}/${props.eventPk}`}>
                  <span style={proj.pk === props.projectPk ? { fontWeight: "bold" } : {}}>
                    {proj.title}
                  </span>
                </Link>
                {arr[i + 1] ? " / " : ""}
              </div>
            ))}
          </div>
        </div>

        {props.metrikaId ? (
          <QueryTable />
        ) : (
          <div>Отсутствует metrika id для данного проекта. Можете попробовать другие</div>
        )}
      </>
    );
  }
  return <div>Неизвестная ошибка</div>;
};

export default connect(
  (state: StoreRootState) => ({
    event: getCurrentEvent(state),
    eventPk: getCurrentEventPk(state),
    loading: isLoading(state),
    projects: getProjectsForCurrentEvent(state),
    projectPk: getCurrentProjectPk(state),
    metrikaId: getMetrikaId(state),
    loadingFailed: hasApiFailed(state),
  }),
  {
    fetchEvent,
    setCurrentEvent,
    setCurrentProject,
  },
)(Event);
