import React from "react";
import List from "./components/List";

const ListProjects = () => (
  <div>
    <section className="content-header">
      <h1>
        Список \ <small>Проекты</small>
      </h1>
    </section>

    <List />
  </div>
);

export default ListProjects;
