import {
  SessionState,
  StoreRootState,
  UserNotification,
  UserNotificationDerived,
} from "#/store/types";
import { compareTwoAPIFormatDates, compareTwoStringDatesAsc } from "#/util";
import _ from "lodash";
import { createSelector } from "reselect";
import { PAG_CURSOR_END } from "#/util/constants";

type Props = {} | null;

const getSession = (store: any, props: Props) => (store.qwerty ? store.qwerty : null);
const getProps = (store: StoreRootState, props: Props) => props;

export const getAuthToken = createSelector(
  [getSession, getProps],
  (session: SessionState | null, props) => (session ? session.token : null),
);

export const isAuthenticated = createSelector([getSession], (session: SessionState | null) =>
  session ? session.currentUserId !== null : false,
);

export const isAuthenticationInProgress = createSelector(
  [getSession],
  (session: SessionState | null) => (session ? session.authenticating : false),
);

export const isCurrentlyFetchingCurrentUserInfo = createSelector(
  [getSession],
  (session: SessionState | null) => (session ? session.fetchingCurrentUserInfo : false),
);

export const getCurrentUserID = createSelector([getSession], (session: SessionState | null) =>
  session ? session.currentUserId : null,
);

export const getLoginErrorMessage = createSelector([getSession], (s: SessionState | null) =>
  s ? s.loginErrMsg : null,
);

export const getCurrentUserNotifications = createSelector(
  [getSession],
  (s: SessionState) => s.notifications,
);

export const isCurrentlyLoadingUserNotifications = createSelector(
  [getSession],
  (s: SessionState) => s.loadingUserNotifications,
);

// export const getUnreadNotificationsAmount = createSelector(
//   [getCurrentUserNotifications],
//   (n: UserNotificationDerived[]) =>
//     n.reduce(
//       (acc: number, nd: UserNotificationDerived) =>
//         acc + ((!nd.notification_model.read as any) as number),
//       0,
//     ),
// );

export const getUnreadNotificationsAmount = createSelector(
  [getSession],
  st => st.notificationsUnread,
);

// CLEANUP: It is probably better to do the sorting in one pass
export const getCurrentUserNotificationsSortedByDateAndReadStatusAsc = createSelector(
  [getCurrentUserNotifications],
  (n: UserNotificationDerived[]) =>
    _.sortBy(_.reverse(_.sortBy(n, ["notification_model.create_date"])), [
      "notification_model.read",
    ]),
);

export const getNotificationsNextCursor = createSelector(
  [getSession],
  (s: SessionState) => s.notificationsNext,
);

export const areAllUserNotificationsAlreadyFetched = createSelector(
  [getSession],
  (s: SessionState) => s.notificationsNext === PAG_CURSOR_END,
);

export default {
  getAuthToken,
  getCurrentUserID,
  getLoginErrorMessage,
  isAuthenticated,
  isAuthenticationInProgress,
  isCurrentlyFetchingCurrentUserInfo,
  getCurrentUserNotifications,
  isCurrentlyLoadingUserNotifications,
  getUnreadNotificationsAmount,
  getCurrentUserNotificationsSortedByDateAsc: getCurrentUserNotificationsSortedByDateAndReadStatusAsc,
  getNotificationsNextCursor,
  areAllUserNotificationsAlreadyFetched,
};
