import { apiGet } from "#/api";
import { API_ROUTES } from "#/conf/api";
import store from "#/store";
import projectsModule from "#/store/modules/projects";
import usersModule from "#/store/modules/users";
import { CRMProject, User } from "#/store/types";
import Fuse from "fuse.js";
import { useMemo, useState } from "react";
import { handleDispatchErrorAndDisplayToast, undefinedOr } from ".";

export const useUsersWithQuery = (
  minimal = false,
  filterFunction?: (user: User) => boolean,
  onlyIds = false,
): [User[], [string, (v: string) => void]] => {
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState<User[]>([]);

  useMemo(() => {
    apiGet(API_ROUTES.CRMPLAN.MANAGERS, { query: searchQuery, minimal, only_ids: onlyIds })
      .then((result: User[]) => {
        const filteredUsers = filterFunction ? result.filter(filterFunction) : result;
        setUsers(filteredUsers);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  }, [searchQuery]);

  return [users, [searchQuery, setSearchQuery]];
};

export const useProjectsWithQuery = (
  minimal = false,
): [CRMProject[], [string, (v: string) => void]] => {
  const [searchQuery, setSearchQuery] = useState("");
  const [projects, setProjects] = useState<CRMProject[]>([]);

  useMemo(() => {
    apiGet(API_ROUTES.CRMPLAN.PROJECTS, { query: searchQuery, minimal })
      .then((result: CRMProject[]) => {
        setProjects(result);
      })
      .catch(handleDispatchErrorAndDisplayToast);
  }, [searchQuery]);

  return [projects, [searchQuery, setSearchQuery]];
};

export const useLocalUsersWithQuery = (
  minimal = false,
  filterFunction?: (user: User) => boolean,
  onlyIds = false,
): [User[], [string, (v: string) => void]] => {
  const [searchQuery, setSearchQuery] = useState("");
  const [users, setUsers] = useState<User[]>([]);
  const users_ = usersModule.selectors.getAllUsersSortedByNameBare(store.getState() as any, null);

  useMemo(() => {
    if (searchQuery.length > 0) {
      const fuse = new Fuse(users_, {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["first_name", "last_name"],
      });
      const result = fuse.search(searchQuery);
      setUsers(result);
    } else {
      setUsers(users_);
    }
  }, [searchQuery, users_]);

  return [users, [searchQuery, setSearchQuery]];
};

export const useLocalProjectsWithQuery = (
  minimal = false,
  filterFunction?: (user: User) => boolean,
  onlyIds = false,
): [User[], [string, (v: string) => void]] => {
  const [searchQuery, setSearchQuery] = useState("");
  const [projects, setProjects] = useState<User[]>([]);
  const projects_ = projectsModule.selectors.getAllProjectsBare(store.getState() as any, null);

  useMemo(() => {
    if (searchQuery.length > 0) {
      const fuse = new Fuse(projects_, {
        shouldSort: true,
        threshold: 0.6,
        location: 0,
        distance: 100,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: ["name"],
      });
      const result = fuse.search(searchQuery);
      setProjects(result);
    } else {
      setProjects(projects_);
    }
  }, [searchQuery, projects_]);

  return [projects, [searchQuery, setSearchQuery]];
};
