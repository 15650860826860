import React from "react";
import { ListGroup, ListGroupItem, FormGroup, Label as h6, Button, Col } from "reactstrap";
import ControllableRegionSelect from "../../RegionSelect/ControllableRegionSelect";
import { undefinedOr, nullOr } from "#/util";
import ControllableSearchEngineSelect from "../SearchEngineSelect/ControllableSearchEngineSelect";
import { Top50Configuration, Top50ProjectConfiguration } from "#/store/types";

export type Top50ConfigurationCreate = Partial<Omit<Top50Configuration, "pk">>;

export interface Props {
  configs: Top50Configuration[];
  addingConfiguration: boolean;
  onAdd: (c: Top50ConfigurationCreate) => void;
}

const ProjectConfigurationModificationForm = (props: Props) => {
  const { configs, onAdd, addingConfiguration } = props;
  const [configurationToAdd, setConfigurationToAdd] = React.useState<Top50ConfigurationCreate>({
    region: undefined,
    search_engine: undefined,
  });
  return (
    <>
      {configs.length > 0 ? (
        <ListGroup flush={true}>
          {configs.map((config, index) => {
            return (
              <ListGroupItem>
                <span>
                  {index + 1}. {config.region_model!.name} - {config.search_engine_model!.name}
                </span>
              </ListGroupItem>
            );
          })}
        </ListGroup>
      ) : null}

      {/* Add a new configuration */}
      <form
        onSubmit={e => {
          e.preventDefault();
          onAdd(configurationToAdd);
        }}
        className={`${addingConfiguration ? "low-opacity" : ""} mt-3`}
      >
        <FormGroup row={true}>
          <Col>
            <label className="my-2">Регион</label>
            <ControllableRegionSelect
              value={undefinedOr(configurationToAdd.region, null)}
              onChange={r =>
                setConfigurationToAdd({
                  ...configurationToAdd,
                  region: r ? r.pk : undefined,
                  region_model: nullOr(r, undefined),
                })
              }
            />
          </Col>
          <Col>
            <label className="my-2">Поисковая система</label>
            <ControllableSearchEngineSelect
              value={undefinedOr(configurationToAdd.search_engine, null)}
              onChange={r =>
                setConfigurationToAdd({
                  ...configurationToAdd,
                  search_engine: r ? r.pk : undefined,
                  search_engine_model: nullOr(r, undefined),
                })
              }
            />
          </Col>
        </FormGroup>
        <Button type="submit" color="primary" disabled={addingConfiguration}>
          {addingConfiguration ? "Сохранение..." : "Добавить"}
        </Button>
      </form>
    </>
  );
};

export default ProjectConfigurationModificationForm;
