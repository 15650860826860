import { formatDate, formatDateLong, shadeColor, compareTwoAPIFormatDates } from "#/util";
import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Top50ProjectGraph, Top50ProjectGraphDataPoint } from "../types";

const CustomizedDot = props => {
  const { cx, cy, stroke } = props;
  return <circle cx={cx} cy={cy} r={4} stroke={stroke} strokeWidth={1} fill={stroke} />;
};

export interface GraphProps {
  graph: Top50ProjectGraph;
  height: number;
}

const useModalStatus = (defaultStatus: boolean = false) => {
  const [opened, setOpened] = useState<boolean>(defaultStatus);
  const toggle = useCallback(() => setOpened(!opened), [setOpened, opened]);
  const open = useCallback(() => setOpened(true), [setOpened]);
  const close = useCallback(() => setOpened(false), [setOpened]);
  return { opened, toggle, close, open };
};

const Graph = (props: GraphProps) => {
  const modal = useModalStatus(false);
  const [payload, setPayload] = useState<Top50ProjectGraphDataPoint | null>(null);
  const handleLineClick = useCallback((data, index) => {
    if (!data) {
      return;
    }
    const newPayload = data.activePayload[0].payload;
    setPayload(newPayload);
    modal.close();
  }, []);
  const dataWithTasks = props.graph.graph
    ? props.graph.graph.filter(g => {
        return g.tasks && g.tasks.length > 0;
      })
    : [];
  const height = props.height;

  const dataToPlot = React.useMemo(
    () => props.graph.graph.sort((a, b) => compareTwoAPIFormatDates(a.date, b.date)),
    [props.graph.graph],
  );

  return (
    <>
      <ResponsiveContainer width="100%" height={height}>
        <LineChart
          data={dataToPlot}
          margin={{ top: 5, right: 30, left: 10, bottom: 5 }}
          onClick={handleLineClick}
        >
          <XAxis tickFormatter={(v, n, p) => formatDate(v)} dataKey="date" interval={0} />
          <YAxis tickFormatter={(v, n, p) => v + "%"} />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            formatter={(v, n, p) => {
              const name = n === "top_3" ? "Топ 3" : n === "top_10" ? "Топ 10" : "Топ 30";
              return [v + "%", name];
            }}
            labelFormatter={(v, n, p) => {
              return [formatDateLong(v)];
            }}
          />
          <Legend
            formatter={(v, n, p) => {
              const name = v === "top_3" ? "Топ 3" : v === "top_10" ? "Топ 10" : "Топ 30";
              return [name];
            }}
          />
          {dataWithTasks.map((data, i) => (
            <ReferenceLine key={i} x={data.date} stroke={shadeColor("#82ca9d", -45)} />
          ))}
          <Line
            type="monotone"
            dataKey="top_3"
            stroke="#8884d8"
            dot={<CustomizedDot />}
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="top_10"
            stroke="#82ca9d"
            dot={<CustomizedDot />}
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="top_30"
            stroke="#b45cc3"
            dot={<CustomizedDot />}
            activeDot={{ r: 8 }}
          />
        </LineChart>
      </ResponsiveContainer>

      <Modal
        isOpen={modal.opened}
        toggle={modal.toggle}
        className="modal-dialog-centered"
        contentClassName="alert alert-primary"
      >
        <ModalHeader toggle={modal.toggle}>
          {payload && "Информация от " + formatDateLong(payload.date)}
        </ModalHeader>
        <ModalBody>
          {payload && (
            <>
              <div className="form-group d-flex justify-content-between">
                <div>Топ 3: {payload.top_3}%</div>
                <div>Топ 10: {payload.top_10}%</div>
                <div>Топ 30: {payload.top_30}%</div>
              </div>
              <div className="form-group">
                {payload.tasks && payload.tasks.length > 0 && (
                  <>
                    Задачи, относящиеся к этой группе запросов:
                    {payload.tasks.map((task, index) => (
                      <div key={index}>
                        <Link target="_blank" to={"/task/" + task.pk}>
                          <i className="fas fa-link" />
                          <span className="ml-1">{task.name}</span>
                        </Link>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={modal.toggle}>
            Закрыть
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Graph;
