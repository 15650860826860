import usersModule from "#/store/modules/users";
import { StoreRootState, UserRolesById } from "#/store/types";
import React from "react";
import { connect } from "react-redux";

const styles = (name: string) => {
  switch (name) {
    case "Разработчик":
      return "label-warning";
    case "Менеджер":
      return "label-inverse";
    // case '':
    //   return "label-orange";
    // case 3:
    //   return "label-success";
    // case 4:
    //   return "label-inverse-simple";
    default:
      return "label-orange";
  }
};

const UserRoleLabel = (props: { name: string; roles: UserRolesById }) => {
  return (
    <label key={name} className={"ml-1 label " + styles(props.name)}>
      {props.name}
    </label>
  );
};

export default connect(
  (store: StoreRootState) => ({
    roles: usersModule.selectors.getUserRolesById(store, null),
  }),
  null,
)(UserRoleLabel) as any;
