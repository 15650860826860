import React, { useState } from "react";
import { connect } from "react-redux";
import { getCounters, fetchGoals, deleteSelectedGoals } from "#/store/modules/metrika/goals";
import { PrimaryKey, StoreRootState } from "#/store/types";
import { Counter } from "#/store/modules/metrika/goals/types";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Table from "./components/Table";

interface EditSceneOwnProps {}

interface EditSceneConnectedProps {
  counters: Record<PrimaryKey, Counter>;
  fetchGoals: any;
  deleteSelectedGoals: any;
}

type EditSceneProps = EditSceneConnectedProps & EditSceneOwnProps;

const EditScene = (props: EditSceneProps) => {
  const [showTokenModal, setShowTokenModal] = useState(false);
  const toggleTokenModal = () => setShowTokenModal(v => !v);
  return (
    <>
      <div className="box-header with-border flex-vertically-centered-x justify-content-between">
        <h4>Изменение целей</h4>
        <div>
          <button
            className="btn btn-list ml-2"
            data-toggle="modal"
            data-target="tokenModal"
            onClick={toggleTokenModal}
          >
            <i className="fas fa-download" />
            <span className="ml-2">Получить существующие цели</span>
          </button>
        </div>
      </div>
      <Table />
      <button className="btn btn-primary" onClick={props.deleteSelectedGoals}>
        WTF
      </button>
      <Modal isOpen={showTokenModal} toggle={toggleTokenModal}>
        <ModalHeader toggle={toggleTokenModal}>
          Выберите счетчик для которого надо получить цели
        </ModalHeader>
        <ModalBody>
          {Object.values(props.counters).map(counter => (
            <button
              onClick={() => props.fetchGoals(counter.id)}
              className="btn btn-primary btn-block"
            >
              <span>{counter.name}</span>
            </button>
          ))}
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
};

export default connect(
  (state: StoreRootState) => ({
    counters: getCounters(state),
  }),
  {
    fetchGoals,
    deleteSelectedGoals,
  },
)(EditScene);
