import { PrimaryKey } from "#/store/types";
import { PRODUCTION_MODE, PUBLIC_DEV_MODE } from "./constants";

// API path
export const HOST_URL = true
  ? `https://seo-rocket.ru/api`
  // ? "http://localhost:8002/api"
  : PUBLIC_DEV_MODE
  ? "http://dev.seo-rocket.ru/api"
  : "http://localhost:8000/api";
// export const HOST_URL = `http://seo-rocket.ru/api`;

// WebSocket API paths
export const WS_API_URL = true
  ? "wss://seo-rocket.ru/ws"
  : PUBLIC_DEV_MODE
  ? "ws://dev.seo-rocket.ru/ws"
  : "ws://localhost:8000/ws";
export const WS_API_SITEMONITORING_URL = `${WS_API_URL}/sitemonitoring/`;
export const WS_API_MAIN_URL = (userId: PrimaryKey) => `${WS_API_URL}/main/${userId}/`;
export const WS_API_TASK_CHAT_URL = (taskId: PrimaryKey) => `${WS_API_URL}/task_chat/${taskId}/`;
export const WS_API_TOP50_URL = `${WS_API_URL}/top50/`;

const API_PREFIXES = {
  MAIN: `/main`,
  CRMPLAN: `/crmplan`,
  TOP50: `/top50`,
  MONITORING: `/sitemonitoring`,
  CONTENTPLAN: `/contentplan`,
  PROXY: `/proxy`,
  INVITATION: "/crmplan/invitation_system",
  ACCOUNTS: `/accounts`,
  SETTINGS: `/serversettings`,
};

const MAIN_ROUTE = HOST_URL + API_PREFIXES.MAIN;
const CRMPLAN_ROUTE = HOST_URL + API_PREFIXES.CRMPLAN;
const TOP50_ROUTE = HOST_URL + API_PREFIXES.TOP50;
const MONITORING_ROUTE = HOST_URL + API_PREFIXES.MONITORING;
const CONTENTPLAN_ROUTE = HOST_URL + API_PREFIXES.CONTENTPLAN;
const PROXY_ROUTE = HOST_URL + API_PREFIXES.PROXY;
const INVITATION_SYSTEM_ROUTE = `${HOST_URL}${API_PREFIXES.INVITATION}`;
const ACCOUNTS_ROUTE = `${HOST_URL}${API_PREFIXES.ACCOUNTS}`;
const SETTINGS_ROUTE = `${HOST_URL}${API_PREFIXES.SETTINGS}`;
const METRIKA_ROUTE = `${HOST_URL.replace("/api", "")}/metrika`;

export const API_ROUTES = {
  MAIN: {
    AUTHORIZE: `${MAIN_ROUTE}/users/authorize/`,
    RESET_PASSWORD: `${MAIN_ROUTE}/users/reset_requests/`,
    RESET_PASSWORD_APPROVE_CODE: `${MAIN_ROUTE}/users/reset_requests/approve/`,
    RESET_PASSWORD_FINAL: `${MAIN_ROUTE}/users/reset_requests/reset/`,
    GET_CURRENT_USER_INFO: `${CRMPLAN_ROUTE}/current_user/`,
  },

  CRMPLAN: {
    PROJECTS: `${CRMPLAN_ROUTE}/projects/`,
    PROJECTS_ITEM: (item: number) => `${CRMPLAN_ROUTE}/projects/${item}/`,

    TASKS: `${CRMPLAN_ROUTE}/tasks/`,
    TASKS_ITEM: (item: number) => `${CRMPLAN_ROUTE}/tasks/${item}/`,
    TASK_CHAT_ADD_MSG: (taskId: PrimaryKey) => `${CRMPLAN_ROUTE}/tasks/${taskId}/add_message/`,
    TASK_CHAT_GET_ALL: (taskId: PrimaryKey) => `${CRMPLAN_ROUTE}/tasks/${taskId}/all_messages/`,
    TASK_BECOME_EXECUTOR: (taskId: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/tasks/${taskId}/become_executor/`,
    TASKS_TO_USER: `${CRMPLAN_ROUTE}/tasks/tasks_to_user/`,
    TASKS_BY_USER: `${CRMPLAN_ROUTE}/tasks/tasks_by_user/`,
    CHECKLIST: `${CRMPLAN_ROUTE}/checklistitems/`,
    CHECKLIST_ITEM: (item: number) => `${CRMPLAN_ROUTE}/checklistitems/${item}/`,
    CHECKLIST_ITEMS_MASS_CREATE: `${CRMPLAN_ROUTE}/checklistitems/mass_create/`,
    TOGGLE_TASK_SUBSCRIPTION: (pk: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/tasks/${pk}/toggle_subscription/`,

    PROJECT_TASKS: (projId: PrimaryKey) => `${CRMPLAN_ROUTE}/projects/${projId}/tasks/`,
    ADD_USER_TO_PROJECT: (projectId: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/projects/${projectId}/add_user/`,
    REMOVE_USER_FROM_PROJECT: (projectId: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/projects/${projectId}/remove_user/`,
    CHANGE_USER_STATUS_TO_USER: (userId: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/users/${userId}/change_status_to_user/`,
    CHANGE_USER_STATUS_TO_ADMIN: (userId: PrimaryKey) =>
      `${CRMPLAN_ROUTE}/users/${userId}/change_status_to_admin/`,

    MANAGERS: `${CRMPLAN_ROUTE}/users/`,
    MANAGERS_ITEM: (item: number) => `${CRMPLAN_ROUTE}/users/${item}/`,
    MANAGERS_ITEM_INFO: (id: PrimaryKey) => `${CRMPLAN_ROUTE}/users/${id}/`,
    MANAGERS_LIST: `${CRMPLAN_ROUTE}/users/`,
    USER_ROLES: `${CRMPLAN_ROUTE}/users/get_roles/`,
    EDIT_USER_PROJECTS: (userId: PrimaryKey) => `${CRMPLAN_ROUTE}/users/${userId}/edit_projects/`,
    EDIT_USER_AVATAR: (userId: PrimaryKey) => `${CRMPLAN_ROUTE}/users/${userId}/upload_avatar/`,
    USER_TASKS_FROM: (id: PrimaryKey) => `${CRMPLAN_ROUTE}/users/${id}/tasks_from/`,
    USER_TASKS_TO: (id: PrimaryKey) => `${CRMPLAN_ROUTE}/users/${id}/tasks_to/`,

    USER_NOTIFICATIONS: `${CRMPLAN_ROUTE}/current_user/notifications/`,
    MARK_USER_NOTIFICATIONS_AS_READ: `${CRMPLAN_ROUTE}/current_user/mark_notifications_as_read/`,
    CURRENT_USER_SETTINGS: `${CRMPLAN_ROUTE}/current_user/settings/`,

    SEARCH: `${CRMPLAN_ROUTE}/search/`,
    TAG_AUTOCOMPLETE: `${CRMPLAN_ROUTE}/tag_autocomplete/`,
  },

  MONITORING: {
    PROJECTS: `${MONITORING_ROUTE}/projects/`,
    PROJECTS_ITEM: (item: number) => `${MONITORING_ROUTE}/projects/${item}/`,
    URLS: `${MONITORING_ROUTE}/urls/`,
    URLS_BY_PROJECT: (item: PrimaryKey) => `${MONITORING_ROUTE}/projects/${item}/full_info/`,
    URLS_MASS_CREATE: `${MONITORING_ROUTE}/urls/mass_create/`,
    URLS_MASS_DELETE: `${MONITORING_ROUTE}/urls/mass_delete/`,
    URLS_MASS_TOGGLE: `${MONITORING_ROUTE}/urls/mass_toggle/`,
    PROJECT_CHECKED_URLS: (pk: PrimaryKey) => `${MONITORING_ROUTE}/projects/${pk}/checked_urls/`,
    RUN_CHECK_PROCESS: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/start_check_process/`,
    CHANGE_PROJECT_FILTERS: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/change_filters/`,
    SUBSCRIBE_TO_PROJECT: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/subscribe/`,
    UNSUBSCRIBE_FROM_PROJECT: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/unsubscribe/`,
    TOGGLE_PROJECT_SUBSCRIPTION: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/toggle_subscription/`,
    IMPORT_URLS_FROM_SITEMAP: (projId: PrimaryKey) =>
      `${MONITORING_ROUTE}/projects/${projId}/import_urls_from_sitemap/`,
  },

  TOP50: {
    EVENTS: `${TOP50_ROUTE}/events/`,
    EVENTS_ITEM: (item: number) => `${TOP50_ROUTE}/events/${item}/`,
    EVENTS_TOGGLE_ON: `${TOP50_ROUTE}/events/events_on/`,
    EVENTS_TOGGLE_OFF: `${TOP50_ROUTE}/events/events_off/`,
    EVENTS_REMOVE_QUERYPAGES: `${TOP50_ROUTE}/events/remove_queries_from_event/`,

    IMPORT_INTO_GROUP: (groupPk: PrimaryKey) =>
      `${TOP50_ROUTE}/events/${groupPk}/import_into_group/`,

    PROJ_ADD_EVENTS: (projId: PrimaryKey) => `${TOP50_ROUTE}/projects/${projId}/add_events/`,
    PROJ_REMOVE_EVENTS: (projId: PrimaryKey) => `${TOP50_ROUTE}/projects/${projId}/remove_events/`,
    PROJECTS_WITH_EVENTS_PAG: (page: number) =>
      `${TOP50_ROUTE}/projects/get_with_events/?page=${page}`,
    PROJECT_GRAPH: (item: PrimaryKey | string) => `${TOP50_ROUTE}/projects/${item}/graph/`,
    PROJECT_FULL_INFO: (item: PrimaryKey | string) => `${TOP50_ROUTE}/projects/${item}/full_info/`,
    PROJECT_ADD_CONFIG: (item: PrimaryKey) => `${TOP50_ROUTE}/projects/${item}/add_configuration/`,

    QUERIES: `${TOP50_ROUTE}/queries/`,
    QUERY: (pk: PrimaryKey) => `${TOP50_ROUTE}/queries/${pk}/`,

    QUERYPAGES: `${TOP50_ROUTE}/querypages/`,
    QUERYPAGES_ITEM: (item: number) => `${TOP50_ROUTE}/querypages/${item}/`,
    QUERYPAGES_POSITIONS: `${TOP50_ROUTE}/querypages/query_positions/`,
    QUERYPAGES_POSITIONS_BY_DATE: `${TOP50_ROUTE}/querypages/query_positions_by_date/`,
    QUERYPAGES_URLS: `${TOP50_ROUTE}/querypages/urls_mass_change/`,

    PROJECTS: `${TOP50_ROUTE}/projects/`,
    PROJECTS_ITEM: (item: number) => `${TOP50_ROUTE}/projects/${item}/`,
    PROJECTS_PAG: (page: number, alias: string) =>
      `${TOP50_ROUTE}/projects/?page=${page}&alias_filter=${alias}`,
    PROJECT_CONFIGARTIONS: (id: PrimaryKey) => `${TOP50_ROUTE}/projects/${id}/configurations/`,

    WORDSTAT_SCRIPT_STATUS: `${TOP50_ROUTE}/queries/fetch_actual_wordstat/`,
    WORDSTAT_SCRIPT_RUN: `${TOP50_ROUTE}/queries/start_script/`,

    START_QUERY_DATA_COLLECTION: `${TOP50_ROUTE}/projects/start_query_data_collection_process/`,
    QUERY_DATA_COL_STATUS: `${TOP50_ROUTE}/projects/query_data_collection_status/`,

    GET_EVENT_FOR_METRIKA: (pk: PrimaryKey) => `${TOP50_ROUTE}/events/${pk}/get_for_metrika/`,

    REGIONS: `${TOP50_ROUTE}/regions/`,

    SEARCH_ENGINES: `${TOP50_ROUTE}/search_engines/`,

    CONFIGURATIONS: `${TOP50_ROUTE}/configurations/`,
    DEFAULT_CONFIGURATION: `${TOP50_ROUTE}/configurations/default_config/`,
  },

  CONTENTPLAN: {
    PROJECTS: `${CONTENTPLAN_ROUTE}/projects/`,
    PROJECTS_ITEM: (alias: string | number) => `${CONTENTPLAN_ROUTE}/projects/${alias}/`,
    PROJECTS_ITEM_FETCH: (pk: PrimaryKey) => `${CONTENTPLAN_ROUTE}/projects/${pk}/fetch/`,
    EVENTS: (params: string) => `${CONTENTPLAN_ROUTE}/events/${params}`,
    EVENTS_ITEM: (item: number) => `${CONTENTPLAN_ROUTE}/events/${item}/`,
  },

  PROXY: {
    PROXIES: `${PROXY_ROUTE}/proxies/`,
    LIST: `${PROXY_ROUTE}/list/`,
    CREATE_PROXIES: `${PROXY_ROUTE}/proxies/create_list/`,
  },

  INVITATION_SYSTEM: {
    GENERATE_INVITATION_LINK: `${INVITATION_SYSTEM_ROUTE}/generate/`,
    REGISTER_VIA_INVITATION: `${INVITATION_SYSTEM_ROUTE}/register_via_invite/`,
  },

  ACCOUNTS: {
    GET_TYPES_OF_SITES: `${ACCOUNTS_ROUTE}/typesites/`,
    GET_SITE: (pk: PrimaryKey) => `${ACCOUNTS_ROUTE}/sites/${pk}/`,
    GET_SITE_ACCOUNTS: (pk: PrimaryKey) => `${ACCOUNTS_ROUTE}/sites/${pk}/accounts/`,
    SITE_ACCOUNTS_REQUEST_WITH_QUERY: (pk: PrimaryKey, query: string) =>
      `${ACCOUNTS_ROUTE}/sites/${pk}/accounts/${query}`,
  },

  SETTINGS: {
    SETTINGS_LIST: `${SETTINGS_ROUTE}/`,
  },

  METRIKA: {
    ALL_TOKENS: `${METRIKA_ROUTE}/all-tokens`,
    ADD_GOAL: `${METRIKA_ROUTE}/add-goal`,
    FETCH_COUNTERS: `${METRIKA_ROUTE}/fetch-counters`,
    FETCH_GOALS: `${METRIKA_ROUTE}/fetch-goals`,
    DELETE_GOALS: `${METRIKA_ROUTE}/delete-goals`,
  },
};

export const ROUTES_ALLOWED_METHODS = {
  MAIN: {
    AUTHORIZE: ["POST"],
    RESET_PASSWORD: ["POST"],
    RESET_PASSWORD_APPROVE_CODE: ["POST"],
    RESET_PASSWORD_FINAL: ["POST"],
    GET_CURRENT_USER_INFO: ["GET"],
  },

  CRMPLAN: {
    PROJECTS: ["GET", "POST"],
    PROJECTS_ITEM: ["GET"],
    PROJECTS_ITEM_INFO: ["GET"],
    TASKS: ["GET", "POST"],
    TASKS_ITEM: ["GET"],
    TASKS_TO_USER: ["POST"],
    TASKS_BY_USER: ["POST"],
    CHECKLIST: ["POST", "GET"],
    CHECKLIST_ITEM: ["GET"],
    CHECKLIST_ITEMS_MASS_CREATE: ["POST"],
    MANAGERS: ["GET"],
    MANAGERS_ITEM: ["GET"],
    MANAGERS_ITEM_INFO: ["GET"],
    MANAGERS_LIST: ["GET"],
    CHAT: ["POST", "GET"],
  },

  MONITORING: {
    PROJECTS: ["POST", "GET"],
    PROJECTS_ITEM: ["GET"],
    URLS: ["POST", "GET"],
    URLS_BY_PROJECT: ["POST"],
    URLS_MASS_CREATE: ["POST"],
    URLS_MASS_DELETE: ["DELETE"],
    URLS_MASS_TOGGLE: ["POST"],
    CHECKEDURLS: ["GET", "POST"],
    CHECKEDURLS_BY_DATE: ["GET", "POST"],
    RUN_CHECK_PROCESS: ["POST"],
    CHANGE_PROJECT_FILTERS: ["POST"],
  },

  TOP50: {
    EVENTS: ["POST", "GET"],
    EVENTS_TOGGLE_ON: ["POST"],
    EVENTS_TOGGLE_OFF: ["POST"],
    EVENTS_REMOVE_QUERYPAGES: ["DELETE"],
    EVENTS_PROJECT_ADD: ["POST"],
    EVENTS_REMOVE: ["DELETE"],
    EVENTS_ITEM: ["GET"],
    QUERYPAGES: ["POST", "DELETE", "GET"],
    QUERYPAGES_ITEM: ["GET"],
    QUERYPAGES_BY_PROJECT: ["GET"],
    QUERYPAGES_BY_PROJECT_WITHOUT_POSITIONS: ["GET"],
    QUERYPAGES_POSITIONS: ["GET"],
    QUERYPAGES_POSITIONS_BY_DATE: ["GET", "POST"],
    QUERYPAGES_POSITIONS_BY_EVENT: ["GET", "POST"],
    QUERYPAGES_URLS: ["GET", "POST"],
    PROJECTS: ["GET", "POST"],
    PROJECTS_ITEM: ["GET", "POST"],
    PROJECTS_PAG: ["GET", "POST"],
    WORDSTAT_SCRIPT_STATUS: ["GET", "POST"],
    WORDSTAT_SCRIPT_RUN: ["GET", "POST"],
  },

  CONTENTPLAN: {
    PROJECTS: ["POST", "GET"],
    PROJECTS_ITEM: ["POST", "GET"],
    PROJECTS_ITEM_DETAIL: ["POST", "GET"],
    PROJECTS_ITEM_FETCH: ["POST", "GET"],
    EVENTS: ["POST", "GET"],
    EVENTS_ITEM: ["GET"],
  },

  PROXY: {
    PROXIES: ["POST", "GET"],
    LIST: ["POST", "GET"],
    CREATE_PROXIES: ["POST", "GET"],
  },

  INVITATION_SYSTEM: {
    GENERATE_INVITATION_LINK: ["POST"],
    REGISTER_VIA_INVITATION: ["POST"],
  },

  ACCOUNTS: {
    GET_WEB_RESOURCES: ["GET"],
    GET_RESOURCE_ACCOUNTS: ["POST"],
  },
};

export const ROUTES_POSSIBLE_HTTP_CODES = {
  MAIN: {
    AUTHORIZE: [200],
    RESET_PASSWORD: [200],
    RESET_PASSWORD_APPROVE_CODE: [200],
    RESET_PASSWORD_FINAL: [200],
    GET_CURRENT_USER_INFO: [200, 403],
  },

  CRMPLAN: {
    PROJECTS: [200, 403],
    PROJECTS_ITEM: [200, 404, 403],
    PROJECTS_ITEM_INFO: [200, 404, 403],
    TASKS: [200, 403],
    TASKS_ITEM: [200, 404, 403],
    TASKS_TO_USER: [200, 403],
    TASKS_BY_USER: [200, 403, 404],
    CHECKLIST: [200, 403],
    CHECKLIST_ITEM: [200, 404, 403],
    CHECKLIST_ITEMS_MASS_CREATE: [200, 403],
    MANAGERS: [200, 403],
    MANAGERS_ITEM: [200, 403, 404],
    MANAGERS_ITEM_INFO: [200, 403, 404],
    MANAGERS_LIST: [200, 403],
    CHAT: [200, 403],
  },

  MONITORING: {
    PROJECTS: [200, 403],
    PROJECTS_ITEM: [404, 200, 403],
    URLS: [200, 403],
    URLS_BY_PROJECT: [200, 403, 404],
    URLS_MASS_CREATE: [200, 403],
    URLS_MASS_DELETE: [200, 403],
    URLS_MASS_TOGGLE: [200, 403],
    CHECKEDURLS: [200, 403],
    CHECKEDURLS_BY_DATE: [200, 403],
    RUN_CHECK_PROCESS: [200, 403],
    CHANGE_PROJECT_FILTERS: [200, 403, 404],
  },

  TOP50: {
    EVENTS: [200, 403],
    EVENTS_TOGGLE_ON: [200, 403, 404],
    EVENTS_TOGGLE_OFF: [200, 403, 404],
    EVENTS_REMOVE_QUERYPAGES: [200, 403, 404],
    EVENTS_PROJECT_ADD: [200, 403, 404],
    EVENTS_REMOVE: [200, 403, 404],
    EVENTS_ITEM: [200, 403, 404],
    QUERYPAGES: [200, 403],
    QUERYPAGES_ITEM: [200, 403, 404],
    QUERYPAGES_BY_PROJECT: [200, 403, 404],
    QUERYPAGES_BY_PROJECT_WITHOUT_POSITIONS: [200, 403, 404],
    QUERYPAGES_POSITIONS: [200, 403, 404],
    QUERYPAGES_POSITIONS_BY_DATE: [200, 403, 404],
    QUERYPAGES_POSITIONS_BY_EVENT: [200, 403, 404],
    QUERYPAGES_URLS: [200, 403, 404],
    PROJECTS: [200, 403],
    PROJECTS_ITEM: [200, 403, 404],
    PROJECTS_PAG: [200, 403, 404],
    WORDSTAT_SCRIPT_STATUS: [200, 403],
    WORDSTAT_SCRIPT_RUN: [200, 403],
  },

  CONTENTPLAN: {
    PROJECTS: [200, 403],
    PROJECTS_ITEM: [200, 403, 404],
    PROJECTS_ITEM_DETAIL: [200, 403, 404],
    PROJECTS_ITEM_FETCH: [200, 403, 404],
    EVENTS: [200, 403, 500],
    EVENTS_ITEM: [200, 403, 404],
  },

  PROXY: {
    PROXIES: [200, 403],
    LIST: [200, 403],
    CREATE_PROXIES: [200, 403],
  },

  INVITATION_SYSTEM: {
    GENERATE_INVITATION_LINK: [200, 403],
    REGISTER_VIA_INVITATION: [200, 403],
  },

  ACCOUNTS: {
    GET_WEB_RESOURCES: [200, 403],
    GET_RESOURCE_ACCOUNTS: [200, 403],
  },
};
