import cn from "classnames";
import React from "react";
import style from "./index.module.scss";
import { SettingsPageTab } from "./types";
import { tabKindToString } from "./util";

interface TabsProps {
  currentTab: SettingsPageTab;
  onChange: (t: SettingsPageTab) => void;
}

interface TabProps {
  currentTab: SettingsPageTab;
  onChange: (t: SettingsPageTab) => void;
  id: SettingsPageTab;
}

const Tab = (props: TabProps) => {
  const name = tabKindToString(props.id);
  const active = props.id === props.currentTab;
  return (
    <div
      className={cn({ [style.tab]: true, [style.tab_active]: active })}
      onClick={() => props.onChange(props.id)}
    >
      {name}
    </div>
  );
};

const Tabs = (props: TabsProps) => {
  const commonTabProps = {
    currentTab: props.currentTab,
    onChange: props.onChange,
  };
  const propsForTab = (kind: SettingsPageTab) => ({
    key: kind,
    id: kind,
  });
  return (
    <div className={style.tabs}>
      <Tab {...commonTabProps} {...propsForTab(SettingsPageTab.GENERAL)} />
      <Tab {...commonTabProps} {...propsForTab(SettingsPageTab.SECURITY)} />
      <Tab {...commonTabProps} {...propsForTab(SettingsPageTab.NOTIFICATIONS)} />
    </div>
  );
};

export default Tabs;
