import React from "react";
import { DropdownToggle } from "reactstrap";
import style from "./index.module.scss";

const MenuToggleButton = (props: { unreadNotifications: number }) => (
  <DropdownToggle tag="span">
    <span className="header-navbar-icon">
      {props.unreadNotifications > 0 && (
        <span className={style["notification-menu-button__status-circle"]}>
          {props.unreadNotifications}
        </span>
      )}
      <i className="fas fa-bell" />
    </span>
  </DropdownToggle>
);

export default MenuToggleButton;
