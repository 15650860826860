import { canUser } from "#/permissions";
import { ChatMessage, CRMTask, SendTaskChatMessage, User } from "#/store/types";
import { handleDispatchErrorAndDisplayToast } from "#/util";
import _ from "lodash";
import React, { useCallback, useMemo, useState } from "react";

const AddChatMessage = (props: {
  currentUser: User | null;
  task: CRMTask;
  sendTaskMessage: SendTaskChatMessage;
}) => {
  const canUserSendMessages = useMemo(
    () =>
      canUser(props.currentUser, "ADD_MESSAGES", "TASK", {
        obj: props.task,
      }),
    [props.task, props.currentUser],
  );

  const [chatMessage, setChatMessage] = useState<Partial<ChatMessage>>({ content: "" });
  const updateChatMessageContent = useCallback(
    (value: string) => setChatMessage({ ...chatMessage, content: value }),
    [chatMessage],
  );

  // On new message submit
  const onNewMessageSubmit = useCallback(() => {
    if (_.isUndefined(chatMessage.content) || chatMessage.content.length === 0) { return; }
    if (!canUserSendMessages) { return; }
    props
      .sendTaskMessage(props.task.pk, chatMessage)
      .then(() => {
        setChatMessage({ content: "" });
      })
      .catch(handleDispatchErrorAndDisplayToast);
  }, [chatMessage, canUserSendMessages, props.sendTaskMessage, props.task]);

  if (!canUserSendMessages) { return null; }

  return (
    <form
      className="position-relative w-100"
      onSubmit={(e: any) => {
        e.preventDefault();
        onNewMessageSubmit();
      }}
    >
      <input
        className="form-control"
        placeholder="Введите сообщение..."
        onChange={e => updateChatMessageContent(e.target.value)}
        value={chatMessage.content}
      />
      <button type="submit" className="btn send">
        <i className="fas fa-paper-plane" />
      </button>
    </form>
  );
};

export default AddChatMessage;
