import React, { useState, useCallback } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { getSelectedAccounts, getCurrentSite, removeAccounts } from "#/store/modules/accounts";
import { connect } from "react-redux";
import { Account, AccountsSite } from "#/store/modules/accounts/types";
import { downloadBlob } from "#/util";
import { showErrorToast } from "#/components/toasts";
import { DispatchProp, StoreRootState } from "#/store/types";

interface MenuOwnProps {}

interface MenuConnectedProps {
  selection: Account[];
  site: AccountsSite;
  removeAccounts: DispatchProp<typeof removeAccounts>;
}

type MenuProps = MenuConnectedProps & MenuOwnProps;

const Menu = (props: MenuProps) => {
  const [modal, setModal] = useState(false);
  const formatAccounts = useCallback(() => {
    if (!props.selection.length) {
      return;
    }
    const formatProperty = (acc: any, key: any, value: any) => acc + `${key}:${value};`;
    const textResult = props.selection.reduce((str, acc) => {
      const { site, balance_set, ...accountData } = acc;
      const orderedObjects = [accountData, balance_set];
      orderedObjects.forEach((obj: any) => {
        Object.entries(obj).forEach(([key, value]) => {
          str = formatProperty(str, key, value);
        });
      });
      return str + "\n";
    }, "");

    const currentdate = new Date();
    const filename = `${props.site ? props.site.name : "Unknown site"} ${
      props.selection.length
    } accs ${currentdate.getDate()}/${currentdate.getMonth() +
      1}/${currentdate.getFullYear()} @ ${currentdate.getHours()}:${currentdate.getMinutes()}:${currentdate.getSeconds()}.txt`;
    downloadBlob(filename, textResult);
  }, [props.selection, props.site]);

  const unloadAccounts = useCallback(() => {
    formatAccounts();
    props.site
      ? props.removeAccounts(
          props.selection.map(acc => acc.pk),
          props.site.pk,
        )
      : showErrorToast("Неизвестная ошибка, нету сайта");
  }, [props.selection, props.site]);

  return (
    <>
      <div>
        <button
          disabled={!props.selection.length}
          className="btn btn-list ml-2"
          onClick={formatAccounts}
        >
          <i className="fas fa-download" />

          <span className="ml-2">Скачать выбранные аккаунты</span>
        </button>
        <button
          disabled={!props.selection.length}
          className="btn btn-list ml-2"
          onClick={() => setModal(true)}
        >
          <i className="fas fa-dolly-flatbed" />

          <span className="ml-2">Выгрузить выбранные аккаунты</span>
        </button>
      </div>
      <Modal isOpen={modal} toggle={() => setModal(e => !e)} contentClassName="alert alert-primary">
        <ModalHeader>Подтвердите действие</ModalHeader>
        <ModalBody>
          Продолжив, вы скроете аккаунты. Их все еще можно будет найти вручную в базе, но их больше
          нельзя будет получить обычным путем.
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => {
              unloadAccounts();
              setModal(false);
            }}
          >
            Скрыть аккаунты
          </Button>
          <Button className="ml-2" color="secondary" onClick={() => setModal(false)}>
            Отмена
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default connect(
  (state: StoreRootState) => ({
    selection: getSelectedAccounts(state, null),
    site: getCurrentSite(state, null),
  }),
  {
    removeAccounts,
  },
)(Menu);
