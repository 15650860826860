import { Entity } from "#/store/types";
import { useDebouncedInputChangeValueUpdater } from "#/util/hooks";
import assert from "assert";
import classnames from "classnames";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownProps,
  DropdownToggle,
  DropdownToggleProps,
  Input,
} from "reactstrap";
import style from "./index.module.scss";

export type FetchHook = (minimal: boolean) => [any[], [string, (v: string) => void]];

type EntityDropdownProps = Omit<DropdownProps, "onClick"> & {
  onClick: (user: Entity) => void;
  className?: string;
  entityDisplayFunction: (entity: Entity) => any;
  filterFunction?: (entity: Entity) => boolean;
  entityFetchWithQueryHook: FetchHook;
  toggleInnerContent: () => React.ReactNode;
  noItemsMessage: string;
  toggle: () => void;
  isOpen: boolean;
  dropdownToggleProps?: DropdownToggleProps;
};

const EntityDropdown = (props: EntityDropdownProps) => {
  const [entities, [, setSearchQuery]] = props.entityFetchWithQueryHook(true);
  const searchQueryRef = useRef<HTMLInputElement>(null);
  const onSearchQueryChange = useDebouncedInputChangeValueUpdater(
    setSearchQuery,
    searchQueryRef,
    1000,
  );
  const [focused, setFocused] = useState<boolean>(false);

  useEffect(() => {
    if (props.isOpen) {
      assert(searchQueryRef.current);
      searchQueryRef.current!.focus();
    }
  }, [props.isOpen]);

  return (
    <div className={style["dropdown-container"]}>
      <Dropdown
        isOpen={props.isOpen}
        toggle={props.toggle}
        className={classnames({
          [style.dropdown]: true,
          [style.dropdown_shown]: props.isOpen,
          ...(props.className ? { [props.className]: true } : {}),
        })}
        {..._.omit(props, [
          "className",
          "onClick",
          "entityDisplayFunction",
          "filterFunction",
          "entityFetchWithQueryHook",
          "dropdownToggleClassname",
          "toggleInnerContent",
          "noItemsMessage",
          "toggle",
          "isOpen",
        ])}
      >
        <DropdownToggle {...(props.dropdownToggleProps ? props.dropdownToggleProps : {})}>
          {props.toggleInnerContent()}
        </DropdownToggle>
        <DropdownMenu className={style["dropdown-menu"]}>
          <div className={style["search-container"]}>
            <Input
              innerRef={searchQueryRef}
              placeholder="Поиск"
              onChange={onSearchQueryChange}
              readOnly={!focused}
              className={style["search-input"]}
              onFocus={_.partial(_.delay, () => setFocused(true), 5)}
              onBlur={_.partial(setFocused, false)}
            />
          </div>
          <div className={style["items-container"]}>
            {entities.length === 0 ? (
              <div className={style["no-items-message"]}>{props.noItemsMessage}</div>
            ) : (
              (() => {
                const entities_ = props.filterFunction
                  ? entities.filter(props.filterFunction)
                  : entities;
                return entities_.map((el, i) => (
                  <DropdownItem
                    key={i}
                    className={style["dropdown-item"]}
                    onClick={_.partial(props.onClick, el)}
                  >
                    {props.entityDisplayFunction(el)}
                  </DropdownItem>
                ));
              })()
            )}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default EntityDropdown;
