import { MonitoringProjectUrl, PrimaryKey } from "#/store/types";
import Switch from "rc-switch";
import React from "react";

interface OwnProps {
  setChecked: (pk: PrimaryKey) => void;
  toggleUrl: (pks: PrimaryKey, action: "0" | "1") => void;
  checked: boolean;
  toggling: boolean;
  u: MonitoringProjectUrl;
}

type Props = OwnProps;

const UrlItem = (props: Props) => {
  const toggleChecked = () => {
    props.setChecked(props.u.pk);
  };

  const toggleUrl = () => {
    const action = props.u.check ? "0" : "1";
    props.toggleUrl(props.u.pk, action);
  };

  return (
    <tr>
      <td>
        <span className="table-checkbox">
          <label className="table-checkbox-label">
            <input
              type="checkbox"
              value="0"
              checked={props.checked}
              onChange={toggleChecked}
              disabled={false}
            />
            &nbsp;
            <span />
          </label>
        </span>
      </td>
      <td>
        <a target="_blank" rel="noopener noreferrer" href={props.u.name}>
          {props.u.name}
        </a>
      </td>
      <td>
        <Switch onChange={toggleUrl} disabled={props.toggling} checked={props.u.check} />
      </td>
    </tr>
  );
};

export default UrlItem;
