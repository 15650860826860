import projectModule from "#/store/modules/project";
import tasksModule from "#/store/modules/tasks";
import { CompleteTaskData, CRMTask, PrimaryKey, TaskData, TasksById } from "#/store/types";
import { compareTwoNumbers } from "#/util";
import _ from "lodash";
import { createSelector } from "reselect";

const getCurrentProjectTasksById = createSelector(
  [tasksModule.selectors.getTasksById, projectModule.selectors.getCurrentProjectId],
  (tid: TasksById, cpid: PrimaryKey | null) =>
    cpid
      ? _.pickBy(tid, (t: TaskData) => {
          return (t as any).data && (t as any).data.project === cpid;
        })
      : {},
);

const getCurrentProjectTasks = createSelector(
  [getCurrentProjectTasksById],
  (tasksById: TasksById): CompleteTaskData[] => {
    return (_.compact(
      _.keys(tasksById).map((k: string) => _.get(tasksById, k, null)),
    ) as any) as CompleteTaskData[];
  },
);

const getCurrentProjectTasksSortedByPriority = createSelector(
  [getCurrentProjectTasks],
  (tasks: CompleteTaskData[]) =>
    tasks.sort((a: CompleteTaskData, b: CompleteTaskData) =>
      compareTwoNumbers(a.data.priority, b.data.priority),
    ),
);

const getCurrentProjectActiveTasksCount = createSelector(
  [getCurrentProjectTasksById],
  tbid => _.keys(tbid).length,
);

export default {
  getCurrentProjectTasks,
  getCurrentProjectTasksById,
  getCurrentProjectTasksSortedByPriority,
  getCurrentProjectActiveTasksCount,
};
