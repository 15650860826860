import { ChatMessage, User } from "#/store/types";
import { falseIfUndefinedElseBoolean } from "#/util";
import cn from "classnames";
import moment from "moment";
import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import style from "../../index.module.scss";

interface ChatItemConnectedProps {}

interface ChatItemOwnProps {
  isPreviousMessageForeign: boolean;
  isForeign: boolean;
  item: ChatMessage;
  user: User | null;
  topSentinel?: boolean;
  onSentinelInView?: (ref: Element) => void;
}

type ChatItemProps = ChatItemConnectedProps & ChatItemOwnProps;

const ChatItem = (props: ChatItemProps) => {
  const { isPreviousMessageForeign, isForeign, item } = props;
  const [ref, inView, entry] = useInView();

  useEffect(() => {
    if (inView && falseIfUndefinedElseBoolean(props.topSentinel)) {
      props.onSentinelInView!(entry!.target);
    }
  }, [inView]);

  const showAvatar =
    (isPreviousMessageForeign && !isForeign) || (!isPreviousMessageForeign && isForeign);

  return (
    <div
      className={cn({
        [style.message]: true,
        me: !isForeign,
      })}
      ref={ref}
    >
      {showAvatar && (
        <div className={style.message__avatar}>
          <span className="k-badge k-badge--username k-badge--lg k-badge--brand">
            {props.user ? props.user.first_name.charAt(0) : "N/A"}
          </span>
        </div>
      )}
      <div className={style["text-main"]}>
        <div
          className={cn({
            [style["text-group"]]: true,
            me: !isForeign,
          })}
        >
          <div className={cn({ text: true, me: !isForeign })}>
            <p>{item.content}</p>
          </div>
        </div>
        <span>{(moment as any)(item.date_create).format("HH:mm:ss, DD.MM.YYYY")}</span>
      </div>
    </div>
  );
};

export default ChatItem;
