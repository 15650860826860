import { showErrorToast } from "#/components/toasts";
import { PrimaryKey } from "#/store/types";
import { SimpleFunction } from "#/types";
import React, { useContext, useMemo, useState, useCallback } from "react";
import { Button, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useEventFullData } from "../../../../../hooks";
import ProjectContext, { IProjectContext } from "../../../ProjectContext";
import EventSelect from "../../../../EventSelect";
import _ from "lodash";
import ControllableConfigurationSelect from "../../../../ConfigurationsSelect/ControllableConfigurationsSelect";

export default (props: { isOpen: boolean; toggle: SimpleFunction }) => {
  // Project context data
  const projectContext = useContext(ProjectContext) as IProjectContext;

  // Selected event
  const [selectedEventID, setSelectedEventID] = useState<PrimaryKey | null>(null);
  const selectedEvent = useEventFullData(selectedEventID, [selectedEventID]);

  // Selected configuration
  const [selectedConfigurationID, setSelectedConfigurationID] = useState<PrimaryKey | null>(null);

  // Event import preview
  const eventImportPreview = useMemo(() => {
    if (!selectedEvent.data) {
      return "";
    }
    return selectedEvent.data.queries.map(q => q.text).join("\n");
  }, [selectedEvent]);

  // Submit
  const submit = useCallback(() => {
    if (selectedEventID && selectedConfigurationID) {
      projectContext.addGroupsToProject([selectedEventID], selectedConfigurationID);
    } else if (!selectedEventID) {
      showErrorToast("Необходимо выбрать группу запросов для импорта");
    } else if (!selectedConfigurationID) {
      showErrorToast("Необходимо выбрать нужную конфигурацию.");
    }
  }, [projectContext, selectedEventID, selectedConfigurationID]);

  const configsFilter = React.useCallback(c => _.includes(projectContext.supportedConfigs, c.pk), [
    projectContext.supportedConfigs,
  ]);

  const eventsFilter = e => {
    return (
      !e.expired! &&
      !_.includes(
        projectContext.projectGroups.map(gg => gg.pk),
        e.pk,
      )
    );
  };

  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      className="modal-dialog-centered"
      contentClassName="alert alert-primary"
    >
      <ModalHeader>Прикрепить группу запрсов к проекту</ModalHeader>
      <form
        onSubmit={e => {
          e.preventDefault();
          submit();
        }}
      >
        <ModalBody>
          {/* Event select */}
          <FormGroup>
            <label className="my-2">Выберите группу запросов</label>
            <EventSelect
              filter={eventsFilter}
              selected={selectedEventID}
              onChange={pk => setSelectedEventID(pk)}
            />
          </FormGroup>
          {/* Event configuration */}
          <FormGroup>
            <label className="my-2">Выберите конфигурацию</label>
            <ControllableConfigurationSelect
              filter={configsFilter}
              value={selectedConfigurationID}
              onChange={c => setSelectedConfigurationID(c)}
            />
          </FormGroup>
          {/* Import event preview */}
          <FormGroup>
            <label htmlFor="top50_requests_modal">Предпросмотр запросов</label>
            <textarea
              value={eventImportPreview}
              className="form-control col-12"
              disabled={true}
              rows={8}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" color="success" disabled={projectContext.fetching}>
            {projectContext.fetching ? "Подождите..." : "Добавить"}
          </Button>
          <Button color="secondary" onClick={props.toggle}>
            Отмена
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
