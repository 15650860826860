import { apiGet, apiGetPaginated } from "#/api";
import { API_ROUTES } from "#/conf/api";
import { GetState, PaginationCursor, SearchParams } from "#/store/types";
import { Dispatch } from "redux";
import MutationType from "./mutationType";

const makeSearchRequest = (
  query: string | null,
  searchParams: SearchParams,
  nextCursor: PaginationCursor,
) => (dispatch: Dispatch, getState: GetState) => {
  return apiGetPaginated(
    API_ROUTES.CRMPLAN.SEARCH,
    null,
    nextCursor,
    {
      kind: searchParams.kind,
      ...(query ? { query } : {}),
    },
    { encodeQueryParams: true },
  );
};

const retreiveTagSuggesstions = (text: string | null) => (dispatch: Dispatch, getState: GetState) =>
  apiGet(API_ROUTES.CRMPLAN.TAG_AUTOCOMPLETE, {
    ...(text ? { text } : {}),
  });

const setQuery = (query: string | null) => (dispatch: Dispatch, getState: GetState) =>
  dispatch({ type: MutationType.SET_SEARCH_QUERY, payload: { query } });

export default { makeSearchRequest, retreiveTagSuggesstions, setQuery };
