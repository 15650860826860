import classnames from "classnames";
import React, { useRef } from "react";
import style from "./index.module.scss";

export interface CopyInputProps {
  className: string;
  value: string | null;
}

const CopyInput = (props: CopyInputProps) => {
  const inputElem = useRef<HTMLTextAreaElement>(null);
  const { className, value } = props;
  const actualValue = value ? value : "";

  const copyText = () => {
    if (inputElem && inputElem.current) {
      inputElem.current.select();
      document.execCommand("copy");
    }
  };

  return (
    <div className={style["copy-input"]}>
      <textarea
        className={`${style["copy-input__textarea"]} ${className}`}
        value={actualValue}
        ref={inputElem}
        readOnly={true}
      />
      <button
        className={classnames([style["copy-input__button"], "btn"])}
        title="Copy"
        onClick={copyText}
      >
        <i className="far fa-copy" />
      </button>
    </div>
  );
};

export default CopyInput;
