import { createReducer } from "#/store/helpers";
import {
  ChatMessage,
  CRMTask,
  PaginationCursor,
  PrimaryKey,
  TaskCheckListItem,
  TaskData,
  TasksState,
} from "#/store/types";
import assert from "assert";
import _ from "lodash";
import { Action } from "redux";
import ActionTypes from "./actionTypes";
import MutationType from "./mutationType";

const initialTaskData: TaskData = {
  data: null,
  editing: false,
  editingFailed: false,
  checklists: [],
  chat: [],
  chatNext: null,
  chatPrev: null,
};

const initialState: TasksState = {
  tasksById: {},
  fetchingTasks: false,
  fetchingTasksFailed: false,
  removingTask: false,
  removingTaskFailed: false,
};

export const reducer = createReducer<TasksState, Action>(
  {
    // Create task
    [MutationType.CREATE_TASK_SUCCEED]: (
      state: TasksState,
      action: ActionTypes.CreateTaskSucceed,
    ) => ({
      ...state,
      tasksById: {
        ...state.tasksById,
        [action.payload.task.pk]: {
          ...initialTaskData,
          data: action.payload.task,
        },
      },
    }),

    // Remove task
    [MutationType.REMOVE_TASK_SUCCEED]: (
      state: TasksState,
      action: ActionTypes.RemoveTaskSucceed,
    ) => ({
      ...state,
      tasksById: _.pickBy(state.tasksById, (a: TaskData) =>
        a.data ? a.data.pk !== action.payload.id : true,
      ),
    }),

    // Edit task
    [MutationType.EDIT_TASK_SUCCEED]: (state: TasksState, action: any) => {
      const oldTaskData = _.get(state.tasksById, action.payload.id, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.id]: {
            ...oldTaskData,
            data: {
              ..._.get(oldTaskData, "data", {}),
              ...action.payload.task,
            },
          },
        },
      };
    },

    // Fetch task
    [MutationType.FETCH_TASK_SUCCEED]: (
      state: TasksState,
      action: ActionTypes.FetchTaskSucceed,
    ) => ({
      ...state,
      tasksById: {
        ...state.tasksById,
        [action.payload.id]: {
          ...initialTaskData,
          checklists: (action.payload.task as any).task_checklists,
          data: action.payload.task,
        },
      },
    }),

    // Fetch tasks
    [MutationType.FETCH_TASKS_SUCCEED]: (
      state: TasksState,
      action: ActionTypes.FetchTasksSucceed,
    ) => {
      const additionalTasks = _.chain(
        action.payload.tasks
          .filter((task: CRMTask) => _.get(state.tasksById, task.pk, null) === null)
          .map((task: CRMTask) => ({
            ...initialTaskData,
            checklists: (task as any).task_checklists,
            data: { ...task },
          })),
      )
        .keyBy("data.pk")
        .value();
      return {
        ...state,
        fetchingTasks: false,
        fetchingTasksFailed: false,
        tasksById: {
          ...state.tasksById,
          ...additionalTasks,
        },
      };
    },

    // Add task checklist item
    [MutationType.ADD_TASK_CHECKLIST_ITEM_SUCCEED]: (
      state: TasksState,
      action: ActionTypes.AddTaskChecklistItemSucceed,
    ) => {
      const oldTaskData = _.get(state.tasksById, action.payload.id, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.id]: {
            ...oldTaskData,
            checklists: [
              ...(action.payload.checklist ? action.payload.checklist : oldTaskData.checklists),
            ],
          },
        },
      };
    },

    // Remove task checklist item
    [MutationType.REMOVE_TASK_CHECKLIST_ITEM_SUCCEED]: (state: TasksState, action: any) => {
      const oldTaskData = _.get(state.tasksById, action.payload.taskId, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.taskId]: {
            ...oldTaskData,
            checklists: _.get(oldTaskData, "checklists", []).filter(
              (a: TaskCheckListItem) => a.pk !== action.payload.itemId,
            ),
          },
        },
      };
    },

    // Edit task checklist item
    [MutationType.EDIT_TASK_CHECKLIST_ITEM_SUCCEED]: (state: TasksState, action: any) => {
      if (!action.payload) {
        console.warn("No payload provided for taskChecklistItemEdit action");
        return state;
      }
      const oldTaskData = _.get(state.tasksById, action.payload.id, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.id]: {
            ...oldTaskData,
            checklists: [
              ..._.get(oldTaskData, "checklists", []).filter(
                (a: TaskCheckListItem) => a.pk !== action.payload.item.pk,
              ),
              action.payload.item,
            ],
          },
        },
      };
    },

    [MutationType.ADD_TASK_CHAT_MESSAGE]: (
      state: TasksState,
      action: { payload: { taskId: PrimaryKey; msg: ChatMessage } },
    ) => {
      const msg = action.payload.msg;
      assert(msg);
      const taskId = action.payload.taskId;
      assert(taskId);
      const oldTaskData = _.get(state.tasksById, taskId, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [taskId]: {
            ...oldTaskData,
            chat: [..._.get(oldTaskData, "chat", []), msg],
          },
        },
      };
    },

    [MutationType.FETCH_TASK_CHAT_MESSAGES_SUCCEED]: (
      state: TasksState,
      action: {
        payload: {
          taskId: PrimaryKey;
          messages: ChatMessage[];
          next: PaginationCursor;
          prev: PaginationCursor;
        };
      },
    ) => {
      const taskId = action.payload.taskId;
      assert(taskId);
      const oldTaskData = _.get(state.tasksById, taskId, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [taskId]: {
            ...oldTaskData,
            chat: [
              ...oldTaskData.chat,
              ...action.payload.messages.filter(
                msg => !_.some(oldTaskData.chat, msg2 => msg2.pk === msg.pk),
              ),
            ],
            chatNext: action.payload.next,
            chatPrev: action.payload.prev,
          },
        },
      };
    },

    [MutationType.SET_TASK_SUBSCRIBED]: (
      state: TasksState,
      action: { payload: { id: PrimaryKey; subscribed: boolean } },
    ) => {
      const oldTaskData = _.get(state.tasksById, action.payload.id, initialTaskData);
      return {
        ...state,
        tasksById: {
          ...state.tasksById,
          [action.payload.id]: {
            ...oldTaskData,
            data: {
              ..._.get(oldTaskData, "data", {}),
              subscribed: action.payload.subscribed,
            } as any,
          },
        },
      };
    },

    [MutationType.FETCH_TASKS_TO_USER_START]: (state: TasksState, action: {}) => {
      return {
        ...state,
        fetchingTasks: true,
      };
    },

    [MutationType.FETCH_TASKS_FROM_USER_START]: (state: TasksState, action: {}) => {
      return {
        ...state,
        fetchingTasks: true,
      };
    },
  },
  initialState,
);

export default reducer;
