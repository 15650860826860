import FAIconText from "#/components/FAIconText";
import { showSuccessToast } from "#/components/toasts";
import cn from "classnames";
import React, { useContext, useMemo } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import ProjectContext from "../ProjectContext";

const ImportURLsButton = () => {
  const projectContext = useContext(ProjectContext);
  const urlsText = useMemo(() => projectContext.urls.map(u => u.name).join("\n"), [
    projectContext.urls,
  ]);
  return (
    <div className={""}>
      <CopyToClipboard
        text={urlsText}
        onCopy={() => showSuccessToast("URL конфигурация была скопирована в буфер обмена")}
      >
        <button
          className={cn({
            btn: true,
            "btn-list": true,
          })}
          title={"Скопировать текст URL конфигурации проекта"}
        >
          <FAIconText type="fas" name="copy" text="Скопировать URL конфиг" />
        </button>
      </CopyToClipboard>
    </div>
  );
};

export default ImportURLsButton;
