import React from "react";
import Header from "./components/Header";
import Page from "#/components/Page";
interface WrapperOwnProps {
  title: string;
  headerName: string;
  children: JSX.Element;
  headerLinks?: Array<[string, string]>;
}

interface WrapperConnectedProps {}

type WrapperProps = WrapperOwnProps & WrapperConnectedProps;

const Wrapper = (props: WrapperProps) => {
  return (
    <Page title={props.title}>
      <>
        <Header
          headerName={props.headerName}
          links={props.headerLinks ? props.headerLinks : undefined}
        />
        <section className="content" style={{ width: "100%" }}>
          <div className="row">
            <div className="col-12">
              <div className="box">{props.children}</div>
            </div>
          </div>
        </section>
      </>
    </Page>
  );
};

export default Wrapper;
