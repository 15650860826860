import { SearchKind } from "#/store/types";
import cn from "classnames";
import React from "react";
import { Button } from "reactstrap";
import style from "../index.module.scss";
import { SetCurrentSearchKind } from "../types";

interface SearchKindsBlockTabProps {
  name: string;
  id: SearchKind;
  setCurrentSearchKind: SetCurrentSearchKind;
  currentSelectedSearchKind: SearchKind;
}

const SearchKindBlockTab = (props: SearchKindsBlockTabProps) => {
  const active = props.currentSelectedSearchKind === props.id;
  return (
    <Button
      className={cn({
        [style["search-kinds-block__item"]]: true,
        [style["search-kinds-block__item_active"]]: active,
      })}
      onClick={() => props.setCurrentSearchKind(props.id)}
      color="transparent"
    >
      {props.name}
    </Button>
  );
};

interface SearchKindsBlockProps {
  setCurrentSearchKind: SetCurrentSearchKind;
  currentSelectedSearchKind: SearchKind;
}

const SearchKindsBlock = (props: SearchKindsBlockProps) => {
  const commonProps = {
    setCurrentSearchKind: props.setCurrentSearchKind,
    currentSelectedSearchKind: props.currentSelectedSearchKind,
  };
  return (
    <div className={style["search-kinds-block"]}>
      <SearchKindBlockTab {...commonProps} name="Все" id={SearchKind.ALL} />
      <SearchKindBlockTab {...commonProps} name="Пользователи" id={SearchKind.USERS} />
      <SearchKindBlockTab {...commonProps} name="Проекты" id={SearchKind.PROJECTS} />
      <SearchKindBlockTab {...commonProps} name="Задачи" id={SearchKind.TASKS} />
    </div>
  );
};

export default SearchKindsBlock;
