import { createReducer } from "#/store/helpers";
import { SearchState } from "#/store/types";
import MutationType from "./mutationType";

const initialState: SearchState = {
  query: null,
};

export const reducer = createReducer(
  {
    // Fetch single project
    [MutationType.SET_SEARCH_QUERY]: (
      state: SearchState,
      action: { payload: { query: string } },
    ) => ({
      ...state,
      query: action.payload.query,
    }),
  },
  initialState,
);

export default reducer;
