import EntityDropdown from "#/components/EntityDropdown";
import { CRMProject, Entity } from "#/store/types";
import { projectName } from "#/util";
import { useLocalProjectsWithQuery } from "#/util/dataHooks";
import React from "react";
import { DropdownProps, DropdownToggleProps } from "reactstrap";

type ProjectDropdownProps = Omit<DropdownProps, "onClick"> & {
  onClick: (project: CRMProject) => void;
  filterFunction?: (project: CRMProject) => boolean;
  toggleInnerContent: () => any;
  toggle: () => void;
  isOpen: boolean;
  dropdownToggleProps?: DropdownToggleProps;
};

const ProjectDropdown = (props: ProjectDropdownProps) => {
  return (
    <EntityDropdown
      {...props}
      isOpen={props.isOpen}
      toggle={props.toggle}
      noItemsMessage="Нет проектов"
      onClick={(props.onClick as any) as (p: Entity) => void}
      filterFunction={(props.filterFunction as any) as (p: Entity) => boolean}
      entityDisplayFunction={(project_: any) => {
        const project = project_ as CRMProject;
        return projectName(project);
      }}
      entityFetchWithQueryHook={useLocalProjectsWithQuery}
      toggleInnerContent={props.toggleInnerContent}
    />
  );
};

export default ProjectDropdown;
