import withAuthentication, { UserAuthenticationStatus } from "#/components/withAuthentication";
import unescape from "lodash/unescape";
import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import changelogItems from "./changelog.json";
import "./index.scss";

interface ChangelogConnectedProps {}

interface ChangelogOwnProps {}

type ChangelogProps = ChangelogConnectedProps & ChangelogOwnProps;

interface ChangelogItem {
  title: string;
  content: Array<{ text: string; image?: string }>;
}

const Changelog = (props: ChangelogProps) => {
  const itemsToRender = changelogItems.map((item: ChangelogItem, idx: number) => (
    <TransitionGroup key={idx}>
      <CSSTransition
        classNames="mask"
        appear={true}
        timeout={{
          enter: 0,
          exit: 300,
        }}
      >
        <div className={"card " + (idx > 0 ? "mt-2" : undefined)}>
          <div className="card-body">
            <h6 className="changelog-headline">{item.title}</h6>
            <ul
              style={{
                listStylePosition: "unset",
                paddingInlineStart: "40px",
              }}
            >
              {item.content.map((el, i) => (
                <li className="changelog-item" key={i}>
                  <div dangerouslySetInnerHTML={{ __html: unescape(el.text) }} />
                  {el.image && (
                    <img
                      src={el.image}
                      alt="Item"
                      style={{ maxHeight: "400px", display: "block" }}
                    />
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </CSSTransition>
    </TransitionGroup>
  ));

  return <div className="p-4">{itemsToRender}</div>;
};

export default withAuthentication(UserAuthenticationStatus.AUTHENTICATED)(Changelog);
