import React from "react";

interface ProjectOwnProps {
  match: any;
}

interface ProjectConnectedProps {}

type ProjectProps = ProjectOwnProps & ProjectConnectedProps;

const Project = (props: ProjectProps) => {
  return <div>{props.match.params.projectPk}</div>;
};

export default Project;
