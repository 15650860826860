import Editor from "#/components/Editor";
import { getActionsFromModule } from "#/store/helpers";
import sessionUsersModule from "#/store/modules/sessionUsers";
import tasksModule from "#/store/modules/tasks";
import { editTask } from "#/store/modules/tasks/actions";
import { CRMTask, DispatchProp, StoreRootState, TaskStatus, User } from "#/store/types";
import {
  CODE_NON_REACHABLE,
  handleDispatchErrorAndDisplayToast,
  taskStatus,
  taskStatusToString,
  undefinedOr,
} from "#/util";
import assert from "assert";
import classnames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import OnEnter from "../OnEnter";
import style from "./index.module.scss";

const nextStatus = (user: User, task: CRMTask): TaskStatus[] => {
  enum PermissionClass {
    EXECUTOR = 0,
    MANAGER = 1,
    BOTH = 2,
  }

  const perm_class_map = {
    [PermissionClass.EXECUTOR]: {
      [TaskStatus.CREATED]: [TaskStatus.ONGOING],
      [TaskStatus.ONGOING]: [TaskStatus.TEST, TaskStatus.DELAYED],
      [TaskStatus.REVISION]: [TaskStatus.ONGOING, TaskStatus.DELAYED, TaskStatus.TEST],
      [TaskStatus.TEST]: [TaskStatus.ONGOING, TaskStatus.DELAYED],
      [TaskStatus.DELAYED]: [TaskStatus.ONGOING, TaskStatus.TEST],
    },
    [PermissionClass.MANAGER]: {
      [TaskStatus.CREATED]: [TaskStatus.DELAYED, TaskStatus.FINISHED],
      [TaskStatus.ONGOING]: [TaskStatus.DELAYED, TaskStatus.FINISHED],
      [TaskStatus.REVISION]: [TaskStatus.DELAYED, TaskStatus.FINISHED],
      [TaskStatus.DELAYED]: [TaskStatus.ONGOING, TaskStatus.FINISHED, TaskStatus.REVISION],
      [TaskStatus.TEST]: [TaskStatus.FINISHED, TaskStatus.REVISION],
      [TaskStatus.FINISHED]: [TaskStatus.REVISION],
    },
    [PermissionClass.BOTH]: {
      [TaskStatus.CREATED]: [TaskStatus.ONGOING, TaskStatus.DELAYED, TaskStatus.FINISHED],
      [TaskStatus.ONGOING]: [TaskStatus.TEST, TaskStatus.DELAYED, TaskStatus.FINISHED],
      [TaskStatus.REVISION]: [
        TaskStatus.DELAYED,
        TaskStatus.FINISHED,
        TaskStatus.ONGOING,
        TaskStatus.TEST,
      ],
      [TaskStatus.TEST]: [
        TaskStatus.FINISHED,
        TaskStatus.REVISION,
        TaskStatus.ONGOING,
        TaskStatus.DELAYED,
      ],
      [TaskStatus.DELAYED]: [TaskStatus.FINISHED, TaskStatus.ONGOING, TaskStatus.TEST],
      [TaskStatus.FINISHED]: [TaskStatus.REVISION, TaskStatus.ONGOING, TaskStatus.TEST],
    },
  };

  const user_perm_map = (() => {
    if (user.super_admin || (task.task_to === user.pk && task.task_from === user.pk)) {
      return perm_class_map[PermissionClass.BOTH];
    }
    if (task.task_from === user.pk) {
      return perm_class_map[PermissionClass.MANAGER];
    }
    if (task.task_to === user.pk) {
      return perm_class_map[PermissionClass.EXECUTOR];
    }
    return {};
  })();

  assert(!!user_perm_map);

  const currentStatusAvailableTransitions = _.get(user_perm_map, task.status, []) as TaskStatus[];

  return currentStatusAvailableTransitions;
};

const classNameFromStatus = (status: TaskStatus) => {
  switch (status) {
    case TaskStatus.CREATED:
      return "label-warning";
    case TaskStatus.ONGOING:
      return "label-inverse";
    case TaskStatus.TEST:
      return "label-inverse-simple";
    case TaskStatus.REVISION:
      return "label-orange";
    case TaskStatus.FINISHED:
      return "label-success";
    case TaskStatus.DELAYED:
      return "label-orange";
    default:
      CODE_NON_REACHABLE();
      return "label-inverse";
  }
};

export interface OwnProps {
  task: CRMTask;
  smallSize?: boolean;
  editTaskCustom?: DispatchProp<typeof editTask>;
}

interface ConnectedProps {
  currentUser: User | null;
  editTask: DispatchProp<typeof editTask>;
}

type Props = ConnectedProps & OwnProps;

const TaskStatusSelect = (props: Props) => {
  const statusClassName = classNameFromStatus(props.task.status);
  const labelStatusClassName = [
    "label label-big",
    statusClassName,
    ...(props.smallSize ? ["label-small"] : []),
  ].join(" ");
  const statuses = nextStatus(props.currentUser!, props.task);
  const taskStatusStr = taskStatus(props.task);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggle = () => setIsOpen(!isOpen);
  const editLocalTask = undefinedOr(props.editTaskCustom, props.editTask);
  const [isStatusCommentModalOpen, setStatusCommentModalOpen] = useState<boolean>(false);
  const [statusChangeComment, setStatusChangeComment] = useState<string>(props.task.status_comment);
  const needed = [TaskStatus.DELAYED, TaskStatus.REVISION];
  const [newStatus, setNewStatus] = useState<TaskStatus | null>(null);

  useEffect(() => {
    setStatusChangeComment(props.task.status_comment);
  }, [props.task.status_comment]);

  const changeStatusWithoutCheck = () => {
    assert(newStatus !== null);
    return editLocalTask({
      ...props.task,
      status: newStatus!,
      status_comment: statusChangeComment,
    })
      .catch(handleDispatchErrorAndDisplayToast)
      .then(task => {
        setNewStatus(null);
        return task;
      });
  };

  useEffect(() => {
    if (newStatus !== null) {
      if (_.includes(needed, newStatus)) {
        setStatusCommentModalOpen(true);
      } else {
        changeStatusWithoutCheck();
      }
    }
  }, [newStatus]);

  const changeStatusInitial = (status: TaskStatus) => {
    setNewStatus(status);
  };

  const onCommentModalOkPress = (e: any) =>
    changeStatusWithoutCheck().then(() => setStatusCommentModalOpen(false));

  return (
    <>
      {statuses.length > 0 ? (
        <Dropdown isOpen={isOpen} toggle={toggle}>
          <DropdownToggle
            className={classnames([
              "dropdown-toggle",
              "cursor-pointer",
              labelStatusClassName,
              style["toggle-button"],
            ])}
            outline={false}
          >
            {taskStatusStr}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu">
            {statuses.map((status: TaskStatus, i: number) => (
              <div
                className="dropdown-item cursor-pointer"
                key={i}
                onClick={() => changeStatusInitial(status)}
              >
                {taskStatusToString(status)}
              </div>
            ))}
          </DropdownMenu>
        </Dropdown>
      ) : (
        <label className={labelStatusClassName}>{taskStatusStr}</label>
      )}
      <Modal
        toggle={() => setStatusCommentModalOpen(!isStatusCommentModalOpen)}
        isOpen={isStatusCommentModalOpen}
      >
        <ModalHeader toggle={() => setStatusCommentModalOpen(!isStatusCommentModalOpen)}>
          Изменить комментарий к новому статусу?
        </ModalHeader>
        <ModalBody>
          <OnEnter do={onCommentModalOkPress}>
            <Editor
              onChildChange={e => setStatusChangeComment(e)}
              contentValue={statusChangeComment}
            />
          </OnEnter>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onCommentModalOkPress}>
            Ок
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default connect(
  (store: StoreRootState) => ({
    currentUser: sessionUsersModule.selectors.getCurrentUser(store, null),
  }),
  {
    ...getActionsFromModule(tasksModule, ["editTask"]),
  },
)(TaskStatusSelect);
