import { TaskCheckListItem } from "#/store/types";
import React, { useState } from "react";
import { Input, InputGroup } from "reactstrap";
import style from "./index.module.scss";

interface AddCheckListItemProps {
  item: Partial<TaskCheckListItem>;
  onChange: (content: string) => void;
  onDiscard: () => void;
  onSave: (hideInputAfterSave?: boolean) => Promise<unknown>;
  addingMethod: string;
}

const AddCheckListItem = React.forwardRef((props: AddCheckListItemProps, ref: any) => {
  const computedStylings = props.addingMethod ? {} : { display: "none" };
  const [addMultiple, setAddMultiple] = useState(false);

  const onKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      // Do not propagate further
      e.preventDefault();
      e.stopPropagation();
      // If user wants to add more than one item,
      // do not hide the input component, just reset the added input state.
      if (addMultiple) {
        props.onSave(false).then(() => {
          ref.current.focus();
        });
      } else {
        props.onSave(true);
      }
    }
  };

  return (
    <div className={style["add-checklist-item"]} style={computedStylings}>
      <InputGroup>
        <Input
          type="text"
          value={props.item.name}
          onChange={e => props.onChange(e.target.value)}
          placeholder="Введите содержание..."
          onKeyDown={onKeyDown}
          innerRef={ref}
        />
        <button
          className="btn btn-list row-action"
          type="button"
          onClick={() => props.onSave(true)}
        >
          <i className="fas fa-check" />
        </button>
        <button type="button" className="btn btn-list row-action" onClick={props.onDiscard}>
          <i className="fas fa-trash-alt" />
        </button>
      </InputGroup>

      <div className={style["add-multiple-checkbox-container"]}>
        <Input
          type="checkbox"
          addon={true}
          checked={addMultiple}
          onChange={() => setAddMultiple(!addMultiple)}
        />
        <label
          onClick={() => setAddMultiple(!addMultiple)}
          className={style["add-multiple-checkbox-container__label"]}
        >
          Добавить несколько
        </label>
      </div>
    </div>
  );
});

export default AddCheckListItem;
