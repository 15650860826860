import TagsList from "#/components/TagsList";
import usersModule from "#/store/modules/users";
import { CRMTask, StoreRootState, UsersById } from "#/store/types";
import {
  constructTaskTagSearchRoute,
  constructURLToTask,
  getTaskExecutor,
  taskStatusToString,
  userFullName,
} from "#/util";
import cn from "classnames";
import _ from "lodash";
import moment from "moment";
import React, { useLayoutEffect, useMemo, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import style from "../index.module.scss";

const TaskSearchItem = (props: { item: CRMTask; usersById: UsersById }) => {
  const taskTo = getTaskExecutor(props.item, props.usersById);
  const [descriptionShown, setDescriptionShown] = useState<boolean>(false);
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [showDescriptionCollapseButton, setShowDescriptionCollapseButton] = useState<boolean>(
    false,
  );

  useLayoutEffect(() => {
    const elem = descriptionRef.current;
    if (elem === null) { return; }
    if (elem.clientHeight < elem.scrollHeight) {
      // There is something to show
      setShowDescriptionCollapseButton(true);
    }
  }, [props.item]);

  return (
    <div className={cn([style["search-item"], style["task-search-item"]])} key={props.item.pk}>
      <div className={style["task-search-item__left"]}>
        <div className={style["task-search-item__name-and-executor-container"]}>
          <div className={style["task-search-item__name"]}>
            <Link to={constructURLToTask(props.item)}>{props.item.name}</Link>
          </div>
          <span className={style["task-search-item__name-and-executor-container__sep"]}>
            {" "}
            &#8212;{" "}
          </span>
          <div className={style["task-search-item__executor"]}>{userFullName(taskTo)}</div>
        </div>
        {props.item.description.length > 0 && (
          <div
            className={cn({
              [style["task-search-item__description"]]: true,
              [style["task-search-item__description_shown"]]: descriptionShown,
            })}
          >
            <div
              ref={descriptionRef}
              className="html-text"
              dangerouslySetInnerHTML={{
                __html: props.item.description,
              }}
            />
            <Button
              className={cn({
                [style["task-search-item-description-collapse-button"]]: true,
                [style[
                  "task-search-item-description-collapse-button_shown"
                ]]: showDescriptionCollapseButton,
              })}
              color="transparent"
              onClick={() => setDescriptionShown(!descriptionShown)}
            >
              {descriptionShown ? "Скрыть" : "Показать всё"}
            </Button>
          </div>
        )}
        {props.item.tags.length > 0 && (
          <div className={style["task-search-item__tags"]}>
            <TagsList
              tags={props.item.tags}
              constructTagLink={constructTaskTagSearchRoute}
              tagsClickable={true}
              noTagsMessage=""
            />
          </div>
        )}
      </div>
      <div className={style["task-search-item__right"]}>
        <div className={style["task-search-item__status"]}>
          <span>{`${taskStatusToString(props.item.status)}`}</span>
          {props.item.progress!.total > 0 && (
            <> {`(${props.item.progress!.completed}/${props.item.progress!.total})`}</>
          )}
        </div>
        <div className={style["task-search-item__date-info"]}>
          {(() => {
            if (!props.item.execution_start_time) {
              return "Не начата";
            }

            if (!props.item.completed_date) {
              return `Начата ${(moment as any)(props.item.execution_start_time).format(
                "DD.MM.YYYY",
              )}`;
            }

            return (
              <>
                <span>{(moment as any)(props.item.execution_start_time).format("DD.MM.YYYY")}</span>
                <span> &#8212; </span>
                <span>{(moment as any)(props.item.completed_date).format("DD.MM.YYYY")}</span>
              </>
            );
          })()}
        </div>
      </div>
    </div>
  );
};

export default connect((store: StoreRootState) => ({
  usersById: usersModule.selectors.getUsersById(store, null),
}))(TaskSearchItem);
