import {
  constructEmptyFailFetchActionReducer,
  constructEmptyStartFetchActionReducer,
  constructEmptySucceedFetchActionReducer,
  createReducer,
} from "#/store/helpers";
import ProjectsMutationType from "#/store/modules/projects/mutationType";
import { CRMTask, PrimaryKey } from "#/store/types";
import MutationType from "./mutationType";
import _ from "lodash";

export type CRMTasksById = Record<PrimaryKey, CRMTask>;

export interface ProjectState {
  projectId: PrimaryKey | null;
  fetching: boolean;
  fetchingFailed: boolean;
}

const initialState: ProjectState = {
  projectId: null,
  fetching: false,
  fetchingFailed: false,
};

export const reducer = createReducer(
  {
    // Set current project
    [MutationType.SET_CURRENT_PROJECT]: (state: ProjectState, action: any) => ({
      ...state,
      projectId: _.toNumber(action.payload.id),
    }),
    // Fetch single project
    [ProjectsMutationType.FETCH_PROJ_START]: constructEmptyStartFetchActionReducer(
      "fetching",
      "fetchingFailed",
    ),
    [ProjectsMutationType.FETCH_PROJ_FAIL]: constructEmptyFailFetchActionReducer(
      "fetching",
      "fetchingFailed",
    ),
    [ProjectsMutationType.FETCH_PROJ_SUCCEED]: constructEmptySucceedFetchActionReducer(
      "fetching",
      "fetchingFailed",
    ),
  },
  initialState,
);

export default reducer;
