import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  fetchTop50Projects,
  getProjectsForCurrentPage,
  isLoading,
  getNumberOfProjectPages,
  getCurrentProjectPage,
} from "#/store/modules/metrika/top50";
import { StoreRootState } from "#/store/types";
import { MetrikaTop50Project } from "#/store/modules/metrika/top50/types";
import ProjectButtonContainer from "./components/ProjectButtonContainer";
import Loader from "#/components/Loader";

interface IndexOwnProps {}

interface IndexConnectedProps {
  fetchTop50Projects: Function;
  projects: MetrikaTop50Project[];
  loading: boolean;
  pages: number;
  currentPage: number;
}

type IndexProps = IndexOwnProps & IndexConnectedProps;

const Index = (props: IndexProps) => {
  useEffect(() => {
    props.fetchTop50Projects(1);
  }, []);

  if (props.loading) {
    return <Loader />;
  }

  return (
    <div className="d-flex flex-column">
      {props.projects.map(proj => (
        <ProjectButtonContainer key={proj.title + proj.pk} project={proj} />
      ))}
      <nav className="mt-3">
        <ul className="pagination pagination-sm justify-content-center">
          {Array(props.pages)
            .fill(undefined)
            .map((_, i) => (
              <li
                key={i}
                onClick={() => props.fetchTop50Projects(i + 1)}
                className={`page-item ${i + 1 === props.currentPage ? "active" : ""}`}
              >
                <span className="page-link">{i + 1}</span>
              </li>
            ))}
        </ul>
      </nav>
    </div>
  );
};

export default connect(
  (state: StoreRootState, ownProps: IndexOwnProps) => ({
    projects: getProjectsForCurrentPage(state),
    loading: isLoading(state),
    pages: getNumberOfProjectPages(state),
    currentPage: getCurrentProjectPage(state),
  }),
  {
    fetchTop50Projects,
  },
)(Index);
