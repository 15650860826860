import { PrimaryKey, StoreRootState } from "#/store/types";
import { createSelector } from "reselect";
import { MetrikaTop50State, ProjectPage, MetrikaTop50ProjectData } from "./types";
import _ from "lodash";

type Props = any;

export const getMetrikaTop50State = (store: StoreRootState) => store.metrika.top50;
export const getProps = (store: StoreRootState, props: Props) => props;

export const getProjects = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.top50ProjectsById,
);

export const isLoading = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.loading,
);

export const isMetrikaLoading = createSelector(
  getMetrikaTop50State,
  (state: MetrikaTop50State) => state.metrikaLoading,
);

export const getCurrentProjectPage = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.currentProjectPage,
);

export const getProjectPages = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.projectPages,
);

export const getEvents = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.top50EventsById,
);

export const getProjectPksForCurrentPage = createSelector(
  [getProjectPages, getCurrentProjectPage],
  (pages: Record<number, ProjectPage>, page: number) => {
    if (pages[page]) {
      return pages[page].projects;
    }
    return [];
  },
);

export const getProjectsForCurrentPage = createSelector(
  [getProjects, getProjectPksForCurrentPage],
  (projects: Record<PrimaryKey, MetrikaTop50ProjectData>, pks: PrimaryKey[]) =>
    pks.map(pk => projects[pk].data),
);

export const getEventPksFromProps = createSelector(
  [getProps],
  (props: Props) => props.project.events,
);

export const makeGetEventsForProject = () =>
  createSelector([getEventPksFromProps, getEvents], (eventPks, events) =>
    eventPks.map((pk: PrimaryKey) => events[pk].data),
  );

export const getCurrentProjectPk = createSelector(
  [getMetrikaTop50State],
  (state: MetrikaTop50State) => state.currentProject,
);

export const getCurrentProject = createSelector(
  [getProjects, getCurrentProjectPk],
  (projects, pk) => _.get(projects, [pk, "data"], undefined),
);

export const getMetrikaId = createSelector([getCurrentProject], project =>
  project ? project.metrika_id : 0,
);

export const getMetrikaToken = createSelector([getMetrikaTop50State], state => state.token);

export const hasApiFailed = createSelector(
  [getMetrikaTop50State],
  state => state.apiFetchingFailed,
);

export const hasMetrikaFailed = createSelector(
  getMetrikaTop50State,
  state => state.metrikaFetchingFailed,
);

export const getCurrentEventPk = createSelector(
  getMetrikaTop50State,
  (state: MetrikaTop50State) => state.currentEvent,
);

export const getCurrentEvent = createSelector([getEvents, getCurrentEventPk], (events, pk) =>
  _.get(events, [parseInt(`${pk}`, 10), "data"], undefined),
);

export const getProjectsForCurrentEvent = createSelector(
  [getProjects, getCurrentEventPk],
  (projects, eventPk) =>
    Object.values(projects)
      .map(x => x.data)
      .filter(proj => proj.events.includes(eventPk)),
);

export const getNumberOfProjectPages = createSelector(
  [getProjectPages],
  projects => Object.keys(projects).length,
);

export const getQueriesForCurrentEvent = createSelector([getCurrentEvent], event =>
  event && event.queries ? event.queries : [],
);

export const getFetchedQueries = createSelector([getCurrentProject], project =>
  project && project.queries ? project.queries : [],
);

export const getQueryTableOptions = createSelector(
  [getMetrikaTop50State],
  state => state.queryTableOptions,
);

export const getQueries = createSelector(
  [getFetchedQueries, getQueryTableOptions, getQueriesForCurrentEvent],
  (queries, options, existingQueries) =>
    queries.filter(
      query =>
        (options.showExisting || !existingQueries.includes(query.text)) &&
        query.visits >= options.minimum &&
        query.text.includes(options.substring),
    ),
);
