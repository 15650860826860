import { UserNewTaskMessageNotification } from "#/store/types";
import { constructURLToTask } from "#/util";
import React from "react";
import style from "./index.module.scss";
import ItemWrapper from "./ItemWrapper";
import { DerivedNotificationItemProps } from "./types";

const NewTaskMessageNotification = React.forwardRef(
  (props: DerivedNotificationItemProps<UserNewTaskMessageNotification>, ref: React.Ref<any>) => (
    <ItemWrapper
      ref={ref}
      onClick={() => {
        props.push(constructURLToTask(props.notification.task_model));
      }}
      notification={props.baseNotification}
    >
      <span className={style["notification-menu-item__text"]}>
        Новое сообщение в задаче {`"${props.notification.task_model.name}"`}{" "}
      </span>
    </ItemWrapper>
  ),
);

export default NewTaskMessageNotification;
